import React from 'react';
import { FormControl } from 'react-bootstrap';
import AudienceMappingManager from './models/AudienceMappingManager';

type AudienceTitleProps = {
  audienceMappingManager: AudienceMappingManager;
  handleOnChangeAudienceName: (newAudienceName: string) => void;
};

type AudienceTitleState = {
  isAudienceNameCorrectFormat: boolean;
};

export default class AudienceTitle extends React.Component<
  AudienceTitleProps,
  AudienceTitleState
> {
  constructor(props: AudienceTitleProps) {
    super(props);
    this.state = {
      isAudienceNameCorrectFormat: true,
    };
    this.handleAudienceName = this.handleAudienceName.bind(this);
  }

  handleAudienceName(e: any) {
    const noSpecialCacExpression = /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/g;

    const isCorrectFormat: boolean = !!noSpecialCacExpression.exec(
      e.target.value
    );

    this.props.handleOnChangeAudienceName(e.target.value);
    this.setState({
      isAudienceNameCorrectFormat: isCorrectFormat,
    });
  }

  render() {
    const { isAudienceNameCorrectFormat } = this.state;
    const { audienceMappingManager } = this.props;

    if (audienceMappingManager.isNewAudience)
      return (
        <div className="form-row d-flex align-items-center">
          <p className="mb-0 col-3">New Audience Name:</p>
          <div className="col-4">
            <FormControl
              type="text"
              id="audienceName"
              className="form-control"
              onChange={this.handleAudienceName}
              value={audienceMappingManager.name}
              placeholder="Type your new audience’s name..."
            />
          </div>
          {!isAudienceNameCorrectFormat && (
            <div className="col text-danger font-italic">
              Special characters are not allowed.
            </div>
          )}
        </div>
      );
    return (
      <div className="form-row align-items-center">
        <p className="mb-0 col-3">{audienceMappingManager.name}</p>
      </div>
    );
  }
}
