import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import { BackboneViewProps } from '../../types';
import { filterActiveIntegration } from './utils';
import EventMappingParentDisplayComponent from './components/generic/EventMappingParentDisplay';
import ActiveIntegrationDataModel from './models/ActiveIntegrationDataModel';
import { isUserReadOnly } from '../../utils';
import { getAllActiveIntegrations, redirectToInsightsReport } from '../utils';
import MappingsMode from '../enums/MappingsMode';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_back': 'go_back',
    'click #check_volumes': 'checkVolumes',
  },
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.isReadOnly = isUserReadOnly(user);

    // Get and filter the activeIntegrations
    const arrayOfActiveIntegrations: ActiveIntegrationDataModel[] = await this.getAndFilterActiveIntegrations();
    // Render the active integrations
    this.renderEventMappingParentDisplayComponent(arrayOfActiveIntegrations);
  },

  renderEventMappingParentDisplayComponent(
    arrayOfActiveIntegrations: ActiveIntegrationDataModel[]
  ) {
    ReactDOM.render(
      <EventMappingParentDisplayComponent
        arrayOfActiveIntegrations={arrayOfActiveIntegrations}
        tenant={this.tenant}
        isReadOnly={this.isReadOnly}
      />,
      $('#eventMappingParentDisplayComponentContainer')[0]
    );
  },

  checkVolumes() {
    redirectToInsightsReport(MappingsMode.event, this.tenant);
  },

  async getAndFilterActiveIntegrations(): Promise<
    ActiveIntegrationDataModel[]
  > {
    const arrayOfActiveIntegrations: ActiveIntegrationDataModel[] = await getAllActiveIntegrations(
      this.tenant
    );
    return filterActiveIntegration(arrayOfActiveIntegrations);
  },

  go_back() {
    Backbone.history.navigate(`/org/${this.tenant}/mapping`, true);
  },

  async isDataDiscoveryForConnector(connector: string) {
    try {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${connector}`
      );
      return !!data && Object.keys(data).length;
    } catch (_e) {
      return false;
    }
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
