import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import {
  Computation,
  PREDICT_RESULT_KEYS,
  PersonResult,
  PredictResult,
  PredictResultKey,
} from '../../types';
import { PROFILE_PAGE_QUERY_TIMEOUT } from '../../constants';

function useFetchProfileData(
  tenant: number,
  userEmail: string,
  onSuccess?: () => void,
  onError?: () => void
) {
  return useMutation({
    mutationFn: async (email: string) => {
      window.analytics.track('Searched profile', {
        tenant,
        email: userEmail,
        searchedEmail: email,
      });

      const [
        { data: personResult },
        { data: computations },
      ] = await Promise.all([
        axios.get<PersonResult>(`${BONGO_URL}/v1/org/${tenant}/persons`, {
          params: { email },
          timeout: PROFILE_PAGE_QUERY_TIMEOUT,
        }),
        axios.get<Computation[]>(
          `${BONGO_URL}/v1/org/${tenant}/computations/${email}`,
          {
            timeout: PROFILE_PAGE_QUERY_TIMEOUT,
          }
        ),
      ]);

      const predictResult: PredictResult = {};

      computations.forEach(({ name, value }) => {
        if (PREDICT_RESULT_KEYS.includes(name as PredictResultKey)) {
          // we can overpass this type as we check it with the includes
          predictResult[name as PredictResultKey] = value;
        }
      });

      return { personResult, computations, predictResult };
    },
    onError,
    onSuccess,
  });
}

export default useFetchProfileData;
