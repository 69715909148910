import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useGetIntegrationConfigQuery<
  TIntegrationConfig extends Record<string, unknown> = Record<string, unknown>
>({ tenant, integration }: { tenant: number; integration: string }) {
  return useQuery({
    queryKey: [tenant, 'integrations', integration, 'config'],
    queryFn: async () => {
      const { data } = await axios.get<TIntegrationConfig>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/config`
      );

      return data;
    },
  });
}
