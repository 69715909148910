import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import { AnalyticsFiltersForm } from '../../../../../models/filters_forms/AnalyticsFiltersForm';
import { ConversionMappingConnectorsNames } from '../../../../../../../types';
import SourceSystems from '../../../../../../enums/SourceSystems';
import { ConversionMappingError } from '../../../../../models/ConversionMappingData';
import MissingFieldMicrocopy from '../../../../../../../components/ErrorMicroCopyComponent';

type SourceSystemComponentProps = {
  filterForm: AnalyticsFiltersForm;
  handleSourceSystem: (s: string) => any;
  handleConversionEvent: (c: string) => any;
  activeIntegrations: ConversionMappingConnectorsNames[];
  errors: ConversionMappingError[];
  conversionTypeDataIndex: number;
};

export default class SourceSystemComponent extends React.Component<
  SourceSystemComponentProps,
  {}
> {
  constructor(props: SourceSystemComponentProps) {
    super(props);
    this.handleConversionEvent = this.handleConversionEvent.bind(this);
  }

  handleConversionEvent(e: any) {
    this.props.handleConversionEvent(e.target.value);
  }

  render() {
    const { filterForm, errors, conversionTypeDataIndex } = this.props;
    const { sourceSystem, conversionEvents } = filterForm;
    const concernedErrors = errors.filter(
      ({ conversionTypeIndex, connector }) =>
        connector === 'analytics' &&
        conversionTypeIndex === conversionTypeDataIndex
    );
    const hasSourceSystemError = concernedErrors.some(
      (error) => error.level === 'empty source systems'
    );
    const hasConversionEventsError = concernedErrors.some(
      (error) => error.level === 'empty conversion events'
    );

    return (
      <div>
        <form>
          <FormGroup controlId="analytics_source_system">
            <ControlLabel>
              Source System (Show only the active one)
            </ControlLabel>
            <Select
              value={{
                label: sourceSystem,
                value: sourceSystem,
              }}
              options={Object.keys(SourceSystems).map(
                (aSourceSystem: SourceSystems) => ({
                  label: aSourceSystem,
                  value: aSourceSystem,
                })
              )}
              onChange={({ value }) => {
                this.props.handleSourceSystem(value);
              }}
            />
            {hasSourceSystemError && (
              <MissingFieldMicrocopy microcopy="Please enter a valid sources system value." />
            )}
            <FormControl.Feedback />
          </FormGroup>
          <FormGroup controlId="analytics_conversion_event">
            <ControlLabel>Conversion event(s) (comma separated)</ControlLabel>
            <FormControl
              name="conversion_event"
              type="text"
              value={conversionEvents}
              placeholder="example1, example2, ..."
              onChange={this.handleConversionEvent}
            />
            {hasConversionEventsError && (
              <MissingFieldMicrocopy microcopy="Please enter a valid conversion events." />
            )}
            <FormControl.Feedback />
          </FormGroup>
        </form>
      </div>
    );
  }
}
