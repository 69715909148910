import { z } from 'zod';

export const githubCredentialsSchema = z.object({
  repositories: z.array(z.string()),
});

export type GithubCredentials = z.infer<typeof githubCredentialsSchema>;

export const INVALID_GITHUB_TOKEN_ERROR = 'Invalid Github Token';

export const KNOWN_GITHUB_ERRORS = [INVALID_GITHUB_TOKEN_ERROR];
