import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import uniqWith from 'lodash/uniqWith';
import {
  fixDuplicatedIndexes,
  getConversionMappingActiveIntegrations,
  prepareConversionMappingDataDisplay,
} from '../utils';
import {
  ConversionMappingConnectorsNames,
  ConversionTypesOfConnector,
  MappingConversionsTypes,
} from '../../../types';
import { extractFieldNamesFromObjectsByDatasource } from '../../pullConfigManager';
import DataSources from '../../enums/DataSources';
import SourceSystems from '../../enums/SourceSystems';
import {
  ConversionTypeDataIndexed,
  ConversionTypesGroup,
} from './ConversionTypesGroup';
import { ConversionTypeData } from './ConversionTypeData';
import { DefaultFiltersForm } from './filters_forms/DefaultFiltersForm';
import { AnalyticsFiltersForm } from './filters_forms/AnalyticsFiltersForm';
import { ParametersFieldsNamesDataModel } from '../../models/ConnectorFieldNameDataModel';
import { CONDITION_LOGIC_CHECK_REGEX } from '../../constants';

const MAXIMUM_NUMBER_OF_SQO_CONVERSION_TYPES = 100;
// 10 to avoid entering in conflict with other index in other groups

export const MappingConversionsTypesValues: {
  [key in MappingConversionsTypes]: number;
} = {
  'Closed Won': 2,
  'Open Opp': 1,
  SQO: 0,
};

export type ActiveConnectorDataModel = {
  connector: ConversionMappingConnectorsNames;
  isActive: boolean;
  updatedAt: number;
};

export type ConversionMappingDataConstructorOptions = {
  activeConnectors?: ActiveConnectorDataModel[];
  createdAt?: number;
  updatedAt?: number;
  conversionTypesGroups?: ConversionTypesGroup[];
};

export type ConversionMappingErrorLevel =
  | 'condition logic empty'
  | 'condition logic extra parameters'
  | 'condition logic ignored parameters'
  | 'condition logic syntax error'
  | 'custom conversion has no name'
  | 'parameter invalid verb'
  | 'parameter invalid field'
  | 'parameter invalid table'
  | 'parameter invalid values'
  | 'empty source systems'
  | 'empty conversion events'
  | 'amount field invalid table'
  | 'amount field invalid value';

export type ConversionMappingError = {
  connector: ConversionMappingConnectorsNames;
  level: ConversionMappingErrorLevel;
  groupName: string;
  conversionTypeIndex: number;
  parameterIndex?: number;
  valueIndex?: number;
  indexes?: number[];
};

export class ConversionMappingData {
  activeConnectors: ActiveConnectorDataModel[];

  tenant: number;

  email: string;

  createdAt: number;

  updatedAt: number;

  conversionTypesGroups: ConversionTypesGroup[];

  parametersFieldsNames: ParametersFieldsNamesDataModel;

  activeIntegrations: ConversionMappingConnectorsNames[];

  constructor(
    tenant: number,
    email: string,
    options?: ConversionMappingDataConstructorOptions
  ) {
    this.tenant = tenant;
    this.email = email;
    this.activeIntegrations = [];
    this.conversionTypesGroups = [];
    this.activeConnectors = [];
    this.parametersFieldsNames = {
      madMl: [],
      salesforce: [],
      analytics: [],
      hubspot: [],
      stripe: [],
    };
    this.build(options);
  }

  async init() {
    if (this.activeIntegrations?.length === 0) {
      this.activeIntegrations = (await getConversionMappingActiveIntegrations(
        this.tenant
      )) as ConversionMappingConnectorsNames[];
    }

    if (this.activeConnectors?.length === 0) {
      this.activeConnectors = this.absorbActiveIntegrations(
        this.activeIntegrations
      );
    } else {
      this.checkAndAbsorbNewIntegration();
    }

    await this.initParametersFieldsNames();

    if (this.conversionTypesGroups?.length === 0) {
      await this.buildAndInitConversionTypesGroupData();
    } else {
      await this.checkAndInitConversionTypesGroups();
    }
  }

  // requires this.activeIntegrations up to date
  // Will check for new activeIntegration if exists, add them, take them under consideration in the rest of the process
  checkAndAbsorbNewIntegration() {
    const activeConnectorsNames: string[] = this.activeConnectors.map(
      (activeConnector) => activeConnector.connector
    );
    const isThereConnectorDiff: boolean = !isEqual(
      activeConnectorsNames.sort(),
      this.activeIntegrations.sort()
    );
    if (isThereConnectorDiff) {
      const connectorsDiff: string[] = differenceWith(
        this.activeIntegrations.sort(),
        activeConnectorsNames.sort(),
        isEqual
      );
      connectorsDiff.forEach((connectorDiff) => {
        this.activeConnectors.push({
          connector: connectorDiff as ConversionMappingConnectorsNames,
          isActive: false,
          updatedAt: Date.now(),
        });
      });
    }
  }

  absorbActiveIntegrations(
    activeIntegrations: ConversionMappingConnectorsNames[]
  ): ActiveConnectorDataModel[] {
    return activeIntegrations.map((activeIntegration) => {
      return {
        connector: activeIntegration,
        isActive: false,
        updatedAt: Date.now(),
      };
    });
  }

  setActiveConnector(
    connector: ConversionMappingConnectorsNames
  ): ActiveConnectorDataModel[] {
    let indexOf: number = 0;
    const foundObject = this.activeConnectors.find((element, index) => {
      indexOf = index;
      return element.connector === connector;
    });
    if (foundObject) {
      this.activeConnectors[indexOf].isActive = !this.activeConnectors[indexOf]
        .isActive;
      this.activeConnectors[indexOf].updatedAt = Date.now();
    }
    return this.activeConnectors;
  }

  setAllConversionTypesDataAmountFieldOfConnector(
    connector: ConversionMappingConnectorsNames,
    value: string | null,
    attribute: 'value' | 'table'
  ): ConversionTypesGroup[] {
    this.conversionTypesGroups = this.conversionTypesGroups.map(
      (conversionTypeGroup) => {
        const newConversionTypeGroup = conversionTypeGroup;
        newConversionTypeGroup.indexedConversionTypesData = conversionTypeGroup.indexedConversionTypesData.map(
          (indexedConversionTypeData) => {
            const newIndexedConversionTypeDataConversionType = indexedConversionTypeData;
            if (
              newIndexedConversionTypeDataConversionType.conversionTypeData
                .connector === connector
            ) {
              newIndexedConversionTypeDataConversionType.conversionTypeData.updatedAt = Date.now();
              newIndexedConversionTypeDataConversionType.conversionTypeData.amountField[
                attribute
              ] = value;
              if (attribute === 'table') {
                newIndexedConversionTypeDataConversionType.conversionTypeData.amountField.value = null;
              }
            }
            return newIndexedConversionTypeDataConversionType;
          }
        );
        return newConversionTypeGroup;
      }
    );
    return this.conversionTypesGroups;
  }

  setAllConversionTypesDataFiltersTypesOfConnector(
    connector: ConversionMappingConnectorsNames,
    filtersTypes: string[]
  ): ConversionTypesGroup[] {
    this.conversionTypesGroups = this.conversionTypesGroups.map(
      (conversionTypeGroup) => {
        const newConversionTypeGroup = conversionTypeGroup;
        newConversionTypeGroup.indexedConversionTypesData = conversionTypeGroup.indexedConversionTypesData.map(
          (indexedConversionTypeData) => {
            const newIndexedConversionTypeDataonversionType = indexedConversionTypeData;
            if (
              newIndexedConversionTypeDataonversionType.conversionTypeData
                .connector === connector
            ) {
              newIndexedConversionTypeDataonversionType.conversionTypeData.updatedAt = Date.now();
              newIndexedConversionTypeDataonversionType.conversionTypeData.filterTypesValues = filtersTypes;
            }
            return newIndexedConversionTypeDataonversionType;
          }
        );
        return newConversionTypeGroup;
      }
    );
    return this.conversionTypesGroups;
  }

  build(options: ConversionMappingDataConstructorOptions) {
    // activeConnectors
    if (options?.activeConnectors) {
      this.activeConnectors = options.activeConnectors;
    } else {
      this.activeConnectors = [];
    }
    // createdAt
    if (options?.createdAt) {
      this.createdAt = options.createdAt;
    } else {
      this.createdAt = Date.now();
    }
    // updatedAt
    if (options?.updatedAt) {
      this.updatedAt = options.updatedAt;
    } else {
      this.updatedAt = Date.now();
    }
    // conversionTypesGroups
    if (options?.conversionTypesGroups) {
      this.conversionTypesGroups = options.conversionTypesGroups
        .filter((conversionTypeGroup) => conversionTypeGroup.name)
        .map((conversionTypeGroup) => {
          const newConversionTypeGroup = new ConversionTypesGroup(
            this.tenant,
            this.email,
            conversionTypeGroup.name,
            conversionTypeGroup.isCustom,
            conversionTypeGroup.tag,
            conversionTypeGroup.index,
            conversionTypeGroup.type,
            this.parametersFieldsNames,
            {
              createdAt: conversionTypeGroup.createdAt,
              updatedAt: conversionTypeGroup.updatedAt,
            }
          );
          newConversionTypeGroup.indexedConversionTypesData = newConversionTypeGroup.transformToIndexedConversionTypesDataInstances(
            conversionTypeGroup.indexedConversionTypesData,
            this.parametersFieldsNames
          );
          return newConversionTypeGroup;
        });
      this.conversionTypesGroups = fixDuplicatedIndexes(
        this.conversionTypesGroups
      );
    } else {
      this.conversionTypesGroups = [];
    }
  }

  buildDefaultConversionTypesGroupsData(
    activeIntegrations: ConversionMappingConnectorsNames[]
  ) {
    activeIntegrations?.forEach((activeIntegration) => {
      return ConversionTypesOfConnector[activeIntegration].forEach(
        (type: MappingConversionsTypes, index: number) => {
          const hasConversionGroupWithComingType: boolean = !!this.conversionTypesGroups.find(
            (conversionTypeGroup) => {
              return conversionTypeGroup.type === type;
            }
          );
          if (!hasConversionGroupWithComingType) {
            const conversionTypeDataGroup = new ConversionTypesGroup(
              this.tenant,
              this.email,
              type,
              false,
              'standard',
              index,
              type,
              this.parametersFieldsNames
            );
            this.conversionTypesGroups.push(conversionTypeDataGroup);
          }
        }
      );
    });
  }

  isLimitOfSqoGroupsReached(): boolean {
    const numberOfSQoConversionTypeGroups: number = this.conversionTypesGroups.filter(
      (conversionTypeGroup) => {
        return conversionTypeGroup.type === 'SQO';
      }
    )?.length;
    return (
      numberOfSQoConversionTypeGroups > MAXIMUM_NUMBER_OF_SQO_CONVERSION_TYPES
    );
  }

  async addNewSQOConversionType(
    isCustom: boolean
  ): Promise<ConversionMappingData> {
    const lastIndexOfGroups = this.conversionTypesGroups.sort((a, b) => {
      return b.index - a.index;
    })[0].index;
    const index = this.conversionTypesGroups
      .sort((a, b) => {
        return b.getMaxIndex() - a.getMaxIndex();
      })[0]
      .getMaxIndex();
    if (!this.isLimitOfSqoGroupsReached()) {
      const newSqoConversionTypesGroup: ConversionTypesGroup = new ConversionTypesGroup(
        this.tenant,
        this.email,
        '',
        isCustom,
        'custom',
        lastIndexOfGroups + 1,
        'SQO',
        this.parametersFieldsNames
      );
      await newSqoConversionTypesGroup.init(
        this.activeConnectors,
        this.parametersFieldsNames,
        index + 1
      );
      this.conversionTypesGroups.push(newSqoConversionTypesGroup);
    }
    return this;
  }

  async buildAndInitConversionTypesGroupData() {
    let index: number = -1;
    this.buildDefaultConversionTypesGroupsData(this.activeIntegrations);
    const initiatedConversionTypesGroups = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const conversionTypeGroup of this.conversionTypesGroups) {
      // eslint-disable-next-line no-await-in-loop
      index = await conversionTypeGroup.init(
        this.activeConnectors,
        this.parametersFieldsNames,
        index + 1
      );
      initiatedConversionTypesGroups.push(conversionTypeGroup);
    }
    this.conversionTypesGroups = initiatedConversionTypesGroups;
  }

  async checkAndInitConversionTypesGroups() {
    const allIndexedConversionTypesData = this.getAllIndexedConversionTypesData();
    const allConnectorsNamesDuplicated = allIndexedConversionTypesData.map(
      (indexedConversionTypesData) => {
        return indexedConversionTypesData.conversionTypeData.connector;
      }
    );
    const allConnectorsNames = uniqWith(allConnectorsNamesDuplicated, isEqual);
    const isThereConnectorDiff: boolean = !isEqual(
      this.activeIntegrations.sort(),
      allConnectorsNames.sort()
    );
    if (isThereConnectorDiff) {
      const connectorsDiff: string[] = differenceWith(
        this.activeIntegrations.sort(),
        allConnectorsNames.sort(),
        isEqual
      );
      this.buildDefaultConversionTypesGroupsData(
        connectorsDiff as ConversionMappingConnectorsNames[]
      );

      // We only take the new connectors that has been activated
      const newActivatedConnectors = this.activeConnectors.filter(
        (activeConnector) => {
          return connectorsDiff.includes(activeConnector.connector);
        }
      );
      let index: number = -1;
      const initiatedConversionTypesGroups = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const conversionTypeGroup of this.conversionTypesGroups) {
        // eslint-disable-next-line no-await-in-loop
        index = await conversionTypeGroup.buildAndInitConversionTypesData(
          newActivatedConnectors,
          this.parametersFieldsNames,
          index + 1
        );
        initiatedConversionTypesGroups.push(conversionTypeGroup);
      }
      this.conversionTypesGroups = initiatedConversionTypesGroups;
    }
  }

  async initParametersFieldsNames(): Promise<ParametersFieldsNamesDataModel> {
    const listOfPromises = this.activeIntegrations.map((activeIntegration) => {
      return extractFieldNamesFromObjectsByDatasource(
        this.tenant,
        activeIntegration as DataSources
      );
    });
    const listOfConnectorFieldsNames = await Promise.all(listOfPromises);
    listOfConnectorFieldsNames.forEach((connectorFieldNames, index) => {
      this.parametersFieldsNames[
        this.activeIntegrations[index]
      ] = connectorFieldNames;
    });
    return this.parametersFieldsNames;
  }

  getIndexGroupByConversionTypeIndex(conversionTypeDataIndex: number): number {
    const targetConversionTypesGroup = this.conversionTypesGroups.find(
      (conversionTypeGroup) => {
        const indexes = conversionTypeGroup.indexedConversionTypesData.map(
          (indexedConversionTypeData) => indexedConversionTypeData.index
        );
        return indexes.includes(conversionTypeDataIndex);
      }
    );
    return this.conversionTypesGroups.findIndex((conversionTypesGroup) => {
      return targetConversionTypesGroup === conversionTypesGroup;
    });
  }

  getConversionTypeByIndex(
    conversionTypeDataIndex: number
  ): ConversionTypeData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    if (conversionTypesGroupIndex !== -1) {
      return this.conversionTypesGroups[
        conversionTypesGroupIndex
      ].indexedConversionTypesData.find(
        (indexedConversionTypeData) =>
          indexedConversionTypeData.index === conversionTypeDataIndex
      ).conversionTypeData;
    }
    return this.conversionTypesGroups[0].indexedConversionTypesData[0]
      .conversionTypeData;
  }

  setGroupName(
    newName: string,
    conversionTypeGroupIndex: number
  ): ConversionMappingData {
    const targetArrayIndex: number = this.conversionTypesGroups.findIndex(
      (conversionTypeIndex) => {
        return conversionTypeIndex.index === conversionTypeGroupIndex;
      }
    );
    this.conversionTypesGroups[targetArrayIndex].name = newName;
    // Propagate change
    this.conversionTypesGroups[targetArrayIndex].setGroupNameOfConversionTypes(
      newName
    );
    return this;
  }

  setLower(
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.setLowerDefaultFilter(parameterIndex);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  addParameter(conversionTypeDataIndex: number): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.addParameterToDefaultFilter();
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  addValue(
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.addValueToParameter(parameterIndex);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  removeValue(
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.removeValueOfParameter(parameterIndex);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeLogic(
    newLogic: string,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeLogicOfDefaultFilter(newLogic);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeValue(
    newValue: string,
    conversionTypeDataIndex: number,
    parameterIndex: number,
    valueIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeValueOfDefaultFilter(
        newValue,
        parameterIndex,
        valueIndex
      );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeTableValue(
    newTableValue: string,
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeTableValueOfDefaultFilter(
        newTableValue,
        parameterIndex
      );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeFieldValue(
    newFieldValue: string,
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeFieldValueOfDefaultFilter(
        newFieldValue,
        parameterIndex
      );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeVerbValue(
    newVerbValue: string,
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeVerbOfDefaultFilterParameters(
        newVerbValue,
        parameterIndex
      );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  removeParameter(
    conversionTypeDataIndex: number,
    parameterIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.removeParameterOfDefaultFilter(parameterIndex);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeAmountThreshold(
    newThreshold: number,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeThresholdOfStripeFilter(newThreshold);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeSourceSystem(
    newSourceSystem: SourceSystems,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeSourceSystemOfAnalyticsFilter(newSourceSystem);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeAmountFieldValue(
    newAmountFieldValue: string,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeAmountFieldValue(newAmountFieldValue);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeAmountFieldTable(
    newAmountFieldTable: string,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeAmountFieldTable(newAmountFieldTable);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeFilterTypesValues(
    newFilterTypesValues: string,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeFilterTypesValues(newFilterTypesValues);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeConversionEvents(
    newConversionEvents: string,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeConversionEventsfAnalyticsFilter(
        newConversionEvents
      );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  changeMadMlSqlQuery(
    newMadMlSqlQuery: string,
    conversionTypeDataIndex: number
  ): ConversionMappingData {
    const conversionTypesGroupIndex = this.getIndexGroupByConversionTypeIndex(
      conversionTypeDataIndex
    );
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].indexedConversionTypesData
      .find((indexedConversionTypeData) => {
        return indexedConversionTypeData.index === conversionTypeDataIndex;
      })
      .conversionTypeData.changeMadMlSqlQuery(newMadMlSqlQuery);
    this.conversionTypesGroups[
      conversionTypesGroupIndex
    ].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this;
  }

  getActivatedConnectors(): ActiveConnectorDataModel[] {
    return this.activeConnectors.filter((activeConnector) => {
      return activeConnector.isActive;
    });
  }

  getConversionTypeGroupWithActiveConnectors(): ConversionTypesGroup[] {
    const activeConnectorsNames = this.getActivatedConnectors().map(
      (activeConnector) => activeConnector.connector
    );

    activeConnectorsNames.push('madMl');
    return this.conversionTypesGroups
      .filter((conversionTypeGroup) => {
        const conversionTypeGroupWithActiveConnectors = conversionTypeGroup;
        conversionTypeGroupWithActiveConnectors.indexedConversionTypesData = conversionTypeGroup.indexedConversionTypesData.filter(
          (indexedConversionTypeData) => {
            return activeConnectorsNames.includes(
              indexedConversionTypeData.conversionTypeData.connector
            );
          }
        );
        return conversionTypeGroupWithActiveConnectors;
      })
      .sort((a, b) => {
        return (
          MappingConversionsTypesValues[b.type] -
          MappingConversionsTypesValues[a.type]
        );
      });
  }

  getAllActiveConversionTypesData(): ConversionTypeDataIndexed[] {
    return this.getConversionTypeGroupWithActiveConnectors()
      .map((conversionTypeGroup) => {
        return conversionTypeGroup.indexedConversionTypesData.map(
          (indexedConversionTypesData) => {
            return indexedConversionTypesData;
          }
        );
      })
      .flat(2);
  }

  getAllIndexedConversionTypesData(): ConversionTypeDataIndexed[] {
    return this.conversionTypesGroups
      .map((conversionTypeGroup) => {
        return conversionTypeGroup.indexedConversionTypesData.map(
          (indexedConversionTypesData) => {
            return indexedConversionTypesData;
          }
        );
      })
      .flat(2);
  }

  getAllStandardIndexedConversionTypesData(): ConversionTypeDataIndexed[] {
    return this.conversionTypesGroups
      .filter((conversionTypeGroup) => {
        return conversionTypeGroup.tag === 'standard';
      })
      .map((conversionTypeGroup) => {
        return conversionTypeGroup.indexedConversionTypesData.map(
          (indexedConversionTypesData) => {
            return indexedConversionTypesData;
          }
        );
      })
      .flat(2);
  }

  removeConversionTypeGroup(
    conversionTypeGroupIndex: number
  ): ConversionMappingData {
    this.conversionTypesGroups = this.conversionTypesGroups.filter(
      (conversionTypeGroup) =>
        conversionTypeGroup.index !== conversionTypeGroupIndex
    );
    this.updatedAt = Date.now();
    return this;
  }

  setIsCustomConversionGroup(conversionTypeDataIndex: number) {
    const index = this.conversionTypesGroups
      .sort((a, b) => {
        return b.getMaxIndex() - a.getMaxIndex();
      })[0]
      .getMaxIndex();
    this.conversionTypesGroups = this.conversionTypesGroups.map(
      (conversionTypeGroup) => {
        if (conversionTypeGroup.index === conversionTypeDataIndex) {
          const newConversionTypeGroup = conversionTypeGroup;
          newConversionTypeGroup.setIsCustom(index);
          return newConversionTypeGroup;
        }
        return conversionTypeGroup;
      }
    );
    this.updatedAt = Date.now();
    return this;
  }

  transformToDisplay(): ConversionMappingData {
    const activatedConnectors: ConversionMappingConnectorsNames[] = this.getActivatedConnectors().map(
      (activatedConnector) => activatedConnector.connector
    );
    const copy = prepareConversionMappingDataDisplay(cloneDeep(this));
    if (activatedConnectors.includes('stripe')) {
      const closedWonIndex = copy.conversionTypesGroups.findIndex(
        (conversionTypeGroup) => conversionTypeGroup.type === 'Closed Won'
      );
      copy.conversionTypesGroups[
        closedWonIndex
      ].indexedConversionTypesData.push({
        index: copy.conversionTypesGroups[closedWonIndex].getMaxIndex() + 1,
        conversionTypeData: new ConversionTypeData(
          copy.tenant,
          copy.email,
          false,
          'stripe',
          'Closed Won',
          [],
          copy.conversionTypesGroups[closedWonIndex].name
        ),
      });
      const openOppIndex = copy.conversionTypesGroups.findIndex(
        (conversionTypeGroup) => conversionTypeGroup.type === 'Open Opp'
      );
      copy.conversionTypesGroups[openOppIndex].indexedConversionTypesData.push({
        index: copy.conversionTypesGroups[closedWonIndex].getMaxIndex() + 1,
        conversionTypeData: new ConversionTypeData(
          copy.tenant,
          copy.email,
          false,
          'stripe',
          'Open Opp',
          [],
          copy.conversionTypesGroups[openOppIndex].name
        ),
      });
    }
    if (activatedConnectors.includes('analytics')) {
      const sqoGroupIndex = copy.conversionTypesGroups.findIndex(
        (conversionTypeGroup) => conversionTypeGroup.type === 'SQO'
      );
      copy.conversionTypesGroups[sqoGroupIndex].indexedConversionTypesData.push(
        {
          index: copy.conversionTypesGroups[sqoGroupIndex].getMaxIndex() + 1,
          conversionTypeData: new ConversionTypeData(
            copy.tenant,
            copy.email,
            false,
            'analytics',
            'SQO',
            [],
            copy.conversionTypesGroups[sqoGroupIndex].name
          ),
        }
      );
      const openOppGroupIndex = copy.conversionTypesGroups.findIndex(
        (conversionTypeGroup) => conversionTypeGroup.type === 'Open Opp'
      );
      copy.conversionTypesGroups[
        openOppGroupIndex
      ].indexedConversionTypesData.push({
        index: copy.conversionTypesGroups[sqoGroupIndex].getMaxIndex() + 1,
        conversionTypeData: new ConversionTypeData(
          copy.tenant,
          copy.email,
          false,
          'analytics',
          'Open Opp',
          [],
          copy.conversionTypesGroups[openOppGroupIndex].name
        ),
      });
    }
    return copy;
  }

  getErrorsByLevels(
    levels: ConversionMappingErrorLevel[]
  ): ConversionMappingError[] {
    const errors = this.checkForErrors();
    return errors.filter((error) => levels.includes(error.level));
  }

  getErrorsByLevelsAndConnector(
    levels: ConversionMappingErrorLevel[],
    connector: ConversionMappingConnectorsNames
  ): ConversionMappingError[] {
    const errors = this.checkForErrors();
    return errors.filter(
      (error) => levels.includes(error.level) && error.connector === connector
    );
  }

  checkForErrors(): ConversionMappingError[] {
    const errorsSet = new Set<ConversionMappingError>();
    const conversionTypesGroups = this.getConversionTypeGroupWithActiveConnectors();
    conversionTypesGroups.forEach((conversionTypeGroup) => {
      if (conversionTypeGroup.name?.length === 0) {
        errorsSet.add({
          connector: 'salesforce',
          conversionTypeIndex: conversionTypeGroup.index,
          level: 'custom conversion has no name',
          groupName: '',
        });
      }
    });
    const indexedConversionTypes = this.getAllActiveConversionTypesData();
    indexedConversionTypes
      .filter(
        (indexedConversionType) =>
          !indexedConversionType.conversionTypeData.isCustom
      )
      .forEach((indexedConversionType) => {
        const {
          conversionTypeData,
          index: conversionTypeIndex,
        } = indexedConversionType;
        if (
          conversionTypeData.connector === 'salesforce' ||
          conversionTypeData.connector === 'hubspot'
        ) {
          const defaultFilterForm = indexedConversionType.conversionTypeData
            .filtersForm as DefaultFiltersForm;
          if (defaultFilterForm.parametersLogic?.length === 0) {
            errorsSet.add({
              conversionTypeIndex,
              connector: conversionTypeData.connector,
              level: 'condition logic empty',
              groupName: conversionTypeData.groupName,
            });
          }
          const usedIndexes = defaultFilterForm.parametersLogic
            ?.match(/\$\d*/gi)
            ?.map((indexWithDollar) => {
              const indexWithoutDollar = indexWithDollar.replace('$', '');
              if (!Number.isNaN(Number(indexWithoutDollar))) {
                return Number(indexWithoutDollar);
              }
              return null;
            })
            ?.filter((index) => !!index);
          const parametersIndex: number[] = [];
          defaultFilterForm.parameters?.forEach(
            ({ field, table, values, verb }, index) => {
              parametersIndex.push(index + 1);
              if (!table) {
                errorsSet.add({
                  conversionTypeIndex,
                  connector: conversionTypeData.connector,
                  level: 'parameter invalid table',
                  groupName: conversionTypeData.groupName,
                  parameterIndex: index,
                });
              }
              if (!field) {
                errorsSet.add({
                  conversionTypeIndex,
                  connector: conversionTypeData.connector,
                  level: 'parameter invalid field',
                  groupName: conversionTypeData.groupName,
                  parameterIndex: index,
                });
              }
              if (!verb) {
                errorsSet.add({
                  conversionTypeIndex,
                  connector: conversionTypeData.connector,
                  level: 'parameter invalid verb',
                  groupName: conversionTypeData.groupName,
                  parameterIndex: index,
                });
              }
              if (!['is known', 'is unknown'].includes(verb)) {
                values.forEach((value, valueIndex) => {
                  if (!String(value)?.trim()?.length) {
                    errorsSet.add({
                      conversionTypeIndex,
                      valueIndex,
                      connector: conversionTypeData.connector,
                      level: 'parameter invalid values',
                      groupName: conversionTypeData.groupName,
                      parameterIndex: index,
                    });
                  }
                });
              }
            }
          );
          const ignoredIndexes = differenceWith(parametersIndex, usedIndexes);
          const extraIndexes = differenceWith(usedIndexes, parametersIndex);
          const syntaxCheckResult = defaultFilterForm.parametersLogic
            ?.trim()
            ?.match(CONDITION_LOGIC_CHECK_REGEX);
          if (ignoredIndexes?.length) {
            errorsSet.add({
              conversionTypeIndex,
              connector: conversionTypeData.connector,
              level: 'condition logic ignored parameters',
              groupName: conversionTypeData.groupName,
              indexes: ignoredIndexes,
            });
          }
          if (extraIndexes?.length) {
            errorsSet.add({
              conversionTypeIndex,
              connector: conversionTypeData.connector,
              level: 'condition logic extra parameters',
              groupName: conversionTypeData.groupName,
              indexes: extraIndexes,
            });
          }
          if (!syntaxCheckResult) {
            errorsSet.add({
              conversionTypeIndex,
              connector: conversionTypeData.connector,
              level: 'condition logic syntax error',
              groupName: conversionTypeData.groupName,
            });
          }
          if (!conversionTypeData.amountField.table) {
            errorsSet.add({
              connector: conversionTypeData.connector,
              level: 'amount field invalid table',
              conversionTypeIndex,
              groupName: conversionTypeData.groupName,
            });
          }
          if (!conversionTypeData.amountField.value) {
            errorsSet.add({
              connector: conversionTypeData.connector,
              level: 'amount field invalid value',
              conversionTypeIndex,
              groupName: conversionTypeData.groupName,
            });
          }
        } else if (conversionTypeData.connector === 'analytics') {
          const analyticsFilterForm = indexedConversionType.conversionTypeData
            .filtersForm as AnalyticsFiltersForm;
          if (analyticsFilterForm.sourceSystem?.length === 0) {
            errorsSet.add({
              connector: 'analytics',
              conversionTypeIndex,
              level: 'empty source systems',
              groupName: conversionTypeData.groupName,
            });
          } else if (analyticsFilterForm.conversionEvents?.length === 0) {
            errorsSet.add({
              connector: 'analytics',
              conversionTypeIndex,
              level: 'empty conversion events',
              groupName: conversionTypeData.groupName,
            });
          }
        }
      });
    return Array.from(errorsSet);
  }

  prepare() {
    delete this.parametersFieldsNames;
    this.conversionTypesGroups.forEach((conversionTypeGroup) => {
      conversionTypeGroup.prepare();
    });
  }
}
