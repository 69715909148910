import axios from 'axios';
import map from 'lodash/map';
import { ConnectorFieldNameDataModel } from '../models/ConnectorFieldNameDataModel';
import { DATASOURCE_OBJECTS } from '../utils';
import DataSources from '../enums/DataSources';
import FieldDataModel from '../models/FieldDataModel';

export function unslugFieldName(
  fieldName: string,
  IS_SALESFORCE = false
): string {
  // This condition exist because if the fieldName is empty, it will be an object instead of a string
  if (typeof fieldName !== 'string') {
    return '';
  }

  if (fieldName.startsWith('a_')) {
    return IS_SALESFORCE
      ? `${fieldName.slice(2).charAt(0).toUpperCase()}${fieldName.slice(3)}`
      : fieldName.slice(2);
  }
  return fieldName;
}

export function slugFieldName(fieldObject: FieldDataModel): FieldDataModel {
  const newFieldObject = fieldObject;
  if (!newFieldObject.standard) {
    newFieldObject.name = `a_${newFieldObject.name}`;
  }
  newFieldObject.name = newFieldObject.name.toLowerCase();
  return newFieldObject;
}

export async function pullDatasourceObjects(
  tenant: number,
  datasource: string
) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/integrations/${datasource}/pull?ignore=true&slug=true`
  );

  return data.config;
}

export async function getConnectorFields(
  tenant: number,
  connector: DataSources,
  arrayOfObjects: string[]
) {
  const datasourceObjects =
    connector === 'hubspot'
      ? await pullDatasourceObjects(tenant, connector)
      : undefined;
  const promisesOfFields = arrayOfObjects.map(async (object) => {
    const obj: UnknownObject = {};
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    obj[object] = await getConnectorPullConfigFields(
      tenant,
      connector,
      object,
      datasourceObjects
    );
    return obj;
  });
  const fields = await Promise.all(promisesOfFields);
  return fields;
}

export function convertAndSlugObjectsToConnectorFieldsNames(
  objects: any[]
): ConnectorFieldNameDataModel[] {
  return map(objects, (item: any, key: string) => {
    const fields: FieldDataModel[] = item.attributes.map((field: any) => {
      const isNameAttribute = field.name.toLowerCase() === 'name';
      if (isNameAttribute) {
        const isAccount = key === DATASOURCE_OBJECTS.salesforce[0];
        return slugFieldName(
          new FieldDataModel(
            field.name,
            isAccount ? !!field.standard : false,
            !!field.ignore
          )
        );
      }
      return slugFieldName(
        new FieldDataModel(field.name, !!field.standard, !!field.ignore)
      );
    });
    return new ConnectorFieldNameDataModel({
      fields,
      title: key,
    });
  });
}

export async function extractFieldNamesFromObjects(
  tenant: number,
  datasource: string,
  datasourceObjects?: any[]
): Promise<ConnectorFieldNameDataModel[]> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const objects: any[] =
    datasourceObjects ?? (await pullDatasourceObjects(tenant, datasource));
  return convertAndSlugObjectsToConnectorFieldsNames(objects);
}

export function sortFieldsByAlphabeticOrder(
  fields: FieldDataModel[]
): FieldDataModel[] {
  return fields.sort(
    (a, b) =>
      unslugFieldName(a.name).charCodeAt(0) -
      unslugFieldName(b.name).charCodeAt(0)
  );
}

export function sortConnectorFieldNames(
  connectorFieldNames: ConnectorFieldNameDataModel[]
): ConnectorFieldNameDataModel[] {
  return connectorFieldNames.map((connectorFieldName) => {
    const sortedConnectorFieldName = connectorFieldName;
    sortedConnectorFieldName.fields = sortFieldsByAlphabeticOrder(
      sortedConnectorFieldName.fields
    );
    return sortedConnectorFieldName;
  });
}

export async function extractFieldNamesFromObjectsByDatasource(
  tenant: number,
  dataSource: DataSources,
  datasourceObjects?: any[]
): Promise<ConnectorFieldNameDataModel[]> {
  let dataSourceValue = dataSource;
  if (
    dataSource === DataSources.salesforce_tasks ||
    dataSource === DataSources.salesforce_campaigns
  ) {
    // eslint-disable-next-line no-param-reassign
    dataSourceValue = DataSources.salesforce;
  }
  const fieldNames =
    (await extractFieldNamesFromObjects(
      tenant,
      dataSourceValue,
      datasourceObjects
    )) || [];
  return sortConnectorFieldNames(fieldNames);
}

export function getFieldNamesFromObject(
  object: string,
  stateFieldNames: ConnectorFieldNameDataModel[],
  connector: string
): FieldDataModel[] {
  let fieldNamesResult: FieldDataModel[] =
    stateFieldNames?.find(
      ({ title }) => title.toLowerCase() === object.toLowerCase()
    )?.fields ?? [];
  // DATASOURCE_OBJECTS.hubspot[0] = 'Contact'
  // eslint-disable-next-line
  if (!object) object = DATASOURCE_OBJECTS.hubspot[0];
  // DATASOURCE_OBJECTS.salesforce[2] = 'Lead / Contact'
  if (object === DATASOURCE_OBJECTS.salesforce[2]) {
    const leadFieldNames = stateFieldNames.filter(
      (v) => v.title.toLowerCase() === 'lead'
    )[0].fields;
    const contactFieldNames = stateFieldNames.filter(
      (v) => v.title.toLowerCase() === 'contact'
    )[0].fields;
    fieldNamesResult = leadFieldNames.concat(contactFieldNames);
  }
  if (connector === 'hubspot') {
    if (object.startsWith('e_')) {
      fieldNamesResult = stateFieldNames.filter(
        (v) => v.title.toLowerCase() === 'web_event'
      )[0].fields;
    }
    if (object === 'form_submission') {
      fieldNamesResult = stateFieldNames.filter(
        (v) => v.title.toLowerCase() === 'form-submissions'
      )[0].fields;
    }
    if (object === 'click' || object === 'statuschange') {
      fieldNamesResult = stateFieldNames.filter(
        (v) => v.title.toLowerCase() === 'event'
      )[0].fields;
    }
  }

  // DATASOURCE_OBJECTS.salesforce[0] = 'Account'
  if (object === DATASOURCE_OBJECTS.salesforce[0]) {
    fieldNamesResult = fieldNamesResult.map((fieldName) => {
      if (fieldName.name === 'name') {
        return new FieldDataModel('a_name', false);
      }
      return fieldName;
    });
  }

  return [
    ...new Set(
      fieldNamesResult
        .sort()
        .map((field: FieldDataModel) => ({ ...field, ignore: false }))
    ),
  ];
}

export async function getConnectorPullConfigFields(
  tenant: number,
  connector: DataSources,
  connectorObjects: string,
  datasourceObjects?: any[]
) {
  const pullFields = await extractFieldNamesFromObjectsByDatasource(
    tenant,
    connector,
    datasourceObjects
  );

  return getFieldNamesFromObject(connectorObjects, pullFields, connector);
}
