import Backbone from 'backbone';
import $ from 'jquery';

const template = require('./api.pug');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #copy_clip_btn': 'copy_API_clipboard',
  },

  initialize(options: { tenant: number; context: string }) {
    this.tenant = options.tenant;
    this.context = options.context;
    this.model.fetch({ success: () => this.render() });
  },

  copy_API_clipboard() {
    const result = this.model.attributes.api_key;
    const fake_area = document.createElement('textarea');
    document.body.appendChild(fake_area);
    fake_area.value = result;
    fake_area.select();
    document.execCommand('copy');
    document.body.removeChild(fake_area);
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    $('#integration_name h1').text('MadKudu API');
    this.applyBindings();
  },
});
