import React from 'react';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import { Button, FormControl, FormControlProps } from 'react-bootstrap';
import { TeamServiceModel, UserServiceModel } from '../types';
import { UserRoles } from '../../../types';
import { shouldShowReinviteButton } from '../utils';

interface UserRowProps {
  user: UserServiceModel;
  teams: TeamServiceModel[];
  isAllowedToDelete: boolean;
  onUserRoleChange: (newUserRole: UserRoles) => Promise<void>;
  onUserTeamChange: (newTeam: string) => Promise<void>;
  onDeleteUserClick: () => void;
  onReinviteUserClick?: () => void;
}

export default function UserRow({
  user,
  teams,
  onUserRoleChange,
  onUserTeamChange,
  onDeleteUserClick,
  onReinviteUserClick,
  isAllowedToDelete,
}: UserRowProps) {
  const { email, first_name, last_name, role, team: currentTeam } = user;
  const showReinviteButton = shouldShowReinviteButton(user);

  return (
    <tr>
      <td>{email}</td>
      <td>{`${first_name ?? ''} ${last_name ?? ''}`}</td>
      <td>
        <FormControl
          name="team"
          componentClass="select"
          value={currentTeam}
          onChange={(event: React.ChangeEvent<FormControlProps>) =>
            onUserTeamChange(event.target.value as string)
          }
        >
          {teams.map((team) => (
            <option
              value={team._id}
              key={team._id}
              selected={team._id === currentTeam}
            >
              {team.name}
            </option>
          ))}
        </FormControl>
      </td>
      <td>
        <FormControl
          name="role"
          componentClass="select"
          value={role}
          onChange={(event: React.ChangeEvent<FormControlProps>) =>
            onUserRoleChange(event.target.value as UserRoles)
          }
        >
          {['admin', 'user', 'architect', 'salesUser'].map((userRole) => (
            <option value={userRole} key={userRole}>
              {upperFirst(lowerCase(userRole))}
            </option>
          ))}
        </FormControl>
      </td>
      {isAllowedToDelete && (
        <td className="d-flex gap-2">
          <Button className="btn btn-danger" onClick={onDeleteUserClick}>
            <i className="fa fa-trash"></i>
          </Button>
          {showReinviteButton && onReinviteUserClick && (
            <Button
              className="btn btn-primary mr-2"
              onClick={onReinviteUserClick}
              title="Resend invitation email"
            >
              <i className="fa fa-paper-plane"></i>
            </Button>
          )}
        </td>
      )}
    </tr>
  );
}
