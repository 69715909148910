import React from 'react';
import { Button, FormControl } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import ModelDataModel from '../../../models/model/ModelDataModel';
import launchAirflowDag from '../../../utils/launch_airflow_dag';

type DiagnosticsAudienceFilterHeaderComponentProps = {
  model: ModelDataModel;
  tenant: number;
  isSuperKudu: boolean;
  selectedConversionType: string;
  activeConversionTypes: string[];
  availableConversions: string[];
  handleOnConversionTypeChange: (conversionType: string) => void;
  audiencesNames: string[];
  availableAudiences: string[];
  selectedAudienceName: string;
  handleOnAudienceNameChange: (audienceName: string) => void;
};

export default class DiagnosticsAudienceFilterHeaderComponent extends React.Component<
  DiagnosticsAudienceFilterHeaderComponentProps,
  {}
> {
  constructor(props: DiagnosticsAudienceFilterHeaderComponentProps) {
    super(props);

    this.handleOnClickCompute = this.handleOnClickCompute.bind(this);
  }

  async handleOnClickCompute() {
    const { model, tenant } = this.props;
    const { type } = model;

    const dag: string =
      type === 'customer_fit'
        ? `customer_fit_performance_${tenant}`
        : `behavioral_performance_report_${tenant}`;
    await launchAirflowDag({
      tenant,
      dagName: dag,
      sectionName: 'diagnostics',
    });
  }

  render() {
    const {
      selectedConversionType,
      activeConversionTypes,
      availableConversions,
      handleOnConversionTypeChange,
      audiencesNames,
      selectedAudienceName,
      availableAudiences,
      handleOnAudienceNameChange,
      isSuperKudu,
    } = this.props;

    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d d-flex justify-content-between"
        aria-label="breadcrumb"
      >
        <ul className="navbar-nav mt-2 mt-lg-0 w-75">
          <span className="font-weight-bold breadcrumb-item mt-1 mb-0">
            Filters
          </span>
          <div className="d-flex justify-content-center">
            <p className="ml-3 mt-1 mb-0">Conversion</p>
            <FormControl
              className="ml-2 w-25"
              componentClass="select"
              value={selectedConversionType}
              onChange={(event: any) =>
                handleOnConversionTypeChange(event.target.value)
              }
            >
              {activeConversionTypes &&
                activeConversionTypes.map((conversionType, index) => {
                  return (
                    <option
                      key={`conversion_type_${conversionType}_${index}`}
                      disabled={!availableConversions.includes(conversionType)}
                    >
                      {conversionType}
                    </option>
                  );
                })}
            </FormControl>
            <p className="ml-4 mb-0 mt-1">Audience</p>
            <FormControl
              className="ml-2 w-25"
              componentClass="select"
              value={selectedAudienceName}
              onChange={(event: any) =>
                handleOnAudienceNameChange(event.target.value)
              }
            >
              {audiencesNames &&
                audiencesNames.map((audienceName, index) => {
                  return (
                    <option
                      key={`audience_name_${audienceName}_${index}`}
                      disabled={!availableAudiences.includes(audienceName)}
                    >
                      {audienceName}
                    </option>
                  );
                })}
            </FormControl>
          </div>
        </ul>
        <p className="font-italic mb-0 mt-1">
          Data is refreshed on a weekly basis
        </p>
        <ReactTooltip place="left" />
        {isSuperKudu && (
          <Button
            data-tip="Refresh this report"
            className="btn btn-primary my-2 my-lg-0 mx-1"
            id="compute"
            onClick={this.handleOnClickCompute}
          >
            <i className="fas fa-sync-alt"></i>
          </Button>
        )}
      </nav>
    );
  }
}
