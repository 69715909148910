import { z } from 'zod';

export const linkedinCredentialsSchema = z.object({
  companyUrl: z
    .string()
    // eslint-disable-next-line no-useless-escape
    .regex(/https:\/\/www\.linkedin\.com\/company\/([^\/]+)\/$/gm, {
      message: 'Invalid LinkedIn company URL',
    })
    .optional(),
});

export type LinkedinCredentials = z.infer<typeof linkedinCredentialsSchema>;
