import { z } from 'zod';

export const slackCredentialsSchema = z.object({
  botToken: z
    .string()
    .min(1, { message: 'Please provide a Slack User OAuth Token.' })
    .refine((v) => v.startsWith('xoxp-'), {
      message: 'Slack User OAuth Token must start with "xoxp-" prefix',
    }),
});

export const slackChannelsSchema = z.object({
  channels: z.array(z.string()),
});

export type SlackCredentials = z.infer<typeof slackCredentialsSchema>;

export const INVALID_SLACK_TOKEN_ERROR = 'Invalid Slack Bot OAuth Token';
export const INVALID_SLACK_TOKEN_SCOPES_ERROR =
  'Invalid Slack Bot OAuth Token Scopes';

export const EXPECTED_SLACK_SCOPES = [
  'channels:history',
  'channels:join',
  'channels:read',
  'files:read',
  'groups:read',
  'links:read',
  'reactions:read',
  'remote_files:read',
  'team:read',
  'usergroups:read',
  'users.profile:read',
  'users:read',
  'users:read.email',
];

export const KNOWN_SLACK_ERRORS = [
  INVALID_SLACK_TOKEN_ERROR,
  INVALID_SLACK_TOKEN_SCOPES_ERROR,
];
