import React from 'react';
import { ConnectedView } from './configuration-views/ConnectedView';
import { ConnectionView } from './configuration-views/ConnectionView';
import { useCognismIsConnected } from './hooks/useCognismIsConnected';

type Props = {
  tenant: number;
};

export function CognismConfiguration({ tenant }: Props) {
  const { data: isCognismConnected, isLoading } = useCognismIsConnected(tenant);

  if (isLoading) return null;
  if (!isCognismConnected) return <ConnectionView tenant={tenant} />;
  return <ConnectedView tenant={tenant} />;
}
