import React from 'react';

type ConnectorsOverviewCardComponentProps = {
  isActive: boolean;
  connector: string;
};

export default class ConnectorsOverviewCardComponent extends React.Component<
  ConnectorsOverviewCardComponentProps,
  {}
> {
  render() {
    const { connector } = this.props;

    return (
      <div className=" d-flex card-body py-auto justify-content-center align-self-center align-items-center con-map-mk-card-size">
        <img
          src={`/media/integrations/logos/${connector}.svg`}
          className="w-50"
        />
      </div>
    );
  }
}
