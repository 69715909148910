import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import $ from 'jquery';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import { BackboneViewProps } from '../types';
import FieldsMapping from './fields_mapping';
import { deleteIntegrationAndLaunchPopUp } from './utils';

const template = require('./marketo.pug');

const channel = Radio.channel('integration');

export default Backbone.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
    'click #mki_delete_connection': 'delete',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'marketo';
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });

    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  async render() {
    this.$el.empty();

    const credentialsFound = this.model.get('not_found') !== true;

    const params: UnknownObject = {
      client_id: null,
      client_secret: null,
      endpoint_url: this.model.get('endpoint_url'),
      identity_url: this.model.get('identity_url'),
      client_id_placeholder: credentialsFound
        ? '*****'
        : 'e.g. afd01957-110d-4155-99a7-f986b2ff13a0',
      client_secret_placeholder: credentialsFound
        ? '*****'
        : 'e.g. afd01957-110d-4155-99a7-f986b2ff13a0',
      endpoint_url_placeholder: 'e.g. http://100-AEK-913.mktorest.com/rest',
      identity_url_placeholder: 'e.g. http://100-AEK-913.mktorest.com/identity',
      credentialsFound,
    };

    const html = $('#tpl_main', template(params)).html();
    this.$el.append(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  async delete() {
    await deleteIntegrationAndLaunchPopUp(this.tenant, 'marketo');
  },

  event_save(event: any) {
    event.preventDefault();
    const credentials: UnknownObject = {};
    credentials.client_id = $('#mki_input_client_id').val();
    credentials.endpoint_url = $('#mki_input_endpoint_url').val();
    credentials.identity_url = $('#mki_input_identity_url').val();
    credentials.client_secret = $('#mki_input_client_secret').val();

    let validationError;

    if (!credentials.client_id || credentials.client_id === '') {
      validationError = 'Please enter a Client ID';
    } else if (!credentials.client_secret || credentials.client_secret === '') {
      validationError = 'Please enter a Client Secret';
    } else if (!credentials.endpoint_url || credentials.endpoint_url === '') {
      validationError = 'Please enter an Endpoint URL';
    } else if (!credentials.identity_url || credentials.identity_url === '') {
      validationError = 'Please enter an Identity URL';
    }

    if (validationError) {
      return Swal.fire({
        title: 'Error',
        text: validationError,
        icon: 'error',
      });
    }

    const show_error_message = function (message: string) {
      Swal.fire({
        title: 'Internal error',
        text: message,
        icon: 'error',
      });
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true&activate_push=true`,
        credentials
      )
      .then(() => {
        channel.trigger('success', { integration: this.integration });
      })
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  },
});
