import Backbone from 'backbone';
import unionBy from 'lodash/unionBy';
import { BackboneViewProps } from '../types';

require('backbone.epoxy');

const DEFAULT_FLAGS = [
  'realtime',
  'gdpr_disable_persons',
  'disable_computations',
  'demo',
  'onboarding_intercom',
  'onboarding_hubspot',
  'onboarding_googlesheet',
  'behavioral',
  'predictleads_active',
];

export const Flag_model = (Backbone as any).Epoxy.Model.extend({
  idAttribute: 'name',
  initialize(properties: BackboneViewProps) {
    this.tenant =
      properties.tenant ||
      (this.collection ? this.collection.tenant : undefined);
    this.name = properties.name;
  },
  urlRoot() {
    const tenant = this.get('tenant') || this.tenant;
    return `${BONGO_URL}/v1/org/${tenant}/flags`;
  },
  parse(response: any) {
    return response || {};
  },
  defaults: {
    active: false,
  },
  computeds: {
    options_json: {
      get() {
        const options = this.get('options');
        return JSON.stringify(options, null, 2) || '';
      },
      set(value: any) {
        const options = JSON.parse(value);
        if (options) {
          return { options };
        }
      },
    },
  },
});

export const Flag_collection = Backbone.Collection.extend({
  model: Flag_model,
  url() {
    return `${BONGO_URL}/v1/org/${this.tenant}/flags`;
  },
  parse(response: any) {
    // merge the list of standard flags with what's available in the API results
    const default_flags = DEFAULT_FLAGS.map((item) => {
      return { name: item };
    });
    return unionBy(response, default_flags, 'name');
  },
  initialize(properties: BackboneViewProps) {
    this.tenant = properties.tenant;
  },
});
