import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import FieldsMapping from './fields_mapping';
import test_connection from './test_connection';
import { deleteIntegrationAndLaunchPopUp } from './utils';

const channel = Radio.channel('integration');
const template = require('./eloqua.pug');

// OAuth is commented because it is not currently available (no developer instance)

export default Backbone.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
    'click #mki_delete_connection': 'delete',
  },

  async initialize(options: { tenant: number; context: string }) {
    this.tenant = options.tenant;
    this.integration = 'eloqua';
    this.context = options.context;
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();

    const credentialsFound = this.model.get('not_found') !== true;

    this.model.set({
      site_name: this.model.get('site_name'),
      username: this.model.get('username'),
      password: null,
      site_name_placeholder: 'e.g. yourcompany',
      username_placeholder: 'e.g. yourcompany',
      password_placeholder: credentialsFound
        ? '*****'
        : 'e.g. mypasswordXXXXXXXXXX',
      credentialsFound,
    });

    const html = $(
      '#tpl_main',
      template({
        credentialsFound,
        integration: this.integration,
      })
    ).html();
    this.$el.html(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    if (this.model.get('refresh_token')) {
      this.render_oauth_connected();
    } else {
      // this.render_oauth()
      this.render_classic();
    }
  },

  async delete() {
    await deleteIntegrationAndLaunchPopUp(this.tenant, 'eloqua');
  },

  render_classic() {
    const credentials = this.model.attributes;
    const html = $(
      '#tpl_classic',
      template({
        integration: this.integration,
        ...credentials,
      })
    ).html();
    $('#mki_classic').append(html);
    return this;
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();

    const credentials: UnknownObject = {};
    credentials.site_name = $('#mki_input_site_name').val();
    credentials.username = $('#mki_input_username').val();
    credentials.password = $('#mki_input_password').val();

    let validationError;
    if (!credentials.site_name || credentials.site_name === '') {
      validationError = 'Please enter a Site Name';
    } else if (!credentials.username || credentials.username === '') {
      validationError = 'Please enter a username';
    } else if (!credentials.password || credentials.password === '') {
      validationError = 'Please enter a password';
    }

    if (validationError) {
      return Swal.fire({
        title: 'Error',
        text: validationError,
        icon: 'error',
      });
    }

    const show_error_message = (message: string, title: string) => {
      Swal.fire({
        title: title || 'Internal error',
        text: message,
        icon: 'error',
      });
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true&activate_push=true`,
        credentials
      )
      .then(() => {
        return test_connection(this.tenant, this.integration)
          .then(() =>
            channel.trigger('success', {
              integration: this.integration,
              alert: true,
            })
          )
          .catch((err: any) =>
            show_error_message(err.message, 'Unable to connect to Eloqua')
          );
      })
      .catch((err: any) => {
        const error = err.response && err.response.data;
        show_error_message(error.message, 'Unable to save your credentials');
      });
  },
});
