import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { JobChangesWatchList } from '../integrations/job_changes/types';

const hasRequestedAccessStorageKey = (tenant: number, integration: string) =>
  `has-request-for-access-${integration}-${tenant}`;

export function useRequestIntegrationAccessMutation(
  tenant: number,
  integration: string
) {
  const [hasRequestedAccess, setHasRequestedAccess] = useState(
    localStorage.getItem(hasRequestedAccessStorageKey(tenant, integration)) ===
      'true'
  );

  return {
    hasRequestedAccess,
    ...useMutation({
      mutationFn: async () => {
        return axios.post<JobChangesWatchList>(
          `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/request-access`
        );
      },
      onSuccess: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        Swal.fire({
          title: 'Access requested',
          icon: 'success',
          text: 'You will be contacted shortly by your CSM.',
        });

        localStorage.setItem(
          hasRequestedAccessStorageKey(tenant, integration),
          'true'
        );
        setHasRequestedAccess(true);
      },
    }),
  };
}
