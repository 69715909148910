import React, { CSSProperties } from 'react';
import classnames from 'classnames';

type CircleBadgeProps = {
  variant: CircleBadgeColors;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
};

export enum CircleBadgeColors {
  GREEN = 'bg-success-100',
  RED = 'bg-red-100',
  BLUE = 'bg-primary-100',
  GRAY = 'bg-secondary-100',
}

export default function CircleBadge({
  variant,
  height,
  width,
}: CircleBadgeProps) {
  return (
    <div
      className={classnames({
        'rounded-circle': true,
        [CircleBadgeColors.GREEN]: variant === CircleBadgeColors.GREEN,
        [CircleBadgeColors.RED]: variant === CircleBadgeColors.RED,
        [CircleBadgeColors.BLUE]: variant === CircleBadgeColors.BLUE,
        [CircleBadgeColors.GRAY]: variant === CircleBadgeColors.GRAY,
      })}
      style={{ height: height ?? 14, width: width ?? 14 }}
    />
  );
}
