// @ts-ignore
import { UnControlled as CodeMirror } from 'react-codemirror2';

export function calculateNextPosition(
  editorChange: CodeMirror.EditorChange
): CodeMirror.Position {
  if (editorChange.to && editorChange.origin) {
    if (editorChange.to === editorChange.from) {
      if (editorChange.origin === '+delete') {
        return {
          ch: editorChange.to.ch - editorChange.removed?.length,
          line: editorChange.to.line,
          sticky: editorChange.to.sticky,
        };
      }
      if (editorChange.origin === 'paste') {
        return {
          ch: editorChange.to.ch + editorChange.text[0].length,
          line: editorChange.to.line,
          sticky: editorChange.to.sticky,
        };
      }
      // +Enter(Keyboard)
      if (editorChange.origin === '+input' && editorChange.text.length > 1) {
        return {
          ch: editorChange.to.ch,
          line: editorChange.to.line + 1,
          sticky: editorChange.to.sticky,
        };
      }
      return {
        ch: editorChange.to.ch + editorChange.text[0].length,
        line: editorChange.to.line,
        sticky: editorChange.to.sticky,
      };
    }
    return {
      ch: editorChange.from.ch,
      line: editorChange.from.line,
      sticky: editorChange.to.sticky,
    };
  }
}
