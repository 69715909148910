import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import ActiveIntegrationDataModel from '../event_mapping/models/ActiveIntegrationDataModel';
import {
  ConnectorsNames,
  ConversionMappingConnectorsNames,
  EventMappingConnectorsNames,
  MappingConversionsTypes,
} from '../../types';
import { getAllActiveIntegrations } from '../utils';
import { ConversionMappingData } from './models/ConversionMappingData';
import { ConversionTypesGroup } from './models/ConversionTypesGroup';

const CONVERSION_MAPPING_INTEGRATIONS: ConversionMappingConnectorsNames[] = [
  'salesforce',
  'hubspot',
  'stripe',
  'analytics',
];

const NUMBER_OF_ADDITION_FOR_INDEX = 10;

export function removeDuplicatedValuesFromActiveIntegrations(
  activeIntegrations: ActiveIntegrationDataModel[]
): ConnectorsNames[] {
  const activeIntegrationsWithoutDuplications: ConnectorsNames[] = [];
  activeIntegrations.forEach((activeIntegration) => {
    if (
      activeIntegrationsWithoutDuplications.indexOf(
        activeIntegration.name as ConnectorsNames
      ) === -1
    ) {
      activeIntegrationsWithoutDuplications.push(
        activeIntegration.name as ConnectorsNames
      );
    }
  });
  return activeIntegrationsWithoutDuplications;
}

export function sortActiveIntegrationsByName(
  activeIntegrationsWithoutDuplications: string[]
): ConnectorsNames[] {
  return activeIntegrationsWithoutDuplications.sort((a: string, b: string) => {
    return a.charCodeAt(0) - b.charCodeAt(0);
  }) as ConnectorsNames[];
}

export async function getConversionMappingActiveIntegrations(
  tenant: number
): Promise<ConnectorsNames[]> {
  const allActiveIntegrations: ActiveIntegrationDataModel[] = await getAllActiveIntegrations(
    tenant
  );
  const analyticsConnectors: EventMappingConnectorsNames[] = [
    'kissmetrics',
    'mixpanel',
    'amplitude',
    'segment',
  ];

  const conversionMappingActiveIntegrations = allActiveIntegrations
    .filter((e) => e)
    .filter((activeIntegration) => {
      if (
        analyticsConnectors.includes(
          activeIntegration.name as EventMappingConnectorsNames
        )
      ) {
        // eslint-disable-next-line no-param-reassign
        activeIntegration.name = 'analytics'; // Conversion mapping only knows analytics
      }
      return CONVERSION_MAPPING_INTEGRATIONS.includes(
        activeIntegration.name as ConversionMappingConnectorsNames
      );
    });
  const activeIntegrationsWithoutDuplications: ConnectorsNames[] = removeDuplicatedValuesFromActiveIntegrations(
    conversionMappingActiveIntegrations
  );
  return sortActiveIntegrationsByName(activeIntegrationsWithoutDuplications);
}

export const conversionTypeCodeColor: {
  [key in MappingConversionsTypes]: string;
} = {
  SQO: 'success',
  'Closed Won': 'primary',
  'Open Opp': 'dark',
};

export function getAmountFieldByConnector(
  connector: ConnectorsNames
): 'Deal' | 'Opportunity' | 'invoice' {
  if (connector === 'hubspot') {
    return 'Deal';
  }
  if (connector === 'salesforce') {
    return 'Opportunity';
  }
  if (connector === 'stripe') {
    return 'invoice';
  }
}

export function conversionMappingDataUrl(tenant: number) {
  return `${BONGO_URL}/v1/org/${tenant}/data/mappings/conversion`;
}

/**
 * Get the conversion mapping data from Bongo
 * @param tenant
 * @returns {Promise<*[]|any>}
 */
export async function getConversionMappingData(tenant: number) {
  try {
    const url = conversionMappingDataUrl(tenant);
    const res = await axios.get(url);
    return res.data;
  } catch (e) {
    return [];
  }
}

export const DEFAULT_CONVERSION_CUSTOM_DEFINITION_NAME =
  'custom conversion definition';

/**
 * Create default('SQO', 'Closed Won', 'Open Opp') conversionTypesGroups if doesn't exist
 * Function only used to format the conversionMappingData for display purpose
 * @param conversionMappingData
 */
export function prepareConversionMappingDataDisplay(
  conversionMappingData: ConversionMappingData
): ConversionMappingData {
  const hasSqoGroup: boolean = conversionMappingData.conversionTypesGroups.some(
    (conversionTypeGroup) => conversionTypeGroup.type === 'SQO'
  );
  const hasClosedWonGroup: boolean = conversionMappingData.conversionTypesGroups.some(
    (conversionTypeGroup) => conversionTypeGroup.type === 'Closed Won'
  );
  const hasOpenOppGroup: boolean = conversionMappingData.conversionTypesGroups.some(
    (conversionTypeGroup) => conversionTypeGroup.type === 'Open Opp'
  );
  if (!hasSqoGroup) {
    conversionMappingData.conversionTypesGroups.push(
      new ConversionTypesGroup(
        conversionMappingData.tenant,
        conversionMappingData.email,
        'SQO',
        false,
        'standard',
        conversionMappingData.conversionTypesGroups.length,
        'SQO',
        conversionMappingData.parametersFieldsNames
      )
    );
  }
  if (!hasClosedWonGroup) {
    conversionMappingData.conversionTypesGroups.push(
      new ConversionTypesGroup(
        conversionMappingData.tenant,
        conversionMappingData.email,
        'Closed Won',
        false,
        'standard',
        conversionMappingData.conversionTypesGroups.length,
        'Closed Won',
        conversionMappingData.parametersFieldsNames
      )
    );
  }
  if (!hasOpenOppGroup) {
    conversionMappingData.conversionTypesGroups.push(
      new ConversionTypesGroup(
        conversionMappingData.tenant,
        conversionMappingData.email,
        'Open Opp',
        false,
        'standard',
        conversionMappingData.conversionTypesGroups.length,
        'Open Opp',
        conversionMappingData.parametersFieldsNames
      )
    );
  }
  return conversionMappingData;
}

export function generateUniqIndex(): number {
  return Math.floor(Math.random() * 100 * Date.now());
}

/**
 * This function aim to modify all the duplicated indexes of the conversionTypeGroups to avoid
 * bad values affectations
 *
 * A basic algorithm to avoid having duplicated indexes for groups -- Crucial (can be optimized)
 * complexity of algirithm is O(n*n) where n is the number of conversionTypesGroup.
 * @param conversionTypesGroups: ConversionTypesGroup[]
 */
export function fixDuplicatedIndexes(
  conversionTypesGroups: ConversionTypesGroup[]
): ConversionTypesGroup[] {
  let isThereIsDuplicatedIndex: boolean = true;
  let conversionTypesGroupsWithoutDuplication = conversionTypesGroups;

  // We do not stop until there is a duplicated index
  while (isThereIsDuplicatedIndex) {
    // We get the array of indexes
    const arrayOfIndex = conversionTypesGroupsWithoutDuplication.map(
      (conversionTypeGroup) => conversionTypeGroup.index
    );
    // We search for a duplicated element
    const conversionTypeGroupIndexToReplace: number = arrayOfIndex.find(
      (element, index) => {
        const tempArray = arrayOfIndex;
        // we remove the wanted element from the array
        tempArray.splice(index, 1);
        // We search for the removed element - if exist -> duplicated
        return tempArray?.includes(element);
      }
    );
    // If it's not undefined - is number
    if (Number.isInteger(conversionTypeGroupIndexToReplace)) {
      conversionTypesGroupsWithoutDuplication = conversionTypesGroupsWithoutDuplication.map(
        (conversionTypeGroup) => {
          if (conversionTypeGroup.index === conversionTypeGroupIndexToReplace) {
            const newConversionTypeGroup = conversionTypeGroup;
            // replace duplicated index
            newConversionTypeGroup.index =
              newConversionTypeGroup.getMaxIndex() +
              Math.floor(Math.random() * NUMBER_OF_ADDITION_FOR_INDEX);
            return newConversionTypeGroup;
          }
          return conversionTypeGroup;
        }
      );
      // Iter again
      isThereIsDuplicatedIndex = true;
    } else {
      // Generate uniq ID for each indexedConversionTypeData
      conversionTypesGroupsWithoutDuplication = conversionTypesGroupsWithoutDuplication.map(
        (conversionTypeGroup) => {
          const cleanedConversionTypeGroup = cloneDeep(conversionTypeGroup);
          cleanedConversionTypeGroup.indexedConversionTypesData = conversionTypeGroup.indexedConversionTypesData.map(
            (indexedConversionTypeData) => ({
              ...indexedConversionTypeData,
              index: generateUniqIndex(),
            })
          );
          return cleanedConversionTypeGroup;
        }
      );
      // We are good to go
      isThereIsDuplicatedIndex = false;
    }
  }
  return conversionTypesGroupsWithoutDuplication;
}
