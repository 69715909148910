import * as React from 'react';

export type DataType =
  | 'export_contacts'
  | 'export_accounts'
  | 'export_campaign_events';

type DataTypeSelectProps = {
  value: DataType;
  onChange: (event: any) => void;
};

function DataTypeSelect({
  value,
  onChange,
}: DataTypeSelectProps): React.ReactElement {
  return (
    <select
      className="form-control"
      id="dataType"
      value={value}
      onChange={onChange}
      required
    >
      <option value="export_contacts">export_contacts</option>
      <option value="export_accounts">export_accounts</option>
      <option value="export_campaign_events">export_campaign_events</option>
    </select>
  );
}

export default DataTypeSelect;
