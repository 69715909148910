import {
  CursorConfig,
  GaugeConfig,
  SegmentsConfig,
  TicksConfig,
  TooltipsConfig,
  ValueConfig,
} from './types';

export const DEFAULT_GAUGE_CONFIG: GaugeConfig = {
  diameter: 400,
  maxCircleAngle: 0.6,
};

export const DEFAULT_TOOLTIP_CONFIG: TooltipsConfig = {
  margin: 15,
  paddingX: 5,
  paddingY: 3,
  bgColor: '#002e52',
  color: 'white',
  borderRadius: 3,
};

export const DEFAULT_SEGMENT_CONFIG: SegmentsConfig = {
  gap: 3,
  width: 15,
  cornerRadius: 10,
};

export const DEFAULT_CURSOR_CONFIG: CursorConfig = {
  margin: 10,
  size: 13,
  color: '#002e52',
  transition: 1000,
};

export const DEFAULT_TICK_CONFIG: TicksConfig = {
  margin: 15,
  color: '#51596B',
};

export const DEFAULT_VALUE_CONFIG: ValueConfig = {
  fontSize: 64,
  fontWeight: 600,
  color: '#2E2F2F',
  bottom: 0,
  tagPaddingX: 15,
  tagPaddingY: 0,
  tagBorderRadius: 5,
  tagFontSize: 24,
  tagFontWeight: 600,
  tagMarginY: 15,
};
