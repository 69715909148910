import axios from 'axios';
import AbstractMadMlMapping from './AbstractMadMlMapping';
import { getEventMappingMadMlData } from '../event_mapping/utils';

export default class MadMlEventMapping extends AbstractMadMlMapping {
  async createMadMlData(madMlSqlQuery: string): Promise<void> {
    const objectToSend = await this.prepareMadMlData(madMlSqlQuery);
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/events/madMl`;
    await axios.put(url, { ...objectToSend });
  }

  getLiveMadMlQueryFromHistory(madMlHistory: any[]) {
    return madMlHistory.filter((madMl) => {
      return madMl.live;
    })[0]?.madMlSqlQuery;
  }

  async getMadMlData(): Promise<string> {
    const { madMlSqlQuery } = await getEventMappingMadMlData(this.tenant);
    return madMlSqlQuery as string;
  }

  async prepareMadMlData(madMlSqlQuery: string): Promise<any> {
    return this.createMadMlObjectToSend(madMlSqlQuery);
  }

  createMadMlObjectToSend(madMlSqlQuery: string): any {
    const indentedMadMlSqlQuery = madMlSqlQuery
      .split('\n')
      .map((lane) => `      ${lane.trim()}`)
      .join('\n');

    const disabledConnectors = [
      'hubspot',
      'salesforce_campaigns',
      'salesforce_tasks',
      'marketo',
      'mixpanel',
      'kissmetrics',
      'amplitude',
      'segment',
    ];

    return {
      disabledConnectors,
      tenant: this.tenant,
      description: `MadML configuration for ${this.tenant} - event mapping`,
      email: this.email,
      isCustom: true,
      madMlSqlQuery: indentedMadMlSqlQuery,
    };
  }
}
