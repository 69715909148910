import uniq from 'lodash/uniq';
import { ParametersFieldsNamesDataModel } from '../../models/ConnectorFieldNameDataModel';
import { AttributeMappingConnectorsNames } from '../../../types';
import { extractFieldNamesFromObjectsByDatasource } from '../../pullConfigManager';
import DataSources from '../../enums/DataSources';
import ActiveIntegrationDataModel from '../../event_mapping/models/ActiveIntegrationDataModel';
import { AttributeMappingFilterForm } from './filters_forms/AttributeMappingFilterForm';
import {
  FIELD_PLACEHOLDER_VALUE,
  OBJECT_PLACEHOLDER_VALUE,
} from '../../constants';
import { getAllActiveIntegrations } from '../../utils';

const MAX_FIELD_NAME_LENGTH = 63;

const AttributeMappingIntegrationsValues: Record<
  AttributeMappingConnectorsNames,
  number
> = {
  salesforce: 0,
  hubspot: 1,
  marketo: 2,
  s3: 3,
  bigquery: 4,
  snowflake: 5,
  madMl: 0,
};

export interface AttributeMappingDataOptions {
  createdAt: number;

  updatedAt: number;

  filtersForms: AttributeMappingFilterForm[];
}

type AttributeMappingDataError = {
  index: number;

  connector: AttributeMappingConnectorsNames;

  property:
    | 'domainField'
    | 'table'
    | 'field'
    | 'attribute name duplicate'
    | 'attribute name too long'
    | 'leadField';
};

export class AttributeMappingData {
  createdAt: number;

  updatedAt: number;

  tenant: number;

  email: string;

  parametersFieldsNames: ParametersFieldsNamesDataModel;

  activeIntegrations: AttributeMappingConnectorsNames[];

  filtersForms: AttributeMappingFilterForm[];

  errors: AttributeMappingDataError[];

  isFixing: boolean;

  constructor(
    tenant: number,
    email: string,
    options?: AttributeMappingDataOptions
  ) {
    this.tenant = tenant;
    this.email = email;
    this.activeIntegrations = [];
    this.isFixing = false;
    this.parametersFieldsNames = {
      salesforce: [],
      hubspot: [],
    };
    this.errors = [];
    this.build(options);
  }

  async init() {
    if (this.activeIntegrations?.length === 0) {
      await this.getActiveIntegrations();
    }

    await this.initParametersFieldsNames();

    if (this.filtersForms?.length === 0) {
      this.buildForms();
    }
  }

  build(options: AttributeMappingDataOptions) {
    if (options?.createdAt) {
      this.createdAt = options.createdAt;
    } else {
      this.createdAt = Date.now();
    }
    if (options?.updatedAt) {
      this.updatedAt = options.updatedAt;
    } else {
      this.updatedAt = Date.now();
    }
    if (options?.filtersForms) {
      this.filtersForms = options.filtersForms.map((filterForm) => {
        let { leadFields } = filterForm;
        if (
          filterForm.connector === 'salesforce' &&
          !filterForm.leadFields?.length
        ) {
          // 'a_convertedaccountid' is the default unremovable lead field
          leadFields = ['a_convertedaccountid'];
        }
        return new AttributeMappingFilterForm(
          this.tenant,
          filterForm.connector,
          this.parametersFieldsNames[filterForm.connector],
          {
            createdAt: filterForm.createdAt,
            domainFields: filterForm.domainFields,
            leadFields,
            forms: filterForm.forms,
            updatedAt: filterForm.updatedAt,
          }
        );
      });
      this.addFormForNewIntegrationsIfExists();
    } else {
      this.filtersForms = [];
    }
  }

  async initParametersFieldsNames(): Promise<void> {
    const listOfPromises = this.activeIntegrations.map((activeIntegration) => {
      return extractFieldNamesFromObjectsByDatasource(
        this.tenant,
        activeIntegration as DataSources
      );
    });
    const listOfConnectorFieldsNames = await Promise.all(listOfPromises);
    listOfConnectorFieldsNames.forEach((connectorFieldNames, index) => {
      this.parametersFieldsNames[
        this.activeIntegrations[index]
      ] = connectorFieldNames;
    });
  }

  async getActiveIntegrations() {
    const allActiveIntegrations: ActiveIntegrationDataModel[] = await getAllActiveIntegrations(
      this.tenant
    );
    this.activeIntegrations = allActiveIntegrations
      .filter((activeIntegration) =>
        [
          'salesforce',
          'hubspot',
          's3',
          'marketo',
          'bigquery',
          'snowflake',
        ].includes(activeIntegration?.name)
      )
      .map(
        (activeIntegration) =>
          activeIntegration.name as AttributeMappingConnectorsNames
      )
      .sort(
        (a, b) =>
          AttributeMappingIntegrationsValues[a] -
          AttributeMappingIntegrationsValues[b]
      );
  }

  buildForms() {
    this.activeIntegrations.forEach((activeIntegration) => {
      const filterForm: AttributeMappingFilterForm = new AttributeMappingFilterForm(
        this.tenant,
        activeIntegration,
        this.parametersFieldsNames[activeIntegration]
      );
      this.filtersForms.push(filterForm);
    });
  }

  addFormForNewIntegrationsIfExists() {
    const connectorsWithoutForm = this.activeIntegrations.filter(
      (activeIntegration) => {
        return !this.filtersForms.some(
          (filterForm) => filterForm.connector === activeIntegration
        );
      }
    );
    if (connectorsWithoutForm?.length > 0) {
      connectorsWithoutForm.forEach((connector) => {
        const filterForm: AttributeMappingFilterForm = new AttributeMappingFilterForm(
          this.tenant,
          connector,
          this.parametersFieldsNames[connector]
        );
        this.filtersForms.push(filterForm);
      });
    }
  }

  getFilterForm(
    connector: AttributeMappingConnectorsNames
  ): AttributeMappingFilterForm {
    return this.filtersForms.find(
      (filterForm) => filterForm.connector === connector
    );
  }

  setTable(
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    newTable: string
  ): AttributeMappingData {
    this.filtersForms.find(
      (filterForm) => filterForm.connector === connector
    ).forms[formIndex].table = newTable;
    const newField = this.filtersForms
      .find((filterForm) => filterForm.connector === connector)
      .getFields(newTable)[0];
    this.setField(connector, formIndex, newField.name);
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  setField(
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    newField: string
  ): AttributeMappingData {
    this.filtersForms.find(
      (filterForm) => filterForm.connector === connector
    ).forms[formIndex].field = newField;
    const { table } = this.filtersForms.find(
      (filterForm) => filterForm.connector === connector
    ).forms[formIndex];
    const generatedFieldName = this.generateFieldName(
      connector,
      table,
      newField
    );
    this.setFieldName(connector, formIndex, generatedFieldName);
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  generateFieldName(
    connector: AttributeMappingConnectorsNames,
    table: string,
    field: string
  ) {
    const cleanedField = field.replace(/(^a_)|(__c)/gi, '');
    return `${connector}_${table}_${cleanedField}`.toLowerCase();
  }

  setFieldName(
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    newFieldName: string
  ): AttributeMappingData {
    this.filtersForms.find(
      (filterForm) => filterForm.connector === connector
    ).forms[formIndex].attributeFieldName = newFieldName;
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  removeForm(
    connector: AttributeMappingConnectorsNames,
    formIndex: number
  ): AttributeMappingData {
    this.filtersForms
      .find((filterForm) => filterForm.connector === connector)
      .forms.splice(formIndex, 1);
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  addForm(connector: AttributeMappingConnectorsNames) {
    this.filtersForms
      .find((filterForm) => filterForm.connector === connector)
      .addDefaultForm();
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  setDomainFields(newDomainFields: string[]) {
    this.filtersForms.find(
      (filterForm) => filterForm.connector === 'salesforce'
    ).domainFields = newDomainFields;

    this.updatedAt = Date.now();

    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  setLeadFields(newLeadFields: string[]) {
    this.filtersForms.find(
      (filterForm) => filterForm.connector === 'salesforce'
    ).leadFields = newLeadFields;

    this.updatedAt = Date.now();

    if (this.isFixing) {
      this.checkIfConfigurationIsValid();
    }
    return this;
  }

  checkIfConfigurationIsValid() {
    this.errors = [];
    this.checkIfFieldNamesAreNotDuplicated();
    this.checkIfInputsAreCorrect();
    this.checkAttributeFieldNameLength();
    return this;
  }

  checkIfInputsAreCorrect() {
    this.activeIntegrations.forEach((connector) => {
      const connectorFilterForm = this.filtersForms.find(
        (filterForm) => filterForm.connector === connector
      );
      if (connector === 'salesforce') {
        if (connectorFilterForm.domainFields.length === 0) {
          this.errors.push({
            property: 'domainField',
            // using -1 as index as the error is not on a specific domain field
            index: -1,
            connector,
          });
        }

        if (connectorFilterForm.leadFields.length === 0) {
          this.errors.push({
            property: 'leadField',
            // using -1 as index as the error is not on a specific lead field
            index: -1,
            connector,
          });
        }
      }
      connectorFilterForm.forms.forEach((form, index) => {
        if ([OBJECT_PLACEHOLDER_VALUE, ''].includes(form.table?.trim())) {
          this.errors.push({
            property: 'table',
            index,
            connector,
          });
        }
        if ([FIELD_PLACEHOLDER_VALUE, ''].includes(form.field?.trim())) {
          this.errors.push({
            property: 'field',
            index,
            connector,
          });
        }
      });
    });
  }

  checkIfFieldNamesAreNotDuplicated() {
    this.activeIntegrations.forEach((connector) => {
      const connectorFilterForm = this.filtersForms.find(
        (filterForm) => filterForm.connector === connector
      );
      const attributeFieldNames: string[] = connectorFilterForm?.forms?.map(
        (form) => form.attributeFieldName
      );
      const duplicateAttributeFieldNames = attributeFieldNames?.filter(
        (fieldName) =>
          attributeFieldNames.filter(
            (subFieldName) => subFieldName === fieldName
          ).length > 1
      );
      const cleanDuplicateAttributeFieldNames = uniq(
        duplicateAttributeFieldNames
      );
      if (cleanDuplicateAttributeFieldNames.length > 0) {
        cleanDuplicateAttributeFieldNames.forEach((fieldNameDuplicated) => {
          connectorFilterForm?.forms.forEach((form, index) => {
            if (form.attributeFieldName === fieldNameDuplicated) {
              this.errors.push({
                property: 'attribute name duplicate',
                index,
                connector,
              });
            }
          });
        });
      }
    });
  }

  checkAttributeFieldNameLength() {
    this.activeIntegrations.forEach((connector) => {
      const connectorFilterForm = this.filtersForms.find(
        (filterForm) => filterForm.connector === connector
      );
      connectorFilterForm.forms.forEach((form, index) => {
        if (form.attributeFieldName.length > MAX_FIELD_NAME_LENGTH) {
          this.errors.push({
            property: 'attribute name too long',
            index,
            connector,
          });
        }
      });
    });
  }

  hasErrorOnProperty(
    connector: AttributeMappingConnectorsNames,
    formIndex: number,
    property: AttributeMappingDataError['property']
  ): boolean {
    return this.errors.some(
      (error) =>
        error.property === property &&
        error.connector === connector &&
        error.index === formIndex
    );
  }

  prepare() {
    delete this.parametersFieldsNames;
    this.filtersForms.forEach((filterForm) => {
      filterForm.prepare();
    });
  }
}
