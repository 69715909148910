import * as React from 'react';
import svgList from './svg';

interface Props extends React.HTMLAttributes<SVGElement> {
  name: IconName;
  height?: number;
  width?: number;
  style?: React.CSSProperties;
  className?: string;
}

export const Icon = function Input(props: Props) {
  const {
    name,
    height = 22,
    width = 22,
    style = {},
    className = '',
    ...rest
  } = props;
  const SVGIcon = svgList[name];
  return (
    <SVGIcon
      height={height}
      width={width}
      style={{ ...style }}
      className={`tw-shrink-0 ${className}`}
      {...rest}
    />
  );
};

export type IconName = keyof typeof svgList;
