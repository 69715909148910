import { useQuery } from '@tanstack/react-query';
import { getConnectorFields } from '../../../../pullConfigManager';
import {
  CONNECTORS_NAMES,
  CONNECTOR_OBJECT_PULL_CONFIG,
} from '../../../constants';
import { getEventsValuesFieldsByConnector } from '../../generic/utils';

enum DataSources {
  salesforce = 'salesforce',
  salesforce_tasks = 'salesforce_tasks',
  salesforce_campaigns = 'salesforce_campaigns',
  hubspot = 'hubspot',
  analytics = 'analytics',
  stripe = 'stripe',
}

type ConnectorPullConfig = {
  pullConfigFields: UnknownObject[];
  pullConfigFieldProperties: UnknownObject;
};
function usePullConfig(tenant: number, connector: string) {
  const { data, isLoading, refetch } = useQuery<ConnectorPullConfig>(
    [`pull-config-${tenant}-${connector}`],
    async () => {
      let objects = CONNECTOR_OBJECT_PULL_CONFIG[connector] || [];

      if (connector === CONNECTORS_NAMES.hubspot) {
        objects = await getEventsValuesFieldsByConnector(connector, tenant);
      }

      const pullConfig = await getConnectorFields(
        tenant,
        connector as DataSources,
        objects
      );

      const pullConfigFields =
        connector !== CONNECTORS_NAMES.hubspot &&
        connector !== CONNECTORS_NAMES.marketo
          ? pullConfig
          : [];

      const pullConfigFieldProperties =
        connector !== CONNECTORS_NAMES.salesforce_campaigns ||
        connector !== CONNECTORS_NAMES.salesforce_tasks
          ? pullConfig.reduce((acc, field) => {
              const key = Object.keys(field)[0];
              const value = Object.values(field)[0];
              acc[key] = value;
              return acc;
            }, {})
          : null;

      return {
        pullConfigFields,
        pullConfigFieldProperties,
      };
    }
  );
  return {
    pullConfigFields: data?.pullConfigFields ?? [],
    pullConfigFieldProperties: data?.pullConfigFieldProperties ?? [],
    /** Refreshes pull config, invalidates cache. */
    refreshPullConfig: refetch,
    isLoadingPullConfig: isLoading,
  };
}
export type UsePullConfigResponse = ReturnType<typeof usePullConfig>;
export default usePullConfig;
