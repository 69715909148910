import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCrown } from '@fortawesome/pro-solid-svg-icons/faCrown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRequestIntegrationAccessMutation } from '../../api/useRequestIntegrationAccessMutation';
import { Button, Tooltip } from '../../components/ui';

type Props = {
  tenant: number;
  integration: string;
};

export function RequestIntegrationAccessButton({ tenant, integration }: Props) {
  const {
    hasRequestedAccess,
    mutate: requestAccess,
    isLoading: isRequestingAccess,
  } = useRequestIntegrationAccessMutation(tenant, integration);

  if (hasRequestedAccess)
    return (
      <Tooltip text="Your CSM will reach out to you shortly">
        <Button color="blue" disabled>
          <FontAwesomeIcon icon={faCheck} className="tw-text-lg" /> Access
          requested
        </Button>
      </Tooltip>
    );

  if (isRequestingAccess)
    return (
      <Button loading disabled>
        Request access
      </Button>
    );

  return (
    <Button onClick={() => requestAccess()}>
      <FontAwesomeIcon icon={faCrown} /> Request access
    </Button>
  );
}
