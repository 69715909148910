import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import DomainListForm from './DomainListForm';

const template = require('./account.pug');

const Account_info_view = (Backbone as any).Epoxy.View.extend({
  events: { 'click #btn_update_account_info': 'event_update_account_info' },

  async initialize() {
    this.domain = this.model.get('domain');
    const otherDomains = this.model.get('other_domains');

    const domains = [];
    if (this.domain) domains.push(this.domain);
    if (otherDomains) domains.push(...otherDomains);

    this.formData = {
      data: { domains },
    };

    setTimeout(() => {
      ReactDOM.render(
        <DomainListForm formData={this.formData} />,
        $('#domain_list_form')[0]
      );
    }, 0);
  },

  render() {
    const html = $('#tpl_account_info', template()).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  event_update_account_info(e: any) {
    e.preventDefault();
    const otherDomains = this.formData.data.domains.filter(
      (domain: string) => domain !== this.domain
    );
    const attributes = {
      name: this.model.attributes.name,
      other_domains: otherDomains,
    };

    this.model.save(attributes, {
      patch: true,
      success() {
        Swal.fire(
          'Done!',
          'Your account information has been updated',
          'success'
        );
      },
      error() {
        Swal.fire(
          'Oops!',
          "There was an error saving your information. We're looking into it!",
          'error'
        );
      },
    });
  },
});

export default Backbone.View.extend({
  initialize() {
    this.account_info_view = new Account_info_view({ model: this.model });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(this.account_info_view, '#mki_account_info');
    return this;
  },
});
