import { useEffect, useState } from 'react';

export interface DelayedText {
  delay?: number;
  text: string;
}

function useDelayedText(delayedTexts: DelayedText[], active: boolean): string {
  const [text, setText] = useState('');

  useEffect(() => {
    if (!active) return;

    let timeoutIDs: NodeJS.Timeout[] = [];

    delayedTexts.forEach(({ text: delayedText, delay }) => {
      const timeoutID = global.setTimeout(() => {
        setText(delayedText);
      }, delay);
      timeoutIDs.push(timeoutID);
    });

    return () => {
      setText('');
      timeoutIDs.forEach((timeoutID) => clearTimeout(timeoutID));
      timeoutIDs = [];
    };
  }, [active, delayedTexts]);

  return text;
}

export default useDelayedText;
