import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { isUserReadOnly } from '../../../../utils';
import app from '../../../../app';
import { BackboneViewProps } from '../../../../types';
import ConversionMappingManager from '../../models/ConversionMappingManager';
import ConnectorsOverviewComponent from './components/ConnectorsOverviewComponent';
import DefaultConnectorsOverviewComponent from './components/DefaultConnectorsOverviewComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.user = app.session.get('user');
    this.email = this.user.email;
    this.isReadOnly = isUserReadOnly(this.user);
    if (this.isReadOnly) {
      Backbone.history.navigate('error/403', true);
      return this;
    }

    this.conversionMappingDataManager = new ConversionMappingManager(
      this.tenant,
      this.email,
      this.isReadOnly,
      this.user.superKudu
    );

    await this.conversionMappingDataManager.init();

    // Not yet ready to be here
    if (
      !this.conversionMappingDataManager.isFinished &&
      this.conversionMappingDataManager.step === 0
    ) {
      Backbone.history.navigate(
        `org/${this.tenant}/mapping/conversion_mapping`,
        true
      );
    }

    const { data } = this.conversionMappingDataManager;

    await this.sleep(0);

    this.render();

    if (data.activeIntegrations && data.activeIntegrations.length > 0) {
      this.renderConnectorsOverviewReactComponent();
    } else {
      this.renderDefaultConnectorsOverviewComponent(this.tenant);
    }
  },

  renderConnectorsOverviewReactComponent() {
    ReactDOM.render(
      <ConnectorsOverviewComponent
        conversionMappingDataManager={this.conversionMappingDataManager}
      />,
      $('#ConnectorsOverviewComponentContainer')[0]
    );
  },

  renderDefaultConnectorsOverviewComponent(tenant: number) {
    ReactDOM.render(
      <DefaultConnectorsOverviewComponent tenant={tenant} />,
      $('#ConnectorsOverviewComponentContainer')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
