export default function getHtmlBodyOfRecapSwal(
  tenant: number,
  email: string,
  message: string,
  object = 'Account',
  period = 'Last 6 months',
  connector = 'Salesforce'
) {
  return `
  <p>This will send a request to Engineering to activate the Account scoring</p>
  <br/>
  <div><b>[Requested by]</b>${' '}${email}</div>
  <div><b>[Tenant]</b>${' '}${tenant}</div>
  <div><b>[Connector]</b>${' '}${connector}</div>
  <div><b>[Object]</b>${' '}${object}</div>
  <div><b>[Period]</b>${' '}${period}</div>
  <div><b>[Message]</b>${' '}${message}</div>
  `;
}
