import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../../app';
import { capitalizeEachWord } from '../../../predictions/utils';
import PageComponent from '../components/PageComponent';

const template = require('./template.pug');

export default (Backbone as any).Epoxy.View.extend({
  async initialize(options: { tenant: number; page: string }) {
    this.options = options;
    const user = app.session.get('user');
    this.superKudu = user.superKudu;
    this.userEmail = app.session.get('user').email;
    this.render();

    if (window.analytics) {
      this.trackAnalytics(options.page, user.email, options.tenant);
    }
  },

  trackAnalytics(page: string, email: string, tenant: number) {
    let event = 'Insights';
    switch (page) {
      case 'l3':
        event = `${event} hand raiser`;
        break;
      case 'l1_leadsource':
        event = `${event} leadsource`;
        break;
      default:
        return;
    }

    window.analytics.page(event, {
      email,
      tenant,
    });
  },

  toggleView(tenant: string, page: string) {
    return (view: string) => {
      Backbone.history.navigate(
        `/org/${tenant}/evr_insights/${page.toLowerCase()}/${view}`
      );
    };
  },

  getActiveView(page: string): () => string {
    return () => {
      const splitUrl = window.location.href.split('/');
      const lastItem = splitUrl[splitUrl.length - 1];
      if (lastItem === page) {
        return '0';
      }
      return lastItem;
    };
  },

  renderPage() {
    const { tenant, page, tab } = this.options;
    const waitForPageReady = () => {
      if (typeof $('#page')[0] !== 'undefined') {
        const title = capitalizeEachWord(page.replace(/_/g, ' '));
        ReactDOM.render(
          <PageComponent
            getActiveView={this.getActiveView(page)}
            toggleView={this.toggleView(tenant, page)}
            tenant={tenant}
            pageId={page}
            tabId={tab}
            title={title}
            superKudu={this.superKudu}
            email={this.userEmail}
            back={() => Backbone.history.navigate(`/org/${tenant}/evr`, true)}
            goTo={(url: string) => Backbone.history.navigate(url, true)}
          />,
          $('#page')[0]
        );
      } else {
        setTimeout(waitForPageReady, 250);
      }
    };
    waitForPageReady();
  },

  render() {
    const html = template({});
    this.$el.html(html);
    this.renderPage();
    return this;
  },
});
