import { DelayedText } from './hooks/useDelayedText';

export const SEARCH_SCORE_DELAYED_TEXT: DelayedText[] = [
  {
    text: 'Profile data is on its way, we just need a little more time!',
    delay: 5000,
  },
  {
    text:
      "Just a heads up, things are moving a bit slower than anticipated. But don't worry, we're almost there! ⏳",
    delay: 15000,
  },
  {
    text: "I'm sorry for the delay. I just need a little more time.",
    delay: 30000,
  },
];

export const PROFILE_PAGE_QUERY_TIMEOUT = 40000;
