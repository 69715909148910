import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';
import { Button, FormControl, Table } from 'react-bootstrap';
import arrayMove from 'array-move';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { getHighLevelConnector } from '../generic/utils';
import { UseEventMappingProps } from './hooks/useEventMapping';
import usePagination from './hooks/primitives/usePagination';
import { SortableList } from './EventMappingSortableList';
import {
  useSorting,
  UseSortingOptions,
} from '../../../../utils/hooks/useSorting';
import { Event } from '../../models/types';
import { IndexedEvent } from './hooks/useEventMappingHistory';
import { ACTIVITY_TYPE } from '../../constants';
import { Loader } from '../../../../components/Loader';
import { useOverflow } from './hooks/primitives/useOverflow';
import { EventMappingColumnHeader } from './EventMappingColumnHeader';

const columnSettings: UseSortingOptions<IndexedEvent>['columns'] = [
  {
    key: 'originalIndex',
    sortFunction: (a, b) => a.originalIndex - b.originalIndex,
  },
];

export interface EventMappingTableProps extends UseEventMappingProps {
  editable: boolean;
  toggleEditable: () => void;
  disableConditions?: boolean;
  toggleActiveConnector: () => void;
}

export default function EventMappingTable(props: EventMappingTableProps) {
  const {
    connector,
    editable,
    discovery,
    disableConditions,
    eventHistory,
    toggleActiveConnector,
    email,
    tenant,
    superKudu,
    pullConfig,
    isLoading,
    isReady,
    isLoadingEvents,
    scriptChecker,
    eventErrors,
    hasEventErrors,
    setHistoryId,
    refreshAll,
    model,
    toggleEditable,
  } = props;

  const {
    events,
    isConnectorActive,
    isAnalytics,
    isSalesforceCampaigns,
    isSalesforceTasks,
    eventFieldValues,
    searchEvents,
    setEvents,
  } = eventHistory;

  const indexedEvents = events.map((event, originalIndex) => ({
    ...event,
    originalIndex,
  }));
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isGrabbing, setGrabbing] = useState(false);
  const isFiltered = !!searchQuery.length;
  const filteredEvents = searchQuery.length
    ? searchEvents(searchQuery)
    : indexedEvents;
  const { sortedItems, SortIcon, sortBy, isSorted, reset } = useSorting<
    Event & { originalIndex: number }
  >(filteredEvents, {
    defaultSortedKey: 'originalIndex',
    defaultSortDirection: 'DESC',
    columns: columnSettings,
  });
  const defaultMaxPerPage = events.length >= 50 ? 50 : events.length;
  const {
    Pagination,
    activeItems,
    currentPage,
    setMaxPerPage,
    maxPerPage,
  } = usePagination(sortedItems, {
    defaultMaxPerPage,
  });
  const hasCustomPagination = maxPerPage !== defaultMaxPerPage;

  const deleteEvent = (event: IndexedEvent) => {
    setEvents([...events].filter((el) => el.id !== event.id));
  };

  const hasCampaignMemberStatus = !!discovery.salesforceCampaignMemberStatuses
    .length;
  const humanReadableConnector = connector.toLowerCase().includes('salesforce')
    ? 'Salesforce'
    : capitalize(connector);

  type PaginationOption = {
    label: string;
    value: number;
  };
  const paginationOptions: PaginationOption[] = [
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '70', value: 70 },
    { label: 'All', value: events.length },
  ].filter((option) => option.value <= events.length);

  const paginationLabel =
    paginationOptions.find((el) => el.value === maxPerPage)?.label ?? 'All';

  useEffect(() => {
    if (paginationLabel === 'All' && maxPerPage !== events.length) {
      setMaxPerPage(events.length);
    }
  }, [events.length]);

  // Adds a new default event at first line.
  const addEvent = () => {
    const _events = [...events];
    _events.unshift(({
      ...discovery.defaultFormData,
      id: Math.max(0, ..._events.map((event) => event.id)) + 1,
    } as unknown) as IndexedEvent);
    // Pre-update pagination, to prevent having two pages when displaying 'All'
    if (paginationLabel === 'All') {
      setMaxPerPage(events.length + 1);
    }
    setEvents(_events);
  };

  // Duplicates selected event and add the newly created event below the selected one.
  const copyEvent = (event: IndexedEvent) => {
    const _events = [...events];
    _events.splice(event.originalIndex + 1, 0, {
      ...event,
      id: Math.max(..._events.map((_event) => _event.id)) + 1,
    });
    // Pre-update pagination, to prevent having two pages when displaying 'All'
    if (paginationLabel === 'All') {
      setMaxPerPage(events.length + 1);
    }
    setEvents(_events);
  };

  function toOptions(stringArr: string[]) {
    return (stringArr ?? []).map((value) => ({
      label: value,
      value,
    }));
  }

  const arrayOfEventsValues =
    isSalesforceCampaigns || isSalesforceTasks
      ? discovery.salesforceFields
      : eventFieldValues;
  const eventTypes = toOptions(arrayOfEventsValues);
  const activityTypes = toOptions(ACTIVITY_TYPE);
  const salesforceStatuses = toOptions(
    discovery.salesforceCampaignMemberStatuses
  );

  const { containerRef, childRef, isOverflowing } = useOverflow({
    deps: [currentPage, editable, events, salesforceStatuses],
  });

  return (
    <div
      className={['card mt-0 mb-4', isGrabbing ? 'cursor-grabbing' : ''].join(
        ' '
      )}
    >
      {eventHistory.isComputingEvents && (
        <div className="mt-5 ml-5 mr-5 mb-5">
          <Loader />
        </div>
      )}
      {!eventHistory.isComputingEvents && (
        <>
          <ReactTooltip
            place="top"
            getContent={(tip) => <div style={{ maxWidth: '300px' }}>{tip}</div>}
          />
          <div className="card-header mb-0">
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-auto my-auto mr-1">
                    <img
                      style={{ width: '35px' }}
                      src={`/media/integrations/logos/${getHighLevelConnector(
                        `${connector}-icon`
                      )}.png`}
                    />
                  </div>
                  <div className="col-auto my-auto">
                    <h5 className="font-weight-bold mb-0">
                      {connector === 's3' ? 'Amazon S3' : capitalize(connector)}
                    </h5>
                  </div>
                  <div className="col-auto my-auto">
                    <React.Fragment>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          onChange={toggleActiveConnector}
                          checked={isConnectorActive}
                        ></input>
                        <label
                          className="custom-control-label my-auto"
                          htmlFor="customSwitch1"
                        >
                          {isConnectorActive ? 'On' : 'Off'}
                        </label>
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </div>
              <div className="col-6 my-auto">
                <div className="pull-right">
                  {editable ? (
                    <Button
                      bsStyle="success"
                      className="ml-1"
                      onClick={addEvent}
                      disabled={!editable}
                      data-tip="Add new default event into first line"
                    >
                      Add new event
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row d-flex flex-row mt-2 mb-0 gap-3">
              <div className="row d-flex flex-row mb-2 gap-2 align-items-center ml-3 mr-3">
                <span>Show </span>
                <Select
                  options={paginationOptions}
                  value={{
                    label: paginationLabel,
                    value: maxPerPage,
                  }}
                  onChange={(option: PaginationOption) =>
                    setMaxPerPage(Number(option.value))
                  }
                  styles={{
                    // Allows to be displayed above the pagination item
                    // (which has an active item style set to z-index = 3)
                    menu: (baseStyles) => ({ ...baseStyles, zIndex: 4 }),
                  }}
                  isSearchable={false}
                />
                <span>
                  events {paginationLabel === 'All' ? '' : 'per page'}
                </span>
              </div>
              <div>
                <Pagination />
              </div>
              <div className="col-4">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e: any) => setSearchQuery(e.target.value)}
                />
              </div>
              {(isSorted || isFiltered || hasCustomPagination) && (
                <div>
                  <Button
                    bsStyle="primary"
                    onClick={() => {
                      reset();
                      setSearchQuery('');
                      setMaxPerPage(defaultMaxPerPage);
                    }}
                  >
                    Reset settings
                  </Button>
                </div>
              )}
            </div>
            <div
              className="overflow-auto"
              ref={containerRef}
              style={isOverflowing ? { maxHeight: 'calc(100vh - 350px)' } : {}}
            >
              <div
                style={{ width: 'fit-content', minWidth: '100%' }}
                ref={childRef}
              >
                <Table hover condensed className="event-mapping-table mb-8">
                  <thead>
                    <tr>
                      <th className="bg-white"></th>
                      <th className="bg-white"></th>
                      {isAnalytics && (
                        <th
                          className="cursor-pointer bg-white"
                          onClick={() => sortBy('excludeFromMapping')}
                        >
                          <EventMappingColumnHeader
                            SortIcon={SortIcon}
                            label="Exclude from mapping"
                            columnKey="excludeFromMapping"
                          />
                        </th>
                      )}
                      <th
                        className="cursor-pointer bg-white"
                        onClick={() => sortBy('event')}
                      >
                        <EventMappingColumnHeader
                          SortIcon={SortIcon}
                          label={
                            // eslint-disable-next-line no-nested-ternary
                            isSalesforceCampaigns
                              ? 'Campaign Type'
                              : isSalesforceTasks
                              ? 'Task Type'
                              : 'Event'
                          }
                          tooltip={
                            // eslint-disable-next-line no-nested-ternary
                            isSalesforceCampaigns
                              ? 'Enter the type from Salesforce Campaign object'
                              : isSalesforceTasks
                              ? 'Enter the type from Salesforce Task object'
                              : `Enter the event name coming from ${humanReadableConnector}.`
                          }
                          columnKey="event"
                        />
                      </th>
                      {hasCampaignMemberStatus && (
                        <th
                          className="cursor-pointer bg-white"
                          onClick={() => sortBy('campaignMemberStatus')}
                        >
                          <EventMappingColumnHeader
                            SortIcon={SortIcon}
                            label="Campaign member status"
                            tooltip="Enter the type from Salesforce Campaign Member object"
                            columnKey="campaignMemberStatus"
                          />
                        </th>
                      )}
                      <th></th>
                      <th
                        className="cursor-pointer bg-white"
                        onClick={() => sortBy('nonUserActivity')}
                      >
                        <EventMappingColumnHeader
                          SortIcon={SortIcon}
                          label="Negative user Activity"
                          tooltip={
                            'Flag events representing a lack of interest or negative behavior \n like Unsubscribing, visiting your careers page… for which you’ll want to assign negative points in a segmentation to avoid deceptively high scores'
                          }
                          columnKey="nonUserActivity"
                        />
                      </th>
                      <th
                        className="cursor-pointer bg-white"
                        onClick={() => sortBy('mkEventNameSignals')}
                      >
                        <EventMappingColumnHeader
                          SortIcon={SortIcon}
                          label="MK Event name (signals)"
                          tooltip="Define how the event will appear in the signals or Sales Intelligence in your CRM"
                          columnKey="mkEventNameSignals"
                        />
                      </th>
                      <th
                        className="cursor-pointer bg-white"
                        onClick={() => sortBy('mkEventName')}
                      >
                        <EventMappingColumnHeader
                          SortIcon={SortIcon}
                          label="MK Event name"
                          tooltip={
                            'Define the name used by the Likelihood to Buy model and aggregations.\nOnce set you should not change it otherwise you would need to change the model or aggregation after publishing'
                          }
                          columnKey="mkEventName"
                        />
                      </th>
                      <th
                        className="cursor-pointer bg-white"
                        onClick={() => sortBy('activityType')}
                      >
                        <EventMappingColumnHeader
                          SortIcon={SortIcon}
                          label="Activity type"
                          tooltip="Groups events by types of activities allowing to apply filters in signals or aggregations"
                          columnKey="activityType"
                        />
                      </th>
                      <th className="bg-white"></th>
                    </tr>
                  </thead>
                  {events && (
                    <SortableList
                      email={email}
                      tenant={tenant}
                      connector={connector}
                      superKudu={superKudu}
                      eventHistory={eventHistory}
                      pullConfig={pullConfig}
                      discovery={discovery}
                      scriptChecker={scriptChecker}
                      setHistoryId={setHistoryId}
                      refreshAll={refreshAll}
                      activeItems={activeItems}
                      onSortStart={() => setGrabbing(true)}
                      onSortEnd={({ newIndex, oldIndex }) => {
                        setEvents(arrayMove(events, oldIndex, newIndex));
                        setGrabbing(false);
                      }}
                      copyEvent={copyEvent}
                      deleteEvent={deleteEvent}
                      activePage={currentPage}
                      isFiltered={isFiltered}
                      isSorted={isSorted}
                      eventTypes={eventTypes}
                      salesforceStatuses={salesforceStatuses}
                      activityTypes={activityTypes}
                      disableConditions={disableConditions}
                      searchQuery={searchQuery}
                      lockAxis={'y'}
                      // When react-sortable-hoc renders the being sorted snapshotted element,
                      // we make our <tr> look like a flex container instead.
                      helperClass={
                        'border border-secondary shadow rounded d-flex flex-row p-2 justify-content-between'
                      }
                      useDragHandle
                      model={model}
                      isLoading={isLoading}
                      isReady={isReady}
                      isLoadingEvents={isLoadingEvents}
                      eventErrors={eventErrors}
                      hasEventErrors={hasEventErrors}
                      editable={editable}
                      toggleEditable={toggleEditable}
                      toggleActiveConnector={toggleActiveConnector}
                    />
                  )}
                </Table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
