import React from 'react';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { Aggregation } from '../types';
import { Loader } from '../../../components/Loader';

interface AggregationsSelectProps {
  tenant: number;
  aggregationsFields: string[];
  handleAggregationsChange: (Aggregations: string[]) => void;
}

export default function AggregationsSelect({
  tenant,
  aggregationsFields,
  handleAggregationsChange,
}: AggregationsSelectProps) {
  const { data: aggregations, isFetching, error } = useQuery(
    [tenant, 'aggregations'],
    async () => {
      const { data } = await axios.get<{ aggregations: Aggregation[] }>(
        `${BONGO_URL}/v1/org/${tenant}/aggregations`
      );
      return data.aggregations.map(({ name }) => name);
    }
  );

  if (isFetching) return <Loader />;

  if (error)
    return <span>An error has happened while loading aggregations</span>;

  return (
    <Select
      placeholder="Select field..."
      isMulti
      value={aggregationsFields.map((aggregation) => ({
        label: aggregation,
        value: aggregation,
      }))}
      options={aggregations.map((aggregation) => ({
        label: aggregation,
        value: aggregation,
      }))}
      onChange={(newValue) => {
        handleAggregationsChange(newValue.map(({ value }) => value));
      }}
    />
  );
}
