import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { BackboneViewProps } from '../types';

const template = require('./tag.pug');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #js_legacy_btn': 'toggleLegacy',
  },
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.model.fetch({
      success: () => {
        this.render();
        setTimeout(() => {
          this.renderTagComponent();
        }, 100);
      },
    });
  },

  toggleLegacy() {
    $('#js_legacy').toggle();
    $('#legacy-caret').toggleClass('fa-rotate-90');
  },

  copyToClipboard(id: string) {
    const toggleClass = () => {
      $(`#${id}-btn`).toggle();
      $(`#${id}-copied`).toggleClass('d-flex');
    };

    toggleClass();
    setTimeout(() => {
      toggleClass();
    }, 1000);

    const text = document
      .getElementById(id)
      .innerText.replace('\x3C', '<')
      .replace('\x3E', '>')
      .replace('\n', '');
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  },

  renderTagComponent() {
    const containerDomElement = $('#TagComponentContainer')[0];

    if (typeof containerDomElement !== 'undefined') {
      ReactDOM.render(
        <>
          <div className="row justify-content-center">
            <div className="col-8 mt-4 mb-4">
              <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src={`/media/integrations/logos/madkudu_simple.png`}
                    />
                    <h5 className="mb-0 ml-2">MadKudu Tag</h5>
                    <span className="rounded-pill px-2 py-1 bg-teal text-white d-inline-block ml-2 font-weight-bold">
                      Coming soon
                    </span>
                  </div>
                </div>

                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      id="js-code-btn"
                      className="btn-primary rounded ml-8 px-2 py-1 nowrap d-none"
                      onClick={() => this.copyToClipboard('js-code')}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        width="12"
                        height="12"
                        className={`text-white mr-1`}
                      />
                      Copy code
                    </button>
                    <button
                      id="js-code-copied"
                      className="text-success bg-white align-items-center py-1 nowrap"
                      style={{ display: 'none' }}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        width="12"
                        height="12"
                        className={`d-inline-block text-success mr-1`}
                      />
                      Code has been copied
                    </button>
                  </div>
                  <div>
                    <h5>Overview</h5>
                    <p>
                      The <b>MadKudu Tag</b> allows you to track who is on your
                      website to understand when companies are showing interests
                      in your product (anonymous or identified).
                    </p>
                    <h5>What you'll need</h5>
                    <p>
                      Access to the code for your website or Google Tag Manager
                      - If you're not comfortable with that, generally the
                      person who built your website or has installed analytics
                      tool like Google Analytics, Amplitude, Segment... will
                      know what to do.
                    </p>
                  </div>
                  <div className="mt-4">
                    <span>
                      To setup <b>MadKudu Tag</b> on your site, paste this
                      javascript snippet on every page you want MadKudu to be
                      on.{' '}
                    </span>
                    <div className="mt-2 border rounded p-4 d-flex align-items-center justify-content-center bg-gray-light">
                      <svg
                        width="54"
                        height="42"
                        viewBox="0 0 54 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.24"
                          d="M33.143 0.102856C31.7085 -0.299012 30.2149 0.512925 29.8015 1.90716L19.0006 38.6494C18.5872 40.0436 19.4225 41.4953 20.857 41.8971C22.2915 42.299 23.7851 41.4871 24.1985 40.0928L34.9994 3.35061C35.4128 1.95637 34.5775 0.504724 33.143 0.102856ZM39.9441 9.95273C38.8894 10.9779 38.8894 12.6428 39.9441 13.668L47.4794 21L39.9357 28.332C38.8809 29.3572 38.8809 31.0221 39.9357 32.0473C40.9905 33.0725 42.7034 33.0725 43.7582 32.0473L53.2089 22.8617C54.2637 21.8365 54.2637 20.1717 53.2089 19.1465L43.7582 9.96093C42.7034 8.93575 40.9905 8.93575 39.9357 9.96093L39.9441 9.95273ZM14.0643 9.95273C13.0095 8.92755 11.2966 8.92755 10.2418 9.95273L0.791077 19.1383C-0.263692 20.1635 -0.263692 21.8283 0.791077 22.8535L10.2418 32.0391C11.2966 33.0642 13.0095 33.0642 14.0643 32.0391C15.1191 31.0139 15.1191 29.349 14.0643 28.3238L6.52059 21L14.0643 13.668C15.1191 12.6428 15.1191 10.9779 14.0643 9.95273Z"
                          fill="#7D8D95"
                        />
                      </svg>
                      <h4 className="d-inline-block ml-2 text-gray">
                        Coming soon
                      </h4>
                    </div>
                    <div
                      id="js-code"
                      className="code mt-2 border rounded p-2 d-none"
                    >
                      <div>
                        &lt;<span className="text-primary">script</span> async
                        src="
                        <span className="text-info">
                          https://cdn.madkudu.com/p.js
                        </span>
                        " data-write-key="
                        <span className="text-danger">
                          {this.model.get('api_key')}
                        </span>
                        " data-tenant="
                        <span className="text-danger">{this.tenant}</span>"
                        data-host="
                        <span className="text-danger">
                          https://data.madkudu.com
                        </span>
                        "&gt;&lt;/<span className="text-primary">script</span>
                        &gt;
                      </div>
                    </div>
                  </div>
                  <span id="js_legacy_btn" className="mt-4 mb-3 d-inline-block">
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      width="10"
                      height="18"
                      id="legacy-caret"
                      className={`text-secondary cursor-pointer mr-2`}
                    />{' '}
                    <span className="text-primary cursor-pointer">
                      Display MadKudu Fastlane JS (legacy)
                    </span>
                  </span>
                  <div id="js_legacy" style={{ display: 'none' }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        To setup <b>MadKudu Fastlane</b> on your site, paste
                        this javascript snippet on every page you want MadKudu
                        to be on.
                      </span>
                      <button
                        id="js-code-legacy-btn"
                        className="btn-primary rounded ml-8 px-2 py-1 nowrap"
                        onClick={() => this.copyToClipboard('js-code-legacy')}
                      >
                        <FontAwesomeIcon
                          icon={faCopy}
                          width="12"
                          height="12"
                          className={`text-white mr-1`}
                        />{' '}
                        Copy code
                      </button>
                      <button
                        id="js-code-legacy-copied"
                        className="text-success bg-white align-items-center py-1 nowrap"
                        style={{ display: 'none' }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          width="12"
                          height="12"
                          className={`d-inline-block text-success mr-1`}
                        />
                        Code has been copied
                      </button>
                    </div>
                    <div
                      id="js-code-legacy"
                      className="code mt-2 border rounded p-2"
                    >
                      <div>
                        &lt;<span className="text-primary">script</span> type="
                        <span className="text-info">text/javascript</span>
                        "&gt;
                      </div>
                      <div className="pl-2">
                        <div>{`!function(e,t){var r=e.madkudu=e.madkudu||[];if(!r.initialize)if(r.invoked)e.console&&console.error&&console.error("MadKudu snippet included twice.");`}</div>
                        <div>{`else{r.invoked=!0,r.methods=["identify","reset","group","ready","page","track","once","on","smart_form"],r.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);`}</div>
                        <div>{`return t.unshift(e),r.push(t),r}};for(var o=0;o<r.methods.length;o++){var n=r.methods[o];`}</div>
                        <div>{`r[n]=r.factory(n)}r.load=function(r){var o=("https:"===t.location.protocol?"https://":"http://")+"cdn.madkudu.com/madkudu.js/v1/"+r+"/madkudu.min.js";`}</div>
                        <div>{`if("function"==typeof e.define&&e.define.amd)e.require([o]);else{var n=t.createElement("script");`}</div>
                        <div>{`n.type="text/javascript",n.async=!0,n.src=o;var a=t.getElementsByTagName("script")[0];`}</div>
                        <div>{`a.parentNode.insertBefore(n,a)}}}}(window,document);`}</div>
                        <div>{`madkudu.SNIPPET_VERSION = "0.4.0";`}</div>
                        <div>
                          {`madkudu.load("`}
                          <span className="text-danger">
                            {this.model.get('api_key')}
                          </span>
                          {`");`}
                        </div>
                        <div>{`madkudu.page();`}</div>
                      </div>
                      <div>
                        &lt;/<span className="text-primary">script</span>&gt;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
