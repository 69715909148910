import React from 'react';
import moment from 'moment';
import useSegmentIntrospectSchema from './hooks/useSegmentIntrospectSchema';

export type SegmentWorkbenchViewParams = {
  tenant: number;
};

export default function SegmentWorkbenchView({
  tenant,
}: SegmentWorkbenchViewParams) {
  const { data: schema } = useSegmentIntrospectSchema(tenant);

  return (
    <div className="m-2">
      <div>
        This table returns all the custom Segment properties found recently.
        Please, note these properties are extracted from a sample of events.
        Consequently, rarely occurring properties might not be visible here.
      </div>

      <table className="mt-4 tw-w-full">
        <thead>
          <tr>
            <th>Name</th>
            <th>Types</th>
            <th>Last Occurrence</th>
            <th>Fill rate</th>
          </tr>
        </thead>
        <tbody>
          {schema ? (
            Object.entries(schema).map(([key, value]) => (
              <tr key={`item-${key}`}>
                <td>{value.name}</td>
                <td>{value.types.join(', ')}</td>
                <td>{moment(value.lastOccurrence).format('ll')}</td>
                <td>{Math.round(value.occurrenceRatio * 100)}%</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center py-4">
                <div
                  className="tw-w-4 tw-h-4 mr-2 spinner-border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                Please wait, processing events (can take up to a minute or
                so)...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
