import React, { Dispatch, SetStateAction } from 'react';
import faExternalLink from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConversionTree, ModelSubset } from '../../../../types';

type Props = {
  setTree: Dispatch<SetStateAction<number>>;
  conversionTree: ConversionTree;
  tenant: number;
  model: ModelSubset;
};

export default function TreeCard({
  setTree,
  conversionTree,
  tenant,
  model,
}: Props) {
  return (
    <div className="tw-relative tw-flex tw-h-[7.5rem] tw-w-[13rem] tw-min-w-[13rem] tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl tw-border tw-border-blue-200 tw-bg-[#EDF1F7] tw-text-xs tw-shadow-centered">
      <div className="tw-flex tw-grow tw-cursor-pointer tw-items-center tw-justify-center tw-p-2 tw-font-bold hover:tw-opacity-80">
        Decision tree {conversionTree.index}
      </div>
      <span
        onClick={() => setTree(conversionTree.index)}
        className="tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2 tw-border-t tw-border-gray-100 tw-bg-white tw-p-2 tw-text-center tw-text-blue-700 hover:tw-text-blue-800 hover:tw-no-underline"
      >
        Show details
      </span>
      <a
        href={`https://studio.madkudu.com/tenant/${tenant}/models/${
          model.modelId
        }/model/trees/${conversionTree.index}/nodes/${
          conversionTree.paths[conversionTree.paths.length - 1].nodexIndex
        }`}
        target="_blank noopener noreferrer"
        className="tw-bg-white tw-p-2 tw-w-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-blue-800 hover:tw-no-underline tw-gap-2 tw-border-t tw-border-gray-100 tw-text-center tw-text-blue-700"
      >
        Studio
        <FontAwesomeIcon icon={faExternalLink} size="sm" />
      </a>
    </div>
  );
}
