import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import ConditionsForm from '../components/generic/ConditionFormComponent';

let globalDomElement: any;

export function publishConnectorsStates(
  tenant: number,
  disabledConnectors: string[],
  madMlSqlQuery: string,
  isCustom: boolean
) {
  return axios
    .post(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/events/connector_status`,
      {
        disabledConnectors,
        madMlSqlQuery,
        isCustom,
      }
    )
    .then((response) => {
      if (response.status < 200 || response.status > 300) {
        throw new Error('Something went wrong!');
      }
    })
    .catch((error) => {
      Swal.showValidationMessage(`Request failed: ${error}`);
    });
}

export async function launchDeactivateConnectorPopup(
  handlerFunction: Function
) {
  return Swal.fire({
    title: 'Deactivate connector in Production?',
    text:
      'This would turn off the connector in production and impact the model using these events, but the events mapped will still be saved in the app.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, turn off',
    cancelButtonText: 'No, cancel',
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      try {
        await handlerFunction();
      } catch (e) {
        Swal.showValidationMessage(`Request failed: ${e}`);
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
}

export async function launchActivateConnectorPopup(handlerFunction: Function) {
  return Swal.fire({
    title: 'Activate connector in Production?',
    text:
      'This would turn on the connector in production and impact the model using these events.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, turn on',
    cancelButtonText: 'No, cancel',
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      try {
        await handlerFunction();
      } catch (e) {
        Swal.showValidationMessage(`Request failed: ${e}`);
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
}

export async function deleteSaved(
  tenant: number,
  connector: string,
  displayHandler: Function
) {
  await axios.put(
    `${BONGO_URL}/v1/org/${tenant}/data/mappings/events_saved/${connector}`,
    {
      events: [],
    }
  );

  return Swal.fire({
    title: 'Are you sure you want to delete the saved mapping?',
    text: 'If you delete it, you will not be able to recover this mapping.',
    confirmButtonColor: '#3085d6',
    preConfirm: () => {
      displayHandler();
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
}

export function displayConditionFormComponent(
  domElement: any,
  id: number,
  formData: UnknownObject,
  editable: boolean,
  isErrorsExists: boolean,
  handlerFunctions: UnknownObject,
  { connectorPullConfigFields, connector, pullFieldProperties }: any = {}
) {
  const htmlContainer = domElement
    ? domElement.children[5]
    : globalDomElement.children[5];
  htmlContainer.style.cssText = 'display: block;';

  ReactDOM.render(
    <ConditionsForm
      editable={editable}
      hasErrors={isErrorsExists}
      formData={formData}
      connector={connector}
      connectorPullConfigFields={
        Array.isArray(connectorPullConfigFields)
          ? connectorPullConfigFields
          : []
      }
      pullFieldProperties={pullFieldProperties}
      createNewCondition={() => handlerFunctions.createNewCondition(id)}
      createNewConditionValue={(conditionIndex: number) =>
        handlerFunctions.createNewConditionValue(conditionIndex, id)
      }
      removeConditionValue={(conditionIndex: number) =>
        handlerFunctions.removeConditionValue(conditionIndex, id)
      }
      handleSubjectChange={(value: string, conditionIndex: number) =>
        handlerFunctions.handleSubjectChange(value, conditionIndex, id)
      }
      handleLogicChange={(value: string) =>
        handlerFunctions.handleLogicChange(value, id)
      }
      handleValueChange={(
        value: string,
        conditionIndex: number,
        valueIndex: number
      ) =>
        handlerFunctions.handleValueChange(
          value,
          conditionIndex,
          valueIndex,
          id
        )
      }
      handleVerbChange={(value: string, conditionIndex: number) =>
        handlerFunctions.handleVerbChange(value, conditionIndex, id)
      }
      handleRemoveCondition={(conditionIndex: number) =>
        handlerFunctions.handleRemoveCondition(conditionIndex, id)
      }
      handleObjectChange={(value: string, conditionIndex: number) =>
        handlerFunctions.handleObjectChange(value, conditionIndex, id)
      }
      handleLower={(conditionIndex: number) =>
        handlerFunctions.handleLower(conditionIndex, id)
      }
    />,
    domElement ? domElement.children[5] : globalDomElement.children[5]
  );
}

export async function launchSwal(
  id: number,
  formData: UnknownObject,
  editable: boolean,
  handlerFunctions: any,
  { connector, connectorPullConfigFields, pullFieldProperties }: UnknownObject,
  updateEventsHandler: any,
  events: UnknownArrayOfObjects,
  isErrorsExists: boolean
) {
  const res = await Swal.fire({
    title: `Additional conditions`,
    width: '50%',
    confirmButtonText: 'Save',
    confirmButtonColor: '#3085d6',
    allowOutsideClick: false,
    didOpen: (domElement: any) => {
      globalDomElement = domElement;
      displayConditionFormComponent(
        domElement,
        id,
        formData,
        editable,
        isErrorsExists,
        handlerFunctions,
        {
          connector,
          connectorPullConfigFields,
          pullFieldProperties,
        }
      );
    },
  });
  if (res.isConfirmed) {
    updateEventsHandler(events);
  }
}
