import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input } from '../../../components/ui';
import { Loading } from '../Loading';
import { useChangeCognismApiToken } from '../hooks/useChangeCognismApiToken';
import { cognismCredentialsSchema } from '../types';

type Props = {
  tenant: number;
};

export function ConnectionView({ tenant }: Props) {
  const { reset: resetForm, control, handleSubmit, formState } = useForm({
    reValidateMode: 'onChange',
    resolver: zodResolver(cognismCredentialsSchema),
    defaultValues: {
      apiToken: '',
    },
  });

  const {
    mutate,
    isLoading: isConnecting,
    isError,
    error,
  } = useChangeCognismApiToken(tenant, {
    onSuccess: () => resetForm(),
  });

  if (isConnecting) return <Loading loadingLabel="Connecting to Cognism" />;

  const { apiToken: apiTokenErrors } = formState.errors;

  return (
    <>
      <p>
        <p>
          Status:{' '}
          {!isConnecting && <b className="tw-text-red-500">Not connected</b>}
        </p>
        Enter your API token below to authorize MadKudu to connect to Cognism
      </p>

      <Form
        className="tw-flex tw-flex-col tw-items-start tw-gap-4"
        onSubmit={handleSubmit((values) => {
          mutate(values);
        })}
      >
        <div className="tw-w-full">
          <span>
            <b>API Token</b>
          </span>
          <Controller
            name="apiToken"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || apiTokenErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500'
                }
                placeholder="Enter your Cognism API token"
                type="text"
                {...field}
              />
            )}
          />
          {apiTokenErrors?.message && (
            <div className="tw-text-red-500 tw-mt-1">
              {apiTokenErrors?.message}
            </div>
          )}
        </div>

        {isError && (
          <p className="tw-text-red-500 tw-mt-2">
            {error?.message ||
              'Invalid API token. Please double-check the token and try again.'}
          </p>
        )}

        <Button type="submit" className="tw-mt-5" disabled={isConnecting}>
          Connect
        </Button>
      </Form>
    </>
  );
}
