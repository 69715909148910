import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import model_integration from '../../models/integration';
import { BackboneViewProps } from '../../types';
import S3ParentComponent, {
  TabValues,
} from './ReactComponents/S3ParentComponent';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 's3';
    this.credentials = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: 's3',
    });
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.apiKey = options.model.attributes.api_key;

    this.model.fetch({
      success: () => {
        this.modelRoleArn = this.model.get('s3_role_arn') || '';
        this.modelBucketRegion = this.model.get('s3_bucket_region') || '';
        this.modelFolderPath = this.model.get('s3_folder_path') || [];
        this.modelExternalId = this.model.get('external_id') || '';

        this.formDataCredentials = {
          s3_role_arn: this.modelRoleArn,
          s3_bucket_region: this.modelBucketRegion,
          s3_folder_path: this.modelFolderPath,
          external_id: this.modelExternalId,
        };

        this.credentialsFound =
          !!this.model.get('s3_role_arn') &&
          !!this.model.get('s3_bucket_region') &&
          !!this.model.get('s3_folder_path');

        this.render();
        this.renderS3IntegrationComponent();
      },
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
          this.renderS3IntegrationComponent();
        }
      },
    });
  },

  getActivePage(): TabValues {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === 's3') {
      return 'overview';
    }
    return lastItem as TabValues;
  },

  toggleView(tenant: string, integration: string) {
    return (page: TabValues) => {
      Backbone.history.navigate(
        `/org/${tenant}/integrations/${integration}/${page}`
      );
    };
  },

  renderS3IntegrationComponent() {
    const containerDomElement = $('#S3ParentComponentContainer')[0];
    if (typeof containerDomElement !== 'undefined') {
      ReactDOM.render(
        <S3ParentComponent
          tenant={this.tenant}
          getActivePage={this.getActivePage}
          toggleView={this.toggleView(this.tenant, this.integration)}
          formData={this.formDataCredentials}
          apiKey={this.apiKey}
          credentialsFound={this.credentialsFound}
        />,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
