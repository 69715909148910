import axios from 'axios';
import pick from 'lodash/pick';
import React from 'react';
import { Button } from 'react-bootstrap';
import { defaultSchema } from '../models/campaign';
import CalendarChoices from './components/CalendarChoices';
import MultiStep from './components/MultiStep';
import Step from './components/Step';
import SubHeader from './components/SubHeader';
import TestStep from './components/TestStep';
import TriggerOptions from './components/TriggerOptions';
import * as boilerplates from './boilerplates';
import { StepModel, stepData } from './defaultStepData';
import { escapeSingleQuotes } from './utils';

type CalendarChoice = 'calendly' | 'chilipiper';
type CalendarField = 'calendlyUrl' | 'chilipiperRouter';

type CampaignProps = {
  tenant: number;
  campaignId: string;
  goBackCampaigns: Function;
  openCampaign: Function;
};

type CampaignState = {
  isPublishing: boolean;
  steps: StepModel[];
  calendarChoice: CalendarChoice;
  currentStep: number;
  campaignName?: string;
  htmlId?: string;
  webhookUrl?: string;
  title?: string;
  subtitle?: string;
  ctaYes?: string;
  ctaNo?: string;
  fastlaneMoment?: string;
  triggerUrl?: string;
  pageUrl?: string;
  pageRegex?: string;
  backgroundColor?: string;
  buttonColor?: string;
  calendlyUrl?: CalendarField;
  chilipiperRouter?: CalendarField;
};

const calendarChoiceTemplate: any = {
  calendly: {
    title: 'Calendly Link',
    placeholder: 'https://calendly.com/sales-person',
    label: 'Calendly URL:',
    field: 'calendlyUrl',
  },
  chilipiper: {
    title: 'Chilipiper Router',
    placeholder: '"madkudu", "inbound-router"',
    label: 'Chilipiper Router',
    field: 'chilipiperRouter',
  },
};
const IS_ADVANCED = true;

export default class Campaign extends React.Component<
  CampaignProps,
  CampaignState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isPublishing: false,
      steps: stepData,
      currentStep: 1,
      calendarChoice: null,
    };

    this.goBackCampaigns = this.goBackCampaigns.bind(this);
    this.publish = this.publish.bind(this);
    this.confirmStep = this.confirmStep.bind(this);
    this.openCampaign = this.openCampaign.bind(this);
    this.changePageState = this.changePageState.bind(this);
    this.confirmCalendarChoice = this.confirmCalendarChoice.bind(this);
    this.handleStepValueChange = this.handleStepValueChange.bind(this);
    this.handleMultipleValuesChange = this.handleMultipleValuesChange.bind(
      this
    );
  }

  async componentDidMount() {
    try {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${this.props.tenant}/campaigns/${this.props.campaignId}`
      );

      let newState = {
        ...this.state,
        currentStep: 1,
      };
      if (data.name && data.campaignUi) {
        newState = {
          ...newState,
          ...data.campaignUi,
          currentStep: 2,
        };
      }
      this.setState(newState);
      this.setStepConfirmStatus();
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate() {
    const { calendarChoice, calendlyUrl, chilipiperRouter } = this.state;
    if (calendarChoice === 'calendly' && chilipiperRouter) {
      this.setState({ chilipiperRouter: null });
    }
    if (calendarChoice === 'chilipiper' && calendlyUrl) {
      this.setState({ calendlyUrl: null });
    }
  }

  async publish() {
    const { calendarChoice } = this.state;
    this.setState({ isPublishing: true });
    const boilerplate = boilerplates[calendarChoice];
    const parsedBoilerplate = this.parseBoilerplate(boilerplate);
    const schema = this.createLegacySchema(parsedBoilerplate);

    await axios.put(
      `${BONGO_URL}/v1/org/${this.props.tenant}/campaigns/${this.props.campaignId}`,
      { ...schema, publish: true }
    );
  }

  parseBoilerplate(boilerplate: string) {
    const {
      backgroundColor,
      buttonColor,
      calendlyUrl,
      chilipiperRouter,
      ctaNo,
      ctaYes,
      pageUrl,
      subtitle,
      title,
    } = this.state;
    return boilerplate
      .replace('{backgroundColor}', backgroundColor)
      .replace('{buttonColor}', buttonColor)
      .replace(/{title}/g, escapeSingleQuotes(title))
      .replace('{subtitle}', escapeSingleQuotes(subtitle))
      .replace('{ctaNo}', escapeSingleQuotes(ctaNo))
      .replace('{ctaYes}', escapeSingleQuotes(ctaYes))
      .replace('{Chilipiper_Router}', chilipiperRouter)
      .replace('{Calendly_URL}', calendlyUrl)
      .replace('{triggerOptions}', this.getTriggerOptions())
      .replace('{Domain}', pageUrl);
  }

  getTriggerOptions() {
    const { fastlaneMoment, pageRegex, pageUrl, triggerUrl } = this.state;
    if (fastlaneMoment === 'On form submit') {
      const urlChunk = triggerUrl && triggerUrl.split(pageUrl)[1];
      const regex = pageRegex || urlChunk;
      return `
        var display_calendar_if_qualified = function () {
          if (!(${regex}.test(window.location.href))) {
            return
          }
        
          if (madkudu.user().is_qualified())  {
            setTimeout(show_modal, 1000); // give the page time to refresh properly before we update the DOM
            form.show_modal(modalOptions);
          }
        }
        
        
        display_calendar_if_qualified();
      `;
    }
    return 'form.show_modal(modalOptions);';
  }

  createLegacySchema(boilerplate: string) {
    const {
      campaignName,
      fastlaneMoment,
      htmlId,
      pageRegex,
      pageUrl,
      webhookUrl,
    } = this.state;
    const { performance, variations } = defaultSchema;
    const [variation] = variations;

    if (fastlaneMoment === 'On form submit') {
      variation.on_load.js = boilerplate;
      variation.on_load.hook = webhookUrl || '';
      variation.on_qualified.js = '';
      variation.on_qualified.hook = '';
    } else {
      variation.on_qualified.js = boilerplate;
      variation.on_qualified.hook = webhookUrl || '';
      variation.on_load.js = '';
      variation.on_load.hook = '';
    }

    const urlConditions = [
      {
        match_type: 'simple',
        value: pageUrl,
      },
    ];
    if (pageRegex) {
      urlConditions.push({ match_type: 'simple', value: pageRegex });
    }

    return {
      ...defaultSchema,
      performance,
      variations: [variation],
      active: true,
      name: campaignName,
      trigger: { js: `form.track_input($('${htmlId}'));` },
      url_conditions: urlConditions,
      campaignUi: pick(this.state, [
        'campaignName',
        'htmlId',
        'webhookUrl',
        'title',
        'subtitle',
        'ctaYes',
        'ctaNo',
        'fastlaneMoment',
        'triggerUrl',
        'pageUrl',
        'pageRegex',
        'backgroundColor',
        'buttonColor',
        'calendarChoice',
        'calendlyUrl',
        'chilipiperRouter',
      ]),
    };
  }

  setStepConfirmStatus() {
    const {
      calendlyUrl,
      chilipiperRouter,
      campaignName,
      htmlId,
      pageUrl,
      steps,
      title,
      subtitle,
      ctaYes,
      ctaNo,
      triggerUrl,
      fastlaneMoment,
      webhookUrl,
      backgroundColor,
      buttonColor,
    } = this.state;
    const newSteps = [...steps];

    const [
      first,
      second,
      third,
      fourth,
      fifth,
      sixth,
      seventh,
      eigth,
      ninth,
    ] = newSteps;
    first.confirmed = !!campaignName;
    second.confirmed = !!pageUrl;
    third.confirmed = !!htmlId;
    fourth.confirmed = !!(calendlyUrl || chilipiperRouter);
    fifth.confirmed = !!(title && subtitle && ctaYes && ctaNo);
    sixth.confirmed = !!(triggerUrl || fastlaneMoment);
    seventh.confirmed = !!webhookUrl;
    eigth.confirmed = !!(backgroundColor || buttonColor);
    ninth.confirmed = false;

    this.setState({
      steps: [
        first,
        second,
        third,
        fourth,
        fifth,
        sixth,
        seventh,
        eigth,
        ninth,
      ],
    });
  }

  confirmStep(stepId: number) {
    const { steps } = this.state;
    const newSteps = steps.map((step, index) => {
      if (stepId !== index + 1) return step;
      return {
        ...step,
        confirmed: true,
      };
    });
    this.setState({ steps: newSteps });
  }

  confirmCalendarChoice(calendar: CalendarChoice) {
    return () => {
      this.setState({ calendarChoice: calendar, currentStep: 2 });
    };
  }

  // go back or forward a step
  changePageState(stepIncrement: 1 | -1) {
    const { currentStep } = this.state;
    return () => this.setState({ currentStep: currentStep + stepIncrement });
  }

  handleStepValueChange(field: string) {
    return (value: string) => {
      // @ts-ignore
      this.setState({ [field]: value });
    };
  }

  handleMultipleValuesChange(fields: string[]) {
    return (value: string, index: number) => {
      // @ts-ignore
      this.setState({ [fields[index]]: value });
    };
  }

  goBackCampaigns() {
    this.props.goBackCampaigns();
  }

  openCampaign() {
    this.props.openCampaign(this.props.campaignId, IS_ADVANCED);
  }

  renderStep1() {
    return (
      <CalendarChoices
        confirmCalendarChoice={this.confirmCalendarChoice}
        openCampaign={this.openCampaign}
      />
    );
  }

  renderStep2() {
    const {
      isPublishing,
      backgroundColor,
      buttonColor,
      calendarChoice,
      calendlyUrl,
      chilipiperRouter,
      campaignName,
      ctaNo,
      ctaYes,
      fastlaneMoment,
      htmlId,
      pageRegex,
      pageUrl,
      steps,
      subtitle,
      title,
      triggerUrl,
      webhookUrl,
    } = this.state;

    const calendarField: CalendarField =
      calendarChoiceTemplate[calendarChoice]?.field;
    const canPublish =
      backgroundColor &&
      buttonColor &&
      (chilipiperRouter || calendlyUrl) &&
      campaignName &&
      ctaNo &&
      ctaYes &&
      htmlId &&
      pageUrl &&
      subtitle &&
      title;

    return (
      <React.Fragment>
        <Step
          stepName="Step 1. Enter Your Campaign Name"
          stepId={1}
          stepValue={campaignName}
          handleStepValueChange={this.handleStepValueChange('campaignName')}
          isConfigured={steps[0].confirmed}
          inputName="Campaign Name:"
          inputType="text"
          placeholder="Enter the Campaign Name..."
          accordionId="accordion1"
          hrefDataAccordionId="#accordion1"
          hrefDataCollapseId="#collapse1"
          headingId="heading1"
          collapseId="collapse1"
          confirmStepHandler={this.confirmStep}
        />
        <Step
          stepName="Step 2. Enter the URL of your page you want Fastlane on"
          stepId={2}
          stepValues={[pageUrl, pageRegex]}
          handleMultipleValuesChange={this.handleMultipleValuesChange([
            'pageUrl',
            'pageRegex',
          ])}
          shouldShow={steps[0].confirmed && !steps[1].confirmed}
          isConfigured={steps[1].confirmed}
          inputName="URL:"
          inputType="url"
          placeholder="https://www.example.com/thank-you"
          accordionId="accordion2"
          hrefDataAccordionId="#accordion2"
          hrefDataCollapseId="#collapse2"
          headingId="heading2"
          collapseId="collapse2"
          isAdvanced
          confirmStepHandler={this.confirmStep}
        />
        <Step
          stepName="Step 3. Enter the HTML ID on your form that collects the email field"
          stepId={3}
          stepValue={htmlId}
          handleStepValueChange={this.handleStepValueChange('htmlId')}
          shouldShow={steps[1].confirmed && !steps[2].confirmed}
          isConfigured={steps[2].confirmed}
          inputName="Identifier:"
          inputType="email"
          placeholder="#email"
          accordionId="accordion3"
          hrefDataAccordionId="#accordion3"
          hrefDataCollapseId="#collapse3"
          headingId="heading3"
          collapseId="collapse3"
          confirmStepHandler={this.confirmStep}
        />
        <Step
          calendarChoice={calendarChoice}
          stepName={`Step 4. Input your ${calendarChoiceTemplate[calendarChoice].title}`}
          stepId={4}
          stepValue={this.state[calendarField]}
          handleStepValueChange={this.handleStepValueChange(calendarField)}
          shouldShow={steps[2].confirmed && !steps[3].confirmed}
          isConfigured={steps[3].confirmed}
          inputName={calendarChoiceTemplate[calendarChoice].label}
          inputType="url"
          placeholder={calendarChoiceTemplate[calendarChoice].placeholder}
          accordionId="accordion4"
          hrefDataAccordionId="#accordion4"
          hrefDataCollapseId="#collapse4"
          headingId="heading4"
          collapseId="collapse4"
          description={
            calendarChoice === 'calendly' ? (
              'Enter the URL of the calendar you want qualified prospects to schedule a time on'
            ) : (
              <React.Fragment>
                To find it, log into Chilipiper, head to settings and to inbound
                router, go to embed for your form. (Screenshot here)
                <img
                  src="/media/calendars/fastlane-chilipiper-router.jpg"
                  style={{ width: '300px' }}
                ></img>
              </React.Fragment>
            )
          }
          confirmStepHandler={this.confirmStep}
        />
        <MultiStep
          stepName="Step 5. Personalize Your Fastlane Message"
          stepId={5}
          stepValues={[title, subtitle, ctaYes, ctaNo]}
          handleMultipleValuesChange={this.handleMultipleValuesChange([
            'title',
            'subtitle',
            'ctaYes',
            'ctaNo',
          ])}
          shouldShow={steps[3].confirmed && !steps[4].confirmed}
          isConfigured={steps[4].confirmed}
          inputTitle="Title:"
          inputSubtitle="Subtitles:"
          inputType="text"
          ctaYes="CTA Yes:"
          ctaNo="CTA No:"
          yesPlaceholder="Ex: Book time with an expert"
          noPlaceholder="Ex: No Thanks"
          titlePlaceholder="Ex: you qualify for the fast lane!"
          subPlaceholder=" Ex: Our scoring tells us you should get access to our experts' calendar"
          accordionId="accordion5"
          hrefDataAccordionId="#accordion5"
          hrefDataCollapseId="#collapse5"
          headingId="heading5"
          collapseId="collapse5"
          confirmStepHandler={this.confirmStep}
        />
        <TriggerOptions
          stepName="Step 6. Trigger Modal (optional)"
          stepId={6}
          stepValues={[triggerUrl, fastlaneMoment]}
          fastlaneMoment={fastlaneMoment}
          handleMultipleValuesChange={this.handleMultipleValuesChange([
            'triggerUrl',
            'fastlaneMoment',
          ])}
          shouldShow={steps[4].confirmed && !steps[5].confirmed}
          isConfigured={steps[5].confirmed}
          inputName="URL:"
          inputType="url"
          placeholder="https://madkudu.com/form_submitted/"
          accordionId="accordion6"
          hrefDataAccordionId="#accordion6"
          hrefDataCollapseId="#collapse6"
          headingId="heading6"
          collapseId="collapse6"
          description="After the form is submitted, where does the user get redirected to ? (if no redirect, leave blank)"
          subRadioDescription="When do you want the Fastlane popup to happen?"
          confirmStepHandler={this.confirmStep}
        />
        <Step
          stepName="Step 7. Configure a Webhook (optional)"
          stepId={7}
          stepValue={webhookUrl}
          handleStepValueChange={this.handleStepValueChange('webhookUrl')}
          shouldShow={steps[5].confirmed && !steps[6].confirmed}
          isConfigured={steps[6].confirmed}
          inputName="Webhook URL:"
          inputType="url"
          placeholder="https://calendly.com/sales-person"
          accordionId="accordion7"
          hrefDataAccordionId="#accordion7"
          hrefDataCollapseId="#collapse7"
          headingId="heading7"
          collapseId="collapse7"
          description="If you'd like to have a webhook to fire whenever a prospects requests a demo"
          confirmStepHandler={this.confirmStep}
        />
        <Step
          stepName="Step 8. Style your Fastlane"
          stepId={8}
          stepValues={[backgroundColor, null, buttonColor]}
          handleMultipleValuesChange={this.handleMultipleValuesChange([
            'backgroundColor',
            // added null to ignore second "advanced mode" input in step component
            null,
            'buttonColor',
          ])}
          shouldShow={steps[6].confirmed && !steps[7].confirmed}
          isConfigured={steps[7].confirmed}
          inputName="Background Color:"
          inputName2="Button Color:"
          inputType="text"
          placeholder="#123B65"
          accordionId="accordion8"
          hrefDataAccordionId="#accordion8"
          hrefDataCollapseId="#collapse8"
          headingId="heading8"
          collapseId="collapse8"
          description="Customize your Fastlane's style"
          twoInput
          confirmStepHandler={this.confirmStep}
        />
        <div className="row justify-content-center mt-3">
          <div className="col text-center">
            <Button
              bsStyle="outline-primary"
              onClick={this.changePageState(-1)}
            >
              Go back
            </Button>
            <Button
              bsStyle="primary"
              className="ml-1"
              disabled={!canPublish || isPublishing}
              onClick={async () => {
                await this.publish();
                this.changePageState(1)();
              }}
            >
              {isPublishing ? 'Publishing...' : 'Publish'}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderStep3() {
    const { steps, pageUrl } = this.state;
    return (
      <React.Fragment>
        <TestStep
          accordionId="accordion9"
          hrefDataAccordionId="#accordion9"
          hrefDataCollapseId="#collapse9"
          headingId="heading9"
          collapseId="collapse9"
          stepId={9}
          isConfigured={steps[8].confirmed}
          confirmStepHandler={this.confirmStep}
          pageUrl={pageUrl}
        />
        <div className="row justify-content-center mt-3">
          <div className="col text-center">
            <Button
              bsStyle="outline-primary"
              onClick={this.changePageState(-1)}
            >
              Go back
            </Button>
            &nbsp;&nbsp;
            <Button bsStyle="primary" onClick={this.goBackCampaigns}>
              Finish
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { currentStep } = this.state;

    return (
      <React.Fragment>
        <SubHeader currentStep={currentStep} />
        <div className="container mt-4 pb-5">
          {currentStep === 1 && this.renderStep1()}
          {currentStep === 2 && this.renderStep2()}
          {currentStep === 3 && this.renderStep3()}
        </div>
      </React.Fragment>
    );
  }
}
