import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useSlackChannels(tenant: number) {
  return useQuery<Array<{ id: string; name: string }>>({
    queryKey: ['slack-channels', tenant],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/integrations/slack/channels`
      );

      return data;
    },
  });
}
