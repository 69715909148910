import classNames from 'classnames';
import React from 'react';

export function StatusPanel({
  status,
  error,
}: {
  status: 'idle' | 'loading' | 'error' | 'success' | undefined;
  error?: Error | null;
}) {
  let content = null;
  if (status === 'loading') {
    content = 'Loading...';
  } else if (status === 'error') {
    const e = error as any;
    const errorMessage = e?.response?.data?.message ?? e.message;
    content = `Error! ${errorMessage || ''}`;
  }
  const className = classNames('integration flex-grow-1 text-center', {
    'text-red-500': status === 'error',
  });
  if (!content) return null;
  return (
    <div className="card box-shadow-3d integration-item hover-animation m-3">
      <div className="card-body d-flex flex-column flex-align-items-center justify-content-center">
        <div className={className} style={{ width: '100%' }}>
          {content}
        </div>
      </div>
    </div>
  );
}
