import React from 'react';
import isEqual from 'lodash/isEqual';
import { ConversionMappingData } from '../../../models/ConversionMappingData';
import { ConversionMappingConnectorsNames } from '../../../../../types';
import GenericFormComponent from './GenericFormComponent';

const DEFAULT_CONFIGURATION_TEXT = 'Default configuration';
const NOT_SAVED_CONFIGURATION = 'Unsaved changes';

const WARNING_COLOR = 'warning';
const PRIMARY_COLOR = 'primary';

type ParameterComponentProps = {
  conversionMappingData: ConversionMappingData;
  originalConversionMappingData: ConversionMappingData;
  connector: ConversionMappingConnectorsNames;
  tenant: number;
  isFinished: boolean;
  handleConnectorTypeFilterValues: (
    c: string,
    c2: ConversionMappingConnectorsNames
  ) => void;
  handleConnectorAmountFieldValue: (
    c: string,
    conn: ConversionMappingConnectorsNames
  ) => void;
  handleConnectorAmountFieldTable: (
    c: string,
    conn: ConversionMappingConnectorsNames
  ) => void;
  index: number;
};

type ParameterComponentState = {
  isConfigured: boolean;
};

export default class ParameterComponent extends React.Component<
  ParameterComponentProps,
  ParameterComponentState
> {
  configuredProperty: any;

  notConfiguredProperty: any;

  constructor(props: ParameterComponentProps) {
    super(props);

    this.state = {
      isConfigured: false,
    };
    const { isFinished, originalConversionMappingData, connector } = this.props;
    const indexedConversionTypeOfConnector = originalConversionMappingData
      .getAllIndexedConversionTypesData()
      .find((indexedConversionType) => {
        return indexedConversionType.conversionTypeData.connector === connector;
      });
    const conversionTypeOfConnector =
      indexedConversionTypeOfConnector.conversionTypeData;

    if (isFinished || !conversionTypeOfConnector.isDefaultConfiguration()) {
      this.configuredProperty = {
        text: NOT_SAVED_CONFIGURATION,
        color: WARNING_COLOR,
      };
      this.notConfiguredProperty = {
        text: '',
        color: PRIMARY_COLOR,
      };
    } else {
      this.configuredProperty = {
        text: NOT_SAVED_CONFIGURATION,
        color: WARNING_COLOR,
      };
      this.notConfiguredProperty = {
        text: DEFAULT_CONFIGURATION_TEXT,
        color: PRIMARY_COLOR,
      };
    }
    this.setIsConfigured = this.setIsConfigured.bind(this);
  }

  setIsConfigured() {
    const {
      conversionMappingData,
      connector,
      originalConversionMappingData,
    } = this.props;
    const {
      conversionTypeData,
    } = conversionMappingData
      .getAllIndexedConversionTypesData()
      .find((indexedConversionType) => {
        return indexedConversionType.conversionTypeData.connector === connector;
      });

    const {
      conversionTypeData: originalConversionTypeData,
    } = originalConversionMappingData
      .getAllIndexedConversionTypesData()
      .find((indexedConversionType) => {
        return indexedConversionType.conversionTypeData.connector === connector;
      });
    this.setState({
      isConfigured:
        !isEqual(
          conversionTypeData?.amountField,
          originalConversionTypeData?.amountField
        ) ||
        !isEqual(
          conversionTypeData?.filterTypesValues?.toString(),
          originalConversionTypeData?.filterTypesValues?.toString()
        ),
    });
  }

  renderConnectorFormComponent() {
    const {
      handleConnectorTypeFilterValues,
      handleConnectorAmountFieldValue,
      handleConnectorAmountFieldTable,
      conversionMappingData,
      connector,
    } = this.props;
    if (connector !== 'analytics') {
      return (
        <GenericFormComponent
          conversionMappingData={conversionMappingData}
          handleConnectorTypeFilterValues={handleConnectorTypeFilterValues}
          handleConnectorAmountFieldValue={handleConnectorAmountFieldValue}
          handleConnectorAmountFieldTable={handleConnectorAmountFieldTable}
          setIsConfigured={this.setIsConfigured}
          connector={connector}
        />
      );
    }
    return <div>Nothing to configure here.</div>;
  }

  render() {
    const { isConfigured } = this.state;
    const { index, connector } = this.props;

    return (
      <div>
        <div className="card mb-2">
          <div
            className={`card-header ${
              isConfigured
                ? `bg-${this.configuredProperty.color}-fade border border-${this.configuredProperty.color}`
                : 'bg-white'
            }`}
            id={`heading_${index}`}
          >
            <div className="row my-auto">
              <div className="col float-left">
                <img
                  src={`/media/integrations/logos/${connector}.svg`}
                  style={{ height: '35px' }}
                />
              </div>
              <div className="col ml-auto text-right my-auto">
                {connector !== 'analytics' &&
                  (isConfigured ? (
                    <h4
                      className={`text-${this.configuredProperty.color} mb-0`}
                    >
                      {this.configuredProperty.text}
                    </h4>
                  ) : (
                    <h4
                      className={`text-${this.notConfiguredProperty.color} mb-0`}
                    >
                      {this.notConfiguredProperty.text}
                    </h4>
                  ))}
              </div>
            </div>
          </div>
          <div className="card-header">
            <div>
              <h4>Configure Standard Parameters</h4>
              <div>These parameters apply to all conversions definitions</div>
            </div>
          </div>
          <div className="card-body">{this.renderConnectorFormComponent()}</div>
        </div>
      </div>
    );
  }
}
