import * as React from 'react';

type Props = React.HTMLProps<HTMLDivElement> & {
  errorLevel?: 'error' | 'warning' | 'suggestion';
};

const errorLevelClasses = {
  error: 'tw-bg-red-100 tw-text-red-500',
  warning: 'tw-bg-yellow-100 tw-text-yellow-500',
  suggestion: 'tw-bg-blue-100 tw-text-blue-500',
};

export function FormError(props: Props) {
  const { errorLevel = 'error', children, className = '', ...rest } = props;

  return (
    <div
      className={`tw-inline-block tw-p-2 tw-mt-2 tw-max-w-2xl tw-font-medium tw-text-sm ${errorLevelClasses[errorLevel]} ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
}
