import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CsvUploadComponent from '../components/CsvUploadComponent';
import app from '../app';

const template = require('./bulk.pug');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: { tenant: number; context: string }) {
    this.tenant = options.tenant;
    this.context = options.context;
    this.superKudu = app.session.get('user').superKudu;
    this.model.fetch({ success: () => this.render() });
  },

  renderForm() {
    const waitForPageReady = () => {
      if (typeof $('#bulk_scorer_main')[0] !== 'undefined') {
        ReactDOM.render(
          <QueryClientProvider client={queryClient}>
            <CsvUploadComponent
              tenant={this.tenant}
              title="Bulk Scorer"
              postPath={`${BONGO_URL}/v1/org/${this.tenant}/bulkscorer/`}
              shortInstruction="Upload a CSV of your leads"
              longInstruction="Score your list of emails or domains based on the MadKudu Customer
            Fit predictions. Upload a CSV with your emails OR domains on the
            first column and voila, you get back a CSV file with the MadKudu
            fit score, segment and signals via email."
            />
          </QueryClientProvider>,
          $('#bulk_scorer_main')[0]
        );
      } else {
        setTimeout(waitForPageReady, 250);
      }
    };
    waitForPageReady();
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
    this.renderForm();
  },
});
