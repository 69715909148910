import React, { Component } from 'react';

type EvolutionOverTimeConversionsMQAGuideComponentProps = {
  tenant: number;
  goTo: (url: string) => void;
};

export default class EvolutionOverTimeConversionsMQAGuideComponent extends Component<
  EvolutionOverTimeConversionsMQAGuideComponentProps,
  {}
> {
  render() {
    const { tenant, goTo } = this.props;
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
        <div className="card-body">
          <i className="far fa-compass fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>Guide: </strong>
            Evolution Over Time: Conversions Distribution
          </h5>
          <div className="border-top mt-2 border-primary"></div>
          <i className="fas fa-flask fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>How MadKudu Computes This:</strong>
          </h5>
          <p className="mt-2">
            How many of your conversions{' '}
            <a
              onClick={() => goTo(`/org/${tenant}/mapping/conversion_mapping`)}
              style={{ textDecoration: 'underline' }}
            >
              (see how conversions are mapped)
            </a>{' '}
            created in the last 6 months and have converted are scored as very
            high, high, medium or low by MadKudu's Account Likelihood to Buy
            model segmented by month. Note that we take the last score of the
            account before the opportunity is created, and we do not account for
            the opportunity close date.
          </p>
          <div className="mt-2">
            <strong>Note:</strong>
            <ul>
              <li>
                We take the last score of the account before the conversion
                point.
              </li>
              <li>
                The conversions above are based off conversions with at least an
                active contact (active contact means they have an event in the
                past 90 days). This is done by design so that the model
                performance looks at the relevant data by which the model has
                been trained on in order to provide helpful insights on the
                model as time goes by.
              </li>
              <li>
                We use the opportunity created date for SQO and Open Opp
                predictions, and we use opportunity close date for Closed Won
                for Closed Won predictions.
              </li>
            </ul>
          </div>
          <h5 className="mt-2">
            <strong>How to Interpret This:</strong>
          </h5>
          <p>
            This section aims to showcase the trend in overall number of
            conversions created in the last 6 months by month and have
            converted, as well as in the proportion of your conversions that
            came from accounts scored as very high or high by MadKudu's Account
            Likelihood to Buy model. Find out how the different marketing and
            sales activities conducted over the past 6 months have impacted
            conversions and the pace of converting accounts scored as very high
            or high.
          </p>
        </div>
      </div>
    );
  }
}
