import axios from 'axios';
import AbstractMadMlMapping from './AbstractMadMlMapping';
import { getAttributes } from '../attribute_mapping/utils';

export default class MadMlAttributeMapping extends AbstractMadMlMapping {
  async createMadMlData(madMlSqlQuery: string): Promise<void> {
    const objectToSend = await this.prepareMadMlData(madMlSqlQuery);
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/attributes`;
    await axios.put(url, { ...objectToSend });
  }

  createMadMlObjectToSend(madMlSqlQuery: string): any {
    const indentedMadMlSqlQuery = madMlSqlQuery
      .split('\n')
      .map((lane) => `      ${lane.trim()}`)
      .join('\n');

    return {
      tenant: this.tenant,
      description: `MadML configuration for ${this.tenant} - attribute mapping`,
      email: this.email,
      has_hubspot: false,
      has_salesforce: false,
      isCustom: true,
      madMlSqlQuery: indentedMadMlSqlQuery,
    };
  }

  async getMadMlData(): Promise<string> {
    const data = await getAttributes(this.tenant);
    if (data) {
      const { madMlSqlQuery } = data;
      return madMlSqlQuery as string;
    }
    return '';
  }

  async prepareMadMlData(madMlSqlQuery: string): Promise<any> {
    return this.createMadMlObjectToSend(madMlSqlQuery);
  }
}
