import * as React from 'react';
import { Icon, IconName } from './Icon';

export const TagColors = {
  gray: 'tw-bg-gray-150 tw-text-gray-500',
  blue: 'tw-bg-blue-200 tw-text-blue-800',
  cyan: 'tw-bg-cyan-200 tw-text-cyan-700',
  green: 'tw-bg-green-200 tw-text-green-700',
  red: 'tw-bg-red-200 tw-text-red-500',
  orange: 'tw-bg-orange-200 tw-text-orange-600',
  purple: 'tw-bg-purple-200 tw-text-purple-600',
  dark: 'tw-bg-blue-900 tw-text-white',
};

const TagSize = {
  small: 'tw-h-7 tw-px-4 tw-text-xs',
  big: 'tw-h-10 tw-px-6 tw-text-xl',
};
type Props = {
  color?: keyof typeof TagColors;
  size?: keyof typeof TagSize;
  icon?: IconName;
} & Omit<React.HTMLProps<HTMLSpanElement>, 'size'>;

export const Tag = function Tag(props: Props) {
  const { color = 'blue', size = 'small', icon, className, children } = props;

  return (
    <span
      className={`tw-inline-flex tw-shrink-0 tw-grow-0 tw-items-center tw-justify-center tw-gap-2 tw-rounded-full tw-font-medium ${
        TagColors[color]
      } ${TagSize[size]} ${className ?? ''}`}
    >
      {icon && <Icon name={icon} width={15} height={15} />}
      {children}
    </span>
  );
};
