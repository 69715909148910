import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  Computation,
  ConversionTree,
  ModelSubset,
  Node,
  EnrichmentCategory,
  TreeDiagnosis,
} from '../../../types';
import ConversionPath from './ConversionPath/ConversionPath';
import ConversionOverview from './ConversionOverview/ConversionOverview';

type Props = {
  treeDiagnosis: TreeDiagnosis;
  computations: Computation[];
  personProperties: Record<string, any>;
  model: ModelSubset;
  email: string;
  tenant: number;
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
};

export default function TreesDiagnosis({
  treeDiagnosis,
  computations,
  personProperties,
  tenant,
  email,
  model,
  setSearch,
  setSelectedCategory,
}: Props) {
  const [tree, setTree] = useState(0);

  const {
    conversionTrees,
    treesWeights,
    treeOverview,
  }: TreeDiagnosis = useMemo(() => {
    const hydratedTreeDiagnosis: TreeDiagnosis = {
      conversionTrees: treeDiagnosis.conversionTrees,
      treesWeights: treeDiagnosis.treesWeights,
      treeOverview: treeDiagnosis.treeOverview,
    };

    if (hydratedTreeDiagnosis.conversionTrees) {
      hydratedTreeDiagnosis.conversionTrees.forEach(
        (conversionTree: ConversionTree) => {
          conversionTree.paths.map((node: Node) => {
            computations.forEach((computation: Computation) => {
              if (node.splitConditions.includes(computation.name)) {
                node.computations.push({
                  name: computation.name,
                  value: computation.value,
                });
              }
            });

            // Order array by string length
            const orderedComputations = node.computations.sort((a, b) => {
              return b.name.length - a.name.length;
            });
            let conditionCheck = node.splitConditions;
            orderedComputations.forEach((computation) => {
              // If computation found in the array, remove from the array
              if (conditionCheck.includes(computation.name)) {
                conditionCheck = conditionCheck.replace(computation.name, '');
              } else {
                // If computation not found in the condition, remove the computation from array
                node.computations.splice(
                  node.computations.findIndex(
                    (c) => c.name === computation.name
                  ),
                  1
                );
              }
            });
            return node;
          });
        }
      );
    }

    return hydratedTreeDiagnosis;
  }, [treeDiagnosis]);

  const findTree = (treeIndex: number) => {
    return conversionTrees.find((t: ConversionTree) => t.index === treeIndex);
  };

  if (personProperties?.diagnosis?.treesDiagnosis?.length === 0) return <></>;

  return (
    <div className="tw-border-t tw-border-white">
      {tree === 0 && (
        <ConversionOverview
          personProperties={personProperties}
          conversionTrees={conversionTrees}
          treesWeights={treesWeights}
          treeOverview={treeOverview}
          setTree={setTree}
          tenant={tenant}
          model={model}
          computations={computations}
          setSearch={setSearch}
          setSelectedCategory={setSelectedCategory}
        ></ConversionOverview>
      )}
      {tree > 0 && (
        <ConversionPath
          email={email}
          conversionTree={findTree(tree)}
          tenant={tenant}
          model={model}
          setTree={setTree}
          setSearch={setSearch}
          setSelectedCategory={setSelectedCategory}
        />
      )}
    </div>
  );
}
