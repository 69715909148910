import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Button, Checkbox, FormControl } from 'react-bootstrap';
import { AuthenticationManager } from '../models/AuthenticationManager';

const ENTRY_POINT_PLACEHOLDER_VALUE =
  'https://example.okta.com/app/abcdefghij12345/identifier/sso/saml';
const ISSUER_PLACEHOLDER_VALUE = 'http://www.okta.com/exampleabcdefghijk1234';

interface AuthenticationParentComponentProps {
  authenticationManager: AuthenticationManager;
  domain: string;
  tenant: number;
}

interface AuthenticationParentComponentState {
  authenticationManagerState: AuthenticationManager;
  domain: string;
  tenant: number;
}

export default class AuthenticationParentComponent extends React.Component<
  AuthenticationParentComponentProps,
  AuthenticationParentComponentState
> {
  constructor(props: AuthenticationParentComponentProps) {
    super(props);

    this.state = {
      authenticationManagerState: cloneDeep(this.props.authenticationManager),
      domain: this.props.domain,
      tenant: this.props.tenant,
    };
    this.handleSSOTypeOnChange = this.handleSSOTypeOnChange.bind(this);
    this.handleEntryPointOnChange = this.handleEntryPointOnChange.bind(this);
    this.handleIssuerOnChange = this.handleIssuerOnChange.bind(this);
    this.handleCertOnChange = this.handleCertOnChange.bind(this);
    this.handleOnSaveClick = this.handleOnSaveClick.bind(this);
    this.handleOnClearClick = this.handleOnClearClick.bind(this);
    this.handleDomainAutoSignupEnabledOnChange = this.handleDomainAutoSignupEnabledOnChange.bind(
      this
    );
  }

  handleSSOTypeOnChange(e: any) {
    const newAuthenticationManager = cloneDeep(
      this.props.authenticationManager.setSSOType(e.target.value)
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  handleDomainAutoSignupEnabledOnChange(e: any) {
    const newAuthenticationManager = cloneDeep(
      this.props.authenticationManager.setDomainAutoSignupEnabled(
        e.target?.checked
      )
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  handleEntryPointOnChange(e: any) {
    const newAuthenticationManager = cloneDeep(
      this.props.authenticationManager.setEntryPoint(e.target.value)
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  handleIssuerOnChange(e: any) {
    const newAuthenticationManager = cloneDeep(
      this.props.authenticationManager.setIssuer(e.target.value)
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  handleCertOnChange(e: any) {
    const newAuthenticationManager = cloneDeep(
      this.props.authenticationManager.setCert(e.target.value)
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  async handleOnSaveClick() {
    const newAuthenticationManager = cloneDeep(
      await this.props.authenticationManager.save()
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  async handleOnClearClick() {
    const newAuthenticationManager = cloneDeep(
      await this.props.authenticationManager.clear()
    );

    this.setState({
      authenticationManagerState: newAuthenticationManager,
    });
  }

  renderOktaCredentialsForm() {
    const { sso } = this.state.authenticationManagerState.data;

    return (
      <div>
        <div className="row mt-2">
          <div className="col-4">Identity Provider Single Sign-On URL</div>
          <div className="col">
            <FormControl
              name="entryPoint"
              type="text"
              value={sso.metas.entryPoint}
              placeholder={ENTRY_POINT_PLACEHOLDER_VALUE}
              onChange={this.handleEntryPointOnChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">Identity Provider Issuer</div>
          <div className="col">
            <FormControl
              name="issuer"
              type="text"
              value={sso.metas.issuer}
              placeholder={ISSUER_PLACEHOLDER_VALUE}
              onChange={this.handleIssuerOnChange}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-4">X.509 Certificate</div>
          <div className="col">
            <FormControl
              name="cert"
              componentClass="textarea"
              value={sso.metas.cert}
              onChange={this.handleCertOnChange}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.state.authenticationManagerState;

    const isOktaChosen: boolean = data.sso.type === 'okta';

    const canSaveOkta: boolean =
      !isEmpty(data.sso.metas?.cert) &&
      !isEmpty(data.sso.metas?.issuer) &&
      !isEmpty(data.sso.metas?.entryPoint);

    return (
      <div className="m-2">
        <div className="row">
          <div className="col-4">Enforce SSO</div>
          <div className="col">
            <FormControl
              name="sso type"
              componentClass="select"
              value={data.sso.type}
              onChange={this.handleSSOTypeOnChange}
            >
              {['disabled', 'google', 'okta'].map((ssoType) => {
                return (
                  <option value={ssoType} key={`sso_type_option_${ssoType}`}>
                    {ssoType}
                  </option>
                );
              })}
            </FormControl>
          </div>
          <div className="col">
            Require all users to log-in using specific identity provider
          </div>
        </div>
        {isOktaChosen && this.renderOktaCredentialsForm()}
        <div className="row mt-4 mb-2">
          <div className="col-4">Enable auto-join</div>
          <div className="col">
            <div className="flex">
              <Checkbox
                id="domainAutoSignupEnabled"
                name="domainAutoSignupEnabled"
                checked={data.domain_auto_signup_enabled}
                onChange={this.handleDomainAutoSignupEnabledOnChange}
              >
                <span className="ml-2">
                  Allow anyone who signs up with a @{this.state.domain} email
                  with Google Auth to join the account automatically as a Sales
                  user.
                </span>
              </Checkbox>
              <span className="ml-3">
                Sales users only have access to MadKudu Copilot.{' '}
                <a href={`/org/${this.props.tenant}/settings/users`}>
                  Prefer to invite users to join?
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-content-sm-start mt-2">
          <div>
            <Button
              bsStyle="success"
              disabled={isOktaChosen && !canSaveOkta}
              onClick={this.handleOnSaveClick}
            >
              Save
            </Button>
          </div>
          {isOktaChosen && (
            <div>
              <Button
                bsStyle="danger"
                className="ml-1"
                onClick={this.handleOnClearClick}
              >
                Clear
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
