import Backbone from 'backbone';
import React from 'react';
import { Button } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import ConversionMappingManager from '../../../models/ConversionMappingManager';
import { ConversionMappingConnectorsNames } from '../../../../../types';
import { ConversionMappingData } from '../../../models/ConversionMappingData';
import { launchDeleteMappingPopup } from '../../../../swal';
import MappingsMode from '../../../../enums/MappingsMode';
import { goBackToConversionMappingOverview } from '../../utils';
import ParameterComponent from './ParameterComponent';

type StandardParameterComponentProps = {
  conversionMappingDataManager: ConversionMappingManager;
};

type StandardParameterComponentState = {
  conversionMappingData: ConversionMappingData;
  isSaving: boolean;
};

export default class StandardParameterComponent extends React.Component<
  StandardParameterComponentProps,
  StandardParameterComponentState
> {
  originalConversionMappingData: ConversionMappingData;

  constructor(props: StandardParameterComponentProps) {
    super(props);

    this.state = {
      // remove reference
      conversionMappingData: cloneDeep(
        this.props.conversionMappingDataManager.data
      ),
      isSaving: false,
    };

    this.originalConversionMappingData = cloneDeep(
      this.props.conversionMappingDataManager.data
    );
    // Binds
    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.goBackToConversionMapping = this.goBackToConversionMapping.bind(this);
    this.goBack = this.goBack.bind(this);
    this.handleClickOnConfirm = this.handleClickOnConfirm.bind(this);
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
    this.handleConnectorTypeFilterValues = this.handleConnectorTypeFilterValues.bind(
      this
    );
    this.handleConnectorAmountFieldValue = this.handleConnectorAmountFieldValue.bind(
      this
    );
    this.handleConnectorAmountFieldTable = this.handleConnectorAmountFieldTable.bind(
      this
    );
  }

  goBackToMapping() {
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingDataManager.tenant}/mapping`,
      true
    );
  }

  goBackToConversionMapping() {
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingDataManager.tenant}/mapping/conversion_mapping/overview`,
      true
    );
  }

  async goBack() {
    this.props.conversionMappingDataManager.step = 1;
    this.setState({
      isSaving: true,
      conversionMappingData: cloneDeep(this.state.conversionMappingData),
    });
    await this.props.conversionMappingDataManager.save();
    this.setState({
      isSaving: false,
    });
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingDataManager.tenant}/mapping/conversion_mapping/connector_overview`,
      true
    );
  }

  async handleClickOnConfirm() {
    this.props.conversionMappingDataManager.step = 3;
    this.setState({
      isSaving: true,
      conversionMappingData: cloneDeep(this.state.conversionMappingData),
    });
    await this.props.conversionMappingDataManager.save();
    this.setState({
      isSaving: false,
    });
    Backbone.history.navigate(
      `org/${this.props.conversionMappingDataManager.tenant}/mapping/conversion_mapping/conversion_type`,
      true
    );
  }

  handleConnectorAmountFieldValue(
    value: string,
    connector: ConversionMappingConnectorsNames
  ) {
    const { conversionMappingData } = this.state;
    conversionMappingData.conversionTypesGroups = this.props.conversionMappingDataManager.data.setAllConversionTypesDataAmountFieldOfConnector(
      connector,
      value,
      'value'
    );

    this.setState({
      conversionMappingData,
    });
  }

  handleConnectorAmountFieldTable(
    value: string,
    connector: ConversionMappingConnectorsNames
  ) {
    const { conversionMappingData } = this.state;
    conversionMappingData.conversionTypesGroups = this.props.conversionMappingDataManager.data.setAllConversionTypesDataAmountFieldOfConnector(
      connector,
      value,
      'table'
    );

    this.setState({
      conversionMappingData,
    });
  }

  handleConnectorTypeFilterValues(
    value: string,
    connector: ConversionMappingConnectorsNames
  ) {
    const { conversionMappingData } = this.state;
    conversionMappingData.conversionTypesGroups = this.props.conversionMappingDataManager.data.setAllConversionTypesDataFiltersTypesOfConnector(
      connector,
      value.split(',')
    );

    this.setState({
      conversionMappingData,
    });
  }

  async handleOnClickRemove() {
    const { tenant, email } = this.props.conversionMappingDataManager;

    await launchDeleteMappingPopup(tenant, email, MappingsMode.conversion, () =>
      goBackToConversionMappingOverview(tenant)
    );
  }

  render() {
    const {
      isFinished,
      tenant,
      isSuperKudu,
    } = this.props.conversionMappingDataManager;
    const { conversionMappingData, isSaving } = this.state;
    const errors = conversionMappingData.getErrorsByLevels([
      'amount field invalid table',
      'amount field invalid value',
    ]);
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToConversionMapping}
              >
                Conversion Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Standard Parameters
            </li>
          </ol>
          <div className="float-right my-2 my-lg-0">
            <div>
              {isFinished && isSuperKudu && (
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-danger"
                    onClick={this.handleOnClickRemove}
                  >
                    Remove and create a new configuration
                  </button>
                </div>
              )}
            </div>
          </div>
        </nav>

        <div className="shadow-sm p-3 mb-5 bg-white rounded text-center border-top">
          <strong>Step 2/3: </strong>Configure your standard parameters for each
          data source
        </div>
        <div className="container pb-4">
          <div className="row align-content-center justify-content-center">
            <div className="col-sm-8">
              {conversionMappingData.activeConnectors
                .filter((activeConnector) => {
                  return activeConnector.isActive;
                })
                .map((activeConnector, index) => {
                  return (
                    <React.Fragment key={`d_${activeConnector.connector}`}>
                      <ParameterComponent
                        connector={activeConnector.connector}
                        tenant={tenant}
                        key={index}
                        index={index}
                        isFinished={isFinished}
                        originalConversionMappingData={
                          this.originalConversionMappingData
                        }
                        conversionMappingData={conversionMappingData}
                        handleConnectorTypeFilterValues={(
                          value: string,
                          conn: ConversionMappingConnectorsNames
                        ) => this.handleConnectorTypeFilterValues(value, conn)}
                        handleConnectorAmountFieldValue={(
                          value: string,
                          conn: ConversionMappingConnectorsNames
                        ) => this.handleConnectorAmountFieldValue(value, conn)}
                        handleConnectorAmountFieldTable={(
                          value: string,
                          conn: ConversionMappingConnectorsNames
                        ) => this.handleConnectorAmountFieldTable(value, conn)}
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 text-center">
              <button
                type="button"
                className="btn btn-outline-primary mr-2"
                disabled={isSaving}
                onClick={this.goBack}
              >
                Go Back
              </button>
              <Button
                bsStyle="primary"
                onClick={this.handleClickOnConfirm}
                disabled={isSaving || errors.length > 0}
              >
                {isSaving ? 'Saving...' : 'Save & continue'}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
