export const htmlBodyForPQLSwal = `
<p>
    Weights or decay factors of events included in the Likelihood to Buy model can
    be manually changed to boost or penalize some events. You can send a request through this page
    to the MadKudu team who will review your request and assess the impact on the model performance.
    <br/>
    Please explain below your request and rationale, or reach out to your <strong>CSM</strong> or <strong>success@madkudu.com</strong> for any question.
</p>
<br/>
<strong>A ticket will be created on Zendesk.</strong>`;

export function returnHtmlBodyForSuccessSwal(url: string) {
  return `
    <p>Your ticket was sent!</p>
    <br/>
    <p>Zendesk ticket: <a href="${url}" target="_blank">${url}</a></p>`;
}
