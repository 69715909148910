var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (chatbotEnabled, flags, tenant) {
      pug_html = pug_html + "\u003Cdiv class=\"sidebar hidden\"\u003E\u003Cdiv class=\"sidebar__logo\" id=\"mki_logo\"\u003E\u003Cimg class=\"logo-hover\" src=\"\u002Fmedia\u002Fimages\u002FMadKudu-Logo-Secondary-Text-White.svg\" id=\"madkudu_image\"\u003E\u003C\u002Fdiv\u003E\u003Cul class=\"sidebar__nav sidebar__nav--main\"\u003E";
if (flags.demo === true) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/dashboard', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--dashboard\"\u003E\u003Cspan\u003EHome\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
}
if (flags.onboarding_intercom === true || flags.onboarding_hubspot === true || flags.onboarding_googlesheet) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id, true, true)) + "\u003E\u003Cli class=\"sidebar__nav--get-started\"\u003E\u003Cspan\u003EGet Started\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
}
if (!(flags.demo === true || flags.onboarding_intercom === true || flags.onboarding_hubspot === true || flags.onboarding_googlesheet === true)) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/homepage', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--homepage\"\u003E\u003Cspan\u003EHome\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
}
if ((flags.profiles_demo === true)) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/profiles/demo', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--profiles\"\u003E\u003Cspan\u003EProfiles\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/profiles', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--profiles tw-relative\"\u003E\u003Cspan\u003EScore lookup\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/predictions', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--predictions\"\u003E\u003Cspan\u003EPredictions\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/data-studio', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--studio tw-relative\"\u003E\u003Cspan\u003EData Studio\u003C\u002Fspan\u003E\u003Cspan class=\"sidebar_new_studio tw-absolute tw-top-2 tw-right-6 tw-top-2 tw-rounded-full\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/mapping', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--mapping\"\u003E\u003Cspan\u003EMapping\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/data', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--data\"\u003E\u003Cspan\u003EData\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/integrations', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--integrations\"\u003E\u003Cspan\u003EIntegrations\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
if (chatbotEnabled) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/chatbot', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--chatbot\"\u003E\u003Cspan\u003EChatbot\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/settings', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--settings\"\u003E\u003Cspan\u003ESettings\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E\u003C\u002Ful\u003E\u003Cul class=\"sidebar__nav sidebar__nav--bottom\"\u003E\u003Ca" + (pug.attr("href", '/org/' + tenant._id + '/help', true, true)) + "\u003E\u003Cli class=\"sidebar__nav--help\"\u003E\u003Cspan\u003EHelp\u003C\u002Fspan\u003E\u003C\u002Fli\u003E\u003C\u002Fa\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"subnav\"\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "chatbotEnabled" in locals_for_with ?
        locals_for_with.chatbotEnabled :
        typeof chatbotEnabled !== 'undefined' ? chatbotEnabled : undefined, "flags" in locals_for_with ?
        locals_for_with.flags :
        typeof flags !== 'undefined' ? flags : undefined, "tenant" in locals_for_with ?
        locals_for_with.tenant :
        typeof tenant !== 'undefined' ? tenant : undefined));
    ;;return pug_html;};
module.exports = template;