import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import app from '../app';
import BulkScorerResultComponent from './components/BulkScorerResultComponent';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: { tenant: number; requestid: string }) {
    this.tenant = options.tenant;
    this.requestid = options.requestid;
    this.superKudu = app.session.get('user').superKudu;
    this.model.fetch({ success: () => this.render() });
  },

  renderForm() {
    const waitForPageReady = () => {
      if (typeof $('#bulkScorerResultComponentContainer')[0] !== 'undefined') {
        ReactDOM.render(
          <BulkScorerResultComponent
            tenant={this.tenant}
            superKudu={this.superKudu}
            requestid={this.requestid}
          />,
          $('#bulkScorerResultComponentContainer')[0]
        );
      } else {
        setTimeout(waitForPageReady, 250);
      }
    };
    waitForPageReady();
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
    this.renderForm();
  },
});
