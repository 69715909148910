export default [
  {
    id: -1,
    name: 'MadKudu',
    integrations: ['api', 'tag', 'bulk', 'identification', 'clearbit_reveal'],
  },
  {
    id: 2,
    name: 'Data Enrichment',
    integrations: ['clearbit', 'predictleads'],
  },
  {
    id: 3,
    name: 'CRM',
    integrations: ['salesforce', 'pipedrive', 'freshsales', 'gong'],
  },
  {
    id: 4,
    name: 'Social',
    integrations: ['linkedin', 'github', 'slack', 'job_changes'],
  },
  {
    id: 5,
    name: 'Marketing Automation',
    integrations: ['hubspot', 'marketo', 'eloqua'],
  },
  {
    id: 6,
    name: 'Contact Sourcing',
    integrations: ['apollo', 'zoominfo', 'cognism'],
  },
  {
    id: 7,
    name: 'Analytics',
    integrations: [
      's3',
      'bigquery',
      'snowflake',
      'amplitude',
      'kissmetrics',
      'mixpanel',
    ],
  },
  {
    id: 8,
    name: 'Customer Data Platform',
    integrations: ['hull', 'segment'],
  },
  {
    id: 9,
    name: 'Chat',
    integrations: ['intercom'],
  },
  {
    id: 10,
    name: 'Payments',
    integrations: ['stripe'],
  },
  {
    id: 11,
    name: 'Customer Support',
    integrations: ['zendesk'],
  },
  {
    id: 12,
    name: 'Sales Execution Platform',
    integrations: ['outreach', 'salesloft'],
  },
];

export const PREMIUM_INTEGRATIONS: string[] = [
  'linkedin',
  'github',
  'slack',
  'job_changes',
];

export const NON_PUSH_PULL_INTEGRATIONS = [
  'apollo',
  'zoominfo',
  'cognism',
  'github',
  'slack',
  'linkedin',
  'job_changes',
  'outreach',
  'salesloft',
] as const;
