import React from 'react';
import { TeamServiceModel } from '../types';

export default function TeamRow({
  team,
  actionColumn,
}: {
  team: TeamServiceModel;
  actionColumn?: React.ReactNode;
}) {
  return (
    <tr key={team._id}>
      <td>{team.name}</td>
      <td>{team.ownerField}</td>
      {actionColumn && <td>{actionColumn}</td>}
    </tr>
  );
}
