import React, { Dispatch, SetStateAction, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faFileUser } from '@fortawesome/pro-solid-svg-icons/faFileUser';
import {
  Computation,
  EnrichmentCategory,
  ModelSubset,
} from '../../../../types';
import { snakeCaseToHumanReadable } from '../../../../../utils';

type Props = {
  personProperties: Record<string, any>;
  tenant: number;
  model: ModelSubset;
  computations: Computation[];
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
};

export default function OverrideCard({
  personProperties,
  tenant,
  model,
  computations,
  setSearch,
  setSelectedCategory,
}: Props) {
  const [unfolded, setUnfolded] = useState(false);

  const searchComputation = (
    nodeComputations: { name: string; value: string }[]
  ) => {
    if (nodeComputations?.length > 0) {
      const list = nodeComputations
        .map((computation) => `"${snakeCaseToHumanReadable(computation.name)}"`)
        .join(' ');
      setSearch(list);
      setSelectedCategory('all');
    }
  };

  const overrideComputations: { name: string; value: string }[] = [];
  personProperties?.diagnosis?.overrideDiagnosis[0].formConditions.forEach(
    (condition: any) =>
      overrideComputations.push({
        name: condition.subject.subject,
        value: computations.find((c) => c.name === condition.subject.subject)
          .value,
      })
  );

  return (
    <div className="tw-z-10 -tw-mt-3">
      <div className="tw-text-center tw-mb-2 tw-text-sm tw-text-blue-900 tw-opacity-80 tw-font-semibold">
        Override
      </div>
      <div className="tw-w-[14rem] tw-flex tw-items-center tw-flex-col tw-rounded-md tw-bg-white tw-text-xs tw-border-blue-200 tw-shadow-centered">
        <div
          className="tw-p-4 tw-text-center tw-full"
          onClick={() => searchComputation(overrideComputations)}
        >
          If{' '}
          {personProperties?.diagnosis?.overrideDiagnosis[0].formConditions.map(
            (condition: any, index: number) => (
              <span key={index} className="tw-text-gray-500">
                {index > 0 ? ' and ' : ''}
                <span className="tw-capitalize tw-text-gray-700">
                  {condition.subject.subject}{' '}
                </span>
                {`${condition.verb} ${condition.complements.join(', ')}`}
              </span>
            )
          )}{' '}
          then segment {personProperties?.diagnosis?.overrideDiagnosis[0].rule}{' '}
          <span className="tw-font-semibold tw-capitalize">
            {personProperties?.diagnosis?.overrideDiagnosis[0].segment}
          </span>
        </div>
        <a
          href={`https://studio.madkudu.com/tenant/${tenant}/models/${model.modelId}/model/overrides`}
          target="_blank noopener noreferrer"
          className="tw-p-2 tw-w-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-blue-800 hover:tw-no-underline tw-gap-2 tw-border-t tw-border-gray-100 tw-text-center tw-text-blue-700"
        >
          Studio
          <FontAwesomeIcon icon={faExternalLink} size="sm" />
        </a>
        <span
          onClick={() => setUnfolded(!unfolded)}
          className="tw-p-3 tw-w-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-blue-800 hover:tw-no-underline tw-rounded-bl-lg tw-rounded-br-lg tw-gap-2 tw-bg-[#d3efff] tw-border-gray-100 tw-text-center tw-text-blue-700"
        >
          {unfolded ? (
            <>
              Hide values{' '}
              <FontAwesomeIcon
                icon={faEyeSlash}
                size="lg"
                className={unfolded ? 'tw-rotate-180' : 'tw-rotate-0'}
              />
            </>
          ) : (
            <>
              Show values{' '}
              <FontAwesomeIcon
                icon={faEye}
                size="lg"
                className={unfolded ? 'tw-rotate-180' : 'tw-rotate-0'}
              />
            </>
          )}
        </span>
      </div>
      {unfolded && (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-4">
          {overrideComputations.map((computation, index) => (
            <div
              key={index}
              className={`tw-bg-white tw-shadow-centered tw-rounded-md tw-w-full tw-text-xs tw-overflow-hidden fade-in`}
              style={{
                animationDelay: `${70 * index}ms`,
                animationDuration: '300ms',
              }}
            >
              <div className="tw-bg-[#6A88A4] tw-relative tw-w-full tw-flex tw-h-8 tw-items-center tw-justify-center tw-text-white">
                <FontAwesomeIcon
                  icon={faFileUser}
                  size="lg"
                  className="tw-absolute tw-left-2"
                />{' '}
                <span className="tw-inline-block tw-whitespace-nowrap tw-overflow-hidden tw-overflow-ellipsis tw-max-w-[120px]">
                  {computation.name}
                </span>
              </div>
              <div className="tw-p-2 tw-text-center tw-max-w-full">
                {computation.value}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
