import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BackboneViewProps } from '../../types';
import SalesloftParentComponent from './ReactComponents/SalesloftParentComponent';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'salesloft';
    setTimeout(() => {
      this.render();
      this.renderSalesloftIntegrationComponent();
    }, 250);
  },

  renderSalesloftIntegrationComponent() {
    const containerDomElement = $('#SalesloftParentComponentContainer')[0];

    if (typeof containerDomElement !== 'undefined') {
      ReactDOM.render(
        <SalesloftParentComponent tenant={this.tenant} />,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
