import isEqual from 'lodash/isEqual';
import {
  ConversionMappingConnectorsNames,
  MappingConversionsTypes,
} from '../../../types';
import { AbstractFiltersForm } from './filters_forms/AbstractFiltersForm';
import FiltersFormFactory from './filters_forms/FiltersFormFactory';
import FieldDataModel from '../../models/FieldDataModel';
import { DefaultFiltersForm } from './filters_forms/DefaultFiltersForm';
import StripeFiltersForm from './filters_forms/StripeFiltersForm';
import SourceSystems from '../../enums/SourceSystems';
import { AnalyticsFiltersForm } from './filters_forms/AnalyticsFiltersForm';
import { getAmountFieldByConnector } from '../utils';
import { ConnectorFieldNameDataModel } from '../../models/ConnectorFieldNameDataModel';

const SALESFORCE_DEFAULT_AMOUNT_FIELD_NAME = 'a_amount';
const HUBSPOT_DEFAULT_AMOUNT_FIELD_NAME = 'a_hs_closed_amount';

export type ConversionTypeDataOptions = {
  createdAt?: number;
  updatedAt?: number;
  filterTypesValues?: string[];
  amountField?: AmountFieldModel;
  filtersForm?: AbstractFiltersForm;
  madMlSqlQuery?: string;
};

export type AmountFieldModel = {
  value: string;
  table: string;
};

export class ConversionTypeData {
  tenant: number;

  email: string;

  createdAt: number;

  updatedAt: number;

  isCustom: boolean;

  madMlSqlQuery: string;

  connector: ConversionMappingConnectorsNames;

  filterTypesValues: string[];

  amountField: AmountFieldModel;

  conversionType: MappingConversionsTypes;

  filtersForm: AbstractFiltersForm;

  connectorFieldsName: ConnectorFieldNameDataModel[];

  groupName: string;

  constructor(
    tenant: number,
    email: string,
    isCustom: boolean,
    connector: ConversionMappingConnectorsNames,
    conversionType: MappingConversionsTypes,
    connectorFieldsName: ConnectorFieldNameDataModel[],
    groupName: string,
    options?: ConversionTypeDataOptions
  ) {
    this.tenant = tenant;
    this.email = email;
    this.conversionType = conversionType;
    this.connector = connector;
    this.connectorFieldsName = connectorFieldsName;
    this.groupName = groupName;
    this.isCustom = isCustom;
    this.amountField = {
      value: '',
      table: '',
    };
    this.build(options);
  }

  build(options: ConversionTypeDataOptions) {
    if (options?.amountField) {
      if (options.amountField?.value) {
        this.amountField.value = options.amountField.value;
      } else {
        this.amountField.value =
          (options?.amountField as any) || this.getDefaultAmountFieldValue();
      }
      if (options.amountField?.table) {
        this.amountField.table = options.amountField.table;
      } else {
        this.amountField.table = this.getDefaultAmountFieldTable();
      }
    } else {
      this.amountField = this.getDefaultAmountFieldData();
    }
    if (options?.filterTypesValues) {
      this.filterTypesValues = options.filterTypesValues;
    } else {
      this.filterTypesValues = [];
    }
    if (options?.createdAt) {
      this.createdAt = options.createdAt;
    } else {
      this.createdAt = Date.now();
    }
    if (options?.updatedAt) {
      this.updatedAt = options.updatedAt;
    } else {
      this.updatedAt = Date.now();
    }
    if (options?.filtersForm) {
      const filtersFormFactory = new FiltersFormFactory();
      this.filtersForm = filtersFormFactory.initFrom(
        options.filtersForm,
        this.connectorFieldsName
      );
    } else {
      this.filtersForm = null;
    }
    if (options?.madMlSqlQuery) {
      this.madMlSqlQuery = options.madMlSqlQuery;
    } else {
      this.madMlSqlQuery = '';
    }
  }

  getDefaultAmountFieldValue(): string {
    const amountFieldValue =
      this.connectorFieldsName
        ?.find((connectorFieldName) => {
          return (
            connectorFieldName.title.toLowerCase() ===
            this.getDefaultAmountFieldTable().toLowerCase()
          );
        })
        ?.fields.find((field) => {
          if (this.connector === 'salesforce') {
            return field.name === SALESFORCE_DEFAULT_AMOUNT_FIELD_NAME;
          }
          if (this.connector === 'hubspot') {
            return field.name === HUBSPOT_DEFAULT_AMOUNT_FIELD_NAME;
          }
          return field;
        })?.name || '';
    if (!amountFieldValue) {
      return this.connectorFieldsName?.find((connectorFieldName) => {
        return (
          connectorFieldName.title.toLowerCase() ===
          this.getDefaultAmountFieldTable().toLowerCase()
        );
      })?.fields[0]?.name;
    }
    return amountFieldValue;
  }

  getDefaultAmountFieldTable(): 'Deal' | 'Opportunity' | 'invoice' {
    return getAmountFieldByConnector(this.connector);
  }

  getDefaultAmountFieldData(): AmountFieldModel {
    return {
      value: this.getDefaultAmountFieldValue(),
      table: this.getDefaultAmountFieldTable(),
    };
  }

  async init() {
    if (!this.isCustom) {
      if (!this.filtersForm) {
        const filtersFormFactory = new FiltersFormFactory();
        this.filtersForm = await filtersFormFactory.createFiltersForm(
          this.tenant,
          this.connector,
          this.conversionType,
          this.connectorFieldsName
        );
      }
    }
  }

  getFields(table?: string): FieldDataModel[] {
    if (!this.connectorFieldsName || !table) return [];

    const fields: FieldDataModel[] =
      this.connectorFieldsName?.find((connectorFieldName) => {
        const tableName = table
          ? table.toLowerCase()
          : getAmountFieldByConnector(this.connector).toLowerCase();
        return connectorFieldName.title.toLowerCase() === tableName;
      })?.fields || this.connectorFieldsName[0]?.fields;
    return fields ?? [];
  }

  setLowerDefaultFilter(parameterIndex: number): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.setLowerOfParameter(parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  addParameterToDefaultFilter(): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.addParameter();
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  addValueToParameter(parameterIndex: number): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.addValue(parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  removeValueOfParameter(parameterIndex: number): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.removeValue(parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeLogicOfDefaultFilter(newLogic: string): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.changeLogic(newLogic);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeValueOfDefaultFilter(
    newValue: string,
    parameterIndex: number,
    valueIndex: number
  ): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.changeValue(newValue, parameterIndex, valueIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeTableValueOfDefaultFilter(
    newTableValue: string,
    parameterIndex: number
  ): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.changeTable(newTableValue, parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeFieldValueOfDefaultFilter(
    newFieldValue: string,
    parameterIndex: number
  ): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.changeField(newFieldValue, parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeVerbOfDefaultFilterParameters(
    newVerbValue: string,
    parameterIndex: number
  ): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.changeVerb(newVerbValue, parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  removeParameterOfDefaultFilter(parameterIndex: number): DefaultFiltersForm {
    if (this.filtersForm instanceof DefaultFiltersForm) {
      this.filtersForm.removeParameter(parameterIndex);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeThresholdOfStripeFilter(newThreshold: number): StripeFiltersForm {
    if (
      this.filtersForm instanceof StripeFiltersForm &&
      this.conversionType === 'SQO'
    ) {
      this.filtersForm.changeSqoAmountThreshold(newThreshold);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeSourceSystemOfAnalyticsFilter(
    newSourceSystem: SourceSystems
  ): AnalyticsFiltersForm {
    if (
      this.filtersForm instanceof AnalyticsFiltersForm &&
      this.conversionType === 'Closed Won'
    ) {
      this.filtersForm.changeSourceSystem(newSourceSystem);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeConversionEventsfAnalyticsFilter(
    newConversionEvents: string
  ): AnalyticsFiltersForm {
    if (
      this.filtersForm instanceof AnalyticsFiltersForm &&
      this.conversionType === 'Closed Won'
    ) {
      this.filtersForm.changeConversionEvents(newConversionEvents);
      this.updatedAt = Date.now();
      return this.filtersForm;
    }
  }

  changeAmountFieldValue(newAmountFieldValue: string): string {
    this.amountField.value = newAmountFieldValue;
    this.updatedAt = Date.now();
    return newAmountFieldValue;
  }

  changeAmountFieldTable(newAmountFieldTable: string): string {
    this.amountField.table = newAmountFieldTable;
    this.updatedAt = Date.now();
    return newAmountFieldTable;
  }

  changeFilterTypesValues(newFilterTypesValues: string): string[] {
    this.filterTypesValues = newFilterTypesValues.split(',');
    this.updatedAt = Date.now();
    return this.filterTypesValues;
  }

  changeMadMlSqlQuery(newMadMlSqlQuery: string): string {
    if (this.isCustom) {
      this.madMlSqlQuery = newMadMlSqlQuery;
      this.updatedAt = Date.now();
      return this.madMlSqlQuery;
    }
  }

  prepare() {
    delete this.connectorFieldsName;
    if (this.isCustom) {
      this.madMlSqlQuery = this.madMlSqlQuery
        .split('\n')
        .map((lane) => `      ${lane.trim()}`)
        .join('\n');
    }
    if (this.filterTypesValues) {
      this.filterTypesValues.map((filterType) =>
        filterType?.trim().toLowerCase()
      );
    }
    this.filtersForm?.prepare();
  }

  isDefaultConfiguration(): boolean {
    return (
      isEqual(this.amountField, this.getDefaultAmountFieldData()) &&
      isEqual(this.filterTypesValues, [])
    );
  }
}
