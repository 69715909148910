import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import FieldsMapping from './fields_mapping';
import testConnection from './test_connection';
import { deleteIntegrationAndLaunchPopUp } from './utils';

const channel = Radio.channel('integration');
const template = require('./salesforce.pug');

export default Backbone.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
    'click #mki_oauth_btn': 'redirect_to_oauth',
    'click #mki_delete_connection': 'delete',
    'click #mki_oauth_btn_with_ack': 'redirect_to_oauth_with_ack',
  },

  async initialize(options: { tenant: number; context: string }) {
    this.tenant = options.tenant;
    this.integration = 'salesforce';
    this.context = options.context;
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  async delete() {
    await deleteIntegrationAndLaunchPopUp(this.tenant, 'salesforce');
  },

  render() {
    this.$el.empty();

    const credentialsFound = this.model.get('not_found') !== true;

    this.model.set({
      password: null,
      security_token: null,
      password_placeholder: credentialsFound
        ? '*****'
        : 'e.g. mypasswordXXXXXXXXXX',
      security_token_placeholder: credentialsFound
        ? '*****'
        : 'e.g. 6wiIBWbGkBMo1mRDMuVwkw1EPsNkeUj95PIz2akv',
      credentialsFound,
    });

    const html = $(
      '#tpl_main',
      template({
        integration: this.integration,
        credentialsFound,
      })
    ).html();
    this.$el.html(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    if (this.model.get('instance_url')) {
      this.render_oauth_connected();
    } else {
      this.render_oauth();
      this.render_classic();
    }
  },

  render_oauth() {
    const html = $(
      '#tpl_oauth',
      template({ integration: this.integration })
    ).html();
    $('#mki_salesforce_oauth').append(html);
    return this;
  },

  redirect_to_oauth() {
    window.location.href = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/oauth/redirect`;
  },

  async redirect_to_oauth_with_ack() {
    const { isConfirmed } = await Swal.fire({
      icon: 'warning',
      title:
        'You are about to change the Salesforce account connected to MadKudu',
      text:
        'This action will update the integration settings and may affect data synchronization between Salesforce and MadKudu. Please ensure the new Salesforce user account has the necessary permissions.',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'I want to continue',
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
    });

    if (isConfirmed) {
      window.location.href = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/oauth/redirect`;
    }
  },

  render_oauth_connected() {
    const html = $(
      '#tpl_connected',
      template({
        integration: this.integration,
        instance_url: this.model.get('instance_url'),
      })
    ).html();
    $('#mki_salesforce_oauth').append(html);
    return this;
  },

  render_classic() {
    const credentials = this.model.attributes;
    const html = $('#tpl_classic', template({ ...credentials })).html();
    $('#mki_salesforce_classic').append(html);
    return this;
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();

    const credentials: UnknownObject = {};
    credentials.username = $('#mki_input_username').val();
    credentials.password = $('#mki_input_password').val();
    credentials.security_token = $('#mki_input_security_token').val();
    credentials.sandbox = $('#mki_input_sandbox').prop('checked');

    let validationMessage;

    if (!credentials.username || credentials.username === '') {
      validationMessage = 'Please enter your username';
    } else if (!credentials.password || credentials.password === '') {
      validationMessage = 'Please enter your password';
    } else if (
      !credentials.security_token ||
      credentials.security_token === ''
    ) {
      validationMessage = 'Please enter your security token';
    }

    if (validationMessage) {
      return Swal.fire({
        title: 'Error',
        text: validationMessage,
        icon: 'error',
      });
    }

    const show_error_message = function (message: string, title: string) {
      Swal.fire({
        title: title || 'Internal error',
        text: message,
        icon: 'error',
      });
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true&activate_push=true`,
        credentials
      )
      .then(() => {
        testConnection(this.tenant, this.integration)
          .then(() =>
            channel.trigger('success', {
              integration: this.integration,
              alert: true,
            })
          )
          .catch((err: any) =>
            show_error_message(err.message, 'Unable to connect to Salesforce')
          );
      })
      .catch((err: any) => {
        const error = err.response && err.response.data;
        show_error_message(error.message, 'Unable to save your credentials');
      });
  },
});
