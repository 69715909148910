import React from 'react';
import capitalize from 'lodash/capitalize';
import OverviewComponent from './OverviewComponent';
import ConfigurationComponent from './ConfigurationComponent';
import { FormDataType } from '../../../types/index';
import TablePullDisplayComponent from '../../components/TablePullDisplayComponent';
import { ConnectorFieldNameDataModel } from '../../../mapping/models/ConnectorFieldNameDataModel';
import { getPullConfigForTenantAndIntegration } from '../../utils/index';

export type TabValues = 'overview' | 'configuration' | 'pull';

type S3ParentComponentProps = {
  tenant: number;
  getActivePage: () => TabValues;
  toggleView: Function;
  formData: FormDataType;
  apiKey: string;
  credentialsFound: boolean;
};

type S3ParentComponentState = {
  activeTab: TabValues;
  pullFields: ConnectorFieldNameDataModel[];
  tabNames: TabValues[];
};

export default class S3ParentComponent extends React.Component<
  S3ParentComponentProps,
  S3ParentComponentState
> {
  constructor(props: S3ParentComponentProps) {
    super(props);

    const currentView = props.getActivePage();

    this.state = {
      activeTab: currentView,
      pullFields: [],
      tabNames: ['overview', 'configuration'],
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  async componentDidMount() {
    const { toggleView, tenant, credentialsFound } = this.props;
    const { activeTab, tabNames } = this.state;

    const pullFields = await getPullConfigForTenantAndIntegration(tenant, 's3');

    if (pullFields?.length > 0 && credentialsFound) {
      tabNames.push('pull');
    }

    this.setState({ pullFields, tabNames });
    toggleView(activeTab);
  }

  handleTabClick = (event: any) => {
    this.setState({ activeTab: event.target.id });
    const { toggleView } = this.props;
    return toggleView(event.target.id);
  };

  render() {
    const { tabNames, activeTab, pullFields } = this.state;
    const { tenant, formData, apiKey } = this.props;

    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
          <ul className="nav nav-pills mx-auto">
            {tabNames.map((tabName) => (
              <li className="nav-item" key={tabName}>
                <a
                  className={`nav-link ${
                    activeTab === tabName ? 'active show' : ''
                  }`}
                  id={tabName}
                  data-toggle="tab"
                  href={`#${tabName}`}
                  onClick={this.handleTabClick}
                  role="tab"
                >
                  {capitalize(tabName)}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <>
          {tabNames.map((tabName, index) => (
            <div className="row ml-4" key={`tab_content_${index}`}>
              <div className="tab-content w-100" id="TabContent">
                <div
                  key={`tab_content_${tabName}`}
                  className={`tab-pane fade ${
                    activeTab === tabName ? 'active show' : ''
                  }`}
                  id={`${tabName}-tab`}
                  role="tabpanel"
                >
                  <div className="row justify-content-center">
                    <div className="col-8 offset-2 mb-4">
                      <div className="card box-shadow-3d mt-md-2 d-flex w-75">
                        <div className="card-header">
                          <div className="d-flex">
                            <img
                              className="con-map-mk-integration-logo-size"
                              style={{ width: '30px' }}
                              src={`/media/integrations/logos/s3-icon.png`}
                            />
                            <h5 className="mb-0 ml-3">Amazon S3</h5>
                          </div>
                        </div>
                        <div className="card-body">
                          {tabName === 'configuration' && (
                            <ConfigurationComponent
                              formData={formData}
                              tenant={tenant}
                              apiKey={apiKey}
                            />
                          )}
                          {tabName === 'overview' && (
                            <OverviewComponent tenant={tenant} />
                          )}
                          {tabName === 'pull' && (
                            <TablePullDisplayComponent
                              pullFields={pullFields}
                              integration="s3"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      </>
    );
  }
}
