export const chilipiper = `
  // load ChiliPiper javascript
  form.load_external_script('https://js.chilipiper.com/marketing.js'); 
  
  var styles = " \
    .madkudu_modal__overlay { \
      background: {backgroundColor}; \
      opacity: 1; \
    } \
    .madkudu_modal__canvas, \
    button { \
      font-family: inherit; \
    } \
    button.madkudu_modal__accept { \
      line-height: 1; \
      color: #FFF; \
      display: inline-block; \
      text-transform: none; \
      letter-spacing: 0; \
      border: 0; \
      cursor: pointer; \
      text-decoration: none; \
      outline: none; \
      transition: background-color 0.2s ease-in, color 0.2s ease-in; \
      width: auto; \
      background-color: {buttonColor}; \
      font-size: 1rem; \
      font-family: inherit; \
      font-weight: 900; \
      display: block; \
      margin: 0 auto 1rem; \
      padding: 1rem 1.5rem; \
      border-radius: 7rem; \
    } \
    button.madkudu_modal__accept:hover { \
      background: #F2D258; \
    } \
    button.madkudu_modal__accept:focus { \
      background: #E6574B; \
      box-shadow: 0 0 0 .25rem rgba(255,97,83,.2); \
    } \
    .madkudu_modal__cancel { \
      line-height: 1; \
      text-transform: none; \
      letter-spacing: 0; \
      text-decoration: none; \
      outline: none; \
      width: auto; \
      margin-left: 0; \
      margin-right: 0; \
      text-decoration: none; \
      font-size: .825rem; \
      font-family: inherit; \
      border-bottom: 1px solid rgba(255, 255, 255, .4); \
      padding: 0 0 .25rem; \
      transition: border-bottom-color 0.2s ease-in-out; \
    } \
    button.madkudu_modal__cancel:hover { \
      border-bottom-color: rgba(255, 255, 255, 1); \
      color: white; \
    } \
    .madkudu_modal__title { \
      font-size: 3rem; \
      line-height: 1.4; \
      color: #FFF; \
      padding-bottom: 2rem; \
      text-transform: none; \
      letter-spacing: 0; \
      font-weight: 900; \
    } \
    .madkudu_modal__subtitle { \
      color: #FFF; \
      font-size: 1.25rem; \
      line-height: 1.5; \
      padding-bottom: 2rem; \
      font-weight: 400; \
    } \
  ";

  var load_style = function (styles) {
    var css = document.createElement('style')
    css.type = 'text/css';
    if (css.styleSheet) css.styleSheet.cssText = styles;
    else css.appendChild(document.createTextNode(styles));
    document.getElementsByTagName('head')[0].appendChild(css);
  };

  load_style(styles);
  /* END STYLING */

  var elem_id = '#mk_smart_form';

  var traits = madkudu.user().traits();
  var firstName = traits.first_name;

  if (!firstName) {
    firstName = 'Howdy';
  }

  var success_state = false;

  const chili_options = {
    title: '{title}',
    lead: {
      FirstName: traits.first_name || '',
      LastName: traits.last_name || ' ',
      Email: traits.email,
      Company: traits.company && traits.company.name ? traits.company.name : ''
    },
    onSuccess: function () {
      success_state = true;
      // Optional code if you have Segment and want to track modal interactions
      // analytics.track('Fast Lane Calendar Clicked');
      form.dismiss_modal();
    },
    onClose: function () {
      if (success_state) {
        // redirect to your website here after a meeting is booked
        window.location.href = '{Domain}';
      }
    }
  }

  var modalOptions = {
    title: firstName + ', {title}',
    subtitle: '{subtitle}',
    cta_yes: '{ctaYes}',
    cta_no: '{ctaNo}', 
  };

  $(document).ready(function() {
    {triggerOptions}
  });

  setTimeout( function () {
    ChiliPiper.submit({Chilipiper_Router}, chili_options);
  }, 300);
`;

export const calendly = `
  var styles = " \
    .madkudu_modal__overlay { \
      background: {backgroundColor}; \
      opacity: 1; \
    } \
    .madkudu_modal__canvas, \
    button { \
      font-family: inherit; \
    } \
    button.madkudu_modal__accept { \
      line-height: 1; \
      color: #FFF; \
      display: inline-block; \
      text-transform: none; \
      letter-spacing: 0; \
      border: 0; \
      cursor: pointer; \
      text-decoration: none; \
      outline: none; \
      transition: background-color 0.2s ease-in, color 0.2s ease-in; \
      width: auto; \
      background-color: {buttonColor}; \
      font-size: 1rem; \
      font-family: inherit; \
      font-weight: 900; \
      display: block; \
      margin: 0 auto 1rem; \
      padding: 1rem 1.5rem; \
      border-radius: 7rem; \
    } \
    button.madkudu_modal__accept:hover { \
      background: #F2D258; \
    } \
    button.madkudu_modal__accept:focus { \
      background: #E6574B; \
      box-shadow: 0 0 0 .25rem rgba(255,97,83,.2); \
    } \
    .madkudu_modal__cancel { \
      line-height: 1; \
      text-transform: none; \
      letter-spacing: 0; \
      text-decoration: none; \
      outline: none; \
      width: auto; \
      margin-left: 0; \
      margin-right: 0; \
      text-decoration: none; \
      font-size: .825rem; \
      font-family: inherit; \
      border-bottom: 1px solid rgba(255, 255, 255, .4); \
      padding: 0 0 .25rem; \
      transition: border-bottom-color 0.2s ease-in-out; \
    } \
    button.madkudu_modal__cancel:hover { \
      border-bottom-color: rgba(255, 255, 255, 1); \
      color: white; \
    } \
    .madkudu_modal__title { \
      font-size: 3rem; \
      line-height: 1.4; \
      color: #FFF; \
      padding-bottom: 2rem; \
      text-transform: none; \
      letter-spacing: 0; \
      font-weight: 900; \
    } \
    .madkudu_modal__subtitle { \
      color: #FFF; \
      font-size: 1.25rem; \
      line-height: 1.5; \
      padding-bottom: 2rem; \
      font-weight: 400; \
    } \
  ";

  var load_style = function (styles) {
    var css = document.createElement('style')
    css.type = 'text/css';
    if (css.styleSheet) css.styleSheet.cssText = styles;
    else css.appendChild(document.createTextNode(styles));
    document.getElementsByTagName('head')[0].appendChild(css);
  };

  load_style(styles);
  /* END STYLING */

  var elem_id = '#mk_smart_form';

  var traits = madkudu.user().traits();
  var firstName = traits.first_name;

  if (!firstName) {
    firstName = 'Howdy';
  }

  var modalOptions = {
    calendlyUrl: '{Calendly_URL}?email=' + traits.email,
    title: firstName + ', {title}',
    subtitle: '{subtitle}',
    cta_yes: '{ctaYes}',
    cta_no: '{ctaNo}'
  };

  $(document).ready(function() {
    {triggerOptions}
  });

  const on_meeting_booked = function () {
    // redirect
    window.location.href = '{Domain}';
  }
`;
