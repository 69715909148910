import axios from 'axios';
import Backbone from 'backbone';
import app from '../../app';
import getActiveInsightsPages from '../../evr_insights/utils';
import { BackboneViewProps } from '../../types';
import { swalWithBootstrapButtons } from '../../integrations/configure/utils';
import { createAsanaTask, slackNotification } from '../../utils';

const $ = require('jquery');
const template = require('./hubspot.pug');

const STATUS_DESCRIPTIONS: { [key: string]: string } = {
  data_preparation:
    'We prepare customer data profiles by making sense of your raw data from various connected sources.',
  configuration:
    'We build a model to predict the fit and likelihood of conversion of your leads or accounts.',
  scoring:
    'We push the MadKudu scores and signals to your platform of choice, and prepare automated workflows based on the scores.',
};

function highlight(subclass: string) {
  $('.sidebar__nav *').removeClass('active');
  $(`li.sidebar__nav--${subclass}`).addClass('active');
}

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #goL1_Leadsource': 'goL1_Leadsource',
    'click #go_L2': 'goL2',
    'click #go_L3': 'goL3',
    'click #go-data-discovery': 'go_data_discovery',
    'click #go-insights': 'go_insights',
    'click #go-mapping': 'go_mapping',
    'click #go-integrations': 'go_integrations',
    'click #go-predictions': 'go_predictions',
    'click #go-profiles': 'go_profiles',
    'click #go-evr': 'go_evr',
    'click #check_step_1': 'check_step_1',
    'click #check_step_2': 'check_step_2',
    'click #check_step_3': 'check_step_3',
    'click #beta-access_L3': 'go_beta_L3',
    'click #access_L1_leadsource': 'go_L1_leadsource',
  },

  // goL1() {
  //   Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l1`, true);
  // },
  goL1_Leadsource() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/l1_leadsource`,
      true
    );
  },
  goL2() {
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l2`, true);
  },
  goL3() {
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l3`, true);
  },
  go_integrations() {
    highlight('integrations');
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_predictions() {
    highlight('predictions');
    Backbone.history.navigate(`/org/${this.tenant}/predictions`, true);
  },
  go_profiles() {
    highlight('profiles');
    Backbone.history.navigate(`/org/${this.tenant}/profiles`, true);
  },

  go_evr() {
    highlight('insights');
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights`, true);
  },
  async go_beta_L3() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L3 Hand Raiser SLA`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_L1_leadsource() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L1 Leadsource report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async initialize(paramOptions: BackboneViewProps) {
    const options = paramOptions || {};
    this.tenant = options.tenant || this.tenant;
    this.activeInsightsPages = await getActiveInsightsPages(this.tenant);
    this.model.fetch({ success: () => this.render() });
    const user = app.session.get('user');
    this.superKudu = user.superKudu;

    let { steps } = await this.get_status();

    if (!steps || !steps.length) {
      steps = [
        {
          step_id: 'data_preparation',
          status: 'not_started',
        },
        {
          step_id: 'configuration',
          status: 'not_started',
        },
        {
          step_id: 'scoring',
          status: 'not_started',
        },
      ];
    }

    const [step1, step2, step3] = steps;

    this.step1Title = this.capitalize_and_clean(step1.step_id);
    this.step1Status = this.capitalize_and_clean(step1.status);
    this.step1StatusRaw = step1.status;
    this.step1Description = STATUS_DESCRIPTIONS[step1.step_id];

    this.step2Title = this.capitalize_and_clean(step2.step_id);
    this.step2Status = this.capitalize_and_clean(step2.status);
    this.step2StatusRaw = step2.status;
    this.step2Description = STATUS_DESCRIPTIONS[step2.step_id];

    this.step3Title = this.capitalize_and_clean(step3.step_id);
    this.step3Status = this.capitalize_and_clean(step3.status);
    this.step3StatusRaw = step3.status;
    this.step3Description = STATUS_DESCRIPTIONS[step3.step_id];

    this.render();
  },

  capitalize_and_clean(phrase: string) {
    return phrase
      .split('_')
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join(' ');
  },

  go_back() {
    return this.render();
  },

  go_data_discovery() {
    highlight('data');
    Backbone.history.navigate(`/org/${this.tenant}/data`, true);
  },

  go_insights() {
    highlight('settings');
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights`, true);
  },

  go_mapping() {
    highlight('mapping');
    Backbone.history.navigate(`/org/${this.tenant}/mapping`, true);
  },

  async get_status() {
    try {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/status/velocity`
      );
      return data;
    } catch (e) {
      return [];
    }
  },

  async change_status(newStepIndex: number) {
    $('[data-toggle="tooltip"]').tooltip('dispose');
    try {
      const { steps } = await this.get_status();
      const currentStepStatus = steps[newStepIndex].status;
      const newSteps = steps.map((step: { step_id: number }, i: number) => {
        /* eslint no-nested-ternary: 0 */
        const { step_id } = step;
        let status;
        if (currentStepStatus === 'completed') {
          // unmark as completed (= mark as in progress)
          status =
            i === newStepIndex
              ? 'in_progress'
              : i < newStepIndex
              ? 'completed'
              : 'not_started';
        } else {
          // mark the step and the previous ones as completed
          status =
            i <= newStepIndex
              ? 'completed'
              : i === newStepIndex + 1
              ? 'in_progress'
              : 'not_started';
        }
        return {
          step_id,
          status,
        };
      });
      await axios.put(`${BONGO_URL}/v1/org/${this.tenant}/status/velocity`, {
        steps: newSteps,
      });
      this.initialize({
        tenant: this.tenant,
      });
    } catch (e) {
      console.warn(e);
    }
  },

  check_step_1() {
    this.change_status(0);
  },

  check_step_2() {
    this.change_status(1);
  },

  check_step_3() {
    this.change_status(2);
  },

  render() {
    const html = $(
      '#tpl_main',
      template({
        tenant: this.tenant,
        superKudu: this.superKudu,
        step1Title: this.step1Title,
        step1Status: this.step1Status,
        step1StatusRaw: this.step1StatusRaw,
        step1Description: this.step1Description,
        step2Title: this.step2Title,
        step2Status: this.step2Status,
        step2StatusRaw: this.step2StatusRaw,
        step2Description: this.step2Description,
        step3Title: this.step3Title,
        step3Status: this.step3Status,
        step3StatusRaw: this.step3StatusRaw,
        step3Description: this.step3Description,
        activeInsightsPages: this.activeInsightsPages,
      })
    ).html();
    this.$el.html(html);
    $('.carousel', this.$el).carousel();
    $('[data-toggle="tooltip"]').tooltip();
    this.currentSlide = 0;
    return this;
  },
});
