import React, { useState } from 'react';
import { Loader } from '../../../../components/Loader';
import EventMappingConnectorsNames from '../../../enums/EventMappingConnectorsNames';
import MappingsMode from '../../../enums/MappingsMode';
import MadMlEditModeComponent from '../../../madMlMapping/reactComponents/MadMlEditModeComponent';
import {
  launchActivateConnectorPopup,
  launchDeactivateConnectorPopup,
} from '../../swal';
import { activateConnector, deactivateConnector } from '../generic/utils';
import EventMappingHeader from './EventMappingHeader';
import EventMappingTable from './EventMappingTable';
import useEventMapping from './hooks/useEventMapping';
import { SelectOptionLike } from './utils';

type EventMappingEditorProps = {
  email: string;
  tenant: number;
  connector: string;
  superKudu: boolean;
  isEventsEmpty: boolean;
};

export default function EventMappingEditor(props: EventMappingEditorProps) {
  const eventMappingProps = useEventMapping(props);
  const {
    tenant,
    email,
    isLoading,
    scriptChecker,
    eventHistory,
    setHistoryId,
    refreshAll,
    hasEventErrors,
    superKudu,
    model,
    connector,
    eventErrors,
    pullConfig,
    discovery,
    isReady,
    isLoadingEvents,
  } = eventMappingProps;
  const [isEditable, setEditable] = useState(false);
  if (isLoading)
    return (
      <div className="mt-5 ml-5 mr-5">
        <Loader />
      </div>
    );
  const toggleActiveConnector = () => {
    const {
      isConnectorActive,
      disabledConnectors,
      toggleConnector,
    } = eventHistory;
    if (isConnectorActive) {
      const newDisabledConnectors: string[] = deactivateConnector(
        connector as EventMappingConnectorsNames,
        disabledConnectors
      );

      return launchDeactivateConnectorPopup(async () => {
        await toggleConnector(newDisabledConnectors, false);
      });
    }
    const newDisabledConnectors: string[] = activateConnector(
      connector as EventMappingConnectorsNames,
      disabledConnectors
    );

    return launchActivateConnectorPopup(async () => {
      await toggleConnector(newDisabledConnectors, true);
    });
  };

  const hasConditionErrors = eventErrors.some(
    ({ level }) => ['verb', 'subject', 'object', 'values'].indexOf(level) >= 0
  );
  const hasConditionLogicError = eventErrors.find(({ level }) =>
    level.includes('logic')
  );

  return (
    <React.Fragment>
      <EventMappingHeader
        hasEventErrors={hasEventErrors}
        scriptChecker={scriptChecker}
        eventHistory={eventHistory}
        editable={isEditable}
        historyOptions={eventHistory.historyOptions}
        editForm={setEditable}
        copy={() => model.launchCopySwal(eventHistory.events)}
        saveEvents={async () => {
          await model.save(
            eventHistory.isCustom
              ? eventHistory.madMlSqlQuery
              : eventHistory.events.map((event) => {
                  const _event = { ...event };
                  if (_event.originalIndex) {
                    delete _event.originalIndex;
                  }
                  return _event;
                })
          );
          eventHistory.saveDraft();
        }}
        publishEvents={() =>
          model.launchPublishSwal(
            eventHistory.isCustom
              ? eventHistory.madMlSqlQuery
              : eventHistory.events.map((indexedEvent) => {
                  const event = { ...indexedEvent };
                  delete event.originalIndex;
                  return event;
                }),
            eventHistory.history.length === 0,
            () => setEditable(false),
            refreshAll
          )
        }
        superKudu={superKudu}
        handleOnHistoryChange={({ value }: SelectOptionLike) =>
          setHistoryId(value as number)
        }
      />
      {!eventHistory.isCustom && hasEventErrors && (
        <div className="row ml-5 mr-5">
          <div className="col mb-2">
            <div className="card d-flex h-100 border-danger bg-danger-fade text-danger">
              <div className="card-body">
                <h5 className="mb-0">
                  There are some errors with the campaigns:
                </h5>
                <ul className="mb-0">
                  {hasConditionErrors && (
                    <li>Some events have missing condition values</li>
                  )}
                  {hasConditionLogicError && (
                    <li>
                      Some events have incorrect condition logic being applied
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {scriptChecker.isScriptCheckerActive && (
        <div className="row ml-5 mr-5">
          <div className="col mb-2">
            <div className="alert alert-warning box-shadow-3d d-flex alert-No-Data">
              <div className="spinner-border float-left mr-2"></div>
              <p>
                Your configuration has been submitted and is currently being
                processed. Please wait for an email to confirm success or
                failure of publish.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="row ml-5 mr-5">
        <div className="col">
          <div id="event-mapping-root">
            {eventHistory.isCustom ? (
              <MadMlEditModeComponent
                tenant={tenant}
                email={email}
                mappingMode={MappingsMode.event}
                goToDisplayMode={null}
                goBackToOverview={null}
                isEventMapping={true}
                isMadMlActive={eventHistory.isConnectorActive}
                isReadOnly={!superKudu}
                toggleActiveConnector={toggleActiveConnector}
                setStatePostPublish={refreshAll}
                madMlSqlQuery={eventHistory.madMlSqlQuery}
                scriptCheckerState={scriptChecker.isScriptCheckerActive}
                reloadScriptCheckerState={
                  scriptChecker.refreshScriptCheckerStatus
                }
                handleSqlQueryChange={eventHistory.setMadMlSqlQuery}
              />
            ) : (
              <EventMappingTable
                editable={isEditable}
                toggleEditable={() => setEditable(!isEditable)}
                toggleActiveConnector={toggleActiveConnector}
                email={email}
                tenant={tenant}
                connector={connector}
                superKudu={superKudu}
                eventHistory={eventHistory}
                pullConfig={pullConfig}
                discovery={discovery}
                scriptChecker={scriptChecker}
                setHistoryId={setHistoryId}
                refreshAll={refreshAll}
                model={model}
                isLoading={isLoading}
                isReady={isReady}
                isLoadingEvents={isLoadingEvents}
                eventErrors={eventErrors}
                hasEventErrors={hasEventErrors}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
