import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { BackboneViewProps } from '../../types';
import ClearbitParentComponent, {
  TabValues,
} from './ReactComponents/ClearbitParentComponent';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'clearbit';
    setTimeout(() => {
      this.render();
      this.renderClearbitIntegrationComponent();
    }, 250);
  },

  getActivePage(): TabValues {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === 'clearbit') {
      return 'overview';
    }
    return lastItem as TabValues;
  },

  toggleView(tenant: string, integration: string) {
    return (page: TabValues) => {
      Backbone.history.navigate(
        `/org/${tenant}/integrations/${integration}/${page}`
      );
    };
  },

  renderClearbitIntegrationComponent() {
    const containerDomElement = $('#ClearbitParentComponentContainer')[0];

    if (typeof containerDomElement !== 'undefined') {
      ReactDOM.render(
        <ClearbitParentComponent
          tenant={this.tenant}
          getActivePage={this.getActivePage}
          toggleView={this.toggleView(this.tenant, this.integration)}
        />,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
