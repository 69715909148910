import React from 'react';

type ErrorBoundaryProps = {
  children: any;
  alertBlock: JSX.Element;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { children, alertBlock } = this.props;
    if (this.state.hasError) {
      return alertBlock;
    }
    return children;
  }
}
