import React, {
  FormEvent,
  Fragment,
  HTMLProps,
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlProps,
  FormGroup,
} from 'react-bootstrap';
import {
  SortableElement,
  SortableElementProps,
  SortableHandle,
} from 'react-sortable-hoc';

import ReactTooltip from 'react-tooltip';
import Select, { GroupHeadingProps } from 'react-select';

import CreateableSelect from 'react-select/creatable';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Condition } from '../../models/types';
import EventMappingConditionForm from './EventMappingConditionForm';
import EventMappingConditionList from './EventMappingConditionList';
import useSwal from './hooks/primitives/useSwal';
import {
  EventMappingHistoryManager,
  IndexedEvent,
} from './hooks/useEventMappingHistory';
import Displayable from './utils/Displayable';
import { SelectOptionLike } from './utils';
import { UsePullConfigResponse } from './hooks/usePullConfig';
import { TrackedEventValidationError } from './hooks/useEventMapping';
import useStateReducer from './hooks/primitives/useStateReducer';
import ActivityType from '../../../enums/ActivityType';
import { EventMappingMkEventField } from './EventMappingMkEventField';

export type EventMappingRowProps = SortableElementProps & {
  eventTypes: SelectOptionLike[];
  event: IndexedEvent;
  eventIndex: number;
  isFiltered: boolean;
  activityTypes: SelectOptionLike[];
  salesforceStatuses: SelectOptionLike[];
  isSorted: boolean;
  copyEvent: (event: IndexedEvent) => void;
  deleteEvent: (event: IndexedEvent) => void;
  pullConfig: UsePullConfigResponse;
  editable: boolean;
  hasEventErrors: boolean;
  eventErrors: TrackedEventValidationError[];
  eventHistory: EventMappingHistoryManager;
  connector: string;
  tenant: number;
  email: string;
};

type SelectOption = { label: string; value: string };

const WILDCARD_EXAMPLES = ['', 'abc', 'anything'];
const WILDCARD_REGEXP = /{{\*}}/g;

export const EventMappingRow = SortableElement(
  (eventMappingRowProps: EventMappingRowProps) => {
    const {
      event,
      pullConfig,
      editable,
      eventTypes,
      eventIndex,
      isFiltered,
      isSorted,
      salesforceStatuses,
      activityTypes,
      hasEventErrors,
      eventErrors,
      copyEvent,
      deleteEvent,
      eventHistory,
      connector,
      tenant,
      email,
    } = eventMappingRowProps;

    const {
      events,
      originalEvents,
      isAnalytics,
      isSalesforceCampaigns,
      isSalesforceTasks,
      setEvent,
      isUnsavedEvent,
    } = eventHistory;

    // Greatly improve performance on text inputs, as we commit only on blur.
    const [snapshot, setSnapshot] = useStateReducer(cloneDeep(event));
    useEffect(() => setSnapshot(cloneDeep(event)), [event]);
    const eventId = snapshot.id;
    const commitOnBlur = () => {
      const _event = cloneDeep(snapshot);
      if (_event.originalIndex || _event.originalIndex === 0) {
        delete _event.originalIndex;
      }
      setEvent(eventId, _event);
    };

    const excluded = snapshot.excludeFromMapping;
    const editableOverall = !excluded && !!editable;
    const isSortable = !isFiltered && !isSorted && editable;
    const isSalesforce = isSalesforceCampaigns || isSalesforceTasks;

    const conditions: Condition[] =
      snapshot?.conditionFormData?.conditions || [];

    const { Modal, fire, clickCancel, clickConfirm } = useSwal();

    const isUnsaved = isUnsavedEvent(event);
    const isMoved = originalEvents[snapshot.originalIndex]?.id !== snapshot.id;

    const defaultCellProps: HTMLProps<HTMLTableDataCellElement> = {
      // Idem, when being sorted, take up all available (flex container) space.
      style: { flexGrow: 1 },
    };

    const currentEventErrors = eventErrors.filter(
      (el) => el.id === snapshot.id
    );
    const hasConditionErrors = currentEventErrors.some(
      ({ level }) => ['verb', 'subject', 'object', 'values'].indexOf(level) >= 0
    );
    const hasConditionLogicError = currentEventErrors.find((el) =>
      el.level.includes('logic')
    );

    const renderWithBold = (text: string, example: string) => {
      const parts = text.split(WILDCARD_REGEXP);
      return (
        <>
          {parts.map((part, index) => (
            <Fragment key={`${part}`}>
              {part}
              {index < parts.length - 1 && <strong>{example}</strong>}
            </Fragment>
          ))}
        </>
      );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const GroupHeading = (_: GroupHeadingProps) => (
      <div className="popover-header">
        <strong>Tip:</strong>
        <span className="ml-1">
          You can create and select custom options with{' '}
          <code className="text-error-500">{'{{*}}'}</code>. Use this feature to
          match multiple events with similar labels.
        </span>
      </div>
    );

    const SortableHandler = ({
      children,
    }: Pick<PropsWithChildren<JSX.Element>, 'children'>) => {
      const RenderedHandler = SortableHandle(() => <>{children}</>);
      return <RenderedHandler />;
    };

    return (
      <tr
        className={
          isUnsaved || isMoved ? 'bg-warning-100 gap-6' : 'bg-white gap-6'
        }
      >
        <SortableHandler>
          <td className={isSortable ? 'cursor-grab' : ''}>
            <div className="pt-1 text-nowrap d-flex flex-row gap-4 align-items-center">
              {editable && (
                <i
                  className={[
                    'fas fa-grip-vertical',
                    !isSortable && 'text-secondary-200',
                  ].join(' ')}
                />
              )}
              <code className="text-primary-300">
                #{snapshot.originalIndex + 1}
              </code>
            </div>
          </td>
        </SortableHandler>
        <SortableHandler>
          <td className={isSortable ? 'cursor-grab' : ''}>
            <div className="pt-1 text-nowrap d-flex flex-row gap-4 align-items-center">
              <code className="text-secondary-500">
                {eventIndex === 0 && 'IF'}
                {eventIndex !== 0 &&
                  eventIndex < events.length - 1 &&
                  'ELSE IF'}
                {eventIndex !== 0 && eventIndex === events.length - 1 && 'ELSE'}
              </code>
            </div>
          </td>
        </SortableHandler>
        {isAnalytics && (
          <td {...defaultCellProps}>
            <div style={{ width: '90px' }}>
              <FormControl
                type="checkbox"
                className={[
                  'wide-checkbox',
                  editable ? 'cursor-pointer' : '',
                ].join(' ')}
                checked={snapshot.excludeFromMapping}
                onChange={({ currentTarget }: FormEvent<FormControlProps>) =>
                  setEvent(eventId, {
                    excludeFromMapping: !!currentTarget.checked,
                  })
                }
                onBlur={commitOnBlur}
                disabled={!editable}
              />
            </div>
          </td>
        )}
        <td {...defaultCellProps}>
          <div className="d-flex flex-column gap-2">
            <Displayable
              readOnlyContent={snapshot.event}
              readOnly={!editableOverall}
            >
              {isAnalytics ? (
                <FormGroup
                  style={{ marginBottom: 0 }}
                  className="popover-wrapper"
                >
                  <FormControl
                    type="text"
                    value={snapshot.event}
                    onChange={({ currentTarget }: any) => {
                      const cleanEventName = startCase(currentTarget?.value);
                      setSnapshot({
                        ...snapshot,
                        event: currentTarget?.value,
                        // Autocompletes meta event fields for analytics connectors (Segment, Amplitude, S3 etc.)
                        mkEventNameSignals: cleanEventName,
                        mkEventName: cleanEventName,
                      });
                    }}
                    onBlur={commitOnBlur}
                  />

                  <div className="popover-main">
                    <div className="popover-header">
                      <strong>Tip:</strong>
                      <span className="ml-1">
                        Automatically match multiple events with similar labels
                        using <code className="text-error-500">{'{{*}}'}</code>
                      </span>
                    </div>
                    {snapshot.event && snapshot.event.match(WILDCARD_REGEXP) && (
                      <div className="popover-body">
                        <strong>Will match:</strong>
                        <ul className="match-preview">
                          {WILDCARD_EXAMPLES.map((example, index) => (
                            <li key={index}>
                              {renderWithBold(snapshot.event, example)}
                              <FontAwesomeIcon
                                className="ml-1 text-success"
                                icon={faCheck}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </FormGroup>
              ) : (
                <CreateableSelect
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  options={[{ label: 'Group', options: eventTypes }]}
                  components={{ GroupHeading }}
                  value={{
                    label: snapshot.event,
                    value: snapshot.event,
                  }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minWidth: 200,
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      paddingTop: 0,
                    }),
                    group: (baseStyles) => ({
                      ...baseStyles,
                      paddingTop: 0,
                    }),
                  }}
                  onChange={(option: SelectOption) => {
                    const salesforceEventType = option?.value;
                    const salesforceEventName = isSalesforceCampaigns
                      ? `${event?.campaignMemberStatus} to ${salesforceEventType}`
                      : salesforceEventType;
                    setEvent(eventId, {
                      event: salesforceEventType,
                      // Autocompletes meta event fields for Salesforce campaigns/tasks
                      ...(isSalesforce
                        ? {
                            mkEventNameSignals: salesforceEventName,
                            mkEventName: salesforceEventName,
                          }
                        : null),
                    });
                  }}
                />
              )}
            </Displayable>
            {!!conditions?.length && !hasConditionLogicError && (
              <EventMappingConditionList
                event={event}
                currentEventErrors={currentEventErrors}
                isSalesforce={isSalesforce}
              />
            )}
            {editable && (
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() =>
                    fire({
                      title: `Additional conditions`,
                      width: '80vw',
                      allowOutsideClick: true,
                      showCancelButton: false,
                      showConfirmButton: false,
                      backdrop: true,
                    })
                  }
                >
                  Add new condition
                </button>
              </div>
            )}
            {!!currentEventErrors.length && (
              <div className="alert alert-danger">
                <ul className="mb-0" style={{ marginLeft: '-18px' }}>
                  {hasConditionErrors && <li>Missing values in conditions.</li>}
                  {hasConditionLogicError && (
                    <li>Condition logic is incorrect.</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </td>
        {isSalesforceCampaigns && (
          <td {...defaultCellProps}>
            <div style={{ minWidth: editableOverall ? 220 : undefined }}>
              <Displayable
                readOnlyContent={snapshot.campaignMemberStatus}
                readOnly={!editableOverall}
              >
                <CreateableSelect
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  components={{ GroupHeading }}
                  options={[
                    {
                      label: 'Group',
                      options: salesforceStatuses,
                    },
                  ]}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minWidth: 180,
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      paddingTop: 0,
                    }),
                    group: (baseStyles) => ({
                      ...baseStyles,
                      paddingTop: 0,
                    }),
                  }}
                  value={{
                    label: snapshot.campaignMemberStatus,
                    value: snapshot.campaignMemberStatus,
                  }}
                  onChange={(option: SelectOption) => {
                    const campaignMemberStatus = option?.value;
                    const campaignEventName = `${campaignMemberStatus} to ${event?.event}`;
                    setEvent(eventId, {
                      campaignMemberStatus,
                      // Autocompletes meta event fields for Salesforce campaigns
                      mkEventNameSignals: campaignEventName,
                      mkEventName: campaignEventName,
                    });
                  }}
                />
              </Displayable>
            </div>
          </td>
        )}
        <td>
          <div className="pt-1">
            <code className="text-secondary-500">THEN</code>
          </div>
        </td>
        <td {...defaultCellProps}>
          <div style={{ width: '110px' }}>
            <FormControl
              type="checkbox"
              className={[
                'wide-checkbox',
                editableOverall ? 'cursor-pointer' : '',
              ].join(' ')}
              checked={!event.nonUserActivity}
              onChange={({ currentTarget }: FormEvent<FormControlProps>) =>
                setEvent(eventId, { nonUserActivity: !currentTarget.checked })
              }
              disabled={!editableOverall}
            />
          </div>
        </td>
        <td {...defaultCellProps}>
          <div style={{ minWidth: 180 }}>
            <Displayable
              readOnlyContent={snapshot.mkEventNameSignals}
              readOnly={!editableOverall}
            >
              <EventMappingMkEventField
                connector={connector}
                onChange={({ currentTarget }: any) =>
                  setSnapshot({
                    ...snapshot,
                    mkEventNameSignals: currentTarget.value,
                  })
                }
                onBlur={commitOnBlur}
                value={snapshot.mkEventNameSignals}
              />
            </Displayable>
          </div>
        </td>
        <td {...defaultCellProps}>
          <div style={{ minWidth: 180 }}>
            <Displayable
              readOnlyContent={snapshot.mkEventName}
              readOnly={!editableOverall}
            >
              <EventMappingMkEventField
                connector={connector}
                onChange={({ currentTarget }: any) =>
                  setSnapshot({
                    ...snapshot,
                    mkEventName: currentTarget.value,
                  })
                }
                onBlur={commitOnBlur}
                value={snapshot.mkEventName}
              />
            </Displayable>
          </div>
        </td>
        <td {...defaultCellProps}>
          <div style={{ minWidth: 180 }}>
            <Displayable
              readOnlyContent={snapshot.activityType}
              readOnly={!editableOverall}
            >
              <Select
                menuPortalTarget={document.body}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minWidth: 160,
                  }),
                }}
                options={activityTypes as SelectOption[]}
                value={{
                  label: snapshot.activityType as string,
                  value: snapshot.activityType as string,
                }}
                onChange={(option: SelectOption) =>
                  setEvent(eventId, {
                    activityType: option?.value as ActivityType,
                  })
                }
              />
            </Displayable>
          </div>
        </td>
        <td>
          {editable && (
            <>
              <ReactTooltip
                place="top"
                getContent={(tip) => (
                  <div style={{ maxWidth: '300px' }}>{tip}</div>
                )}
              />
              <ButtonGroup>
                <Button
                  className="btn btn-light btn-outline-dark"
                  disabled={!editable}
                  data-tip={
                    'Duplicate event, the newly created event will appear below this hereby event.'
                  }
                  onClick={() => copyEvent(event)}
                >
                  <i
                    className="fas fa-copy"
                    style={{ pointerEvents: 'none' }}
                  />
                </Button>
                <Button
                  className="btn btn-light btn-outline-dark"
                  disabled={!editable}
                  data-tip={'Delete event'}
                  onClick={() => deleteEvent(event)}
                >
                  <i
                    className="fas fa-trash"
                    style={{ pointerEvents: 'none' }}
                  />
                </Button>
              </ButtonGroup>
            </>
          )}
        </td>
        <Modal>
          <EventMappingConditionForm
            clickCancel={clickCancel}
            clickConfirm={clickConfirm}
            setEvent={setEvent}
            connectorPullConfigFields={pullConfig.pullConfigFields}
            hasErrors={hasEventErrors}
            pullFieldProperties={pullConfig.pullConfigFieldProperties}
            event={cloneDeep(snapshot)}
            connector={connector}
            tenant={tenant}
            email={email}
          />
        </Modal>
      </tr>
    );
  }
);
