import Backbone from 'backbone';
import filter from 'lodash/filter';

import isEmpty from 'lodash/isEmpty';
import app from '../app';
import { NON_PUSH_PULL_INTEGRATIONS } from '../integrations/integration_list_data';
import { BackboneViewProps } from '../types';

const JSON_INDENT = 2;

require('backbone.epoxy');

export default {
  Integration_model: (Backbone as any).Epoxy.Model.extend({
    idAttribute: 'name',
    initialize(options: BackboneViewProps) {
      this.tenant = options.tenant;
      this.name = options.name;
    },
    url() {
      return `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.name}`;
    },
    computeds: {
      pull_json: {
        get() {
          const attributes = this.get('pull');
          return JSON.stringify(attributes, null, JSON_INDENT) || '';
        },
        set(value: any) {
          const pull = JSON.parse(value);
          if (pull) {
            return { pull };
          }
        },
      },
      full_pull_json: {
        get() {
          const attributes = this.get('full_pull');
          return JSON.stringify(attributes, null, JSON_INDENT) || '';
        },
        set(value: any) {
          const fullPull = JSON.parse(value);
          if (fullPull) {
            return { full_pull: fullPull };
          }
        },
      },
      push_json: {
        get() {
          const attributes = this.get('push');
          return JSON.stringify(attributes, null, JSON_INDENT) || '';
        },
        set(value: any) {
          const push = JSON.parse(value);
          if (push) {
            return { push };
          }
        },
      },
      throttling_json: {
        get() {
          const attributes = this.get('throttling');
          return JSON.stringify(attributes, null, JSON_INDENT) || '';
        },
        set(value: any) {
          const throttling = JSON.parse(value);
          if (throttling) {
            return { throttling };
          }
        },
      },
    },
  }),

  Integrations_model: (Backbone as any).Epoxy.Model.extend({
    urlRoot() {
      return `${BONGO_URL}/v1/org/${this.tenant}/integrations`;
    },

    initialize(properties: BackboneViewProps) {
      this.tenant = properties.tenant;
      // this.add_computeds();
    },

    parse(response: any) {
      return { integrations: response };
    },

    // generate a computed attribute for each active pull connector
    computeds() {
      const computeds: UnknownObject = {};
      // add a computed attribute for each integration
      app.session.config.active_connectors.forEach(
        (connector: UnknownObject) => {
          const { name } = connector;
          computeds[`${name}_active`] = {
            deps: [name],
            get() {
              const integrations = this.get('integrations');
              const integration =
                filter(integrations, { name } as any)[0] || {};
              const { pull = {}, push = {} } = integration as any;

              if (NON_PUSH_PULL_INTEGRATIONS.includes(name))
                return !isEmpty(integration);

              return pull.active || push.active;
            },
          };
        }
      );

      return computeds;
    },
  }),

  Credentials_model: (Backbone as any).Epoxy.Model.extend({
    initialize(options: { tenant: number; integration: string }) {
      this.tenant = options.tenant;
      this.integration = options.integration;
    },
    url() {
      const model_url = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials`;
      return model_url;
    },
    parse(response: any) {
      return response;
    },
    idAttribute: '_id',
  }),

  Pull_model: (Backbone as any).Epoxy.Model.extend({
    initialize(options: { tenant: number; integration: string }) {
      this.tenant = options.tenant;
      this.integration = options.integration;
    },
    urlRoot() {
      const model_url = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/pull/custom`;
      return model_url;
    },
    defaults: {
      config: {},
    },
    computeds: {
      config_json: {
        get() {
          const config = this.get('config');
          return JSON.stringify(config, null, 2) || '';
        },
        set(value: any) {
          const config = JSON.parse(value);
          if (config) {
            return { config };
          }
        },
      },
    },
  }),
};
