import faGear from '@fortawesome/pro-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type OverviewComponentProps = {
  tenant: number;
  loading: boolean;
  enabled: boolean;
  handleTabClick: Function;
};

export default class OverviewComponent extends React.Component<
  OverviewComponentProps
> {
  render() {
    return (
      <div className="row">
        <div className="col-8">
          <div>
            <h5>Overview</h5>
            <div>
              Deanonymize your website traffic with MadKudu Account
              Identification
            </div>
          </div>

          <div className="mt-3">
            <h5>Best Used For</h5>
            <div>
              <p>
                &bull; Your Sales reps get alerted when their target account is
                on your website
              </p>
              <p>
                &bull; Identify new qualified accounts showing intent on your
                website
              </p>
            </div>
          </div>

          <div className="mt-3">
            <h5>How this works</h5>
            <div>
              <p>
                &bull; MadKudu pulls your anonymous web traffic via Segment,
                Amplitude or MadKudu Tag
              </p>
              <p>
                &bull; Analyzes your visitors behavior and identify which
                company they work for
              </p>
            </div>
          </div>
          <div className="mt-3">
            <h5>Prerequisites</h5>
            <div>
              <p>
                &bull; You’re tracking your website anonymous visitors via
                Segment or Amplitude and connected to MadKudu
              </p>
              <p>OR</p>
              <p>&bull; You’ve installed MadKudu Tag on your website</p>
            </div>
          </div>
        </div>
        <div className="col-4">
          {/* TODO: remove this condition when MadKudu Account identification is ready */}
          {false && (
            <p>
              <b>Status</b>
              <span className="d-block pt-1">
                {this.props.loading ? (
                  <span className="text-secondary">loading...</span>
                ) : (
                  <span
                    className="cursor-pointer d-block"
                    onClick={() => this.props.handleTabClick('configuration')}
                  >
                    {this.props.enabled ? (
                      <b className="text-success-dark d-block p-2 rounded bg-success-light">
                        <FontAwesomeIcon
                          icon={faGear}
                          width="14"
                          height="14"
                          className="mr-1"
                        />
                        Enabled
                      </b>
                    ) : (
                      <b className="text-secondary d-block p-2 rounded bg-gray-light">
                        <FontAwesomeIcon
                          icon={faGear}
                          width="14"
                          height="14"
                          className="mr-1"
                        />
                        Disabled
                      </b>
                    )}
                  </span>
                )}
              </span>
            </p>
          )}
        </div>
      </div>
    );
  }
}
