import Backbone from 'backbone';

require('backbone.epoxy');

export const Plan_model = (Backbone as any).Epoxy.Model.extend({});

export const Plan_collection = Backbone.Collection.extend({
  model: Plan_model,

  url() {
    return `${BONGO_URL}/v1/plans`;
  },

  parse(response: UnknownArray = []) {
    const plans = response.map((plan) => {
      // eslint-disable-next-line
      plan.value = plan.id;
      // eslint-disable-next-line
      plan.label = `${plan.id} - $${plan.amount / 100}`;
      return plan;
    });
    return plans || [];
  },
});
