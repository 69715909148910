import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import { BackboneViewProps } from '../types';
import FieldsMapping from './fields_mapping';

const template = require('./kissmetrics.pug');

const channel = Radio.channel('integration');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
    'click #mki_kissmetrics_option1': 'render_option1',
    'click #mki_kissmetrics_option2': 'create_s3_bucket',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'kissmetrics';
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    const s3_bucket = this.model.get('s3_bucket');
    const s3_access_key = this.model.get('s3_access_key');
    const s3_secret_access_key = this.model.get('s3_secret_access_key');
    if (!s3_bucket) {
      // if no kissmetrics credentials, render the choice screen
      this.render_choice();
    } else if (s3_access_key && s3_secret_access_key) {
      // if access key, means the user is using his/her own bucket
      this.option = 1;
      this.render_option1();
    } else {
      // using our bucket
      this.option = 2;
      this.render_option2();
    }
    return this;
  },

  render_choice() {
    const html = $('#tpl_choice', template()).html();
    this.$el.html(html);
    return this;
  },

  render_option1() {
    if (!this.model.get('s3_bucket')) {
      this.model.set({ s3_bucket: '' });
    }
    if (!this.model.get('s3_access_key')) {
      this.model.set({ s3_access_key: '' });
    }
    if (!this.model.get('s3_secret_access_key')) {
      this.model.set({ s3_secret_access_key: '' });
    }
    const html = $('#tpl_credentials', template({})).html();
    this.$el.html(html);

    const credentialsFound = this.model.get('not_found') !== true;
    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    this.applyBindings();
    return this;
  },

  render_option2() {
    const html = $('#tpl_instructions', template({})).html();
    this.$el.html(html);

    const credentialsFound = this.model.get('not_found') !== true;
    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    this.applyBindings();
    return this;
  },

  create_s3_bucket() {
    axios
      .get(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/initialize`
      )
      .then(() => {
        this.model.fetch({ success: () => this.render() });
      })
      .catch(() => {
        Swal.fire({
          title: 'Internal error',
          text:
            'We were not able to create this connection. Our team has been notified and is looking into it.',
          icon: 'error',
        });
      });
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();

    const show_error_message = function (text: string) {
      Swal.fire({
        title: 'Oops',
        text,
        icon: 'error',
      });
    };

    if (this.option === 1 && !this.model.get('s3_bucket')) {
      return show_error_message('Please enter a S3 bucket');
    }

    if (this.option === 1 && !this.model.get('s3_access_key')) {
      return show_error_message('Please enter a S3 Access Key ID');
    }

    if (this.option === 1 && !this.model.get('s3_secret_access_key')) {
      return show_error_message('Please enter a S3 Secret Access Key');
    }

    const credentials = {
      s3_bucket: this.model.get('s3_bucket'),
      s3_access_key: this.model.get('s3_access_key'),
      s3_secret_access_key: this.model.get('s3_secret_access_key'),
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true&activate_push=true`,
        credentials
      )
      .then(() => {
        channel.trigger('success', { integration: this.integration });
      })
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  },
});
