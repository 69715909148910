import MappingsMode from '../../../../enums/MappingsMode';
import useScriptChecker from './useScriptChecker';
import useDiscoveryEvents from './useDiscoveryEvents';
import usePullConfig from './usePullConfig';
import useEventMappingModel from './useEventMappingModel';
import useEventMappingHistory from './useEventMappingHistory';
import { validateEvents } from '../utils';

type UseEventMappingOptions = {
  email: string;
  tenant: number;
  connector: string;
  superKudu: boolean;
};

type EventValidationError = {
  level: string;
  index: number;
  originalIndex: number;
  isUncaughtError: boolean;
};
export type TrackedEventValidationError = EventValidationError & { id: number };

/**
 * The goal of this hook is to manage most of event mapping related states
 * altogether, while also caching/memoizing when possible.
 */
function useEventMapping({
  email,
  tenant,
  connector,
  superKudu,
}: UseEventMappingOptions) {
  const scriptChecker = useScriptChecker(tenant, MappingsMode.event);
  const pullConfig = usePullConfig(tenant, connector);
  const discovery = useDiscoveryEvents(tenant, connector);
  const history = useEventMappingHistory(tenant, connector);
  const {
    model,
    isFetching: isSettingUpModel,
    invalidateModel,
  } = useEventMappingModel(tenant, connector, email, superKudu);

  const { events, isLoadingEvents, isLoadingEventsFieldValues } = history;

  let eventErrors: TrackedEventValidationError[];
  try {
    eventErrors = events.length
      ? events
          .map((event) => {
            // FIXME: Legacy validator may need a refactoring, making sure to
            // identify each event (without the need of doing it ourselves)
            // and send consistent values.
            // It currently sends a condition index (0 if condition logic error)
            // and a level string which can be the invalid field name or the condition
            // logic error description.
            const errors = validateEvents([event]);
            return errors.map((error) => ({
              ...error,
              id: event.id,
              originalIndex: event.originalIndex,
              isUncaughtError: false,
            }));
          })
          .flat()
      : [];
  } catch (e) {
    eventErrors = [
      {
        level: e.message,
        index: 0,
        originalIndex: 0,
        isUncaughtError: true,
        id: 0,
      },
    ];
  }

  const { isLoadingDiscoveryEvents, isLoadingFormData } = discovery;
  const { isLoadingScriptCheckerStatus } = scriptChecker;
  const { isLoadingPullConfig } = pullConfig;

  const isLoading =
    isLoadingEvents ||
    isLoadingEventsFieldValues ||
    isLoadingPullConfig ||
    isLoadingScriptCheckerStatus ||
    isLoadingFormData ||
    isLoadingDiscoveryEvents ||
    isSettingUpModel;

  return {
    email,
    tenant,
    connector,
    superKudu,
    eventHistory: history,
    pullConfig,
    discovery,
    scriptChecker,
    setHistoryId: (id: number) => {
      history.setHistoryId(id);
    },
    refreshAll: async () => {
      return Promise.all([
        history.refreshEvents(),
        scriptChecker.refreshScriptCheckerStatus(),
        discovery.refreshDefaultFormData(),
        invalidateModel(),
        pullConfig.refreshPullConfig(),
      ]);
    },
    model,
    isLoading,
    isReady: !isLoading,
    isLoadingEvents,
    eventErrors,
    hasEventErrors: !!eventErrors.length,
  };
}

export type UseEventMappingProps = ReturnType<typeof useEventMapping>;
export default useEventMapping;
