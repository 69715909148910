import React from 'react';
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';
import { EventMappingRow } from './EventMappingRow';
import { EventMappingTableProps } from './EventMappingTable';
import { IndexedEvent } from './hooks/useEventMappingHistory';
import { SelectOptionLike } from './utils';

export type SortableListProps = SortableContainerProps &
  EventMappingTableProps & {
    eventTypes: SelectOptionLike[];
    activityTypes: SelectOptionLike[];
    salesforceStatuses: SelectOptionLike[];
    searchQuery: string;
    activePage: number;
    activeItems: IndexedEvent[];
    isFiltered: boolean;
    isSorted: boolean;
    copyEvent: (event: IndexedEvent) => void;
    deleteEvent: (event: IndexedEvent) => void;
  };

export const SortableList = SortableContainer(
  (sortableListprops: SortableListProps) => {
    const {
      activeItems,
      isFiltered,
      isSorted,
      editable,
      pullConfig,
      connector,
      tenant,
      email,
      eventTypes,
      salesforceStatuses,
      copyEvent,
      deleteEvent,
      eventHistory,
      activityTypes,
      hasEventErrors,
      eventErrors,
    } = sortableListprops;
    return (
      <tbody>
        {activeItems.map((event) => (
          <EventMappingRow
            pullConfig={pullConfig}
            editable={editable}
            eventTypes={eventTypes}
            isFiltered={isFiltered}
            isSorted={isSorted}
            salesforceStatuses={salesforceStatuses}
            activityTypes={activityTypes}
            hasEventErrors={hasEventErrors}
            eventErrors={eventErrors}
            copyEvent={copyEvent}
            deleteEvent={deleteEvent}
            eventHistory={eventHistory}
            connector={connector}
            tenant={tenant}
            email={email}
            event={event}
            eventIndex={event.originalIndex}
            key={event.id}
            // 'index' is the key used by react-sortable-hoc to track order of items
            index={event.originalIndex ?? 0}
            // Disable sorting rows if search filter is active
            disabled={isFiltered || isSorted || !editable}
          />
        ))}
      </tbody>
    );
  }
);
