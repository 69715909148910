var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (activeDataDiscoverySourceSystems) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav navbar-brand mr-auto mt-2 mt-lg-0\"\u003E\u003Cli class=\"breadcrumb-item active\" aria-current=\"page\"\u003EData Discovery\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003C\u002Fdiv\u003E\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav mx-auto mt-2 mt-lg-0\"\u003E\u003Cdiv class=\"p\"\u003EData Discovery helps you identify volume or quality issues on leads and contacts and explore the standard fields to be used in the training of the model.\u003C\u002Fdiv\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003Cdiv class=\"container mt-4 pb-5\"\u003E";
// iterate activeDataDiscoverySourceSystems
;(function(){
  var $$obj = activeDataDiscoverySourceSystems;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var sourceSystem = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"row justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d m-2\"\u003E\u003Ch5 class=\"card-header\"\u003E" + (pug.escape(null == (pug_interp = sourceSystem.charAt(0).toUpperCase() + sourceSystem.slice(1)) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cbutton" + (" class=\"btn btn-primary\""+" id=\"go-data-discovery\""+pug.attr("data-sourcesystem", `${sourceSystem}`, true, true)) + "\u003EDiscover your Data &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var sourceSystem = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"row justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d m-2\"\u003E\u003Ch5 class=\"card-header\"\u003E" + (pug.escape(null == (pug_interp = sourceSystem.charAt(0).toUpperCase() + sourceSystem.slice(1)) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cbutton" + (" class=\"btn btn-primary\""+" id=\"go-data-discovery\""+pug.attr("data-sourcesystem", `${sourceSystem}`, true, true)) + "\u003EDiscover your Data &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "activeDataDiscoverySourceSystems" in locals_for_with ?
        locals_for_with.activeDataDiscoverySourceSystems :
        typeof activeDataDiscoverySourceSystems !== 'undefined' ? activeDataDiscoverySourceSystems : undefined));
    ;;return pug_html;};
module.exports = template;