var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isActive, waitingForActive) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cp\u003E\u003Cb\u003EActivate PredictLeads data through MadKudu\u003C\u002Fb\u003E\u003C\u002Fp\u003E\u003Cp class=\"mt-4\"\u003EStart syncing job offer data from PredictLeads in your CRM platform.\u003Cspan class=\"ml-1\" style=\"color: silver\"\u003ENote: only Salesforce is currently supported\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003Cp class=\"mt-3\"\u003EPredictLeads detects new technology trends and companies behind them by scouring millions of news articles, job postings, websites, and more every day.\u003C\u002Fp\u003E";
if (!(isActive)) {
pug_html = pug_html + "\u003Cp class=\"mt-3\"\u003E\u003Cb\u003EDisclaimer:\u003C\u002Fb\u003E\u003Cspan class=\"ml-1\"\u003EMadKudu offers integrations for Salesforce only for now.\u003C\u002Fspan\u003E\u003Ca class=\"ml-1\" id=\"request-activation-other\" style=\"color: #0000EE; cursor: pointer\"\u003ERequest here\u003C\u002Fa\u003E\u003Cspan class=\"ml-1\"\u003Eif you would like to activate this integration for other CRM platforms.\u003C\u002Fspan\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"mt-4\"\u003E\u003Cbutton class=\"btn btn-primary\" id=\"see-sample\" style=\"width: 180px\"\u003ESee what it looks like\u003C\u002Fbutton\u003E";
if (isActive) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger ml-2\" id=\"deactivate-integration\" style=\"width: 180px\"\u003EDeactivate\u003C\u002Fbutton\u003E";
}
else {
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-info ml-2\""+" id=\"activate-integration\" style=\"width: 180px\""+pug.attr("disabled", waitingForActive ? true : false, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = waitingForActive ? 'Awaiting confirmation..' : 'Activate') ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isActive" in locals_for_with ?
        locals_for_with.isActive :
        typeof isActive !== 'undefined' ? isActive : undefined, "waitingForActive" in locals_for_with ?
        locals_for_with.waitingForActive :
        typeof waitingForActive !== 'undefined' ? waitingForActive : undefined));
    ;;return pug_html;};
module.exports = template;