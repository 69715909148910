import React from 'react';
import axios from 'axios';
import { detect } from 'detect-browser';
import copy from 'copy-to-clipboard';
import Swal from 'sweetalert2';
import { PushConfigurationItem } from '../types';

type ReviewYamlJsonProps = {
  pushConfigurationItem: PushConfigurationItem;
  tenant: number;
};
const browser = detect();

export default class ReviewYamlJson extends React.Component<
  ReviewYamlJsonProps
> {
  constructor(props: ReviewYamlJsonProps) {
    super(props);
    this.copy = this.copy.bind(this);
  }

  async copy() {
    if (browser.name === 'firefox') {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text:
          'We are sorry, but this feature is not available on Firefox, please try with Google Chrome...',
      });
    } else {
      let response: any;
      const url = `${BONGO_URL}/v1/org/${this.props.tenant}/settings/push/yaml`;
      await Swal.fire({
        title: 'Copy',
        text: 'This is going to create the query and copy it to your clipboard',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, copy it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios
            .put(url, this.props.pushConfigurationItem)
            .then((res) => {
              response = res;
              if (response.status < 200 || response.status > 300) {
                throw new Error('Something went wrong!');
              }
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((res: any) => {
        copy(response.data);
        if (res?.value) {
          Swal.fire({
            icon: 'success',
            title: 'Copied successfully!',
          });
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-6 mx-auto">
            <div className="alert alert-primary" role="alert">
              Review your YAML and JSON configuration before triggering the push
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <textarea
              defaultValue={JSON.stringify(
                this.props.pushConfigurationItem.attributes,
                null,
                2
              )}
              style={{ height: '600px', width: '700px' }}
              readOnly
            />
          </div>
          <div className="col">
            <button className="btn btn-primary" onClick={this.copy}>
              Copy
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
