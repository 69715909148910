import { AbstractFiltersForm } from './AbstractFiltersForm';
import ParameterDataModel from '../ParameterDataModel';
import { ConnectorsNames, MappingConversionsTypes } from '../../../../types';
import VerbsNames from '../../../enums/VerbsNames';
import { getVerbBoundaries } from '../../../utils';
import { ConnectorFieldNameDataModel } from '../../../models/ConnectorFieldNameDataModel';
import { getFieldsNameFromTable } from '../../configuration_steps/utils';
import {
  FIELD_PLACEHOLDER_VALUE,
  OBJECT_PLACEHOLDER_VALUE,
} from '../../../constants';

export function updateConditionChange(
  verb: VerbsNames,
  parameterIndex: number,
  parameters: ParameterDataModel[]
): ParameterDataModel[] {
  const boundaries = getVerbBoundaries(verb);
  const newParameters = parameters;
  newParameters[parameterIndex].verb = verb;
  newParameters[parameterIndex].values = [];
  for (let i = 0; i <= boundaries.min - 1; i += 1) {
    parameters[parameterIndex].values.push('');
  }
  return parameters;
}

export type DefaultFiltersFormOptions = {
  parametersLogic: string;

  parameters: ParameterDataModel[];

  parametersForGlobalVars: string;

  isParametersLogicCustom: boolean;
};

export class DefaultFiltersForm extends AbstractFiltersForm {
  parametersLogic: string;

  parameters: ParameterDataModel[];

  parametersForGlobalVars: string;

  isParametersLogicCustom: boolean;

  constructor(
    tenant: number,
    connector: ConnectorsNames,
    conversionType: MappingConversionsTypes,
    parametersFieldsNames: ConnectorFieldNameDataModel[],
    options?: DefaultFiltersFormOptions
  ) {
    super(tenant, connector, conversionType, parametersFieldsNames);
    this.build(options);
  }

  build(option: DefaultFiltersFormOptions) {
    if (option?.parametersLogic) {
      this.parametersLogic = option.parametersLogic;
    } else {
      this.parametersLogic = '';
    }
    if (option?.parameters) {
      this.parameters = option.parameters;
    } else {
      this.parameters = [];
    }
    if (option?.parametersForGlobalVars) {
      this.parametersForGlobalVars = option.parametersForGlobalVars;
    } else {
      this.parametersForGlobalVars = '';
    }
    if (option?.isParametersLogicCustom) {
      this.isParametersLogicCustom = option.isParametersLogicCustom;
    } else {
      this.isParametersLogicCustom = false;
    }
  }

  async init(): Promise<void> {
    if (this.parameters?.length === 0) {
      this.parameters.push(
        ParameterDataModel.createDefaultParameterBasedOnConversionType(
          this.connector as any,
          this.parametersFieldsNames,
          this.conversionType
        )
      );
    }
    if (!this.isParametersLogicCustom) {
      this.computeDefaultLogic();
    }
  }

  private getNotNullConditionIndexes(
    parameters: ParameterDataModel[]
  ): number[] {
    return parameters
      .map((parameter, index) => {
        if (parameter !== null) return index;
        return null;
      })
      .filter((parameter) => parameter !== null);
  }

  computeDefaultLogic(): string {
    const notNullConditionsIndexes = this.getNotNullConditionIndexes(
      this.parameters
    );
    this.parametersLogic = notNullConditionsIndexes
      .map((index) => `$${index + 1}`)
      .join(' AND ');
    return this.parametersLogic;
  }

  computeLogicNewParameter(): string {
    const notNullConditionsIndexes = this.getNotNullConditionIndexes(
      this.parameters
    );
    const lastIndex = notNullConditionsIndexes.length;
    this.parametersLogic = this.parametersLogic.concat(` AND $${lastIndex}`);
    return this.parametersLogic;
  }

  setLowerOfParameter(parameterIndex: number): ParameterDataModel[] {
    this.parameters[parameterIndex].lower = !this.parameters[parameterIndex]
      .lower;
    this.updatedAt = Date.now();
    this.parameters[parameterIndex].updatedAt = Date.now();
    return this.parameters;
  }

  addParameter(): ParameterDataModel[] {
    this.parameters.push(
      ParameterDataModel.createDefaultParameterBasedOnConversionType(
        this.connector as any,
        this.parametersFieldsNames,
        this.conversionType
      )
    );
    this.computeLogicNewParameter();
    this.updatedAt = Date.now();
    return this.parameters;
  }

  addValue(parameterIndex: number): ParameterDataModel[] {
    this.parameters[parameterIndex].values.push('');
    this.parameters[parameterIndex].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this.parameters;
  }

  removeValue(parameterIndex: number): ParameterDataModel[] {
    this.parameters[parameterIndex].values.pop();
    this.parameters[parameterIndex].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this.parameters;
  }

  changeLogic(newLogic: string): string {
    this.parametersLogic = newLogic;
    this.isParametersLogicCustom = true;
    this.updatedAt = Date.now();
    return this.parametersLogic;
  }

  changeValue(
    newValue: string,
    parameterIndex: number,
    valueIndex: number
  ): ParameterDataModel[] {
    this.parameters[parameterIndex].values[valueIndex] = newValue;
    this.parameters[parameterIndex].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this.parameters;
  }

  changeTable(
    newTableValue: string,
    parameterIndex: number
  ): ParameterDataModel[] {
    this.parameters[parameterIndex].table = newTableValue;
    this.parameters[parameterIndex].updatedAt = Date.now();
    if (newTableValue !== OBJECT_PLACEHOLDER_VALUE) {
      const connectorFieldNames = getFieldsNameFromTable(
        this.parametersFieldsNames,
        newTableValue
      );
      this.changeField(connectorFieldNames.fields[0].name, parameterIndex);
    } else {
      this.changeField(FIELD_PLACEHOLDER_VALUE, parameterIndex);
    }
    this.updatedAt = Date.now();
    return this.parameters;
  }

  changeField(
    newFieldValue: string,
    parameterIndex: number
  ): ParameterDataModel[] {
    this.parameters[parameterIndex].field = newFieldValue;
    this.parameters[parameterIndex].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this.parameters;
  }

  changeVerb(
    newVerbValue: string,
    parameterIndex: number
  ): ParameterDataModel[] {
    this.parameters = updateConditionChange(
      newVerbValue as VerbsNames,
      parameterIndex,
      this.parameters
    );
    this.parameters[parameterIndex].updatedAt = Date.now();
    this.updatedAt = Date.now();
    return this.parameters;
  }

  removeParameter(parameterIndex: number): ParameterDataModel[] {
    this.parameters.splice(parameterIndex, 1);
    if (!this.isParametersLogicCustom) {
      this.computeDefaultLogic();
    }
    if (this.parameters[parameterIndex]) {
      this.parameters[parameterIndex].updatedAt = Date.now();
    }
    this.updatedAt = Date.now();
    return this.parameters;
  }

  prepare(): void {
    delete this.parametersFieldsNames;
  }
}
