import * as React from 'react';
import { Icon, IconName } from './Icon';

const ButtonNames = {
  blue:
    'tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-border-2 tw-border-blue-600 hover:tw-border-blue-700 focus:tw-outline-none disabled:tw-border-blue-200 disabled:tw-bg-blue-200',
  white:
    'tw-bg-white hover:tw-bg-gray-50 tw-text-gray-700 tw-outline tw-outline-1 tw-outline-gray-200 tw-focus disabled:tw-border-gray-200 disabled:tw-bg-gray-200',
  red:
    'tw-bg-red-100 hover:tw-bg-red-200 tw-text-red-600 tw-outline-gray-200 tw-focus disabled:tw-border-red-200 disabled:tw-bg-red-200',
  danger:
    'tw-bg-red-600 hover:tw-bg-red-700 tw-text-white tw-border-2 tw-border-red-600 hover:tw-border-red-700 focus:tw-outline-none disabled:tw-bg-red-200 disabled:tw-border-red-200',
};

const ButtonSize: { [key: string]: string } = {
  small: 'tw-h-9 tw-px-4 tw-text-xxs',
  regular: 'tw-h-10 tw-px-5 tw-text-sm',
  big: 'tw-h-12 tw-px-8 tw-text-md',
};

type Props = {
  color?: keyof typeof ButtonNames;
  type?: 'button' | 'submit' | 'reset';
  size?: keyof typeof ButtonSize;
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  loading?: boolean;
} & Omit<React.HTMLProps<HTMLButtonElement>, 'type' | 'size'>;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  function Button(props: Props, ref) {
    const {
      color = 'blue',
      size = 'regular',
      type = 'button',
      icon,
      iconPosition = 'left',
      loading,
      disabled = false,
      className,
      children,
      ...rest
    } = props;
    const commonStyle = `tw-relative tw-font-medium tw-rounded-md tw-inline-flex tw-shrink-0 tw-items-center tw-justify-center tw-shadow ${
      loading ? 'tw-pointer-events-none' : ''
    }`;

    return (
      <button
        className={`${commonStyle} ${ButtonSize[size]} ${ButtonNames[color]} ${
          className || ''
        }`}
        ref={ref}
        type={type}
        disabled={disabled}
        {...rest}
      >
        {/* {loading ? (
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
            <Icon name="load" />
          </div>
        ) : null} */}
        <div className={`tw-flex tw-items-center tw-justify-center tw-gap-2`}>
          {loading && <Icon name="load" />}

          {icon && iconPosition === 'left' && (
            <Icon name={icon} width={14} height={14} />
          )}
          {children}
          {icon && iconPosition === 'right' && (
            <Icon name={icon} width={14} height={14} />
          )}
        </div>
      </button>
    );
  }
);
