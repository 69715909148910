import ModelDataModel from '../models/model/ModelDataModel';

export interface Computation {
  name: string;
  categoryName: string;
  dataSource: 'Clearbit' | 'HG Insights' | 'PredictLeads' | 'MadKudu';
  description: string;
  isBoolean: boolean;
  liveModelsUsingIt?: string[];
  scoredInOverrides: boolean;
  scoredInTrees: boolean;
  scoredInPoints: boolean;
  value: any;
}

export type PersonResult = {
  company: { properties: Record<string, any> }; // TODO: type me
  properties: Record<string, any>; // TODO: type me
  email: string;
};

export type PersonModelScore = {
  score: number;
  segment: Segment;
  top_signals: TopSignal[];
  diagnosis: Record<string, any>; // TODO: type me
};

export const PREDICT_RESULT_KEYS = [
  'person__avatar',
  'person__location',
  'person__employment__title',
  'company__logo',
  'company__geo__city',
  'company__geo__country',
  'company__description',
] as const;

export type PredictResultKey = typeof PREDICT_RESULT_KEYS[number];

export type PredictResult = Partial<Record<PredictResultKey, string>>;

export type ModelSubset = Pick<ModelDataModel, 'name' | 'modelId'> & {
  key: string;
  isDefault: boolean;
};
export type Model = { label: string; value: ModelSubset };

export type EnrichmentCategory =
  | 'all'
  | 'person'
  | 'company'
  | 'tech'
  | 'hiring'
  | 'custom';

export interface TopSignal {
  name: string;
  value: string;
  type: 'positive' | 'negative';
}
export interface Thresolds {
  medium: number;
  good: number;
  'very good': number;
}

export type Segment = 'low' | 'medium' | 'good' | 'very good';
export interface ConversionTree {
  index: number;
  paths: Node[];
}

export interface Node {
  nodexIndex: number;
  splitConditions: string;
  ifTrue: boolean;
  conversionRate: number;
  segment: Segment;
  computations: { name: string; value: string }[];
}

export interface Override {
  rule: string;
  formConditions: OverrideFormCondition[];
  segment: Segment;
  ruleType: string;
  computationsValuesAndRules: OverrideComputations[];
}

export interface OverrideFormCondition {
  verb: string;
  subject: {
    context: string;
    type: string;
    subject: string;
    tags: string[];
  };
  lower: boolean;
  complements: string[];
}

export interface OverrideComputations {
  computation: string;
  value: string;
}

export const SegmentsColors = {
  low: '#E13655',
  medium: '#fdc400',
  good: '#61D0C1',
  'very good': '#3071CC',
};

export type TreeDiagnosis = {
  treesWeights: { [key: number]: number };
  conversionTrees: ConversionTree[];
  treeOverview: {
    conversionRateForAllTrees: number;
    segmentForAllTrees: Segment;
  };
};

export type RuleDiagnosis = {}[];
