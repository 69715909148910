import axios from 'axios';
import Backbone from 'backbone';
import qs from 'qs';
import model_integration from '../models/integration';
import INTEGRATIONS, { PREMIUM_INTEGRATIONS } from './integration_list_data';
import app from '../app';
import { hasEnabledSocialSignals } from './utils/launchdarkly';

const template = require('./view_all.pug');

const unmaintained_integrations_list = [
  'freshsales',
  'pipedrive',
  'zendesk',
  'hull',
  'predictleads',
  'kissmetrics',
  'clearbit_reveal',
];

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click .mki_integration': 'configure',
    'change #integration_category_drop_down': 'filter',
    'click #go_back': 'back',
    'click #filter_buttons': 'filter_buttons',
  },

  back() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  async initialize(options: {
    tenant: number;
    query_params: string;
    category: string;
    s3ping: boolean;
  }) {
    this.tenant = options.tenant;
    const list_categories = ['All'];
    this.session = app.session;
    this.query_params = options.query_params
      ? qs.parse(options.query_params)
      : {};
    // eslint-disable-next-line no-restricted-syntax
    for (const category of INTEGRATIONS) {
      list_categories.push(category.name);
    }
    this.model = new model_integration.Integrations_model({
      tenant: this.tenant,
    });

    // Init variables
    this.current_list = [];
    this.complete_list = [];
    this.list_template = {
      maintained_integrations_current_list: this
        .maintained_integrations_current_list,
      current_list: this.current_list,
      enabledSocialSignals: false,
      tenant: this.tenant,
      current_category: 'All',
      category_list: [],
    };

    this.model.fetch({
      success: async () => {
        // eslint-disable-next-line
        for (const category of INTEGRATIONS) {
          // eslint-disable-next-line
          for (const integration of category.integrations) {
            this.complete_list.push({
              active: this.model.get(`${integration}_active`),
              category: category.name,
              integration,
              show: true,
              premium: PREMIUM_INTEGRATIONS.includes(integration),
            });
          }
        }

        const maintained_integrations_current_list = this.complete_list.filter(
          // eslint-disable-next-line array-callback-return
          (integrationDetails: any) => {
            const integrationIsUnmaintained = unmaintained_integrations_list.includes(
              integrationDetails.integration
            );
            if (
              !integrationIsUnmaintained ||
              (integrationIsUnmaintained && integrationDetails.active === true)
            ) {
              return integrationDetails;
            }
          }
        );
        this.list_template = {
          maintained_integrations_current_list,
          current_list: maintained_integrations_current_list,
          tenant: this.tenant,
          current_category: 'All',
          category_list: list_categories,
          enabledSocialSignals: await hasEnabledSocialSignals(this.tenant),
        };
        if (options.category && options.category !== 'all')
          return this.filterByName(options.category);
        return this.render();
      },
    });
  },

  filter(event: any) {
    this.filterByName(event.target.value);
  },
  filter_buttons(event: any) {
    this.filterByName(event.currentTarget.text);
  },

  filterByName(res: string) {
    let copy = [];
    if (res !== 'All') {
      // eslint-disable-next-line
      for (const integration of this.list_template
        .maintained_integrations_current_list) {
        if (integration.category === res) copy.push(integration);
      }
    } else {
      copy = this.list_template.maintained_integrations_current_list;
    }
    this.list_template.current_list = copy;
    this.list_template.current_category = res;
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/${res}`,
      true
    );
    this.render();
  },

  render() {
    const html = $('#tpl_main', template(this.list_template)).html();
    this.$el.html(html);
    this.parse_query_params();

    return this;
  },

  // Note: this is pretty ugly but will do for now
  parse_query_params() {
    if (this.query_params && this.query_params.activate) {
      const integration = this.query_params.activate;

      const patch = { 'pull.active': true };
      // eslint-disable-next-line
      axios
        .post(
          `${BONGO_URL}/v1/org/${this.tenant}/integrations/${integration}`,
          patch
        )
        .then(() => {
          // reset query params to avoid infinite loop
          this.query_params = {};
          this.model.fetch({ success: () => this.render() });
        });
    }
  },

  configure(e: any) {
    const integration = e.currentTarget.id.replace('mki_', '');
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${integration}`,
      true
    );
  },
});
