export type TableMapping = {
  event: string;
  contact_update: string;
  account_update: string;
};

type EventColumns =
  | 'id'
  | 'event_name'
  | 'event_timestamp'
  | 'contact_id'
  | 'created_at'
  | 'updated_at';
type ContactColumns = 'id' | 'email' | 'created_at' | 'updated_at';
type AccountColumns = 'id' | 'domain' | 'created_at' | 'updated_at';

export type Columns<T> = T extends 'event'
  ? EventColumns
  : T extends 'contact_update'
  ? ContactColumns
  : T extends 'account_update'
  ? AccountColumns
  : never;

export type ColumnNames = {
  event?: Partial<Record<EventColumns, string>>;
  contact_update?: Partial<Record<ContactColumns, string>>;
  account_update?: Partial<Record<AccountColumns, string>>;
};

export const defaultColumNames: ColumnNames = {
  event: {
    id: '',
    event_name: '',
    event_timestamp: '',
    contact_id: '',
    created_at: '',
    updated_at: '',
  },
  contact_update: {
    id: '',
    email: '',
    created_at: '',
    updated_at: '',
  },
  account_update: {
    id: '',
    domain: '',
    created_at: '',
    updated_at: '',
  },
};

export function getTableLabel(
  table: 'event' | 'contact_update' | 'account_update'
): 'Events' | 'Contacts' | 'Accounts' {
  switch (table) {
    case 'event':
      return 'Events';
    case 'contact_update':
      return 'Contacts';
    case 'account_update':
      return 'Accounts';
    default:
      throw new Error(`Unknown table: ${table}`);
  }
}
