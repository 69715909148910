import axios from 'axios';
import Backbone from 'backbone';
import Swal from 'sweetalert2';
import app from '../../app';
import model_integration from '../../models/integration';
import { getConfig, setConfig, showSavingModal } from '../configure/utils';

const template = require('./template.pug');

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-info',
    cancelButton: 'btn btn-primary',
  },
  buttonsStyling: false,
});

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #activate-integration': 'activateModal',
    'click #deactivate-integration': 'deactivateModal',
    'click #see-sample': 'seeSampleDataModal',
    // activate when zendesk article is written
    // 'click #learn-more': 'learnMore'
    'click #request-activation-other': 'requestActivationOtherPlatformsModal',
  },

  async initialize(options: { tenant: number; showConfigure: boolean }) {
    this.tenant = options.tenant;
    this.isActive = options.showConfigure;
    this.integration = 'predictleads';
    this.userEmail = app.session.get('user').email;
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.config = await getConfig(this.tenant, this.integration);

    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  async seeSampleDataModal() {
    // activate confirm button and final return when zendesk article is written
    await swalWithBootstrapButtons.fire({
      imageUrl: '/media/images/joboffer-sample.png',
      // confirmButtonText: 'Learn more',
      showConfirmButton: false,
      width: 1000,
    });
  },

  // learnMore() {
  //   // redirect
  // },

  async activateModal() {
    const { value: confirmed } = await swalWithBootstrapButtons.fire({
      title: 'Activate integration?',
      text: `
        This integration with PredictLeads costs an additional $5,000/year.
        Benefit of integrating with MadKudu: you save $15,000/year.
      `,
      confirmButtonText: 'Activate',
      width: 600,
    });

    if (!confirmed) return;
    // eslint-disable-next-line
    showSavingModal();

    const url = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}`;
    const body = {
      tenant: this.tenant,
      push: { active: true },
    };
    const { data: existingIntegration } = await axios.get(
      `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}`
    );

    if (existingIntegration && Object.keys(existingIntegration).length) {
      await axios.put(url, body);
    } else {
      await axios.post(url, body);
    }

    const formData = {
      subject: `Add support for ${this.integration} on ${this.tenant}`,
      body: `Please add support for ${this.integration} on ${this.tenant}. This operation was requested by user ${this.userEmail}`,
      email: this.userEmail,
      destination: 'support',
    };
    const { data } = await axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zendesk`,
      formData
    );
    const { url: ticketUrl } = data;

    const config = {
      ...this.config,
      waitingForActive: true,
    };
    await setConfig(this.tenant, this.integration, config);
    this.initialize({ tenant: this.tenant, showConfigure: this.showConfigure });

    await swalWithBootstrapButtons.fire({
      title: 'Request sent',
      html: `<div>The ticket has been created here: <a href="${ticketUrl}">${ticketUrl}</a></div>`,
      confirmButton: 'Ok',
      width: 600,
    } as any);
  },

  async deactivateModal() {
    const { value: confirmed } = await swalWithBootstrapButtons.fire({
      title: 'Deactivate integration?',
      text: `
        Confirming this action will contact a CSM in order to deactivate this integration for you.
      `,
      confirmButtonText: 'Confirm',
      width: 600,
    });

    if (!confirmed) return;

    const data = {
      subject: `Deactivate ${this.integration} on ${this.tenant}`,
      body: `Please deactivate support for ${this.integration} on ${this.tenant}. This operation was requested by user ${this.userEmail}`,
      email: this.userEmail,
      destination: 'support',
    };
    return axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zendesk`,
      data
    );
  },

  async requestActivationOtherPlatformsModal() {
    const { value: confirmed } = await swalWithBootstrapButtons.fire({
      // html,
      title: 'Are you sure?',
      text: `
        You're about to request the activation of PredictLeads for another CRM platform.
      `,
      confirmButtonText: 'Request',
      width: 600,
    });

    if (!confirmed) return;

    const data = {
      subject: `Request for non-Salesforce push for ${this.integration} data (${this.tenant})`,
      body: `Please get in touch with the client concerning a non-Salesforce push for ${this.integration}.
      This operation was requested by user ${this.userEmail}`,
      email: this.userEmail,
      destination: 'product',
    };
    return axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zendesk`,
      data
    );
  },

  render() {
    const html = template({
      integration: this.integration,
      isActive: this.isActive,
      waitingForActive: this.config.waitingForActive,
    });
    this.$el.html(html);
    return this;
  },
});
