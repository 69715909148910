import axios from 'axios';
import Backbone from 'backbone';
import Chart from 'chart.js';
import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import { COLORS } from '../../../predictions/utils';
import { BackboneViewProps } from '../../../types';
import { getBootstrapColumnsFromData, refreshDataDiscovery } from '../../utils';
import app from '../../../app';

const ChartDataLabels = require('chartjs-plugin-datalabels');

require('bootstrap-table/dist/bootstrap-table');

const template = require('./discovery.pug');

Chart.plugins.register(ChartDataLabels);

export default Backbone.View.extend({
  events: {
    'click #go_back': 'go_back',
    'click #refresh': 'refresh',
  },
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.userEmail = app.session.get('user').email;
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/discovery/marketo`;
    const { data } = await axios.get(url);
    const updatedAt: string =
      moment(data.updated_at).format('lll') ?? 'unknown';

    this.data = data;
    this.updatedAt = updatedAt;
    this.render();
    this.event_type_distribution();
    this.monthly_active_contacts();
    this.avg_events_per_contact();
    this.total_events_and_distinct_moments();
    this.top_interesting_moments_6_months();
    this.top_form_submissions_6_months_table();
    this.total_events_and_distinct_forms();
    this.top_web_pages_visited_6_months();
    this.total_events_and_distinct_urls();
    this.top_clicked_campaigns_last_6_months();
    this.email_campaigns_vs_titles();
    this.email_click_through_rate();
  },

  go_back() {
    Backbone.history.navigate(`/org/${this.tenant}/data`, true);
  },

  async refresh() {
    await refreshDataDiscovery(this.tenant, this.userEmail, 'marketo');
  },

  event_type_distribution() {
    const data: UnknownObject[] = this.data.dataset.event_type_distribution;

    if (!data || !data.length) {
      $('#event_type_distribution').closest('.card.box-shadow-3d').remove();
      return;
    }

    const ctx = document
      .getElementById('event_type_distribution')
      .getContext('2d');

    const allDates = [
      ...new Set(
        data.map((row) => moment(row['event timestamp']).format('MMM YYYY'))
      ),
    ];
    const allEvents = [...new Set(data.map((row) => row.event))];

    const datasets: UnknownObject[] = allEvents.map((event, i) => ({
      label: event,
      data: allDates.map((date) => {
        const evt = data.find(
          (row) =>
            row.event === event &&
            moment(row['event timestamp']).format('MMM YYYY') === date
        );
        return {
          x: date,
          y: evt ? evt['total events logged'] : 0,
        };
      }),
      backgroundColor: COLORS[i],
    }));

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: allDates,
        datasets,
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        legend: {
          position: 'right',
        },
        plugins: {
          datalabels: {
            display: false,
            color: 'white',
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, dat) {
              let label = dat.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  monthly_active_contacts() {
    const data: UnknownObject[] = this.data.dataset.monthly_active_contacts;

    if (!data || !data.length) {
      $('#monthly_active_contacts')
        .closest('.card.mt-2.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('monthly_active_contacts')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const values: number[] = [];
    data.forEach((row) => {
      values.push(row['active contacts']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'active_contacts',
            data: values,
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: {
          position: 'top',
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            rotation: 270,
            color: 'white',
            display: false,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, dat) {
              let label = dat.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  avg_events_per_contact() {
    const data: UnknownObject[] = this.data.dataset.avg_events_per_contact;

    if (!data || !data.length) {
      $('#avg_events_per_contact').closest('.card.mt-2.box-shadow-3d').remove();
      return;
    }

    const ctx = document
      .getElementById('avg_events_per_contact')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const values: string[] = [];
    data.forEach((row) => {
      values.push(
        row['avg events per active contact']
          ? numeral(row['avg events per active contact']).format('0,0')
          : ''
      );
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'avg events per active contact',
            data: values as any,
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: {
          position: 'top',
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
                precision: 0,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            rotation: 270,
            display: false,
            color: 'white',
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, dat) {
              let label = dat.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  top_interesting_moments_6_months() {
    const rawData: UnknownObject[] = this.data.dataset
      .top_interesting_moments_6_months;

    if (!rawData || !rawData.length) {
      $('#top_interesting_moments_6_months')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const data = rawData.map((row: any) => {
      return {
        user_activity_type: row['user activity type'],
        moment: row.moment,
        campaign: row.campaign,
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#top_interesting_moments_6_months', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  total_events_and_distinct_moments() {
    const data: UnknownObject[] = this.data.dataset
      .total_events_and_distinct_moments;

    if (!data || !data.length) {
      $('#total_events_and_distinct_moments')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('total_events_and_distinct_moments')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const distinct_campaigns_dataset: number[] = [];
    data.forEach((row) => {
      distinct_campaigns_dataset.push(row['distinct campaigns']);
    });

    const total_events_logged_dataset: number[] = [];
    data.forEach((row) => {
      total_events_logged_dataset.push(row['total events logged']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'distinct campaigns',
            data: distinct_campaigns_dataset,
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 14,
            backgroundColor: COLORS[3],
            borderColor: COLORS[3],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'total events logged',
            data: total_events_logged_dataset,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  top_form_submissions_6_months_table() {
    const rawData: UnknownObject[] = this.data.dataset
      .top_form_submissions_6_months;

    if (!rawData || !rawData.length) {
      $('#top_form_submissions_6_months_table')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const data = rawData.map((row: any) => {
      return {
        form: row.form,
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#top_form_submissions_6_months_table', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  total_events_and_distinct_forms() {
    const data: UnknownObject[] = this.data.dataset
      .total_events_and_distinct_forms;

    if (!data || !data.length) {
      $('#total_events_and_distinct_forms')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('total_events_and_distinct_forms')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const distinct_forms_dataset: number[] = [];
    data.forEach((row) => {
      distinct_forms_dataset.push(
        row['distinct forms (primaryattributevalue)']
      );
    });

    const total_events_logged_dataset: number[] = [];
    data.forEach((row) => {
      total_events_logged_dataset.push(row['total events logged']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'distinct forms (primaryattributevalue)',
            data: distinct_forms_dataset,
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 14,
            backgroundColor: COLORS[3],
            borderColor: COLORS[3],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'total events logged',
            data: total_events_logged_dataset,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  top_web_pages_visited_6_months() {
    const rawData: UnknownObject[] = this.data.dataset
      .top_web_pages_visited_6_months;

    if (!rawData || !rawData.length) {
      $('#top_web_pages_visited_6_months')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const data = rawData.map((row: any) => {
      return {
        count: row.count,
        days_since_last_seen: row['days since last seen'],
        url: row.url,
      };
    });

    $('#top_web_pages_visited_6_months', this.$el).bootstrapTable({
      columns: [
        { field: 'count', title: 'Count', sortable: true },
        {
          field: 'days_since_last_seen',
          title: 'Days since last seen',
          sortable: true,
        },
        {
          field: 'url',
          title: 'Url',
          sortable: true,
        },
      ],
      data,
      pagination: true,
      search: true,
    });
  },

  total_events_and_distinct_urls() {
    const data: UnknownObject[] = this.data.dataset
      .total_events_and_distinct_urls;

    if (!data || !data.length) {
      $('#total_events_and_distinct_urls')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('total_events_and_distinct_urls')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const distinct_urls_dataset: number[] = [];
    data.forEach((row) => {
      distinct_urls_dataset.push(row['distinct campaigns']);
    });

    const total_events_logged_dataset: number[] = [];
    data.forEach((row) => {
      total_events_logged_dataset.push(row['total events logged']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'distinct urls',
            data: distinct_urls_dataset,
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 16,
            backgroundColor: COLORS[3],
            borderColor: COLORS[3],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'total events logged',
            data: total_events_logged_dataset,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                beginAtZero: true,
                precision: 0,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  top_clicked_campaigns_last_6_months() {
    const rawData: UnknownObject[] = this.data.dataset
      .top_clicked_campaigns_last_6_months;

    if (!rawData || !rawData.length) {
      $('#top_clicked_campaigns_last_6_months')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const data = rawData.map((row: any) => {
      return {
        primary_attribute: row['primary attribute'],
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#top_clicked_campaigns_last_6_months', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  email_campaigns_vs_titles() {
    const data: UnknownObject[] = this.data.dataset.email_campaigns_vs_titles;

    if (!data || !data.length) {
      $('#email_campaigns_vs_titles')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('email_campaigns_vs_titles')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const distinct_campaigns_dataset: number[] = [];
    data.forEach((row) => {
      distinct_campaigns_dataset.push(row['distinct campaigns']);
    });

    const distinct_titles_dataset: number[] = [];
    data.forEach((row) => {
      distinct_titles_dataset.push(row['distinct titles (primary attribute)']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'distinct campaigns dataset',
            data: distinct_campaigns_dataset,
            backgroundColor: COLORS[3],
          },
          {
            label: 'distinct titles',
            data: distinct_titles_dataset,
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  email_click_through_rate() {
    const data: UnknownObject[] = this.data.dataset.email_click_through_rate;

    if (!data || !data.length) {
      $('#email_click_through_rate')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('email_click_through_rate')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const email_opened: number[] = [];
    data.forEach((row) => {
      email_opened.push(row['emails opened']);
    });

    const email_clicked: number[] = [];
    data.forEach((row) => {
      email_clicked.push(row['emails clicked']);
    });

    const email_rate: number[] = [];
    data.forEach((row) => {
      email_rate.push(row['email click-through rate']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'email click-through rate',
            data: email_rate,
            backgroundColor: COLORS[1],
            borderColor: COLORS[1],
            yAxisID: 'B',
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 22,
            showLine: false,
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'email opened',
            data: email_opened,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
          {
            label: 'email clicked',
            data: email_clicked,
            backgroundColor: COLORS[3],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
                beginAtZero: true,
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
                callback: (value: string) => {
                  return `${value ? value.toLocaleString() : '0'}%`;
                },
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  render() {
    const html = template({
      tenant: this.tenant,
      noData: typeof this.data === 'undefined',
      updatedAt: this.updatedAt,
    });
    this.$el.html(html);

    return this;
  },
});
