import Backbone from 'backbone';
import axios from 'axios';
import Swal from 'sweetalert2';
import populateInputs from './populate_inputs';

const template = require('./login_sso.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #mki_btn_sso': 'sso',
  },

  render() {
    this.$el.html(template());
    populateInputs();
    return this;
  },

  async sso(e: any) {
    e.preventDefault();
    const form_data: UnknownObject = {};
    $.each($('form#ssoform').serializeArray(), (index, field) => {
      form_data[field.name] = field.value;
    });
    try {
      const { data } = await axios.post(`${BONGO_URL}/v1/login/sso`, form_data);
      window.location = data.login_url;
    } catch (err) {
      let message;
      switch (err.response.status) {
        case 400:
        case 404:
          message =
            'Email not found. Please ask your company IT admin to grant you permission to MadKudu or try connecting through the other available authentication options';
          break;
        default:
          message =
            "Something went wrong while getting your organization SSO's informations";
          break;
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
      });
    }
  },
});
