var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (isInsightsPagesHappyPath, isInsightsPagesPersonaAnalysis, isInsightsPagesl1, isInsightsPagesl2, isInsightsPagesl3) {
      pug_html = pug_html + "\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav navbar-brand mr-auto mt-2 mt-lg-0\"\u003E\u003Cli class=\"breadcrumb-item\"\u003EInsights Reports &nbsp;\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav mx-auto mt-2 mt-lg-0\"\u003E\u003Cdiv class=\"p\"\u003EUse MadKudu’s intelligent insights and reporting to make decisions based on real-time data, anticipate necessary changes to your marketing strategy, and ensure no revenue is left on the table.\u003C\u002Fdiv\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003Cdiv\u003E\u003Cdiv class=\"container mt-4 pb-5\"\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003EChampion Buyer Analysis\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003ELooking at signups in the last year, what percentage of users converts to paid and what percentage lead to an Open Opp. We break this down by the lead role, seniority and title which is enriched through MadKudu.\u003C\u002Fdiv\u003E\u003Cdiv class=\"a\" id=\"go_champion_analysis\"\u003E\u003Cbutton class=\"btn btn-primary text-white mt-3\"\u003EEnter &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003E";
if (!isInsightsPagesl1) {
pug_html = pug_html + "\u003Ci class=\"fas fa-lock mr-2 text-teal\"\u003E\u003C\u002Fi\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"h5\"\u003EFunnel by Leadsource\u003C\u002Fspan\u003E";
if (!isInsightsPagesl1) {
pug_html = pug_html + "\u003Cspan class=\"float-right badge badge-pill badge-secondary\"\u003ERequires Salesforce or HubSpot\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003EThe Funnel by Lead Source report aims to give you a deeper dive into whether there are any major trend changes in your main marketing metrics over the past 6 months in terms of funnel metrics or lead source.\u003C\u002Fdiv\u003E";
if (isInsightsPagesl1) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-primary text-white mt-3\""+" id=\"goL1_Leadsource\""+pug.attr("disabled", !isInsightsPagesl1 ? true : false, true, true)) + "\u003EEnter &rarr;\u003C\u002Fbutton\u003E";
}
if (!isInsightsPagesl1) {
pug_html = pug_html + "\u003Cdiv class=\"div\"\u003E\u003Cp class=\"mb-0 a\"\u003E\u003Cbutton class=\"btn btn-teal text-white mt-2\" id=\"access_L1_leadsource\"\u003ERequest Access &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003E";
if (!isInsightsPagesl2) {
pug_html = pug_html + "\u003Ci class=\"fas fa-lock mr-2 text-teal\"\u003E\u003C\u002Fi\u003E";
}
if (!isInsightsPagesl2) {
pug_html = pug_html + "\u003Cspan class=\"float-right badge badge-pill badge-secondary\"\u003ERequires Salesforce or HubSpot\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan\u003ECampaigns & Predicted Revenue\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003EYou likely already know that Very good fit Handraisers are your best leads but how does a low customer fit lead compare to a very good customer fit content lead?\u003C\u002Fdiv\u003E";
if (isInsightsPagesl2) {
pug_html = pug_html + "\u003Cdiv class=\"a\" id=\"go_L2\"\u003E\u003Cbutton class=\"btn btn-primary text-white mt-3\"\u003EEnter &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
if (!isInsightsPagesl2) {
pug_html = pug_html + "\u003Cdiv class=\"div\"\u003E\u003Cp class=\"mb-0 a\"\u003E\u003Cbutton class=\"btn btn-teal text-white mt-2\" id=\"beta-access_L2\"\u003ERequest Access &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003E";
if (!isInsightsPagesl3) {
pug_html = pug_html + "\u003Ci class=\"fas fa-lock mr-2 text-teal\"\u003E\u003C\u002Fi\u003E";
}
if (!isInsightsPagesl3) {
pug_html = pug_html + "\u003Cspan class=\"float-right badge badge-pill badge-secondary\"\u003ERequires Salesforce or HubSpot\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan\u003EHand Raiser SLA\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003EMake the most of the leads that are knocking on your door. Get insights into how much pipeline is left on the table from untouched hand raisers, overall touch rate of leads and time to first touch.\u003C\u002Fdiv\u003E";
if (isInsightsPagesl3) {
pug_html = pug_html + "\u003Cdiv class=\"a\" id=\"go_L3\"\u003E\u003Cbutton class=\"btn btn-primary text-white mt-3\"\u003EEnter &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
if (!isInsightsPagesl3) {
pug_html = pug_html + "\u003Cdiv class=\"div\"\u003E\u003Cp class=\"mb-0 a\"\u003E\u003Cbutton class=\"btn btn-teal text-white mt-2\" id=\"beta-access_L3\"\u003ERequest Access &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003E";
if (!isInsightsPagesHappyPath) {
pug_html = pug_html + "\u003Ci class=\"fas fa-lock mr-2 text-teal\"\u003E\u003C\u002Fi\u003E";
}
if (!isInsightsPagesHappyPath) {
pug_html = pug_html + "\u003Cspan class=\"float-right badge badge-pill badge-secondary\"\u003ERequires PLG motion\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"div\"\u003EHappy Path\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003EWhat events in your user journey correlate most to conversion? This report aims to help you get more visibility into your events and their impact.\u003C\u002Fdiv\u003E";
if (isInsightsPagesHappyPath) {
pug_html = pug_html + "\u003Cdiv class=\"a\" id=\"go_happy_path\"\u003E\u003Cbutton class=\"btn btn-primary text-white mt-3\"\u003EEnter &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
if (!isInsightsPagesHappyPath) {
pug_html = pug_html + "\u003Cdiv class=\"div\"\u003E\u003Cp class=\"mb-0 a\"\u003E\u003Cbutton class=\"btn btn-teal text-white mt-2\" id=\"beta-access_happy_path\"\u003ERequest Access &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003E";
if (!isInsightsPagesPersonaAnalysis) {
pug_html = pug_html + "\u003Ci class=\"fas fa-lock mr-2 text-teal\"\u003E\u003C\u002Fi\u003E";
}
if (!isInsightsPagesPersonaAnalysis) {
pug_html = pug_html + "\u003Cspan class=\"float-right badge badge-pill badge-secondary\"\u003ERequires PLG motion\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan\u003EPersona Analysis\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003ELooking at signups in the last year, what percentage of users converts to paid and what percentage activates the product. We break this down by the users role which is enriched through MadKudu.\u003C\u002Fdiv\u003E";
if (isInsightsPagesPersonaAnalysis) {
pug_html = pug_html + "\u003Cdiv class=\"a\" id=\"go_persona_analysis\"\u003E\u003Cbutton class=\"btn btn-primary text-white mt-3\"\u003EEnter &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
if (!isInsightsPagesPersonaAnalysis) {
pug_html = pug_html + "\u003Cdiv class=\"div\"\u003E\u003Cp class=\"mb-0 a\"\u003E\u003Cbutton class=\"btn btn-teal text-white mt-2\" id=\"beta-access_persona_analysis\"\u003ERequest Access &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5\"\u003E\u003Ci class=\"fas fa-lock mr-2 text-teal\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003EHot qualified new accounts visiting anonymously your website\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-75\"\u003E\u003Cdiv class=\"p\"\u003EUnderstand which hot qualified accounts are visiting your website anonymously to find new accounts to reach out to.\u003C\u002Fdiv\u003E\u003Cdiv class=\"div\"\u003E\u003Cp class=\"mb-0 a\"\u003E\u003Cbutton class=\"btn btn-teal text-white mt-2\" id=\"beta-clearbit_reveal\"\u003ERequest Access &rarr;\u003C\u002Fbutton\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-2 justify-content-center\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d\"\u003E\u003Cdiv class=\"card-header h5 border-bottom-0\"\u003EMore Coming Soon. . .\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "isInsightsPagesHappyPath" in locals_for_with ?
        locals_for_with.isInsightsPagesHappyPath :
        typeof isInsightsPagesHappyPath !== 'undefined' ? isInsightsPagesHappyPath : undefined, "isInsightsPagesPersonaAnalysis" in locals_for_with ?
        locals_for_with.isInsightsPagesPersonaAnalysis :
        typeof isInsightsPagesPersonaAnalysis !== 'undefined' ? isInsightsPagesPersonaAnalysis : undefined, "isInsightsPagesl1" in locals_for_with ?
        locals_for_with.isInsightsPagesl1 :
        typeof isInsightsPagesl1 !== 'undefined' ? isInsightsPagesl1 : undefined, "isInsightsPagesl2" in locals_for_with ?
        locals_for_with.isInsightsPagesl2 :
        typeof isInsightsPagesl2 !== 'undefined' ? isInsightsPagesl2 : undefined, "isInsightsPagesl3" in locals_for_with ?
        locals_for_with.isInsightsPagesl3 :
        typeof isInsightsPagesl3 !== 'undefined' ? isInsightsPagesl3 : undefined));
    ;;return pug_html;};
module.exports = template;