import React, { useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Button, Input } from '../../../components/ui';
import { useUpdateJobChangesWatchListMutation } from '../api/useUpdateJobChangesWatchListMutation';
import { AudienceCsvInput } from '../components/AudienceCsvInput';
import { useGetJobChangesWatchListQuery } from '../api/useGetJobChangesWatchListQuery';
import { Loading } from '../../components/Loading';
import { useGetIntegrationConfigQuery } from '../../../api/useGetIntegrationConfigQuery';
import { JobChangesConfig } from '../types';

type Props = {
  tenant: number;
};

export function IntegrationEditView({ tenant }: Props) {
  const [uploadedEmails, setUploadedEmails] = React.useState<string[]>([]);
  const [search, setSearch] = React.useState('');

  const {
    data: watchList,
    isLoading: isLoadingWatchList,
  } = useGetJobChangesWatchListQuery({ tenant });

  const watchListPreview = useMemo(() => {
    if (uploadedEmails.length > 0) return uploadedEmails;
    if (watchList?.emails?.length > 0) return watchList.emails;
    return [];
  }, [uploadedEmails, search, watchList]);

  const filteredWatchListPreview = useMemo(() => {
    return watchListPreview.filter((email) =>
      email.toLowerCase().includes(search.toLowerCase())
    );
  }, [watchListPreview, search]);

  const {
    mutateAsync: updateWatchList,
    isLoading: isUpdatingWatchList,
  } = useUpdateJobChangesWatchListMutation();

  const { data: jobChangesConfig } = useGetIntegrationConfigQuery<
    JobChangesConfig
  >({
    tenant,
    integration: 'job_changes',
  });

  if (isLoadingWatchList)
    return <Loading loadingLabel="Loading watch list..." />;

  return (
    <div className="tw-flex tw-flex-col tw-w-full">
      <h5 className="tw-font-bold">Update your watch list</h5>
      <div className="tw-mb-2">
        Provide as a .csv file, the list of users you want to track job changes
        for.
      </div>
      <div className="tw-mt-4">
        <AudienceCsvInput
          onUpdate={setUploadedEmails}
          limit={jobChangesConfig.watch_list_limit}
        />
      </div>

      <div className="tw-inline-flex tw-justify-end tw-gap-2 tw-mt-6">
        <Button
          disabled={isUpdatingWatchList || uploadedEmails.length === 0}
          loading={isUpdatingWatchList}
          onClick={async () => {
            await updateWatchList({
              tenant,
              emails: uploadedEmails,
            });
          }}
        >
          Update
        </Button>
      </div>

      <div className="tw-inline-flex tw-justify-between tw-items-end tw-text-sm tw-text-gray-300 tw-mt-10 tw-mb-4">
        <Input
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          icon="search"
          placeholder="Search for an email in your watch list"
          className="tw-w-[400px] tw-rounded tw-shadow-none"
        />
        <div className="tw-whitespace-nowrap">
          {watchListPreview.length}/{jobChangesConfig.watch_list_limit} emails
        </div>
      </div>
      <div className="tw-h-[300px] tw-w-full tw-overflow-y-auto tw-border tw-border-gray-100 tw-rounded-lg">
        <TableVirtuoso
          components={{
            Table: (props) => (
              <table {...props} className="tw-w-full tw-table-fixed" />
            ),
            TableRow: (props) => (
              <tr {...props} className="tw-h-12 tw-text-left" />
            ),
          }}
          style={{ height: '100%' }}
          data={filteredWatchListPreview}
          fixedHeaderContent={() => (
            <tr className="tw-h-12 tw-text-left">
              <th className="tw-w-[30%] tw-font-semibold tw-px-6 tw-py-2 tw-sticky tw-top-0 tw-bg-gray-50 tw-border-b tw-border-gray-100">
                <span className="tw-cursor-pointer tw-gap-2 tw-select-none">
                  Email
                </span>
              </th>
            </tr>
          )}
          itemContent={(_index, email) => (
            <>
              <td
                className={`tw-text-sm tw-w-[30%] tw-rounded-l-md tw-px-6 tw-py-2 tw-border-b tw-border-gray-100`}
              >
                {email}
              </td>
            </>
          )}
        />
      </div>
    </div>
  );
}
