/* eslint import/no-dynamic-require:0 */

import Backbone from 'backbone';
import Mn from 'backbone.marionette';
import Radio from 'backbone.radio';
import $ from 'jquery';
import app from './app';
import IntegrationCallBackView from './integrations/callback';
import { checkToggleForTenant } from './integrations/utils/launchdarkly';
import MainView from './main';
import execute_demo_script from './predictions';
import SidebarView from './sidebar';
import { isIntegrationActive, snakeCaseToHumanReadable } from './utils';

require('backbone.epoxy');

const channel = Radio.channel('integration');

const Controller = (Mn.Object as any).extend({
  async initialize() {
    this.session = app.session;
    this.tenant = app.session.tenant;
    this.manual_connections = {};
    this.layout_set = false;
    this.demo_set = false;
    this.chatbotEnabled = this.tenant.attributes._id
      ? await checkToggleForTenant(this.tenant.attributes._id, 'chat-bot')
      : false;
    this.set_listeners();
  },

  set_listeners() {
    channel.on('success', (results: any) => {
      if (results && results.alert) {
        app.notifications.show(
          `Successfully connected to <b> ${results.integration}</b>`,
          {
            dismiss_timeout: 10000,
          }
        );
      }
      const tenant = this.tenant.get('_id');
      Backbone.history.navigate(`/org/${tenant}/integrations`);
      this.manual_connections[results.integration] = true;
      this.integrations(tenant);
    });

    channel.on('cancel', () => {
      window.history.back();
    });
  },

  execute(callback: () => {}, args: { session: any }) {
    if (app.session.get('logged_in')) {
      if (callback) callback.apply(this, args);
    } else {
      let redirect;
      const pathname = window.location.pathname + window.location.search;
      if (!/login|redirect/.test(pathname) && pathname.length > 1) {
        redirect = `redirect=${encodeURIComponent(pathname)}`;
      }
      const login_url = redirect ? `/login?${redirect}` : '/login';
      Backbone.history.navigate(login_url, {
        trigger: true,
      });
    }
  },

  get_flags() {
    const flags: UnknownObject = {};
    this.tenant.flags.forEach((flag: any) => {
      flags[flag.get('name')] = flag.get('active');
    });
    return flags;
  },

  set_demo() {
    if (this.demo_set) {
      return;
    }
    const demo = this.tenant.flags.get('demo');
    if (demo && demo.get('active') === true) {
      execute_demo_script();
    }
    this.demo_set = true;
  },

  set_layout() {
    if (this.layout_set) {
      return;
    }
    const flags = this.get_flags();

    this.sidebar = new SidebarView({
      model: this.session,
      flags,
      chatbotEnabled: this.chatbotEnabled,
    });
    app.root.showChildView('sidebar', this.sidebar);

    this.renderMain(flags);
    app.notifications.show_sidebar();

    this.layout_set = true;
  },

  renderMain(existingFlags: string[]) {
    const flags = existingFlags || this.get_flags();
    app.root.detachChildView('main');
    this.main = new MainView({
      flags,
    });
    app.root.showChildView('main', this.main);
  },

  assign(view: any, selector: string) {
    view.setElement(this.main.$(selector));
  },

  capitalize_first(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  change_title(title: string) {
    const newTitle = title ? `${title} | MadKudu` : 'MadKudu';
    $(document).attr('title', newTitle);
  },

  track_page(title: string) {
    return title && window.analytics && window.analytics.page(title);
  },

  async fetch_tenant() {
    // if the tenant is not initialized, get the data from the server
    if (!this.tenant.is_fetched()) {
      await this.tenant.fetch();
    }
  },

  async setup(tenant: number) {
    if (tenant !== this.tenant.get('_id')) {
      // if the tenant has changed, force a change
      this.session.update_tenant({
        _id: tenant,
      });
      this.layout_set = false;
    }
    try {
      await this.fetch_tenant();
    } catch (err) {
      // if request fails, redirect to the corresponding error page
      if (err.status === 404) {
        // note, if a user tries to access a page he doesn't have access to, we actually return a 404 (to avoid leaking existence of resources)
        Backbone.history.navigate('error/404', {
          trigger: true,
        });
      } else {
        throw err;
      }
    }
    this.set_layout();
    this.set_demo();
  },

  update_page(page: string, subpage: string, paramTitle: string) {
    const title = paramTitle || page;
    const newTitle = title
      ? this.capitalize_first(snakeCaseToHumanReadable(title))
      : undefined;
    this.change_title(newTitle);
    this.track_page(newTitle);
    this.sidebar.highlight(page, subpage);
  },

  check_superkudu() {
    const user = this.session.get('user');
    if (!user.superKudu) {
      window.location.href = '/';
    }
  },

  /**
   * Hot reload mechanism
   * Don't change the string interpolations into variables! It's a hack for allowing a dynamic import.
   * @param {*} tenant
   * @param {*} viewPath
   * @param {*} page
   * @param {*} title
   */
  hot_reload_view(viewPath: string, transition_view: () => {}) {
    if ((module as any).hot) {
      (module as any).hot.accept(require.resolve(`${viewPath}`), () => {
        transition_view();
      });
    }
    transition_view();
  },

  async transition_view(
    paramTenant: string,
    View: any,
    params: { tenant?: number; model?: string } = {},
    options: { page?: string; subpage?: string; title?: string } = {}
  ) {
    const tenant =
      typeof paramTenant === 'string' ? Number(paramTenant) : paramTenant;
    const { page, subpage, title } = options;
    await this.setup(tenant);
    this.update_page(page, subpage, title);
    const newParams = params;
    newParams.tenant = tenant;
    newParams.model = this.tenant;

    // eslint-disable-next-line
    this.main.showChildView(
      'content', // eslint-disable-next-line
      View.default ? new View.default(newParams) : new View(newParams)
    );
  },

  transition_view_without_tenant(
    View: any,
    params = {},
    options: { title?: string } = {}
  ) {
    const { title } = options;
    this.change_title(title);
    // eslint-disable-next-line
    app.root.showChildView(
      'main', // eslint-disable-next-line
      View.default ? new View.default(params) : new View(params)
    );
  },

  direct() {
    this.home(this.tenant.get('_id'));
  },

  async home(tenant: number, query_params: {}) {
    await this.setup(tenant); // since this is first page, force a fetch
    const onboarding_intercom = this.tenant.flags.get('onboarding_intercom');
    const onboarding_hubspot = this.tenant.flags.get('onboarding_hubspot');
    const onboarding_googlesheet = this.tenant.flags.get(
      'onboarding_googlesheet'
    );
    if (onboarding_intercom && onboarding_intercom.get('active') === true) {
      this.onboarding_intercom(tenant, query_params);
    } else if (
      onboarding_hubspot &&
      onboarding_hubspot.get('active') === true
    ) {
      this.onboarding_hubspot(tenant, query_params);
    } else if (
      onboarding_googlesheet &&
      onboarding_googlesheet.get('active') === true
    ) {
      this.onboarding_googlesheet(tenant, query_params);
    } else {
      this.homepage(tenant);
    }
  },

  help(tenant: number) {
    this.hot_reload_view('./help/index', () =>
      this.transition_view(
        tenant,
        require('./help/index'),
        {},
        {
          page: 'help',
        }
      )
    );
  },

  homepage(tenant: number) {
    this.hot_reload_view('./onboarding/homepage/index', () =>
      this.transition_view(
        tenant,
        require('./onboarding/homepage/index'),
        {},
        {
          page: 'homepage',
          title: 'Homepage',
        }
      )
    );
  },
  dataStudio(tenant: number) {
    this.hot_reload_view('./data-studio/index', () =>
      this.transition_view(
        tenant,
        require('./data-studio/index'),
        {},
        {
          page: 'data-studio',
          title: 'Data Studio',
        }
      )
    );
  },

  evrInsights(tenant: number, page: string, tab: string) {
    let newPage = page;

    this.hot_reload_view('./evr_insights/engine/page', async () => {
      if (newPage === 'l3') {
        const isHubspotActive = await isIntegrationActive(tenant, 'hubspot');
        const isSalesforceActive = await isIntegrationActive(
          tenant,
          'salesforce'
        );
        if (isHubspotActive && !isSalesforceActive) {
          newPage = 'l3_hubspot';
        }
      }
      this.transition_view(
        tenant,
        require('./evr_insights/engine/page'),
        {
          page: newPage,
          tab,
        },
        {
          page: `insights`,
        }
      );
    });
  },
  evrInsightsConfiguration(tenant: number, page: string) {
    this.hot_reload_view('./evr_insights/configuration/page', () =>
      this.transition_view(
        tenant,
        require('./evr_insights/configuration/page'),
        {
          page,
        },
        {
          page: `insights`,
        }
      )
    );
  },
  evr(tenant: number) {
    this.hot_reload_view('./evr_insights', () =>
      this.transition_view(
        tenant,
        require('./evr_insights'),
        {},
        {
          page: 'insights',
        }
      )
    );
  },

  onboarding_intercom(tenant: number, query_params: {}) {
    this.hot_reload_view('./onboarding/intercom/intercom', () =>
      this.transition_view(
        tenant,
        require('./onboarding/intercom/intercom'),
        {
          query_params,
        },
        {
          page: 'get-started',
          title: 'Onboarding - Intercom',
        }
      )
    );
  },

  onboarding_hubspot(tenant: number, query_params: {}) {
    this.hot_reload_view('./onboarding/hubspot/hubspot', () =>
      this.transition_view(
        tenant,
        require('./onboarding/hubspot/hubspot'),
        {
          query_params,
        },
        {
          page: 'get-started',
          title: 'Onboarding - Hubspot',
        }
      )
    );
  },

  onboarding_googlesheet(tenant: number, query_params: {}) {
    this.hot_reload_view('./onboarding/googlesheet/googlesheet', () =>
      this.transition_view(
        tenant,
        require('./onboarding/googlesheet/googlesheet'),
        {
          query_params,
        },
        {
          page: 'get-started',
          title: 'Onboarding - Googlesheet',
        }
      )
    );
  },

  onboarding_googlesheet_redirection() {
    const tenant = this.tenant.get('_id');
    Backbone.history.navigate(`/org/${tenant}/get-started/googlesheet`, {
      trigger: true,
    });
  },

  chatbot() {
    window.location.assign(`${NEW_ADMIN_URL}/chatbot`);
  },

  integrations(tenant: number, query_params: {}) {
    this.hot_reload_view('./integrations/view_all', () =>
      this.transition_view(
        tenant,
        require('./integrations/view_all'),
        {
          query_params,
        },
        {
          page: 'integrations',
          title: 'Integrations',
        }
      )
    );
  },

  integration_callback(integration: string, query_params: {}) {
    this.transition_view_without_tenant(
      IntegrationCallBackView,
      {
        integration,
        query_params,
      },
      {
        page: 'integrations callback',
        subpage: integration,
        title: integration,
      }
    );
  },

  integration_setup_without_tenant(integration: string) {
    const redirectUrl = `/org/${this.tenant.get(
      '_id'
    )}/integrations/${integration}${window.location.search}`;
    Backbone.history.navigate(redirectUrl, {
      trigger: true,
    });
  },
  integration_setup(tenant: number, integration: string, query_params: {}) {
    this.hot_reload_view('./integrations/view_one', () =>
      this.transition_view(
        tenant,
        require('./integrations/view_one'),
        {
          integration,
          query_params,
          model: this.tenant,
          manuallyConnected: this.manual_connections[integration],
        },
        {
          page: 'integrations',
          subpage: integration,
          title: integration,
        }
      )
    );
  },
  integration_admin(tenant: number, integration: string, query_params: {}) {
    this.hot_reload_view('./integrations/view_admin', () =>
      this.transition_view(
        tenant,
        require('./integrations/view_admin'),
        {
          integration,
          query_params,
          model: this.tenant,
        },
        {
          page: 'integrations',
          subpage: integration,
          title: integration,
        }
      )
    );
  },
  integration_configure(tenant: number, integration: string, query_params: {}) {
    this.hot_reload_view('./integrations/configure', () =>
      this.transition_view(
        tenant,
        require('./integrations/configure'),
        {
          integration,
          query_params,
          model: this.tenant,
        },
        {
          page: 'integrations',
          subpage: integration,
          title: integration,
        }
      )
    );
  },
  integration_configure_sample(
    tenant: number,
    integration: string,
    query_params: {}
  ) {
    this.hot_reload_view('./integrations/configure', () =>
      this.transition_view(
        tenant,
        require('./integrations/configure'),
        {
          integration,
          query_params,
          model: this.tenant,
          showSample: true,
        },
        {
          page: 'integrations',
          subpage: integration,
          title: integration,
        }
      )
    );
  },
  integration_pull_config(
    tenant: number,
    integration: string,
    object: string,
    query_params: {}
  ) {
    this.hot_reload_view('./integrations/admin_pages/view_mapping_config', () =>
      this.transition_view(
        tenant,
        require('./integrations/admin_pages/view_mapping_config'),
        {
          integration,
          query_params,
          model: this.tenant,
          object,
        },
        {
          page: 'integrations',
          subpage: integration,
          title: integration,
        }
      )
    );
  },
  integration_workbench(tenant: number, integration: string, query_params: {}) {
    this.hot_reload_view('./integrations/admin_pages/view_workbench', () =>
      this.transition_view(
        tenant,
        require('./integrations/admin_pages/view_workbench'),
        {
          integration,
          query_params,
          model: this.tenant,
        },
        {
          page: 'integrations workbench',
          subpage: integration,
          title: integration,
        }
      )
    );
  },

  // Campaigns

  campaigns(tenant: number, campaign_id: string) {
    this.hot_reload_view('./campaigns', () =>
      this.transition_view(
        tenant,
        require('./campaigns'),
        {
          campaign_id,
        },
        {
          render: false,
          page: 'settings',
          subpage: 'campaigns',
        }
      )
    );
  },

  advanced_campaigns(tenant: number, campaign_id: string) {
    this.hot_reload_view('./campaigns/view_all', () =>
      this.transition_view(
        tenant,
        require('./campaigns/view_all'),
        {
          campaign_id,
        },
        {
          render: false,
          page: 'settings',
          subpage: 'campaigns',
        }
      )
    );
  },

  // Settings and admins

  settings_admin(tenant: number) {
    this.hot_reload_view('./settings/admin', () =>
      this.transition_view(
        tenant,
        require('./settings/admin'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'admin',
          title: 'Admin',
        }
      )
    );
  },
  settings_api_usage(tenant: number) {
    this.hot_reload_view('./settings/api_usage', () =>
      this.transition_view(
        tenant,
        require('./settings/api_usage'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'usage',
          title: 'API Usage',
        }
      )
    );
  },
  settings_api_usage_admin(tenant: number) {
    this.hot_reload_view('./settings/api_usage_admin', () =>
      this.transition_view(
        tenant,
        require('./settings/api_usage_admin'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'usage',
          title: 'API Usage',
        }
      )
    );
  },
  settings_billing(tenant: number) {
    this.hot_reload_view('./settings/billing', () =>
      this.transition_view(
        tenant,
        require('./settings/billing'),
        {},
        {
          render: false,
          page: 'settings',
          subpage: 'billing',
          title: 'Billing',
        }
      )
    );
  },
  settings_billing_admin(tenant: number) {
    this.hot_reload_view('./settings/billing_admin', () =>
      this.transition_view(
        tenant,
        require('./settings/billing_admin'),
        {},
        {
          render: false,
          page: 'settings',
          subpage: 'billing',
          title: 'Billing',
        }
      )
    );
  },
  settings_profile(tenant: number) {
    this.hot_reload_view('./settings/profile', () =>
      this.transition_view(
        tenant,
        require('./settings/profile'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'profile',
          title: 'Profile',
        }
      )
    );
  },
  settings_account(tenant: number) {
    this.hot_reload_view('./settings/account', () =>
      this.transition_view(
        tenant,
        require('./settings/account'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'account',
          title: 'Account',
        }
      )
    );
  },
  settings_authentication(tenant: number) {
    this.hot_reload_view('./settings/authentication/index', () =>
      this.transition_view(
        tenant,
        require('./settings/authentication/index'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'authentication',
          title: 'Authentication',
        }
      )
    );
  },
  settings_users(tenant: number) {
    this.hot_reload_view('./settings/users', () =>
      this.transition_view(
        tenant,
        require('./settings/users'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'users',
          title: 'Users',
        }
      )
    );
  },
  settings_privacy(tenant: number) {
    this.hot_reload_view('./settings/privacy', () =>
      this.transition_view(
        tenant,
        require('./settings/privacy'),
        {
          model: this.tenant,
        },
        {
          page: 'settings',
          subpage: 'privacy',
          title: 'Privacy',
        }
      )
    );
  },
  flags(tenant: number, name: string) {
    if (name) {
      this.hot_reload_view('./metadata/flag', () =>
        this.transition_view(
          tenant,
          require('./metadata/flag'),
          {
            name,
          },
          {
            render: false,
            page: 'settings',
            subpage: 'flags',
            title: 'Flags',
          }
        )
      );
    } else {
      this.hot_reload_view('./metadata/flags', () =>
        this.transition_view(
          tenant,
          require('./metadata/flags'),
          {},
          {
            render: false,
            page: 'settings',
            subpage: 'flags',
            title: 'Flags',
          }
        )
      );
    }
  },

  processes(tenant: number) {
    this.hot_reload_view('./metadata/processes', () =>
      this.transition_view(
        tenant,
        require('./metadata/processes'),
        {},
        {
          render: false,
          page: 'settings',
          subpage: 'processes',
          title: 'Processes',
        }
      )
    );
  },
  push(tenant: number, pageName: string) {
    this.hot_reload_view('./metadata/PushConfiguration/push', () =>
      this.transition_view(
        tenant,
        require('./metadata/PushConfiguration/push'),
        { pageName: pageName || 'overview' },
        {
          render: false,
          page: 'settings',
          subpage: 'push',
          title: 'Push',
        }
      )
    );
  },

  // prototypes

  prototypes(tenant: number) {
    this.hot_reload_view('./prototypes/index', () =>
      this.transition_view(
        tenant,
        require('./prototypes/index'),
        {},
        {
          page: 'settings',
          subpage: 'Prototypes',
          title: 'Prototypes',
        }
      )
    );
  },
  sqline_ui(tenant: number) {
    this.hot_reload_view('./prototypes/sqline_ui', () =>
      this.transition_view(
        tenant,
        require('./prototypes/sqline_ui'),
        {},
        {
          page: 'prototypes',
          title: 'YAML to SQL',
        }
      )
    );
  },

  // demo section
  profiles(tenant: number, queryString: string) {
    this.hot_reload_view('./profiles/index', () =>
      this.transition_view(
        tenant,
        require('./profiles'),
        { queryString },
        {
          page: 'profiles',
          title: 'Profiles',
        }
      )
    );
  },
  predictions(tenant: number) {
    this.hot_reload_view('./predictions/predictions', () =>
      this.transition_view(
        tenant,
        require('./predictions/predictions'),
        {},
        {
          page: 'predictions',
          title: 'Predictions',
        }
      )
    );
  },
  performances(tenant: number, id: string) {
    const flags = this.get_flags();
    this.hot_reload_view('./predictions/performances/main', () =>
      this.transition_view(
        tenant,
        require('./predictions/performances/main'),
        {
          id,
          flags,
        },
        {
          page: 'predictions',
          subpage: 'performances',
          title: 'Performances',
        }
      )
    );
  },
  diagnostics(tenant: number, id: string) {
    const flags = this.get_flags();
    this.hot_reload_view('./predictions/performances/diagnostics', () =>
      this.transition_view(
        tenant,
        require('./predictions/performances/diagnostics'),
        {
          id,
          flags,
        },
        {
          page: 'diagnostics',
          subpage: 'diagnostics',
          title: 'Diagnostics',
        }
      )
    );
  },
  diagnostics_lead_grade(tenant: number, id: string) {
    const flags = this.get_flags();
    this.hot_reload_view('./predictions/performances/diagnostics', () =>
      this.transition_view(
        tenant,
        require('./predictions/performances/diagnostics'),
        {
          id,
          flags,
          context: 'lead_grade',
        },
        {
          page: 'diagnostics',
          subpage: 'diagnostics',
          title: 'Diagnostics',
        }
      )
    );
  },
  diagnostics_model(tenant: number, id: string, lead_grade: string) {
    const flags = this.get_flags();
    const context = lead_grade ? 'lead_grade' : '';
    this.hot_reload_view('./predictions/modelWeightAndOverrides', () =>
      this.transition_view(
        tenant,
        require('./predictions/modelWeightAndOverrides'),
        {
          id,
          flags,
          context,
        },
        {
          page: 'diagnostics_model',
          subpage: 'diagnostics',
          title: 'Model',
        }
      )
    );
  },
  model_edit(tenant: number, id: string) {
    const flags = this.get_flags();
    this.hot_reload_view('./predictions/model', () =>
      this.transition_view(
        tenant,
        require('./predictions/model'),
        {
          id,
          flags,
        },
        {
          page: 'model_edit',
          title: 'Edit model',
        }
      )
    );
  },
  data_overview(tenant: number) {
    this.hot_reload_view('./data', () =>
      this.transition_view(
        tenant,
        require('./data'),
        {},
        {
          page: 'data',
          title: 'Data Overview',
        }
      )
    );
  },
  mapping_overview(tenant: number) {
    this.hot_reload_view('./mapping', () =>
      this.transition_view(
        tenant,
        require('./mapping'),
        {},
        {
          page: 'mapping',
          title: 'Mapping Overview',
        }
      )
    );
  },
  attribute_mapping_overview(tenant: number) {
    this.hot_reload_view('./mapping/attribute_mapping', () =>
      this.transition_view(
        tenant,
        require('./mapping/attribute_mapping'),
        {},
        {
          page: 'mapping',
          title: 'Attribute Mapping',
        }
      )
    );
  },

  attribute_mapping_configuration_simple(tenant: number) {
    this.hot_reload_view(
      './mapping/attribute_mapping/configurationSection/simple',
      () =>
        this.transition_view(
          tenant,
          require('./mapping/attribute_mapping/configurationSection/simple'),
          {},
          {
            page: 'mapping',
            title: 'Attribute Mapping',
          }
        )
    );
  },

  attribute_mapping_configuration_custom(tenant: number) {
    this.hot_reload_view(
      './mapping/attribute_mapping/configurationSection/custom',
      () =>
        this.transition_view(
          tenant,
          require('./mapping/attribute_mapping/configurationSection/custom'),
          {},
          {
            page: 'mapping',
            title: 'Attribute Mapping',
          }
        )
    );
  },

  event_mapping(tenant: number) {
    this.hot_reload_view('./mapping/event_mapping', () =>
      this.transition_view(
        tenant,
        require('./mapping/event_mapping'),
        {},
        {
          page: 'mapping',
          title: 'Event Mapping',
        }
      )
    );
  },
  event_mapping_parent(tenant: number, connector: string) {
    this.hot_reload_view(`./mapping/event_mapping/event_mapping_parent`, () =>
      this.transition_view(
        tenant,
        require(`./mapping/event_mapping/event_mapping_parent`),
        {
          connector,
        },
        {
          page: 'event_mapping',
          title: `${this.capitalize_first(connector)} Mapping`,
        }
      )
    );
  },
  audience_mapping(tenant: number) {
    this.hot_reload_view('./mapping/audiences', () =>
      this.transition_view(
        tenant,
        require('./mapping/audiences'),
        {},
        {
          page: 'mapping',
          title: 'Audience Mapping',
        }
      )
    );
  },
  audience_page(tenant: number, audience: string) {
    this.hot_reload_view('./mapping/audiences/audience', () =>
      this.transition_view(
        tenant,
        require('./mapping/audiences/audience'),
        { audience },
        {
          page: 'mapping',
          title: 'Edit Audience',
        }
      )
    );
  },
  conversion_mapping(tenant: number) {
    this.hot_reload_view('./mapping/conversion_mapping/landing_page', () =>
      this.transition_view(
        tenant,
        require('./mapping/conversion_mapping/landing_page'),
        {},
        {
          page: 'mapping',
          title: 'Conversion Mapping',
        }
      )
    );
  },
  connectors_overview_conversion_mapping(tenant: number) {
    this.hot_reload_view(
      './mapping/conversion_mapping/configuration_steps/first_step',
      () =>
        this.transition_view(
          tenant,
          require('./mapping/conversion_mapping/configuration_steps/first_step'),
          {},
          {
            page: 'conversion mapping - connectors',
            title: 'Conversion Mapping - connectors',
          }
        )
    );
  },
  standard_parameters_config_conversion_mapping(tenant: number) {
    this.hot_reload_view(
      './mapping/conversion_mapping/configuration_steps/second_step',
      () =>
        this.transition_view(
          tenant,
          require('./mapping/conversion_mapping/configuration_steps/second_step'),
          {},
          {
            page: 'conversion mapping - standard parameters',
            title: 'Conversion Mapping - standard parameters',
          }
        )
    );
  },
  conversion_type_config_conversion_mapping(tenant: number) {
    this.hot_reload_view(
      './mapping/conversion_mapping/configuration_steps/third_step',
      () =>
        this.transition_view(
          tenant,
          require('./mapping/conversion_mapping/configuration_steps/third_step'),
          {},
          {
            page: 'conversion mapping',
            title: 'Conversion Mapping',
          }
        )
    );
  },
  connectors_overview_manage_conversion_mapping(tenant: number) {
    this.hot_reload_view('./mapping/conversion_mapping/display_page', () =>
      this.transition_view(
        tenant,
        require('./mapping/conversion_mapping/display_page'),
        {},
        {
          page: 'conversion mapping',
          title: 'Conversion Mapping',
        }
      )
    );
  },
  data_discovery(tenant: number, source_system: string) {
    const folder =
      source_system === 'hubspot' ||
      source_system === 'salesforce' ||
      source_system === 'eloqua' ||
      source_system === 'marketo'
        ? source_system
        : 'analytics';
    // eslint-disable-next-line
    this.hot_reload_view(`./data/discovery/${folder}`, () =>
      this.transition_view(
        tenant,
        require(`./data/discovery/${folder}`),
        {
          source_system,
        },
        {
          page: 'data',
          title: `${this.capitalize_first(source_system)} Data Discovery`,
        }
      )
    );
  },
  dashboard(tenant: number) {
    this.hot_reload_view('./predictions/dashboard', () =>
      this.transition_view(
        tenant,
        require('./predictions/dashboard'),
        {},
        {
          page: 'dashboard',
          title: 'Dashboard',
        }
      )
    );
  },
  setup_page(tenant: number) {
    this.hot_reload_view('./predictions/setup', () =>
      this.transition_view(
        tenant,
        require('./predictions/setup'),
        {},
        {
          page: 'setup',
          title: 'Setup',
        }
      )
    );
  },
  configure_push_page(tenant: number) {
    this.hot_reload_view('./integrations/configurePush', () =>
      this.transition_view(
        tenant,
        require('./integrations/configurePush'),
        {
          model: this.tenant,
        },
        {
          page: 'configure',
          title: 'Configure Salesforce',
        }
      )
    );
  },
  results(tenant: number) {
    this.hot_reload_view('./predictions/results', () =>
      this.transition_view(
        tenant,
        require('./predictions/results'),
        {},
        {
          page: 'results',
          title: 'Results',
        }
      )
    );
  },
  bulk_scorer_result(tenant: number, requestid: string) {
    this.hot_reload_view('./bulkScorer', () =>
      this.transition_view(
        tenant,
        require('./bulkScorer'),
        { requestid },
        {
          page: 'bulk result',
          title: 'Bulk Result',
        }
      )
    );
  },
});

export default Mn.AppRouter.extend({
  execute(callback: () => {}, args: { session: any }, name: string) {
    this.controller.execute(callback, args, name);
  },
  appRoutes: {
    // Home
    '(/)': 'direct',
    'org/:tenant/homepage': 'homepage',
    'org/get-started/googlesheet': 'onboarding_googlesheet_redirection', // redirection to have a link without tenant-number for ggsheet onboarding
    'org/:tenant(/)': 'home',
    'org/:tenant/help(/)': 'help',

    // Data Studio
    'org/:tenant/data-studio': 'dataStudio',
    // EVR Insights
    'org/:tenant/evr_insights/:page': 'evrInsights',
    'org/:tenant/evr_insights/:page/configuration': 'evrInsightsConfiguration',
    'org/:tenant/evr_insights/:page/:tab': 'evrInsights',
    'org/:tenant/evr': 'evr',

    // Get started
    'org/:tenant/get-started/intercom': 'onboarding_intercom',
    'org/:tenant/get-started/hubspot': 'onboarding_hubspot',
    'org/:tenant/get-started/googlesheet': 'onboarding_googlesheet',

    // Integrations
    'org/:tenant/integrations(/)': 'integrations',
    'org/:tenant/integrations/:integration(/)': 'integration_setup',
    'org/:tenant/integrations/:integration/overview(/)': 'integration_setup',
    'org/:tenant/integrations/:integration/configuration(/)':
      'integration_setup',
    'org/:tenant/integrations/:integration/superkudu-configuration(/)':
      'integration_setup',
    'org/:tenant/integrations/:integration/pull(/)': 'integration_setup',
    'org/:tenant/integrations/:integration/push(/)': 'integration_setup',
    'org/:tenant/integrations/:integration/push/configure(/)':
      'configure_push_page',
    'integrations/:integration(/)': 'integration_setup_without_tenant',
    'org/:tenant/integrations/:integration/admin(/)': 'integration_admin',
    'org/:tenant/integrations/:integration/configure/sample(/)':
      'integration_configure_sample',
    'org/:tenant/integrations/:integration/configure(/)':
      'integration_configure',
    'org/:tenant/integrations/:integration/pull/:object(/)':
      'integration_pull_config',
    'org/:tenant/integrations/:integration/workbench(/)':
      'integration_workbench',
    'integrations/:integration/callback(/)': 'integration_callback',

    // Settings & metadata
    'org/:tenant/settings(/)': 'settings_profile',
    'org/:tenant/settings/billing(/)': 'settings_billing',
    'org/:tenant/settings/billing/admin(/)': 'settings_billing_admin',
    'org/:tenant/settings/profile(/)': 'settings_profile',
    'org/:tenant/settings/account(/)': 'settings_account',
    'org/:tenant/settings/authentication(/)': 'settings_authentication',
    'org/:tenant/settings/users(/)': 'settings_users',
    'org/:tenant/settings/admin': 'settings_admin',
    'org/:tenant/settings/usage': 'settings_api_usage',
    'org/:tenant/settings/usage/admin': 'settings_api_usage_admin',
    'org/:tenant/settings/privacy(/)': 'settings_privacy',
    'org/:tenant/admin(/)': 'settings_admin',
    'org/:tenant/flags(/)': 'flags',
    'org/:tenant/processes(/)': 'processes',
    'org/:tenant/flags/:flag_name(/)': 'flags',
    'org/:tenant/push(/)': 'push',
    'org/:tenant/push/:pageName(/)': 'push',

    // Smart form campaigns
    'org/:tenant/campaigns(/)': 'campaigns',
    'org/:tenant/campaigns/:campaign_id(/)': 'campaigns',
    'org/:tenant/advanced_campaigns(/)': 'advanced_campaigns',
    'org/:tenant/advanced_campaigns/:campaign_id(/)': 'advanced_campaigns',

    // Prototypes
    'org/:tenant/prototypes(/)': 'prototypes',
    'org/:tenant/sqline_ui(/)': 'sqline_ui',

    // Demo
    'org/:tenant/profiles(/)': 'profiles',
    'org/:tenant/predictions(/)': 'predictions',
    'org/:tenant/predictions/performances/models/:id': 'performances',
    'org/:tenant/predictions/performances/models/:id/diagnostics':
      'diagnostics',
    'org/:tenant/predictions/performances/models/:id/diagnostics/lead_grade':
      'diagnostics_lead_grade',
    'org/:tenant/predictions/performances/models/:id/diagnostics/model(/)(:lead_grade)':
      'diagnostics_model',
    'org/:tenant/predictions/performances/models/:id/admin': 'model_edit',
    'org/:tenant/dashboard(/)': 'dashboard',
    'org/:tenant/setup(/)': 'setup_page',
    'org/:tenant/results(/)': 'results',
    'org/:tenant/data(/)': 'data_overview',
    'org/:tenant/mapping(/)': 'mapping_overview',
    'org/:tenant/mapping/conversion_mapping(/)': 'conversion_mapping',
    'org/:tenant/mapping/conversion_mapping/connector_overview(/)':
      'connectors_overview_conversion_mapping',
    'org/:tenant/mapping/conversion_mapping/standard_parameters(/)':
      'standard_parameters_config_conversion_mapping',
    'org/:tenant/mapping/conversion_mapping/conversion_type(/)':
      'conversion_type_config_conversion_mapping',
    'org/:tenant/mapping/conversion_mapping/overview(/)':
      'connectors_overview_manage_conversion_mapping',
    'org/:tenant/mapping/attribute_mapping/overview(/)':
      'attribute_mapping_overview',
    'org/:tenant/mapping/attribute_mapping/configuration/simple(/)':
      'attribute_mapping_configuration_simple',
    'org/:tenant/mapping/attribute_mapping/configuration/custom(/)':
      'attribute_mapping_configuration_custom',
    'org/:tenant/mapping/event_mapping(/)': 'event_mapping',
    'org/:tenant/mapping/audiences(/)': 'audience_mapping',
    'org/:tenant/mapping/audiences/:audience': 'audience_page',
    'org/:tenant/data/discovery/:source_system(/)': 'data_discovery',

    // Events
    'org/:tenant/mapping/event_mapping/:connector(/)': 'event_mapping_parent',

    //
    'org/:tenant/bulkresult/:requestid(/)': 'bulk_scorer_result',

    // Chatbot
    'org/:tenant/chatbot': 'chatbot',
  },

  initialize() {
    this.controller = new Controller({});
  },
});
