import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useBigQueryContext } from './contexts/BigQueryContext';
import { Loader } from '../../components/Loader';
import { BigQueryForm } from './BigQueryForm';
import { BigQueryOverview } from './BigQueryOverview';

export function BigQuery() {
  const {
    tenant,
    editMode,
    setEditMode,
    getCredentials,
    setCredentials,
  } = useBigQueryContext();

  const { data: savedCredentials, isLoading } = useQuery(
    ['bigquery', 'credentials', tenant],
    getCredentials
  );

  useEffect(() => {
    if (savedCredentials) {
      setCredentials(savedCredentials);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  }, [savedCredentials]);

  if (isLoading) return <Loader className="mx-auto mt-4 w-50" />;

  if (editMode) return <BigQueryForm />;

  return <BigQueryOverview />;
}
