import axios from 'axios';
import Backbone from 'backbone';
import app from '../app';
import { BackboneViewProps } from '../types';
import { getAllActiveIntegrations } from '../mapping/utils';

const template = require('./index.pug');

const DATA_DISCOVERY_SOURCE_SYSTEMS = [
  'hubspot',
  'salesforce',
  'segment',
  'mixpanel',
  'marketo',
  'kissmetrics',
  'amplitude',
  'eloqua',
  's3',
  'bigquery',
  'snowflake',
];

export default Backbone.View.extend({
  events: {
    'click #go-data-discovery': 'go_data_discovery',
    'click #refresh-button': 'refresh',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.userEmail = app.session.get('user').email;
    this.flags = app.session.tenant.flags;
    this.activeDataDiscoverySourceSystems = [];
    const allActiveIntegrations: string[] = (
      await getAllActiveIntegrations(this.tenant)
    ).map(({ name }) => name);
    const promisesList = DATA_DISCOVERY_SOURCE_SYSTEMS.map(
      async (sourceSystem) => {
        const hasDataDiscoveryData: boolean = await this.isDataDiscoveryForConnector(
          sourceSystem
        );
        const isSourceSystemActive: boolean = allActiveIntegrations.includes(
          sourceSystem
        );
        if (hasDataDiscoveryData && isSourceSystemActive) {
          this.activeDataDiscoverySourceSystems.push(sourceSystem);
        }
      }
    );
    await Promise.all(promisesList);
    this.render();
  },

  go_data_discovery(ev: any) {
    const sourceSystem = $(ev.currentTarget).data('sourcesystem');
    Backbone.history.navigate(
      `/org/${this.tenant}/data/discovery/${sourceSystem}`,
      true
    );
  },

  async isDataDiscoveryForConnector(connector: string): Promise<boolean> {
    try {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${connector}`
      );
      return !!(data && Object.keys(data).length);
    } catch (_e) {
      return false;
    }
  },

  render() {
    const options = {
      activeDataDiscoverySourceSystems: this.activeDataDiscoverySourceSystems,
    };
    const html = template(options);
    this.$el.html(html);
    return this;
  },
});
