import { AbstractFiltersForm } from './AbstractFiltersForm';
import SourceSystems from '../../../enums/SourceSystems';
import { ConnectorsNames, MappingConversionsTypes } from '../../../../types';
import { ConnectorFieldNameDataModel } from '../../../models/ConnectorFieldNameDataModel';

export type AnalyticsFiltersFormOptions = {
  conversionEvents?: string;
  sourceSystem?: SourceSystems;
};

export class AnalyticsFiltersForm extends AbstractFiltersForm {
  conversionEvents: string;

  sourceSystem: SourceSystems;

  constructor(
    tenant: number,
    connector: ConnectorsNames,
    conversionType: MappingConversionsTypes,
    parametersFieldsNames: ConnectorFieldNameDataModel[],
    options?: AnalyticsFiltersFormOptions
  ) {
    super(tenant, connector, conversionType, parametersFieldsNames);

    if (options?.conversionEvents) {
      this.conversionEvents = options.conversionEvents;
    } else {
      this.conversionEvents = '';
    }
    if (options?.sourceSystem) {
      this.sourceSystem = options.sourceSystem;
    } else {
      this.sourceSystem = SourceSystems.amplitude;
    }
  }

  changeSourceSystem(sourceSystem: SourceSystems): SourceSystems {
    this.sourceSystem = sourceSystem;
    this.updatedAt = Date.now();
    return this.sourceSystem;
  }

  changeConversionEvents(newConversionEvents: string): string {
    this.conversionEvents = newConversionEvents;
    this.updatedAt = Date.now();
    return this.conversionEvents;
  }

  async init(): Promise<void> {
    return undefined;
  }

  prepare(): void {}
}
