import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

export function useDisconnectGithub(
  tenant: number,
  options: UseMutationOptions = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await axios.delete(`${BONGO_URL}/v1/org/${tenant}/integrations/github`);
    },
    onSuccess: (...args) => {
      queryClient.setQueryData(['github', tenant], false);
      queryClient.setQueryData(['github-credentials', tenant], {});
      options?.onSuccess?.(...args);
    },
  });
}
