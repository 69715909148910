import React from 'react';
import { Button, Col, FormControl, Label, Row, Table } from 'react-bootstrap';
import { VERBS } from '../../mapping/utils';

const FILTER_TRAITS = ['category', 'job_opening_title'];
const TRAITS = [
  'job_opening_title',
  'location',
  'category',
  'seniority',
  'description',
  'contract_types',
  'keywords',
];
const IS_SUBCONDITION = true;

type ConfigConditionsFormProps = {
  formData: UnknownObject;
  filters?: boolean;
};

type ConfigConditionsFormState = {
  conditions: any[];
  filtersLogic: string;
};

export default class ConfigConditionsForm extends React.Component<
  ConfigConditionsFormProps,
  ConfigConditionsFormState
> {
  keyword: string;

  traits: string[];

  constructor(props: ConfigConditionsFormProps) {
    super(props);
    this.handleTraitChange = this.handleTraitChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.createNewCondition = this.createNewCondition.bind(this);
    this.createNewConditionValue = this.createNewConditionValue.bind(this);
    this.removeConditionValue = this.removeConditionValue.bind(this);
    this.handleLogicChange = this.handleLogicChange.bind(this);
    this.getVerbBoundaries = this.getVerbBoundaries.bind(this);
    this.keyword = props.filters ? 'filters' : 'conditions';

    // "conditions" can refer to 'conditions' or 'filters' within this component.
    // this is to allow us to use the same component for both forms (condition and filter)
    this.state = {
      conditions: this.verifyProps(this.keyword)
        ? props.formData[this.keyword]
        : [],
      filtersLogic:
        this.verifyProps('filtersLogic') && props.filters
          ? props.formData.filtersLogic
          : null,
    };

    this.traits = props.filters ? FILTER_TRAITS : TRAITS;
  }

  // eslint-disable-next-line
  componentWillMount() {
    if (this.state.conditions.length) return;
    this.createNewCondition();
  }

  componentDidUpdate() {
    this.props.formData[this.keyword] = this.state.conditions;
    if (this.props.filters) {
      this.props.formData.filtersLogic = this.state.filtersLogic;
    }
  }

  verifyProps(key: string) {
    return this.props.formData && this.props.formData[key];
  }

  handleLogicChange(e: any) {
    const logic = e.target.value;
    this.setState({ filtersLogic: logic.toUpperCase() });
  }

  createNewCondition(trait = TRAITS[0], verb = 'is', firstValue = '') {
    const newCondition = this.conditionCreator(trait, verb, firstValue);
    const conditions = [...this.state.conditions, newCondition];
    const stateToUpdate: any = { conditions };

    if (this.props.filters) {
      stateToUpdate.filtersLogic = conditions
        .filter((c) => c)
        .map((_e, i) => `$${i + 1}`)
        .join(' AND ');
    }

    this.setState(stateToUpdate);
  }

  conditionCreator(trait = TRAITS[0], verb = 'is', firstValue = '') {
    return {
      trait,
      verb,
      values: [firstValue],
    };
  }

  createNewConditionValue(
    conditionIndex: number,
    isSubCondition: boolean,
    parentCondIndex: number
  ) {
    const { conditions } = this.state;
    const condition =
      isSubCondition && conditions[parentCondIndex].subConditions
        ? conditions[parentCondIndex].subConditions[conditionIndex]
        : conditions[conditionIndex];

    condition.values.push('');
    this.setState({ ...this.state, conditions });
  }

  removeConditionValue(
    conditionIndex: number,
    isSubCondition: boolean,
    parentCondIndex: number
  ) {
    const { conditions } = this.state;
    const condition =
      isSubCondition && conditions[parentCondIndex].subConditions
        ? conditions[parentCondIndex].subConditions[conditionIndex]
        : conditions[conditionIndex];

    condition.values.pop();
    this.setState({ ...this.state, conditions });
  }

  // modify a condition with a given property and condition index
  // modifies state with new conditions
  modifyCondition(
    property: string,
    value: string,
    conditionIndex: number,
    valueIndex: number = null,
    isSubCondition = false,
    parentConditionIndex: number
  ) {
    const conditions = this.state.conditions.map((pCondition, pIndex) => {
      const condition =
        isSubCondition && pCondition.subConditions
          ? pCondition.subConditions[conditionIndex]
          : pCondition;
      const index = isSubCondition ? parentConditionIndex : conditionIndex;

      if (index === pIndex) {
        if (property === 'value') {
          condition.values.forEach((_inputValue: string, vindex: number) => {
            if (vindex === valueIndex) {
              // eslint-disable-next-line
              condition.values[vindex] = value;
            }
          });
        } else {
          // eslint-disable-next-line
          condition[property] = value;
        }
      }
      return pCondition;
    });
    this.setState({ ...this.state, conditions });
  }

  handleTraitChange(
    e: any,
    conditionIndex: number,
    isSubCondition: boolean,
    parentConditionIndex: number
  ) {
    this.modifyCondition(
      'trait',
      e.target.value,
      conditionIndex,
      null,
      isSubCondition,
      parentConditionIndex
    );
  }

  handleValueChange(
    e: any,
    conditionIndex: number,
    valueIndex: number,
    isSubCondition: boolean,
    parentConditionIndex: number
  ) {
    this.modifyCondition(
      'value',
      e.target.value,
      conditionIndex,
      valueIndex,
      isSubCondition,
      parentConditionIndex
    );
  }

  handleVerbChange(
    e: any,
    conditionIndex: number,
    isSubCondition: boolean,
    parentConditionIndex: number
  ) {
    const verb = e.target.value;
    const boundaries = this.getVerbBoundaries(verb);
    const { conditions } = this.state;
    const condition =
      isSubCondition && conditions[parentConditionIndex].subConditions
        ? conditions[parentConditionIndex].subConditions[conditionIndex]
        : conditions[conditionIndex];

    condition.verb = verb;
    condition.values = [];
    for (let i = 0; i <= boundaries.min - 1; i += 1) {
      condition.values.push('');
    }

    this.setState({ conditions });
  }

  handleRemoveCondition(conditionIndex: number) {
    const newConditions = this.state.conditions.filter((c, index) => {
      return conditionIndex !== index;
    });
    const stateToUpdate: any = { conditions: newConditions };

    if (this.props.filters) {
      stateToUpdate.filtersLogic = newConditions
        .filter((c) => c)
        .map((_e, i) => `$${i + 1}`)
        .join(' AND ');
    }

    this.setState(stateToUpdate);
  }

  handleAddSubCondition(conditionIndex: number) {
    const { conditions } = this.state;
    const newCondition = {
      ...conditions[conditionIndex],
      subConditions: [
        ...(conditions[conditionIndex].subConditions || []),
        this.conditionCreator(),
      ],
    };
    const newConditions = conditions.map((condition, index) => {
      if (conditionIndex === index) return newCondition;
      return condition;
    });
    this.setState({ conditions: newConditions });
  }

  handleRemoveSubCondition(
    parentConditionIndex: number,
    subConditionIndex: number
  ) {
    const { conditions } = this.state;
    const newConditions = conditions.map((condition, index) => {
      if (!condition) return null;
      const { subConditions } = condition;

      if (subConditions && parentConditionIndex === index) {
        const newSubConditions = subConditions.filter(
          (_c: string, subIndex: number) => {
            return subIndex !== subConditionIndex;
          }
        );
        return {
          ...condition,
          subConditions: newSubConditions,
        };
      }
      return condition;
    });

    this.setState({ conditions: newConditions });
  }

  getVerbBoundaries(trait: string) {
    switch (trait) {
      case 'is between':
        return {
          min: 2,
          max: 2,
        };
      case 'is known':
        return {
          min: 0,
          max: 0,
        };
      case 'is unknown':
        return {
          min: 0,
          max: 0,
        };
      case 'is any of':
        return {
          min: 1,
          max: 10,
        };
      case 'is none of':
        return {
          min: 1,
          max: 10,
        };
      default:
        return {
          min: 1,
          max: 1,
        };
    }
  }

  conditionsColumnsBuilder(
    condition: UnknownObject,
    conditionIndex: number,
    isSubCondition?: boolean,
    parentConditionIndex?: number
  ) {
    const { filters: isFilters } = this.props;
    return [
      // eslint-disable-next-line react/jsx-key
      <td>
        <Col xs={3} style={{ width: '100px', marginLeft: '3px' }}>
          {isSubCondition && <b>AND</b>}
          <FormControl
            name="trait"
            componentClass="select"
            value={condition.trait}
            onChange={(e) =>
              this.handleTraitChange(
                e,
                conditionIndex,
                isSubCondition,
                parentConditionIndex
              )
            }
          >
            {this.traits.map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </FormControl>
        </Col>
      </td>,
      // eslint-disable-next-line react/jsx-key
      <td>
        <Col xs={3} style={{ width: '100px', marginLeft: '3px' }}>
          <FormControl
            name="verb"
            componentClass="select"
            value={condition.verb}
            onChange={(e) =>
              this.handleVerbChange(
                e,
                conditionIndex,
                isSubCondition,
                parentConditionIndex
              )
            }
          >
            {VERBS.map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </FormControl>
        </Col>
      </td>,
      // eslint-disable-next-line react/jsx-key
      <td>
        <Col xs={3} style={{ width: '100px', marginLeft: '3px' }}>
          <div>
            {condition.values.map((value: string, valueIndex: number) => {
              return (
                <FormControl
                  name="value"
                  type="text"
                  value={value}
                  key={`form_control${conditionIndex}_condition${valueIndex}`}
                  onChange={(e) =>
                    this.handleValueChange(
                      e,
                      conditionIndex,
                      valueIndex,
                      isSubCondition,
                      parentConditionIndex
                    )
                  }
                />
              );
            })}
          </div>
        </Col>
      </td>,
      // eslint-disable-next-line react/jsx-key
      <td>
        <Row className="pl-2">
          {condition.values.length <
            this.getVerbBoundaries(condition.verb).max && (
            <Col xs={1} className="mr-1">
              <Button
                bsStyle="primary"
                onClick={() =>
                  this.createNewConditionValue(
                    conditionIndex,
                    isSubCondition,
                    parentConditionIndex
                  )
                }
              >
                +
              </Button>
            </Col>
          )}
          {condition.values.length >
            this.getVerbBoundaries(condition.verb).min && (
            <Col xs={1} className="mr-1">
              <Button
                bsStyle="danger"
                onClick={() =>
                  this.removeConditionValue(
                    conditionIndex,
                    isSubCondition,
                    parentConditionIndex
                  )
                }
              >
                -
              </Button>
            </Col>
          )}
          {!isSubCondition && (
            <Col xs={1} className="mr-1">
              <Button
                bsStyle="danger"
                onClick={() => this.handleRemoveCondition(conditionIndex)}
              >
                X
              </Button>
            </Col>
          )}
          {!isSubCondition && !isFilters && (
            <Col>
              <Button
                bsStyle="info"
                onClick={() => this.handleAddSubCondition(conditionIndex)}
              >
                Add sub-condition
              </Button>
            </Col>
          )}
          {isSubCondition && !isFilters && (
            <Col>
              <Button
                bsStyle="danger"
                onClick={() =>
                  this.handleRemoveSubCondition(
                    parentConditionIndex,
                    conditionIndex
                  )
                }
              >
                Remove sub-condition
              </Button>
            </Col>
          )}
        </Row>
      </td>,
    ];
  }

  conditionsRowBuilder(condition: UnknownObject, conditionIndex: number) {
    const { filters: isFilters } = this.props;
    const { subConditions = [] } = condition;

    const row = [
      <tr key={conditionIndex}>
        {this.conditionsColumnsBuilder(condition, conditionIndex)}
      </tr>,
    ];
    // if subconditions, add extra row for each subcondition
    if (subConditions && !isFilters) {
      subConditions.forEach(
        (subCondition: UnknownObject, subConditionIndex: number) => {
          const subConditionRow = (
            <tr
              key={`${subConditionIndex}_sub`}
              style={{ backgroundColor: 'lightblue' }}
            >
              {this.conditionsColumnsBuilder(
                subCondition,
                subConditionIndex,
                IS_SUBCONDITION,
                conditionIndex
              )}
            </tr>
          );
          row.push(subConditionRow);
        }
      );
    }
    return row;
  }

  render() {
    const { filters } = this.props;
    const { conditions, filtersLogic } = this.state;

    return (
      <div>
        <Row>
          <Col sm={12}>
            <div>
              {filters && (
                <Row>
                  <Col md={2}>
                    <Label className="font-weight-bold">
                      Edit condition logic:
                    </Label>
                  </Col>
                  <Col md={6}>
                    <FormControl
                      type="text"
                      value={filtersLogic}
                      onChange={this.handleLogicChange}
                      placeholder="$1 AND ($2 OR $3)"
                      name="conditionsLogic"
                      className="mb-2"
                    />
                  </Col>
                </Row>
              )}
              <Table hover>
                <thead>
                  <tr>
                    <th>Trait</th>
                    <th>Verb</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody key={'form_group_conditions'}>
                  {conditions.map((condition, conditionIndex) => {
                    if (!condition) return null;
                    return this.conditionsRowBuilder(condition, conditionIndex);
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row className="pull-right">
          <Col>
            <Button bsStyle="primary" onClick={() => this.createNewCondition()}>
              Add a new condition
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
