import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import OverrideContainer from './reactComponents/OverrideContainer';

function check_and_redirect(model: UnknownObject, tenant: number) {
  if (!model.batch || !model.live) {
    Backbone.history.navigate(`/org/${tenant}/predictions`, true);
    return true;
  }
  return false;
}

export async function getModel(tenant: number, modelId: number) {
  const url = `${BONGO_URL}/v1/org/${tenant}/models/${modelId}`;
  const res = await (axios as any).get(url, {
    crossDomain: true,
    item: { tenant, id: modelId },
  });
  const model = res.data;

  // If illegal data, redirect and don't fill the vars in this object
  if (check_and_redirect(model, tenant)) {
    return;
  }
  return model;
}

function launchMQAOverridesSwal(
  tenant: number,
  modelId: number,
  userEmail: string
) {
  Swal.fire({
    title: 'Overrides',
    width: '50%',
    icon: 'info',
    showConfirmButton: false,
  });

  ReactDOM.render(
    <OverrideContainer
      tenant={tenant}
      modelId={modelId}
      userEmail={userEmail}
    />,
    $('.swal2-content')[0]
  );
}

export async function displayOverrides(
  tenant: number,
  modelId: number,
  userEmail: string
) {
  return launchMQAOverridesSwal(tenant, modelId, userEmail);
}
