import React from 'react';
import { Table } from 'react-bootstrap';

export default class OverviewCardComponent extends React.Component {
  render() {
    return (
      <div className="card box-shadow-3d mb-4">
        <div className="card-header">
          <h4 className="mb-0">Configure Conversion Mapping</h4>
        </div>
        <Table responsive className="table align-items-center">
          <thead>
            <tr className="text-center">
              <th>Type</th>
              <th>Definitions</th>
              <th>Defined Within</th>
            </tr>
          </thead>
          <tbody className="con-map-connector-size">
            <tr>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-dark con-map-mk-badge-size">
                  Open Opp
                </span>
              </td>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-gray con-map-mk-badge-size">
                  &nbsp;
                </span>
              </td>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-gray con-map-mk-badge-size">
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-success con-map-mk-badge-size">
                  Closed Won
                </span>
              </td>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-gray con-map-mk-badge-size">
                  &nbsp;
                </span>
              </td>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-gray con-map-mk-badge-size">
                  &nbsp;
                </span>
              </td>
            </tr>
            <tr>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-success con-map-mk-badge-size">
                  SQO
                </span>
              </td>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-gray con-map-mk-badge-size">
                  &nbsp;
                </span>
              </td>
              <td className="align-middle con-map-connector-size text-center">
                <span className="badge badge-gray con-map-mk-badge-size">
                  &nbsp;
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
