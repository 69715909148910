import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons/faExternalLink';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import {
  ConversionTree,
  SegmentsColors,
  Node,
  EnrichmentCategory,
  ModelSubset,
} from '../../../../types';
import NodeCard from './NodeCard';

type Props = {
  conversionTree: ConversionTree;
  tenant: number;
  model: ModelSubset;
  email: string;
  setTree: Dispatch<SetStateAction<number>>;
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
};

export default function ConversionPath({
  conversionTree,
  tenant,
  model,
  email,
  setTree,
  setSearch,
  setSelectedCategory,
}: Props) {
  return (
    <>
      <div className="tw-ml-[4rem] tw-mt-8 tw-leading-loose">
        <div
          className="tw-mb-4 tw-flex tw-items-center gap-2 tw-cursor-pointer hover:tw-opacity-80"
          onClick={() => setTree(0)}
        >
          <span className="tw-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-rounded-full tw-bg-white">
            <FontAwesomeIcon
              icon={faArrowLeft}
              width="22"
              height="22"
              className="tw-text-blue-700"
            />
          </span>
          <span className="tw-text-blue-700 tw-text-sm">Back to Overview</span>
        </div>
        <span className="tw-text-xl tw-font-bold">
          Path for Decision tree {conversionTree.index}
        </span>
        <div className="tw-text-sm tw-leading-relaxed">
          Here is the path for <b>{email}</b> based on{' '}
          <b>Decision tree {conversionTree.index}</b>
          <br />
          You can see the complete tree in{' '}
          <a
            href={`https://studio.madkudu.com/tenant/${tenant}/models/${model.modelId}/model/trees/${conversionTree.index}`}
            target="_blank noopener noreferrer"
            className="hover:tw-text-blue-800 hover:tw-no-underline tw-text-blue-700"
          >
            Studio <FontAwesomeIcon icon={faExternalLink} size="sm" />
          </a>
        </div>
      </div>

      <div className="tw-relative tw-py-10 tw-overflow-auto visible-scrollbar">
        <div className="tw-min-w-full tw-flex tw-flex-nowrap tw-items-start">
          <div className="tw-relative tw-ml-auto tw-min-w-[4rem]"></div>

          {conversionTree.paths
            .filter(
              (node: Node, i: number) => i < conversionTree.paths.length - 1
            )
            .map((node: Node, i: number) => {
              const isLastNode = i + 1 === conversionTree.paths.length - 1;
              return (
                <div key={i} className="tw-flex my-3">
                  <>
                    {i === 0 && (
                      <div className="tw-relative tw-min-w-[4rem]">
                        <span className="tw-absolute tw-top-9 tw-left-0 tw-m-auto tw-block tw-h-3 tw-w-3 tw-rounded-full tw-bg-white"></span>
                        <div className="tw-absolute tw-h-[1px] tw-border-t-2 tw-border-white tw-border-dashed tw-top-10  tw-left-0 tw-right-0"></div>
                      </div>
                    )}

                    <NodeCard
                      conversionTree={conversionTree}
                      node={node}
                      conditionIsTrue={conversionTree.paths[i + 1]?.ifTrue}
                      tenant={tenant}
                      model={model}
                      setSearch={setSearch}
                      setSelectedCategory={setSelectedCategory}
                    />

                    <div
                      className={`tw-relative ${
                        isLastNode
                          ? 'tw-min-w-[12rem]'
                          : 'tw-min-w-[9rem] tw-text-center'
                      }`}
                    >
                      <div
                        className={`tw-absolute tw-h-[1px] tw-border-t-2 tw-border-white tw-border-dashed tw-top-10 tw-left-0 ${
                          isLastNode ? 'tw-w-8' : 'tw-right-0'
                        }`}
                      ></div>
                      <span
                        className={`tw-relative tw-whitespace-nowrap tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-bg-[${
                          SegmentsColors[conversionTree.paths[i + 1].segment]
                        }] tw-text-white tw-capitalize tw-z-10 tw-rounded-md tw-mt-7 tw-inline-block ${
                          isLastNode ? 'tw-ml-[44px]' : ''
                        }`}
                      >
                        {i + 1 === conversionTree.paths.length - 1 && (
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            size="xl"
                            className="tw-absolute -tw-left-[16px] -tw-top-[2px] tw-bottom-0 tw-m-auto"
                          />
                        )}

                        {conversionTree.paths[i + 1].segment}
                      </span>
                    </div>
                  </>
                </div>
              );
            })}
          <div className="tw-relative tw-mr-auto tw-min-w-[4rem]"></div>
        </div>
      </div>
    </>
  );
}
