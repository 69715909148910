import chunk from 'lodash/chunk';
import React, { useEffect, useState } from 'react';

import ReactJsPagination from 'react-js-pagination';

type UsePaginationOptions = {
  defaultMaxPerPage?: number;
  defaultPage?: number;
};

export default function usePagination<T = unknown>(
  originalItems: Array<T> = [],
  options?: UsePaginationOptions
) {
  const computedOptions: Required<UsePaginationOptions> = {
    defaultMaxPerPage: options?.defaultMaxPerPage ?? 50,
    defaultPage: options?.defaultPage ?? 0,
  };
  const [currentPage, setCurrentPage] = useState(computedOptions.defaultPage);
  const [maxPerPage, setMaxPerPage] = useState(
    computedOptions.defaultMaxPerPage
  );

  const totalItems = originalItems.length;
  const totalPages = Math.ceil(totalItems / maxPerPage) || 1;

  useEffect(() => {
    // Resets to first page if not enough pages for newly passed items
    if (totalPages <= currentPage) setCurrentPage(0);
  }, [totalItems, maxPerPage]);

  const paginatedItems = chunk(originalItems, maxPerPage);
  const currentHumanPage = currentPage + 1;

  return {
    Pagination: function Pagination() {
      return (
        <ReactJsPagination
          activePage={currentHumanPage}
          itemsCountPerPage={maxPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={100}
          onChange={(pageNumber) => setCurrentPage(pageNumber - 1)}
          itemClass="page-item" // added because we use bootstrap4
          linkClass="page-link" // added because we use bootstrap4
        />
      );
    },
    originalItems,
    activeItems: paginatedItems[currentPage] ?? [],
    paginatedItems,
    totalItems,
    currentPage,
    currentHumanPage,
    previousPage: () => {
      if (currentPage - 1 >= 0) setCurrentPage(currentPage - 1);
    },
    nextPage: () => {
      if (currentPage + 1 <= totalPages) setCurrentPage(currentPage + 1);
    },
    setCurrentPage,
    maxPerPage,
    setMaxPerPage,
    totalPages,
  };
}
