import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import { BackboneViewProps } from '../types';
import FieldsMapping from './fields_mapping';
import { deleteIntegrationAndLaunchPopUp } from './utils';

const channel = Radio.channel('integration');

const template = require('./pipedrive.pug');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
    'click #mki_delete_connection': 'delete',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'pipedrive';
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    if (!this.model.get('list_id')) {
      this.model.set({ list_id: '' });
    }

    const credentialsFound = this.model.get('not_found') !== true;

    this.model.set({
      api_token: null,
    });

    const params = {
      api_token_placeholder: credentialsFound
        ? '*****'
        : 'Enter your Pipedrive API Key',
      credentialsFound,
      integration: this.integration,
    };

    const html = $('#tpl_main', template(params)).html();
    this.$el.html(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    this.applyBindings();
  },

  async delete() {
    await deleteIntegrationAndLaunchPopUp(this.tenant, 'pipedrive');
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();

    const show_error_message = function (text: string) {
      Swal.fire({
        title: 'Oops',
        text,
        icon: 'error',
      });
    };

    if (!this.model.get('api_token')) {
      return show_error_message('Please enter an API Key');
    }

    const credentials = {
      api_token: this.model.get('api_token'),
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true&activate_push=true`,
        credentials
      )
      .then(() => {
        channel.trigger('success', { integration: this.integration });
      })
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  },
});
