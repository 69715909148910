import React, { useState } from 'react';
import { Steps } from '../../components/Steps';
import { GrantAccessStep } from './steps/GrantAccessStep';
import { ProjectConfigurationStep } from './steps/ProjectConfigurationStep';
import { TableMappingStep } from './steps/TableMappingStep';
import { ColumnMappingStep } from './steps/ColumnMappingStep';

export function BigQueryForm() {
  const [step, setStep] = useState(0);

  return (
    <>
      <Steps
        steps={[
          { title: 'Grant access' },
          { title: 'Project configuration' },
          { title: 'Table Mapping' },
          { title: 'Column Mapping' },
        ]}
        currentStep={step}
        onStepChange={(nextStep) => {
          setStep(nextStep);
        }}
      />
      {step === 0 && (
        <GrantAccessStep
          nextStep={() => {
            setStep(1);
          }}
        />
      )}
      {step === 1 && (
        <ProjectConfigurationStep
          nextStep={() => {
            setStep(2);
          }}
          previousStep={() => {
            setStep(1);
          }}
        />
      )}
      {step === 2 && (
        <TableMappingStep
          previousStep={() => {
            setStep(1);
          }}
          nextStep={() => {
            setStep(3);
          }}
        />
      )}
      {step === 3 && (
        <ColumnMappingStep
          previousStep={() => {
            setStep(2);
          }}
          nextStep={() => {
            setStep(0);
          }}
        />
      )}
    </>
  );
}
