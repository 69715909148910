import React from 'react';
import { Modal } from 'react-bootstrap';

export default class CalendarChoices extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      showAdvancedModal: false,
    };

    this.toggleAdvancedModal = this.toggleAdvancedModal.bind(this);
    this.openCampaign = this.openCampaign.bind(this);
  }

  toggleAdvancedModal() {
    this.setState({ showAdvancedModal: !this.state.showAdvancedModal });
  }

  openCampaign() {
    this.toggleAdvancedModal();
    this.props.openCampaign();
  }

  render() {
    const { confirmCalendarChoice } = this.props;
    const { showAdvancedModal } = this.state;

    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-3 col-sm-4 d-flex justify-content-center">
            <div
              className="card box-shadow-3d hover-size-increase"
              onClick={confirmCalendarChoice('calendly')}
              style={{ width: '18rem', height: '18rem', cursor: 'pointer' }}
            >
              <div
                className="card-body m-4 rounded d-flex justify-content-center"
                style={{ backgroundColor: '#00A1E0' }}
              >
                <div
                  className="card-body m-2 rounded box-shadow-3d d-flex flex-wrap align-items-center"
                  style={{ backgroundColor: 'white' }}
                >
                  <img
                    className="img-fluid my-auto"
                    src="/media/calendars/calendly.png"
                    alt="Card image cap"
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className="col-3 col-sm-4 d-flex justify-content-center">
            <div
              className="card box-shadow-3d hover-size-increase"
              onClick={confirmCalendarChoice('chilipiper')}
              style={{ width: '18rem', height: '18rem', cursor: 'pointer' }}
            >
              <div
                className="card-body m-4 rounded d-flex justify-content-center"
                style={{ backgroundColor: '#FF7A59' }}
              >
                <div
                  className="card-body m-2 rounded box-shadow-3d"
                  style={{ backgroundColor: 'white' }}
                >
                  <img
                    className="img-fluid"
                    src="/media/calendars/chilipiper.png"
                    alt="Card image cap"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 col-sm-4 d-flex justify-content-center">
            <div
              className="card box-shadow-3d hover-size-increase"
              onClick={this.toggleAdvancedModal}
              data-placement="bottom"
              style={{ width: '18rem', height: '18rem', cursor: 'pointer' }}
            >
              <div
                className="card-body m-4 rounded d-flex justify-content-center "
                style={{ backgroundColor: '#182B52' }}
              >
                <div
                  className="card-body m-2 rounded box-shadow-3d d-flex flex-wrap align-items-center"
                  style={{ backgroundColor: 'white' }}
                >
                  <div className="align-items-center">
                    <h6 className="my-auto">Other Calendar</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAdvancedModal && (
          <div className="static-modal">
            <Modal.Dialog>
              <Modal.Body>
                In order to implement another calendar you must head over to
                advanced mode. We advise you to setup a supported calendar
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.toggleAdvancedModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.openCampaign}
                >
                  Advanced mode
                </button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )}
      </React.Fragment>
    );
  }
}
