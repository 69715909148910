import { ConversionTypeData } from './ConversionTypeData';
import {
  ConnectorsOfConversionTypes,
  MappingConversionsTypes,
} from '../../../types';
import { ActiveConnectorDataModel } from './ConversionMappingData';
import { ParametersFieldsNamesDataModel } from '../../models/ConnectorFieldNameDataModel';

export type ConversionTypeDataIndexed = {
  index: number;
  conversionTypeData: ConversionTypeData;
};

export type ConversionTypesGroupOptions = {
  createdAt?: number;
  updatedAt?: number;
  indexedConversionTypesData?: ConversionTypeDataIndexed[];
};

export class ConversionTypesGroup {
  indexedConversionTypesData: ConversionTypeDataIndexed[];

  name: string;

  createdAt: number;

  updatedAt: number;

  type: MappingConversionsTypes;

  tenant: number;

  email: string;

  tag: 'standard' | 'custom';

  index: number;

  isCustom: boolean;

  constructor(
    tenant: number,
    email: string,
    name: string,
    isCustom: boolean,
    tag: 'standard' | 'custom',
    index: number,
    type: MappingConversionsTypes,
    parametersFieldsNames: ParametersFieldsNamesDataModel,
    options?: ConversionTypesGroupOptions
  ) {
    this.name = name;
    this.type = type;
    this.tenant = tenant;
    this.email = email;
    this.tag = tag;
    this.index = index;
    this.isCustom = isCustom;
    this.indexedConversionTypesData = [];
    this.build(parametersFieldsNames, options);
  }

  build(
    parametersFieldsNames: ParametersFieldsNamesDataModel,
    options: ConversionTypesGroupOptions
  ) {
    // createdAt
    if (options?.createdAt) {
      this.createdAt = options.createdAt;
    } else {
      this.createdAt = Date.now();
    }
    // updatedAt
    if (options?.updatedAt) {
      this.updatedAt = options.updatedAt;
    } else {
      this.updatedAt = Date.now();
    }
    // conversionTypesData
    if (options?.indexedConversionTypesData) {
      this.indexedConversionTypesData = this.transformToIndexedConversionTypesDataInstances(
        options.indexedConversionTypesData,
        parametersFieldsNames
      );
      this.setGroupNameOfConversionTypes(this.name);
    } else {
      this.indexedConversionTypesData = [];
    }
  }

  transformToIndexedConversionTypesDataInstances(
    indexedConversionTypesData: ConversionTypeDataIndexed[],
    parametersFieldsNames: ParametersFieldsNamesDataModel
  ): ConversionTypeDataIndexed[] {
    return indexedConversionTypesData.map((indexedConversionTypeData) => {
      const { conversionTypeData } = indexedConversionTypeData;
      const connectorFieldNames =
        parametersFieldsNames[conversionTypeData.connector] || [];
      const newConversionTypeData = new ConversionTypeData(
        this.tenant,
        this.email,
        conversionTypeData.isCustom,
        conversionTypeData.connector,
        conversionTypeData.conversionType,
        connectorFieldNames,
        this.name,
        {
          amountField: conversionTypeData.amountField,
          createdAt: conversionTypeData.createdAt,
          filtersForm: conversionTypeData.filtersForm,
          filterTypesValues: conversionTypeData.filterTypesValues,
          updatedAt: conversionTypeData.updatedAt,
          madMlSqlQuery: conversionTypeData.madMlSqlQuery,
        }
      );
      return {
        index: indexedConversionTypeData.index,
        conversionTypeData: newConversionTypeData,
      };
    });
  }

  async init(
    activeConnectors: ActiveConnectorDataModel[],
    parametersFieldsNames: ParametersFieldsNamesDataModel,
    index: number
  ): Promise<number> {
    if (this.indexedConversionTypesData?.length === 0) {
      if (this.isCustom) {
        return this.buildMadMlConversionTypesData(index);
      }
      return this.buildAndInitConversionTypesData(
        activeConnectors,
        parametersFieldsNames,
        index
      );
    }
  }

  buildConversionTypesData(
    activeConnectors: ActiveConnectorDataModel[],
    parametersFieldsNames: ParametersFieldsNamesDataModel,
    index: number
  ): number {
    let newIndex: number = index;
    const acceptedConnectors = activeConnectors?.filter(
      ({ isActive, connector }) =>
        isActive && ConnectorsOfConversionTypes[this.type].includes(connector)
    );

    if (acceptedConnectors?.length > 0) {
      acceptedConnectors.forEach((acceptedConnector) => {
        newIndex += 1;
        const conversionTypeData = new ConversionTypeData(
          this.tenant,
          this.email,
          false,
          acceptedConnector.connector,
          this.type,
          parametersFieldsNames[acceptedConnector.connector],
          this.name
        );
        const indexedConversionTypeData = {
          conversionTypeData,
          index: newIndex,
        };
        this.indexedConversionTypesData.push(indexedConversionTypeData);
      });
    }
    return newIndex;
  }

  buildMadMlConversionTypesData(index: number): number {
    const conversionTypeData = new ConversionTypeData(
      this.tenant,
      this.email,
      true,
      'madMl',
      this.type,
      null,
      this.name
    );
    this.indexedConversionTypesData = [
      {
        conversionTypeData,
        index: index + 1,
      },
    ];
    return index + 1;
  }

  async buildAndInitConversionTypesData(
    activeConnectors: ActiveConnectorDataModel[],
    parametersFieldsNames: ParametersFieldsNamesDataModel,
    index: number
  ): Promise<number> {
    const newIndex = this.buildConversionTypesData(
      activeConnectors,
      parametersFieldsNames,
      index
    );
    const listOfPromises = this.indexedConversionTypesData.map(
      (indexedConversionTypeData) => {
        return indexedConversionTypeData.conversionTypeData.init();
      }
    );
    await Promise.all(listOfPromises);
    return newIndex;
  }

  getMaxIndex(): number {
    return (
      this.indexedConversionTypesData.sort((a, b) => {
        return b.index - a.index;
      })[0]?.index ?? 0
    );
  }

  setGroupNameOfConversionTypes(newName: string) {
    this.indexedConversionTypesData = this.indexedConversionTypesData.map(
      (indexedConversionTypesData) => {
        const newIndexedConversionTypesData = indexedConversionTypesData;
        newIndexedConversionTypesData.conversionTypeData.groupName = newName;
        return newIndexedConversionTypesData;
      }
    );
  }

  createNewCustomConversionType(wantedIndex: number) {
    const customConversionType = new ConversionTypeData(
      this.tenant,
      this.email,
      true,
      'madMl',
      this.type,
      [],
      this.name
    );

    const newIndexedConversionTypeData = {
      index: wantedIndex + 1,
      conversionTypeData: customConversionType,
    };

    this.indexedConversionTypesData.push(newIndexedConversionTypeData);
  }

  setIsCustom(newIndex: number) {
    this.isCustom = !this.isCustom;
    if (this.isCustom) {
      const hasAlreadyACustomConversionType: boolean = this.indexedConversionTypesData.some(
        (indexedConversionType) => {
          return indexedConversionType.conversionTypeData.isCustom;
        }
      );

      if (!hasAlreadyACustomConversionType) {
        this.createNewCustomConversionType(newIndex);
      }
    }
    this.updatedAt = Date.now();
  }

  getCustomIndexedConversionType(): ConversionTypeDataIndexed {
    return this.indexedConversionTypesData.find((indexedConversionTypeData) => {
      return indexedConversionTypeData.conversionTypeData.isCustom;
    });
  }

  prepare() {
    this.indexedConversionTypesData.forEach((indexedConversionTypeData) => {
      indexedConversionTypeData.conversionTypeData.prepare();
    });
  }
}
