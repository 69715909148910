import React from 'react';
import { unslugFieldName } from '../../../pullConfigManager';
import AttributeMappingManager from '../../models/AttributeMappingManager';
import MissingFieldMicrocopy from '../../../../components/ErrorMicroCopyComponent';
import MultiSelect from '../../../../components/select/MultiSelect';
import { MultiSelectOption } from '../../../../components/select/types';

interface LeadMappingFormProps {
  attributeMappingManager: AttributeMappingManager;
  isEditClicked: boolean;
  onLeadFieldsChange: (newLeadFields: string[]) => void;
}

function LeadMappingFormComponent({
  attributeMappingManager,
  isEditClicked,
  onLeadFieldsChange,
}: LeadMappingFormProps) {
  const { isReadOnly, data: attributeMappingData } = attributeMappingManager;
  const salesforceLeadFields = attributeMappingData
    .getFilterForm('salesforce')
    .getFields('Lead', false);

  const { leadFields } = attributeMappingData.getFilterForm('salesforce');

  const fieldToOption = (field: string): MultiSelectOption => ({
    label: unslugFieldName(field),
    value: field,
    isFixed: field === 'a_convertedaccountid',
  });

  return (
    <>
      <p>
        Which field on the Lead object identifies the account ID it is linked
        to?
      </p>
      <MultiSelect
        disabled={isReadOnly || !isEditClicked}
        options={salesforceLeadFields.map(({ name }) => fieldToOption(name))}
        values={leadFields.map((field) => fieldToOption(field))}
        onChange={(newValues) => {
          onLeadFieldsChange(newValues.map(({ value }) => value));
        }}
      />
      {attributeMappingData.hasErrorOnProperty(
        'salesforce',
        -1,
        'leadField'
      ) && (
        <MissingFieldMicrocopy
          microcopy={`Please select a valid lead field value.`}
        />
      )}
    </>
  );
}

export default LeadMappingFormComponent;
