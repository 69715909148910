import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { BackboneViewProps } from '../../types';
import { Snowflake } from './Snowflake';
import { defaultQueryClient, localStoragePersister } from '../../query_client';
import { SnowflakeProvider } from './contexts/SnowflakeContext';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'snowflake';
    requestAnimationFrame(() => {
      this.render();
      this.renderSnowflakeIntegrationComponent();
    });
  },

  getActiveTabName(): string {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === '') {
      return 'overview';
    }
    return lastItem;
  },

  toggleView(tenant: string, integration: string) {
    return (tabName: string) => {
      Backbone.history.navigate(
        `/org/${tenant}/integrations/${integration}/${tabName}`
      );
    };
  },

  renderSnowflakeIntegrationComponent() {
    const containerDomElement = $('#SnowflakeComponentContainer')[0];
    if (containerDomElement) {
      ReactDOM.render(
        <PersistQueryClientProvider
          client={defaultQueryClient}
          persistOptions={{ persister: localStoragePersister }}
        >
          <SnowflakeProvider tenant={this.tenant}>
            <Snowflake />
          </SnowflakeProvider>
        </PersistQueryClientProvider>,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
