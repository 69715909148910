import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import { BackboneViewProps } from '../types';

const Syntax = require('syntax');
const template = require('./sqline_ui.pug');

declare const conversion_status: any;

export default Backbone.View.extend({
  events: {
    'click #convert': 'convert_yaml',
  },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.render();
  },

  save_dom_elements() {
    const container = document.querySelector('#sqline');
    this.elements = {
      conversion_status: document.querySelector('#conversion_status'),
      yaml_input: document.querySelector('#yaml_input'),
      sql_results_wrapper: document.querySelector('#sql_results'),
      sql_results: [...Array.from(document.querySelectorAll('.sql_result'))],
      container,
    };
  },

  async convert_yaml() {
    // save DOM elements
    this.save_dom_elements();

    // fetch sql result from API
    const results = await this.call_sqline_api(this.elements.yaml_input);

    if (results.length < 1) {
      // handle invalid API results
      this.elements.conversion_status.innerText = 'Invalid input, try again';
      this.elements.conversion_status.classList.add('error');
      this.elements.sql_results.forEach(function (result_card: UnknownObject) {
        // eslint-disable-next-line
        result_card.style.display = 'none';
      });
    } else {
      // merge all results
      const sql = results.join(' ');

      // display and handle results
      this.display_results(sql);
      this.handle_results(sql);
    }

    return this;
  },

  async call_sqline_api(yaml_input: UnknownObject) {
    // show loading message
    this.elements.conversion_status.innerText = 'Converting...';

    let results;
    const config = {
      yaml: yaml_input.value,
    };

    try {
      // fetch conversion results from API
      const response = await axios.post(`${BONGO_URL}/v1/sqline`, config);
      results = response.data.sql;
    } catch (e) {
      // handle API calls fails
      this.elements.conversion_status.innerText = 'Conversion failed';
      this.elements.conversion_status.classList.add('error');
    }

    return results;
  },

  display_results(sql: UnknownObject) {
    // reset previous state
    this.elements.sql_results.forEach(function (result_card: UnknownObject) {
      // eslint-disable-next-line
      result_card.style.display = 'none';
    });
    this.elements.yaml_input.value = '';
    this.elements.conversion_status.classList.remove('error');

    // display result in card
    const sql_result = document.createElement('div');

    // highlight SQL syntax
    const highlighted_syntax = this.highlight_syntax(sql);

    sql_result.innerHTML = `
            <button class="copy">Copy</button>
            <pre>
                <code class="sql">${highlighted_syntax}</code>
            </pre>
            <a href="#" class="expand">See all</a>
        `;
    sql_result.classList.add('sql_result', 'collapsed');
    this.elements.sql_results_wrapper.appendChild(sql_result);
    this.elements.sql_result = sql_result;
  },

  highlight_syntax(sql: UnknownObject) {
    // only highlight for small pieces of code
    if (sql.length < 40000) {
      const syntax = new Syntax({
        language: 'sql',
        cssPrefix: '',
      });
      syntax.richtext(sql);
      return syntax.html();
    }
    return sql;
  },

  handle_results(sql: UnknownObject) {
    // save newly created elements
    this.elements.expand_button = this.elements.sql_result.querySelector(
      '.expand'
    );
    this.elements.copy_button = this.elements.sql_result.querySelector(
      'button.copy'
    );

    // expand card on click
    this.elements.expand_button.addEventListener('click', () => {
      this.elements.sql_result.classList.remove('collapsed');
    });

    // copy to clipboard on click
    this.elements.copy_button.addEventListener('click', () => {
      this.copy_to_clipboard(sql);
    });

    // remove loading message
    // eslint-disable-next-line
    conversion_status.innerText = 'SQL result';
  },

  copy_to_clipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // display success message
        const toast = document.createElement('div');
        toast.classList.add('toast');
        toast.innerText = 'Copied to clipboard';
        this.elements.container.appendChild(toast);
      })
      .catch(() => {
        // display error message
        const toast = document.createElement('div');
        toast.classList.add('toast', 'error');
        toast.innerText = 'Copying failed';
        this.elements.container.appendChild(toast);
      });
  },

  render() {
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
  },
});
