import axios from 'axios';

export default function (tenant: number, integration: string) {
  return axios
    .get(`${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/limit`)
    .then((res) => res && res.data)
    .catch((err: any) => {
      const error = err.response && err.response.data;
      throw new Error(error.message);
    });
}
