var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (RECAPTCHA_SITE_KEY) {
      pug_html = pug_html + "\u003Cdiv class=\"onboarding-container\"\u003E\u003Cdiv class=\"div\"\u003E\u003Cdiv class=\"text-center\"\u003E\u003Cimg src=\"\u002Fmedia\u002Fimages\u002Fmadkudu_horns_only_blue.svg\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"justify-content-center d-flex align-items-center\"\u003E\u003Cdiv class=\"col-md-4 col-md-offset-4\"\u003E\u003Cdiv class=\"panel panel-login p-5 m-1 box-shadow-3d rounded text-white\"\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cdiv class=\"alert alert-danger col-sm-12\" id=\"login-alert\" style=\"display: none;\"\u003E\u003C\u002Fdiv\u003E\u003Cform class=\"form-horizontal\" id=\"resetform\" role=\"form\"\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput class=\"form-control\" type=\"text\" name=\"email\" placeholder=\"Email\" autocomplete=\"off\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cbutton class=\"btn btn-primary btn-block\" id=\"mki_recover_password\" type=\"submit\"\u003ESend Reset Instructions\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003Cscript" + (pug.attr("src", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`, true, true)+" async=\"\" defer=\"\"") + "\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "RECAPTCHA_SITE_KEY" in locals_for_with ?
        locals_for_with.RECAPTCHA_SITE_KEY :
        typeof RECAPTCHA_SITE_KEY !== 'undefined' ? RECAPTCHA_SITE_KEY : undefined));
    ;;return pug_html;};
module.exports = template;