import React from 'react';

export default class Step extends React.Component<any> {
  render() {
    const {
      isConfigured,
      accordionId,
      collapseId,
      headingId,
      hrefDataAccordionId,
      hrefDataCollapseId,
      pageUrl,
    } = this.props;
    return (
      <div className="row justify-content-center">
        <div className="col-10 mt-2">
          <div
            className="accordion md-accordion"
            id={accordionId}
            role="tablist"
            aria-multiselectable="true"
          >
            <div
              className="card box-shadow-3d"
              style={{ borderColor: isConfigured ? '#4DCCBD' : 'default' }}
            >
              <div
                className="card-header align-items-center"
                role="tab"
                id={headingId}
              >
                <a
                  data-toggle="collapse"
                  data-parent={hrefDataAccordionId}
                  href={hrefDataCollapseId}
                  aria-expanded="true"
                  aria-controls={collapseId}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="row align-items-center">
                    <div className="col align-items-center">
                      <h5
                        className="align-items-center mb-0"
                        style={{ color: isConfigured ? '#4DCCBD' : 'black' }}
                      >
                        Test your Fastlane
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div
                id={collapseId}
                className="collapse show"
                role="tabpanel"
                aria-labelledby={headingId}
                data-parent={hrefDataAccordionId}
                style={{ textDecoration: 'none' }}
              >
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col">
                      <h6>
                        To test your fastlane, please view your fastlane with
                        the button below and try out a qualified email in your
                        form. (eg. elon@tesla.com)
                      </h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <a
                        href={pageUrl}
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        className="btn btn-success"
                      >
                        Your FastLane Page
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
