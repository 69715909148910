import moment from 'moment';
import numeral from 'numeral';
import { capitalizeEachWord, COLORS } from '../predictions/utils';
import { BootstrapTableColumn } from '../types';
import launchAirflowDag from '../utils/launch_airflow_dag';

const BUBBLE_SIZE_MIN: number = 1;

export function getDatesFormat(rawData: UnknownObject[]) {
  if (rawData[0]['Created date']) return 'Created date';
  if (rawData[0]['event timestamp']) return 'event timestamp';
  if (rawData[0].Date) return 'Date';
}

export function dateFormatter(value: string) {
  return moment(value).format('MMM YYYY');
}

export function createDefaultDates(rawData: UnknownObject[]) {
  const timeLabel = getDatesFormat(rawData);
  return [
    ...new Set(rawData.map((row) => moment(row[timeLabel]).format('MMM YYYY'))),
  ];
}

export function extractTypes(rawData: UnknownObject[], typeLabel: string) {
  return [...new Set(rawData.map((row) => row[typeLabel]))];
}

export function getdataType(
  rawData: UnknownObject[],
  typeLabel: string,
  type: string,
  countField: string
) {
  return rawData
    .filter((row) => row[typeLabel] === type)
    .map((row) => row[countField]);
}

export function createDefaultDataset(
  rawData: UnknownObject[],
  typeLabel: string,
  countField: string
) {
  const types = extractTypes(rawData, typeLabel);

  return types.map((type, index) => {
    return {
      label: type,
      data: getdataType(rawData, typeLabel, type, countField),
      backgroundColor: COLORS[index],
    };
  });
}

export function createDataAndPointRadius(data: UnknownObject[], label: string) {
  return data.map((row: any) => {
    const value: number =
      row[label] >= BUBBLE_SIZE_MIN
        ? row[label].toFixed(2) / 2
        : BUBBLE_SIZE_MIN;
    return value;
  });
}

export function checkHasNumberFormatter(value: any, key: string): boolean {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(Number(value)) && !key.toLowerCase().includes('conversion');
}

export function checkHasDateFormatter(key: string): boolean {
  return key.toLowerCase() === 'created_date' || key.toLowerCase() === 'date';
}

export function getFormatterOfAField(
  value: any,
  key: string,
  formatterFormat: string
): Function {
  const formatter = (_value: string) => numeral(_value).format(formatterFormat);
  const hasDateFormatter: boolean = checkHasDateFormatter(key);
  const hasNumberFormatter: boolean = checkHasNumberFormatter(value, key);

  if (hasDateFormatter) return dateFormatter;
  if (hasNumberFormatter) return formatter;
}

/**
 * @deprecated This function should be no longer used. It is not stable.
 * Columns should be manually defined for each table.
 */
export function getBootstrapColumnsFromData(
  data: UnknownObject[],
  formatterOptions = { formatterFormat: '0,0' }
): BootstrapTableColumn[] {
  const columns: BootstrapTableColumn[] = [];
  const firstElementOfData = data[0];
  if (firstElementOfData) {
    Object.keys(firstElementOfData).forEach((key) => {
      const formatedKey: string = capitalizeEachWord(key.replace(/_/g, ' '));

      const column: BootstrapTableColumn = {
        field: key,
        title: formatedKey,
        sortable: true,
      };
      columns.push({
        ...column,
        formatter: getFormatterOfAField(
          firstElementOfData[key],
          key,
          formatterOptions.formatterFormat
        ),
      });
    });
  }

  return columns;
}

export async function refreshDataDiscovery(
  tenant: number,
  email: string,
  connector: string
) {
  await launchAirflowDag({
    tenant,
    sectionName: `data discovery ${connector}`,
    dagName: `data_discovery_${tenant}`,
  });
  window.analytics.track(`Data discovery ${connector} refresh button clicked`, {
    tenant,
    email,
  });
}
