import axios from 'axios';
import React from 'react';

type DataSharingComponentProps = {
  tenant: number;
};

type DataSharingComponentState = {
  loading: boolean;
  enabled: boolean;
};

export default class DataSharingComponent extends React.Component<
  DataSharingComponentProps,
  DataSharingComponentState
> {
  constructor(props: DataSharingComponentProps) {
    super(props);

    this.state = {
      loading: true,
      enabled: true,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { data: settings } = await axios.get(
      `${BONGO_URL}/v1/org/${this.props.tenant}`
    );
    this.setState({ enabled: settings.data_sharing_enabled, loading: false });
  }

  updateStatus = async (newStatus: boolean) => {
    this.setState({ enabled: newStatus });
    const { data } = await axios.put(
      `${BONGO_URL}/v1/org/${this.props.tenant}`,
      {
        data_sharing_enabled: newStatus,
      }
    );
    return data;
  };

  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-8 mt-4 mb-4">
          <div className="card box-shadow-3d mt-md-2 d-flex w-full">
            <div className="card-header">
              <div className="d-flex">
                <h5 className="mb-0">Data sharing</h5>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-3">Loading...</div>
            ) : (
              <div className="card-body">
                <div className="mb-4 d-flex align-items-center w-full">
                  <span className="mr-2">
                    <b>Status:</b>
                  </span>
                  <div className="d-inline-block border rounded overflow-hidden">
                    <span
                      onClick={() => this.updateStatus(true)}
                      className={`${
                        this.state.enabled ? 'bg-success text-white' : ''
                      } cursor-pointer d-inline-flex align-items-center py-2 px-3`}
                    >
                      Enabled
                    </span>
                    <span
                      onClick={() => this.updateStatus(false)}
                      className={`${
                        !this.state.enabled ? 'bg-success text-white' : ''
                      } cursor-pointer d-inline-flex align-items-center py-2 px-3`}
                    >
                      Disabled
                    </span>
                  </div>
                  <span className="text-secondary ml-2">
                    You are opted {this.state.enabled ? 'in' : 'out'}
                  </span>
                </div>

                <p className="mt-4">
                  <strong>
                    Sharing you data with Madkudu will help us improve identify
                    more visitors
                  </strong>
                </p>
                <p className="mt-2 text-secondary">
                  If set to "Enabled", MadKudu collects IP addresses and domain
                  of email addresses from a connected integration to improve
                  MadKudu's database of IP addresses matched to companies. It is
                  used to enhance MadKudu's de-anonymization rate. By enabling
                  the visitor data collection, you agree to share with MadKudu
                  data about your website visitors for the purpose of MadKudu
                  Account Identification{' '}
                  <a
                    href="https://www.madkudu.com/privacy-center/data-processing-addendum"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Controller Terms
                  </a>
                  .
                </p>
                <p className="text-secondary">
                  {' '}
                  To opt out of sharing your visitors data with MadKudu for the
                  purpose of MadKudu Account Identification, please reach out to{' '}
                  <a href="mailto:privacy@madkudu.com" className="text-primary">
                    privacy@madkudu.com
                  </a>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
