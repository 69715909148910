import Backbone from 'backbone';
import Swal from 'sweetalert2';
import { Flag_model } from '../models/flag';

const $ = require('jquery');
require('bootstrap-toggle');

const template = require('./flag.pug');

const Item_view = (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_cancel': 'cancel',
    'click #btn_save': 'save',
  },

  initialize() {
    this.render();
  },

  render() {
    const html = $('#tpl_flag', template()).html();
    this.$el.html(html);
    this.applyBindings();
    $('.mki_toggle', this.$el).bootstrapToggle();
    return this;
  },

  cancel(event: any) {
    event.preventDefault();
    window.history.back();
  },

  save(event: any) {
    event.preventDefault();
    this.model.save(null, {
      success() {
        Swal.fire('Done!', 'Config updated successfully', 'success');
      },
      error() {
        Swal.fire('Oops!', 'There was an error saving your config', 'error');
      },
    });
  },
});

export default Backbone.View.extend({
  initialize(options: { tenant: number; name: string }) {
    this.tenant = options.tenant;
    this.name = options.name;
    this.model = new Flag_model({
      tenant: this.tenant,
      name: this.name,
    });
    this.flag_view = new Item_view({ model: this.model });
    this.model.fetch({ success: () => this.render() });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(this.flag_view, '#mki_flag');
    return this;
  },
});
