import { Literal, Static, Union } from 'runtypes';
import ActiveIntegrationDataModel from '../mapping/event_mapping/models/ActiveIntegrationDataModel';
import OverridesConditionDataModel from '../models/conditions/OverridesConditionDataModel';

export type TenantType = {
  _id: number;
  name: string;
};

export type UserType = {
  _id: number;
  email: string;
  user_hash: string;
};

export type FeaturesType = 'insights' | 'mappings' | 'push';

export type MappingConversionsTypes = 'SQO' | 'Closed Won' | 'Open Opp';

// Used for sort
export const ModelTypesPoints = {
  // Fit models
  customer_fit: 4,
  fit: 4,
  // LTB models
  likelihood: 3,
  behavioral: 3,
  pql: 3,
  pql2: 3,
  mql: 3,
  // Grade models
  lead_grade: 2,
  grade: 2,
  // MQA
  mqa: 1,
  // Others
  topical: 0,
};

export type ModelTypes =
  | 'customer_fit'
  | 'behavioral'
  | 'lead_grade'
  | 'topical'
  | 'fit'
  | 'grade'
  | 'likelihood'
  | 'pql'
  | 'pql2'
  | 'mql'
  | 'mqa';

export type BackboneViewProps = {
  tenant?: number;
  name?: string;
  error_code?: string;
  model?: any;
  integrations?: ActiveIntegrationDataModel;
  pageName?: string;
};

export type Should = 'should at most be' | 'should at least be' | 'should be';

export const Segment = {
  customer_fit: Union(
    Literal('low'),
    Literal('medium'),
    Literal('good'),
    Literal('very good')
  ),
  mqa: Union(
    Literal('low'),
    Literal('medium'),
    Literal('high'),
    Literal('very high')
  ),
};

export type FormDataType = {
  s3_role_arn: string;
  s3_bucket_region: string;
  s3_folder_path: string[];
  external_id: string;
};

export type SegmentCustomerFit = Static<typeof Segment.customer_fit>;

export type SegmentMqa = Static<typeof Segment.mqa>;

export type Object = 'Lead' | 'Contact' | 'Opportunity' | 'Account';

export type PerformanceData = {
  [key in string]: any;
};

export type AudiencePerformanceData = {
  [key in string]: PerformanceData;
};

export type ModelBase = 'points' | 'trees';

export type DatasetDataModel = {
  overrides: OverridesConditionDataModel[];
};
export type AudienceNameTitleModel = {
  audience: string;
};

export type BootstrapTableColumn = {
  field: string;
  title: string;
  sortable: boolean;
  formatter?: Function;
};

export type BootstrapTableData = {
  data: any;
  pagination: boolean;
  search: boolean;
  columns: BootstrapTableColumn[];
};

export type UserRoles = 'user' | 'admin' | 'architect';

export type ClassicConnectorsNames = 'salesforce' | 'hubspot' | 'madMl';

export type AttributeMappingConnectorsNames =
  | ClassicConnectorsNames
  | 's3'
  | 'marketo'
  | 'bigquery'
  | 'snowflake';

export type AudienceMappingConnectorsNames =
  | ClassicConnectorsNames
  | 'marketo'
  | 'stripe';

export type ConversionMappingConnectorsNames =
  | ClassicConnectorsNames
  | 'analytics'
  | 'stripe';

export type EventMappingConnectorsNames =
  | ClassicConnectorsNames
  | 'segment'
  | 'amplitude'
  | 'kissmetrics'
  | 'mixpanel'
  | 'marketo'
  | 'salesforce_campaigns'
  | 'salesforce_tasks';

export type ConnectorsNames =
  | EventMappingConnectorsNames
  | ConversionMappingConnectorsNames;

export const ConversionTypesOfConnector: {
  [key in ConversionMappingConnectorsNames]: MappingConversionsTypes[];
} = {
  analytics: ['Closed Won'],
  hubspot: ['SQO', 'Closed Won', 'Open Opp'],
  salesforce: ['SQO', 'Closed Won', 'Open Opp'],
  stripe: ['SQO'],
  madMl: [],
};

export const ConnectorsOfConversionTypes: {
  [key in MappingConversionsTypes]: ConversionMappingConnectorsNames[];
} = {
  'Closed Won': ['analytics', 'hubspot', 'salesforce'],
  SQO: ['stripe', 'salesforce', 'hubspot'],
  'Open Opp': ['hubspot', 'salesforce'],
};

// A basic format email check, accept all characters, only check for any(not @)@any(not @).any(not @)
export const SIMPLE_EMAIL_CHECK_REGEX = /^[^@]+@[^@]+(\.)[^@]+$/;
