import axios from 'axios';
import React, { ReactNode, createContext, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnNames } from '../../utils/data-warehouse';

type TableMapping = {
  event: string;
  contact_update: string;
  account_update: string;
};

export type Credentials = {
  account: string;
  username: string;
  pwd: string;
  database: string;
  schema: string;
  tableNames: TableMapping;
  columnNames: ColumnNames;
};

type SnowflakeContextType = {
  tenant: number;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  getCredentials: () => Promise<Credentials>;
  credentials: Credentials;
  setCredentials: (credentials: Credentials) => void;
  testCredentials: () => Promise<boolean>;
  getTables: () => Promise<string[]>;
  testTableMapping: () => Promise<boolean>;
  getColumns: () => Promise<Record<string, string[]>>;
  save: () => Promise<boolean>;
  clearCredentials: () => void;
};

export const SnowflakeContext = createContext<SnowflakeContextType>(null);

export function SnowflakeProvider({
  tenant,
  children,
}: {
  tenant: number;
  children: ReactNode;
}) {
  const queryClient = useQueryClient();
  const [credentials, setCredentials] = React.useState<Credentials>(null);
  const [editMode, setEditMode] = React.useState(false);

  async function getCredentials(): Promise<Credentials> {
    try {
      const response = await axios.get<Credentials>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/snowflake/credentials`
      );

      if (Object.keys(response.data).length === 0) return null;

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response.status === 404) {
        return null;
      }

      throw error;
    }
  }

  async function getTables(): Promise<string[]> {
    const response = await axios.post<string[]>(
      `${BONGO_URL}/v1/org/${tenant}/integrations/snowflake/get_tables`,
      credentials
    );

    return response.data;
  }

  async function getColumns(): Promise<Record<string, string[]>> {
    const response = await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/integrations/snowflake/get_columns`,
      credentials
    );

    return response.data;
  }

  async function testCredentials(): Promise<boolean> {
    try {
      const response = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/snowflake/test_credentials`,
        credentials
      );

      return response?.data?.tested ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function testTableMapping(): Promise<boolean> {
    try {
      const response = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/snowflake/test_table_mapping`,
        credentials
      );

      return response?.data?.tested ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function save(): Promise<boolean> {
    try {
      const response = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/snowflake/credentials`,
        credentials,
        { params: { activate_pull: true } }
      );

      queryClient.setQueryData(
        ['snowflake', 'credentials', tenant],
        response.data
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  function clearCredentials() {
    queryClient.invalidateQueries(['snowflake', 'credentials', tenant]);
  }

  return (
    <SnowflakeContext.Provider
      value={{
        tenant,
        editMode,
        setEditMode,
        getCredentials,
        credentials,
        setCredentials,
        testCredentials,
        getTables,
        testTableMapping,
        getColumns,
        save,
        clearCredentials,
      }}
    >
      {children}
    </SnowflakeContext.Provider>
  );
}

export function useSnowflakeContext() {
  const snowflakeContext = useContext(SnowflakeContext);

  if (!snowflakeContext)
    throw new Error(
      'useSnowflakeContext was used outside SnowflakeContext provider'
    );

  return snowflakeContext;
}
