import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';

export type AuthenticationData = {
  sso: {
    enabled: boolean;
    type: 'okta' | 'google' | 'disabled';
    metas: {
      entryPoint: string;
      issuer: string;
      cert: string;
    };
    updatedAt: number;
  };
  domain_auto_signup_enabled: boolean;
};

const DEFAULT_AUTHENTICATION_DATA_VALUE: AuthenticationData = {
  sso: {
    enabled: false,
    type: 'disabled',
    metas: {
      entryPoint: '',
      cert: '',
      issuer: '',
    },
    updatedAt: Date.now(),
  },
  domain_auto_signup_enabled: false,
};

export class AuthenticationManager {
  tenant: number;

  data: AuthenticationData;

  constructor(tenant: number) {
    this.tenant = tenant;

    this.data = cloneDeep(DEFAULT_AUTHENTICATION_DATA_VALUE);
  }

  async init() {
    await this.get();
  }

  async get() {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${this.tenant}/authentication`
    );
    this.data = {
      sso: {
        enabled: data.sso?.enabled || false,
        type: data.sso?.type || 'disabled',
        metas: {
          entryPoint: data.sso?.metas?.entryPoint || '',
          cert: data.sso?.metas?.cert || '',
          issuer: data.sso?.metas?.issuer || '',
        },
        updatedAt: data.updatedAt || Date.now(),
      },
      domain_auto_signup_enabled: data.domain_auto_signup_enabled || false,
    };
  }

  setSSOType(
    ssoTypeValue: 'okta' | 'google' | 'disabled'
  ): AuthenticationManager {
    if (ssoTypeValue === 'disabled') {
      this.data.sso.enabled = false;
      this.data.sso.type = 'disabled';
    } else {
      this.data.sso.type = ssoTypeValue;
      this.data.sso.enabled = true;
    }
    return this;
  }

  setDomainAutoSignupEnabled(
    domainAutoSignupEnabled: boolean
  ): AuthenticationManager {
    this.data.domain_auto_signup_enabled = domainAutoSignupEnabled;
    return this;
  }

  setEntryPoint(newEntryPoint: string): AuthenticationManager {
    this.data.sso.metas.entryPoint = newEntryPoint;
    this.data.sso.updatedAt = Date.now();
    return this;
  }

  setIssuer(newIssuer: string): AuthenticationManager {
    this.data.sso.metas.issuer = newIssuer;
    this.data.sso.updatedAt = Date.now();
    return this;
  }

  setCert(newCert: string): AuthenticationManager {
    this.data.sso.metas.cert = newCert;
    this.data.sso.updatedAt = Date.now();
    return this;
  }

  async save(): Promise<AuthenticationManager> {
    await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      text: 'This will override your authentication configuration.',
      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonText: 'Yes, override it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return axios
          .post(`${BONGO_URL}/v1/org/${this.tenant}/authentication`, this.data)
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: `Oops... something went wrong!\n${error}`,
              title: 'Error',
            });
          });
      },
    }).then((result: any) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your authentication configuration has been submitted!',
        });
      }
    });
    return this;
  }

  async clear(): Promise<AuthenticationManager> {
    await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      text: 'This will clear your authentication configuration.',
      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonText: 'Yes, clear it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return axios
          .post(
            `${BONGO_URL}/v1/org/${this.tenant}/authentication`,
            cloneDeep(DEFAULT_AUTHENTICATION_DATA_VALUE)
          )
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: `Oops... something went wrong!\n${error}`,
              title: 'Error',
            });
          });
      },
    }).then((result: any) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your authentication configuration has been cleared!',
        });
      }
    });
    this.data = cloneDeep(DEFAULT_AUTHENTICATION_DATA_VALUE);
    return this;
  }
}
