import axios from 'axios';

export async function isIntegrationPushActive(
  tenantId: number,
  integration: string
): Promise<boolean> {
  try {
    const {
      data: { push },
    } = await axios.get(
      `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}`
    );
    return push && push.active;
  } catch (_error) {
    return false;
  }
}

export async function isIntegrationPullActive(
  tenantId: number,
  integration: string
): Promise<boolean> {
  try {
    const {
      data: { pull },
    } = await axios.get(
      `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}`
    );
    return pull && pull.active;
  } catch (_error) {
    return false;
  }
}
