const TRUSTED_DOMAINS = ['madkudu.com', 'wisekudu.com', 'localhost'];

/**
 * Mitigates open redirect security issues by making sure
 * the provided URL is same top-level origin.
 */
export function isSafeRedirect(path: string): boolean {
  try {
    const url = new URL(path, window.location.href);
    return TRUSTED_DOMAINS.some(
      (domain) => url.hostname === domain || url.hostname.endsWith(`.${domain}`)
    );
  } catch (_) {
    return false;
  }
}
