import React from 'react';
import CsvUpload from '../../../components/gdpr/CsvUpload';
import app from '../../../app';

type DataPrivacyComponentProps = {
  tenant: number;
};

export default class DataPrivacyComponent extends React.Component<
  DataPrivacyComponentProps
> {
  user = app.session.get('user');

  email = this.user.email;

  render() {
    return <CsvUpload tenant={this.props.tenant} email={this.email} />;
  }
}
