import axios from 'axios';
import Swal from 'sweetalert2';

type launchAirflowDagOptions = {
  tenant: number;
  dagName: string;
  sectionName: string;
  dagConf?: Record<string, any>;
};

export default async function launchAirflowDag({
  tenant,
  dagName,
  sectionName,
  dagConf,
}: launchAirflowDagOptions) {
  try {
    Swal.fire({
      title: 'Asking our kudus to fetch fresh data...',
      icon: 'info',
      allowOutsideClick: () => !Swal.isLoading(),
    });
    Swal.showLoading();
    await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/airflowdag/${dagName}`,
      dagConf ?? {}
    );
    await Swal.fire({
      icon: 'success',
      title: 'Request sent!',
      text: `Get some coffee and come back in a few minutes while the report ${sectionName} is refreshing!`,
    });
  } catch (err) {
    await Swal.fire({
      icon: 'error',
      title: 'Something went wrong!',
      text: `The process to refresh ${sectionName} failed to start due to: ${err}`,
    });
  }
}
