import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../../app';
import { BackboneViewProps } from '../../../types';
import { isUserReadOnly } from '../../../utils';
import MappingsMode from '../../enums/MappingsMode';
import { getScriptCheckerStateBasedOnMappingMode } from '../../utils';
import AudienceMappingManager from './models/AudienceMappingManager';
import AudienceParentComponent from './AudienceParentComponent';

const template = require('./index.pug');

type AudienceViewProps = {
  audience: string;
};

export default Backbone.View.extend({
  events: {
    'click #go_back': 'goBack',
    'click #go_back_mappings': 'goBackMappings',
    'click #delete-button': 'deleteAudience',
    'click #edit-button': 'editForm',
    'click #publish-button': 'publish',
    'click #goMadMlEdit': 'goMadMlEdit',
    'click #goStandardMode': 'goStandardMode',
  },
  async initialize(options: BackboneViewProps & AudienceViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.isReadOnly = isUserReadOnly(user);
    this.superKudu = user.superKudu;

    this.audienceMappingManager = new AudienceMappingManager(
      this.tenant,
      user.email,
      options.audience,
      this.superKudu
    );

    await this.audienceMappingManager.init();

    const {
      data: scriptCheckerData,
    } = await getScriptCheckerStateBasedOnMappingMode(
      this.tenant,
      MappingsMode.audience
    );
    this.scriptCheckerRunning = !!scriptCheckerData;

    this.render();

    setTimeout(() => this.renderAudienceParentComponent(), 0);
  },

  renderTitleForm() {},

  renderAudienceParentComponent() {
    ReactDOM.render(
      <AudienceParentComponent
        audienceMappingManager={this.audienceMappingManager}
        scriptCheckerRunning={this.scriptCheckerRunning}
        isReadOnly={this.isReadOnly}
        superKudu={this.superKudu}
      />,
      $('#AudienceParentComponentContainer')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);
    return this;
  },
});
