import $ from 'jquery';
import app from './app';
import Router from './router';
import AdminRouter from './router_admin';
import AuthRouter from './router_auth';
import ErrorRouter from './router_errors';
import PublicRouter from './router_public';

require('bootstrap');
require('./analytics');
require('./tailwind.config');

$(document).ready(() => {
  app.on('before:start', () => {
    app.router_errors = new ErrorRouter();
    app.router_auth = new AuthRouter();
    app.router_public_pages = new PublicRouter();
    app.router = new Router();
    app.router_admin = new AdminRouter();
  });

  app.session
    .check_auth()
    .catch(console.error)
    .then(() => app.start());
});
