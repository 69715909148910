import * as d3Shape from 'd3-shape';

import {
  D3BasicSelection,
  GaugeConfigWithRanges,
  Segment,
  SegmentsConfig,
  TooltipsConfig,
} from '../types';
import { getMiddleOfRange, getTextSize, transformValueToAngle } from '../utils';

const slugy = (value: string) => value.replaceAll(' ', '_').toLowerCase();

export default function drawSegments(
  svg: D3BasicSelection,
  segments: Segment[],
  gaugeConfig: GaugeConfigWithRanges,
  segmentsConfig: SegmentsConfig,
  tooltipsConfig: TooltipsConfig
) {
  const radius = gaugeConfig.diameter / 2;
  const padRadius = 180;
  const padAngle = segmentsConfig.gap / padRadius;

  const segmentsSvg = svg.append('g').attr('width', gaugeConfig.diameter);
  const tooltipsSvg = svg.append('g');

  const segmentGenerator = d3Shape
    .arc<Segment>()
    .padAngle(padAngle)
    .innerRadius(radius - segmentsConfig.width)
    .outerRadius(radius)
    .startAngle(({ range }) =>
      transformValueToAngle(
        range[0],
        gaugeConfig.valuesRange,
        gaugeConfig.anglesRange,
        true
      )
    )
    .endAngle(({ range }) =>
      transformValueToAngle(
        range[1],
        gaugeConfig.valuesRange,
        gaugeConfig.anglesRange,
        true
      )
    )
    .cornerRadius(segmentsConfig.cornerRadius);

  // draw segments
  segmentsSvg
    .selectAll('segment')
    .data<Segment>(segments)
    .enter()
    .append('path')
    .attr('class', 'segment')
    .attr('id', ({ label }) => `segment_${slugy(label)}`)
    .attr('d', segmentGenerator)
    .attr('fill', ({ color }) => color)
    .on('mouseenter', (_e, d) => {
      tooltipsSvg
        .selectAll(`#segment-tooltip-${slugy(d.label)}`)
        .attr('opacity', 1);

      const otherSegments = segments.filter(({ label }) => label !== d.label);

      otherSegments.forEach(({ label }) =>
        segmentsSvg.select(`#segment_${slugy(label)}`).attr('opacity', 0.5)
      );
    })
    .on('mouseleave', (_e, d) => {
      tooltipsSvg
        .selectAll(`#segment-tooltip-${slugy(d.label)}`)
        .attr('opacity', 0);

      const otherSegments = segments.filter(({ label }) => label !== d.label);

      otherSegments.forEach(({ label }) =>
        segmentsSvg.select(`#segment_${slugy(label)}`).attr('opacity', 1)
      );
    });

  // draw segments tooltips
  segments.forEach(({ label, range }) => {
    const middleRangeValue = getMiddleOfRange(range);

    const angle = transformValueToAngle(
      middleRangeValue,
      gaugeConfig.valuesRange,
      gaugeConfig.anglesRange
    );
    const middleAngle = transformValueToAngle(
      50,
      gaugeConfig.valuesRange,
      gaugeConfig.anglesRange
    );

    const cursorRotate = middleAngle - angle;

    const yShift = radius + tooltipsConfig.margin;

    const { height: textHeight, width: textWidth } = getTextSize(
      svg,
      label,
      tooltipsConfig.fontSize ?? 16
    );

    const bgWidth = textWidth + tooltipsConfig.paddingX * 2;
    const bgHeight = textHeight + tooltipsConfig.paddingY * 2;

    const tooltipSvg = tooltipsSvg
      .append('g')
      .attr('width', 10)
      .attr('height', radius)
      .attr('transform', `rotate(${angle})`)
      .attr('id', `segment-tooltip-${slugy(label)}`)
      .attr('opacity', 0)
      .append('g')
      .attr('transform', `translate(0, -${yShift}) rotate(${cursorRotate})`);

    tooltipSvg
      .append('rect')
      .attr('width', bgWidth)
      .attr('height', bgHeight)
      .attr('rx', tooltipsConfig.borderRadius)
      .attr('ry', tooltipsConfig.borderRadius)
      .attr('fill', tooltipsConfig.bgColor)
      .attr(
        'transform',
        `translate(-${
          middleRangeValue < 50 ? bgWidth / 1.15 : tooltipsConfig.paddingX
        }, -${bgHeight / 1.9})`
      );

    tooltipSvg
      .append('text')
      .text(label)
      .attr('fill', tooltipsConfig.color)
      .attr(
        'text-anchor',
        middleRangeValue < 50
          ? 'end'
          : middleRangeValue > 50
          ? 'start'
          : 'middle'
      )
      .attr('alignment-baseline', 'middle');

    if (tooltipsConfig.fontSize)
      tooltipSvg.attr('font-size', tooltipsConfig.fontSize);
  });
}
