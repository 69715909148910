import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import Swal from 'sweetalert2';
import app from '../app';
import { Plan_collection } from '../models/plan';
import { BackboneViewProps } from '../types';
import Billing_model from './model_tenant_billing';

const template = require('./billing_admin.pug');
const template_403 = require('../errors/403.pug');

const Plans_view = (Backbone as any).Epoxy.View.extend({
  events: { 'click #btn_save': 'update_plan' },

  initialize() {
    this.listenTo(this.collection, 'update', this.render);
  },

  render() {
    const html = $('#tpl_plan', template()).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  update_plan(e: any) {
    e.preventDefault();
    axios
      .post(`${BONGO_URL}/v1/org/${this.model.attributes.tenant}/plan`, {
        plan: this.model.attributes.plan,
      })
      .then(() => {
        app.notifications.success('Settings updated', {
          dismiss_timeout: 2000,
        });
      })
      .catch((err: any) => {
        const { data } = err.response;
        const { message } = data;
        return Swal.fire(
          "There was an error saving your information. We're looking into it!",
          message,
          'error'
        );
      });
  },
});

const Billing_view = Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.model = new Billing_model({ tenant: this.tenant });
    this.collection = new Plan_collection();
    this.plan_view = new Plans_view({
      collection: this.collection,
      model: this.model,
    });
    this.model.fetch({
      success: () => this.render(),
      error: () => this.render_error(),
    });
  },

  render() {
    const html = $('#tpl_billing', template()).html();
    this.$el.html(html);
    this.plan_view.setElement(this.$('#plan'));
    this.collection.fetch();
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },
});

export default Backbone.View.extend({
  initialize() {},

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(
      new Billing_view({ tenant: this.model.get('_id') }),
      '#billing'
    );
    return this;
  },
});
