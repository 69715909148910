import React from 'react';
import { ContainerWhite } from '../../components/ui';
import { PersonResult, PredictResult } from '../types';

type Props = {
  personResult?: PersonResult;
  predictResult?: PredictResult;
  isLoading: boolean;
};

export default function CompanyCard({
  predictResult,
  personResult,
  isLoading,
}: Props) {
  return (
    <ContainerWhite
      title="Company"
      className="tw-h-[257px]"
      isLoading={isLoading}
    >
      {!isLoading && personResult && predictResult && (
        <>
          {personResult.company.properties.name && (
            <>
              <div className="tw-flex tw-gap-5">
                <span className="tw-flex tw-items-center tw-justify-center tw-h-20 tw-w-20 tw-shrink-0 tw-grow-0 tw-rounded-md tw-bg-gray-50 tw-border tw-border-gray-200 tw-overflow-hidden">
                  <img
                    src={predictResult.company__logo}
                    style={{
                      width: '56px',
                    }}
                  />
                </span>
                <div>
                  <div className="tw-text-lg tw-font-semibold">
                    {personResult.company.properties.name || '-'}
                  </div>
                  <div className="tw-text-sm">
                    {predictResult.company__geo__city
                      ? `${predictResult.company__geo__city}, ${predictResult.company__geo__country}`
                      : '-'}
                  </div>
                  <div className="tw-text-sm tw-text-gray-500">
                    {personResult.company.properties.industry || '-'}
                  </div>
                </div>
              </div>
              <div className="tw-mt-4 tw-text-sm tw-leading-relaxed">
                {predictResult.company__description || '-'}
              </div>
            </>
          )}
          {!personResult.company.properties.name && (
            <div className="tw-flex tw-items-center tw-justify-center tw-text-center">
              <div className="tw-text-gray-200">
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 21 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.50851 1.73463H14.4907V13.2159H6.50851V1.73463ZM0 15H21V13.8039H0V15ZM13.2028 1.14659V0H7.61577V1.14564L13.2028 1.14659ZM1.64529 3.98397H5.915V13.2157H1.64529V3.98397ZM3.01447 6.37518H4.54379V4.85747H3.01447V6.37518ZM3.01447 9.3497H4.54379V7.83199H3.01447V9.3497ZM3.01447 12.3242H4.54379V10.8037H3.01447V12.3242ZM15.0831 6.7681H19.3545V13.2158H15.0831V6.7681ZM16.4522 9.30878H17.9844V7.79107H16.4532V9.30878H16.4522ZM16.4522 12.4307H17.9844L17.9854 10.912H16.4532V12.4307H16.4522ZM11.3072 10.7978V12.3165H12.8375V10.7978H11.3072ZM8.16147 10.7978V12.3165H9.69078L9.69174 10.7978H8.16147ZM11.3072 8.20771V9.72637H12.8375V8.20866L11.3072 8.20771ZM8.16147 8.20771V9.72637H9.69078L9.69174 8.20866L8.16147 8.20771ZM11.3072 5.61758V7.13529H12.8375V5.61758H11.3072ZM8.16147 5.61758V7.13529H9.69078V5.61758H8.16147ZM11.3072 3.02745V4.54516H12.8375V3.02745H11.3072ZM8.16147 3.02745V4.54516H9.69078V3.02745H8.16147Z"
                    fill="currentColor"
                  />
                </svg>
                <div className="tw-text-sm tw-text-gray-300">
                  No company found for this profile.
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </ContainerWhite>
  );
}
