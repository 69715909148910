import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useSlackSubscriptions(tenant: number) {
  return useQuery<string[]>({
    queryKey: ['slack-subscriptions', tenant],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/integrations/slack/subscriptions`
      );

      return data?.channels || [];
    },
    retry: false,
  });
}
