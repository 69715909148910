import React, { Component } from 'react';

type GlobalModelPerformanceCustomerFitGuideComponentProps = {
  tenant: number;
  goTo: (url: string) => void;
};

export default class GlobalModelPerformanceCustomerFitGuideComponent extends Component<
  GlobalModelPerformanceCustomerFitGuideComponentProps,
  {}
> {
  render() {
    const { tenant, goTo } = this.props;
    return (
      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
            <div className="card-body">
              <i className="far fa-compass fa-lg mt-2"></i>
              <h5 className="mt-2">
                <strong>Guide: </strong>
                Global Model Performance
              </h5>
              <div className="border-top mt-2 border-primary"></div>
              <i className="fas fa-flask fa-lg mt-2"></i>
              <h5 className="mt-2">
                <strong>How MadKudu Computes This:</strong>
              </h5>
              <p className="mt-2">
                <strong>Recall Distribution:</strong> What percentage of
                conversions{' '}
                <a
                  onClick={() =>
                    goTo(`/org/${tenant}/mapping/conversion_mapping`)
                  }
                  style={{ textDecoration: 'underline' }}
                >
                  (see how conversions are mapped)
                </a>{' '}
                in the selected timeframe and have converted came from leads
                scored as very good, good, medium or low by the MadKudu's
                Customer Fit model. Note that we take the last score of the lead
                before the opportunity is created.
              </p>
              <p className="mt-2">
                <strong>Leads Distribution:</strong> in the selected timeframe
                and have converted came from leads scored as very good, good,
                medium or low by the MadKudu's Customer Fit model. Note that we
                take the last score of the lead before the opportunity is
                created.
              </p>
              <p className="mt-2">
                <strong>Note:</strong>We use the opportunity created date for
                SQO and Open Opp predictions, and we use opportunity close date
                for Closed Won for Closed Won predictions.
              </p>
              <h5 className="mt-2">
                <strong>How to Interpret This:</strong>
              </h5>
              <p>
                Find out if your highly scored leads account for most of the
                conversions. The higher the number the better the model is at
                identifying who is likely to generate a success.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
