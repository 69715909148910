import React from 'react';
import { components, OptionProps } from 'react-select';
import { getBadgeColorFrom } from './EventMappingHeader';
import { SelectOptionLike } from './utils';
import CircleBadge from './utils/CircleBadge';

export function EventMappingHistoryOption(
  props: OptionProps<SelectOptionLike>
) {
  const { children, ...rest } = props;
  const className = getBadgeColorFrom(props.label);
  return (
    <components.Option {...rest}>
      <div className="d-flex flex-row align-items-center gap-2">
        <div>
          <CircleBadge variant={className} />
        </div>
        <span>{children}</span>
      </div>
    </components.Option>
  );
}
