import React from 'react';
import { ConnectedView } from './configuration-views/ConnectedView';
import { ConnectionView } from './configuration-views/ConnectionView';
import { useZoomInfoIsConnected } from './hooks/useZoomInfoIsConnected';

type Props = {
  tenant: number;
};

export function ZoomInfoConfiguration({ tenant }: Props) {
  const { data: isZoomInfoConnected } = useZoomInfoIsConnected(tenant);

  if (!isZoomInfoConnected) return <ConnectionView tenant={tenant} />;
  return <ConnectedView tenant={tenant} />;
}
