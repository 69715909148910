import React from 'react';

import { deleteIntegrationAndLaunchPopUp } from '../../utils';

export default function GongConnectButton(props: {
  tenant: number;
  integration: string;
  loading?: boolean;
  isConnected?: boolean;
}) {
  const { tenant, integration, loading, isConnected } = props;

  const redirect_to_oauth = () => {
    window.location.href = `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/oauth/redirect`;
  };

  const deleteConnection = async () => {
    await deleteIntegrationAndLaunchPopUp(
      tenant,
      integration,
      undefined,
      false
    );
  };

  return (
    <>
      {loading && <div>Loading...</div>}
      {isConnected && !loading && (
        <>
          <p>
            <button
              className="btn btn-primary"
              onClick={() => redirect_to_oauth()}
            >
              Update connection
            </button>
          </p>
          <p>
            <button
              className="btn btn-danger"
              onClick={() => deleteConnection()}
            >
              Delete connection
            </button>
          </p>
        </>
      )}
      {!isConnected && !loading && (
        <button className="btn btn-primary" onClick={() => redirect_to_oauth()}>
          Connect Gong
        </button>
      )}
    </>
  );
}
