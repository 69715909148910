import React, { useEffect } from 'react';
import { GaugeProps } from './types';
import drawGauge from './drawers/drawGauge';

function Gauge(props: GaugeProps) {
  useEffect(() => {
    drawGauge(props);
  }, [props.value]);

  return <svg id="chart" />;
}

export default Gauge;
