import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { ConnectorsNames } from '../../../../types';
import { updateConditionChange } from '../../../conversion_mapping/models/filters_forms/DefaultFiltersForm';
import MappingsMode from '../../../enums/MappingsMode';
import VerbsNames from '../../../enums/VerbsNames';
import { computeDefaultLogicUpdate, updateParameters } from '../../../utils';
import { Condition, ConditionFormData, Event } from '../../models/types';
import ConditionFormComponent from '../generic/ConditionFormComponent';
import { createNewDefault } from '../generic/utils';
import { UseSwalInstance } from './hooks/primitives/useSwal';
import { IndexedEvent } from './hooks/useEventMappingHistory';

type EventMappingConditionFormProps = {
  clickCancel: UseSwalInstance['clickCancel'];
  clickConfirm: UseSwalInstance['clickConfirm'];
  event: Partial<Event>;
  hasErrors: boolean;
  connectorPullConfigFields: UnknownObject[];
  connector: string;
  pullFieldProperties: UnknownObject;
  tenant: number;
  email: string;
  setEvent: (id: number, commit: Partial<IndexedEvent>) => void;
};

export default function EventMappingConditionForm({
  event,
  tenant,
  email,
  hasErrors,
  connectorPullConfigFields,
  connector,
  pullFieldProperties,
  clickCancel,
  clickConfirm,
  setEvent,
}: EventMappingConditionFormProps) {
  function ensureCleanFormData(conditionFormData: ConditionFormData) {
    return {
      // When in doubt, pick everything from the original object.
      // Some events include 'conditionsForGlobalVars' in their condition form data.
      ...conditionFormData,
      conditionsLogic: conditionFormData?.conditionsLogic ?? '',
      conditions: (conditionFormData?.conditions ?? []).map((condition) => ({
        ...condition,
        values: condition?.values ?? [],
      })),
    };
  }

  const [state, setState] = useState<Partial<Event>>({
    // For Hubspot mappings, the 'event' value is needed as a pull fields' registry key
    event: event?.event,
    conditionFormData: ensureCleanFormData(event?.conditionFormData),
  });

  // For now, computeDefaultLogicUpdate just looks at how many conditions we have
  // and output something like '$1 AND $2 AND ... $N' for N conditions.
  // In the future, we would maybe want to support 'OR' operator.
  const defaultLogic = computeDefaultLogicUpdate(
    state.conditionFormData.conditions
  );
  const isCustomLogic =
    state.conditionFormData.conditionsLogic !== defaultLogic;

  const createNewCondition = () => {
    const _conditions = [
      ...state.conditionFormData.conditions,
      { ...(createNewDefault(connector) as Condition), lower: true },
    ];
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditionsLogic: !isCustomLogic
          ? computeDefaultLogicUpdate(_conditions)
          : `${state.conditionFormData.conditionsLogic} AND $${_conditions.length}`,
        conditions: _conditions,
      },
    });
  };

  const createNewConditionValue = (conditionIndex: number) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: state?.conditionFormData?.conditions.map(
          (condition, index) => ({
            ...condition,
            values:
              index === conditionIndex
                ? [...condition.values, '']
                : condition.values,
          })
        ),
      },
    });
  };

  const removeConditionValue = (conditionIndex: number) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: state.conditionFormData.conditions.map(
          (condition, index) => ({
            ...condition,
            values:
              index === conditionIndex
                ? condition.values.filter(
                    // Pops last element
                    (_, i) => i !== condition.values.length - 1
                  )
                : condition.values,
          })
        ),
      },
    });
  };

  const handleSubjectChange = (value: string, conditionIndex: number) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: state.conditionFormData.conditions.map(
          (condition, index) => ({
            ...condition,
            subject: index === conditionIndex ? value : condition?.subject,
          })
        ),
      },
    });
  };

  const handleLogicChange = (value: string) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditionsLogic: value,
      },
    });
  };

  const handleValueChange = (
    value: string,
    conditionIndex: number,
    valueIndex: number
  ) => {
    const _conditions = (updateParameters(
      'value',
      value,
      conditionIndex,
      valueIndex,
      state.conditionFormData.conditions,
      tenant,
      email,
      connector as ConnectorsNames,
      MappingsMode.event
    ) as unknown) as Condition[];
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: _conditions,
      },
    });
  };

  const handleVerbChange = (value: VerbsNames, conditionIndex: number) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: (updateConditionChange(
          value,
          conditionIndex,
          // @ts-ignore: TODO re-adapt updateConditionChange for Conditions.
          state.conditionFormData.conditions
        ) as unknown) as Condition[],
      },
    });
  };

  const handleLower = (conditionIndex: number) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: state.conditionFormData.conditions.map(
          (condition, index) => ({
            ...condition,
            lower:
              index === conditionIndex ? !condition?.lower : !!condition?.lower,
          })
        ),
      },
    });
  };

  const handleRemoveCondition = (removedIndex: number) => {
    const _conditions = state?.conditionFormData?.conditions.filter(
      (_, index) => index !== removedIndex
    );
    if (!_conditions.length) {
      setState({
        ...state,
        conditionFormData: {
          ...state.conditionFormData,
          conditionsLogic: '',
          conditions: [],
        },
      });
    } else {
      setState({
        ...state,
        conditionFormData: {
          ...state.conditionFormData,
          conditionsLogic: isCustomLogic
            ? state?.conditionFormData?.conditionsLogic
            : computeDefaultLogicUpdate(_conditions),
          conditions: _conditions,
        },
      });
    }
  };

  const handleObjectChange = (value: string, conditionIndex: number) => {
    setState({
      ...state,
      conditionFormData: {
        ...state.conditionFormData,
        conditions: state.conditionFormData.conditions.map(
          (condition, index) => ({
            ...condition,
            object: index === conditionIndex ? value : condition?.object,
          })
        ),
      },
    });
  };

  return (
    <>
      <div className="overflow-x-hidden">
        <ConditionFormComponent
          formData={state}
          editable
          hasErrors={!!hasErrors}
          createNewCondition={createNewCondition}
          createNewConditionValue={createNewConditionValue}
          removeConditionValue={removeConditionValue}
          handleSubjectChange={handleSubjectChange}
          handleLogicChange={handleLogicChange}
          handleValueChange={handleValueChange}
          handleVerbChange={handleVerbChange}
          handleLower={handleLower}
          handleRemoveCondition={handleRemoveCondition}
          handleObjectChange={handleObjectChange}
          connectorPullConfigFields={connectorPullConfigFields}
          connector={connector}
          pullFieldProperties={pullFieldProperties}
        />
      </div>
      {isCustomLogic && (
        <div className="alert alert-primary mt-4 text-left" role="alert">
          <strong>Info:</strong> This event has custom condition logic. If
          removing items, make sure to update the condition logic depending on
          your preferences.
        </div>
      )}
      <div className="d-flex flex-row gap-8 justify-content-center mt-4">
        <button
          type="button"
          className="btn btn-light btn-outline-dark btn-lg"
          onClick={clickCancel}
        >
          Cancel
        </button>
        {!isEqual(
          ensureCleanFormData(event.conditionFormData),
          state.conditionFormData
        ) && (
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => {
              clickConfirm();
              setEvent(event.id, state);
            }}
          >
            Save
          </button>
        )}
      </div>
    </>
  );
}
