import React, { Component } from 'react';

import LeadConversionRevenueCardComponent from './LeadConversionRevenueCardComponent';

type LeadConversionRevenueComponentProps = {
  values: string[];
  leadAmountPercentageValues: any;
  sqoAmountPercentageValues: any;
  leadRevenuePercentageValues: any;
};

export default class LeadConversionRevenueComponent extends Component<
  LeadConversionRevenueComponentProps,
  {}
> {
  render() {
    const {
      leadAmountPercentageValues,
      values,
      leadRevenuePercentageValues,
      sqoAmountPercentageValues,
    } = this.props;

    return (
      <div className="col-sm-6">
        <div className="card box-shadow-3d d-flex h-100">
          <h5 className="card-header">MadKudu Fit Quality Distribution</h5>
          <div className="card-body">
            <div className={'mt-2'}></div>
            <LeadConversionRevenueCardComponent
              values={values}
              amountPercentageValues={leadAmountPercentageValues}
              title="Leads"
            />
            <React.Fragment>
              <div className={'mt-4px'}></div>
              <LeadConversionRevenueCardComponent
                values={values}
                amountPercentageValues={sqoAmountPercentageValues}
                title="MadKudu SQOs"
              />
            </React.Fragment>
            <div className={'mt-4px'}></div>
            <LeadConversionRevenueCardComponent
              values={values}
              amountPercentageValues={leadRevenuePercentageValues}
              title="Revenue"
            />
            <p className="mt-3 text-muted">Legend:</p>
            <div className="badge badge-primary">
              <span>Very Good</span>
            </div>
            <div className="badge badge-teal text-white">
              <span>Good</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
