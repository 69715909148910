import React from 'react';
import { PushConfigurationItem } from '../types';

type AdminPushConfigurationProps = {
  tenant: number;
  handleTextAreaAdmin: any;
  pushConfiguration: PushConfigurationItem[];
  saveAdminJson: Function;
};

export default class AdminPushConfiguration extends React.Component<
  AdminPushConfigurationProps
> {
  render() {
    return (
      <React.Fragment>
        {this.props.pushConfiguration.map((field, index: number) => {
          return (
            <React.Fragment key={`${index}_${field}`}>
              <div className="row mt-4">
                <div className="col">
                  <label>{field.object_type}</label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <textarea
                    defaultValue={JSON.stringify(field.attributes, null, 2)}
                    style={{ height: '600px', width: '700px' }}
                    onChange={(e) => this.props.handleTextAreaAdmin(e, index)}
                  ></textarea>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col">
                  <button
                    className="btn btn-primary"
                    onClick={async () => {
                      await this.props.saveAdminJson(
                        field.object_type,
                        this.props.tenant,
                        this.props.pushConfiguration[index]
                      );
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}
