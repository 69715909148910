import React from 'react';

export function LegacyMixpanelAuthInstructions() {
  return (
    <div className="mr-4 mt-2 px-2">
      <h5>How it works</h5>
      <ul>
        <li>
          First, login to your{' '}
          <a href="https://mixpanel.com" target="_blank" rel="noreferrer">
            Mixpanel account
          </a>
        </li>
        <li>
          You will need to have a <b>Mixpanel project</b> ready to be used for
          MadKudu
        </li>
        <li>
          Go to either your <b>Personal settings</b> and click on{' '}
          <b>Projects</b>
        </li>
        <li>
          You should find your project's <b>API Key</b> and <b>API Secret</b>.
        </li>
        <li>
          Copy the <b>API Key</b> and <b>API Secret</b> and paste them in the
          form below
        </li>
      </ul>
    </div>
  );
}
