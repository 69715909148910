import 'chartjs-plugin-datalabels';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { DataSourceDefinition, DataSourceVariable } from '../../InsightsTypes';
import ConfigurationFormFieldComponent from './ConfigurationFormFieldComponent';

type ConfigurationFormComponentProps = {
  superKudu: boolean;
  dataSourcesDefinition: DataSourceDefinition[];
  handleDefinitionChange: (name: string, newDefinition: UnknownObject) => void;
};

export default class ConfigurationFormComponent extends React.Component<
  ConfigurationFormComponentProps,
  {}
> {
  render() {
    const {
      dataSourcesDefinition,
      handleDefinitionChange,
      superKudu,
    } = this.props;

    return (
      <div>
        {dataSourcesDefinition.map(({ name, definition }) => (
          <div key={name} className="card box-shadow-3d mt-md-2 d-flex">
            <h5 className="card-header">Global Variables ({name})</h5>
            <ListGroup className="list-group-flush">
              {Object.keys(definition).map((variableName) => {
                const value = definition[variableName];
                const variable: DataSourceVariable = {
                  value,
                  name: variableName,
                };
                return (
                  <ConfigurationFormFieldComponent
                    superKudu={superKudu}
                    key={variableName}
                    variable={variable}
                    handleVariableChange={(newValue) => {
                      const newDefinition = cloneDeep(definition);
                      newDefinition[variableName] = newValue;
                      handleDefinitionChange(name, newDefinition);
                    }}
                    deletable={false}
                  />
                );
              })}
            </ListGroup>
          </div>
        ))}
      </div>
    );
  }
}
