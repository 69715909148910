import Backbone from 'backbone';
import React from 'react';
import { Button } from 'react-bootstrap';
import ConversionMappingManager from '../../../models/ConversionMappingManager';
import ConnectorsOverviewCardComponent from './ConnectorOverviewCardComponent';
import { ConversionMappingConnectorsNames } from '../../../../../types';
import { launchDeleteMappingPopup } from '../../../../swal';
import MappingsMode from '../../../../enums/MappingsMode';
import { goBackToConversionMappingOverview } from '../../utils';
import { ActiveConnectorDataModel } from '../../../models/ConversionMappingData';

type ConnectorsOverviewComponentProps = {
  conversionMappingDataManager: ConversionMappingManager;
};

type ConnectorsOverviewComponentState = {
  activeConnectors: ActiveConnectorDataModel[];
  isSaving: boolean;
};

export default class ConnectorsOverviewComponent extends React.Component<
  ConnectorsOverviewComponentProps,
  ConnectorsOverviewComponentState
> {
  constructor(props: ConnectorsOverviewComponentProps) {
    super(props);
    this.state = {
      activeConnectors: this.props.conversionMappingDataManager.data
        .activeConnectors,
      isSaving: false,
    };
    // Binds
    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.handleClickOnConfirm = this.handleClickOnConfirm.bind(this);
    this.handleOnClickOnCard = this.handleOnClickOnCard.bind(this);
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
    this.goBackToConversionMapping = this.goBackToConversionMapping.bind(this);
  }

  async goBackToMapping() {
    this.props.conversionMappingDataManager.step = 0;
    this.setState({
      isSaving: true,
    });
    await this.props.conversionMappingDataManager.save();
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingDataManager.tenant}/mapping`,
      true
    );
  }

  goBackToConversionMapping() {
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingDataManager.tenant}/mapping/conversion_mapping/overview`,
      true
    );
  }

  async handleClickOnConfirm() {
    this.props.conversionMappingDataManager.step = 2;
    this.setState({
      isSaving: true,
    });
    await this.props.conversionMappingDataManager.save();
    Backbone.history.navigate(
      `org/${this.props.conversionMappingDataManager.tenant}/mapping/conversion_mapping/standard_parameters`,
      true
    );
  }

  handleOnClickOnCard(connector: string) {
    const newActiveConnectors = this.props.conversionMappingDataManager.data.setActiveConnector(
      connector as ConversionMappingConnectorsNames
    );
    this.setState({
      activeConnectors: newActiveConnectors,
    });
  }

  async handleOnClickRemove() {
    const { tenant, email } = this.props.conversionMappingDataManager;

    await launchDeleteMappingPopup(tenant, email, MappingsMode.conversion, () =>
      goBackToConversionMappingOverview(tenant)
    );
  }

  render() {
    const { activeConnectors, isSaving } = this.state;
    const {
      data,
      isFinished,
      isSuperKudu,
    } = this.props.conversionMappingDataManager;
    const { activeIntegrations } = data;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className={`breadcrumb-link text-primary ${
                  isSaving ? 'disabled' : ''
                }`}
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToConversionMapping}
              >
                Conversion Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Connector Overview
            </li>
          </ol>
          <div className="float-right my-2 my-lg-0">
            <div>
              {isFinished && isSuperKudu && (
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-danger"
                    onClick={this.handleOnClickRemove}
                  >
                    Remove and create a new configuration
                  </button>
                </div>
              )}
            </div>
          </div>
        </nav>
        <div className="shadow-sm p-3 mb-5 bg-white rounded text-center border-top">
          <strong>Step 1/3: </strong>Select the data source(s) where your
          conversions are stored
        </div>
        <div>
          <div className="card-deck justify-content-center">
            {activeIntegrations.map((activeIntegration, index) => {
              const activeConnectorObject = activeConnectors.find(
                (activeConnector) => {
                  return activeConnector.connector === activeIntegration;
                }
              );
              return (
                <div
                  className={`text-white mb-3 mx-4 ${
                    activeConnectorObject?.isActive
                      ? 'border border-success shadow rounded bg-success-fade'
                      : 'border text-white bg-white mb-3 mx-4'
                  }`}
                  key={activeIntegration}
                  onClick={() => this.handleOnClickOnCard(activeIntegration)}
                >
                  <ConnectorsOverviewCardComponent
                    isActive={activeConnectorObject?.isActive}
                    connector={activeIntegration}
                    key={index}
                  />
                </div>
              );
            })}
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 text-center">
              <Button
                bsStyle="primary"
                onClick={this.handleClickOnConfirm}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save & continue'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
