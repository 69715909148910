import axios from 'axios';
import AbstractMadMlMapping from './AbstractMadMlMapping';
import { getAudienceParams } from '../audiences/audience/utils';

export default class MadMlAudienceMapping extends AbstractMadMlMapping {
  audienceName: string;

  setAudienceName(audienceName: string) {
    this.audienceName = audienceName;
  }

  async createMadMlData(madMlSqlQuery: string): Promise<void> {
    const objectToSend = await this.prepareMadMlData(madMlSqlQuery);
    const url = `${BONGO_URL}/v1/org/${
      this.tenant
    }/data/mappings/audiences/${this.audienceName.trim()}`;
    await axios.post(url, { ...objectToSend });
  }

  createMadMlObjectToSend(madMlSqlQuery: string): any {
    const indentedMadMlSqlQuery = madMlSqlQuery
      .split('\n')
      .map((lane) => `      ${lane.trim()}`)
      .join('\n');

    return {
      tenant: this.tenant,
      description: `MadML configuration for ${this.tenant} - audience mapping`,
      email: this.email,
      isCustom: true,
      name: this.audienceName,
      madMlSqlQuery: indentedMadMlSqlQuery,
    };
  }

  async getMadMlData(): Promise<string> {
    const { madMlSqlQuery } = await getAudienceParams(
      this.tenant,
      this.audienceName
    );
    return madMlSqlQuery as string;
  }

  async prepareMadMlData(madMlSqlQuery: string): Promise<any> {
    return this.createMadMlObjectToSend(madMlSqlQuery);
  }
}
