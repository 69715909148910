import React from 'react';
import { Loading } from './Loading';
import { useSlackIsConnected } from './hooks/useSlackIsConnected';
import { SlackOverview } from './SlackOverview';

const TABS = ['overview', 'configuration'] as const;
export type Tab = typeof TABS[number];

type Props = {
  tenant: number;
};

export function SlackIntegration({ tenant }: Props) {
  const { isLoading } = useSlackIsConnected(tenant);

  return (
    <div className="row ml-4">
      <div className="tab-content w-100" id="TabContent">
        <div className="tab-pane fade active show" role="tabpanel">
          <div className="row justify-content-center">
            <div className="col-8 mt-4 mb-4">
              <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src="/media/integrations/logos/slack-icon.png"
                    />
                    <h5 className="mb-0 ml-3">Slack</h5>
                  </div>
                </div>

                <div className="card-body">
                  {isLoading && (
                    <Loading loadingLabel="Checking Slack connection" />
                  )}
                  {!isLoading && <SlackOverview tenant={tenant} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
