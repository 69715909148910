import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';
import EvolutionOverTimeConversionsCustomerFitGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeConversionsCustomerFitGuideComponent';
import EvolutionOverTimeConversionsLeadGradeGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeConversionsLeadGradeGuideComponent';
import EvolutionOverTimeConversionsMQAGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeConversionsMQAGuideComponent';
import EvolutionOverTimeConversionsPQLGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeConversionsPQLGuideComponent';
import EvolutionOverTimeLeadsCustomerFitGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeLeadsCustomerFitGuideComponent';
import EvolutionOverTimeLeadsLeadGradeGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeLeadsLeadGradeGuideComponent';
import EvolutionOverTimeLeadsMQAGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeLeadsMQAGuideComponent';
import EvolutionOverTimeLeadsPQLGuideComponent from '../guideCards/evolution_over_time/EvolutionOverTimeLeadsPQLGuideComponent';

type ChartsOverTimeComponentProps = {
  tenant: number;
  modelType: string;
  leadsAndRecallsForAudienceAndConversion: UnknownObject;
  goTo: (url: string) => void;
};
export default class ChartsOverTimeComponent extends Component<
  ChartsOverTimeComponentProps,
  {}
> {
  constructor(props: ChartsOverTimeComponentProps) {
    super(props);
    this.getConversionsGuide = this.getConversionsGuide.bind(this);
    this.getLeadsGuide = this.getLeadsGuide.bind(this);
  }

  getConversionsGuide() {
    const { tenant, modelType, goTo } = this.props;
    switch (modelType) {
      case 'customer_fit':
        return (
          <EvolutionOverTimeConversionsCustomerFitGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      case 'lead_grade':
        return (
          <EvolutionOverTimeConversionsLeadGradeGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      case 'mqa':
        return (
          <EvolutionOverTimeConversionsMQAGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      case 'pql':
      case 'pql2':
      case 'mql':
        return (
          <EvolutionOverTimeConversionsPQLGuideComponent
            tenant={tenant}
            goTo={goTo}
          />
        );
      default:
        return <div />;
    }
  }

  getLeadsGuide() {
    const { modelType } = this.props;
    switch (modelType) {
      case 'customer_fit':
        return <EvolutionOverTimeLeadsCustomerFitGuideComponent />;
      case 'lead_grade':
        return <EvolutionOverTimeLeadsLeadGradeGuideComponent />;
      case 'mqa':
        return <EvolutionOverTimeLeadsMQAGuideComponent />;
      case 'pql':
      case 'pql2':
      case 'mql':
        return <EvolutionOverTimeLeadsPQLGuideComponent />;
      default:
        return <div />;
    }
  }

  render() {
    const { leadsAndRecallsForAudienceAndConversion, modelType } = this.props;
    const { leads, recall } = leadsAndRecallsForAudienceAndConversion;
    const conversionsChart = (
      <Bar
        data={recall}
        height={270}
        options={{
          title: {
            display: true,
            text: 'Conversions Distribution',
            fontSize: 16,
            position: 'top',
          },
          legend: { position: 'bottom' },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          plugins: {
            datalabels: {
              display() {
                return false;
              },
            },
          },
        }}
      />
    );

    const leadsChart = (
      <Bar
        data={leads}
        height={270}
        options={{
          title: {
            display: true,
            text: `${modelType === 'mqa' ? 'Accounts' : 'Leads'} Distribution`,
            fontSize: 16,
            position: 'top',
          },
          legend: { position: 'bottom' },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          plugins: {
            datalabels: {
              display() {
                return false;
              },
            },
          },
        }}
      />
    );

    const guides = (
      <div className="row mt-3">
        <div className="col-sm-6">{this.getConversionsGuide()}</div>
        <div className="col-sm-6">{this.getLeadsGuide()}</div>
      </div>
    );

    return (
      <div>
        <div className="card box-shadow-3d mt-3">
          <h4 className="card-header">Evolution Over Time</h4>
          <div className="row">
            <div className="col-sm-6">{conversionsChart}</div>
            <div className="col-sm-6">{leadsChart}</div>
          </div>
        </div>
        {guides}
      </div>
    );
  }
}
