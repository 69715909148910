import React, { useEffect } from 'react';
import { useBigQueryContext } from './contexts/BigQueryContext';
import { getTableLabel } from '../utils/data-warehouse';
import { deleteIntegrationAndLaunchPopUp } from '../utils';
import Fields_mapping from '../fields_mapping';

export function BigQueryOverview() {
  const {
    tenant,
    credentials,
    setEditMode,
    clearCredentials,
  } = useBigQueryContext();

  useEffect(() => {
    const backboneComponent = new Fields_mapping({
      tenant,
      integration: 'bigquery',
      credentialsFound: true,
    });
    const target = document.getElementById('fields_mapping');
    if (target) backboneComponent.setElement(target);
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleDeleteClick = async () => {
    await deleteIntegrationAndLaunchPopUp(tenant, 'bigquery', () => {
      clearCredentials();
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-4">
        <div className="col-8">
          <div className="card box-shadow-3d mb-3">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <img
                  className="con-map-mk-integration-logo-size"
                  style={{ width: '30px' }}
                  src="/media/integrations/logos/bigquery-icon.png"
                />
                <h5 className="mb-0 ml-3">BigQuery</h5>
              </div>
            </div>
            <div className="card-body">
              <p>BigQuery has been configured!</p>
              <h5>Project configuration</h5>
              <div className="row">
                <div className="col-md-2">Project Id:</div>
                <div className="col-md-10">{credentials?.projectId}</div>
              </div>
              <div className="row">
                <div className="col-md-2">Dataset:</div>
                <div className="col-md-10">{credentials?.dataset}</div>
              </div>
              {credentials?.location && (
                <div className="row">
                  <div className="col-md-2">Location:</div>
                  <div className="col-md-10">{credentials?.location}</div>
                </div>
              )}
              <h5 className="mt-2">Mapping</h5>
              {(['event', 'contact_update', 'account_update'] as const).map(
                (table) => {
                  const theirTable = credentials?.tableNames?.[table];

                  if (!theirTable) return null;

                  return (
                    <div key={table} className="mb-2">
                      <div className="row">
                        <div className="col-md-2 font-weight-bold">
                          {getTableLabel(table)}:
                        </div>
                        <div className="col-md-10 font-weight-bold">
                          {theirTable}
                        </div>
                      </div>
                      {Object.entries(
                        credentials?.columnNames?.[table] ?? {}
                      ).map(([key, value]) => {
                        return (
                          <div key={key} className="row">
                            <div className="col-md-2">{key}:</div>
                            <div className="col-md-10">{value}</div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 pb-4 text-center">
        <button className="btn btn-primary" onClick={handleEditClick}>
          Edit
        </button>

        <button className="btn btn-danger ml-2" onClick={handleDeleteClick}>
          Delete
        </button>
      </div>

      <div id="fields_mapping" />
    </>
  );
}
