import { ModelTypes } from '../types';

export const MODEL_TYPES_NAMES = {
  customer_fit: 'Customer Fit',
  pql: 'Lead Likelihood to Buy',
  mqa: 'Account Likelihood to Buy',
  pql2: 'PQL',
  mql: 'MQL',
};

export const IS_LLTB = (modelType: ModelTypes) =>
  ['pql2', 'mql', 'pql'].includes(modelType);

export const IS_HYBRID = (modelType: ModelTypes) =>
  ['pql2', 'mql'].includes(modelType);
