import React from 'react';
import { ControlLabel, Form, FormControl } from 'react-bootstrap';
import {
  ChartDefinitionAndData,
  ChartGroupDefinition,
} from '../../InsightsTypes';
import CustomChartJSComponent from './CustomChartJSComponent';
import TableComponent from './TableComponent';
import BigNumberComponent from './BigNumberComponent';
import SpeedometerComponent from './SpeedometerComponent';

export function getFilterValues(
  definition: ChartGroupDefinition,
  data: UnknownArrayOfObjects
): {
  label: string;
  variable: string;
  values: string[];
} {
  const chartWithFilter = definition.charts.find((c) => c.filter);
  if (chartWithFilter) {
    const { label, variable } = chartWithFilter.filter;
    return {
      label,
      variable,
      values: [...new Set(data.map((row) => row[variable]))],
    };
  }
}

type InnerChartComponentProps = {
  chartDefinitionAndData: ChartDefinitionAndData;
};

type InnerChartComponentState = {
  filterValue: string;
};

export default class InnerChartComponent extends React.Component<
  InnerChartComponentProps,
  InnerChartComponentState
> {
  constructor(props: InnerChartComponentProps) {
    super(props);
    this.changeFilterValue = this.changeFilterValue.bind(this);

    const { chartDefinitionAndData } = this.props;
    const { definition, data } = chartDefinitionAndData;
    const filterValues = getFilterValues(definition, data);
    this.state = {
      filterValue: filterValues ? filterValues.values[0] : null,
    };
  }

  changeFilterValue(newValue: string) {
    this.setState({
      filterValue: newValue,
    });
  }

  render() {
    const { chartDefinitionAndData } = this.props;
    const { filterValue } = this.state;
    const { definition, data } = chartDefinitionAndData;
    const filterValues = getFilterValues(definition, data);
    const filteredData = data.filter(
      (row) =>
        !filterValue ||
        String(row[definition.charts[0]?.filter?.variable]) ===
          String(filterValue)
    );

    const chartDefinitionAndDataFiltered: ChartDefinitionAndData = {
      definition,
      data: filteredData,
    };

    return (
      <div>
        {filterValue && (
          <div>
            <Form inline className="mb-2">
              <ControlLabel className="mr-2">{filterValues.label}</ControlLabel>
              <FormControl
                componentClass="select"
                value={filterValue}
                onChange={(e: any) => this.changeFilterValue(e.target.value)}
              >
                {filterValues.values.map((value, i) => {
                  return (
                    <option
                      key={`select_${i}_${Math.random() * 100}`}
                      value={value}
                    >
                      {value}
                    </option>
                  );
                })}
              </FormControl>
            </Form>
          </div>
        )}
        {(() => {
          switch (definition.charts[0].type) {
            case 'table':
              return (
                <TableComponent
                  chartDefinition={definition.charts[0]}
                  data={filteredData}
                />
              );
            case 'big_number':
              return (
                <BigNumberComponent
                  chartDefinition={definition.charts[0]}
                  data={filteredData}
                />
              );
            case 'speedometer':
              return (
                <SpeedometerComponent
                  chartDefinition={definition.charts[0]}
                  data={filteredData}
                />
              );
            default:
              return (
                <CustomChartJSComponent
                  chartDefinitionAndData={chartDefinitionAndDataFiltered}
                />
              );
          }
        })()}
      </div>
    );
  }
}
