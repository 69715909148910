import axios from 'axios';
import { parse, stringify } from 'flatted';
import cloneDeep from 'lodash/cloneDeep';
import copy from 'copy-to-clipboard';
import { detect } from 'detect-browser';
import Swal from 'sweetalert2';
import { getAttributes, sortFiltersForm } from '../utils';
import { getScriptCheckerStateBasedOnMappingMode } from '../../utils';
import MappingsMode from '../../enums/MappingsMode';
import { AttributeMappingData } from './AttributeMappingData';

const browser = detect();

export default class AttributeMappingManager {
  tenant: number;

  email: string;

  lastPublisher: string;

  createdAt: number;

  updatedAt: number;

  scriptCheckerStatus: boolean;

  isReadOnly: boolean;

  isDefaultMapping: boolean;

  data: AttributeMappingData;

  isOld: boolean;

  constructor(tenant: number, email: string, isReadOnly: boolean) {
    this.tenant = tenant;
    this.email = email;
    this.isReadOnly = isReadOnly;
    this.scriptCheckerStatus = false;
    this.isOld = false;
    this.lastPublisher = '';
    this.createdAt = Date.now();
    this.updatedAt = Date.now();
    this.data = new AttributeMappingData(tenant, email);
  }

  async init() {
    const dataFromDb: AttributeMappingManager = await getAttributes(
      this.tenant
    );

    const {
      data: scriptCheckerData,
    } = await getScriptCheckerStateBasedOnMappingMode(
      this.tenant,
      MappingsMode.attribute
    );

    this.scriptCheckerStatus = !!scriptCheckerData;
    if (dataFromDb) {
      this.isOld = true;
      this.createdAt = dataFromDb.createdAt;
      this.updatedAt = dataFromDb.updatedAt;
      this.lastPublisher = dataFromDb.lastPublisher;
      this.data = new AttributeMappingData(this.tenant, this.email);
      // Init the fields to use
      await this.data.init();
      // Build using the data from db
      this.data.build({
        filtersForms: sortFiltersForm(dataFromDb.data.filtersForms),
        updatedAt: dataFromDb.data.updatedAt,
        createdAt: dataFromDb.data.createdAt,
      });
    } else {
      await this.data.init();
    }
  }

  prepare() {
    this.data.prepare();
  }

  async publish(callback?: (c: boolean) => void) {
    if (this.data.errors.length) {
      Swal.fire({
        title: 'Form errors',
        text: `It seems you have invalid inputs, please enter valid values.`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
      return;
    }
    const clonedThis = cloneDeep(this);
    clonedThis.prepare();
    // We do not send the scriptCheckerStatus to the server side
    // Since the value is only used for front purposes
    delete clonedThis.scriptCheckerStatus;
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/attributes`;

    Swal.fire({
      title: 'Are you sure?',
      text: 'This will update your attributes mapping',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, publish it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const requestResponse = await axios
          .put(url, clonedThis)
          .then((response) => {
            if (response.status < 200 || response.status > 300) {
              throw new Error('Something went wrong!');
            }
            window.analytics.track(
              `${this.isOld ? 'Publish' : 'Create new'} mapping`,
              {
                mode: 'simple',
                map: 'attribute',
                tenant: this.tenant,
                email: this.email,
              }
            );
          })
          .catch(async (error) => {
            Swal.showValidationMessage(
              error?.response?.data?.message ?? `Request failed: ${error}`
            );
          });
        return parse(stringify(requestResponse));
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (response: any) => {
      if (response.value) {
        Swal.fire({
          icon: 'success',
          title: 'Published successfully!',
        });
        const {
          data: scriptCheckerData,
        } = await getScriptCheckerStateBasedOnMappingMode(
          this.tenant,
          MappingsMode.attribute
        );
        this.scriptCheckerStatus = !!scriptCheckerData;
        if (callback) callback(this.scriptCheckerStatus);
      }
    });
  }

  async copy() {
    if (browser.name === 'firefox') {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text:
          'We are sorry, but this feature is not available on Firefox, please try with Google Chrome...',
      });
    } else {
      const clonedThis = cloneDeep(this);
      clonedThis.prepare();
      let response: any;

      const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/attributes/query`;
      await Swal.fire({
        title: 'Copy',
        text: 'This is going to create the query and copy it to your clipboard',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, copy it!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios
            .put(url, clonedThis)
            .then((res) => {
              response = res;
              if (response.status < 200 || response.status > 300) {
                throw new Error('Something went wrong!');
              }
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((res: any) => {
        copy(response.data);
        if (res?.value) {
          Swal.fire({
            icon: 'success',
            title: 'Copied successfully!',
          });
        }
      });
    }
  }
}
