import React, { useState } from 'react';
import { Button, Input } from '../../../components/ui';
import { Loading } from '../Loading';
import { useChangeApolloApiKey } from '../hooks/useChangeApolloApiKey';

type Props = {
  tenant: number;
};

export function ConnectionView({ tenant }: Props) {
  const [apiKey, setApiKey] = useState<string>('');

  const {
    mutate,
    isLoading: isConnecting,

    isError,
    reset,
  } = useChangeApolloApiKey(tenant, { onSuccess: () => setApiKey('') });

  if (isConnecting) return <Loading loadingLabel="Connecting to Apollo" />;

  return (
    <>
      <p>
        <p>
          Status:{' '}
          {!isConnecting && (
            <b className="tw-text-red-500">You're not connected to Apollo</b>
          )}
        </p>
        Enter your Apollo API key bellow, to authorize MadKudu to connect to
        Apollo
      </p>

      <Input
        className={isError && 'tw-border-red-500 tw-text-red-500'}
        placeholder="Enter your Apollo API key"
        type="text"
        value={apiKey}
        onChange={(e) => {
          if (isError || !apiKey) reset();
          setApiKey(e.currentTarget.value);
        }}
      />

      {isError && (
        <p className="tw-text-red-500 tw-mt-2">
          The API key you entered is invalid. Please try again.
        </p>
      )}

      <Button
        className="tw-mt-5"
        disabled={!apiKey}
        onClick={() => mutate(apiKey)}
      >
        Connect
      </Button>
    </>
  );
}
