import axios from 'axios';
import Backbone from 'backbone';
import populateInputs from './populate_inputs';
import { generateReCaptchaV3Token } from './check_recaptcha';

const template = require('./recover_password.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #mki_recover_password': 'recover',
  },

  render() {
    this.$el.html(template());
    populateInputs();
    return this;
  },

  async recover(e: any) {
    e.preventDefault();
    const form_data: UnknownObject = {};
    $.each($('form#resetform').serializeArray(), (index, field) => {
      form_data[field.name] = field.value;
    });
    form_data['g-recaptcha-response'] = await generateReCaptchaV3Token();
    try {
      await axios.post(`${BONGO_URL}/v1/recover`, form_data);
      const user_email = $('form#resetform').find('[name="email"]').val();
      window.analytics.track('Asked for password reset', {
        email: user_email,
      });
    } catch (err) {
      // do nothing
    }
    Backbone.history.navigate('/login?recover=yes', { trigger: true });
  },
});
