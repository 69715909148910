import React, { useMemo } from 'react';
import {
  flushCache,
  parseEmailResult,
  parseEmailResultReturnType,
} from './utils';
import useFetchPredictCompute from '../hooks/api/useFetchPredictCompute';

interface ProfileAdminProps {
  tenant: number;
  email: string;
  personResult: Record<string, any>;
}

export default function ProfileAdmin({
  tenant,
  personResult,
  email,
}: ProfileAdminProps) {
  const handleOnClearCacheClick = async () => {
    await flushCache(tenant);
  };

  const { data: predictResult, isFetching } = useFetchPredictCompute(
    tenant,
    email
  );

  const data: parseEmailResultReturnType | null = useMemo(
    () =>
      predictResult && personResult
        ? parseEmailResult({
            personResult,
            predictResult,
          })
        : null,
    [email, predictResult, personResult]
  );

  return (
    <div className="row mt-2">
      <button
        id="flush_cache"
        className="btn btn-primary btn-block mb-2"
        onClick={handleOnClearCacheClick}
      >
        Clear the config cache on predict
      </button>
      {isFetching && <div>Loading...</div>}
      {!isFetching && data && (
        <>
          <div className="col-sm-6">
            <div>
              <h3>Person API</h3>
            </div>
            <textarea
              disabled
              value={JSON.stringify(data.predictResultPerson, null, 4)}
              style={{ width: '100%', height: '600px' }}
            />
          </div>
          <div className="col-sm-6">
            <div>
              <h3>Compute API</h3>
            </div>
            <textarea
              disabled
              value={JSON.stringify(data, null, 4)}
              style={{ width: '100%', height: '600px' }}
            />
          </div>
        </>
      )}
      {!isFetching && !data && <div>No data</div>}
    </div>
  );
}
