var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (integration) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav navbar-brand mr-auto mt-2 mt-lg-0\"\u003E\u003Cli class=\"breadcrumb-item\"\u003E\u003Ca class=\"breadcrumb-link text-primary\" id=\"go_back_integration_board\"\u003EIntegrations\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item\"\u003E\u003Ca class=\"breadcrumb-link text-primary text-capitalize\" id=\"go_admin\"\u003E" + (pug.escape(null == (pug_interp = integration) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item text-capitalize\" aria-current=\"page\"\u003E\u003Ca class=\"breadcrumb-link text-primary text-capitalize\" id=\"go_back\"\u003E" + (pug.escape(null == (pug_interp = integration + ' Admin') ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item active text-capitalize\" aria-current=\"page\"\u003E" + (pug.escape(null == (pug_interp = integration + ' Admin Workbench') ? "" : pug_interp)) + "\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fnav\u003E\u003Cdiv class=\"container mt-4 pb-5\"\u003E\u003Cdiv class=\"card box-shadow-3d mx-2\"\u003E\u003Cdiv id=\"BigQueryWorkbenchViewContainer\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "integration" in locals_for_with ?
        locals_for_with.integration :
        typeof integration !== 'undefined' ? integration : undefined));
    ;;return pug_html;};
module.exports = template;