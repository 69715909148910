import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import {
  EVENTS_VERBS,
  getVerbBoundaries,
  VERBS,
  VERBS_FLOATS_ONLY,
} from '../../../utils';
import { lowerCaseAndReplaceSpaces } from './utils';
import conditionFormData from '../../../../models/conditionFormData';
import FieldDataModel from '../../../models/FieldDataModel';
import { unslugFieldName } from '../../../pullConfigManager';
import MissingFieldMicrocopy from '../../../../components/ErrorMicroCopyComponent';
import {
  CONNECTOR_OBJECT_PULL_CONFIG,
  CONNECTORS_NAMES,
} from '../../constants';
import { ErrorDataModel, ErrorLevel } from '../../models/ErrorDataModel';
import {
  OBJECT_PLACEHOLDER_VALUE,
  SUBJECT_PLACEHOLDER_VALUE,
  VERB_PLACEHOLDER_VALUE,
} from '../../../constants';
import { SelectOptionLike } from '../functional/utils';

type ConditionsFormProps = {
  editable: boolean;
  eventsVerbs?: UnknownArrayOfObjects;
  formData: UnknownObject;
  hasErrors: boolean;
  createNewCondition: () => void;
  createNewConditionValue: (c: number) => void;
  removeConditionValue: (c: number) => void;
  handleSubjectChange: (c: string, c2: number) => void;
  handleLogicChange: (c: string) => void;
  handleValueChange: (c: string, c2: number, c3: number) => void;
  handleVerbChange: (c: string, c2: number) => void;
  handleLower: (c: number) => void;
  handleRemoveCondition: (c: number) => void;
  handleObjectChange: (c: string, c2: number) => void;
  connectorPullConfigFields: UnknownArrayOfObjects;
  connector: string;
  pullFieldProperties: UnknownObject;
};

export default class ConditionsForm extends React.Component<
  ConditionsFormProps,
  {}
> {
  constructor(props: ConditionsFormProps) {
    super(props);

    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleLogicChange = this.handleLogicChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleLower = this.handleLower.bind(this);
    this.handleRemoveCondition = this.handleRemoveCondition.bind(this);
    this.createNewCondition = this.createNewCondition.bind(this);
    this.createNewConditionValue = this.createNewConditionValue.bind(this);
    this.removeConditionValue = this.removeConditionValue.bind(this);
    this.handleObjectChange = this.handleObjectChange.bind(this);
  }

  createNewCondition() {
    this.props.createNewCondition();
  }

  createNewConditionValue(conditionIndex: number) {
    this.props.createNewConditionValue(conditionIndex);
  }

  removeConditionValue(conditionIndex: number) {
    this.props.removeConditionValue(conditionIndex);
  }

  handleSubjectChange(e: any, conditionIndex: number) {
    this.props.handleSubjectChange(e.target.value, conditionIndex);
  }

  handleLogicChange(e: any) {
    this.props.handleLogicChange(e.target.value);
  }

  handleValueChange(e: any, conditionIndex: number, valueIndex: number) {
    this.props.handleValueChange(e.target.value, conditionIndex, valueIndex);
  }

  handleVerbChange(e: any, conditionIndex: number) {
    this.props.handleVerbChange(e.target.value, conditionIndex);
  }

  handleLower(conditionIndex: number) {
    this.props.handleLower(conditionIndex);
  }

  handleRemoveCondition(conditionIndex: number) {
    this.props.handleRemoveCondition(conditionIndex);
  }

  handleObjectChange(e: any, conditionIndex: number) {
    this.props.handleObjectChange(e.target.value, conditionIndex);
  }

  getJsxOfObject(
    editable: boolean,
    condition: UnknownObject,
    conditionIndex: number,
    connector: string,
    connectorPullConfigFields: UnknownObject,
    pullFieldProperties: UnknownObject,
    chosenEvent: string,
    errors: ErrorDataModel[]
  ) {
    const isHubspot = connector === CONNECTORS_NAMES.hubspot;
    const isMarketoOrAnalytic =
      connector !== CONNECTORS_NAMES.salesforce_tasks &&
      connector !== CONNECTORS_NAMES.salesforce_campaigns;
    const isConnectorPullConfigFields =
      connectorPullConfigFields && connectorPullConfigFields.length > 0;
    let options: SelectOptionLike[] = [];

    const defaultOptionValue = new FieldDataModel(
      OBJECT_PLACEHOLDER_VALUE,
      true
    );

    // For old data, the condition.object coming from server doesn't contain the a_, we search for it and take it with the a_.
    const value: string = condition.object;

    if (isHubspot || isMarketoOrAnalytic || isConnectorPullConfigFields) {
      let selectedOption;
      if (isHubspot) {
        const fields: FieldDataModel[] = [
          defaultOptionValue,
          ...(pullFieldProperties?.[chosenEvent] ?? []),
        ];
        options = fields.map((field: FieldDataModel) => ({
          label:
            field.name === OBJECT_PLACEHOLDER_VALUE
              ? field.name
              : unslugFieldName(field.name),
          value: field.name,
        }));
        selectedOption = options.find((option) => value === option.value);
      } else if (isMarketoOrAnalytic) {
        const fields: FieldDataModel[] = [
          defaultOptionValue,
          ...pullFieldProperties[CONNECTOR_OBJECT_PULL_CONFIG[connector][0]],
        ];
        options = fields.map((field: FieldDataModel) => ({
          label:
            field.name === OBJECT_PLACEHOLDER_VALUE
              ? field.name
              : lowerCaseAndReplaceSpaces(unslugFieldName(field.name)),
          value: lowerCaseAndReplaceSpaces(field.name),
        }));
        selectedOption = options.find(
          (option) =>
            lowerCaseAndReplaceSpaces(value) === option.value ||
            value === option.value
        );
      } else if (isConnectorPullConfigFields) {
        const fields: FieldDataModel[] = [
          defaultOptionValue,
          ...connectorPullConfigFields.map(
            (connectorPullConfigField: any) =>
              new FieldDataModel(Object.keys(connectorPullConfigField)[0], true)
          ),
        ];
        options = fields.map((field: FieldDataModel) => ({
          label: unslugFieldName(field.name),
          value: field.name,
        }));
        selectedOption = options.find((option) => value === option.value);
      }

      return (
        <td>
          <Select
            name="object"
            options={options}
            value={selectedOption}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: 1065, // Swal container z-index is 1060
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                minWidth: 150,
              }),
            }}
            onChange={(e: SelectOptionLike) =>
              this.handleObjectChange(
                { target: { value: e.value } },
                conditionIndex
              )
            }
          />
          {this.hasErrorOnProperty(errors, conditionIndex, 'object') && (
            <MissingFieldMicrocopy microcopy="Please select a valid object/property." />
          )}
        </td>
      );
    }

    return (
      <td>
        <FormControl
          name="object"
          type="text"
          value={condition.object}
          disabled={!editable}
          onChange={(e) => this.handleObjectChange(e, conditionIndex)}
        />
      </td>
    );
  }

  getJsxOfSubjectField(
    condition: UnknownObject,
    conditionIndex: number,
    editable: boolean,
    isConnectorPullConfigFields: boolean,
    connectorPullConfigFields: UnknownArray,
    errors: ErrorDataModel[]
  ) {
    let value: FieldDataModel;
    const defaultValue: FieldDataModel = {
      name: SUBJECT_PLACEHOLDER_VALUE,
      standard: true,
    };
    let values: FieldDataModel[] = [defaultValue];
    if (isConnectorPullConfigFields) {
      if (condition.object !== OBJECT_PLACEHOLDER_VALUE) {
        values = [
          defaultValue,
          ...connectorPullConfigFields?.filter((element: UnknownObject) => {
            return Object.keys(element)[0] === condition.object;
          })[0][condition.object],
        ];

        value =
          values.find((field) => {
            return field.name === condition.subject;
          }) || values[0];
      } else {
        value = defaultValue;
      }

      const options = values.map((field) => ({
        label: unslugFieldName(field.name),
        value: field.name,
      }));
      const selectedOption = options.find(
        (option) => option.value === value.name
      );

      return (
        <td>
          <Select
            name="subject"
            options={options}
            value={selectedOption}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: 1065, // Swal container z-index is 1060
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                minWidth: 150,
              }),
            }}
            onChange={(e: SelectOptionLike) =>
              this.handleSubjectChange(
                { target: { value: e.value } },
                conditionIndex
              )
            }
          />
          {this.hasErrorOnProperty(errors, conditionIndex, 'subject') && (
            <MissingFieldMicrocopy microcopy="Please select a valid property." />
          )}
        </td>
      );
    }
  }

  hasErrorOnProperty(
    errors: ErrorDataModel[],
    conditionIndex: number,
    property: ErrorLevel
  ): boolean {
    const { hasErrors } = this.props;
    return (
      hasErrors &&
      errors?.some(
        (error) => error.level === property && error.index === conditionIndex
      )
    );
  }

  render() {
    const {
      connector,
      editable,
      eventsVerbs,
      formData,
      connectorPullConfigFields,
      pullFieldProperties,
    } = this.props;

    // handle case when older event mapping objects have no conditionFormData
    if (!this.props.formData.conditionFormData) {
      this.props.formData.conditionFormData = conditionFormData;
    }

    const {
      conditions,
      conditionsLogic,
      errors,
    } = this.props.formData.conditionFormData;

    const verbsToUse = eventsVerbs ? EVENTS_VERBS : VERBS;
    const isNotMarketoOrAnalytic =
      connector === CONNECTORS_NAMES.salesforce_tasks ||
      connector === CONNECTORS_NAMES.salesforce_campaigns;
    const isConnectorPullConfigFields =
      connectorPullConfigFields &&
      connectorPullConfigFields.length > 0 &&
      isNotMarketoOrAnalytic;
    const conditionsLogicError = errors?.find(
      ({ level }: ErrorDataModel) =>
        level === 'condition logic - empty' ||
        level === 'condition logic - extra indexes' ||
        level === 'condition logic - ignored indexes' ||
        level === 'condition logic - syntax error'
    );
    const verbOptions = verbsToUse.map((v) => ({ label: v, value: v }));
    return (
      <div>
        <Row>
          <Col sm={12}>
            {conditions?.length ? (
              <Row className="mb-2">
                <ReactTooltip place="top" />
                <Col
                  componentClass={ControlLabel}
                  className="font-weight-bold mt-1"
                  sm={3}
                >
                  Edit condition logic:
                </Col>
                <Col sm={8}>
                  <Row>
                    <FormControl
                      type="text"
                      defaultValue={conditionsLogic}
                      value={conditionsLogic}
                      onChange={(e) => this.handleLogicChange(e)}
                      placeholder="$1 AND ($2 OR $3)"
                      data-tip="You can use parenthesis eg: $1 AND ($2 OR $3)"
                      name="conditionsLogic"
                      readOnly={!editable}
                    />
                  </Row>
                  <Row>
                    {conditionsLogicError?.level ===
                      'condition logic - empty' && (
                      <MissingFieldMicrocopy microcopy="Please enter a valid condition logic." />
                    )}
                    {conditionsLogicError?.level ===
                      'condition logic - syntax error' && (
                      <MissingFieldMicrocopy microcopy="Syntax error, please respect the following pattern: $1 AND ($2 OR $3)." />
                    )}
                    {conditionsLogicError?.level ===
                      'condition logic - extra indexes' && (
                      <MissingFieldMicrocopy
                        microcopy={`Extra parameters shouldn't be used: ${conditionsLogicError.indexes.join(
                          ', '
                        )}`}
                      />
                    )}
                    {conditionsLogicError?.level ===
                      'condition logic - ignored indexes' && (
                      <MissingFieldMicrocopy
                        microcopy={`Missing parameter(s): ${conditionsLogicError.indexes.join(
                          ', '
                        )}`}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Alert bsStyle="info">No conditions yet.</Alert>
            )}
            <div className="overflow-x-auto">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th></th>
                    {isConnectorPullConfigFields && <th>Object</th>}
                    <th>Property</th>
                    <th>Condition</th>
                    <th>Value</th>
                    <th>Edit</th>
                    <th>Case insensitive</th>
                  </tr>
                </thead>
                {conditions?.map(
                  (condition: UnknownObject, conditionIndex: number) => {
                    const selectedVerbOption = verbOptions.find(
                      (el) => el.value === condition.verb
                    );
                    return (
                      condition && (
                        <tbody key={`form_group_${conditionIndex}`}>
                          <tr key={conditionIndex}>
                            <td>
                              <span
                                style={{ opacity: 0.5 }}
                                className="text-nowrap"
                              >
                                ${conditionIndex + 1}
                              </span>
                            </td>
                            {this.getJsxOfObject(
                              editable,
                              condition,
                              conditionIndex,
                              connector,
                              connectorPullConfigFields,
                              pullFieldProperties,
                              formData.event,
                              errors
                            )}
                            {this.getJsxOfSubjectField(
                              condition,
                              conditionIndex,
                              editable,
                              isConnectorPullConfigFields,
                              connectorPullConfigFields,
                              errors
                            )}
                            <td>
                              <Select
                                name="verb"
                                options={verbsToUse.map((v) => ({
                                  label: v,
                                  value: v,
                                }))}
                                value={selectedVerbOption}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (baseStyles) => ({
                                    ...baseStyles,
                                    zIndex: 1065, // Swal container z-index is 1060
                                  }),
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    minWidth: 200,
                                  }),
                                }}
                                onChange={(e: SelectOptionLike) =>
                                  this.handleVerbChange(
                                    { target: { value: e.value } },
                                    conditionIndex
                                  )
                                }
                              />
                              {this.hasErrorOnProperty(
                                errors,
                                conditionIndex,
                                'verb'
                              ) && (
                                <MissingFieldMicrocopy microcopy="Please select a valid condition." />
                              )}
                            </td>
                            <td style={{ minWidth: 150 }}>
                              <div>
                                {condition.values.map(
                                  (value: string, valueIndex: number) => {
                                    return (
                                      <FormControl
                                        name="value"
                                        type={
                                          VERBS_FLOATS_ONLY.includes(
                                            condition.verb
                                          )
                                            ? 'number'
                                            : 'text'
                                        }
                                        value={value}
                                        readOnly={
                                          !editable ||
                                          condition.verb ===
                                            VERB_PLACEHOLDER_VALUE
                                        }
                                        placeholder="Enter value"
                                        key={`form_control${conditionIndex}_condition${valueIndex}`}
                                        onChange={(e) =>
                                          this.handleValueChange(
                                            e,
                                            conditionIndex,
                                            valueIndex
                                          )
                                        }
                                      />
                                    );
                                  }
                                )}
                                {this.hasErrorOnProperty(
                                  errors,
                                  conditionIndex,
                                  'values'
                                ) && (
                                  <MissingFieldMicrocopy microcopy="Please select valid values." />
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition.values.length <
                                  getVerbBoundaries(condition.verb).max && (
                                  <Button
                                    disabled={!editable}
                                    bsStyle="primary"
                                    onClick={() =>
                                      this.createNewConditionValue(
                                        conditionIndex
                                      )
                                    }
                                  >
                                    +
                                  </Button>
                                )}
                                {condition.values.length >
                                  getVerbBoundaries(condition.verb).min && (
                                  <Button
                                    disabled={!editable}
                                    bsStyle="danger"
                                    onClick={() =>
                                      this.removeConditionValue(conditionIndex)
                                    }
                                  >
                                    -
                                  </Button>
                                )}
                                <OverlayTrigger
                                  placement="left"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      Remove the condition
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    bsStyle="danger"
                                    disabled={!editable}
                                    onClick={() =>
                                      this.handleRemoveCondition(conditionIndex)
                                    }
                                  >
                                    X
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            </td>
                            <td style={{ minWidth: 150 }}>
                              <div>
                                <Checkbox
                                  checked={condition.lower}
                                  disabled={!editable}
                                  onChange={() =>
                                    this.handleLower(conditionIndex)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )
                    );
                  }
                )}
              </Table>
            </div>
          </Col>
        </Row>
        <Row className="pull-right mr-2">
          <Col>
            <Button
              bsStyle="primary"
              disabled={!editable}
              onClick={() => this.createNewCondition()}
            >
              Add a new condition
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
