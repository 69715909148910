import 'chartjs-plugin-datalabels';
import React from 'react';
import { PageDescription } from '../../InsightsTypes';

type PageDescriptionComponentProps = {
  description: PageDescription;
};

export default class PageDescriptionComponent extends React.Component<
  PageDescriptionComponentProps,
  {}
> {
  render() {
    const { description } = this.props;
    return (
      <div className="card mb-3 box-shadow-3d">
        <div className="card-header">{description.title}</div>
        <div className="card-body">
          {description.items.map((item, i) => (
            <div key={`item_${i}`}>
              <h5 className="card-title">{item.title}</h5>
              <p
                className="card-text"
                dangerouslySetInnerHTML={{ __html: item.body }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
