import { useReducer } from 'react';

/**
 * Like `this.state` / `this.setState` in a class component, but as a hook.
 * Can be useful for batch state updates or
 * when refactoring class components into functional ones.
 */
export default function useStateReducer<T extends object = {}>(
  initialState: T
) {
  return useReducer<(prevState: T, nextState: Partial<T>) => T>(
    (prev, next) => ({ ...prev, ...next }),
    initialState
  );
}
