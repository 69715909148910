import React, { useState } from 'react';
import ProfileV2 from './ProfileV2';
import Badge from '../../components/Badge';

interface ProfileContainerProps {
  tenant: number;
  isSuperKudu: boolean;
  userEmail: string;
  updateQueryString: (email: string) => void;
  queryParams: Record<string, any>;
}

export default function ProfileContainer({
  tenant,
  updateQueryString,
  queryParams,
  userEmail,
  isSuperKudu,
}: ProfileContainerProps) {
  const [isAdminChecked, setIsAdminChecked] = useState<boolean>(false);

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        aria-label="breadcrumb"
      >
        <ul className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
          <li
            className="breadcrumb-item active tw-inline-flex tw-items-center tw-gap-1"
            aria-current="page"
          >
            Score Lookup
            <Badge>New</Badge>
          </li>
        </ul>
        {isSuperKudu && (
          <div className="my-2 my-lg-0 form-inline">
            <p className="mb-0 mr-2">Admin</p>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                onChange={() => setIsAdminChecked(!isAdminChecked)}
                checked={isAdminChecked}
              />
              <label
                className="custom-control-label my-auto cursor-pointer"
                htmlFor="customSwitch1"
              >
                {isAdminChecked ? 'On' : 'Off'}
              </label>
            </div>
          </div>
        )}
      </nav>

      <div>
        <ProfileV2
          tenant={tenant}
          userEmail={userEmail}
          updateQueryString={updateQueryString}
          queryParams={queryParams}
          isAdminChecked={isAdminChecked}
        />
      </div>
    </>
  );
}
