import React, { Component } from 'react';

import { getColorForSegment } from '../../utils';

type LeadConversionRevenueComponentProps = {
  values: string[];
  amountPercentageValues: any;
  title: string;
};

export default class LeadConversionRevenueCardComponent extends Component<
  LeadConversionRevenueComponentProps,
  {}
> {
  render() {
    const { amountPercentageValues, values, title } = this.props;

    return (
      <div>
        <React.Fragment>
          <h6 className={'m-auto mt-3'}>{title}</h6>
          <div className="progress my-2 bg-gray" style={{ height: '40px' }}>
            {values.map((value) => (
              <div
                key={`sqo_${value}`}
                className={`progress-bar bg-${getColorForSegment(value)}`}
                role="progressbar"
                aria-valuenow={10}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{
                  width: parseInt(amountPercentageValues[value], 10)
                    ? amountPercentageValues[value]
                    : 0,
                }}
              >
                <h6 className={'m-auto'}>{amountPercentageValues[value]}</h6>
              </div>
            ))}
          </div>
        </React.Fragment>
      </div>
    );
  }
}
