export default class ActiveIntegrationDataModel {
  name: string;

  constructor(name?: string) {
    if (name) {
      this.name = name;
    } else {
      this.name = '';
    }
  }
}
