import React, { HTMLProps, useState } from 'react';

type UseSortingColumn<T extends Record<string, unknown>> = {
  key: keyof T;
  sortFunction?: (a: T, b: T) => number;
};

export type UseSortingOptions<T extends Record<string, unknown>> = {
  defaultSortedKey?: keyof T;
  defaultSortDirection?: SortDirection;
  columns?: Array<UseSortingColumn<T>>;
};

type SortDirection = 'ASC' | 'DESC';
type SortIconProps<T extends Record<string, unknown>> = HTMLProps<
  HTMLElement
> & {
  columnKey: keyof T;
};

export function useSorting<T extends Record<string, unknown>>(
  originalItems: Array<T> = [],
  options: UseSortingOptions<T>
) {
  const [activeKey, setActiveKey] = useState(options?.defaultSortedKey ?? null);
  const [direction, setDirection] = useState<SortDirection>(
    options?.defaultSortDirection ?? null
  );
  const sortedItems = [...originalItems];
  const defaultSorter = (a: T, b: T) =>
    String(a[activeKey]).localeCompare(String(b[activeKey]));
  const columns = options?.columns ?? [];
  if (!!activeKey && !!direction) {
    sortedItems.sort(
      columns.find(({ key }) => key === activeKey)?.sortFunction ??
        defaultSorter
    );
    if (direction === 'ASC') {
      sortedItems.reverse();
    }
  }
  const isActiveKey = (columnKey: keyof T) => columnKey === activeKey;
  const isSortedAsc = direction === 'ASC';
  const isSortedDesc = direction === 'DESC';
  const getIconClass = (columnKey: keyof T) => {
    if (isActiveKey(columnKey)) {
      if (isSortedAsc) {
        return 'fa-sort-up';
      }
      return isSortedDesc ? 'fa-sort-down' : 'fa-sort';
    }
    return 'fa-sort';
  };
  const reset = () => {
    setActiveKey(options?.defaultSortedKey ?? null);
    setDirection(options?.defaultSortDirection ?? null);
  };
  const sortBy = (key: keyof T, explicitDirection?: SortDirection) => {
    const hasExplicitDirection =
      explicitDirection || explicitDirection === null;
    if (activeKey === key) {
      if (hasExplicitDirection) {
        setDirection(explicitDirection);
      } else {
        let updatedDirection: SortDirection;
        if (direction === 'DESC') {
          updatedDirection = 'ASC';
        } else {
          updatedDirection = direction === 'ASC' ? null : 'DESC';
        }
        if (updatedDirection === null) {
          reset();
        } else {
          setDirection(updatedDirection);
        }
      }
    } else {
      setActiveKey(key);
      setDirection(hasExplicitDirection ? explicitDirection : 'DESC');
    }
  };
  return {
    isSorted:
      activeKey !== (options?.defaultSortedKey ?? null) ||
      direction !== (options?.defaultSortDirection ?? null),
    reset,
    SortIcon: function SortIcon(props: SortIconProps<T>) {
      const { columnKey, className, onClick, ...otherProps } = props;
      return (
        <i
          {...otherProps}
          className={['fas', getIconClass(columnKey), className ?? ''].join(
            ' '
          )}
        />
      );
    },
    sortBy,
    sortedItems,
    sortedActiveKeyValues: sortedItems.map((el) => el[activeKey]),
    activeKey,
    direction,
  };
}
