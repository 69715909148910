import Backbone from 'backbone';
import React from 'react';
import { Button } from 'react-bootstrap';
import ConversionMappingManager from '../../models/ConversionMappingManager';
import OverviewCardComponent from './OverviewCardComponent';

type OverviewComponentProps = {
  conversionMappingDataManager: ConversionMappingManager;
};

type OverviewComponentState = {
  isSaving: boolean;
};

export default class OverviewComponent extends React.Component<
  OverviewComponentProps,
  OverviewComponentState
> {
  constructor(props: OverviewComponentProps) {
    super(props);
    this.state = {
      isSaving: false,
    };
    // Binds
    this.handleOnClickCreateSimple = this.handleOnClickCreateSimple.bind(this);
  }

  async handleOnClickCreateSimple() {
    const { conversionMappingDataManager } = this.props;
    conversionMappingDataManager.step = 1;
    this.setState({
      isSaving: true,
    });
    await conversionMappingDataManager.save();
    Backbone.history.navigate(
      `org/${conversionMappingDataManager.tenant}/mapping/conversion_mapping/connector_overview`,
      true
    );
  }

  renderNoConversionMappingAlert() {
    return (
      <div className="alert alert-warning" role="alert">
        There is no conversion mapping configured yet!
      </div>
    );
  }

  renderCreateNewButton() {
    const { isSaving } = this.state;

    return (
      <div>
        <Button bsStyle="primary" onClick={this.handleOnClickCreateSimple}>
          {isSaving ? 'Redirecting...' : 'Create new configuration'}
        </Button>
      </div>
    );
  }

  render() {
    const { isSaving } = this.state;
    const { isReadOnly } = this.props.conversionMappingDataManager;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a className="breadcrumb-link text-primary">Mapping</a>
            </li>
            <li
              className={`breadcrumb-item active ${isSaving ? 'disabled' : ''}`}
              aria-current="page"
            >
              Conversion Mapping
            </li>
          </ol>
        </nav>
        <nav className="navbar bavbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d">
          <p className="m-auto">
            <strong>Overview: </strong>MadKudu's models are defined using 3
            conversion definitions, it's essential that these are correctly
            defined before your model is built.
          </p>
        </nav>
        <div className="container mt-4 p-3">
          <div className="row mt-3">
            <div className="col-sm-12">
              <OverviewCardComponent />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-center">
              {isReadOnly
                ? this.renderNoConversionMappingAlert()
                : this.renderCreateNewButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
