import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import { UserServiceModel } from '../../settings/users/types';
import { CognismIntegration, Tab } from './CognismIntegration';

const template = require('./index.pug');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

export default Backbone.View.extend({
  async initialize(options: { tenant: number; queryString: string }) {
    this.tenant = options.tenant;
    this.integration = 'cognism';
    this.session = app.session;
    const user: UserServiceModel = this.session.get('user');

    this.hasAccessToConfigurations =
      user.permissions.some(
        (permission) =>
          permission.tenant === this.tenant && permission.role === 'admin'
      ) || user.superKudu;

    await this.renderCognismIntegrationComponent(this.tenant, user);
    this.onlyAdminTabsGuard();
  },

  onlyAdminTabsGuard() {
    if (!this.hasAccessToConfigurations) {
      this.changeTab('overview');
    }
  },

  getActiveTab(): Tab {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === 'cognism' || !this.hasAccessToConfigurations)
      return 'overview';
    return lastItem as Tab;
  },

  changeTab(tab: Tab) {
    if (tab === 'overview') {
      Backbone.history.navigate(
        `/org/${this.tenant}/integrations/${this.integration}`
      );
    } else {
      Backbone.history.navigate(
        `/org/${this.tenant}/integrations/${this.integration}/${tab}`
      );
    }
  },

  async renderCognismIntegrationComponent() {
    await this.$el.empty();
    this.$el.html(template());

    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <CognismIntegration
          tenant={this.tenant}
          onActiveTabChange={this.changeTab.bind(this)}
          initialTab={this.getActiveTab()}
          hasAccessToConfiguration={this.hasAccessToConfigurations}
        />
      </QueryClientProvider>,
      $('#CognismIntegrationContainer')[0]
    );
  },
});
