import axios from 'axios';
import Swal from 'sweetalert2';
import { snakeCaseToHumanReadable } from '../../utils';
import profilesData from '../default_data';

const PERSON_PREFIX = 'person__';
const COMPANY_PREFIX = 'company__';
const EMPLOYMENT_PREFIX = 'employment__';
const CATEGORY_PREFIX = 'category__';
const METRICS_PREFIX = 'metrics__';
const GEO_PREFIX = 'geo__';

export type parseEmailResultReturnType = {
  predictResultCompany: Record<string, any>;
  predictResultPerson: Record<string, any>;
  personResultsProperties: Record<string, any>;
  traits: Record<string, any>;
};

export type emailResultReturnType = {
  predictResult: Record<string, any>;
  personResult: Record<string, any>;
};

export async function getEmailResults(
  tenant: number,
  email: string
): Promise<{
  predictResult: Record<string, any>;
  personResult: Record<string, any>;
}> {
  const predictResultPromise = axios.post(
    `${BONGO_URL}/v1/org/${tenant}/predict/compute`,
    { email }
  );
  const personResultPromise = axios.get(
    `${BONGO_URL}/v1/org/${tenant}/persons`,
    {
      params: { email },
    }
  );
  const [predictResult, personResult] = await Promise.all([
    predictResultPromise,
    personResultPromise,
  ]);
  return {
    personResult,
    predictResult,
  };
}

function sortPredictDataByAttributesNames(
  predictResult: Record<string, any>
): Record<string, any> {
  let updatedPredictResult = predictResult;
  const sortedPredictResult: Record<string, any> = {};
  const attributesSortedByNames: string[] = Object.keys(predictResult).sort(
    (a: string, b: string) => {
      return a.charCodeAt(0) - b.charCodeAt(0);
    }
  );
  attributesSortedByNames.forEach((attributeName: string) => {
    sortedPredictResult[attributeName] = predictResult[attributeName];
  });
  updatedPredictResult = sortedPredictResult;
  return updatedPredictResult;
}

function removeAugmentFieldsFromPredictData(
  predictResult: Record<string, any>
): Record<string, any> {
  let updatedPredictResult = predictResult;
  const predictResultData: Record<string, any> = {};
  Object.keys(predictResult).forEach((keyValue) => {
    if (!keyValue.includes('raw__')) {
      predictResultData[keyValue] = predictResult[keyValue];
    }
  });
  updatedPredictResult = predictResultData;
  return updatedPredictResult;
}

function extractPersonFieldsFromPredictData(
  predictResult: Record<string, any>
): Record<string, any> {
  const personResult: Record<string, any> = {
    employment: {},
    geo: {},
  };
  Object.keys(predictResult).forEach((keyValue) => {
    // Get all fields key starts with persona__
    if (keyValue.startsWith(PERSON_PREFIX)) {
      // Remove persona__ from the field name, keep the rest
      const keyValueWithoutPersonPrefix: string = keyValue.slice(
        PERSON_PREFIX.length
      );
      // Convert employment__ field names to property category
      if (keyValueWithoutPersonPrefix.startsWith(EMPLOYMENT_PREFIX)) {
        const keyValueWithoutEmploymentPrefix: string = keyValueWithoutPersonPrefix.slice(
          EMPLOYMENT_PREFIX.length
        );
        personResult.employment[keyValueWithoutEmploymentPrefix] =
          predictResult[keyValue];
        // Convert geo__ field names to property category
      } else if (keyValueWithoutPersonPrefix.startsWith(GEO_PREFIX)) {
        const keyValueWithoutGeoPrefix: string = keyValueWithoutPersonPrefix.slice(
          GEO_PREFIX.length
        );
        personResult.geo[keyValueWithoutGeoPrefix] = predictResult[keyValue];
        // Convert all the persona__ startWith fields to attributes
      } else {
        personResult[keyValueWithoutPersonPrefix] = predictResult[keyValue];
      }
    }
  });
  return personResult;
}

function extractCompanyFieldsFromPredictData(
  predictResult: Record<string, any>
): Record<string, any> {
  const companyResult: Record<string, any> = {
    category: {},
    metrics: {
      // For predict, predicted_revenue is on the root of the object, not in metrics
      predicted_revenue: predictResult.predicted_revenue,
    },
    geo: {},
  };
  Object.keys(predictResult).forEach((keyValue) => {
    // We get only fields keys, start with company__
    if (keyValue.startsWith(COMPANY_PREFIX)) {
      // Remove company__ from the field name, keep the rest
      const keyValueWithoutCompanyPrefix: string = keyValue.slice(
        COMPANY_PREFIX.length
      );
      // Convert category__ field names to property category
      if (keyValueWithoutCompanyPrefix.startsWith(CATEGORY_PREFIX)) {
        const keyValueWithoutCategoryPrefix: string = keyValueWithoutCompanyPrefix.slice(
          CATEGORY_PREFIX.length
        );
        companyResult.category[keyValueWithoutCategoryPrefix] =
          predictResult[keyValue];
        // Convert metrics__ field names to property metrics
      } else if (keyValueWithoutCompanyPrefix.startsWith(METRICS_PREFIX)) {
        const keyValueWithoutMetricsPrefix: string = keyValueWithoutCompanyPrefix.slice(
          METRICS_PREFIX.length
        );
        companyResult.metrics[keyValueWithoutMetricsPrefix] =
          predictResult[keyValue];
        // Convert geo__ field names to property geo
      } else if (keyValueWithoutCompanyPrefix.startsWith(GEO_PREFIX)) {
        const keyValueWithoutGeoPrefix: string = keyValueWithoutCompanyPrefix.slice(
          GEO_PREFIX.length
        );
        companyResult.geo[keyValueWithoutGeoPrefix] = predictResult[keyValue];
        // Convert all the company__ startWith fields to attributes
      } else {
        companyResult[keyValueWithoutCompanyPrefix] = predictResult[keyValue];
      }
    }
  });
  return companyResult;
}

export function parseEmailResult({
  predictResult,
  personResult,
}: {
  predictResult: Record<string, any>;
  personResult: Record<string, any>;
}): parseEmailResultReturnType {
  let parsedPredictResult = removeAugmentFieldsFromPredictData(predictResult);
  parsedPredictResult = sortPredictDataByAttributesNames(parsedPredictResult);
  const personResultsProperties = personResult.properties;
  // extract the person attributes
  const predictResultPerson = extractPersonFieldsFromPredictData(
    parsedPredictResult
  );
  // extract the company attributes
  const predictResultCompany = extractCompanyFieldsFromPredictData(
    parsedPredictResult
  );

  const predictResultPersonEmail = predictResult.email;
  const predictResultPersonCompanyName = predictResultPerson.employment_name;
  const predictResultPersonLocation = predictResultPerson.location;
  const predictResultPersonAvatar = predictResultPerson.avatar;
  const predictResultCompanyLogo = predictResultCompany.logo;

  let customer_fit = ([profilesData] as any).fit; // hack in case the API dosent return a customer_fit

  if (personResultsProperties.customer_fit.segment)
    customer_fit = personResultsProperties.customer_fit;

  let full_name = '';

  if (personResultsProperties.first_name)
    full_name += `${personResultsProperties.first_name} `;
  if (personResultsProperties.last_name)
    full_name += personResultsProperties.last_name;

  return {
    predictResultCompany,
    predictResultPerson,
    personResultsProperties,
    traits: {
      name: full_name,
      email: predictResultPersonEmail,
      company_name: predictResultPersonCompanyName,
      location: predictResultPersonLocation,
      fit_results: customer_fit,
      avatar: predictResultPersonAvatar,
      logo: predictResultCompanyLogo,
    },
  };
}

export async function getAndParseEmailResult(
  tenant: number,
  email: string
): Promise<parseEmailResultReturnType> {
  const { personResult, predictResult } = await getEmailResults(tenant, email);
  return parseEmailResult({ predictResult, personResult });
}

export async function flushCache(tenant: number): Promise<void> {
  try {
    await axios.get(`${BONGO_URL}/v1/org/${tenant}/cache/flush`);
    await Swal.fire(
      `Cache cleared for tenant ${tenant}`,
      'You can retry your lookup',
      'success'
    );
  } catch (e) {
    await Swal.fire('Error flushing the cache', e?.message, 'error');
  }
}

export function isBooleanComputation(name: string) {
  const BOOLEAN_TOKENS = ['is', 'has'];

  const regex = new RegExp(`\\b(${BOOLEAN_TOKENS.join('|')})\\b`, 'i');
  return regex.test(snakeCaseToHumanReadable(name));
}

export function extractDoubleQuotedWords(text: string) {
  const regex = /"([^"]*)"/g;
  const matches: string[] = [];

  let match = regex.exec(text);

  while (match !== null) {
    matches.push(match[1]);
    match = regex.exec(text);
  }

  return matches;
}

export function transformModelName(modelName: string) {
  const lowerCaseModelName = modelName.toLowerCase();
  const noSpecialCharacters = lowerCaseModelName.replace(/[^a-zA-Z0-9]/g, '_');
  const noNumbers = noSpecialCharacters.replace(/[0-9]/g, 'x');
  // encode string to be sure that there is nothing tricky left
  const encoded = encodeURI(noNumbers);
  return encoded;
}

export function prettifyCondition(condition: string) {
  const pattern = /LOWER\('?([a-zA-Z0-9-_/&+ ]*)'?\)/g;
  return condition.replace(pattern, '$1');
}
