import React, { useEffect, useState } from 'react';
import model_integration from '../../../models/integration';
import SalesloftConnectButton from './SalesloftConnectButton';

export type TabValues = 'overview' | 'configuration';

type SalesloftParentComponentProps = {
  tenant: number;
};

export default function SalesloftParentComponent({
  tenant,
}: SalesloftParentComponentProps) {
  const integration = 'salesloft';

  const [loading, setLoading] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const fetchSalesloftCredentials = () => {
    const model = new model_integration.Credentials_model({
      tenant,
      integration,
    });

    model.fetch({
      success: (_collection: any, response: any) => {
        console.log('fetched', response);
        setLoading(false);
        setIsConnected(Object.keys(response).length > 0);
      },
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          model.set({
            not_found: true,
          });
        }
        setLoading(false);
        setIsConnected(false);
      },
    });
  };

  useEffect(() => {
    fetchSalesloftCredentials();
  }, []);

  return (
    <div className="row ml-4">
      <div className="tab-content w-100" id="TabContent">
        <div>
          <div className="row justify-content-center">
            <div className="col-8 mt-4 mb-4">
              <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src={`/media/integrations/logos/salesloft-simple.svg`}
                    />
                    <h5 className="mb-0 ml-3">Salesloft</h5>
                  </div>
                </div>

                <div className="card-body">
                  <div className="d-flex">
                    <div className="row w-75 mr-4">
                      <div className="col">
                        <div>
                          <h5>Overview</h5>
                          <p>
                            Salesloft helps thousands of the world's most
                            successful selling teams drive more revenue with the
                            Modern Revenue Platform.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-25">
                      <p>
                        <b>Status</b>
                        <span className="d-block pt-1">
                          <SalesloftConnectButton
                            tenant={tenant}
                            integration={integration}
                            loading={loading}
                            isConnected={isConnected}
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
