import React from 'react';
import { FormControl } from 'react-bootstrap';
import {
  activateClearbitReveal,
  clearBitRevealPlans,
  deactivateClearbitReveal,
  getClearbitRevealActivePlan,
  getClearbitRevealCPullState,
  updateClearbitRevealActivePlan,
} from '../utils';
import app from '../../../app';
import { Card } from '../../../components/Card';

type ClearBitRevealConfigModeComponentState = {
  selectedPlan: string;
  pullState: boolean;
  isLoading: boolean;
  isRequesting: boolean;
  isUpdating: boolean;
  isDeactivating: boolean;
  error: any;
};

type ClearBitRevealConfigModeComponentProps = {
  tenant: number;
  userEmail: string;
};

export default class ClearBitRevealConfigModeComponent extends React.Component<
  ClearBitRevealConfigModeComponentProps,
  ClearBitRevealConfigModeComponentState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedPlan: clearBitRevealPlans.trial,
      pullState: false,
      isLoading: true,
      isRequesting: false,
      isUpdating: false,
      error: null,
      isDeactivating: false,
    };

    this.handleOnPlanChange = this.handleOnPlanChange.bind(this);
    this.handleOnClickActivate = this.handleOnClickActivate.bind(this);
    this.handleOnUpdatePlanClick = this.handleOnUpdatePlanClick.bind(this);
    this.handleOnDeactivateButtonClick = this.handleOnDeactivateButtonClick.bind(
      this
    );
  }

  async componentDidMount() {
    const { tenant } = this.props;

    const pullState = await getClearbitRevealCPullState(tenant);
    const activePlan = await getClearbitRevealActivePlan(tenant);
    this.setState({
      selectedPlan: activePlan,
      pullState,
      isLoading: false,
    });
  }

  handleOnPlanChange(event: any) {
    this.setState({
      selectedPlan: event.target.value,
    });
  }

  launchSuccessAlert(action: string) {
    app.notifications.success(`${action} correctly!`, {
      dismiss_timeout: 2000,
    });
  }

  async getAndSetClearbitRevealPullState(tenant: number) {
    const pullState = await getClearbitRevealCPullState(tenant);

    this.setState({
      pullState,
    });
  }

  async handleOnClickActivate() {
    this.setState({
      isRequesting: true,
    });
    try {
      const { tenant, userEmail } = this.props;
      const { selectedPlan } = this.state;
      await activateClearbitReveal(tenant, userEmail, selectedPlan);

      await this.getAndSetClearbitRevealPullState(tenant);

      this.setState({
        isRequesting: false,
        error: null,
      });

      this.launchSuccessAlert('Activated');
    } catch (e) {
      this.setState({
        isRequesting: false,
        error: e,
      });
    }
  }

  async handleOnUpdatePlanClick() {
    this.setState({
      isRequesting: true,
      isUpdating: true,
    });
    try {
      const { tenant } = this.props;
      const { selectedPlan } = this.state;
      await updateClearbitRevealActivePlan(tenant, selectedPlan);

      this.setState({
        isRequesting: false,
        isUpdating: false,
        error: null,
      });

      this.launchSuccessAlert('Updated');
    } catch (e) {
      this.setState({
        isRequesting: false,
        isUpdating: false,
        error: e,
      });
    }
  }

  async handleOnDeactivateButtonClick() {
    this.setState({
      isRequesting: true,
      isDeactivating: true,
    });
    try {
      const { tenant } = this.props;

      await deactivateClearbitReveal(tenant);

      await this.getAndSetClearbitRevealPullState(tenant);

      this.setState({
        isRequesting: false,
        isDeactivating: false,
        error: null,
      });

      this.launchSuccessAlert('Deactivated');
    } catch (e) {
      this.setState({
        isRequesting: false,
        isDeactivating: false,
        error: e,
      });
    }
  }

  renderErrorCard() {
    const { error } = this.state;

    return (
      <div className="card box-shadow-3d d-flex h-100 border-danger bg-danger-fade text-danger">
        <div className="card-body align-middle">
          <h5>{`Something went wrong: ${error.message}`}</h5>
        </div>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data">
        <div className="card-body align-middle">
          <div className="spinner-border float-left mr-2"></div>
          <h5>Loading...</h5>
        </div>
      </div>
    );
  }

  renderPlanDropDownMenu() {
    const { selectedPlan, isRequesting } = this.state;

    return (
      <FormControl
        componentClass="select"
        placeholder="select plan"
        className="w-25 ml-2"
        onChange={this.handleOnPlanChange}
        disabled={isRequesting}
        value={selectedPlan}
      >
        {Object.keys(clearBitRevealPlans).map(
          (clearBitRevealPlan: string, index: number) => {
            return (
              <option
                key={`clearbit_reveal_plan_option_${index}`}
                // @ts-ignore
                value={clearBitRevealPlans[clearBitRevealPlan]}
              >
                {clearBitRevealPlan}
              </option>
            );
          }
        )}
      </FormControl>
    );
  }

  renderCard() {
    const { pullState, isRequesting, isUpdating, isDeactivating } = this.state;
    return (
      <Card cardTitle="Clearbit Reveal Lookup">
        <p>
          The partnership with Clearbit distinguishes high traffic versus low
          traffic customers and different activation plan will be provisioned
          according to it. The plan can be updated later if needed.
        </p>
        {pullState ? (
          <React.Fragment>
            <div className="d-flex flex-row">
              <p className="mt-1">
                Successfully connected to Clearbit Reveal on plan:{' '}
              </p>
              {this.renderPlanDropDownMenu()}
            </div>
            <div className="d-flex flex-row"></div>
            <button
              className="btn btn-outline-primary"
              disabled={isRequesting}
              onClick={this.handleOnUpdatePlanClick}
            >
              {isRequesting && isUpdating ? 'Updating...' : 'Update Plan'}
            </button>
            <button
              className="btn btn-danger ml-2"
              disabled={isRequesting}
              onClick={this.handleOnDeactivateButtonClick}
            >
              {isRequesting && isDeactivating
                ? 'Deactivating...'
                : 'Deactivate'}
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              Activating Clearbit Reveal plan will activate the IP lookup on the
              anonymous web traffic to reveal the domain of the visitor.
            </p>
            <p className="text-danger font-italic">
              Every tenant activated will be charged by Clearbit. If you are not
              sure, please check-in with the Product/Sales team if we contract
              was signed first.
            </p>
            <div className="d-flex flex-row">
              <strong className="mt-1">Select plan:</strong>
              {this.renderPlanDropDownMenu()}
            </div>
            <button
              className="btn btn-primary mt-3"
              disabled={isRequesting}
              onClick={this.handleOnClickActivate}
            >
              {isRequesting ? 'Activating...' : 'Activate'}
            </button>
          </React.Fragment>
        )}
      </Card>
    );
  }

  renderContent() {
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        {isLoading ? this.renderLoader() : this.renderCard()}
      </React.Fragment>
    );
  }

  render() {
    const { error } = this.state;

    return (
      <div className="container mt-4 pb-5 w-50">
        {error && this.renderErrorCard()}
        {this.renderContent()}
      </div>
    );
  }
}
