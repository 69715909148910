import React, { useEffect, useState } from 'react';
import { useIntegrationQuery } from '../../api/useIntegrationQuery';
import { IntegrationOverviewView } from './views/IntegrationOverviewView';
import { Loading } from '../components/Loading';
import { IntegrationEditView } from './views/IntegrationEditView';
import { Tab } from './types';
import { NavItem } from './components/NavItem';
import { IntegrationSuperKuduConfigurationView } from './views/IntegrationSuperKuduConfigurationView';
import { useGetIntegrationConfigQuery } from '../../api/useGetIntegrationConfigQuery';

type Props = {
  tenant: number;
  initialTab: Tab;
  onActiveTabChange: (tab: Tab) => void;
  isSuperkudu: boolean;
};

export function IntegrationPage({
  tenant,
  initialTab,
  onActiveTabChange,
  isSuperkudu,
}: Props) {
  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  function changeActiveTab(tab: Tab) {
    setActiveTab(tab);
    onActiveTabChange(tab);
  }

  const {
    data: jobChangesIntegration,
    isLoading: isLoadingIntegration,
  } = useIntegrationQuery({
    tenant,
    integration: 'job_changes',
  });

  const { isLoading: isLoadingJobChangesConfig } = useGetIntegrationConfigQuery(
    {
      tenant,
      integration: 'job_changes',
    }
  );

  const isLoading = isLoadingIntegration || isLoadingJobChangesConfig;

  const isJobChangesConnected = jobChangesIntegration?.full_pull?.active;

  // Redirect to overview tab if the integration is not connected
  useEffect(() => {
    if (activeTab === 'superkudu-configuration') return;
    if (isLoading || isJobChangesConnected || activeTab === 'overview') return;

    changeActiveTab('overview');
  }, [isJobChangesConnected, activeTab, isLoading]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
        <ul className="nav nav-pills mx-auto">
          <NavItem
            tabName="overview"
            activeTab={activeTab}
            changeActiveTab={changeActiveTab}
          />
          <NavItem
            tabName="configuration"
            activeTab={activeTab}
            changeActiveTab={changeActiveTab}
            disabled={isLoading || !jobChangesIntegration?.full_pull?.active}
          />
          {isSuperkudu && (
            <NavItem
              superkuduTab
              tabName="superkudu-configuration"
              activeTab={activeTab}
              changeActiveTab={changeActiveTab}
            />
          )}
        </ul>
      </nav>

      <div className="tw-w-full tw-flex tw-justify-center tw-p-6">
        <div className="card box-shadow-3d d-flex tw-w-[800px]">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <img
                className="con-map-mk-integration-logo-size"
                style={{ width: '30px' }}
                src="/media/integrations/logos/madkudu_horns.svg"
              />
              <h5 className="mb-0 ml-3">Job Changes</h5>
            </div>
          </div>

          <div className="card-body tw-p-10">
            {isLoading && (
              <Loading loadingLabel="Loading job changes configuration..." />
            )}
            {!isLoading && activeTab === 'overview' && (
              <IntegrationOverviewView
                tenant={tenant}
                changeActiveTab={changeActiveTab}
              />
            )}
            {!isLoading && activeTab === 'configuration' && (
              <IntegrationEditView tenant={tenant} />
            )}
            {!isLoading && activeTab === 'superkudu-configuration' && (
              <IntegrationSuperKuduConfigurationView tenant={tenant} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
