import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useFetchPredictCompute(tenant: number, email: string) {
  return useQuery<Record<string, any>>(
    ['profile-predict-compute', tenant, email],
    async () => {
      const { data: predictResult } = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/predict/compute`,
        {
          email,
        }
      );

      return predictResult;
    }
  );
}
