import axios from 'axios';

const PAGES = [
  'l1_leadsource',
  'l3',
  'l3_hubspot',
  'champion_buyer_analysis',
  'happy_path',
  'persona_analysis',
  'l2',
];

export function isNumber(str: string): boolean {
  return !Number.isNaN(Number(str));
}

async function isDataSourceExistForTenantAndPage(
  tenant: number,
  pageId: string
): Promise<boolean> {
  try {
    const urlPage = `${BONGO_URL}/v1/org/${tenant}/insights/page/${pageId}`;
    const { data: dataPage } = await axios.get(urlPage);
    const { configured }: { configured: boolean } = dataPage;
    return configured;
  } catch (err) {
    return false;
  }
}

export default async function getActiveInsightsPages(
  tenant: number
): Promise<Record<string, boolean>> {
  const activeInsightsPages: Record<string, boolean> = {};

  const promises = PAGES.map((page) =>
    isDataSourceExistForTenantAndPage(tenant, page)
  );
  const results = await Promise.all(promises);
  PAGES.forEach((page, index) => {
    activeInsightsPages[page] = results[index];
  });
  return activeInsightsPages;
}
