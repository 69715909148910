/**
 * ReCAPTCHA v3 client exposed as a global variable,
 * see: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/grecaptcha/index.d.ts
 */
declare let grecaptcha: {
  ready(callback: () => void): void;
  execute(siteKey: string, options: { action: string }): PromiseLike<string>;
};

/**
 * ReCAPTCHA v3 site key global variable exposed during Webpack build
 */
declare const RECAPTCHA_SITE_KEY: string;

export async function generateReCaptchaV3Token(action?: string) {
  return grecaptcha.execute(RECAPTCHA_SITE_KEY, {
    action: action || 'submit',
  });
}
