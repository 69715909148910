import classNames from 'classnames';
import React from 'react';

type Step = {
  title: string;
};

type Props = {
  steps: Step[];
  currentStep: number;
  onStepChange: (step: number) => void;
};

export function Steps({ steps, currentStep, onStepChange }: Props) {
  return (
    <ul className="nav nav-pills nav-fill shadow-sm p-3 mb-3 bg-white rounded text-center border-top">
      {steps.map((step, index) => (
        <li key={index} className="nav-item">
          <a
            key={index}
            className={classNames(
              'nav-link',
              currentStep === index && 'active'
            )}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (currentStep !== index) onStepChange(index);
            }}
          >
            <strong>Step {index + 1}:</strong>
            &nbsp;{step.title}
          </a>
        </li>
      ))}
    </ul>
  );
}
