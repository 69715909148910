import React from 'react';
import { ConnectedView } from './configuration-views/ConnectedView';
import { ConnectionView } from './configuration-views/ConnectionView';
import { useApolloIsConnected } from './hooks/useApolloIsConnected';

type Props = {
  tenant: number;
};

export function ApolloConfiguration({ tenant }: Props) {
  const { data: isApolloConnected } = useApolloIsConnected(tenant);

  if (!isApolloConnected) return <ConnectionView tenant={tenant} />;
  return <ConnectedView tenant={tenant} />;
}
