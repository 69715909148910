import Backbone from 'backbone';

import axios from 'axios';
import Swal from 'sweetalert2';
import app from '../app';
import { BackboneViewProps } from '../types';
import getActiveInsightsPages from './utils';
import { createAsanaTask, slackNotification } from '../utils/index';
import { swalWithBootstrapButtons } from '../integrations/configure/utils';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #goL1_Leadsource': 'goL1Leadsource',
    'click #go_L2': 'goL2',
    'click #go_L3': 'goL3',
    'click #go_happy_path': 'goHappyPath',
    'click #go_champion_analysis': 'goChampionAnalysis',
    'click #go_persona_analysis': 'goPersonaAnalysis',
    'click #access_L1_leadsource': 'go_L1_leadsource',
    'click #beta-access_L2': 'go_beta_L2',
    'click #beta-access_L3': 'go_beta_L3',
    'click #beta-access_happy_path': 'go_beta_happy_path',
    'click #beta-access_champion_analysis': 'go_beta_champion_analysis',
    'click #beta-access_persona_analysis': 'go_beta_persona_analysis',
    'click #beta-clearbit_reveal': 'go_beta_clearbit_reveal',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    const user = app.session.get('user');
    this.userEmail = app.session.get('user').email;
    this.activeInsightsPages = await getActiveInsightsPages(this.tenant);
    this.isInsightsPagesl1 =
      this.activeInsightsPages && this.activeInsightsPages.l1_leadsource;
    this.isInsightsPagesl2 =
      this.activeInsightsPages && this.activeInsightsPages.l2;
    this.isInsightsPagesl3 =
      this.activeInsightsPages &&
      (this.activeInsightsPages.l3 || this.activeInsightsPages.l3_hubspot);
    this.isInsightsPagesHappyPath =
      this.activeInsightsPages && this.activeInsightsPages.happy_path;
    this.isInsightsPagesPersonaAnalysis =
      this.activeInsightsPages && this.activeInsightsPages.persona_analysis;
    this.render();

    if (window.analytics) {
      window.analytics.page('Insights', {
        email: user.email,
        tenant: options.tenant,
      });
    }
  },

  async goL1Leadsource() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/l1_leadsource`,
      true
    );
    await axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zapier/5691597/ol0j2w6`,
      {
        email: this.userEmail,
        tenant: this.tenant,
        report: 'L1 Leadsource',
      }
    );
  },
  goL2() {
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l2`, true);
  },
  async goL3() {
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l3`, true);
    await axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zapier/5691597/ol0j2w6`,
      {
        email: this.userEmail,
        tenant: this.tenant,
        report: 'L3',
      }
    );
  },
  async goHappyPath() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/happy_path`,
      true
    );
    await axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zapier/5691597/ol0j2w6`,
      {
        email: this.userEmail,
        tenant: this.tenant,
        report: 'Happy Path',
      }
    );
  },
  async goChampionAnalysis() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/champion_buyer_analysis`,
      true
    );
    await axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zapier/5691597/ol0j2w6`,
      {
        email: this.userEmail,
        tenant: this.tenant,
        report: 'Champion Buyer Analysis',
      }
    );
  },
  async goPersonaAnalysis() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/persona_analysis`,
      true
    );
    await axios.post(
      `${BONGO_URL}/v1/org/${this.tenant}/services/zapier/5691597/ol0j2w6`,
      {
        email: this.userEmail,
        tenant: this.tenant,
        report: 'Persona Analysis',
      }
    );
  },

  async go_L1_leadsource() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L1 Leadsource report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_beta_L2() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L2 Campaigns & Predicted Revenue report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_beta_happy_path() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the Happy Path report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_beta_champion_analysis() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the Champion & Buyers Analysis report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_beta_persona_analysis() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the Persona Analysis report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_beta_L3() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L3 Hand Raiser SLA`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_beta_clearbit_reveal() {
    const requestBody = {
      subject: 'Request clearbit reveal Activation',
      body: `The tenant ${this.tenant} requested activation for Anonymous Visitor Scorer`,
      email: this.userEmail,
      destination: 'support',
    };
    const htmlBody = `
      <div>
        <p>
            Deanonymize your website traffic with the MadKudu Anonymous Visitor Scorer powered by <span style="text-decoration: underline">Clearbit Reveal</span> and
            MadKudu's scoring product to discover hot and engaged new accounts visiting your website.
        </p>
        <p>
            Activate a pilot now!
        </p>
      </div>
    `;
    const htmlFooter = `
      <div class="float-right">
        <div class="d-flex justify-content-between">
            <span class="mt-2">in partnership with</span>
              <img
                src="/media/integrations/logos/clearbit.svg"
                style='height: 40px'
              />
        </div>
      </div>
    `;
    return Swal.fire({
      title: 'Anonymous Visitor Scorer',
      html: htmlBody,
      footer: htmlFooter,
      width: 600,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonColor: '#5bc0de',
      confirmButtonColor: '#0275d8',
      confirmButtonText: 'See what it looks like',
      denyButtonText: 'Request Activation',
      preDeny: async () => {
        await axios.post(
          `${BONGO_URL}/v1/org/${this.tenant}/services/zendesk`,
          requestBody
        );
        Swal.fire({
          title: 'Request sent!',
          icon: 'success',
          text:
            'Thank you for your interest! Your request was sent to our Customer Success team who will contact you shortly!',
        });
      },
    }).then((result: any) => {
      if (result.value === true) {
        Swal.fire({
          title: 'Example',
          width: 1200,
          imageUrl: '/media/bg/clearbit_reveal_looks_like.png',
          imageWidth: 1200,
          imageHeight: 700,
        });
      }
    });
  },

  async render() {
    const html = template({
      activeInsightsPages: this.activeInsightsPages,
      isInsightsPagesl1: this.isInsightsPagesl1,
      isInsightsPagesl2: this.isInsightsPagesl2,
      isInsightsPagesl3: this.isInsightsPagesl3,
      isInsightsPagesHappyPath: this.isInsightsPagesHappyPath,
      isInsightsPagesChampionAnalysis: this.isInsightsPagesChampionAnalysis,
      isInsightsPagesPersonaAnalysis: this.isInsightsPagesPersonaAnalysis,
    });
    await this.$el.html(html);
    return this;
  },
});
