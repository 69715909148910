import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import app from '../../../app';
import ConfigurationPage from '../components/ConfigurationPageComponent';
import { capitalizeEachWord } from '../../../predictions/utils';

const template = require('./template.pug');

export default (Backbone as any).Epoxy.View.extend({
  async initialize(options: { tenant: number; page: string }) {
    this.options = options;
    const user = app.session.get('user');
    this.superKudu = user.superKudu;
    this.email = user.email;

    this.render();

    if (window.analytics) {
      window.analytics.page('Insights configuration', {
        email: user.email,
        tenant: options.tenant,
        insights_report: options.page,
      });
    }
  },

  renderPage() {
    const { tenant, page } = this.options;
    const waitForPageReady = () => {
      if (typeof $('#page')[0] !== 'undefined') {
        const title = capitalizeEachWord(page.replace(/_/g, ' '));
        ReactDOM.render(
          <ConfigurationPage
            superKudu={this.superKudu}
            tenant={tenant}
            pageId={page}
            title={title}
            email={this.email}
            back={() =>
              Backbone.history.navigate(
                `/org/${tenant}/evr_insights/${page}`,
                true
              )
            }
          />,
          $('#page')[0]
        );
      } else {
        setTimeout(waitForPageReady, 250);
      }
    };
    waitForPageReady();
  },

  render() {
    const html = template({});
    this.$el.html(html);
    this.renderPage();
    return this;
  },
});
