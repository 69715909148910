import Backbone from 'backbone';
import { BackboneViewProps } from '../types';

require('backbone.epoxy');

export default (Backbone as any).Epoxy.Model.extend({
  idAttribute: '_id',

  url() {
    return `${BONGO_URL}/v1/org/${this.tenant}/admin`;
  },

  initialize(properties: BackboneViewProps) {
    this.tenant = properties.tenant || this.tenant;
  },

  is_fetched() {
    return !!this.get('name');
  },

  defaults: {
    _id: '',
    domain: '',
    name: '',
    status: {},
  },

  computeds: {
    active: {
      get() {
        const status = this.get('status');
        return status && typeof status.active === 'boolean'
          ? status.active.toString()
          : 'true';
      },
      set(value: string) {
        const status = this.get('status') || {};
        status.active = value === 'true';
        return { status };
      },
    },

    load_only: {
      get() {
        const status = this.get('status');
        return status && typeof status.load_only === 'boolean'
          ? status.load_only.toString()
          : 'true';
      },
      set(value: string) {
        const status = this.get('status') || {};
        status.load_only = value === 'true';
        return { status };
      },
    },

    disable_batch: {
      get() {
        const status = this.get('status');
        return status && typeof status.disable_batch === 'boolean'
          ? status.disable_batch.toString()
          : 'true';
      },
      set(value: string) {
        const status = this.get('status') || {};
        status.disable_batch = value === 'true';
        return { status };
      },
    },
  },
});
