tailwind.config = {
  corePlugins: {
    preflight: false,
  },
  prefix: 'tw-',
  theme: {
    fontFamily: {
      sans: ['Inter, sans-serif'],
    },
    extend: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        black: '#000',
        white: '#fff',
        gray: {
          DEFAULT: '#4b5563',
          50: '#f9fafb',
          75: '#F0F3F6',
          100: '#e6ecf3',
          150: '#DEE5EE',
          200: '#CDD4DE',
          300: '#99A2B3',
          400: '#666E7F',
          600: '#374151',
          700: '#0D2342',
        },
        blue: {
          '50': '#d6efff',
          '100': '#c2e8ff',
          '200': '#a8dfff',
          '300': '#70cdff',
          '400': '#38bdff',
          '500': '#1fadff',
          '600': '#0aa1ff',
          '700': '#0096fa',
          '800': '#0062a8',
          '900': '#002e52',
        },
        cyan: {
          '50': '#bdfeff',
          '100': '#9ff8ff',
          '200': '#81f3fe',
          '300': '#47e8fa',
          '400': '#0fcde6',
          '500': '#00acc7',
          '600': '#0089a8',
          '700': '#07728d',
          '800': '#125368',
          '900': '#103947',
        },
        green: {
          '50': '#ddf9f6',
          '100': '#baf7ef',
          '200': '#9cf2e8',
          '300': '#5eded3',
          '400': '#38c2b9',
          '500': '#21aba7',
          '600': '#1a8e8c',
          '700': '#1b7474',
          '800': '#1c5f5f',
          '900': '#1c494a',
        },
        orange: {
          '50': '#fefbf5',
          '100': '#fef3e1',
          '200': '#fce6c5',
          '300': '#f9c580',
          '400': '#f6a146',
          '500': '#f4831f',
          '600': '#ea640b',
          '700': '#d75204',
          '800': '#a04118',
          '900': '#713114',
        },
        red: {
          '50': '#fffafa',
          '100': '#fff0f0',
          '200': '#fed7d8',
          '300': '#fdb0b1',
          '400': '#fb9398',
          '500': '#f84f5d',
          '600': '#e22840',
          '700': '#c8142f',
          '800': '#a9142f',
          '900': '#781228',
        },
      },
      backgroundColor: {
        light: '#f1f5f9',
        dark: '#131F40',
      },
      borderColor: {
        light: '#e5e7eb',
        dark: '#c9cdd4',
      },
      backgroundImage: {
        sidebar: 'linear-gradient(166deg, #172549 0%, #141F3D 92%)',
      },
      fontSize: {
        '2.5xl': ['1.675rem', { lineHeight: '2.25rem' }],
        md: ['0.975rem', { lineHeight: '1.6rem' }],
        xs: ['0.825rem', { lineHeight: '1.2rem' }],
        xxs: ['0.8rem', { lineHeight: '1.1rem' }],
      },
      boxShadow: {
        DEFAULT: '2px 2px 6px 0 rgba(32,38,77,0.09)',
        centered: '0 0 8px 0 rgba(32,38,77,0.09)',
      },
      minWidth: {
        '70': '70rem',
      },
      maxWidth: {
        '84': '84rem',
        '96': '96rem',
      },
      height: {
        '12.5': '3.15rem',
        '13': '3.25rem', // this one is not available by default
      },
      opacity: {
        '4': '0.04',
        '6': '0.06',
      },
      rotate: {
        '23': '23deg',
        '40': '40deg',
      },
      transitionProperty: {
        width: 'width',
      },
      keyframes: {
        // For dialog alert (radix) - maybe to be replaced with Transition
        overlayShow: {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
        contentShow: {
          from: { opacity: 0, transform: 'translate(-50%, -48%) scale(0.9)' },
          to: { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
        },
        slideDownAndFade: {
          from: { opacity: 0, transform: 'translateY(-2px)' },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        slideLeftAndFade: {
          from: { opacity: 0, transform: 'translateX(2px)' },
          to: { opacity: 1, transform: 'translateX(0)' },
        },
        slideUpAndFade: {
          from: { opacity: 0, transform: 'translateY(2px)' },
          to: { opacity: 1, transform: 'translateY(0)' },
        },
        slideRightAndFade: {
          from: { opacity: 0, transform: 'translateX(2px)' },
          to: { opacity: 1, transform: 'translateX(0)' },
        },
      },
      animation: {
        overlayShow: 'overlayShow 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        contentShow: 'contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideDownAndFade:
          'slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideLeftAndFade:
          'slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideUpAndFade: 'slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
        slideRightAndFade:
          'slideRightAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1)',
      },
    },
  },
};
