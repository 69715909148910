import React, { Component } from 'react';

export default class LeadsAndRevenueInNumbersComponent extends Component<
  {},
  {}
> {
  render() {
    return (
      <div className="col-sm-6">
        <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
          <div className="card-body">
            <i className="far fa-compass fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>Guide: </strong>
              Leads and Revenue in Numbers
            </h5>
            <div className="border-top mt-2 border-primary"></div>
            <i className="fas fa-flask fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>How MadKudu Computes This:</strong>
            </h5>
            <p>
              <strong>Leads:</strong> how many net new emails were captured in
              the last 6 months. Note that we count all leads and contacts in
              the systems you've integrated with MadKudu. See which systems have
              been integrated.
            </p>
            <p className="mt-2">
              <strong>Revenue: </strong>how much revenue did leads bring in
              through closed-won opportunities (Regardless of when the lead has
              been created).
            </p>
            <h5 className="mt-2">
              <strong>How to Interpret This:</strong>
            </h5>
            <p>
              Compare conversion rates and deal sizes by MadKudu predicted
              segment. There is a chance your very good leads might convert at a
              lower rate than some other segment but at a higher average value.
              This is meant to showcase how each metric plays a different role
              in the predictive model.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
