import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Swal from 'sweetalert2';
import { JobChangesWatchList } from '../types';

export function useEnabledJobChangesMutation() {
  return useMutation({
    mutationFn: async (tenant: number) =>
      axios.post<JobChangesWatchList>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/job_changes/enable`
      ),
    onSuccess: (_data, tenant) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Swal.fire({
        title: `Job changes has been enabled for tenant ${tenant}`,
        icon: 'success',
        text: 'They now have access to the feature and can start using it.',
      });
    },
  });
}
