import Backbone from 'backbone';
import getActiveInsightsPages from '../../evr_insights/utils';
import { BackboneViewProps } from '../../types';
import { swalWithBootstrapButtons } from '../../integrations/configure/utils';
import { createAsanaTask, slackNotification } from '../../utils';

const $ = require('jquery');
const template = require('./googlesheet.pug');

function highlight(subclass: string) {
  $('.sidebar__nav *').removeClass('active');
  $(`li.sidebar__nav--${subclass}`).addClass('active');
}

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #goL1_Leadsource': 'goL1_Leadsource',
    'click #go-integrations': 'go_integrations',
    'click #go-profiles': 'go_profiles',
    'click #go-predictions': 'go_predictions',
    'click #copy_clip_btn': 'copy_API_clipboard',
    'click #go-evr': 'go_evr',
    'click #beta-access_L3': 'go_beta_L3',
    'click #access_L1_leadsource': 'go_L1_leadsource',
  },

  goL1_Leadsource() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/l1_leadsource`,
      true
    );
  },

  go_integrations() {
    highlight('integrations');
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_predictions() {
    highlight('predictions');
    Backbone.history.navigate(`/org/${this.tenant}/predictions`, true);
  },

  go_profiles() {
    highlight('profiles');
    Backbone.history.navigate(`/org/${this.tenant}/profiles`, true);
  },

  go_evr() {
    highlight('insights');
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights`, true);
  },
  async go_beta_L3() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L3 Hand Raiser SLA`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_L1_leadsource() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L1 Leadsource report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async initialize(paramOptions: BackboneViewProps) {
    const options = paramOptions || {};
    this.tenant = options.tenant || this.tenant;
    this.activeInsightsPages = await getActiveInsightsPages(this.tenant);
    this.model.fetch({ success: () => this.render() });
  },

  copy_API_clipboard() {
    const result = this.model.attributes.api_key;
    const fake_area = document.createElement('textarea');
    document.body.appendChild(fake_area);
    fake_area.value = result;
    fake_area.select();
    document.execCommand('copy');
    document.body.removeChild(fake_area);
  },

  render() {
    this.copy_API_clipboard();
    const html = $(
      '#tpl_main',
      template({
        tenant: this.tenant,
        API_key: this.model.attributes.api_key,
        url: this.sheet_url,
        activeInsightsPages: this.activeInsightsPages,
      })
    ).html();
    this.$el.html(html);
    $('.carousel', this.$el).carousel();
    this.currentSlide = 0;
    return this;
  },
});
