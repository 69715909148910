import React from 'react';

type CardProps = {
  cardTitle: string;
  children: React.ReactNode;
};

export function Card({ cardTitle, children }: CardProps) {
  return (
    <div className="card box-shadow-3d">
      <div className="card-header">
        <div className="row justify-content-between">
          <div className="col-6 my-auto">
            <h5 className="mb-0">{cardTitle}</h5>
          </div>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
}
