var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (api_token_placeholder, credentialsFound) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E";
if (!credentialsFound) {
pug_html = pug_html + "\u003Cp\u003EConnect your Pipedrive account\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp\u003E\u003Cb\u003ESuccessfully connected to Pipedrive.\u003C\u002Fb\u003E\u003C\u002Fp\u003E\u003Cdiv class=\"row mb-2\"\u003E\u003Cp class=\"col-3\"\u003Ecan update your credentials below or\u003C\u002Fp\u003E\u003Cbutton class=\"btn btn-danger col-2\" id=\"mki_delete_connection\"\u003EDelete connection\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cform class=\"form-horizontal\" id=\"form_pipedrive\"\u003E\u003Cdiv class=\"row form-group\"\u003E\u003Cdiv class=\"col-sm-3\"\u003E\u003Clabel for=\"api_token\"\u003EPipedrive API Key\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-sm-6\"\u003E\u003Cinput id='#mki_input_api_token' class='form-control' type='text' name='api_token' placeholder=\"" + (pug.escape(null == (pug_interp = api_token_placeholder) ? "" : pug_interp)) + "\" data-bind='value:api_token' autocomplete='off'\u003E\u003C\u002Finput\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cbutton class=\"btn btn-success mr-2\" id=\"btn_save\"\u003ESave\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-light btn-save\" id=\"btn_cancel\"\u003ECancel\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003Cdiv\u003E\u003Ch3\u003EWhere do I get this information?\u003C\u002Fh3\u003E\u003Cp\u003ELog in to your \u003Ca href='http:\u002F\u002Fwww.pipedrive.com\u002F' target='_blank'\u003EPipedrive\u003C\u002Fa\u003E account.\u003C\u002Fp\u003E\u003Cp\u003EClick on your name in the upper right corner and choose \u003Cb\u003ESettings\u003C\u002Fb\u003E.\u003C\u002Fp\u003E\u003Cp\u003EClick on \u003Cb\u003EAPI\u003C\u002Fb\u003E in the submenu and copy the API token value\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"fields_mapping\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "api_token_placeholder" in locals_for_with ?
        locals_for_with.api_token_placeholder :
        typeof api_token_placeholder !== 'undefined' ? api_token_placeholder : undefined, "credentialsFound" in locals_for_with ?
        locals_for_with.credentialsFound :
        typeof credentialsFound !== 'undefined' ? credentialsFound : undefined));
    ;;return pug_html;};
module.exports = template;