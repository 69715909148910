import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import app from '../../app';
import BigQueryWorkbenchView from '../bigQuery/BigQueryWorkbenchView';
import SnowflakeWorkbenchView from '../snowflake/SnowflakeWorkbenchView';
import SegmentWorkbenchView from '../segment/SegmentWorkbenchView';
import { defaultQueryClient, localStoragePersister } from '../../query_client';

require('bootstrap-table/dist/bootstrap-table');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_run_query_salesforce': 'run_query',
    'keydown #mki_input_query': 'handleKeyDown',
    'click #btn_list_fields': 'display_fields',
    'click #btn_lookup_salesforce': 'salesforce_lookup',
    'click #btn_lookup_hubspot': 'hubspot_lookup',
    'click #go_admin': 'go_admin',
    'click #go_back': 'go_back',
    'click #go_back_integrations': 'go_back_integrations',
    'click #go_back_integration_board': 'go_back_integration_board',
    'click #hubspot-switch': 'toggle_hubspot_switch',

    'change .integration_drop_down': 'display_fields',
    'click .copy': 'copy_data',

    'click .nav-item': 'change_tab',
  },

  initialize(options: { tenant: number; integration: string }) {
    this.tenant = options.tenant;
    this.integration = options.integration;
    this.idOrEmail = false;
    this.handleKeyDown = this.handleKeyDown.bind(this);
  },

  async render() {
    // eslint-disable-next-line
    const template = require(`integrations/admin_pages/view_${this.integration}_workbench.pug`);
    const list_obj =
      this.integration === 'salesforce'
        ? [
            'Lead',
            'Contact',
            'Account',
            'Opportunity',
            'Task',
            'Custom',
            'Campaign',
            'CampaignMember',
          ]
        : ['Contacts', 'Deals', 'Companies'];
    const html = await $(
      '#tpl_main',
      template({
        tenant: this.tenant,
        integration: this.integration,
        list_objects: list_obj,
      })
    ).html();
    await this.$el.html(html);
    $('#section_id').hide();
    if (this.integration === 'salesforce') {
      $('#section_fields').hide();
    }
    if (this.integration === 'bigquery') {
      this.renderBigQueryIntegrationComponent();
    } else if (this.integration === 'snowflake') {
      this.renderSnowflakeIntegrationComponent();
    } else if (this.integration === 'segment') {
      this.renderSegmentIntegrationComponent();
    }
    return this;
  },

  change_tab(event: any) {
    const type = event.target.id.replace('nav-', '');
    $('#section_id').hide();
    $('#section_fields').hide();
    $('#section_query').hide();

    if (type === 'query') {
      $('#section_query').show();
      this.reset_tabs();
      document.getElementById('nav-query').classList.add('active-tab');
    } else if (type === 'fields') {
      $('#section_fields').show();
      this.reset_tabs();
      document.getElementById('nav-fields').classList.add('active-tab');
    } else {
      $('#section_id').show();
      this.reset_tabs();
      document.getElementById('nav-id').classList.add('active-tab');
    }
  },

  reset_tabs() {
    if (this.integration === 'salesforce') {
      document.getElementById('nav-query').classList.remove('active-tab');
    }
    document.getElementById('nav-fields').classList.remove('active-tab');
    document.getElementById('nav-id').classList.remove('active-tab');
  },

  filter(event: any, type: string) {
    const value = event.target.value.toLowerCase();
    let list_to_filter;

    if (type === 'query') {
      list_to_filter = this.list_query;
    } else if (type === 'fields') {
      list_to_filter = this.list_fields;
    }

    const filtered_list = [];
    const list_of_fields = Object.keys(list_to_filter[0]);

    // eslint-disable-next-line
    for (const elem of list_to_filter) {
      // eslint-disable-next-line
      for (const field of list_of_fields) {
        if (elem[field] && elem[field].toLowerCase().includes(value)) {
          filtered_list.push(elem);
          break;
        }
      }
    }
    $(`#result_tab_${type}`).bootstrapTable('load', filtered_list);
  },

  toggle_hubspot_switch() {
    if (this.idOrEmail) {
      document.getElementById('hubspot-switch-label').innerText = 'Email';
    } else {
      document.getElementById('hubspot-switch-label').innerText = 'ID';
    }
    this.idOrEmail = !this.idOrEmail;
  },

  async hubspot_lookup(event: any) {
    let url = '';
    const id = (document.getElementById('id_choice') as HTMLInputElement).value;

    if (this.idOrEmail) {
      url = `${BONGO_URL}/v1/org/${this.tenant}/integrations/hubspot/data/?id=${id}`;
    } else {
      url = `${BONGO_URL}/v1/org/${
        this.tenant
      }/integrations/hubspot/data/email?email=${encodeURIComponent(id)}`;
    }

    ReactDOM.render(
      <div className="loader" />,
      $('.div_loader_id', this.$el)[0]
    );
    event.preventDefault();
    try {
      const res = await axios.get(url, {});
      await this.display_json('id', res.data);
    } catch (e) {
      const err = e && e.response && e.response.data && e.response.data.message;
      Swal.fire({
        title: 'An error occured',
        text: err,
        icon: 'error',
      });
    }
    ReactDOM.render(<div />, $('.div_loader_id', this.$el)[0]);
  },

  get_fields(columns: UnknownObject[], list_fields: UnknownObject[], res: any) {
    // gets the fields to display
    columns.push(
      {
        field: 'name',
        title: 'Name',
        sortable: true,
      },
      {
        field: 'label',
        title: 'Label',
        sortable: true,
      },
      {
        field: 'type',
        title: 'Type',
        sortable: true,
      }
    );

    if (this.integration === 'hubspot') {
      columns.push(
        {
          field: 'groupName',
          title: 'Group Name',
          sortable: true,
        },
        {
          field: 'fieldType',
          title: 'Field Type',
          sortable: true,
        }
      );
    }

    // eslint-disable-next-line
    for (const field of res.data.fields) {
      const obj: UnknownObject = {};
      obj.name = field.name;
      obj.label = field.label;
      obj.type = field.type;

      if (this.integration === 'hubspot') {
        // HubSpot has a couple of extra fields which may be useful to display
        obj.groupName = field.groupName;
        obj.fieldType = field.fieldType;
      }

      list_fields.push(obj);
    }
  },

  async display_fields(event: any) {
    ReactDOM.render(
      <div className="loader" />,
      $('.div_loader_fields', this.$el)[0]
    );
    event.preventDefault();

    let object = (document.getElementById(
      'object_field_selector_fields'
    ) as HTMLSelectElement).value;
    if (object === 'Custom') {
      // shows the custom input bar without making an API call
      if ($('#choice_field_selector_fields').is(':hidden')) {
        document
          .getElementById('choice_field_selector_fields')
          .removeAttribute('hidden');
        ReactDOM.render(<div />, $('.div_loader_fields', this.$el)[0]);
        return;
      }
      object = (document.getElementById(
        'choice_field_selector_fields'
      ) as HTMLInputElement).value;
    } else if (this.integration === 'salesforce') {
      document
        .getElementById('choice_field_selector_fields')
        .setAttribute('hidden', '');
    } else if (this.integration === 'hubspot') {
      object = object.toLowerCase();
    }

    try {
      const res = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/data/${object}/fields`
      );
      const list_fields: UnknownObject[] = [];
      const columns: UnknownObject[] = [];

      this.get_fields(columns, list_fields, res);
      this.list_fields = list_fields;

      await this.display_table('fields', this.list_fields, columns, res.data);
    } catch (e) {
      const err = e && e.response && e.response.data && e.response.data.message;
      Swal.fire({
        title: 'An error occured',
        text: err,
        icon: 'error',
      });
    }
    ReactDOM.render(<div />, $('.div_loader_fields', this.$el)[0]);
  },

  copy_data() {
    const result = this.json_data;
    const fake_area = document.createElement('textarea');
    document.body.appendChild(fake_area);
    fake_area.value = result;
    fake_area.select();
    document.execCommand('copy');
    document.body.removeChild(fake_area);
  },

  back(event: any) {
    event.preventDefault();
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}`,
      true
    );
  },

  go_admin() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}`,
      true
    );
  },
  go_back() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/admin`,
      true
    );
  },
  go_back_integrations() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_back_integration_board() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations/`, true);
  },

  // recursive function that decompose a complex object into a simple object
  // example: { name: {first_name: Paul, second_name: Charles}, age: 30}
  // TRANSFORM INTO {first_name: Paul, second_name: Charles, age: 30}
  decompose(obj: UnknownObject, map: UnknownObject, name: string) {
    let object = obj;
    if (typeof map === 'object' && map) {
      const list_keys = Object.keys(map);
      // eslint-disable-next-line
      for (const key of list_keys) {
        object = this.decompose(object, map[key], key);
      }
    } else if (name !== 'url') {
      object[name] = map;
    }
    return object;
  },

  handleKeyDown(event: any) {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      this.run_query();
    }
  },

  async run_query(event: any) {
    ReactDOM.render(
      <div className="loader" />,
      $('.div_loader_query', this.$el)[0]
    );
    event.preventDefault();
    const query = (document.getElementById(
      'mki_input_query'
    ) as HTMLTextAreaElement).value;

    try {
      const res = await axios.post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/salesforce/query`,
        { item: { query } }
      );
      const list = res.data.records;

      if (list.length > 0) {
        const list_final = [];
        // eslint-disable-next-line
        for (const l of list) {
          // get all the fields of the object
          let new_elem = {};
          new_elem = this.decompose(new_elem, l, list);
          list_final.push(new_elem);
        }
        const list_keys = Object.keys(list_final[0]);
        const columns = [];

        // eslint-disable-next-line
        for (const key of list_keys) {
          // prepare columns for bootstrap-table
          columns.push({
            field: key,
            title: key,
            sortable: true,
          });
        }
        this.list_query = list_final;
        await this.display_table('query', this.list_query, columns, res.data);
      } else {
        Swal.fire({
          title: 'No result',
          text: 'The query response was empty',
          icon: 'info',
        });
      }
    } catch (e) {
      const err = e && e.response && e.response.data && e.response.data.message;
      Swal.fire({
        title: 'An error occured',
        text: err,
        icon: 'error',
      });
    }
    ReactDOM.render(<div />, $('.div_loader_query', this.$el)[0]);
  },

  async salesforce_lookup(event: any) {
    ReactDOM.render(
      <div className="loader" />,
      $('.div_loader_id', this.$el)[0]
    );
    event.preventDefault();
    let object = (document.getElementById(
      'object_field_selector_id'
    ) as HTMLSelectElement).value;
    if (object === 'Custom')
      object = (document.getElementById(
        'choice_field_selector_id'
      ) as HTMLSelectElement).value;
    const id = (document.getElementById('id_choice') as HTMLInputElement).value;
    try {
      const res = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/salesforce/data/${object}/${id}`,
        {}
      );
      await this.display_json('id', res.data);
    } catch (e) {
      const err = e && e.response && e.response.data && e.response.data.message;
      Swal.fire({
        title: 'An error occured',
        text: err,
        icon: 'error',
      });
    }
    ReactDOM.render(<div />, $('.div_loader_id', this.$el)[0]);
  },

  async display_table(
    type: string,
    list: UnknownObject[],
    columns: UnknownObject[],
    total: number
  ) {
    $(`#result_tab_${type}`).bootstrapTable('destroy');
    $(`#result_tab_${type}`).bootstrapTable('load', list);
    ReactDOM.render(
      <div>
        <p>Filter: </p>
        <input
          className="custom-input"
          type="text"
          placeholder="Search..."
          onChange={(event) => this.filter(event, type)}
        />
      </div>,
      $(`#input_filter_${type}`, this.$el)[0]
    );

    this.table = await $(`#result_tab_${type}`, this.$el).bootstrapTable({
      columns,
      data: list,
      pagination: true,
      pageSize: 100,
    });
    this.display_json(type, total);
  },

  display_json(type: string, data: UnknownObject[]) {
    const text = JSON.stringify(data);
    this.json_data = text;

    if (type === 'id') {
      ReactDOM.render(
        <div style={{ width: '100%' }}>
          <div>
            <h3>JSON</h3>
          </div>
          <button className="copy">Copy JSON</button>
          <textarea
            disabled
            value={JSON.stringify(data, null, 10)}
            style={{ width: '100%', height: '600px' }}
          />
        </div>,
        $(`#result_json_${type}`, this.$el)[0]
      );
    }
  },

  save(event: any) {
    event.preventDefault();
    this.model.save(null, {
      success() {
        app.notifications.success('Configuration updated successfully.', {
          dismiss_timeout: 3000,
        });
      },
      error() {
        Swal.fire('Oops!', 'There was an error saving your config', 'error');
      },
    });
  },

  renderBigQueryIntegrationComponent() {
    const containerDomElement = $('#BigQueryWorkbenchViewContainer')[0];
    if (typeof containerDomElement !== 'undefined') {
      const component = (
        <BigQueryWorkbenchView tenant={this.tenant}></BigQueryWorkbenchView>
      );
      ReactDOM.render(component, containerDomElement);
    }
  },

  renderSnowflakeIntegrationComponent() {
    const containerDomElement = $('#SnowflakeWorkbenchViewContainer')[0];
    if (typeof containerDomElement !== 'undefined') {
      const component = (
        <SnowflakeWorkbenchView tenant={this.tenant}></SnowflakeWorkbenchView>
      );
      ReactDOM.render(component, containerDomElement);
    }
  },

  renderSegmentIntegrationComponent() {
    const containerDomElement = $('#SegmentWorkbenchView')[0];
    if (typeof containerDomElement !== 'undefined') {
      const component = (
        <PersistQueryClientProvider
          client={defaultQueryClient}
          persistOptions={{ persister: localStoragePersister }}
        >
          <SegmentWorkbenchView tenant={this.tenant}></SegmentWorkbenchView>
        </PersistQueryClientProvider>
      );
      ReactDOM.render(component, containerDomElement);
    }
  },
});
