import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useDisconnectSlack(tenant: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await axios.delete(`${BONGO_URL}/v1/org/${tenant}/integrations/slack`);
    },
    onSuccess: () => {
      queryClient.setQueryData(['slack', tenant], false);
      queryClient.setQueryData(['slack-subscriptions', tenant], []);
      queryClient.setQueryData(['slack-channels', tenant], []);
    },
  });
}
