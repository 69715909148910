import Backbone, { $ } from 'backbone';

const template = require('./gdpr_request.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #summit_GDPR_request': 'submitRequest',
  },

  render() {
    this.$el.html(template());
    return this;
  },

  validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  },

  showError(message: string) {
    const errorElement = $('#error-message');
    errorElement.text(message).show();
  },

  hideError() {
    $('#error-message').hide();
  },

  async submitRequest(e: any) {
    e.preventDefault();
    this.hideError();

    const emailRequester = $('#email-requester').val() as string;
    const emailToDelete = $('#email-to-delete').val() as string;

    if (
      !this.validateEmail(emailRequester) ||
      !this.validateEmail(emailToDelete)
    ) {
      this.showError('Please enter valid email addresses for both fields.');
      return;
    }

    const requestData = {
      email_asking_for_a_deletion: emailRequester,
      email_to_delete: emailToDelete,
      request: $('#request').val(),
      description: $('#description').val(),
    };

    try {
      const response = await fetch(
        'https://hooks.zapier.com/hooks/catch/1833600/a1j071/',
        {
          method: 'POST',
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      $('#form-section').hide();
      $('#success-section').show();
    } catch (error) {
      this.showError(
        'An error occurred while submitting your request. Please try again later.'
      );
    }
  },
});
