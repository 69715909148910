import React from 'react';
import Select from 'react-select';
import lowerCase from 'lodash/lowerCase';

import { Models, RoundLgYaml, RoundLtbYaml } from '../types';

type AdvancedConfigurationProps = {
  handleScoresChangeLtb: (name: string, value: number) => void;
  handleScoresChangeLg: (name: string, value: number) => void;
  roundLtbYaml: RoundLtbYaml;
  roundLgYaml: RoundLgYaml;
  handleYamlPickedModel: Function;
  yamlPickedModel: any;
  pickedModel: Models;
};

export default class AdvancedConfiguration extends React.Component<
  AdvancedConfigurationProps
> {
  render() {
    const {
      pickedModel,
      yamlPickedModel,
      handleYamlPickedModel,
      roundLgYaml,
      roundLtbYaml,
      handleScoresChangeLg,
      handleScoresChangeLtb,
    } = this.props;
    return (
      <div className="row mt-2">
        <div className="col p-2">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-2">Advanced configuration</div>
              </div>
            </div>
            <div className="card-body p-4 mt-2 mb-2">
              <div className="row">
                <div className="col mb-3">
                  <div className="card">
                    <div className="card-header">
                      When should a record be updated?
                    </div>
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col">
                          Specify which variation of score should trigger a
                          record update. This would help limit the number of API
                          calls to your system by trigeering only relevant
                          variations
                        </div>
                      </div>
                      {pickedModel.likelihoodToBuy && (
                        <>
                          <div className="row mb-1">
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(e: any) =>
                                    handleYamlPickedModel(e)
                                  }
                                  id="round_behavioral_scores"
                                  checked={
                                    yamlPickedModel.round_behavioral_scores
                                  }
                                />
                                <label
                                  className="form-check-label font-weight-bold"
                                  htmlFor="defaultCheck1"
                                >
                                  Round Likelihood to Buy Score
                                </label>
                              </div>
                            </div>
                          </div>
                          {yamlPickedModel.round_behavioral_scores && (
                            <>
                              {[
                                'already_paying',
                                'very_high',
                                'high',
                                'medium',
                                'low',
                              ].map((ltb) => {
                                const key = `modulo_to_round_${ltb}_behavioral_scores` as keyof RoundLtbYaml;
                                const ltbValue = roundLtbYaml[key];
                                return (
                                  <div key={key} className="row mb-1">
                                    <div className="col-4">
                                      Within the {lowerCase(ltb)} segment, for
                                      any variation of
                                    </div>
                                    <div className="col-2">
                                      <Select
                                        value={{
                                          label: ltbValue,
                                          value: ltbValue,
                                        }}
                                        options={[1, 5, 10].map((value) => ({
                                          label: value,
                                          value,
                                        }))}
                                        onChange={({ value }) => {
                                          handleScoresChangeLtb(
                                            key,
                                            Number(value)
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-2">of score</div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                      {pickedModel.leadGrade && (
                        <>
                          <div className="row mb-1">
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(e: any) =>
                                    handleYamlPickedModel(e)
                                  }
                                  id="round_lead_grade_scores"
                                  checked={
                                    yamlPickedModel.round_lead_grade_scores
                                  }
                                />
                                <label
                                  className="form-check-label font-weight-bold"
                                  htmlFor="defaultCheck1"
                                >
                                  Round Lead Grades scores
                                </label>
                              </div>
                            </div>
                          </div>
                          {yamlPickedModel.round_lead_grade_scores && (
                            <>
                              {['A', 'B', 'C', 'D', 'E'].map((lg) => {
                                const key = `modulo_to_round_${lg}_grades` as keyof RoundLgYaml;
                                const lgValue = roundLgYaml[key];
                                return (
                                  <div key={key} className="row mb-1">
                                    <div className="col-4">
                                      Within the {lg} segment, for any variation
                                      of
                                    </div>
                                    <div className="col-2">
                                      <Select
                                        value={{
                                          label: lgValue,
                                          value: lgValue,
                                        }}
                                        options={[1, 5, 10].map((value) => ({
                                          label: value,
                                          value,
                                        }))}
                                        onChange={({ value }) => {
                                          handleScoresChangeLg(
                                            key,
                                            Number(value)
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-2">of score</div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
