// eslint-disable-next-line import/no-cycle
import conditionFormData from '../../../models/conditionFormData';
import {
  convertToMkEventStyle,
  getEventsValuesFieldsByConnector,
} from '../components/generic/utils';
import { ACTIVITY_TYPE, CONNECTORS_NAMES, HUBSPOT_EVENTS } from '../constants';

// "event" and "eventGroup" properties are added dynamically in getFormData
const defaultBoilerplate = {
  nonUserActivity: true, // Unchecked
  mkEventName: 'Event',
  mkEventNameSignals: 'Event',
  activityType: ACTIVITY_TYPE[2], // Web Activity
  excludeFromMapping: false, // unchecked
};

function getBoilerplate(connector: string) {
  if (connector === CONNECTORS_NAMES.salesforce_campaigns) {
    const campaignMemberStatus = 'Registered';
    const mkEventNameSignals = 'Registered to Webinar';
    const mkEventName = 'Registered to Webinar';
    // add a salesforce special field
    return {
      ...defaultBoilerplate,
      mkEventNameSignals,
      mkEventName,
      campaignMemberStatus,
      conditionFormData,
    };
  }
  // else, return the default one
  return {
    ...defaultBoilerplate,
    conditionFormData,
  };
}

/**
 * Takes connector as an argument and returns form row data
 * @param {string} connector - 'analytic' | 'marketo' | 'salesforce'
 * @param {object} nonStandardChanges - custom changes to the form data object, applied after event/eventGroup
 * @param tenant
 */
async function getFormData(
  connector: string,
  nonStandardChanges: UnknownObject,
  tenant = 3327,
  tasksList: string[] = []
) {
  // Transform to  connector === (CONNECTORS_NAMES.marketo || CONNECTORS_NAMES.marketo.salesforce) When the Campaign Type field become a drop down menu
  const arrayOfEvents =
    connector === CONNECTORS_NAMES.marketo
      ? await getEventsValuesFieldsByConnector(connector, tenant)
      : [];
  let event = arrayOfEvents[0] ? arrayOfEvents[0] : 'event';

  const eventGroup = `${connector[0].toUpperCase()}${connector.slice(1)}`;
  const boilerplate = getBoilerplate(connector);

  if (connector === CONNECTORS_NAMES.hubspot) {
    [event] = HUBSPOT_EVENTS;
    const cleanedEventName = convertToMkEventStyle(event);
    boilerplate.mkEventName = cleanedEventName;
    boilerplate.mkEventNameSignals = cleanedEventName;
  }

  if (connector === CONNECTORS_NAMES.salesforce_tasks) {
    // eslint-disable-next-line no-param-reassign
    nonStandardChanges = {
      ...nonStandardChanges,
      mkEventNameSignals: tasksList[0],
      mkEventName: tasksList[0],
      event: tasksList[0],
      activityType: ACTIVITY_TYPE[3], // Sales Activity
    };
  }

  return {
    ...boilerplate,
    event,
    eventGroup,
    ...nonStandardChanges,
  };
}

export default getFormData;
