import axios from 'axios';
import Backbone from 'backbone';
import Swal from 'sweetalert2';
import getQueryParameter from './get_query_parameter';

const template = require('./reset_password.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #mki_reset_password': 'reset',
  },

  render() {
    this.$el.html(template());
    return this;
  },

  async reset(e: any) {
    e.preventDefault();
    const token = getQueryParameter('token');
    const email = getQueryParameter('email');
    const form_data: UnknownObject = { email, token };
    $.each($('form#resetform').serializeArray(), (index, field) => {
      form_data[field.name] = field.value;
      // eslint-disable-next-line
      field.value = ''; // https://app.asana.com/0/33091180320398/1118497934278433/f
    });

    try {
      await axios.put(`${BONGO_URL}/v1/reset`, form_data);
      window.analytics.track('Reset password', { email });
      Backbone.history.navigate(`/login?reset=yes&email=${email}`, {
        trigger: true,
      });
    } catch (err) {
      const { data } = err.response;
      const { message } = data;
      Swal.fire({
        title: 'Ooops...',
        text: message,
        icon: 'error',
      });
    }
  },
});
