import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { ChartGroupDefinition } from '../../InsightsTypes';

type InnerChartConfigurationComponentProps = {
  tenant: number;
  chartName: string;
  chartDefinition: ChartGroupDefinition;
  cancelConfigurationMode: () => void;
  cancelConfigurationModeAndRefresh: () => void;
};

type InnerChartConfigurationComponentState = {
  saving: boolean;
  error: boolean;
  newChartGroupDefinition: ChartGroupDefinition;
};

const INPUT_NUMBER: string = 'Input a number.';

export default class InnerChartConfigurationComponent extends React.Component<
  InnerChartConfigurationComponentProps,
  InnerChartConfigurationComponentState
> {
  constructor(props: InnerChartConfigurationComponentProps) {
    super(props);
    this.handleChangeProperty = this.handleChangeProperty.bind(this);
    this.saveNewConfiguration = this.saveNewConfiguration.bind(this);
    this.state = {
      saving: false,
      error: null,
      newChartGroupDefinition: cloneDeep(this.props.chartDefinition),
    };
  }

  async saveNewConfiguration() {
    this.setState({
      saving: true,
      error: false,
    });
    try {
      const {
        tenant,
        chartName,
        cancelConfigurationModeAndRefresh,
      } = this.props;
      const { newChartGroupDefinition } = this.state;
      const url = `${BONGO_URL}/v1/org/${tenant}/insights/chart/${chartName}/configuration`;

      // filter chart group definition to keep only id and properties
      const filteredObject: ChartGroupDefinition = {
        id: newChartGroupDefinition.id,
        charts: newChartGroupDefinition.charts.map((c) => ({
          properties: c.properties,
        })),
      };

      await axios.post(url, filteredObject);

      cancelConfigurationModeAndRefresh();
    } catch (err) {
      this.setState({
        error: true,
      });
    }
  }

  handleChangeProperty(
    chartIdx: number,
    propertyName: string,
    newValue: string
  ) {
    const { newChartGroupDefinition } = this.state;
    newChartGroupDefinition.charts[chartIdx].properties.find(
      (property) => property.name === propertyName
    ).value = newValue;
    this.setState({ newChartGroupDefinition });
  }

  render() {
    const { cancelConfigurationMode } = this.props;
    const { saving, error, newChartGroupDefinition } = this.state;
    const propertiesAreAllSet = newChartGroupDefinition.charts?.every((chart) =>
      chart.properties?.every((p) => p.value?.length)
    );
    return (
      <React.Fragment>
        {error && (
          <Alert bsStyle="error">
            The configuration cannot be saved, please verify the form.
          </Alert>
        )}
        {newChartGroupDefinition.charts.map((chartDefinition, i) => {
          return (
            <React.Fragment key={i}>
              {(chartDefinition.properties || [])
                .filter((c) => c.customizable)
                .map((property) => {
                  return (
                    <form key={property.name} className="row col mb-2">
                      <div className="form-group row">
                        <label
                          className="col-md-auto col-form-label font-weight-bold"
                          style={{
                            fontSize: '1em',
                          }}
                        >
                          {property.label}:
                        </label>
                      </div>
                      <div className="col-7">
                        <input
                          className="col-sm-10 form-control"
                          name={property.name}
                          type={
                            property.inputType === 'number' ? 'number' : 'text'
                          }
                          value={property.value}
                          onChange={(e) =>
                            this.handleChangeProperty(
                              i,
                              property.name,
                              (e.target as any).value
                            )
                          }
                        ></input>
                        {property.inputType === 'number' ? (
                          <small className="form-text text-muted">
                            {INPUT_NUMBER}
                          </small>
                        ) : null}
                      </div>
                    </form>
                  );
                })}
              {i < newChartGroupDefinition.charts.length - 1 && <hr />}
            </React.Fragment>
          );
        })}
        <Button
          bsStyle="primary"
          onClick={this.saveNewConfiguration}
          disabled={!propertiesAreAllSet || saving}
          className="mr-1"
        >
          {saving ? 'Saving...' : 'Save'}
        </Button>
        {!saving && (
          <Button bsStyle="danger" onClick={cancelConfigurationMode}>
            Cancel
          </Button>
        )}
      </React.Fragment>
    );
  }
}
