import React from 'react';
import capitalize from 'lodash/capitalize';
import axios from 'axios';
import OverviewComponent from './OverviewComponent';
import ConfigurationComponent from './ConfigurationComponent';
import app from '../../../app';

export type TabValues = 'overview' | 'configuration';

type Connector = 'amplitude' | 'segment' | null;

type IdentificationParentComponentProps = {
  tenant: number;
  getActivePage: () => TabValues;
  toggleView: Function;
};

type IdentificationParentComponentState = {
  activeTab: TabValues;
  tabNames: TabValues[];
  enabled: boolean;
  isLoading: boolean;
  connectorsEnabled: { amplitude: boolean; segment: boolean };
  connector: Connector;
};

export default class IdentificationParentComponent extends React.Component<
  IdentificationParentComponentProps,
  IdentificationParentComponentState
> {
  constructor(props: IdentificationParentComponentProps) {
    super(props);

    const currentView = props.getActivePage();

    this.state = {
      activeTab: currentView,
      tabNames: ['overview', 'configuration'],
      isLoading: true,
      enabled: false,
      connectorsEnabled: { amplitude: false, segment: false },
      connector: null,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.updateEnabled = this.updateEnabled.bind(this);
    this.updateConnector = this.updateConnector.bind(this);
  }

  superKudu = app.session.get('user').superKudu;

  async componentDidMount() {
    const { toggleView } = this.props;
    const { activeTab, tabNames } = this.state;

    this.setState({ tabNames });
    toggleView(activeTab);
    await this.getStatus();
  }

  getStatus = async () => {
    this.setState({ isLoading: true });
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${this.props.tenant}/integrations`
    );
    const segmentEnabled =
      data.find((integration: any) => integration.name === 'segment')?.pull
        ?.active || false;

    const amplitudeEnabled =
      data.find((integration: any) => integration.name === 'amplitude')?.pull
        ?.active || false;

    const { data: settings } = await axios.get(
      `${BONGO_URL}/v1/org/${this.props.tenant}`
    );

    this.setState({
      isLoading: false,
      enabled: settings.account_id_enabled,
      connectorsEnabled: {
        segment: segmentEnabled,
        amplitude: amplitudeEnabled,
      },
    });
  };

  updateConnector(newConnector: Connector) {
    if (this.state.enabled && !this.state.connectorsEnabled[newConnector]) {
      return false;
    }

    this.setState({ connector: newConnector });
  }

  updateEnabled = async (newStatus: boolean) => {
    if (!this.superKudu) return false;

    this.setState({ enabled: newStatus });

    const { data } = await axios.put(
      `${BONGO_URL}/v1/org/${this.props.tenant}`,
      {
        account_id_enabled: newStatus,
      }
    );
    return data;
  };

  handleTabClick = (tab: TabValues) => {
    this.setState({ activeTab: tab });
    const { toggleView } = this.props;
    return toggleView(tab);
  };

  render() {
    const { tabNames, activeTab } = this.state;
    const { tenant } = this.props;

    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
          <ul className="nav nav-pills mx-auto">
            {tabNames
              // TODO: remove this filter when MadKudu Account identification is ready
              .filter((tabName) => tabName !== 'configuration')
              .map((tabName) => (
                <li className="nav-item" key={tabName}>
                  <a
                    className={`nav-link ${
                      activeTab === tabName ? 'active show' : ''
                    }`}
                    id={tabName}
                    data-toggle="tab"
                    href={`#${tabName}`}
                    onClick={() => this.handleTabClick(tabName)}
                    role="tab"
                  >
                    {capitalize(tabName)}
                  </a>
                </li>
              ))}
          </ul>
        </nav>

        {tabNames.map((tabName, index) => (
          <div className="row ml-4" key={`tab_content_${index}`}>
            <div className="tab-content w-100" id="TabContent">
              <div
                key={`tab_content_${tabName}`}
                className={`tab-pane fade ${
                  activeTab === tabName ? 'active show' : ''
                }`}
                id={`${tabName}-tab`}
                role="tabpanel"
              >
                <div className="row justify-content-center">
                  <div className="col-8 mt-4 mb-4">
                    <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                      <div className="card-header">
                        <div className="d-flex align-items-center">
                          <img
                            className="con-map-mk-integration-logo-size"
                            style={{ width: '30px' }}
                            src={`/media/integrations/logos/madkudu_simple.png`}
                          />
                          <h5 className="mb-0 ml-2">
                            MadKudu Account Identification
                          </h5>
                          <span className="rounded-pill px-2 py-1 bg-teal text-white d-inline-block ml-2 font-weight-bold">
                            Coming soon
                          </span>
                        </div>
                      </div>
                      <div className="card-body">
                        {tabName === 'configuration' && (
                          <ConfigurationComponent
                            tenant={tenant}
                            enabled={this.state.enabled}
                            setEnabled={this.updateEnabled}
                            loading={this.state.isLoading}
                            connector={this.state.connector}
                            updateConnector={this.updateConnector}
                          />
                        )}
                        {tabName === 'overview' && (
                          <OverviewComponent
                            tenant={tenant}
                            loading={this.state.isLoading}
                            enabled={this.state.enabled}
                            handleTabClick={this.handleTabClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}
