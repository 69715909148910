import { BigQueryAccessTokens } from '../types';

export type BigQueryAccessRequest = {
  tenant: number;
  timeout?: number;
  width?: number;
  height?: number;
};

async function handleTimeout(timeout: number) {
  return new Promise<void>((_, reject) =>
    setTimeout(() => reject(new Error('Timeout')), timeout)
  );
}

async function handleWindowClose(win: Window) {
  const timeout = 1000;
  let timerId: any;
  return new Promise((_, reject) => {
    timerId = setInterval(() => {
      if (win.closed) {
        reject(new Error('Window closed'));
      }
    }, timeout);
  }).finally(() => {
    if (timerId) {
      clearInterval(timerId);
    }
  });
}

export async function loadBigQueryAccessTokens({
  tenant,
  timeout = 60 * 1000,
  width = 450,
  height = 600,
}: BigQueryAccessRequest): Promise<BigQueryAccessTokens> {
  let wnd: Window | null = null;
  const cleanupMethods: Function[] = [];
  const url = `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/oauth/redirect`;
  wnd = window.open(
    url,
    'mozillaWindow',
    `popup,width=${width},height=${height}`
  );
  cleanupMethods.push(() => wnd && wnd.close && wnd.close());
  wnd.focus();
  const promise = Promise.race([
    // Wait for the message from the popup
    new Promise((resolve) => {
      async function onMessage(ev: MessageEvent) {
        if (ev.data.target === 'bigqueryAuthPopup') {
          window.removeEventListener('message', onMessage, true);
          resolve(ev.data.data);
        }
      }
      // Remove the message listener when the promise is resolved
      // (to prevent memory leaks)
      cleanupMethods.push(() =>
        window.removeEventListener('message', onMessage, true)
      );
      window.addEventListener('message', onMessage, true);
    }),

    // Interrupt the process if timeout is reached
    handleTimeout(timeout),

    // Throw an error if window is closed
    handleWindowClose(wnd),
  ]) as Promise<BigQueryAccessTokens>;
  // Cleanup all resources after promise is resolved
  promise.finally(() => cleanupMethods.forEach((fn) => fn()));
  return promise;
}
