import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Select from 'react-select';

import { ConversionMappingData } from '../../../models/ConversionMappingData';
import { ConversionMappingConnectorsNames } from '../../../../../types';
import { unslugFieldName } from '../../../../pullConfigManager';
import { DATASOURCE_OBJECTS } from '../../../../utils';
import MissingFieldMicrocopy from '../../../../../components/ErrorMicroCopyComponent';
import {
  FIELD_PLACEHOLDER_VALUE,
  OBJECT_PLACEHOLDER_VALUE,
} from '../../../../constants';

type GenericFormComponentProps = {
  conversionMappingData: ConversionMappingData;
  connector: ConversionMappingConnectorsNames;
  setIsConfigured: () => void;
  handleConnectorTypeFilterValues: (
    c: string,
    conn: ConversionMappingConnectorsNames
  ) => void;
  handleConnectorAmountFieldValue: (
    c: string,
    conn: ConversionMappingConnectorsNames
  ) => void;
  handleConnectorAmountFieldTable: (
    c: string,
    conn: ConversionMappingConnectorsNames
  ) => void;
};

export default class GenericFormComponent extends React.Component<
  GenericFormComponentProps,
  {}
> {
  constructor(props: GenericFormComponentProps) {
    super(props);
    // BINDS
    this.handleConnectorTypeFilterValues = this.handleConnectorTypeFilterValues.bind(
      this
    );
    this.handleConnectorAmountFieldValue = this.handleConnectorAmountFieldValue.bind(
      this
    );
    this.handleConnectorAmountFieldTable = this.handleConnectorAmountFieldTable.bind(
      this
    );
  }

  async handleConnectorTypeFilterValues(e: any) {
    this.props.handleConnectorTypeFilterValues(
      e.target.value,
      this.props.connector
    );
    this.props.setIsConfigured();
  }

  handleConnectorAmountFieldValue(value: string) {
    this.props.handleConnectorAmountFieldValue(value, this.props.connector);
    this.props.setIsConfigured();
  }

  handleConnectorAmountFieldTable(value: string) {
    this.props.handleConnectorAmountFieldTable(value, this.props.connector);
    this.props.setIsConfigured();
  }

  render() {
    const { conversionMappingData, connector } = this.props;

    const indexedConversionTypeOfConnector = conversionMappingData
      .getAllStandardIndexedConversionTypesData()
      .find((indexedConversionType) => {
        return indexedConversionType.conversionTypeData.connector === connector;
      });
    const conversionTypeOfConnector =
      indexedConversionTypeOfConnector.conversionTypeData;

    const errors = conversionMappingData.getErrorsByLevelsAndConnector(
      ['amount field invalid value', 'amount field invalid table'],
      connector
    );
    const hasTableError = errors.some(
      (error) => error.level === 'amount field invalid table'
    );
    const hasValueError = errors.some(
      (error) => error.level === 'amount field invalid value'
    );

    return (
      <div>
        <form>
          {connector !== 'stripe' && (
            <FormGroup controlId={`${connector}_type_filter_values`}>
              <ControlLabel>
                What type of deal would you like to predict? (deal type - comma
                seperated)
              </ControlLabel>
              <ControlLabel className="font-weight-lighter font-italic ml-1">
                - can be empty
              </ControlLabel>
              <FormControl
                type="text"
                value={conversionTypeOfConnector.filterTypesValues?.join(',')}
                placeholder="Enter text"
                onChange={this.handleConnectorTypeFilterValues}
              />
              <FormControl.Feedback />
            </FormGroup>
          )}
          <FormGroup controlId={`${connector}_amount_field`}>
            <ControlLabel>
              What field identifies the deals amount on the deal object?
            </ControlLabel>
            <div className="d-flex justify-content-between">
              <div className="row d-flex w-25 flex-column">
                <div className="col">
                  <Select
                    value={{
                      label: conversionTypeOfConnector.amountField.table,
                      value: conversionTypeOfConnector.amountField.table,
                    }}
                    placeholder={OBJECT_PLACEHOLDER_VALUE}
                    options={DATASOURCE_OBJECTS[connector].map((object) => ({
                      label: object,
                      value: object,
                    }))}
                    onChange={({ value }) => {
                      this.handleConnectorAmountFieldTable(value);
                    }}
                  />
                </div>
                <div className="col">
                  {hasTableError && (
                    <MissingFieldMicrocopy microcopy="Please select a valid table value." />
                  )}
                </div>
              </div>
              <div className="row d-flex w-75 flex-column">
                <div className="col">
                  <Select
                    value={
                      conversionTypeOfConnector.amountField.value && {
                        label: unslugFieldName(
                          conversionTypeOfConnector.amountField.value
                        ),
                        value: conversionTypeOfConnector.amountField.value,
                      }
                    }
                    placeholder={FIELD_PLACEHOLDER_VALUE}
                    options={conversionTypeOfConnector
                      .getFields(conversionTypeOfConnector.amountField.table)
                      .map(({ name }) => ({
                        label: unslugFieldName(name),
                        value: name,
                      }))}
                    onChange={({ value }) => {
                      this.handleConnectorAmountFieldValue(value);
                    }}
                  />
                </div>
                <div className="col">
                  {hasValueError && (
                    <MissingFieldMicrocopy microcopy="Please select a valid field value." />
                  )}
                </div>
              </div>
            </div>
            <FormControl.Feedback />
          </FormGroup>
        </form>
      </div>
    );
  }
}
