import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import Swal from 'sweetalert2';
import { BackboneViewProps } from '../types';

const channel = Radio.channel('integration');
const template = require('./zendesk.pug');

export default Backbone.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'zendesk';
    this.render();
  },

  async render() {
    this.$el.empty();

    let data;

    try {
      data = (
        await axios.get(
          `${BONGO_URL}/v1/org/${this.tenant}/integrations/zendesk/credentials`
        )
      ).data;
    } catch (e) {
      if (e.response.status === 404) {
        data = null;
      } else {
        throw e;
      }
    }

    const credentialsFound = data !== null;

    const params: UnknownObject = {
      username: data && data.username,
      token: null,
      url: data && data.url,
      username_placeholder: 'e.g. johnny',
      token_placeholder: credentialsFound
        ? '*****'
        : 'e.g. 6wiIBWbGkBMo1mRDMuVwkw1EPsNkeUj95PIz2akv',
      url_placeholder: 'eg. https://remote.zendesk.com',
      credentialsFound,
      integration: this.integration,
    };

    const html = $('#tpl_main', template(params)).html();
    this.$el.append(html);
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();

    const credentials: UnknownObject = {};
    credentials.username = $('#mki_input_username').val();
    credentials.url = $('#mki_input_url').val();
    credentials.token = $('#mki_input_token').val();

    let validationError;

    if (!credentials.username || credentials.username === '') {
      validationError = 'Please enter a Username';
    } else if (!credentials.token || credentials.token === '') {
      validationError = 'Please enter a token';
    } else if (!credentials.url || credentials.url === '') {
      validationError = 'Please enter a URL';
    }

    if (validationError) {
      return Swal.fire({
        title: 'Error',
        text: validationError,
        icon: 'error',
      });
    }

    const show_error_message = function (message: string, title?: string) {
      Swal.fire({
        title: title || 'Internal error',
        text: message,
        icon: 'error',
      });
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true`,
        credentials
      )
      .then(() => {
        axios
          .get(
            `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/ping`
          )
          .then(() => {
            channel.trigger('success', {
              integration: this.integration,
              alert: true,
            });
          })
          .catch((response) => {
            show_error_message(
              response.data.message,
              `Unable to connect to ${this.integration}`
            );
          });
      })
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  },
});
