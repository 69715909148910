import Backbone from 'backbone';
import $ from 'jquery';
import app from '../app';
import { handleErrors } from '../auth/handle_errors';

const template = require('./profile.pug');

const User_info_view = (Backbone as any).Epoxy.View.extend({
  events: { 'click #btn_update_user_info': 'update_user' },

  initialize() {
    this.listenTo(this.model, 'sync', this.render);
  },

  render() {
    const html = $('#tpl_user_info', template()).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  update_user(e: any) {
    e.preventDefault();
    this.model.save(null, {
      success: () => {
        app.notifications.success('Settings updated', {
          dismiss_timeout: 5000,
        });
        this.render();
      },
    });
  },
});

const Password_update_view = Backbone.View.extend({
  events: { 'click #btn_update_password': 'event_update_password' },

  render() {
    const html = $('#tpl_user_password', template()).html();
    this.$el.html(html);
    return this;
  },

  event_update_password(e: any) {
    e.preventDefault();
    const data = {
      current_password: $('#mki_input_current_password').val(),
      password: $('#mki_input_new_password').val(),
      confirm: $('#mki_input_confirm_password').val(),
    };
    this.model
      .update_password(data)
      .then(() => {
        app.notifications.success('Password updated', {
          dismiss_timeout: 5000,
        });
      })
      .catch((err: any) => {
        const { data: res } = err.response;
        const { message } = res;
        const error = new Error(message);
        handleErrors(error);
      });
  },
});

export default Backbone.View.extend({
  initialize() {
    this.model = app.session.user;
    this.user_info_view = new User_info_view({ model: this.model });
    this.password_update_view = new Password_update_view({ model: this.model });
    this.render();
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(this.user_info_view, '#mki_user_info');
    this.assign(this.password_update_view, '#mki_user_password');
    return this;
  },
});
