import Backbone from 'backbone';
import numeral from 'numeral';
import find from 'lodash/find';
import forEach from 'lodash/forEach';

import { BackboneViewProps } from '../types';

require('backbone.epoxy');

export const defaultSchema: any = {
  active: false,
  name: 'campaign',
  description: '',
  url_conditions: [],
  custom_css: '',
  custom_js: '',
  trigger: { js: '' },
  variations: [
    {
      name: 'New variation',
      description: '',
      active: true,
      weight: 1000,
      on_load: { js: '' },
      on_qualified: { js: '' },
      on_not_qualified: { js: '' },
      on_request: { js: '' },
    },
  ],
  performance: [
    {
      event_collection: 'qualify',
      campaign_id: '',
      result: 0,
    },
    {
      event_collection: 'qualified',
      campaign_id: '',
      result: 0,
    },
    {
      event_collection: 'request',
      campaign_id: '',
      result: 0,
    },
  ],
};

export const CampaignModel = (Backbone as any).Epoxy.Model.extend({
  idAttribute: '_id',
  initialize(options: BackboneViewProps) {
    this.tenant =
      options.tenant ||
      (this.collection ? this.collection.tenant : this.get('tenant')._id);
    this.name = options.name;
  },
  urlRoot() {
    const tenant = this.tenant || this.get('tenant')._id;
    return `${BONGO_URL}/v1/org/${tenant}/campaigns`;
  },
  parse(response: {}) {
    return response || {};
  },
  publish(paramAttrs: { publish?: boolean }, options: {}) {
    this.set('campaignUi', {}); // put campaignUi as empty object, to consider this campaign as advanced
    const attrs = paramAttrs || {};
    attrs.publish = true;
    Backbone.Model.prototype.save.call(this, attrs, options);
  },
  defaults: defaultSchema,
  computeds() {
    const computeds: UnknownObject = {};

    computeds.website = {
      deps: ['tenant'],
      get() {
        const is_url = new RegExp('^https?://');
        const tenant = this.get('tenant');
        const domain = tenant ? tenant.domain : undefined;
        return is_url.test(domain) ? domain : `http://${domain}`;
      },
    };

    computeds.url = {
      deps: ['_id'],
      get() {
        return `campaigns/${this.get('_id')}`;
      },
    };

    computeds.first_url_condition = {
      deps: ['url_conditions'],
      get() {
        return this.get('url_conditions').length > 0 &&
          this.get('url_conditions')[0].value
          ? this.get('url_conditions')[0].value
          : '';
      },
      set(value: string) {
        const url_conditions = this.get('url_conditions');
        if (url_conditions.length > 0) {
          url_conditions[0].value = value;
        } else {
          url_conditions.push({ value });
        }
        return { url_conditions };
      },
    };

    computeds.regexp_url_condition = {
      deps: ['url_conditions'],
      get() {
        return this.get('url_conditions').length > 1 &&
          this.get('url_conditions')[1].value
          ? this.get('url_conditions')[1].value
          : '.^';
      },
      set(value: string) {
        const url_conditions = this.get('url_conditions');
        if (url_conditions.length > 1) {
          url_conditions[1].value = value;
        } else {
          url_conditions.push({ value });
        }
        return { url_conditions };
      },
    };

    computeds.performance_qualify = {
      deps: ['performance'],
      get() {
        return (find(this.get('performance'), {
          event_collection: 'qualify',
        }) as any).result;
      },
    };

    computeds.performance_qualify_display = {
      deps: ['performance_qualify'],
      get() {
        return numeral(this.get('performance_qualify')).format('0,0');
      },
    };

    computeds.performance_qualified = {
      deps: ['performance'],
      get() {
        return (find(this.get('performance'), {
          event_collection: 'qualified',
        }) as any).result;
      },
    };

    computeds.performance_request = {
      deps: ['performance'],
      get() {
        return (find(this.get('performance'), {
          event_collection: 'request',
        }) as any).result;
      },
    };

    computeds.performance_qualified_rate = {
      deps: ['performance', 'performance_qualify', 'performance_qualified'],
      get() {
        const num_qualify_rec = this.get('performance_qualify');
        const num_qualified_rec = this.get('performance_qualified');
        return num_qualify_rec > 0 ? num_qualified_rec / num_qualify_rec : 0;
      },
    };

    computeds.performance_qualified_display = {
      deps: ['performance_qualified', 'performance_qualified_rate'],
      get() {
        const qualifieds = numeral(this.get('performance_qualified')).format(
          '0,0'
        );
        const qualified_rate = this.get('performance_qualified_rate');
        return qualified_rate > 0
          ? `${qualifieds} (${numeral(qualified_rate).format('0.00%')})`
          : qualifieds;
      },
    };

    computeds.performance_request_rate = {
      deps: ['performance'],
      get() {
        const num_qualified_rec = this.get('performance_qualified');
        const num_request_rec = this.get('performance_request');
        return num_qualified_rec > 0 ? num_request_rec / num_qualified_rec : 0;
      },
    };

    computeds.performance_request_display = {
      deps: ['performance_request', 'performance_request_rate'],
      get() {
        const requests = numeral(this.get('performance_request')).format('0,0');
        const request_rate = this.get('performance_request_rate');
        return request_rate > 0
          ? `${requests} (${numeral(request_rate).format('0.00%')})`
          : requests;
      },
    };

    computeds.trigger_js = {
      deps: ['trigger'],
      get() {
        return this.get('trigger').js || '';
      },
      set(value: string) {
        const trigger = this.get('trigger');
        trigger.js = value;
        return { trigger };
      },
    };

    const actions = [
      'on_load',
      'on_qualified',
      'on_not_qualified',
      'on_request',
    ];
    const types = ['js', 'hook'];
    const variation_id = 0;
    forEach(actions, (action: string) => {
      forEach(types, (type: string) => {
        computeds[`variation_${variation_id}_${action}_${type}`] = {
          deps: ['variations'],
          get() {
            const variation = this.get('variations')[variation_id];
            return variation && variation[action]
              ? variation[action][type]
              : '';
          },
          set(value: string) {
            const variations = this.get('variations');
            const variation = variations[0] || {};
            variation[action] = variation[action] || {};
            variation[action][type] = value;
            variations[0] = variation;
            return { variations };
          },
        };
      });
    });

    return computeds;
  },
});

export const CampaignCollection = Backbone.Collection.extend({
  model: CampaignModel,
  url() {
    return `${BONGO_URL}/v1/org/${this.tenant}/campaigns`;
  },
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
  },
});
