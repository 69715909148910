import React, { ReactElement, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import ForcePushForm, { Values as GenericValues } from './ForcePushForm';
import ForcePushRecordsForm, {
  Values as RecordsValues,
} from './ForcePushRecordsForm';
import ForcePushSalesforceForm, {
  ValuesToSubmit as SalesforceValues,
} from './ForcePushSalesforceForm';

type ForcePushProps = {
  tenant: number;
};

type Type = 'generic' | 'records' | 'salesforce';

function ForcePush({ tenant }: ForcePushProps): ReactElement {
  const [pushType, setPushType] = useState<Type>('generic');
  const [sending, setSending] = useState<boolean>(false);

  const handleChange = (event: any) => {
    setPushType(event.target.value);
  };

  const handleSubmit = async (
    values: GenericValues | RecordsValues | SalesforceValues
  ) => {
    const result = await Swal.fire({
      title: 'Start push?',
      text:
        'This will trigger a score backfill on all the records defined in the configuration. Please make sure your configuration is correct.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Start push',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      setSending(true);

      try {
        await axios.post(
          `${BONGO_URL}/v1/org/${tenant}/force_push/${pushType}`,
          {
            ...values,
            tenant,
          }
        );

        await Swal.fire(
          'Done',
          `Force push request has been successfully sent. We will send you an email when it is completed.`,
          'success'
        );
      } catch (error) {
        await Swal.fire(
          'Not done',
          'We have encountered an error while requesting the force push. Please contact the engineering team.',
          'error'
        );
      } finally {
        setSending(false);
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <label htmlFor="push-type" className="mr-2">
            Push type:
          </label>
          <select id="push-type" value={pushType} onChange={handleChange}>
            <option value="generic">Advanced</option>
            <option value="records">Push specific records</option>
            <option value="salesforce">Push to Salesforce</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {pushType === 'generic' && (
            <ForcePushForm onSubmit={handleSubmit} sending={sending} />
          )}
          {pushType === 'records' && (
            <ForcePushRecordsForm onSubmit={handleSubmit} sending={sending} />
          )}
          {pushType === 'salesforce' && (
            <ForcePushSalesforceForm
              onSubmit={handleSubmit}
              sending={sending}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ForcePush;
