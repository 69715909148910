import React from 'react';
import { capitalizeEachWord, getColorForSegment } from '../../utils';

type AnalysisCardComponentProps = {
  values: string[];
  rates: any;
  title: string;
  description: string;
  isPercentage: boolean;
};

export default class AnalysisCardComponent extends React.Component<
  AnalysisCardComponentProps,
  {}
> {
  render() {
    const { values, rates, title, description, isPercentage } = this.props;

    return (
      <div className="col-4">
        <div className="card box-shadow-3d d-flex h-100">
          <h5 className="card-header">{title}</h5>
          <div className="card-body">
            <p>{description}</p>
            <div>
              {values.map((value) => (
                <div
                  key={`conversion_${value}`}
                  className="progress mt-3 bg-gray"
                  style={{ height: '40px' }}
                >
                  <div
                    className={`progress-bar bg-${getColorForSegment(value)}`}
                    role="progressbar"
                    aria-valuenow={10}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{
                      width:
                        rates[value] && parseInt(rates[value].percentage, 10)
                          ? rates[value].percentage
                          : 0,
                      overflow: 'visible',
                    }}
                  >
                    <h6 className={'m-auto'}>
                      {capitalizeEachWord(value)} -{' '}
                      {isPercentage
                        ? `${rates[value].value.toFixed(2)}%`
                        : `$${rates[value].value
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
