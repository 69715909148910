import axios from 'axios';
import { beautifyWhitespace, getFieldPrefix, VERBS_AND_SQL } from '../../utils';

export function unslugAudienceField(fieldName: any) {
  if (!fieldName) return '';
  if (typeof fieldName === 'string') {
    return fieldName.startsWith('a_') ? fieldName.slice(2) : fieldName;
  }
  return fieldName.name.startsWith('a_')
    ? fieldName.name.slice(2)
    : fieldName.name;
}

function escape(value: string | boolean | number) {
  return String(value).trim().replace(/'/g, "''");
}

function mapConditionToSql(condition: any) {
  if (!condition) return '';
  const { object, sourceSystem, verb, lower } = condition;
  // handle cases where old audience fields are stored in db with a_ prefix
  // the prefix is now only added to the generated SQL
  const trait = condition?.trait;
  let fieldName = `et.${sourceSystem}_${object}_${trait}`;
  let { values } = condition;

  if (lower) {
    fieldName = `LOWER(${fieldName})`;
    values = values.map((value: string) => value.toLowerCase());
  }

  const value1 = values[0];
  const value2 = values[1];
  const allValues = `'${values.map(escape).join("', '")}'`;

  return VERBS_AND_SQL[verb]
    .replace('{{value}}', escape(value1))
    .replace('{{value2}}', escape(value2))
    .replace('{{subject}}', fieldName)
    .replace('{{allValues}}', allValues);
}

export const LEAD_MAPPING_OBJECTS: {
  [key in 'Account' | 'Opportunity']: string[];
} = {
  Account: ['Domain', 'Account ID'],
  Opportunity: ['Account ID', 'Opportunity id'],
};

export function mapAudienceConditionsToSql(
  conditions: any[],
  conditionsLogic: string = ''
) {
  const sqlConditions = conditions.map((condition) =>
    mapConditionToSql(condition)
  );

  const conditionsLogicWithSpecialCharacters = (conditionsLogic ?? '').replace(
    /\$/gi,
    '*#$'
  );
  let formattedConditions =
    conditionsLogicWithSpecialCharacters ||
    beautifyWhitespace(conditionsLogicWithSpecialCharacters);

  sqlConditions.forEach((condition, index) => {
    if (!condition) return;
    formattedConditions = formattedConditions.replace(
      `*#$${index + 1}`,
      condition
    );
  });

  const notEmptySqlConditions = sqlConditions.filter(
    (condition) => condition !== ''
  );

  if (notEmptySqlConditions.length === 1) return notEmptySqlConditions[0];
  if (!formattedConditions.trim()) return '';
  return `${formattedConditions}`;
}

export function mapAudienceFieldsToSql(conditions: any[]) {
  return conditions.reduce((acc: string, condition: any, index: number) => {
    if (!condition) return acc;
    const { object, sourceSystem } = condition;
    // handle cases where old audience fields are stored in db with a_ prefix
    // the prefix is now only added to the generated SQL
    const trait = condition?.trait;
    const fieldName = `${sourceSystem}_${object}_${trait}`;
    if (
      acc.includes(
        `MIN(${getFieldPrefix(sourceSystem, object)}.${trait}) AS ${fieldName}`
      )
    )
      return acc;
    // eslint-disable-next-line
    acc = `${acc}${index !== 0 ? '      ' : ''}MIN(${getFieldPrefix(
      sourceSystem,
      object
    )}.${trait}) AS ${fieldName},\n    `;
    return acc;
  }, '');
}

export async function getAudienceParams(tenant: number, audienceName: string) {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/audiences/${audienceName}`
    );
    return data;
  } catch (error) {
    if (['newAudience', 'newCustomAudience'].includes(audienceName)) {
      return null;
    }
    throw new Error(error);
  }
}

export async function getSimpleAudiencesNames(tenant: number) {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/audiences`
    );
    return data.map((audience: UnknownObject) => audience.name);
  } catch (_e) {
    return [];
  }
}
