import React from 'react';
import { Button, ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import Backbone from 'backbone';
import moment from 'moment';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import ConversionMappingManager from '../../models/ConversionMappingManager';
import { conversionTypeCodeColor } from '../../utils';
import { ConversionTypesGroup } from '../../models/ConversionTypesGroup';
import { ConversionTypeData } from '../../models/ConversionTypeData';
import { AnalyticsFiltersForm } from '../../models/filters_forms/AnalyticsFiltersForm';
import StripeFiltersForm from '../../models/filters_forms/StripeFiltersForm';
import {
  ConnectorsNames,
  ConnectorsOfConversionTypes,
} from '../../../../types';
import { DefaultFiltersForm } from '../../models/filters_forms/DefaultFiltersForm';
import { unslugFieldName } from '../../../pullConfigManager';
import { redirectToInsightsReport } from '../../../utils';
import MappingsMode from '../../../enums/MappingsMode';

require('codemirror/mode/sql/sql');

type DisplayConversionMappingDataComponentProps = {
  conversionMappingManager: ConversionMappingManager;
};

type DisplayConversionMappingDataComponentState = {
  isSaving: boolean;
};

export default class DisplayConversionMappingDataComponent extends React.Component<
  DisplayConversionMappingDataComponentProps,
  DisplayConversionMappingDataComponentState
> {
  constructor(props: DisplayConversionMappingDataComponentProps) {
    super(props);
    this.state = {
      isSaving: false,
    };
    this.handleOnClickEditButton = this.handleOnClickEditButton.bind(this);
    this.handleOnCheckVolumeClick = this.handleOnCheckVolumeClick.bind(this);
  }

  _checkNullFullArray(array: UnknownObject[]) {
    const filtredArray = array
      ? array.filter((element) => {
          return element === null;
        })
      : [];
    return array.length === filtredArray.length;
  }

  getDefaultDefinitionRow(
    conversionTypeData: ConversionTypeData,
    connectorName: ConnectorsNames
  ) {
    const filterForm = conversionTypeData.filtersForm as DefaultFiltersForm;
    if (filterForm.parameters.length > 0) {
      return (
        <div>
          <ListGroup className="list-group-flush">
            {filterForm.parameters &&
              !this._checkNullFullArray(filterForm.parameters) &&
              filterForm.parameters.map((parameter, index) => {
                return (
                  <div key={`r_${connectorName}_${index}`}>
                    {parameter && (
                      <ListGroupItem
                        key={`list_${connectorName}_${index}`}
                        className="pl-0"
                      >
                        <div className="row">
                          <div className="col-sm-10">
                            {`${parameter.table}.${unslugFieldName(
                              parameter.field
                            )} ${parameter.verb} ${parameter.values}`}
                          </div>
                        </div>
                      </ListGroupItem>
                    )}
                  </div>
                );
              })}
          </ListGroup>
        </div>
      );
    }
  }

  getStripeDefinitionRow(conversionTypeData: ConversionTypeData) {
    if (conversionTypeData.conversionType === 'SQO') {
      const {
        sqoAmountThreshold,
      } = conversionTypeData.filtersForm as StripeFiltersForm;
      return (
        <ListGroup className="list-group-flush">
          <ListGroupItem className="pl-0">
            <div className="row">
              <div className="col-sm-10">
                {`Stripe SQO Amount threshold: ${sqoAmountThreshold}`}
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>
      );
    }
    if (conversionTypeData.conversionType === 'Closed Won') {
      return (
        <div className="row">
          <div className="col-sm-10">invoice paid (&gt;$0)</div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-sm-10">undefined for stripe</div>
      </div>
    );
  }

  getAnalyicsDefinitionRow(conversionTypeData: ConversionTypeData) {
    if (conversionTypeData.conversionType === 'Closed Won') {
      const {
        sourceSystem,
        conversionEvents,
      } = conversionTypeData.filtersForm as AnalyticsFiltersForm;
      return (
        <ListGroup className="list-group-flush">
          <ListGroupItem className="pl-0">
            <div className="row">
              <div className="col-sm-10">{`Source system: ${sourceSystem}`}</div>
            </div>
          </ListGroupItem>
          <ListGroupItem className="pl-0">
            <div className="row">
              <div className="col-sm-10">{`Conversion event: ${conversionEvents}`}</div>
            </div>
          </ListGroupItem>
        </ListGroup>
      );
    }
    return (
      <div className="row">
        <div className="col-sm-10">Undefined for analytics</div>
      </div>
    );
  }

  renderDefinitionRow(
    conversionTypeDataIndex: number,
    conversionMappingTypeData: ConversionTypeData
  ) {
    switch (conversionMappingTypeData.connector) {
      case 'analytics':
        return this.getAnalyicsDefinitionRow(conversionMappingTypeData);
      case 'stripe':
        return this.getStripeDefinitionRow(conversionMappingTypeData);
      default:
        return this.getDefaultDefinitionRow(
          conversionMappingTypeData,
          conversionMappingTypeData.connector
        );
    }
  }

  renderConversionTypeConnectorBlock(
    conversionTypeDataIndex: number,
    conversionTypeData: ConversionTypeData
  ) {
    const displayStandardParameters: boolean = ConnectorsOfConversionTypes[
      conversionTypeData.conversionType
    ].includes(conversionTypeData.connector);
    return (
      <tr key={conversionTypeDataIndex}>
        <td className="align-middle con-map-connector-size w-25">
          <div>
            <div className="text-white mt-1">
              <img
                src={`/media/integrations/logos/${conversionTypeData.connector}.svg`}
                key={`img_connector_${conversionTypeData.connector}_${conversionTypeDataIndex}`}
                className="con-map-mk-integration-logo-size"
              />
            </div>
          </div>
        </td>
        <td className="align-middle w-25">
          {displayStandardParameters && conversionTypeData.filterTypesValues}
        </td>
        <td className="align-middle w-25">
          {displayStandardParameters &&
            conversionTypeData.connector !== 'analytics' &&
            `${conversionTypeData.amountField.table}.${unslugFieldName(
              conversionTypeData.amountField.value
            )}`}
        </td>
        <td className="align-middle w-25">
          {this.renderDefinitionRow(
            conversionTypeDataIndex,
            conversionTypeData
          )}
        </td>
      </tr>
    );
  }

  renderCardBody(conversionGroupData: ConversionTypesGroup) {
    if (conversionGroupData.isCustom) {
      return (
        <div className="card-body h-100">
          <CodeMirror
            value={
              conversionGroupData.getCustomIndexedConversionType()
                ?.conversionTypeData.madMlSqlQuery
            }
            className="w-100 h-100"
            options={{
              mode: 'sql',
              lineNumbers: true,
              readOnly: true,
            }}
          />
        </div>
      );
    }
    const notCustomConversionTypes = conversionGroupData.indexedConversionTypesData.filter(
      (indexedConversionTypeData) =>
        !indexedConversionTypeData.conversionTypeData.isCustom
    );

    return (
      <Table responsive className="table">
        <thead>
          <tr>
            <th className="pl-3">Defined Within</th>
            <th>Type Parameter</th>
            <th>Amount Field</th>
            <th>Definitions</th>
          </tr>
        </thead>
        <tbody>
          {notCustomConversionTypes.map((indexedConversionTypeData) => {
            return this.renderConversionTypeConnectorBlock(
              indexedConversionTypeData.index,
              indexedConversionTypeData.conversionTypeData
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderSections() {
    const { data } = this.props.conversionMappingManager;
    const conversionMappingData = data.transformToDisplay();
    const availableConversionGroup = conversionMappingData.getConversionTypeGroupWithActiveConnectors();
    return (
      <div>
        <div>
          {availableConversionGroup.map((conversionGroupData, index) => {
            return (
              <div
                className="card box-shadow-3d mb-4"
                key={`conversion_group_card_${index}_${conversionGroupData.name}`}
              >
                <h4 className="card-header d-flex justify-content-lg-start">
                  <span
                    className={`badge badge-${
                      conversionTypeCodeColor[conversionGroupData.type]
                    } con-map-mk-badge-size`}
                  >
                    {conversionGroupData.type}
                  </span>
                  {conversionGroupData.isCustom && (
                    <span className="badge badge-warning con-map-mk-badge-size ml-2">
                      SCRIPTED
                    </span>
                  )}
                  <div className="ml-2">{conversionGroupData.name}</div>
                  <div className="ml-2">
                    <span
                      className="font-italic text-dark"
                      style={{ fontSize: '60%' }}
                    >
                      ({conversionGroupData.name})
                    </span>
                  </div>
                </h4>
                {this.renderCardBody(conversionGroupData)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  goBackToMapping() {
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingManager.tenant}/mapping`,
      true
    );
  }

  async handleOnClickEditButton() {
    const { tenant, email } = this.props.conversionMappingManager;
    window.analytics.track('Edit mapping', {
      tenant,
      email,
      map: `conversion mapping`,
    });
    this.props.conversionMappingManager.step = 1;
    this.setState({
      isSaving: true,
    });
    await this.props.conversionMappingManager.save();
    this.setState({
      isSaving: false,
    });
    Backbone.history.navigate(
      `org/${tenant}/mapping/conversion_mapping/connector_overview`,
      true
    );
  }

  handleOnCheckVolumeClick() {
    redirectToInsightsReport(
      MappingsMode.conversion,
      this.props.conversionMappingManager.tenant
    );
  }

  render() {
    const {
      lastPublisher,
      isReadOnly,
      scriptCheckerStatus,
      updatedAt,
    } = this.props.conversionMappingManager;
    const { isSaving } = this.state;
    const updateAtFormatted = moment(updatedAt).format('lll');

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light box-shadow-3d">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Conversion Mapping
            </li>
          </ol>
          <div className="mt-2 mr-2 my-auto">
            {lastPublisher && (
              <p className="mb-0">{`Last update: ${updateAtFormatted}, by ${lastPublisher}`}</p>
            )}
          </div>

          <div className="float-right my-2 my-lg-0">
            <div>
              <Button
                bsStyle="primary"
                className="mr-2"
                onClick={this.handleOnCheckVolumeClick}
              >
                Check volumes
              </Button>
              {!isReadOnly && (
                <Button
                  bsStyle="primary"
                  className="mr-2"
                  disabled={isSaving}
                  onClick={this.handleOnClickEditButton}
                >
                  {isSaving ? 'Redirecting...' : 'Edit'}
                </Button>
              )}
            </div>
          </div>
        </nav>
        <div className="container">
          {scriptCheckerStatus && (
            <div className="alert alert-warning box-shadow-3d d-flex alert-No-Data">
              <div className="spinner-border float-left mr-2"></div>
              <p>
                Your configuration has been submitted and is currently being
                processed. Please wait for an email to confirm success or
                failure of publish.
              </p>
            </div>
          )}
          <div className="mt-4">{this.renderSections()}</div>
        </div>
      </div>
    );
  }
}
