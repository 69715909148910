import Backbone from 'backbone';
import pick from 'lodash/pick';
import find from 'lodash/find';
import Tenant from './model_tenant';
import { Model as User } from './model_user';
import { TenantType, UserType } from './types';

type UserParam = {
  permissions?: UnknownObject;
  role?: string;
};

export default Backbone.Model.extend({
  defaults: {
    logged_in: false,
    tenant: {},
    user: {},
  },

  url() {
    return `${BONGO_URL}/v1/auth`;
  },

  initialize() {
    this.tenant = new Tenant({});
    this.user = new User({});
  },

  update_user(paramUser: UserParam, tenant: TenantType) {
    const user: UserParam = paramUser || {};
    const permission = find(user.permissions, { tenant: tenant._id }) || {};
    user.role = permission.role || 'user';
    const filtered = pick(user, Object.keys(this.user.defaults));
    this.user.set(filtered); // initialize the user model with the value returned
    this.user.tenant = tenant._id;
    this.set('user', user); // set the tenant as an attribute of the model as well (makes it available to Marionette)
  },

  update_tenant(tenant: TenantType) {
    // const filtered = _.pick(user, _.keys(this.user.defaults))
    this.tenant.reset(tenant); // set the tenant property
    this.set('tenant', tenant); // set the tenant as an attribute of the model as well (makes it available to Marionette)
  },

  update_config(config: {}) {
    this.config = config;
  },

  track(tenant: TenantType, user: UserType) {
    if (user._id && tenant && tenant._id) {
      // window.analytics.identify(
      //   user._id,
      //   {
      //     email: user.email,
      //     tenant: tenant._id,
      //   },
      //   {
      //     Intercom: { user_hash: user.user_hash },
      //   }
      // );

      // if a madkudu address, do not group to avoid messing up the organizations in intercom and others
      if (!/madkudu\.com/.test(user.email)) {
        window.analytics.group(tenant._id, { name: tenant.name });
      }
    }
  },

  store(res: { tenant: TenantType; user: UserType; config: {} }) {
    console.log('storing session', res);

    const { tenant, user, config } = res;
    this.update_user(user, tenant);
    this.update_tenant(tenant);
    this.update_config(config);
    this.track(tenant, user);
    this.set({ logged_in: true });
  },

  async check_auth() {
    const res = await this.fetch();

    if (res && res.user) {
      this.store(res);
    } else {
      throw new Error('Not authenticated');
    }
  },
});
