import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BackboneViewProps } from '../../../../types';
import app from '../../../../app';
import CustomConfigurationParentComponent from '../../components/custom/CustomConfigurationParentComponent';
import { isUserReadOnly } from '../../../../utils';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.email = user.email;
    this.superKudu = user.superKudu;
    this.isReadOnly = isUserReadOnly(user);

    await this.sleep(0); // Force to sleep until the page is created with the html tags

    this.render();
    this.renderCustomConfigurationParentComponent(
      this.tenant,
      this.email,
      this.isReadOnly
    );
  },

  renderCustomConfigurationParentComponent(
    tenant: number,
    email: string,
    isReadOnly: boolean
  ) {
    ReactDOM.render(
      <CustomConfigurationParentComponent
        tenant={tenant}
        email={email}
        isReadOnly={isReadOnly}
        isSuperKudu={this.superKudu}
      />,
      $('#CustomConfigurationParentComponentContainer')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
