import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { faWavePulse } from '@fortawesome/pro-solid-svg-icons/faWavePulse';
import { Tooltip } from '../../components/ui';
import { ModelSubset, TopSignal } from '../types';

type Props = {
  topSignals?: TopSignal[];
  model: ModelSubset;
  tenant: number;
};

export default function TopSignals({ topSignals = [], model, tenant }: Props) {
  return (
    <>
      <div className="tw-relative tw-h-[27rem] tw-rounded-lg tw-border tw-border-light tw-bg-white tw-shadow tw-flex tw-flex-col tw-p-7">
        <Tooltip
          avoidCollisions={false}
          position="top"
          offset={20}
          text={
            <div>
              <span className="tw-font-bold tw-mb-2 tw-text-sm tw-block">
                About the Top Signals
              </span>
              <p className="tw-text-sm">
                Signals are meant to provide your Sales teams with high-level
                context on the leads' score they see in the CRM. They help build
                trust in the scoring system you built.
              </p>
            </div>
          }
        >
          <div className="tw-w-full tw-flex tw-gap-2 tw-justify-center tw-items-center tw-cursor-default tw-mb-4">
            <span className="tw-font-medium">Top signals</span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              className="tw-text-gray-300 tw-absolute tw-right-4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_147_461)">
                <path
                  d="M9.00934 3.70006e-06C11.3972 0.00211312 13.6857 0.953446 15.372 2.64438C17.0588 4.33469 18.0038 6.62616 18.0003 9.01404C17.9969 11.4019 17.0441 13.6899 15.3531 15.3752C13.6614 17.0606 11.3691 18.0049 8.98144 18C6.59428 17.9951 4.3063 17.0416 2.62168 15.3491C0.93699 13.6567 -0.00595854 11.3645 0.00052146 8.97678C0.00684954 6.5925 0.958877 4.3074 2.64778 2.62476C4.33739 0.941476 6.62542 -0.0021563 9.0097 3.70006e-06H9.00934ZM8.32169 6.84H6.71575C6.59903 6.84141 6.48301 6.85758 6.37051 6.88852C6.23269 6.91735 6.12792 7.02844 6.10754 7.16766C6.06958 7.31883 6.14551 7.47492 6.28754 7.53821C6.46051 7.61907 6.64614 7.66899 6.83669 7.68587C7.3373 7.70907 7.5384 7.92916 7.46318 8.44383C7.32255 9.40712 7.13412 10.3626 6.97169 11.3238C6.862 11.9721 6.76989 12.6218 6.66373 13.2694C6.5217 14.1427 7.33522 14.9612 7.98138 15.0694C8.36388 15.1236 8.75059 15.1482 9.13732 15.1433C9.96561 15.1426 10.7566 14.8002 11.324 14.1969C11.4273 14.102 11.5047 13.9824 11.549 13.8495C11.5708 13.7335 11.5433 13.6133 11.473 13.5184C11.3886 13.4649 11.2846 13.4537 11.1911 13.4874C11.0434 13.5359 10.9119 13.6365 10.7678 13.6998C10.5997 13.7785 10.4232 13.839 10.2418 13.8798C10.0963 13.9156 9.9416 13.8854 9.81996 13.7975C9.69761 13.7096 9.62027 13.5725 9.60831 13.4227C9.57808 13.2554 9.57245 13.0838 9.59214 12.9151C9.65331 12.4974 9.7398 12.0833 9.81504 11.6692C10.021 10.5406 10.2292 9.41069 10.4401 8.28011C10.4978 7.97073 10.5948 7.66628 10.5301 7.34073C10.5203 7.19588 10.4521 7.06088 10.3417 6.96737C10.2313 6.87315 10.0878 6.82745 9.94302 6.84011L8.32169 6.84ZM9.30114 2.83716C8.63739 2.80763 8.02215 3.18311 7.74443 3.78708C7.46669 4.39037 7.58201 5.10192 8.03623 5.58708C8.49044 6.07153 9.19287 6.23324 9.81302 5.99629C10.4339 5.75864 10.8487 5.16941 10.8635 4.50567C10.8888 3.61552 10.1906 2.87162 9.30114 2.84067V2.83716Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_147_461">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </Tooltip>
        <ul className="tw-full tw-text-sm tw-leading-10 -tw-mr-6 tw-m-0 tw-p-0 tw-flex-grow tw-overflow-auto visible-scrollbar">
          {!!topSignals.length &&
            topSignals.map(({ type, name, value }, index: number) => {
              return (
                <li className="tw-flex tw-items-center tw-gap-2" key={index}>
                  {type === 'positive' ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 19 19"
                      fill="none"
                      className="tw-opacity-90"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.3696 0.572504C7.03081 0.621777 4.80657 1.58295 3.17714 3.24911C1.54773 4.9145 0.644442 7.15002 0.663598 9.4717C0.648327 11.8231 1.57834 14.0844 3.24751 15.7527C4.9174 17.4211 7.18895 18.3589 9.55745 18.3589C11.9267 18.3589 14.1975 17.4212 15.8674 15.7527C17.5365 14.0843 18.4673 11.8231 18.4513 9.4717C18.4712 7.08462 17.5159 4.79244 15.8056 3.11638C14.0945 1.43962 11.7725 0.522438 9.3696 0.572504ZM14.512 5.55428C14.8793 5.5376 15.2183 5.74682 15.3657 6.08035C15.5138 6.41389 15.4405 6.80351 15.1809 7.06124L8.73198 13.4627C8.26927 13.9221 7.51794 13.9221 7.05446 13.4627L3.91327 10.3443C3.56815 9.99862 3.56891 9.44147 3.91556 9.09655C4.26221 8.75241 4.82418 8.75166 5.17161 9.09504L7.89293 11.7975L13.922 5.812H13.9228C14.0793 5.65585 14.2898 5.56338 14.512 5.55428Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 58 58"
                      fill="none"
                      className="tw-opacity-40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.26533 2.26533C4.86947 -0.338808 9.08827 -0.338808 11.6924 2.26533L28.6617 19.2347L45.6311 2.26533C48.2352 -0.338808 52.454 -0.338808 55.0581 2.26533C57.6623 4.86946 57.6623 9.08826 55.0581 11.6924L38.0888 28.6617L55.0581 45.6311C57.6623 48.2352 57.6623 52.454 55.0581 55.0581C52.454 57.6623 48.2352 57.6623 45.6311 55.0581L28.6671 38.0941L11.6977 55.0635C9.0936 57.6676 4.8748 57.6676 2.27067 55.0635C-0.333468 52.4593 -0.333468 48.2405 2.27067 45.6364L19.24 28.6671L2.27067 11.6977C-0.333468 9.09359 -0.333468 4.87479 2.27067 2.27066L2.26533 2.26533Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  {name} {value ?? ''}
                </li>
              );
            })}

          {!topSignals.length && (
            <div className="tw-text-gray-300 tw-text-sm tw-flex tw-items-center tw-justify-center tw-text-center tw-h-full">
              <div>
                <FontAwesomeIcon
                  icon={faWavePulse}
                  className="tw-text-5xl tw-text-gray-200"
                />
                <div className="tw-mt-2">
                  No signals found for this profile.
                </div>
                {!model.isDefault && (
                  <div>
                    You can configure new signals{' '}
                    <a
                      className="tw-text-blue-700 hover:tw-no-underline hover:tw-text-blue-800"
                      href={`https://studio.madkudu.com/tenant/${tenant}/models/${model.modelId}/model/signals`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                      <FontAwesomeIcon
                        icon={faUpRightFromSquare}
                        className="tw-ml-1"
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </ul>
      </div>
    </>
  );
}
