import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Backbone from 'backbone';
import moment from 'moment';

type AudienceBoxProps = {
  tenant: number;
  name: string;
  date: number;
  key: number;
};

export default class AudienceBox extends React.Component<AudienceBoxProps, {}> {
  tenant: number;

  name: string;

  date: string;

  key: number;

  constructor(props: AudienceBoxProps) {
    super(props);
    this.tenant = props.tenant;
    this.name = props.name;
    this.date = new Date(props.date).toISOString();
    this.key = props.key;

    this.handleClickOnAudienceBox = this.handleClickOnAudienceBox.bind(this);
  }

  handleClickOnAudienceBox() {
    Backbone.history.navigate(
      `/org/${this.tenant}/mapping/audiences/${this.name}`,
      true
    );
  }

  render() {
    const formattedDate = moment.utc(this.props.date).format('MM-DD-YYYY');
    return (
      <Row
        key={this.key}
        onClick={this.handleClickOnAudienceBox}
        className="mt-2"
      >
        <Col sm={12}>
          <div className="card m-auto p-4">
            <h4 className="audience_box">{this.name}</h4>
            <p>Date configured: {formattedDate}</p>
          </div>
        </Col>
      </Row>
    );
  }
}
