import axios from 'axios';
import React from 'react';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {
  htmlBodyForPQLSwal,
  returnHtmlBodyForSuccessSwal,
} from '../htmlScripts';

// Duplicated function, because of a weired bug when import this function from another document
async function launchSwalPqlOverrides(
  tenant: number,
  modelId: number,
  userEmail: string
) {
  return Swal.fire({
    title: 'Overrides',
    html: htmlBodyForPQLSwal,
    input: 'textarea',
    width: '50%',
    confirmButtonText: 'Send',
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
    preConfirm: async (value: string) => {
      try {
        const {
          data: { url },
        } = await axios.post(
          `${BONGO_URL}/v1/org/${tenant}/models/${modelId}/overrides`,
          { message: value, email: userEmail }
        );
        return url;
      } catch (e) {
        // do nothing
      }
    },
  }).then(async (result: UnknownObject) => {
    if (result && result.value) {
      return (Swal as any).fire({
        type: 'success',
        html: returnHtmlBodyForSuccessSwal(result.value),
      });
    }
    if (result && typeof result.value === 'undefined' && !result.dismiss) {
      return (Swal as any).fire({
        type: 'error',
        html: '<p>Something went wrong!</p>',
      });
    }
  });
}

type OverrideContainerProps = {
  tenant: number;
  modelId: number;
  userEmail: string;
};

export default class OverrideContainer extends React.Component<
  OverrideContainerProps,
  {}
> {
  constructor(props: OverrideContainerProps) {
    super(props);

    this.handleRequestOverridesButton = this.handleRequestOverridesButton.bind(
      this
    );
    this.handleCancelOverridesButton = this.handleCancelOverridesButton.bind(
      this
    );
  }

  async handleRequestOverridesButton() {
    const { tenant, modelId, userEmail } = this.props;
    await launchSwalPqlOverrides(tenant, modelId, userEmail);
  }

  handleCancelOverridesButton() {
    Swal.close();
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <p>
              Weights or decay factors of events included in the Likelihood to
              Buy model can be manually changed to boost or penalize some
              events. You can send a request through this page to the MadKudu
              team who will review your request and assess the impact on the
              model performance.
            </p>
          </div>
          <div>
            <div>
              <Button bsSize="large" onClick={this.handleCancelOverridesButton}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={this.handleRequestOverridesButton}
              >
                Request Overrides
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div
            className="m-1 p-1 rounded row"
            style={{
              backgroundColor: '#395D7F',
              color: '#EBEFF2',
              fontSize: '25px',
              fontWeight: 'bolder',
            }}
          >
            <div id="OD_box" className="col-sm-6 text-left">
              <b>Overrides rules</b>
            </div>
            <div id="OS_box" className="col-sm-6 text-right">
              <b>Segment applied</b>
            </div>
          </div>
          <div
            className="card box-shadow-3d m-2 p-2"
            style={{
              fontWeight: 'bolder',
              fontSize: '20px',
            }}
          >
            <div className="row">
              <div className="col-sm-3">
                <span>active_users_last_30</span>
              </div>
              <div className="col-sm-4">
                <span>is more than or equal to</span>
              </div>
              <div className="col-sm-1">
                <span>20</span>
              </div>
              <div className="col-sm-2">
                <span>should at least be</span>
              </div>
              <div className="col-sm-2">
                <span className="badge badge-primary" style={{ width: '80px' }}>
                  very high
                </span>
              </div>
            </div>
          </div>
          <div
            className="card box-shadow-3d m-2 p-2"
            style={{
              fontWeight: 'bolder',
              fontSize: '20px',
            }}
          >
            <div className="row">
              <div className="col-sm-3">
                <span>active_users_last_30</span>
              </div>
              <div className="col-sm-4">
                <span>is more than or equal to</span>
              </div>
              <div className="col-sm-1">
                <span>10</span>
              </div>
              <div className="col-sm-2">
                <span>should at least be</span>
              </div>
              <div className="col-sm-2">
                <span className="badge badge-success" style={{ width: '80px' }}>
                  high
                </span>
              </div>
            </div>
          </div>
          <div
            className="card box-shadow-3d m-2 p-2"
            style={{
              fontWeight: 'bolder',
              fontSize: '20px',
            }}
          >
            <div className="row">
              <div className="col-sm-3">
                <span>active_users_last_30</span>
              </div>
              <div className="col-sm-4">
                <span>is</span>
              </div>
              <div className="col-sm-1">
                <span>1</span>
              </div>
              <div className="col-sm-2">
                <span>should at most be</span>
              </div>
              <div className="col-sm-2">
                <span className="badge badge-warning" style={{ width: '80px' }}>
                  medium
                </span>
              </div>
            </div>
          </div>
          <div
            className="card box-shadow-3d m-2 p-2"
            style={{
              fontWeight: 'bolder',
              fontSize: '20px',
            }}
          >
            <div className="row">
              <div className="col-sm-3">
                <span>active_users</span>
              </div>
              <div className="col-sm-4">
                <span>is</span>
              </div>
              <div className="col-sm-1">
                <span>1</span>
              </div>
              <div className="col-sm-2">
                <span>should at most be</span>
              </div>
              <div className="col-sm-2">
                <span className="badge badge-danger" style={{ width: '80px' }}>
                  low
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
