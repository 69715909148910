import React, { Component } from 'react';

import { capitalizeEachWord, getColorForSegment } from '../../utils';

type FunnelBreakdownComponentProps = {
  dataPredictedValueQualifiedSum: number;
  leadRevenuePercentage: string;
  colorOfRevenue: string;
  leadAmount: any;
  leadRevenue: any;
  leadAmountTotal: number;
  leadRevenueTotal: number;
  values: string[];
};

export default class FunnelBreakdownComponent extends Component<
  FunnelBreakdownComponentProps,
  {}
> {
  render() {
    const {
      dataPredictedValueQualifiedSum,
      leadRevenuePercentage,
      colorOfRevenue,
      values,
      leadAmount,
      leadRevenue,
      leadAmountTotal,
      leadRevenueTotal,
    } = this.props;

    return (
      <div className="col-sm-6">
        <div className="card box-shadow-3d d-flex h-100">
          <h5 className="card-header">Leads and Revenue in Numbers</h5>
          <div className="card-body">
            <p>
              <b>
                $
                {dataPredictedValueQualifiedSum
                  .toFixed(0)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                <span style={{ color: colorOfRevenue }}>
                  {' '}
                  ({leadRevenuePercentage})
                </span>
              </b>{' '}
              <a
                className="badge badge-secondary"
                data-tip="A sum of the 'Very Good' and 'Good' revenue from the chart below"
              >
                ?
              </a>{' '}
              of your revenue was successfully predicted{' '}
              <a
                className="badge badge-secondary"
                data-tip="Predicted = Leads we scored as 'Very Good' and 'Good' before they converted"
              >
                ?
              </a>{' '}
              by MadKudu
            </p>

            <div className="row">
              <div className="col">
                <b>Segment</b>
              </div>
              <div className="col">
                <b># of Leads</b>
              </div>
              <div className="col">
                <b>Revenue</b>
              </div>
            </div>
            {values.map((value) => (
              <div key={`lead_amount_${value}`} className="row mt-3">
                <div className="col">
                  <h4>
                    <span
                      className={`badge badge-${getColorForSegment(
                        value
                      )} p-2 text-white w-75`}
                    >
                      {capitalizeEachWord(value)}
                    </span>
                  </h4>
                </div>
                <div className="col">
                  {parseInt(leadAmount[value], 10)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
                <div className="col">
                  $
                  {parseInt(leadRevenue[value], 10)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
              </div>
            ))}
            <div className="row pt-3 border-top">
              <div className="col">
                <b>Totals:</b>
              </div>
              <div className="col">
                <b>
                  {leadAmountTotal
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </b>
              </div>
              <div className="col">
                <b>
                  $
                  {leadRevenueTotal
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
