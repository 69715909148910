import React, { Dispatch, SetStateAction, useState } from 'react';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faFileUser } from '@fortawesome/pro-solid-svg-icons/faFileUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from '../../../../../components/ui';
import { prettifyCondition } from '../../../utils';
import { snakeCaseToHumanReadable } from '../../../../../utils';
import {
  ConversionTree,
  EnrichmentCategory,
  Node,
  ModelSubset,
} from '../../../../types';

type Props = {
  conversionTree: ConversionTree;
  node: Node;
  conditionIsTrue: boolean;
  tenant: number;
  model: ModelSubset;
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
};

export default function NodeCard({
  conversionTree,
  node,
  conditionIsTrue,
  tenant,
  model,
  setSearch,
  setSelectedCategory,
}: Props) {
  const [unfolded, setUnfolded] = useState(false);

  const searchComputation = (
    nodeComputations: { name: string; value: string }[]
  ) => {
    if (nodeComputations?.length > 0) {
      const list = nodeComputations
        .map((computation) => `"${snakeCaseToHumanReadable(computation.name)}"`)
        .join(' ');
      setSearch(list);
      setSelectedCategory('all');
    }
  };

  return (
    <div>
      <div className="tw-relative tw-bg-white tw-flex tw-flex-col tw-w-[12rem] tw-min-w-[12rem] tw-rounded-lg tw-text-xs tw-border tw-border-blue-200 tw-shadow-centered">
        <Tag
          color="dark"
          size="small"
          icon={conditionIsTrue ? 'check' : 'xmark'}
          className="tw-absolute -tw-top-[35px] tw-h-[2rem] tw-ml-[50%] -tw-translate-x-[50%] tw-whitespace-nowrap"
        >
          {conditionIsTrue ? 'Validated' : 'Not validated'}
        </Tag>

        <div
          onClick={() => searchComputation(node.computations)}
          className="tw-p-2 tw-pt-6 tw-flex tw-grow tw-justify-center tw-items-center tw-break-all"
        >
          {prettifyCondition(node.splitConditions)}
        </div>

        <a
          href={`https://studio.madkudu.com/tenant/${tenant}/models/${model.modelId}/model/trees/${conversionTree.index}/nodes/${node.nodexIndex}`}
          target="_blank noopener noreferrer"
          className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-blue-800 hover:tw-no-underline tw-gap-2 tw-border-t tw-border-gray-100 tw-text-center tw-text-blue-700"
        >
          Studio
          <FontAwesomeIcon icon={faExternalLink} size="sm" />
        </a>
        <span
          onClick={() => setUnfolded(!unfolded)}
          className="tw-p-3 tw-w-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-text-blue-800 hover:tw-no-underline tw-rounded-bl-lg tw-rounded-br-lg tw-gap-2 tw-bg-[#d3efff] tw-border-gray-100 tw-text-center tw-text-blue-700"
        >
          {unfolded ? (
            <>
              Hide values{' '}
              <FontAwesomeIcon
                icon={faEyeSlash}
                size="lg"
                className={unfolded ? 'tw-rotate-180' : 'tw-rotate-0'}
              />
            </>
          ) : (
            <>
              Show values{' '}
              <FontAwesomeIcon
                icon={faEye}
                size="lg"
                className={unfolded ? 'tw-rotate-180' : 'tw-rotate-0'}
              />
            </>
          )}
        </span>
      </div>
      {unfolded && (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-4">
          {node.computations.map((computation, index) => (
            <div
              key={index}
              className={`tw-bg-white tw-shadow-centered tw-rounded-md tw-w-full tw-text-xs tw-overflow-hidden fade-in`}
              style={{
                animationDelay: `${70 * index}ms`,
                animationDuration: '300ms',
              }}
            >
              <div className="tw-bg-[#6A88A4] tw-relative tw-w-full tw-flex tw-h-8 tw-items-center tw-justify-center tw-text-white">
                <FontAwesomeIcon
                  icon={faFileUser}
                  size="lg"
                  className="tw-absolute tw-left-2"
                />{' '}
                <span className="tw-inline-block tw-whitespace-nowrap tw-overflow-hidden tw-overflow-ellipsis tw-max-w-[120px]">
                  {computation.name}
                </span>
              </div>
              <div className="tw-p-2 tw-text-center tw-max-w-full">
                {computation.value}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
