import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import FieldSelectionContainer from './fields_selection';

const template = require('./view_mapping_config.pug');

require('bootstrap-table/dist/bootstrap-table');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_workbench': 'go_workbench',
    'click #go_admin': 'go_admin',
    'click #go_back': 'go_back',
    'click #go_back_integrations': 'go_back_integrations',
    'click #go_back_integration_board': 'go_back_integration_board',
  },

  async initialize(options: {
    tenant: number;
    integration: string;
    object: string;
  }) {
    this.tenant = options.tenant;
    this.integration = options.integration;
    this.object = options.object;
    this.userEmail = await app.session.get('user').email;
  },
  go_admin() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}`,
      true
    );
  },
  go_back() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/admin`,
      true
    );
  },
  go_back_integrations() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_back_integration_board() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations/`, true);
  },
  go_workbench() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/workbench`,
      true
    );
  },

  async getPullConfigForTenantAndIntegration(
    tenantId: number,
    integration: string
  ) {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/pull?ignore=true`
    );
    return data.config;
  },

  async renderForm() {
    const pullConfig = await this.getPullConfigForTenantAndIntegration(
      this.tenant,
      this.integration
    );
    ReactDOM.render(
      <FieldSelectionContainer
        tenant={this.tenant}
        email={this.userEmail}
        integration={this.integration}
        field_pulled={pullConfig}
        object={this.object}
      />,
      $('#fieldSelectionContainer', this.$el)[0]
    );
  },

  async render() {
    const html = $(
      '#tpl_main',
      template({ tenant: this.tenant, integration: this.integration })
    ).html();
    await this.$el.html(html);
    this.renderForm();
    return this;
  },
});
