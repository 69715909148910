import React, { HTMLProps, ReactNode } from 'react';

type Props = {
  title?: string;
  children: ReactNode;
  topContent?: ReactNode;
  skeleton?: ReactNode;
  isLoading?: boolean;
};

export function ContainerWhite({
  className = '',
  title,
  topContent,
  children,
  isLoading = false,
  skeleton,
}: Props & HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={`tw-rounded-lg tw-border tw-border-light tw-shadow tw-bg-white ${className} tw-flex tw-flex-col`}
    >
      {title && (
        <div className="tw-flex tw-h-12 tw-items-center tw-border-b tw-border-gray-100 tw-px-7 tw-font-semibold tw-text-md">
          {title}
        </div>
      )}
      {topContent}
      <div
        className={`tw-grow tw-p-7 ${
          isLoading && !skeleton
            ? 'tw-bg-gray-150 tw-animate-pulse'
            : 'tw-bg-white'
        }`}
      >
        {isLoading ? skeleton : children}
      </div>
    </div>
  );
}
