export type StepModel = {
  confirmed: boolean;
};

export const stepData: StepModel[] = [
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
  { confirmed: false },
];
