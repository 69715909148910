import { useQuery } from '@tanstack/react-query';
import {
  CONNECTORS_NAMES,
  DATA_DISCOVERY_EVENT_KEYS,
} from '../../../constants';
import getFormData from '../../../models/formData';
import { getConnectorEvents } from '../../generic/utils';

export default function useDiscoveryEvents(tenant: number, connector: string) {
  const highLevelConnector = connector.includes('salesforce')
    ? 'salesforce'
    : connector;

  const {
    data: discoveryReport,
    refetch,
    isLoading: isLoadingDiscoveryEvents,
  } = useQuery(
    [`discovery-events-${tenant}-${highLevelConnector}`],
    async () => {
      if (connector !== 'madMl') {
        const res = await getConnectorEvents(tenant, highLevelConnector);
        return res.data;
      }
      return {};
    }
  );

  const connectorCampaignKey = DATA_DISCOVERY_EVENT_KEYS[connector];
  const salesforceCampaignKey = DATA_DISCOVERY_EVENT_KEYS.salesforce_campaigns;
  const hasDataset = !!discoveryReport?.dataset;

  const discoveryEvents =
    hasDataset && discoveryReport.dataset[connectorCampaignKey]
      ? discoveryReport.dataset[connectorCampaignKey]
          .reduce((acc: string[], campaign: UnknownObject) => {
            let eventName;
            switch (connector) {
              case CONNECTORS_NAMES.salesforce_campaigns:
                eventName = 'campaign_type';
                break;
              case CONNECTORS_NAMES.salesforce_tasks:
                eventName = 'a_type';
                break;
              default:
                eventName = 'event';
            }
            const discoveryItem = String(campaign[eventName]);

            if (!acc.includes(discoveryItem)) acc.push(discoveryItem);
            return acc;
          }, [])
          .sort()
      : [];

  const discoveryCampaignMemberStatuses =
    hasDataset && discoveryReport.dataset[salesforceCampaignKey]
      ? [
          ...new Set<string>(
            discoveryReport.dataset[salesforceCampaignKey]
              .map((campaign: UnknownObject) => campaign.campaign_member_status)
              .sort()
          ),
        ]
      : [];

  const salesforceFields =
    connector === CONNECTORS_NAMES.salesforce_tasks ||
    connector === CONNECTORS_NAMES.salesforce_campaigns
      ? discoveryEvents
      : [];

  type DefaultFormData = ReturnType<typeof getFormData>;

  const {
    data: defaultFormData,
    isFetching: isLoadingFormData,
    refetch: refreshDefaultFormData,
  } = useQuery<DefaultFormData>(
    [`discovery-default-fields-${tenant}-${connector}`],
    async () =>
      salesforceFields
        ? getFormData(connector, {}, tenant, salesforceFields)
        : null
  );

  const salesforceCampaignMemberStatuses =
    connector === CONNECTORS_NAMES.salesforce_campaigns
      ? discoveryCampaignMemberStatuses
      : [];

  return {
    isLoadingDiscoveryEvents,
    isLoadingFormData,
    salesforceFields,
    discoveryEvents,
    discoveryCampaignMemberStatuses,
    salesforceCampaignMemberStatuses,
    defaultFormData,
    refreshDefaultFormData,
    invalidate: refetch,
  };
}
