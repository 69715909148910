import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import app from '../app';
import { TenantType, UserType } from '../types';
import getQueryParameter from './get_query_parameter';
import populateInputs from './populate_inputs';
import { handleErrors } from './handle_errors';
import { checkPassword } from './check_password';
import { generateReCaptchaV3Token } from './check_recaptcha';
import { isSafeRedirect } from './check_redirect';

const template = require('./signup.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #mki_btn_signup': 'signup',
  },

  render() {
    this.$el.html(template());
    populateInputs();
    this.toggle_signup_content();
    return this;
  },

  toggle_signup_content() {
    const CONTENT: UnknownObject = {
      classic: {
        tagline: 'Accelerate your sales with MadKudu',
        cta: 'Get started with my account',
      },
      opp_scoring: {
        tagline: 'Turbocharge your sales pipeline review',
        cta: "Let's go!",
      },
      zapier: {
        tagline: 'Get your MadKudu API Key',
        cta: "Let's go!",
      },
    };

    const plan = getQueryParameter('plan') || 'classic';
    const content = CONTENT[plan] || CONTENT.classic;
    $('.login__title').text(content.tagline);
    $('#mki_btn_signup').text(content.cta);
    // hack to avoid a flashing display
    $('.hack-wrapper').show();
  },

  track_signup(signupResponse: { tenant?: TenantType; user?: UserType } = {}) {
    const tenantDoc = signupResponse.tenant;
    const tenant = tenantDoc ? tenantDoc._id : undefined;
    const tenant_name = tenantDoc ? tenantDoc.name : undefined;
    const { user } = signupResponse;

    const ref = getQueryParameter('ref');

    // do the segment tomfoolery as soon as we know the user_id
    try {
      window.analytics.alias(user._id);

      // Note: the identify and group are also done in the back-end (for extra accuracy)
      window.analytics.identify(user._id.toString(), {
        email: user.email,
        tenant: tenant.toString(),
        ref,
      });

      if (!/madkudu\.com/.test(user.email)) {
        // avoid doing a group for MadKudu addresses to avoid having us being part of every organization
        window.analytics.group(tenant.toString(), {
          name: tenant_name,
          ref,
        });
      }
      // note: the analytics.track is in the back-end (controllers/user_management/api_signup)
    } catch (e) {
      e.response = signupResponse;
      console.error(e);
    }
  },

  parse_form() {
    const form_content = $('form#signupform');
    const body: UnknownObject = {};
    $.each(form_content.serializeArray(), function (i, field) {
      body[field.name] = field.value;
      // eslint-disable-next-line
      field.value = ''; // https://app.asana.com/0/33091180320398/1118497934278433/f
    });
    return body;
  },

  async signup(e: any) {
    e.preventDefault();
    const body = this.parse_form();
    const ref = getQueryParameter('ref');
    body.ref = ref; // pass the ref along with the login info
    body['g-recaptcha-response'] = await generateReCaptchaV3Token();

    if (checkPassword(body.password, body.email)) {
      try {
        const { data } = await axios.post(`${BONGO_URL}/v1/signup`, body);
        app.session.store(data);
        this.track_signup(data);
        const redirect = getQueryParameter('redirect');
        if (ref === 'intercom' && redirect) {
          window.location.href = isSafeRedirect(redirect)
            ? `${redirect}&onboarding=intercom`
            : '/error/404';
        } else if (ref === 'googlesheet') {
          app.router.navigate(
            `/org/${data.tenant._id}/get-started/googlesheet`,
            {
              trigger: true,
            }
          );
        } else if (redirect) {
          window.location.href = isSafeRedirect(redirect)
            ? redirect
            : '/error/404';
        } else {
          app.router.navigate(`/org/${data.tenant._id}`, { trigger: true });
        }
      } catch (err) {
        await handleErrors(err.response.data);
      }
    }
  },
});
