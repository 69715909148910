import React from 'react';
import Backbone from 'backbone';

type OverviewComponentProps = {
  tenant: number;
  isReadOnly: boolean;
};

export default class OverviewComponent extends React.Component<
  OverviewComponentProps,
  {}
> {
  constructor(props: any) {
    super(props);

    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.handleOnClickCreateSimple = this.handleOnClickCreateSimple.bind(this);
    this.handleOnClickCreateCustom = this.handleOnClickCreateCustom.bind(this);
  }

  goBackToMapping() {
    const { tenant } = this.props;

    Backbone.history.navigate(`org/${tenant}/mapping`, true);
  }

  handleOnClickCreateSimple() {
    const { tenant } = this.props;

    Backbone.history.navigate(
      `org/${tenant}/mapping/attribute_mapping/configuration/simple`,
      true
    );
  }

  handleOnClickCreateCustom() {
    const { tenant } = this.props;

    Backbone.history.navigate(
      `org/${tenant}/mapping/attribute_mapping/configuration/custom`,
      true
    );
  }

  renderCreateNewButton() {
    return (
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Create new configuration
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" onClick={this.handleOnClickCreateSimple}>
            Create new
          </a>
          <a className="dropdown-item" onClick={this.handleOnClickCreateCustom}>
            Create new custom
          </a>
        </div>
      </div>
    );
  }

  renderNoAttributeAlert() {
    return (
      <div className="alert alert-warning" role="alert">
        There is no attribute mapping configured yet!
      </div>
    );
  }

  render() {
    const { isReadOnly } = this.props;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Attribute Mapping
            </li>
          </ol>
        </nav>
        <nav className="navbar bavbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d">
          <p className="m-auto">
            <strong>Overview: </strong>Select the fields from your CRM be used
            in your segmentations.
          </p>
        </nav>
        <div className="container mt-4 p-3">
          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="card box-shadow-3d mb-4">
                <div className="card-header">
                  <h4 className="mb-0">Overview</h4>
                </div>
                <div className="card-body">
                  <p>
                    MadKudu's platform is able to tie your CRM attributes and
                    use them as part of the predictive segmentation. Say if you
                    have an account-level attributes you'd like to map back to a
                    lead, we are able to do that automatically once you have
                    configured them.
                  </p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col text-center">
                  {isReadOnly
                    ? this.renderNoAttributeAlert()
                    : this.renderCreateNewButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
