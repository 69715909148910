export default class FieldDataModel {
  name: string;

  standard: boolean;

  ignore?: boolean;

  constructor(name?: string, standard?: boolean, ignore?: boolean) {
    if (name || standard) {
      this.name = name;
      this.standard = standard;
      this.ignore = ignore;
    } else {
      this.name = '';
      this.standard = false;
      this.ignore = false;
    }
  }
}
