var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (tenant) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E\u003Cdiv class=\"p-2\"\u003E\u003Cdiv" + (" class=\"card box-shadow-3d prototype_box\""+pug.attr("href", '/org/' + tenant + '/sqline_ui', true, true)+" style=\"cursor: pointer\"") + "\u003E\u003Cdiv class=\"card-body\"\u003E\u003Ch4\u003EYAML to SQL\u003C\u002Fh4\u003E\u003Cp\u003ETest your YAML files\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "tenant" in locals_for_with ?
        locals_for_with.tenant :
        typeof tenant !== 'undefined' ? tenant : undefined));
    ;;return pug_html;};
module.exports = template;