import { DelayedText } from './hooks/useDelayedText';
import { Segment } from './types';

export const SEARCH_SCORE_DELAYED_TEXT: DelayedText[] = [
  {
    text: 'Profile data is on its way, we just need a little more time!',
    delay: 5000,
  },
  {
    text:
      "Just a heads up, things are moving a bit slower than anticipated. But don't worry, we're almost there! ⏳",
    delay: 15000,
  },
  {
    text: "I'm sorry for the delay. I just need a little more time.",
    delay: 30000,
  },
];

export const PROFILE_PAGE_QUERY_TIMEOUT = 40000;

export type SegmentConfig = {
  segment: Segment;
  range: [number, number];
  color: string;
  label: string;
  symbol: string;
};

export const SEGMENT_CONFIGS: SegmentConfig[] = [
  {
    segment: 'very good',
    range: [85, 100],
    color: '#3071CC',
    label: 'Very good',
    symbol: '⭐⭐⭐',
  },
  {
    segment: 'good',
    range: [70, 85],
    color: '#61D0C1',
    label: 'Good',
    symbol: '⭐⭐',
  },
  {
    segment: 'medium',
    range: [50, 70],
    color: '#fdc400',
    label: 'Medium',
    symbol: '⭐',
  },
  {
    segment: 'low',
    range: [0, 50],
    color: '#E13655',
    label: 'Low',
    symbol: '❌',
  },
];

export const getSegmentConfig = (segment: Segment): SegmentConfig => {
  return (
    SEGMENT_CONFIGS.find((config) => config.segment === segment) ||
    SEGMENT_CONFIGS[SEGMENT_CONFIGS.length - 1]
  );
};

export const getSegmentRange = (segment: Segment): [number, number] => {
  return getSegmentConfig(segment).range;
};

export const getSegmentSymbol = (segment: Segment): string => {
  return getSegmentConfig(segment).symbol;
};
