import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { ChartDefinition, ChartProperty } from '../../InsightsTypes';

type SpeedometerComponentProps = {
  chartDefinition: ChartDefinition;
  data: UnknownArrayOfObjects;
};

export default class SpeedometerComponent extends React.Component<
  SpeedometerComponentProps
> {
  render() {
    const { chartDefinition, data } = this.props;
    const properties = chartDefinition.properties || [];
    const valueProperty: ChartProperty = properties.find(
      (property) => property.name === 'value'
    );
    const maxValueProperty: ChartProperty = properties.find(
      (property) => property.name === 'maxValue'
    );
    const segmentsProperty: ChartProperty = properties.find(
      (property) => property.name === 'segments'
    );

    const speedometerValue = Number(data[0][valueProperty.value]);

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-auto">
            <div
              style={{
                width: '400px',
                height: '250px',
              }}
            >
              <ReactSpeedometer
                maxValue={Number(maxValueProperty.value)}
                value={speedometerValue}
                fluidWidth={true}
                needleColor="#182b52"
                startColor="#dee2e6"
                textColor="#182b52"
                maxSegmentLabels={5}
                segments={Number(segmentsProperty.value)}
                endColor="#1e77cc"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
