import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Alert } from 'react-bootstrap';

interface CsvDropzoneProps {
  tenant: number;
  email: string;
}

export default function CsvDropzone({ tenant, email }: CsvDropzoneProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isFileGiven, setIsFileGiven] = useState<boolean>(false);
  const [isConfirmedRemoval, setIsConfirmedRemoval] = useState<boolean>(false);
  const [color, setColor] = useState<'dark' | 'info' | 'danger'>('dark');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitCsvFile = async (file: any) => {
    const data = new FormData();
    data.append('fileToUpload', file);
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Delete records from MadKudu database?',
        icon: 'info',
        text:
          'All personal data associated to these emails will be permanently deleted. The data is unretrievable.',
        showCancelButton: true,
        focusConfirm: false,
        reverseButtons: true,
        confirmButtonColor: '#d9534f',
        confirmButtonText: 'Delete records',
        cancelButtonText: 'Cancel',
      });
      if (isConfirmed) {
        setIsConfirmedRemoval(true);
        setIsLoading(true);

        Swal.fire({
          title: 'Request in progress',
          icon: 'info',
          allowOutsideClick: () => !Swal.isLoading(),
        });

        Swal.showLoading();

        await axios.post(`${BONGO_URL}/v1/org/${tenant}/gdpr`, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        await Swal.fire({
          title: 'Success',
          icon: 'success',
          text:
            'Thank you! You will receive an email when your request will be processed.',
        });
        setErrorMessage(null);
      }
    } catch (err) {
      const message =
        err?.response?.status === 400
          ? 'Csv file format is wrong. No valid email found/No email header provided.'
          : 'Please contact our support team.';
      await Swal.fire({
        title: 'Error',
        icon: 'error',
        text: `There was an error sending your request.\n${message}`,
      });
      setErrorMessage(`There was an error sending your request.\n${message}`);
      window.analytics.track('Failed to sent GDPR Request', {
        tenant,
        email,
        message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles: any[]) => {
    window.analytics.track('Dropped file to GDPR Request', {
      tenant,
      email,
    });
    const checkAndSubmitFile = async () => {
      setIsFileGiven(true);

      if (acceptedFiles?.length === 1) {
        if (acceptedFiles[0].type === 'text/csv') {
          await submitCsvFile(acceptedFiles[0]);
          window.analytics.track(`Sent GDPR Request`, {
            tenant,
            email,
          });
        } else {
          setErrorMessage('File is not csv extension.');
          window.analytics.track('Failed to sent GDPR Request', {
            tenant,
            email,
            message: 'File is not csv extension.',
          });
        }
      } else {
        setErrorMessage('More than one file has been provided.');
        window.analytics.track('Failed to sent GDPR Request', {
          tenant,
          email,
          message: 'More than one file has been provided.',
        });
      }
    };
    checkAndSubmitFile();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (isDragActive) {
      setColor('info');
    } else {
      setColor('dark');
    }
    if (errorMessage) {
      setColor('danger');
    }
  }, [isDragActive, errorMessage]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!errorMessage && isFileGiven && isConfirmedRemoval) {
    return (
      <Alert bsStyle="success" className="w-100">
        Thank you! You will receive an email when your request will be
        processed.
      </Alert>
    );
  }

  return (
    <div
      className={`h-50 border rounded border-${color} ${
        isDragActive ? 'shadow rounded bg-light bg-gradient' : ''
      }`}
    >
      <div {...getRootProps()} className="h-100 w-100">
        <input {...getInputProps()} />
        <p className={`drop-zone-title text-${color}`}>
          Upload a CSV containing emails you want to delete from MadKudu
          database.
        </p>
        <p className={`drop-zone-text text-${color}`}>
          {isDragActive ? 'Drop it here!' : 'Drag and drop your file here.'}
        </p>
      </div>
      {isFileGiven && errorMessage && (
        <Alert bsStyle="danger" className="w-100 mt-2">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}
