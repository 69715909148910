import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnowflakeContext } from './contexts/SnowflakeContext';
import { Loader } from '../../components/Loader';
import { SnowflakeForm } from './SnowflakeForm';
import { SnowflakeOverview } from './SnowflakeOverview';

export function Snowflake() {
  const {
    tenant,
    editMode,
    setEditMode,
    getCredentials,
    setCredentials,
  } = useSnowflakeContext();

  const { data: savedCredentials, isLoading } = useQuery(
    ['snowflake', 'credentials', tenant],
    getCredentials
  );

  useEffect(() => {
    if (savedCredentials) {
      setCredentials(savedCredentials);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  }, [savedCredentials]);

  if (isLoading) return <Loader className="mx-auto mt-4 w-50" />;

  if (editMode) return <SnowflakeForm />;

  return <SnowflakeOverview />;
}
