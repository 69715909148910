import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import {
  INVALID_GITHUB_TOKEN_ERROR,
  GithubCredentials,
  KNOWN_GITHUB_ERRORS,
} from '../types';

export function useSaveGithubCredentials(
  tenant: number,
  options?: UseMutationOptions<
    GithubCredentials,
    unknown,
    GithubCredentials,
    unknown
  >
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (credentials: GithubCredentials) => {
      await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/github/credentials`,
        credentials
      );

      // validate key
      try {
        const { data } = await axios.get<{
          valid: boolean;
        }>(`${BONGO_URL}/v1/org/${tenant}/integrations/github/ping`);

        if (!data.valid) throw Error(INVALID_GITHUB_TOKEN_ERROR);
      } catch (error) {
        if (!KNOWN_GITHUB_ERRORS.includes(error.message))
          throw new Error('Uncaught Github validation error: ' + error.message);
        else throw error;
      }

      return credentials;
    },
    ...options,
    onSuccess: (data, variables, ctx) => {
      queryClient.setQueryData(['github', tenant], true);
      queryClient.setQueryData(['github-credentials', tenant], variables);
      options?.onSuccess?.(data, variables, ctx);
    },
  });
}
