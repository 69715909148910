import React from 'react';
import { deleteIntegrationAndLaunchPopUp } from '../../utils';

type ConfigurationComponentProps = {
  tenant: number;
  integration: string;
  loading: boolean;
  isConnected: boolean;
};

export default function ConfigurationComponent(
  props: ConfigurationComponentProps
) {
  const { tenant, integration, loading, isConnected } = props;

  const redirect_to_oauth = () => {
    window.location.href = `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/oauth/redirect`;
  };

  const deleteConnection = async () => {
    await deleteIntegrationAndLaunchPopUp(tenant, integration);
  };

  return (
    <>
      {loading && <div>Loading...</div>}
      {isConnected && !loading && (
        <>
          <p>
            Status:{' '}
            <b className="text-success">
              You're connected to Clearbit Enrichment
            </b>
          </p>
          <p>
            You are connected to Clearbit. You can update your connection or
            delete by clicking on the corresponding button below
          </p>
          <div className="mt-4">
            <button
              className="btn btn-primary"
              onClick={() => redirect_to_oauth()}
            >
              Update connection
            </button>
            <button
              className="btn btn-danger ml-2"
              onClick={() => deleteConnection()}
            >
              Delete connection
            </button>
          </div>
        </>
      )}
      {!isConnected && !loading && (
        <>
          <p>
            Status:{' '}
            <b className="text-danger">
              You're not connected to Clearbit Enrichment
            </b>
          </p>
          <p>
            Click on the button below to authorize MadKudu in Clearbit (this
            will redirect you to Clearbit, then bring you back here)
          </p>
          <button
            className="btn btn-primary"
            onClick={() => redirect_to_oauth()}
          >
            Connect Clearbit Enrichment
          </button>
        </>
      )}
    </>
  );
}
