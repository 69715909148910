enum SourceSystems {
  hubspot = 'hubspot',
  salesforce = 'salesforce',
  segment = 'segment',
  mixpanel = 'mixpanel',
  marketo = 'marketo',
  kissmetrics = 'kissmetrics',
  amplitude = 'amplitude',
}

export default SourceSystems;
