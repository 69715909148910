import React, { Component } from 'react';

export default class RevenueDistributionComponent extends Component<{}, {}> {
  render() {
    return (
      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
            <div className="card-body">
              <i className="far fa-compass fa-lg mt-2"></i>
              <h5 className="mt-2">
                <strong>Guide: </strong>
                Revenue Distribution
              </h5>
              <div className="border-top mt-2 border-primary"></div>
              <i className="fas fa-flask fa-lg mt-2"></i>
              <h5 className="mt-2">
                <strong>How MadKudu Computes This:</strong>
              </h5>
              <p className="mt-2">
                How much of your revenue generated in the last 6 months are
                scored as very good, good, medium or low by the MadKudu's
                Customer Fit model. See Mapping &gt; Conversion Mapping page to
                understand which amount field in your CRM the revenue amount is
                based on. This takes into account only Closed Won opportunities.
                We typically count the first conversion won tied to an email
                address in your CRM database.
              </p>
              <h5 className="mt-2">
                <strong>How to Interpret This:</strong>
              </h5>
              <p>
                This section aims to showcase the trend in the overall revenue
                generated in the last 6 months that have been scored as very
                good or good by MadKudu's Customer Fit model.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
