import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BackboneViewProps } from '../../../../types';
import app from '../../../../app';
import { isUserReadOnly } from '../../../../utils';
import ConversionMappingManager from '../../models/ConversionMappingManager';
import StandardParameterComponent from './components/StandardParameterComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.user = app.session.get('user');
    this.email = this.user.email;
    this.isReadOnly = isUserReadOnly(this.user);

    if (this.isReadOnly) {
      Backbone.history.navigate('error/403', true);
      return this;
    }

    this.conversionMappingDataManager = new ConversionMappingManager(
      this.tenant,
      this.email,
      this.isReadOnly,
      this.user.superKudu
    );

    await this.conversionMappingDataManager.init();

    // Not yet ready to be here
    if (
      !this.conversionMappingDataManager.isFinished &&
      this.conversionMappingDataManager.step === 0
    ) {
      Backbone.history.navigate(
        `org/${this.tenant}/mapping/conversion_mapping`,
        true
      );
    }

    this.render();
    this.renderConnectorsOverviewReactComponent();
  },

  renderConnectorsOverviewReactComponent() {
    ReactDOM.render(
      <StandardParameterComponent
        conversionMappingDataManager={this.conversionMappingDataManager}
      />,
      $('#StandardParameterComponentContainer')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
