import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../../app';
import PerformancesParentComponent from './PerformancesParentComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_to_event_mapping': 'goEventMapping',
    'click #go_to_mapping': 'goMapping',
  },

  async initialize(options: { tenant: number; id: string }) {
    this.tenant = options.tenant;
    this.modelId = options.id;
    this.isSuperKudu = await app.session.get('user').superKudu;
    this.userEmail = await app.session.get('user').email;

    this.render();
    this.renderPerformanceParentComponent();
  },

  goTo(url: string) {
    Backbone.history.navigate(url, true);
  },

  renderPerformanceParentComponent() {
    ReactDOM.render(
      <PerformancesParentComponent
        isSuperkudu={this.isSuperKudu}
        tenant={this.tenant}
        modelId={this.modelId}
        userEmail={this.userEmail}
        goTo={this.goTo}
      />,
      $('#predictionParentComponentContainer')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
