import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useCognismIsConnected } from './hooks/useCognismIsConnected';
import { Tab } from './CognismIntegration';

type Props = {
  tenant: number;
  changeActiveTab: (tab: Tab) => void;
  hasAccessToConfiguration: boolean;
};

export function CognismOverview({ tenant, changeActiveTab }: Props) {
  const { data: apiKey, isLoading } = useCognismIsConnected(tenant);

  return (
    <div className="d-flex">
      <div className="row w-75 mr-4">
        <div className="col">
          <div className="row justify-space-between">
            <div className="col-8">
              <h5>Overview</h5>
              <div>
                MadKudu integrates with{' '}
                <a
                  href="https://www.cognism.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cognism
                </a>{' '}
                to enhance MadKudu Copilot as a comprehensive solution for
                targeted sales prospecting. Sellers no longer need to search
                outside MadKudu to find and engage the right buyers. Through
                MadKudu Copilot, users seamlessly access Cognism's vast database
                to identify essential buyer contacts not yet in their systems.
              </div>
            </div>
          </div>

          <div className="mt-3">
            <h5>How MadKudu x Cognism works</h5>
            <div>
              <h6>Pre-requisite</h6>
              <ul className="pl-3">
                <li>
                  You must have a{' '}
                  <a
                    href="https://www.cognism.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cognism
                  </a>{' '}
                  account with API access
                </li>
              </ul>
            </div>
            <div>
              <h5>Once Cognism is connected, your users will be able to</h5>
              <ul className="pl-3">
                <li>Discover key missing contacts in their book of accounts</li>
                <li>Add these new contacts into Salesforce</li>
                <li>
                  Add these new contacts to Gong flows, Outreach sequences,
                  Salesloft cadences
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-25">
        <p>
          <b>Status</b>
          <span className="d-block pt-1">
            {isLoading ? (
              <span className="text-secondary">loading...</span>
            ) : (
              <span
                className="cursor-pointer d-block"
                onClick={() => changeActiveTab('configuration')}
              >
                {apiKey ? (
                  <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Connected
                  </b>
                ) : (
                  <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Not Connected
                  </b>
                )}
              </span>
            )}
          </span>
        </p>
        <p className="mt-4">
          <b>Links</b>
        </p>
        <p>
          <a
            href="https://help.madkudu.com/docs/cognism"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to connect Cognism to MadKudu
          </a>
        </p>
        <p>
          <a
            href="https://developers.cognism.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cognism API documentation
          </a>
        </p>
      </div>
    </div>
  );
}
