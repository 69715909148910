import React from 'react';

const headerTexts = [
  'Choose the data model to push',
  'Choose your data destination',
  'Map the data to your destination',
  'Review your configuration',
];

const SubHeader = (props: { pageName: string }) => {
  const { pageName } = props;
  let configurationStep: number;
  switch (pageName) {
    case 'model':
      configurationStep = 1;
      break;
    case 'connector':
      configurationStep = 2;
      break;
    case 'dataMapping':
      configurationStep = 3;
      break;
    case 'review':
      configurationStep = 4;
      break;
    default:
      break;
  }
  return (
    <div className="navbar-expand-lg shadow-sm p-3 mb-4 bg-white rounded text-center border-top">
      <p className="m-auto">
        <strong>Step {configurationStep}/4:</strong>{' '}
        {headerTexts[configurationStep - 1]}
      </p>
    </div>
  );
};

export default SubHeader;
