import axios from 'axios';
import {
  AudiencePerformanceData,
  FeaturesType,
  MappingConversionsTypes,
} from '../types';

export async function getModelData(
  tenant: number,
  modelId: number
): Promise<any> {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/models/${modelId}`,
    {
      item: { tenant, id: modelId },
    } as any
  );
  return data;
}

export async function getAudiencesData(tenant: number): Promise<any> {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/data/mappings/audiences`
  );
  return data;
}

export function extractActiveConversionTypeFromAudience(
  audiencePerformanceData: AudiencePerformanceData
): string[] {
  return Object.keys(audiencePerformanceData).map((conversionTypeName) => {
    return conversionTypeName as MappingConversionsTypes;
  });
}

export async function getAudiencesNames(tenant: number): Promise<string[]> {
  try {
    const data = await getAudiencesData(tenant);
    const audiencesNames = data.length
      ? data.map((audience: UnknownObject) => audience.name)
      : [];
    if (!audiencesNames.includes('all')) audiencesNames.unshift('all');
    return audiencesNames.reverse();
  } catch (_e) {
    return ['all'];
  }
}

export function getCleanObject(object: UnknownObject | UnknownArray) {
  return JSON.parse(JSON.stringify(object));
}

export async function isIntegrationActive(tenant: number, integration: string) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}`
  );
  return data && data.name;
}

// Admins + Architects can Edit Conversion + Audience + Attributes mapping
export function isUserReadOnly(user: any): boolean {
  return !user.superKudu && user.role !== 'architect' && user.role !== 'admin';
}

export async function createAsanaTask(
  tenant: number,
  feature: FeaturesType,
  name: string,
  notes: string
) {
  const workspaceId = '12635457239181';
  const insightsProjectId = '1138928499489829';
  let taskParameters;
  if (feature === 'insights') {
    taskParameters = {
      notes,
      name,
      workspace: workspaceId,
      projects: [insightsProjectId],
    };
  }
  try {
    await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/services/asana`,
      taskParameters
    );
  } catch (error) {
    console.error(error);
  }
}

export async function slackNotification(
  tenant: number,
  feature: FeaturesType,
  text: string,
  channel: string
) {
  let notificationParameters = {};
  if (feature === 'insights') {
    notificationParameters = {
      text,
      channel,
      icon_emoji: ':bar_chart:',
      username: 'Insights report request',
    };
  }
  if (feature === 'mappings') {
    notificationParameters = {
      text,
      channel,
      icon_emoji: ':safety_vest:',
      username: 'Customer Safety Bot',
    };
  }
  if (feature === 'push') {
    notificationParameters = {
      text,
      channel,
      icon_emoji: ':safety_vest:',
      username: 'Push Configuration',
    };
  }
  try {
    await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/services/slack`,
      notificationParameters
    );
  } catch (error) {
    console.error(error);
  }
}

export function snakeCaseToHumanReadable(field: string): string {
  return field
    .split('_')
    .filter((f) => f.trim().length > 0)
    .map((f) => f.trim().charAt(0).toUpperCase() + f.slice(1))
    .join(' ');
}
