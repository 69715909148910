import Mn from 'backbone.marionette';
import $ from 'jquery';
import app from './app';
import View404 from './errors/404';
import View403 from './errors/403';
import { BackboneViewProps } from './types';

const AuthController = (Mn.Object as any).extend({
  initialize(options: BackboneViewProps) {
    this.error_code = options.error_code;
  },

  change_title(title: string) {
    const newTitle = title ? `${title} | MadKudu` : 'MadKudu';
    $(document).attr('title', newTitle);
  },

  track_page(title: string) {
    return title && window.analytics && window.analytics.page(title);
  },

  transition_view(View: any, options: { title?: string } = {}) {
    const { title } = options;
    this.track_page(title);
    this.change_title(title);
    // eslint-disable-next-line
    app.root.showChildView(
      'main', // eslint-disable-next-line
      View.default ? new View.default() : new View()
    );
  },

  error(error_code: string) {
    if (error_code === '403') {
      this.transition_view(View403, { title: error_code });
    } else {
      this.transition_view(View404, { title: error_code });
    }
  },
});

export default Mn.AppRouter.extend({
  appRoutes: {
    'error/:error_code(/)': 'error',
  },

  initialize() {
    this.controller = new AuthController({});
  },
});
