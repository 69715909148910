import * as React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLProps<HTMLFormElement>;

export const Form = React.forwardRef<HTMLFormElement, Props>(function Form(
  props: Props,
  ref
) {
  const { children, className = '', ...rest } = props;
  return (
    <form ref={ref} className={`tw-p0 tw-m-0 ${className}`} {...rest}>
      {children}
    </form>
  );
});
