import axios from 'axios';
import app from '../app';

export default function (tenant: number, body: {}, callback = () => {}) {
  return axios
    .post(`${BONGO_URL}/v1/org/${tenant}/processes/`, body)
    .then(() => {
      app.notifications.success('Process launched.', { dismiss_timeout: 2000 });
      callback();
    })
    .catch((err: any) => {
      app.notifications.error('There was an error sending your request.', {
        dismiss_timeout: 5000,
      });
      callback();
      throw err;
    });
}
