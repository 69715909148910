import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import Swal from 'sweetalert2';

require('bootstrap-toggle');

const adminTenantTemplate = require('./create_tenant.pug');

export default Backbone.View.extend({
  events: {
    'click #btn_create_tenant': 'createTenant',
  },

  async createTenant(e: any) {
    e.preventDefault();
    const name = $('#tenant_name').val();
    const domain = $('#tenant_domain').val();
    try {
      const { data } = await axios.post(`${BONGO_URL}/v1/org`, {
        name,
        domain,
      });
      Swal.fire({
        title: 'Tenant created',
        text: `The tenant ${data._id} was created with success`,
        icon: 'success',
      });
      $('#tenant_name').val('');
      $('#tenant_domain').val('');
    } catch (e2) {
      const err =
        e2 && e2.response && e2.response.data && e2.response.data.message;
      Swal.fire({
        title: 'Error while creating the tenant',
        text: `${e2} - ${err}`,
        icon: 'error',
      });
    }
  },

  initialize() {
    this.render();
  },

  render() {
    this.$el.empty();
    this.$el.html(adminTenantTemplate());
    return this;
  },
});
