import React from 'react';
import Alert from '../../../components/Alert';

export default function LegacyAuthMethodWarning() {
  return (
    <div className="tw-w-full">
      <Alert variant="warning">
        <div className="p-2">
          <b>Warning!</b> Project tokens{' '}
          <a
            href="https://developer.mixpanel.com/reference/project-secret"
            target="_blank"
            rel="noreferrer"
            className="tw-text-orange-700 focus:tw-text-orange-900 hover:tw-text-orange-800"
          >
            are being deprecated
          </a>{' '}
          for new customers, please use{' '}
          <a
            href="https://developer.mixpanel.com/reference/service-accounts"
            target="_blank"
            rel="noreferrer"
            className="tw-text-orange-700 focus:tw-text-orange-900 hover:tw-text-orange-800"
          >
            Service Accounts
          </a>{' '}
          instead.
          <br />
          Mixpanel will continue to support Project tokens for legacy customers
          indefinitely.
        </div>
      </Alert>
    </div>
  );
}
