import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { BackboneViewProps } from '../types';

const channel = Radio.channel('integration');
const template = require('./database.pug');

export default Backbone.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
  },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'database';
    this.render();
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_main', template()).html();
    // eslint-disable-next-line
    axios
      .get(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials`
      )
      .then((response) => {
        const credentials = response.data;
        this.$el.html(html);
        $('#mki_input_server').val(credentials.server);
        $('#mki_input_port').val(credentials.port);
        $('#mki_input_database').val(credentials.database);
        $('#mki_input_username').val(credentials.username);
        $('#mki_input_password').val(credentials.password);
      });
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();
    const credentials: UnknownObject = {};
    // Server field
    if ($('#mki_input_server').val() === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Please enter the address of the server to connect to.',
      });
    }
    credentials.server = $('#mki_input_server').val();
    // Port field
    if ($('#mki_input_port').val() === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Please enter a port.',
      });
    }
    credentials.port = $('#mki_input_port').val();
    // Database field
    if ($('#mki_input_database').val() === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Please enter the name of the database to connect to.',
      });
    }
    credentials.database = $('#mki_input_database').val();
    // Port field
    if ($('#mki_input_username').val() === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Please enter a username.',
      });
    }
    credentials.username = $('#mki_input_username').val();
    // Port field
    if ($('#mki_input_password').val() === '') {
      return Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Please enter a password.',
      });
    }
    credentials.password = $('#mki_input_password').val();

    const show_error_message = function (text: string) {
      Swal.fire({
        title: 'Oops',
        text,
        icon: 'error',
      });
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true`,
        credentials
      )
      .then(() => {
        channel.trigger('success', { integration: this.integration });
      })
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  },
});
