import isEqual from 'lodash/isEqual';
import { useState } from 'react';

/**
 * Split a React state into a currently saved value (`originalValue`)
 * and a mutable draft value (`currentValue`).
 */
export default function useDraft<T = unknown>(initialValue: T) {
  const [originalState, setOriginalState] = useState(initialValue);
  const [currentState, setCurrentState] = useState(initialValue);

  return {
    /** Currently saved value. */
    originalState,
    /** Explicitly mutates original state value. */
    setOriginalState,
    /** Current draft value. */
    currentState,
    /** Explicitly mutates current draft value. */
    setCurrentState,
    /** Reports difference between original and current state, if any. Uses deep comparison. */
    hasUnsavedChanges: !isEqual(originalState, currentState),
    /** Resets current state to original state value. */
    reset: () => setCurrentState(originalState),
  };
}
