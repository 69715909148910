const Calculator: any = function () {};

Calculator.prototype.compute_mrr_breakdown = function () {
  const cumulated_existing_customer_revenue: UnknownObject = {
    0: this.starting_mrr,
  };
  const cumulated_new_customer_revenue: UnknownObject = { 0: 0 };
  const cumulated_upsell_revenue: UnknownObject = { 0: 0 };
  const cumulated_churn: UnknownObject = { 0: 0 };
  let i;
  // calculate monthly numbers
  for (i = 1; i <= this.number_of_month; i += 1) {
    cumulated_existing_customer_revenue[i] =
      cumulated_existing_customer_revenue[i - 1] * (1 - this.churn);
    cumulated_new_customer_revenue[i] =
      cumulated_new_customer_revenue[i - 1] * (1 - this.churn) +
      this.revenue_growth;
    cumulated_upsell_revenue[i] =
      cumulated_upsell_revenue[i - 1] * (1 - this.churn) +
      (cumulated_existing_customer_revenue[i - 1] +
        cumulated_new_customer_revenue[i - 1] +
        cumulated_upsell_revenue[i - 1]) *
        this.upsell;
    cumulated_churn[i] =
      cumulated_churn[i - 1] -
      (cumulated_existing_customer_revenue[i - 1] +
        cumulated_upsell_revenue[i - 1] +
        cumulated_upsell_revenue[i - 1]) *
        this.churn;
  }
  this.mrr_breakdown = [
    [
      'Months',
      'Revenue from new customers',
      'Revenue from upsells',
      'Revenue from existing customers',
      'Lost revenue from churn',
    ],
  ];
  for (i = 1; i <= this.number_of_month; i += 1) {
    this.mrr_breakdown.push([
      i.toString(),
      cumulated_new_customer_revenue[i],
      cumulated_upsell_revenue[i],
      cumulated_existing_customer_revenue[i],
      -cumulated_churn[i],
    ]);
  }
  // return this.mrr_breakdown;
};

Calculator.prototype.compute_mrr = function () {
  this.mrr = [['Months', 'MRR']];
  for (let i = 1; i < this.mrr_breakdown.length; i += 1) {
    const month_mrr_breakdown = this.mrr_breakdown[i];
    this.mrr.push([
      month_mrr_breakdown[0],
      month_mrr_breakdown[1] + month_mrr_breakdown[2] + month_mrr_breakdown[3],
    ]);
  }
};

Calculator.prototype.compute = function (
  starting_mrr: number,
  revenue_growth: number,
  churn: number,
  upsell: number,
  number_of_month: number
) {
  this.starting_mrr = starting_mrr;
  this.revenue_growth = revenue_growth;
  this.churn = churn;
  this.upsell = upsell;
  this.number_of_month = number_of_month;
  this.compute_mrr_breakdown();
  this.compute_mrr();
};

export default Calculator;
