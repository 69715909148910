import React from 'react';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { Computation } from '../types';
import { Loader } from '../../../components/Loader';

interface ComputationsSelectProps {
  tenant: number;
  computationsFields: string[];
  handleComputationsChange: (computations: string[]) => void;
}

export default function ComputationsSelect({
  tenant,
  computationsFields,
  handleComputationsChange,
}: ComputationsSelectProps) {
  const { data: computations, isFetching, error } = useQuery(
    [tenant, 'computations'],
    async () => {
      const { data } = await axios.get<Computation[]>(
        `${BONGO_URL}/v1/org/${tenant}/mkobjects/contact/fields`
      );
      return data.map(({ name }) => name);
    }
  );

  if (isFetching) return <Loader />;

  if (error)
    return <span>An error has happened while loading computations</span>;

  return (
    <Select
      placeholder="Select field..."
      isMulti
      value={computationsFields.map((computation) => ({
        label: computation,
        value: computation,
      }))}
      options={computations.map((computation) => ({
        label: computation,
        value: computation,
      }))}
      onChange={(newValue) => {
        handleComputationsChange(newValue.map(({ value }) => value));
      }}
    />
  );
}
