import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useBigQueryContext } from '../contexts/BigQueryContext';
import { Select } from '../../../components/ui';

type Props = {
  nextStep: () => void;
  previousStep: () => void;
};

export function ProjectConfigurationStep({ nextStep, previousStep }: Props) {
  const {
    tenant,
    credentials,
    getDatasets,
    setCredentials,
    testCredentials,
  } = useBigQueryContext();

  const tablesCacheKey = [
    'bigquery',
    'datasets',
    tenant,
    credentials?.tokens?.refresh_token ?? '',
    credentials?.projectId ?? '',
  ];
  const { data: datasets, isFetching: fetchingDatasets, error } = useQuery(
    tablesCacheKey,
    getDatasets,
    {
      enabled: !!credentials?.projectId,
    }
  );

  useEffect(() => {
    if (error) {
      let message = 'unknown error';
      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.data) {
            message = error.response.data.message;
          }
        }
      } else if (error instanceof Error) {
        message = error.message;
      }
      Swal.fire({
        title: 'Error fetching datasets',
        text: `There was an error fetching the datasets (${message}). Please try again.`,
        icon: 'error',
      });
    }
  }, [error]);

  const { mutate: test, isLoading: isTesting } = useMutation(testCredentials);

  const { projectId, dataset, location } = credentials ?? {};

  const handleNextClick = async () => {
    if (!credentials?.projectId || !credentials?.dataset) {
      Swal.fire({
        title: 'Invalid project configuration',
        text: 'Please fill all the required fields',
        icon: 'error',
      });

      return;
    }

    test(null, {
      onSettled(state, settleError) {
        if (settleError || !state) {
          Swal.fire({
            title: 'Invalid project configuration',
            text:
              'MadKudu is unable to connect with this project configuration. Please check them and try again.',
            icon: 'error',
          });
        } else {
          nextStep();
        }
      },
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="card box-shadow-3d mb-3">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <img
                  className="con-map-mk-integration-logo-size"
                  style={{ width: '30px' }}
                  src="/media/integrations/logos/bigquery-icon.png"
                />
                <h5 className="mb-0 ml-3">BigQuery</h5>
              </div>
            </div>
            <div className="card-body">
              <h5>Project configuration</h5>
              <p className="mt-3">
                Please define the project identifier and dataset name for your
                data.
              </p>

              <p className="mt-3">To locate your project ID:</p>
              <ol>
                <li>
                  Go to the{' '}
                  <a
                    href="https://console.cloud.google.com/bigquery"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    API Console
                  </a>
                  .
                </li>
                <li>
                  From the projects list, select{' '}
                  <strong>Manage all projects</strong>. The names and IDs for
                  all the projects you're a member of are displayed.
                </li>
              </ol>

              <form>
                <div className="form-group row">
                  <label
                    htmlFor="projectId"
                    className="col-sm-2 col-form-label"
                  >
                    Project Id <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="projectId"
                      name="projectId"
                      value={projectId ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          projectId: event.target.value,
                        });
                      }}
                      aria-describedby="projectIdHelp"
                    />
                    <small id="projectIdHelp" className="form-text text-muted">
                      The project identifier. Important to select dataset.
                    </small>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="dataset" className="col-sm-2 col-form-label">
                    Dataset <sup className="text-danger">*</sup>
                  </label>
                  <div className="col-sm-10 my-auto">
                    {fetchingDatasets ? (
                      <span>Loading datasets...</span>
                    ) : (
                      <Select
                        value={
                          dataset
                            ? {
                                label: dataset,
                                value: dataset,
                              }
                            : null
                        }
                        name="dataset"
                        placeholder="Select the dataset"
                        onChange={(option) =>
                          setCredentials({
                            ...credentials,
                            dataset: option?.value ?? '',
                          })
                        }
                        options={datasets?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        isClearable
                        isDisabled={!datasets?.length}
                      />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="location" className="col-sm-2 col-form-label">
                    Location
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      value={location ?? ''}
                      required
                      onChange={(event) => {
                        setCredentials({
                          ...credentials,
                          location: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 pb-4 text-center">
        <button
          className="btn btn-light mr-2"
          disabled={isTesting}
          onClick={previousStep}
        >
          Previous
        </button>
        <button
          className="btn btn-primary"
          disabled={isTesting}
          onClick={handleNextClick}
        >
          {isTesting ? 'Testing project configuration...' : 'Next'}
        </button>
      </div>
    </>
  );
}
