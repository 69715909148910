import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BackboneViewProps } from '../../types';
import PushConfiguration from './PushConfiguration';
import app from '../../app';

const template = require('./push.pug');

export default (Backbone as any).Epoxy.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    this.pageName = options.pageName;
    this.user = this.session.get('user');
    this.isSuperKudu = this.user.superKudu;
    this.userCanEdit = this.user.role === 'admin' || this.isSuperKudu;
    this.userIsReadonly = !this.userCanEdit;
    this.userEmail = this.user.email;
    this.render();

    setTimeout(() => {
      this.renderPushConfiguration();
    }, 0);
  },

  toggleView(tenant: string) {
    return (page: string) => {
      Backbone.history.navigate(`/org/${tenant}/push/${page}`);
    };
  },

  renderPushConfiguration() {
    ReactDOM.render(
      <PushConfiguration
        isSuperKudu={this.isSuperKudu}
        tenant={this.tenant}
        userEmail={this.userEmail}
        toggleView={this.toggleView(this.tenant)}
        pageName={this.pageName}
        userCanEdit={this.userCanEdit}
        userIsReadonly={this.userIsReadonly}
      />,
      $('#pushConfiguration')[0]
    );
  },

  render() {
    const html = template();
    this.$el.html(html);
    return this;
  },

  save(event: any) {
    event.preventDefault();
    this.model.save(null, {
      success() {
        app.notifications.success('Configuration updated successfully.', {
          dismiss_timeout: 2000,
        });
      },
      error() {
        console.error(
          'Oops!',
          'There was an error saving your config',
          'error'
        );
      },
    });
  },
});
