import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

function Badge({ children }: Props) {
  return (
    <div className="tw-text-sm tw-border tw-border-blue-700 tw-uppercase tw-text-blue-700 tw-px-1  tw-font-semibold tw-inline-flex tw-items-center">
      {children}
    </div>
  );
}

export default Badge;
