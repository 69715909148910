import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useMixpanelIsConnected } from '../hooks/useMixpanelIsConnected';
import { Loading } from '../../utils/Loading';
import { useMixpanelCredentials } from '../hooks/useMixpanelCredentials';
import { MixpanelForm } from './MixpanelForm';
import FieldsMapping from '../../fields_mapping';
import { UserServiceModel } from '../../../settings/users/types';

type Props = {
  tenant: number;
  hasAccessToConfiguration: boolean;
  user: UserServiceModel;
};

export function MixpanelOverview({ tenant, hasAccessToConfiguration }: Props) {
  const { data: isConnected } = useMixpanelIsConnected(tenant);
  const { isLoading: isLoadingCredentials } = useMixpanelCredentials(tenant);
  const isLoading = isLoadingCredentials;

  // NOTE: To be replaced by a proper React-based implementation of the FieldsMapping component.
  // once ready (see PullFieldsMapping.tsx).
  useEffect(() => {
    if (!isLoading && isConnected) {
      const backboneComponent = new FieldsMapping({
        tenant,
        integration: 'mixpanel',
        credentialsFound: true,
      });
      const target = document.getElementById('fields_mapping');
      if (target) backboneComponent.setElement(target);
    }
  }, [isLoading, isConnected]);

  if (isLoading) return <Loading loadingLabel="Checking Mixpanel connection" />;

  return (
    <>
      <div>
        <div className="d-flex">
          <div className="row w-75 mr-4">
            <div className="col">
              <div className="row justify-space-between">
                <div className="col-10">
                  <h5>Overview</h5>
                  <div>
                    Connect your Mixpanel account to MadKudu, and start
                    leveraging your Mixpanel data to score your leads and
                    accounts.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-25">
            <p>
              <b>Status</b>
              <span className="d-block pt-1">
                <span className="cursor-pointer d-block">
                  {isConnected && (
                    <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                      <FontAwesomeIcon
                        icon={faGear}
                        width="14"
                        height="14"
                        className="mr-1"
                      />
                      Connected
                    </b>
                  )}
                  {!isConnected && (
                    <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                      <FontAwesomeIcon
                        icon={faGear}
                        width="14"
                        height="14"
                        className="mr-1"
                      />
                      Not Connected
                    </b>
                  )}
                </span>
              </span>
            </p>
          </div>
        </div>

        <div className="row mt-4 mr-4">
          <div className="col">
            <div className="row justify-space-between">
              <div className="px-2 col-12">
                <MixpanelForm
                  tenant={tenant}
                  hasAccessToConfiguration={hasAccessToConfiguration}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fields_mapping" />
    </>
  );
}
