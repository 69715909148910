import React from 'react';
import { Button } from 'react-bootstrap';
import ModelDataModel from '../../../models/model/ModelDataModel';

type PredictionHeaderComponentProps = {
  isSuperKudu: boolean;
  hasOverrides: boolean;
  model: ModelDataModel;
  userEmail: string;
  tenant: number;
  labels: string[];
  overrides: any;
  goTo: (url: string) => void;
};

export default class PredictionHeaderComponent extends React.Component<
  PredictionHeaderComponentProps,
  {}
> {
  render() {
    const { isSuperKudu, tenant, model, goTo } = this.props;
    const { modelId, name, is_primary, live } = model;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between"
        aria-label="breadcrumb"
      >
        <ul className="navbar-nav navbar-brand mr-auto.mt-2 mt-lg-0">
          <li className="breadcrumb-item">
            <a
              className="breadcrumb-link text-primary"
              onClick={() => goTo(`/org/${tenant}/predictions`)}
            >
              Predictions
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Model: Customer Fit{live && !is_primary ? ` - ${name}` : ''}
          </li>
        </ul>
        <div>
          <Button
            className="btn btn-primary my-2 my-lg-0 mx-1"
            onClick={() =>
              goTo(
                `/org/${tenant}/predictions/performances/models/${modelId}/diagnostics`
              )
            }
          >
            Diagnostics
          </Button>
          {isSuperKudu && (
            <Button
              className="btn btn-outline-primary my-2 my-lg-0 mx-1"
              onClick={() =>
                goTo(
                  `/org/${tenant}/predictions/performances/models/${modelId}/admin`
                )
              }
            >
              Admin
            </Button>
          )}
        </div>
      </nav>
    );
  }
}
