import React from 'react';
import { Icon } from '../../components/ui';

type Props = {
  loadingLabel?: string;
};

export function Loading({ loadingLabel = 'Loading' }: Props) {
  return (
    <div className="tw-w-full tw-inline-flex tw-justify-center">
      <div className="tw-flex tw-flex-col tw-items-center">
        <Icon name="load" />
        <span className="tw-text-gray-300 tw-mt-3">{loadingLabel}...</span>
      </div>
    </div>
  );
}
