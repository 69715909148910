import Backbone from 'backbone';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import app from '../app';
import { BackboneViewProps } from '../types';
import Tenant from './model_tenant_admin';

const template = require('./admin.pug');

const template_403 = require('../errors/403.pug');

const Admin_view = (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_save': 'event_save',
    'click #btn_prepare_tenant': 'prepare_tenant',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.model = new Tenant({ tenant: this.tenant });
    this.model.fetch({
      success: () => this.render(),
      error: () => this.render_error(),
    });
    const { data } = await axios.get(`${BONGO_URL}/v1/org/${this.tenant}`);
    const { superKudu } = app.session.get('user');
    this.status = data.status?.active;
    this.prepareButtonEnable = superKudu && !this.status;
    this.render();
  },

  render() {
    const html = $(
      '#tpl_admin',
      template({ prepareButtonEnable: this.prepareButtonEnable })
    ).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },

  event_save(e: any) {
    e.preventDefault();
    this.model.save(null, {
      patch: true,
      success: () => {
        app.notifications.success('Settings updated', {
          dismiss_timeout: 2000,
        });
      },
      error: () => {
        Swal.fire(
          'Oops!',
          "There was an error saving your information. We're looking into it!",
          'error'
        );
      },
    });
  },

  async prepare_tenant(e: any) {
    e.preventDefault();
    try {
      // active realtime flag before to prevent user push again the prepare tenant button later
      await axios.put(`${BONGO_URL}/v1/org/${this.tenant}/flags/realtime`, {
        active: true,
      });

      // launch dag for prepare tenant
      await axios.post(`${BONGO_URL}/v1/org/${this.tenant}/prepare`);
      app.notifications.success('Tenant being prepared!', {
        dismiss_timeout: 2000,
      });
      this.render();
    } catch (error) {
      app.notifications.error("Couldn't send message to prepare tenant", {
        dismiss_timeout: 2000,
      });
    }
  },
});

export default Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.admin_view = new Admin_view({ tenant: this.model.get('_id') });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(this.admin_view, '#tenant_info');
    return this;
  },
});
