export default {
  traits: {
    name: ' ',
    email: ' ',
    company_name: ' ',
    location: ' ',
    fit_results: {
      segment: ' ',
      score: 0,
      top_signals: [{ name: '', value: '', type: '' }],
      person: {
        employement: {
          name: '',
        },
        location: '',
      },
    },
    avatar: ' ',
    logo: ' ',
  },
};
