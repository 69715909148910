import { ConnectorsNames, MappingConversionsTypes } from '../../../../types';
import { AbstractFiltersForm } from './AbstractFiltersForm';
import StripeFiltersForm from './StripeFiltersForm';
import { DefaultFiltersForm } from './DefaultFiltersForm';
import { AnalyticsFiltersForm } from './AnalyticsFiltersForm';
import { ConnectorFieldNameDataModel } from '../../../models/ConnectorFieldNameDataModel';

export default class FiltersFormFactory {
  public async createFiltersForm(
    tenant: number,
    connector: ConnectorsNames,
    conversionType: MappingConversionsTypes,
    parametersFieldsNames: ConnectorFieldNameDataModel[]
  ): Promise<AbstractFiltersForm> {
    switch (connector) {
      case 'stripe':
        // eslint-disable-next-line no-case-declarations
        const stripeFiltersForm = new StripeFiltersForm(
          tenant,
          connector,
          conversionType,
          parametersFieldsNames
        );
        await stripeFiltersForm.init();
        return stripeFiltersForm;
      case 'analytics':
        // eslint-disable-next-line no-case-declarations
        const analyticsFiltersForm = new AnalyticsFiltersForm(
          tenant,
          connector,
          conversionType,
          parametersFieldsNames
        );
        await analyticsFiltersForm.init();
        return analyticsFiltersForm;
      default:
        // eslint-disable-next-line no-case-declarations
        const defaultFiltersForm = new DefaultFiltersForm(
          tenant,
          connector,
          conversionType,
          parametersFieldsNames
        );
        await defaultFiltersForm.init();
        return defaultFiltersForm;
    }
  }

  public initFrom(
    abstractFilterForm: AbstractFiltersForm,
    parametersFieldsNames: ConnectorFieldNameDataModel[]
  ): AbstractFiltersForm {
    switch (abstractFilterForm.connector) {
      case 'analytics':
        // eslint-disable-next-line no-case-declarations
        const instanceOfStripe = abstractFilterForm as AnalyticsFiltersForm;
        return new AnalyticsFiltersForm(
          instanceOfStripe.tenant,
          instanceOfStripe.connector,
          instanceOfStripe.conversionType,
          parametersFieldsNames,
          {
            conversionEvents: instanceOfStripe.conversionEvents,
            sourceSystem: instanceOfStripe.sourceSystem,
          }
        );
      case 'stripe':
        // eslint-disable-next-line no-case-declarations
        const instanceOfAnalytics = abstractFilterForm as StripeFiltersForm;
        return new StripeFiltersForm(
          instanceOfAnalytics.tenant,
          instanceOfAnalytics.connector,
          instanceOfAnalytics.conversionType,
          parametersFieldsNames,
          instanceOfAnalytics.sqoAmountThreshold
        );
      default:
        // eslint-disable-next-line no-case-declarations
        const instanceOfDefault = abstractFilterForm as DefaultFiltersForm;
        return new DefaultFiltersForm(
          instanceOfDefault.tenant,
          instanceOfDefault.connector,
          instanceOfDefault.conversionType,
          parametersFieldsNames,
          {
            parameters: instanceOfDefault.parameters,
            parametersForGlobalVars: instanceOfDefault.parametersForGlobalVars,
            parametersLogic: instanceOfDefault.parametersLogic,
            isParametersLogicCustom: instanceOfDefault.isParametersLogicCustom,
          }
        );
    }
  }
}
