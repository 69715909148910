import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input, Tooltip } from '../../../components/ui';
import { useSaveLinkedinCredentials } from '../hooks/useSaveMixpanelCredentials';
import { useMixpanelCredentials } from '../hooks/useMixpanelCredentials';
import { useDisconnectMixpanel } from '../hooks/useDisconnectMixpanel';
import { legacyMixpanelAuthSchema, newMixpanelAuthSchema } from '../types';
import LegacyAuthMethodWarning from './LegacyAuthMethodWarning';
import { NewMixpanelAuthInstructions } from './NewMixpanelAuthInstructions';
import { LegacyMixpanelAuthInstructions } from './LegacyMixpanelAuthInstructions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';

type Props = {
  tenant: number;
  hasAccessToConfiguration: boolean;
};

const DEFAULT_VALUES = {
  api_key: '',
  api_secret: '',
  accountUsername: '',
  accountPassword: '',
  projectId: '',
  eu: false,
  where: '',
};

export function MixpanelForm({ tenant, hasAccessToConfiguration }: Props) {
  const {
    data: credentials,
    isLoading: isLoadingCredentials,
  } = useMixpanelCredentials(tenant);

  // Defines whether the initial credentials are using either legacy project secret auth method
  // or the new service account auth method.
  const [isLegacyAuth, setIsLegacyAuth] = useState<boolean>(false);

  const { reset: resetForm, control, handleSubmit, formState } = useForm({
    reValidateMode: 'onChange',
    resolver: zodResolver(
      isLegacyAuth ? legacyMixpanelAuthSchema : newMixpanelAuthSchema
    ),
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    if (!isLoadingCredentials && credentials) {
      setIsLegacyAuth(
        credentials.api_key !== undefined &&
          credentials.api_key !== null &&
          credentials.api_secret !== undefined &&
          credentials.api_secret !== null
      );
      resetForm(
        Object.fromEntries(
          Object.entries(credentials).map(([key, value]) => [
            key,
            value ?? '', // Ensure that null or undefined values are converted to empty strings
          ])
        )
      );
    }
  }, [credentials, isLoadingCredentials]);

  const {
    mutate,
    isLoading: isConnecting,
    isSuccess: hasSavedCredentials,
    isError,
    reset,
  } = useSaveLinkedinCredentials(tenant, {
    onSuccess: (values) => resetForm(values),
  });

  const {
    mutate: disconnectMixpanel,
    isLoading: isDisconnecting,
  } = useDisconnectMixpanel(tenant, {
    onSuccess: () => resetForm(DEFAULT_VALUES),
  });

  const {
    api_key: apiKeyErrors,
    api_secret: apiSecretErrors,
    accountUsername: accountUsernameErrors,
    accountPassword: accountPasswordErrors,
    projectId: projectIdErrors,
    where: whereErrors,
  } = formState.errors;

  const isDisabled =
    !hasAccessToConfiguration || isDisconnecting || isConnecting;

  function onSubmit(values: typeof DEFAULT_VALUES) {
    console.log(values);
    const payload: Partial<typeof values> = {};
    payload.api_key = isLegacyAuth ? values.api_key : null;
    payload.api_secret = isLegacyAuth ? values.api_secret : null;
    payload.accountUsername = !isLegacyAuth ? values.accountUsername : null;
    payload.accountPassword = !isLegacyAuth ? values.accountPassword : null;
    payload.projectId = !isLegacyAuth ? values.projectId : null;
    payload.eu = values.eu;
    payload.where =
      String(values.where).trim().length > 0 ? values.where : null;
    mutate(payload);
  }

  return (
    <Form
      className="tw-flex tw-flex-col tw-items-start tw-gap-4 w-full"
      onSubmit={handleSubmit(onSubmit)}
      disabled={isDisconnecting || isConnecting}
    >
      <h5>Configuration</h5>

      <span>
        <b>Authentication method</b>
      </span>
      <div className="tw-flex tw-gap-4">
        <Button
          type="button"
          onClick={() => setIsLegacyAuth(false)}
          color={!isLegacyAuth ? 'blue' : 'white'}
        >
          Service Account
        </Button>
        <Button
          type="button"
          onClick={() => setIsLegacyAuth(true)}
          color={isLegacyAuth ? 'blue' : 'white'}
        >
          Project Secret (legacy)
        </Button>
      </div>

      {isLegacyAuth && (
        <>
          <LegacyAuthMethodWarning />

          <div>
            <LegacyMixpanelAuthInstructions />
          </div>

          <span>
            <b>Your API key</b> <span className="tw-text-red-500">*</span>
          </span>
          <Controller
            name="api_key"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || apiKeyErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
                }
                placeholder="Enter your Mixpanel API Key"
                type="text"
                disabled={isDisabled}
                {...field}
              />
            )}
          />
          {apiKeyErrors?.message && (
            <div className="tw-text-red-500">{apiKeyErrors?.message}</div>
          )}

          <span>
            <b>Your API secret</b> <span className="tw-text-red-500">*</span>
          </span>
          <Controller
            name="api_secret"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || apiKeyErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
                }
                placeholder="Enter your Mixpanel API Secret"
                type="password"
                disabled={isDisabled}
                {...field}
              />
            )}
          />
          {apiSecretErrors?.message && (
            <div className="tw-text-red-500">{apiSecretErrors?.message}</div>
          )}
        </>
      )}

      {!isLegacyAuth && (
        <>
          <NewMixpanelAuthInstructions />
          <span>
            <b>Your account username</b>{' '}
            <span className="tw-text-red-500">*</span>
          </span>
          <Controller
            name="accountUsername"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || accountUsernameErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
                }
                placeholder="Enter your Mixpanel account username"
                type="text"
                disabled={isDisabled}
                {...field}
              />
            )}
          />
          {accountUsernameErrors?.message && (
            <div className="tw-text-red-500">
              {accountUsernameErrors?.message}
            </div>
          )}

          <span>
            <b>Your account password</b>{' '}
            <span className="tw-text-red-500">*</span>
          </span>
          <Controller
            name="accountPassword"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || accountPasswordErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
                }
                placeholder="Enter your Mixpanel account password"
                type="password"
                disabled={isDisabled}
                {...field}
              />
            )}
          />
          {accountPasswordErrors?.message && (
            <div className="tw-text-red-500">
              {accountPasswordErrors?.message}
            </div>
          )}

          <span>
            <b>Your project ID</b> <span className="tw-text-red-500">*</span>
          </span>
          <Controller
            name="projectId"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || projectIdErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
                }
                placeholder="Enter your Mixpanel project ID"
                type="text"
                disabled={isDisabled}
                {...field}
              />
            )}
          />
          {projectIdErrors?.message && (
            <div className="tw-text-red-500">{projectIdErrors?.message}</div>
          )}
        </>
      )}

      <span>
        <b>Filter events where...</b>{' '}
        <Tooltip
          text={
            <span>
              Uses Mixpanel's{' '}
              <a
                href="https://developer.mixpanel.com/reference/segmentation-expressions"
                target="_blank"
                rel="noreferrer"
              >
                segmentation expressions
              </a>
              .
            </span>
          }
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="tw-text-lg tw-text-gray-300 tw-ml-2 tw-align-text-bottom"
          />
        </Tooltip>
      </span>
      <Controller
        name="where"
        control={control}
        render={({ field }) => (
          <Input
            className={
              (isError || whereErrors) &&
              'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
            }
            placeholder="Enter a filter for the events (optional)"
            type="text"
            disabled={isDisabled}
            {...field}
          />
        )}
      />

      <Controller
        name="eu"
        control={control}
        render={({ field }) => {
          const disabledClassName = isDisabled ? 'tw-opacity-50' : '';
          const checkedClassName = field.value ? 'tw-bg-blue-600' : '';
          const className = [
            'tw-h-5 tw-w-5 tw-cursor-pointer tw-rounded tw-border tw-border-gray-200 tw-text-blue-600 tw-outline-none',
            'tw-ring-0 tw-ring-offset-0 focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-offset-0',
            disabledClassName,
            checkedClassName,
          ].join(' ');
          return (
            <div className="d-flex gap-2">
              <input
                className={className}
                type="checkbox"
                onClick={(event: any) => field.onChange(event.target.checked)}
                disabled={isDisabled}
                defaultChecked={field.value}
              />
              <span>Are you using EU datacenter? 🇪🇺</span>
            </div>
          );
        }}
      />

      <div className="tw-flex tw-gap-4">
        <Button
          type="submit"
          className="tw-mt-5"
          disabled={isDisconnecting || isConnecting}
          color={isConnecting ? 'white' : 'blue'}
        >
          {isConnecting ? 'Saving...' : 'Save'}
        </Button>

        <Button
          type="button"
          className="tw-mt-5"
          disabled={isDisconnecting || isConnecting}
          color={isDisconnecting ? 'white' : 'red'}
          onClick={() => {
            reset();
            disconnectMixpanel();
          }}
        >
          {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
        </Button>
      </div>

      {isError && (
        <p className="tw-text-red-500 tw-mt-2">
          The credentials you entered are invalid. Please try again.
        </p>
      )}

      {hasSavedCredentials && (
        <p className="tw-text-green-500 tw-mt-2">
          Configuration saved successfully!
        </p>
      )}
    </Form>
  );
}
