import axios from 'axios';
import Backbone from 'backbone';
import ReactDOM from 'react-dom';
import React from 'react';
import $ from 'jquery';
import app from '../../app';
import { BackboneViewProps } from '../../types';
import OverviewComponent from './components/OverviewComponent';
import { getScriptCheckerStateBasedOnMappingMode } from '../utils';
import MappingsMode from '../enums/MappingsMode';
import { isUserReadOnly } from '../../utils';

const template = require('./audience_mapping.pug');

export default Backbone.View.extend({
  events: {
    'click #go_back': 'goBack',
    'click #new_audience': 'requestNewAudience',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.isReadOnly = isUserReadOnly(user);
    this.data = await this.getAudienceData();
    this.scriptCheckerData = await getScriptCheckerStateBasedOnMappingMode(
      this.tenant,
      MappingsMode.audience
    );
    this.scriptCheckerState = this.scriptCheckerData.data || false;
    this.render();
    this.renderAudience();
  },

  async getAudienceData() {
    try {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/audiences`
      );
      return data;
    } catch (_e) {
      return null;
    }
  },

  goBack() {
    Backbone.history.navigate(`/org/${this.tenant}/mapping`, true);
  },

  renderAudience() {
    const waitForPageReady = () => {
      const boxData = this.data?.map((item: UnknownObject) => {
        return { name: item.name, date: item.updated_at || item.created_at };
      });
      if (typeof $('#audienceMappingMain')[0] !== 'undefined') {
        ReactDOM.render(
          <OverviewComponent
            isReadOnly={this.isReadOnly}
            tenant={this.tenant}
            boxData={boxData}
            scriptCheckerState={this.scriptCheckerState}
          />,
          $('#audienceMappingMain')[0]
        );
      } else {
        setTimeout(waitForPageReady, 250);
      }
    };
    waitForPageReady();
  },

  render() {
    const html = template();
    this.$el.html(html);
    return this;
  },
});
