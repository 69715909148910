import React from 'react';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import Backbone from 'backbone';
import AudienceMappingManager from './models/AudienceMappingManager';
import AudienceConditionsForm from './audienceConditionsForm';
import VerbsNames from '../../enums/VerbsNames';
import { AudienceMappingConnectorsNames } from '../../../types';
import { launchDeleteMappingPopup } from '../../swal';
import MappingsMode from '../../enums/MappingsMode';
import AudienceTitle from './audienceTitle';
import MadMlEditModeComponent from '../../madMlMapping/reactComponents/MadMlEditModeComponent';

type AudienceParentComponentProps = {
  audienceMappingManager: AudienceMappingManager;
  isReadOnly: boolean;
  scriptCheckerRunning: boolean;
  superKudu: boolean;
};

type AudienceParentComponentState = {
  editable: boolean;
  audienceMappingManager: AudienceMappingManager;
};

export default class AudienceParentComponent extends React.Component<
  AudienceParentComponentProps,
  AudienceParentComponentState
> {
  constructor(props: AudienceParentComponentProps) {
    super(props);

    this.handleVerbChange = this.handleVerbChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleLogicChange = this.handleLogicChange.bind(this);
    this.handleLowerChange = this.handleLowerChange.bind(this);
    this.handleTraitChange = this.handleTraitChange.bind(this);
    this.handleObjectChange = this.handleObjectChange.bind(this);
    this.createNewCondition = this.createNewCondition.bind(this);
    this.removeConditionValue = this.removeConditionValue.bind(this);
    this.handleRemoveCondition = this.handleRemoveCondition.bind(this);
    this.createNewConditionValue = this.createNewConditionValue.bind(this);
    this.handleSourceSystemChange = this.handleSourceSystemChange.bind(this);
    this.handleDomainFieldsChange = this.handleDomainFieldsChange.bind(this);
    this.handleAccountLeadMappingChange = this.handleAccountLeadMappingChange.bind(
      this
    );
    this.handleOpportunityLeadMappingChange = this.handleOpportunityLeadMappingChange.bind(
      this
    );
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnDeleteClick = this.handleOnDeleteClick.bind(this);
    this.handleOnPublishClick = this.handleOnPublishClick.bind(this);
    this.goStandardEdit = this.goStandardEdit.bind(this);
    this.goMadMlEdit = this.goMadMlEdit.bind(this);
    this.handleOnChangeAudienceName = this.handleOnChangeAudienceName.bind(
      this
    );

    this.state = {
      editable: false,
      audienceMappingManager: cloneDeep(this.props.audienceMappingManager),
    };
  }

  componentDidMount() {
    const { audienceMappingManager, isReadOnly, superKudu } = this.props;
    const { isNewAudience } = audienceMappingManager;
    if (isNewAudience && isReadOnly) {
      Backbone.history.navigate('error/403', true);
    }

    if (isNewAudience && audienceMappingManager.isCustom && !superKudu) {
      this.goBack();
    }
  }

  createNewCondition() {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.addCondition()
    );
    this.setState({
      audienceMappingManager,
    });
  }

  createNewConditionValue(conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.addConditionValue(conditionIndex)
    );
    this.setState({
      audienceMappingManager,
    });
  }

  removeConditionValue(conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.removeConditionValue(conditionIndex)
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleLogicChange(value: string) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionsLogic(value.toUpperCase())
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleValueChange(value: string, conditionIndex: number, valueIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionValue(
        value,
        conditionIndex,
        valueIndex
      )
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleObjectChange(value: string, conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionObject(
        value,
        conditionIndex
      )
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleTraitChange(value: string, conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionTrait(value, conditionIndex)
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleSourceSystemChange(
    value: AudienceMappingConnectorsNames,
    conditionIndex: number
  ) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionSourceSystem(
        value,
        conditionIndex
      )
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleLowerChange(conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionLower(conditionIndex)
    );
    this.setState({
      audienceMappingManager,
    });
  }

  handleVerbChange(value: string, conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setConditionVerb(
        value as VerbsNames,
        conditionIndex
      )
    );

    this.setState({
      audienceMappingManager,
    });
  }

  handleRemoveCondition(conditionIndex: number) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.removeCondition(conditionIndex)
    );

    this.setState({
      audienceMappingManager,
    });
  }

  handleAccountLeadMappingChange(value: string) {
    const audienceMappingManager = cloneDeep(this.state.audienceMappingManager);
    audienceMappingManager.accountLeadMapping = value;
    this.setState({ audienceMappingManager });
  }

  handleOpportunityLeadMappingChange(value: string) {
    const audienceMappingManager = cloneDeep(this.state.audienceMappingManager);
    audienceMappingManager.opportunityLeadMapping = value;
    this.setState({ audienceMappingManager });
  }

  handleDomainFieldsChange(value: string) {
    const audienceMappingManager = cloneDeep(this.state.audienceMappingManager);
    audienceMappingManager.domainFields = value;
    this.setState({ audienceMappingManager });
  }

  handleOnChangeAudienceName(newAudienceName: string) {
    const audienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.setName(newAudienceName)
    );
    this.setState({ audienceMappingManager });
  }

  handleOnEditClick() {
    this.setState({
      editable: true,
    });
  }

  async handleOnPublishClick() {
    const postErrorsCheckAudienceMappingManager = cloneDeep(
      this.state.audienceMappingManager.checkErrors()
    );
    postErrorsCheckAudienceMappingManager.isFixing = !!postErrorsCheckAudienceMappingManager
      .errors.length;
    this.setState({
      audienceMappingManager: postErrorsCheckAudienceMappingManager,
    });
    await this.state.audienceMappingManager.publish();
  }

  goBack() {
    const { tenant } = this.state.audienceMappingManager;

    Backbone.history.navigate(`/org/${tenant}/mapping/audiences`, true);
  }

  async handleOnDeleteClick() {
    const { tenant, email, name } = this.state.audienceMappingManager;
    await launchDeleteMappingPopup(
      tenant,
      email,
      MappingsMode.audience,
      () => this.goBack(),
      name
    );
  }

  goMadMlEdit() {
    const { tenant } = this.state.audienceMappingManager;

    Backbone.history.navigate(
      `/org/${tenant}/mapping/audiences/newCustomAudience`,
      true
    );
  }

  goStandardEdit() {
    const { tenant } = this.state.audienceMappingManager;

    Backbone.history.navigate(
      `/org/${tenant}/mapping/audiences/newAudience`,
      true
    );
  }

  render() {
    const { isReadOnly, scriptCheckerRunning } = this.props;
    const { editable, audienceMappingManager } = this.state;
    const {
      isCustom,
      isNewAudience,
      email,
      lastPublisherEmail,
      updatedAt,
      tenant,
      name,
    } = audienceMappingManager;

    return (
      <>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light box-shadow-3d"
          aria-label="breadcrumb"
        >
          <ul className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                href={`/org/${audienceMappingManager.tenant}/mapping`}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                href={`/org/${audienceMappingManager.tenant}/mapping/audiences`}
              >
                Audiences
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit
            </li>
          </ul>
          <div className="my-2 my-lg-0">
            {!isReadOnly && (
              <>
                {!isCustom && (
                  <>
                    {!isNewAudience ? (
                      <>
                        {scriptCheckerRunning ? (
                          <button
                            className="btn btn-danger mr-2"
                            disabled={true}
                            onClick={this.handleOnDeleteClick}
                          >
                            Delete
                          </button>
                        ) : (
                          <>
                            <button
                              className="btn btn-danger mr-2"
                              onClick={this.handleOnDeleteClick}
                            >
                              Delete
                            </button>
                            <button
                              className="btn btn-primary mr-2"
                              onClick={this.handleOnEditClick}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={this.handleOnPublishClick}
                            >
                              Publish
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-outline-primary mr-2"
                          onClick={this.goMadMlEdit}
                        >
                          Enable MadML Mode
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={this.handleOnPublishClick}
                        >
                          Publish
                        </button>
                      </>
                    )}
                  </>
                )}
                {isCustom && (
                  <>
                    {isNewAudience ? (
                      <button
                        className="btn btn-outline-primary mr-2"
                        onClick={this.goStandardEdit}
                      >
                        Enable Standard Mode
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger mr-2"
                        onClick={this.handleOnDeleteClick}
                      >
                        Delete
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </nav>
        {isNewAudience && (
          <nav
            className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d"
            aria-label="breadcrumb"
          >
            <ul className="navbar-nav mx-auto mt-2 mt-lg-0">
              <p>
                Create an Audience in the MadKudu Platform based on fields from
                your Customer Relationship Management platform.
              </p>
            </ul>
          </nav>
        )}
        <div className="container">
          <div className="ml-5 mt-2">
            <p>
              {`Last updated on ${moment(updatedAt).format('lll')} by ${
                isNewAudience ? email : lastPublisherEmail
              }`}
            </p>
          </div>
          {scriptCheckerRunning && (
            <div className="alert alert-warning box-shadow-3d alert-No-Data">
              <div className="spinner-border float-left mr-2" />
              <p>
                Your configuration has been submitted and is currently being
                processed. Please wait for an email to confirm success or
                failure of publish.
              </p>
            </div>
          )}
          <div className="ml-5 mt-2">
            {isCustom ? (
              <>
                <div className="h4 mb-0">
                  <AudienceTitle
                    audienceMappingManager={audienceMappingManager}
                    handleOnChangeAudienceName={this.handleOnChangeAudienceName}
                  />
                </div>
                {
                  <MadMlEditModeComponent
                    tenant={tenant}
                    email={email}
                    goToDisplayMode={() => this.goBack()}
                    mappingMode={MappingsMode.audience}
                    goBackToOverview={() => this.goBack()}
                    isCreatingAudienceMapping={isNewAudience}
                    audienceName={name}
                    isReadOnly={isReadOnly}
                    scriptCheckerState={scriptCheckerRunning}
                  />
                }
              </>
            ) : (
              <div className="card box-shadow-3d">
                <div className="card-header">
                  <div className="h4 mb-0">
                    <AudienceTitle
                      audienceMappingManager={audienceMappingManager}
                      handleOnChangeAudienceName={
                        this.handleOnChangeAudienceName
                      }
                    />
                  </div>
                </div>
                <div className="card-body">
                  <AudienceConditionsForm
                    isNewAudience={isNewAudience}
                    audienceMappingManager={audienceMappingManager}
                    editable={editable}
                    handleDomainFieldsChange={this.handleDomainFieldsChange}
                    createNewCondition={this.createNewCondition}
                    handleAccountLeadMappingChange={
                      this.handleAccountLeadMappingChange
                    }
                    createNewConditionValue={this.createNewConditionValue}
                    handleOpportunityLeadMappingChange={
                      this.handleOpportunityLeadMappingChange
                    }
                    handleLogicChange={this.handleLogicChange}
                    handleLowerChange={this.handleLowerChange}
                    handleObjectChange={this.handleObjectChange}
                    handleRemoveCondition={this.handleRemoveCondition}
                    handleSourceSystemChange={this.handleSourceSystemChange}
                    handleTraitChange={this.handleTraitChange}
                    handleValueChange={this.handleValueChange}
                    handleVerbChange={this.handleVerbChange}
                    removeConditionValue={this.removeConditionValue}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
