enum ActivityTypeColors {
  App_Usage = 'primary',
  Email_Activity = 'secondary',
  Web_Activity = 'success',
  Sales_Activity = 'danger',
  Marketing_Activity = 'warning',
  Nonuser_Activity = 'info',
}

export default ActivityTypeColors;
