import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useZoomInfoIsConnected(tenant: number) {
  return useQuery<boolean>({
    queryKey: ['zoominfo', tenant],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/integrations/zoominfo`
      );

      return !!data?._id;
    },
    retry: false,
  });
}
