import React, { HTMLProps } from 'react';

interface TableHeaderContentProps {
  label: string;
  columnKey: string;
  SortIcon: (
    props: HTMLProps<HTMLElement> & { columnKey: string }
  ) => JSX.Element;
  tooltip?: string;
}

/**
 * This is a table header item helper in order to combine
 * string label, hoverable tooltip icon and sorting icons without
 * making the main table component dirty. Especially when handling
 * `white-space`.
 *
 * This helps you avoiding to do something like this yourself:
 * ```tsx
 * <div>
 *   Column
 *   <span style={{ whiteSpace: 'nowrap' }}>
 *     title
 *     <i className="fas fa-info-circle" data-tip={'my tooltip message'} />
 *   </span>
 * </div>
 * ```
 */
export function EventMappingColumnHeader({
  label,
  columnKey,
  SortIcon,
  tooltip,
}: TableHeaderContentProps) {
  const words = label.split(' ');
  const elements = words.map((chunk, index) =>
    index === words.length - 1 ? (
      // Wrap only the last word with the hoverable icon element.
      <span style={{ whiteSpace: 'nowrap' }}>
        {chunk}
        {tooltip && (
          <i
            className="ml-1 mr-1 fas fa-info-circle text-secondary-200 cursor-pointer"
            data-tip={tooltip}
          />
        )}
      </span>
    ) : (
      // Otherwise just output the text part.
      <>{chunk} </>
    )
  );
  return (
    <div className="d-flex flex-row align-items-end gap-2">
      <div style={{ flex: 1, userSelect: 'none' }}>{...elements}</div>
      <SortIcon columnKey={columnKey} />
    </div>
  );
}
