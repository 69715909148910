import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Swal from 'sweetalert2';
import { JobChangesWatchList } from '../types';

export function useUpdateJobChangesWatchListMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      emails,
      tenant,
    }: {
      emails: string[];
      tenant: number;
    }) => {
      return axios.post<JobChangesWatchList>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/job_changes/watch-list`,
        { emails }
      );
    },
    onSuccess: (_data, { emails, tenant }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Swal.fire({
        title: 'Watch list updated',
        icon: 'success',
        text: 'You watch list has been updated successfully.',
      });

      queryClient.setQueryData<JobChangesWatchList>(
        [tenant, 'integrations', 'job_changes', 'watchList'],
        () => ({
          updated_at: new Date().toISOString(),
          emails,
        })
      );
    },
  });
}
