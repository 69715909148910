import Backbone from 'backbone';
import $ from 'jquery';
import qs from 'qs';
import model_integration from '../models/integration';

const template = require('./stripe.pug');

export default Backbone.View.extend({
  events: {
    'click #mki_oauth_btn': 'redirect_to_stripe',
    'click #mki_oauth': 'redirect_to_stripe',
  },

  async initialize(options: { tenant: number; query_params: string }) {
    this.tenant = options.tenant;
    this.integration = 'stripe';
    this.query_params = options.query_params
      ? qs.parse(options.query_params)
      : {};
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  redirect_to_stripe() {
    window.location.href = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/oauth/redirect`;
  },

  render() {
    if (this.model.get('stripe_user_id')) {
      this.render_connected();
    } else {
      this.render_connect();
    }
    return this;
  },

  render_connected() {
    const params = {
      account_name: this.model.get('stripe_user_id'),
      integration: this.integration,
    };
    const html = $('#tpl_connected', template(params)).html();
    this.$el.html(html);
    return this;
  },

  render_connect() {
    const html = $(
      '#tpl_main',
      template({ integration: this.integration })
    ).html();
    this.$el.html(html);
    return this;
  },
});
