import axios from 'axios';
import startCase from 'lodash/startCase';
import Swal from 'sweetalert2';
import { generateApiUrlDeleteBasedOnMappingMode } from '../utils';
import MappingsMode from '../enums/MappingsMode';

export async function launchDeleteMappingPopup(
  tenant: number,
  email: string,
  mappingMode: MappingsMode,
  goBack: Function,
  audienceName?: string
) {
  const url = generateApiUrlDeleteBasedOnMappingMode(
    tenant,
    mappingMode,
    audienceName
  );
  const isDangerousDelete =
    mappingMode === MappingsMode.attribute ||
    mappingMode === MappingsMode.conversion;

  const htmlBody = `
    <div>
        <p>This will delete ${
          audienceName || ''
        } ${mappingMode} mapping. Rest assured, this has no impact on scoring but you will not be able to retrieve it.</p>
        <p>${
          isDangerousDelete
            ? "Deleting this mapping will generate error at map process if you don't create a new one directly after"
            : ''
        }</p>
    </div>
  `;
  return Swal.fire({
    title: `Delete ${audienceName || 'mapping'}?`,
    html: htmlBody,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: 'gray',
    reverseButtons: true,
    cancelButtonText: 'No, cancel',
    confirmButtonText: 'Yes, delete mapping',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return axios
        .delete(url)
        .then((response) => {
          if (response.status < 200 || response.status > 300) {
            throw new Error('Something went wrong!');
          }
          window.analytics.track('Delete mapping', {
            map: mappingMode,
            tenant,
            email,
          });
        })
        .catch((error) => {
          Swal.showValidationMessage(`Request failed: ${error}`);
        });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((response: any) => {
    if (response.isConfirmed) {
      Swal.fire({
        icon: 'success',
        title: `${startCase(mappingMode)} deleted!`,
      });
      goBack();
    }
  });
}
