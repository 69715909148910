import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BackboneViewProps } from '../../types';
import AuthenticationParentComponent from './components/AuthenticationParentComponent';
import { AuthenticationManager } from './models/AuthenticationManager';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.domain = this.model.get('domain');
    this.tenant = options.tenant;

    this.authenticationManager = new AuthenticationManager(this.tenant);

    await this.authenticationManager.init();

    this.render();
    this.renderOverviewReactComponent();
  },

  renderOverviewReactComponent() {
    ReactDOM.render(
      <AuthenticationParentComponent
        authenticationManager={this.authenticationManager}
        domain={this.domain}
        tenant={this.tenant}
      />,
      $('#AuthenticationParentComponentContainer')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
