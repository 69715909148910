import React from 'react';
import ModelDataModel from '../../../models/model/ModelDataModel';
import { MappingConversionsTypes, PerformanceData } from '../../../types';
import {
  extractActiveConversionTypeFromAudience,
  getAudiencesNames,
  getModelData,
} from '../../../utils';
import {
  calculateConversionRates,
  calculateDealSize,
  calculateLeadAmount,
  calculateLeadAmountPercentageValues,
  calculateLeadAmountTotal,
  calculateLeadRevenue,
  calculateLeadRevenueTotal,
  calculateLeadRevenueValues,
  calculateLeadValue,
  calculateTotalRevenuePredicted,
  capitalizeEachWord,
  DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION,
  DEFAULT_NAME_CONVERSION_SQO_DEFINITION,
  extractLeadsAndRecallsFromAudiencePerformanceData,
  extractScoreAndDatasetFromPerformanceData,
  getModelValues,
  getMostRelevantDefaultAudienceName,
  sortOverrides,
} from '../../utils';
import AudienceFilterHeaderComponent from '../AudienceFilterHeaderComponent';
import ConversionRateAndAverageDealSizeComponent from '../guideCards/ConversionRateAndAverageDealSizeComponent';
import LeadsAndRevenueInNumbersComponent from '../guideCards/LeadsAndRevenueInNumbersComponent';
import MadKuduFitQualityDistributionComponent from '../guideCards/MadKuduFitQualityDistributionComponent';
import PredictedLeadValueComponent from '../guideCards/PredictedLeadValueComponent';
import AnalysisCardComponent from './AnalysisCardComponent';
import FunnelBreakdownComponent from './FunnelBreakdownComponent';
import GlobalModelPerformanceComponent from './GlobalModelPerformanceComponent';
import LeadConversionRevenueComponent from './LeadConversionRevenueComponent';
import PredictionHeaderComponent from './PredictionHeaderComponent';

type PerformancesParentComponentProps = {
  tenant: number;
  modelId: number;
  isSuperkudu: boolean;
  userEmail: string;
  goTo: (url: string) => void;
};

type PerformancesParentComponentState = {
  selectedAudienceName: string;
  audiencesNames: string[];
  model: ModelDataModel;
  hasOverrides: boolean;
  isLoading: boolean;
  noData: boolean;
  activeConversionTypes: string[];
  selectedConversionType: string;
  currentAudiencePerformanceData: PerformanceData;
  currentPerformanceData: any;
  noAudienceData: boolean;
  overrides: any;
};

export default class PerformancesParentComponent extends React.Component<
  PerformancesParentComponentProps,
  PerformancesParentComponentState
> {
  constructor(props: PerformancesParentComponentProps) {
    super(props);
    this.state = {
      audiencesNames: [],
      selectedAudienceName: 'all',
      model: null,
      hasOverrides: false,
      isLoading: true,
      noData: true,
      activeConversionTypes: [],
      selectedConversionType: DEFAULT_NAME_CONVERSION_SQO_DEFINITION,
      currentAudiencePerformanceData: null,
      currentPerformanceData: null,
      overrides: null,
      noAudienceData: true,
    };

    // BIND
    this.handleOnAudienceNamesChange = this.handleOnAudienceNamesChange.bind(
      this
    );
    this.handleOnConversionTypeChange = this.handleOnConversionTypeChange.bind(
      this
    );
  }

  async componentDidMount() {
    const { tenant, modelId } = this.props;

    this.setState({
      isLoading: true,
    });

    const listOfPromises = [
      getAudiencesNames(tenant),
      getModelData(tenant, modelId),
    ];
    const results = await Promise.all(listOfPromises);
    const audiencesNames = results[0] as string[];

    const model: ModelDataModel = new ModelDataModel(results[1]);

    const selectedAudienceName = getMostRelevantDefaultAudienceName(
      audiencesNames,
      model.type
    );

    const hasOverrides: boolean = !!model.dataset?.overrides;
    const overrides = hasOverrides && sortOverrides(model.dataset.overrides);
    const currentAudiencePerformanceData =
      model.audience_performance_data[selectedAudienceName];
    if (!currentAudiencePerformanceData) {
      this.setState({
        selectedAudienceName,
        model,
        isLoading: false,
        noData: model.name === undefined,
        noAudienceData: true,
      });
      return;
    }
    const activeConversionTypes = extractActiveConversionTypeFromAudience(
      currentAudiencePerformanceData
    );
    const selectedConversionType = activeConversionTypes[0];
    const currentPerformanceData =
      currentAudiencePerformanceData[selectedConversionType];

    this.setState({
      audiencesNames,
      model,
      selectedAudienceName,
      hasOverrides,
      isLoading: false,
      noData: model.name === undefined,
      activeConversionTypes,
      selectedConversionType,
      currentAudiencePerformanceData,
      currentPerformanceData,
      noAudienceData: false,
      overrides,
    });
  }

  handleOnAudienceNamesChange(newSelectedAudienceName: string) {
    const { model, selectedConversionType } = this.state;

    const currentAudiencePerformanceData =
      model.audience_performance_data[newSelectedAudienceName];
    if (!currentAudiencePerformanceData) {
      this.setState({
        noAudienceData: true,
        selectedAudienceName: newSelectedAudienceName,
      });
      return;
    }
    const activeConversionTypes = extractActiveConversionTypeFromAudience(
      currentAudiencePerformanceData
    );
    const newSelectedConversionType = activeConversionTypes.includes(
      selectedConversionType
    )
      ? selectedConversionType
      : activeConversionTypes[0];

    const currentPerformanceData =
      currentAudiencePerformanceData[newSelectedConversionType];
    this.setState({
      selectedAudienceName: newSelectedAudienceName,
      currentAudiencePerformanceData,
      currentPerformanceData,
      activeConversionTypes,
      selectedConversionType: newSelectedConversionType,
      noAudienceData: false,
    });
  }

  handleOnConversionTypeChange(event: any) {
    const { currentAudiencePerformanceData } = this.state;
    const conversionType: MappingConversionsTypes = event.target
      .value as MappingConversionsTypes;
    const currentPerformanceData =
      currentAudiencePerformanceData[conversionType];

    this.setState({
      selectedConversionType: conversionType,
      currentPerformanceData,
    });
  }

  // render functions
  renderLoader(text: string) {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data">
        <div className="card-body align-middle">
          <div className="spinner-border float-left mr-2"></div>
          <h5>{text}</h5>
        </div>
      </div>
    );
  }

  renderGlobalPerformanceDataComponent() {
    const {
      selectedConversionType,
      activeConversionTypes,
      model,
      currentAudiencePerformanceData,
    } = this.state;
    const leadsAndRecalls = extractLeadsAndRecallsFromAudiencePerformanceData(
      currentAudiencePerformanceData,
      model.type,
      []
    );
    const { score } = extractScoreAndDatasetFromPerformanceData(
      selectedConversionType,
      leadsAndRecalls
    );
    return (
      <div>
        <GlobalModelPerformanceComponent
          selectedConversionType={selectedConversionType}
          activeConversionTypes={activeConversionTypes}
          score={score}
          handleOnConversionTypeChange={this.handleOnConversionTypeChange}
        />
      </div>
    );
  }

  renderFitQualityDistribution() {
    const { model, currentAudiencePerformanceData } = this.state;

    const closedWonPerformanceData =
      currentAudiencePerformanceData[
        DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION
      ];
    const modelValues = getModelValues(model.type);
    const firstThreeModelValues = modelValues.slice(0, 2);
    const leadAmountPercentageValues = calculateLeadAmountPercentageValues(
      closedWonPerformanceData,
      modelValues,
      false
    );
    const sqoAmountPercentageValues = calculateLeadAmountPercentageValues(
      currentAudiencePerformanceData[DEFAULT_NAME_CONVERSION_SQO_DEFINITION],
      modelValues,
      true
    );
    const { leadRevenuePercentageValues } = calculateLeadRevenueValues(
      modelValues,
      closedWonPerformanceData
    );
    return (
      <div className="row my-3">
        <LeadConversionRevenueComponent
          values={firstThreeModelValues}
          leadAmountPercentageValues={leadAmountPercentageValues}
          sqoAmountPercentageValues={sqoAmountPercentageValues}
          leadRevenuePercentageValues={leadRevenuePercentageValues}
        />
        <MadKuduFitQualityDistributionComponent />
      </div>
    );
  }

  renderLeadAndRevenueInNumbers() {
    const { model, currentAudiencePerformanceData } = this.state;
    const closedWonPerformanceData =
      currentAudiencePerformanceData[
        DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION
      ];
    const modelValues = getModelValues(model.type);
    const dataPredictedValueQualifiedSum = calculateTotalRevenuePredicted(
      closedWonPerformanceData,
      model.type
    );
    const {
      colorOfRevenue,
      leadRevenuePercentage,
    } = calculateLeadRevenueValues(modelValues, closedWonPerformanceData);
    const leadAmount = calculateLeadAmount(
      closedWonPerformanceData,
      modelValues,
      false
    );
    const leadAmountTotal = calculateLeadAmountTotal(
      closedWonPerformanceData,
      modelValues,
      false
    );
    const leadRevenue = calculateLeadRevenue(
      closedWonPerformanceData,
      modelValues
    );
    const leadRevenueTotal = calculateLeadRevenueTotal(
      closedWonPerformanceData,
      modelValues
    );

    return (
      <div className="row my-3">
        <LeadsAndRevenueInNumbersComponent />
        <FunnelBreakdownComponent
          dataPredictedValueQualifiedSum={dataPredictedValueQualifiedSum}
          leadRevenuePercentage={leadRevenuePercentage}
          colorOfRevenue={colorOfRevenue}
          leadAmount={leadAmount}
          leadRevenue={leadRevenue}
          leadAmountTotal={leadAmountTotal}
          leadRevenueTotal={leadRevenueTotal}
          values={modelValues}
        />
      </div>
    );
  }

  renderGuideSection() {
    return (
      <div className="row my-3">
        <ConversionRateAndAverageDealSizeComponent />
        <PredictedLeadValueComponent />
      </div>
    );
  }

  renderAnalysisCardsSection() {
    const { model, currentAudiencePerformanceData } = this.state;

    const closedWonPerformanceData =
      currentAudiencePerformanceData[
        DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION
      ];

    const modelValues = getModelValues(model.type);
    const conversionRates = calculateConversionRates(
      closedWonPerformanceData,
      model.type
    );
    const dealSizes = calculateDealSize(closedWonPerformanceData, model.type);
    const { leadValues, veryGoodWorthXMore } = calculateLeadValue(
      closedWonPerformanceData,
      model.type
    );
    return (
      <div className="row my-3">
        <AnalysisCardComponent
          values={modelValues}
          rates={conversionRates}
          title="Conversion Rate: Lead to Revenue"
          description="Conversion rate of your leads broken down by segment."
          isPercentage={true}
        />
        <AnalysisCardComponent
          values={modelValues}
          rates={dealSizes}
          title="Average Deal Size"
          description="Average deal size of your conversions broken down by segment."
          isPercentage={false}
        />
        <AnalysisCardComponent
          values={modelValues}
          rates={leadValues}
          title="Predicted Lead Value"
          description={`Your '${capitalizeEachWord(
            modelValues[0]
          )}' lead are worth ${veryGoodWorthXMore}x more than your '${capitalizeEachWord(
            modelValues[modelValues.length - 1]
          )}' leads`}
          isPercentage={false}
        />
      </div>
    );
  }

  renderContent() {
    const { noAudienceData } = this.state;
    if (noAudienceData) {
      return (
        <div>
          {this.renderLoader(
            'No audiences has been configured for this model.'
          )}
        </div>
      );
    }
    return (
      <div>
        {this.renderGlobalPerformanceDataComponent()}
        {this.renderFitQualityDistribution()}
        {this.renderLeadAndRevenueInNumbers()}
        {this.renderGuideSection()}
        {this.renderAnalysisCardsSection()}
      </div>
    );
  }

  renderBody() {
    const { noData } = this.state;

    return (
      <div>
        {noData
          ? this.renderLoader(
              'Your model has just been deployed and we are still processing your data. Please check back in 4 hours.'
            )
          : this.renderContent()}
      </div>
    );
  }

  renderHeader() {
    const { isSuperkudu, tenant, userEmail, goTo } = this.props;
    const {
      selectedAudienceName,
      audiencesNames,
      hasOverrides,
      model,
      overrides,
    } = this.state;

    return (
      <div>
        <PredictionHeaderComponent
          isSuperKudu={isSuperkudu}
          hasOverrides={hasOverrides}
          tenant={tenant}
          model={model}
          labels={model.labels}
          overrides={overrides}
          userEmail={userEmail}
          goTo={goTo}
        />
        <AudienceFilterHeaderComponent
          isSuperKudu={isSuperkudu}
          model={model}
          tenant={tenant}
          audiencesNames={audiencesNames}
          selectedAudienceName={selectedAudienceName}
          handleOnAudienceNamesChange={this.handleOnAudienceNamesChange}
        />
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>{!isLoading && this.renderHeader()}</div>
        <div className="container mt-4 pb-5">
          {isLoading ? this.renderLoader('Loading...') : this.renderBody()}
        </div>
      </div>
    );
  }
}
