var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (tenant) {
      pug_html = pug_html + "\u003Cdiv id=\"integration_name\"\u003E\u003Ch2 class=\"testing\"\u003EYour Results: Fastlane on Form #2\u003C\u002Fh2\u003E\u003Cp style=\"text-transform: none !important;\"\u003EYou've unlocked 3.6M in the past 30 days by generating 3032 good leads.\u003Cspan class=\"floatright\"\u003E\u003Ca" + (" id=\"btn_home\""+pug.attr("href", '/org/' + tenant + '/dashboard', true, true)) + "\u003E\u003Cbutton class=\"btn btn-primary\"\u003EView Pipeline Generated\u003C\u002Fbutton\u003E\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cdiv class=\"chart-holder\"\u003E\u003Ccanvas id=\"myLineChart\"\u003E\u003C\u002Fcanvas\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "tenant" in locals_for_with ?
        locals_for_with.tenant :
        typeof tenant !== 'undefined' ? tenant : undefined));
    ;;return pug_html;};
module.exports = template;