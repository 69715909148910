import Backbone from 'backbone';
import $ from 'jquery';
import { Process_collection } from '../models/process';
import { BackboneViewProps } from '../types';
import launch_process from './launch_process';
import app from '../app';

require('bootstrap-toggle');
const template = require('./processes.pug');
const template_403 = require('../errors/403.pug');

const FULL_PREFIX = '_full';

const Process_view = (Backbone as any).Epoxy.View.extend({
  tagName: 'tr',
  className: 'process__row',
  setterOptions: { save: true },
  events: {
    'click #btn_run': 'run',
  },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.listenTo(this.model, 'change', this.render);
    this.render();
  },

  get_data() {
    return {
      queue_name: 'data-workers',
      tenant: this.tenant,
      title: `run ${this.model.get('element_1')}`,
      sequence: this.model.get('element_1'),
    };
  },

  run(event: any) {
    event.preventDefault();
    // eslint-disable-next-line
    launch_process(this.tenant, this.get_data()).then(() =>
      this.collection.fetch()
    );
  },

  render() {
    const html = $('#tpl_process', template({})).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },
});

const Process_list_view = (Backbone as any).Epoxy.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.user = app.session.get('user');
    this.isSuperKudu = this.user.superKudu || false;
  },

  render() {
    if (!this.isSuperKudu) {
      this.collection.models = this.collection.models.filter(
        (model: any) => !model.get('name').includes(FULL_PREFIX)
      );
    }
    this.collection.models.forEach((model: any) => {
      // exception: don't create a view for the parent data model
      // ideally, we should display the value in the header instead
      if (model.get('name') === 'data') {
        return;
      }

      const view = new Process_view({
        tenant: this.tenant,
        model,
        collection: this.collection,
      });
      $(`#mki_processes_${model.get('sub_category')}`).append(view.$el);
    });
    return this;
  },
});

const Processes_view = Backbone.View.extend({
  events: {
    'click #mki_refresh': 'refresh',
    'click #btn_run_all_pull': 'run_pull',
    'click #btn_run_all_data': 'run_all_data',
  },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.collection = new Process_collection({
      tenant: this.tenant,
    });
    this.user = app.session.get('user');
    this.isSuperKudu = this.user.superKudu || false;
    this.listenTo(this.collection, 'sync', this.render);
    this.proc_view = new Process_list_view({
      tenant: this.tenant,
      collection: this.collection,
    });
    this.collection.fetch({
      error: () => this.render_error(),
    });
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_processes', template({})).html();
    this.$el.html(html);
    this.proc_view.render();
    if (this.isSuperKudu && $('.mki_refresh_buttons').html()) {
      $('.mki_refresh_buttons').show();
      $('.run_all_buttons').show();
      $('.run_td').show();
    }
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },

  get_data(launch_type: string) {
    if (launch_type === 'data_all') {
      return {
        queue_name: 'data-workers',
        title: 'start data',
        tenant: this.tenant,
      };
    }
    if (launch_type === 'pull_all') {
      return {
        queue_name: 'pull-launcher',
        title: 'start connectors',
        tenant: this.tenant,
      };
    }
  },

  run_pull(event: any) {
    event.preventDefault();
    // eslint-disable-next-line
    launch_process(this.tenant, this.get_data('pull_all')).then(() =>
      this.collection.fetch()
    );
  },

  run_all_data(event: any) {
    event.preventDefault();
    // eslint-disable-next-line
    launch_process(this.tenant, this.get_data('data_all')).then(() =>
      this.collection.fetch()
    );
  },

  refresh() {
    this.collection.fetch();
  },
});

export default Backbone.View.extend({
  initialize() {},

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_main', template({})).html();
    this.$el.html(html);
    this.assign(
      new Processes_view({ tenant: this.model.get('_id') }),
      '#process_info'
    );
    return this;
  },
});
