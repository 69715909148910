import axios from 'axios';
import partition from 'lodash/partition';
import { cleanArrayFromNullOrUndefinedValues } from '../../mapping/event_mapping/utils';
import ModelDataModel from '../../models/model/ModelDataModel';
import { ArrayOfPushObjects, PushConfigurationItem } from './types';

export function splitSentenceByCapitals(sentence: string) {
  return sentence.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g).join(' ');
}

/** Recursively removes key-value pairs with nullish values from provided object */
export function removeNullish<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) return obj;
  if (Array.isArray(obj)) return (obj.map(removeNullish) as unknown) as T;
  return (Object.entries(obj)
    .filter(
      ([, value]) => value !== null && value !== undefined && value !== ''
    )
    .reduce<Partial<T>>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: removeNullish(value),
      }),
      {}
    ) as unknown) as T;
}

export function sortFields(fieldList: string[]) {
  const [mkFields, nonMkFields] = partition(fieldList, (field: string) =>
    field.toLowerCase().startsWith('mk_')
  );
  const orderedFieldList = [...mkFields.sort(), ...nonMkFields.sort()];
  return orderedFieldList;
}

// TODO: Why we need this?
export function iterateOnNestedObject(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      iterateOnNestedObject(obj[key]);
    }
    // eslint-disable-next-line no-param-reassign
    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
}

export async function getJson(tenant: number) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/metadata/push`
  );
  if (data.length === 0) {
    return [{ object_type: 'contact_update' }];
  }
  return data;
}

export async function saveJson(
  tenant: number,
  pushConfigurationItem: PushConfigurationItem
) {
  await axios.put(
    `${BONGO_URL}/v1/org/${tenant}/metadata/push/contact_update`,
    pushConfigurationItem
  );
}

export async function updateExportContacts(
  tenant: number,
  configurationConnectors: string[]
) {
  const arrayOfPushObjects: ArrayOfPushObjects = [];
  configurationConnectors.map((connector: string) => {
    return arrayOfPushObjects.push({
      name: connector,
      property: 'push',
      body: {
        active: true,
        data_types: {
          export_contacts: true,
        },
      },
    });
  });
  await axios.put(
    `${BONGO_URL}/v1/org/${tenant}/integrations`,
    arrayOfPushObjects
  );
}

export async function fetchModelList(
  tenant: number
): Promise<ModelDataModel[]> {
  const { data = [] } = await axios.get(`${BONGO_URL}/v1/org/${tenant}/models`);
  const models = data.filter((model: ModelDataModel) => model.live);
  return models;
}

export function hasConnectorActive(integrations: any, connector: any) {
  const cleanedIntegrationsList = cleanArrayFromNullOrUndefinedValues(
    integrations
  );
  const hasConnector = cleanedIntegrationsList.some(
    (activeIntegration: any) => {
      return activeIntegration.name === connector;
    }
  );
  return hasConnector;
}
