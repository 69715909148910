import { checkConditionFormDataErrors } from '../generic/utils';
import { IndexedEvent } from './hooks/useEventMappingHistory';

export type SelectOptionLike = {
  label: string | number;
  value: string | number | boolean;
};

export function validateEvents(events: IndexedEvent[]): any[] {
  return events
    .map(
      (event) =>
        checkConditionFormDataErrors(event.conditionFormData)?.errors ?? []
    )
    .flat();
}
