import * as d3Selection from 'd3-selection';

import {
  CursorConfig,
  GaugeConfigWithRanges,
  GaugeProps,
  SegmentsConfig,
  TicksConfig,
  TooltipsConfig,
  ValueConfig,
} from '../types';
import drawSegments from './drawSegments';
import drawCursor from './drawCursor';
import drawTicks from './drawTicks';
import drawValue from './drawValue';
import {
  DEFAULT_CURSOR_CONFIG,
  DEFAULT_GAUGE_CONFIG,
  DEFAULT_SEGMENT_CONFIG,
  DEFAULT_TICK_CONFIG,
  DEFAULT_TOOLTIP_CONFIG,
  DEFAULT_VALUE_CONFIG,
} from '../default.configs';
import { getTextSize } from '../utils';

export default function drawGauge({
  className,
  value,
  segments,
  size = '100%',
  ...rest
}: GaugeProps) {
  if (!d3Selection.select('#chart').empty())
    d3Selection.select('#chart').selectChildren().remove();

  // find min/max value
  const valuesRange = segments
    .map(({ range }) => range)
    .reduce(
      ([currMin, currMax], [start, end]) => {
        return [Math.min(currMin, start), Math.max(currMax, end)];
      },
      [Infinity, -Infinity]
    );

  const maxAngle =
    rest.gaugeConfig?.maxCircleAngle ?? DEFAULT_GAUGE_CONFIG.maxCircleAngle;

  const gaugeConfig: GaugeConfigWithRanges = {
    ...DEFAULT_GAUGE_CONFIG,
    ...rest.gaugeConfig,
    valuesRange,
    anglesRange: [-maxAngle, maxAngle],
  };
  const cursorConfig: CursorConfig = {
    ...DEFAULT_CURSOR_CONFIG,
    ...rest.cursorConfig,
  };
  const segmentsConfig: SegmentsConfig = {
    ...DEFAULT_SEGMENT_CONFIG,
    ...rest.segmentsConfig,
  };
  const ticksConfig: TicksConfig = {
    ...DEFAULT_TICK_CONFIG,
    ...rest.ticksConfig,
  };
  const tooltipsConfig: TooltipsConfig = {
    ...DEFAULT_TOOLTIP_CONFIG,
    ...rest.tooltipsConfig,
  };
  const valueConfig: ValueConfig = {
    ...DEFAULT_VALUE_CONFIG,
    ...rest.valueConfig,
  };

  // get the max padding needed in addition of the diameter (to take into account of svg size elements likes ticks and tooltip )
  // compute size of the bigger tooltip
  const biggerTooltip = segments.reduce((prev, current) => {
    if (current.label.length > prev.label.length) return current;

    return prev;
  });
  const { width: tooltipWidth, height: tooltipHeight } = getTextSize(
    d3Selection.select('#chart'),
    biggerTooltip.label,
    tooltipsConfig.fontSize ?? 16
  );
  const tooltipsPaddingX =
    tooltipsConfig.margin + tooltipWidth + tooltipsConfig.paddingX;

  // compute size of the bigger tick
  const maxTick = Math.max(...(ticksConfig.ticks ?? []));
  const { width: tickWidth, height: ticksHeight } = getTextSize(
    d3Selection.select('#chart'),
    `${maxTick}`,
    ticksConfig.fontSize ?? 16
  );
  const ticksPaddingX = ticksConfig.margin + tickWidth;

  // choose the bigger padding for final diameter
  const maxPaddingX = Math.max(ticksPaddingX, tooltipsPaddingX);
  const diameterWithPaddingX = gaugeConfig.diameter + maxPaddingX * 2;

  const maxPaddingY =
    Math.max(ticksConfig.margin, tooltipsConfig.margin) +
    Math.max(ticksHeight, tooltipHeight);
  const diameterWithPaddingY = gaugeConfig.diameter + maxPaddingY * 2;
  const viewBoxDiameterWithPaddingY =
    gaugeConfig.diameter * (gaugeConfig.maxCircleAngle + 0.07) +
    maxPaddingY * 2;

  const svg = d3Selection
    .select('#chart')
    .attr('width', size)
    .attr(
      'viewBox',
      `0 0 ${diameterWithPaddingX} ${viewBoxDiameterWithPaddingY}`
    )
    .append('g')
    .attr(
      'transform',
      `translate(${diameterWithPaddingX / 2},${diameterWithPaddingY / 2})`
    );

  drawSegments(svg, segments, gaugeConfig, segmentsConfig, tooltipsConfig);

  drawCursor(svg, value, gaugeConfig, segmentsConfig, cursorConfig);

  drawTicks(svg, gaugeConfig, ticksConfig);

  drawValue(svg, value, segments, gaugeConfig, valueConfig);
}
