import React from 'react';
import ModelDataModel from '../../../models/model/ModelDataModel';
import { IS_LLTB } from '../../../utils/constants';

interface HybridSelectProps {
  modelsList: ModelDataModel[];
  models: number[];
  handleModelsChange: (models: number[]) => void;
  generateModelBadges: Function;
  type: 'likelihood' | 'grade';
}

export default function HybridSelect({
  modelsList,
  models,
  handleModelsChange,
  generateModelBadges,
  type,
}: HybridSelectProps) {
  // Build object of checked input models
  const checkedModels: { [key: number]: boolean } = {};
  models.forEach((model: number) => {
    checkedModels[model] = true;
  });
  const handleCheckModel = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkedModels[parseInt(e.target.value, 10)] = e.target.checked;
    const newModels: number[] = [];
    Object.keys(checkedModels)
      .map(Number)
      .forEach((key: number) => {
        if (checkedModels[key] === true) {
          newModels.push(key);
        }
      });
    handleModelsChange(newModels);
  };

  return (
    <>
      {modelsList
        .filter((model: ModelDataModel) => IS_LLTB(model.type))
        .map((model: ModelDataModel) => {
          const id = `cb-${type}-${model.type}-${model.modelId}`;
          return (
            <div key={id} className="form-check">
              <label
                className="form-check-label cursor-pointer d-flex align-items-center"
                htmlFor={id}
              >
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  defaultChecked={models.some(
                    (m: number) => m === model.modelId
                  )}
                  id={id}
                  onChange={(e) => handleCheckModel(e)}
                  name={model.name}
                  value={model.modelId}
                />
                {generateModelBadges('', type, model)}
              </label>
            </div>
          );
        })}
    </>
  );
}
