import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

export function useChangeApolloApiKey(
  tenant: number,
  options?: UseMutationOptions<void, unknown, string, unknown>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (apiKey: string) => {
      // validate key
      const { data } = await axios.get<{ is_logged_in: boolean }>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/apollo/test-api-key?api_key=${apiKey}`
      );

      if (!data.is_logged_in) throw Error('invalid-api-key');

      await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/apollo/credentials`,
        { api_key: apiKey }
      );
    },
    ...options,
    onSuccess: (data, variables, ctx) => {
      options?.onSuccess?.(data, variables, ctx);
      queryClient.setQueryData(['apollo', tenant], true);
    },
  });
}
