import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { LinkedinCredentials } from '../types';

export function useLinkedinCredentials(tenant: number) {
  return useQuery<LinkedinCredentials>({
    queryKey: ['linkedin-credentials', tenant],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/integrations/linkedin/credentials`
      );

      return data;
    },
  });
}
