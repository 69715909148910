import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import {
  INVALID_SLACK_TOKEN_SCOPES_ERROR,
  INVALID_SLACK_TOKEN_ERROR,
  SlackCredentials,
  KNOWN_SLACK_ERRORS,
} from '../types';

export function useChangeSlackToken(
  tenant: number,
  options?: UseMutationOptions<void, unknown, SlackCredentials, unknown>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (credentials: SlackCredentials) => {
      // validate key
      try {
        const { data } = await axios.post<{
          validToken: boolean;
          validScopes: boolean;
          scopes: string[];
          missingScopes: string[];
        }>(
          `${BONGO_URL}/v1/org/${tenant}/integrations/slack/ping`,
          credentials
        );

        if (!data.validToken) throw Error(INVALID_SLACK_TOKEN_ERROR);
        if (!data.validScopes) throw Error(INVALID_SLACK_TOKEN_SCOPES_ERROR);
      } catch (error) {
        if (!KNOWN_SLACK_ERRORS.includes(error.message))
          throw new Error('Uncaught Slack validation error: ' + error.message);
        else throw error;
      }

      await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/slack/credentials`,
        credentials
      );
    },
    ...options,
    onSuccess: (data, variables, ctx) => {
      options?.onSuccess?.(data, variables, ctx);
      queryClient.setQueryData(['slack', tenant], true);
    },
  });
}
