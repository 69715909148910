import React from 'react';

import Campaign from './Campaign';
import Campaigns from './Campaigns';

type CampaignsParentProps = {
  campaignId: string;
  createCampaign: Function;
  openCampaign: Function;
  tenant: number;
  goBackCampaigns: Function;
};

type CampaignsParentState = {};

export default class CampaignsParent extends React.Component<
  CampaignsParentProps,
  CampaignsParentState
> {
  render() {
    const {
      campaignId,
      createCampaign,
      openCampaign,
      tenant,
      goBackCampaigns,
    } = this.props;
    if (campaignId)
      return (
        <Campaign
          campaignId={campaignId}
          tenant={tenant}
          openCampaign={openCampaign}
          goBackCampaigns={goBackCampaigns}
        />
      );
    return (
      <Campaigns
        createCampaign={createCampaign}
        openCampaign={openCampaign}
        tenant={tenant}
      />
    );
  }
}
