import axios from 'axios';
import Backbone from 'backbone';
import Chart from 'chart.js';
import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import { COLORS } from '../../../predictions/utils';
import { BackboneViewProps } from '../../../types';
import {
  createDefaultDates,
  getBootstrapColumnsFromData,
  refreshDataDiscovery,
} from '../../utils';
import app from '../../../app';

const ChartDataLabels = require('chartjs-plugin-datalabels');

require('bootstrap-table/dist/bootstrap-table');

const template = require('./discovery.pug');

Chart.plugins.register(ChartDataLabels);

export default Backbone.View.extend({
  events: {
    'click #go_back': 'go_back',
    'click #refresh': 'refresh',
  },
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.userEmail = app.session.get('user').email;
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/discovery/eloqua`;
    const { data } = await axios.get(url);
    const updatedAt: string =
      moment(data.updated_at).format('lll') ?? 'unknown';

    this.data = data;
    this.updatedAt = updatedAt;
    this.render();

    this.created_contacts();
    this.data_quality_table();

    this.event_type_distribution();

    this.total_events_and_distinct_forms(); // to use when data is available
    this.top_form_submissions_6_months_table();

    this.most_frequent_first_url();
    this.top_web_pages_visited_6_months();

    this.most_clicked_emails();
    this.email_click_through_rate();
    this.subscribe_and_unsubscribe_request();
  },

  go_back() {
    Backbone.history.navigate(`/org/${this.tenant}/data`, true);
  },

  async refresh() {
    await refreshDataDiscovery(this.tenant, this.userEmail, 'eloqua');
  },

  data_quality_table() {
    const opportunitiesdata: UnknownObject[] = this.data.dataset.contacts;
    const data = opportunitiesdata.map((row: any) => {
      return {
        date: row.Date,
        contact_id: row['Contact id'],
        duplicated_emails: row['Duplicated emails'],
        contacts_without_email: row['Contacts without email'],
      };
    });

    $('#data_quality', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  event_type_distribution() {
    const data: UnknownArrayOfObjects = this.data.dataset.events;

    if (!data || !data.length) {
      $('#event_type_distribution').closest('.card.box-shadow-3d').remove();
      return;
    }

    const ctx = document
      .getElementById('event_type_distribution')
      .getContext('2d');

    const allDates = [
      ...new Set(
        data.map((row) => moment(row['event timestamp']).format('MMM YYYY'))
      ),
    ];
    const allEvents = [...new Set(data.map((row) => row.event))];

    const datasets: UnknownObject[] = allEvents.map((event, i) => ({
      label: event,
      data: allDates.map((date) => {
        const evt = data.find(
          (row) =>
            row.event === event &&
            moment(row['event timestamp']).format('MMM YYYY') === date
        );
        return {
          x: date,
          y: evt ? evt['total events logged'] : 0,
        };
      }),
      backgroundColor: COLORS[i],
    }));

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: allDates,
        datasets,
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        legend: {
          position: 'right',
        },
        plugins: {
          datalabels: {
            display: false,
            color: 'white',
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, dat) {
              let label = dat.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  created_contacts() {
    const rawData: UnknownArrayOfObjects = this.data.dataset.contacts;
    if (!rawData) return;

    const ctx = document
      .getElementById('contacts_created_chart')
      .getContext('2d');

    const dates = createDefaultDates(rawData);
    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Contacts Created',
            data: rawData.map((row: any) => row['Contacts created']),
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'Date',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  total_events_and_distinct_forms() {
    const data: UnknownArrayOfObjects = this.data.dataset
      .total_events_and_distinct_forms;

    if (!data || !data.length) {
      $('#total_events_and_distinct_forms')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('total_events_and_distinct_forms')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const distinct_forms_dataset: number[] = [];
    data.forEach((row) => {
      distinct_forms_dataset.push(
        row['distinct forms (primaryattributevalue)']
      );
    });

    const total_events_logged_dataset: number[] = [];
    data.forEach((row) => {
      total_events_logged_dataset.push(row['total events logged']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'distinct forms (primaryattributevalue)',
            data: distinct_forms_dataset,
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 14,
            backgroundColor: COLORS[3],
            borderColor: COLORS[3],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'total events logged',
            data: total_events_logged_dataset,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  top_form_submissions_6_months_table() {
    const rawData: UnknownArrayOfObjects = this.data.dataset.form_submissions;
    const data = rawData.map((row: any) => {
      return {
        form_name: row['form name'],
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#top_form_submissions_6_months_table', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  top_web_pages_visited_6_months() {
    const rawData: UnknownArrayOfObjects = this.data.dataset.most_frequent_page;

    if (!rawData || !rawData.length) {
      $('#top_web_pages_visited_6_months')
        .closest('.card.mt-3.box-shadow-3d')
        .remove();
      return;
    }

    const data = rawData.map((row: any) => {
      return {
        count: row.count,
        days_since_last_seen: row['days since last seen'],
        url: row.url,
      };
    });

    $('#top_web_pages_visited_6_months', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  most_frequent_first_url() {
    const rawData: UnknownArrayOfObjects = this.data.dataset
      .most_frequent_first_url;

    const data = rawData.map((row: any) => {
      return {
        first_page_view_url: row['first page view url'],
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#most_frequent_first_url', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  email_click_through_rate() {
    const data: UnknownArrayOfObjects = this.data.dataset.email_activity;

    const ctx = document
      .getElementById('email_click_through_rate')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const email_opened: number[] = [];
    data.forEach((row) => {
      email_opened.push(row['emails opened']);
    });

    const email_clicked: number[] = [];
    data.forEach((row) => {
      email_clicked.push(row['emails clicked']);
    });

    const email_rate: number[] = [];
    data.forEach((row) => {
      email_rate.push(row['email click-through rate']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'email click-through rate',
            data: email_rate,
            backgroundColor: COLORS[1],
            borderColor: COLORS[1],
            yAxisID: 'B',
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 22,
            showLine: false,
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'email opened',
            data: email_opened,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
          {
            label: 'email clicked',
            data: email_clicked,
            backgroundColor: COLORS[3],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
                beginAtZero: true,
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
                callback: (value: string) => {
                  return `${value ? value.toLocaleString() : '0'}%`;
                },
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  most_clicked_emails() {
    const rawData: UnknownArrayOfObjects = this.data.dataset
      .most_frequently_emails_clicked;

    const data = rawData.map((row: any) => {
      return {
        user_activity_type: row['user activity type'],
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#most_clicked_emails', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  subscribe_and_unsubscribe_request() {
    const data: UnknownArrayOfObjects = this.data.dataset.email_activity;

    const ctx = document
      .getElementById('subscribe_and_unsubscribe_request')
      .getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const event_timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(event_timestamp)) dates.push(event_timestamp);
    });

    const subscription_requests: number[] = [];
    data.forEach((row) => {
      subscription_requests.push(row['subscription requests']);
    });

    const unsubscribe_requests: number[] = [];
    data.forEach((row) => {
      unsubscribe_requests.push(row['unsubscribe requests']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'unsubscribe requests',
            data: unsubscribe_requests,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
          {
            label: 'subscription requests',
            data: subscription_requests,
            backgroundColor: COLORS[3],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  render() {
    const html = template({
      tenant: this.tenant,
      isData: this.data && Object.keys(this.data).length,
      updatedAt: this.updatedAt,
    });
    this.$el.html(html);

    return this;
  },
});
