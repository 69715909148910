import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import merge from 'lodash/merge';
import Swal from 'sweetalert2';
import { snakeCaseToHumanReadable } from '../utils';

export function usePatchIntegrationConfigMutation<
  TIntegrationConfig extends Record<string, unknown> = Record<string, unknown>
>() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      integration,
      configPatch,
      tenant,
    }: {
      tenant: number;
      integration: string;
      configPatch: Partial<TIntegrationConfig>;
    }) => {
      return axios.patch(
        `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/config`,
        configPatch
      );
    },
    onSuccess: (_data, { integration, configPatch, tenant }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Swal.fire({
        title: `${snakeCaseToHumanReadable(
          integration
        )} config updated for tenant ${tenant}`,
        icon: 'success',
      });

      queryClient.setQueryData(
        [tenant, 'integrations', integration, 'config'],
        (oldConfig: TIntegrationConfig) => merge(oldConfig, configPatch)
      );
    },
  });
}
