import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import { BackboneViewProps } from '../types';

const channel = Radio.channel('integration');
const template = require('./salesforceiq.pug');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
  },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'salesforceiq';
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
    });
  },

  render() {
    if (!this.model.get('api_key')) {
      this.model.set({ api_key: '' });
    }
    if (!this.model.get('api_secret')) {
      this.model.set({ api_secret: '*************' });
    }
    if (!this.model.get('list_id')) {
      this.model.set({ list_id: '' });
    }
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.applyBindings();
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  event_save(event: any) {
    event.preventDefault();

    const show_error_message = function (text: string) {
      Swal.fire({
        title: 'Oops',
        text,
        icon: 'error',
      });
    };

    if (!this.model.get('api_key')) {
      return show_error_message('Please enter an API Key');
    }

    if (!this.model.get('api_secret')) {
      return show_error_message('Please enter an API secret');
    }

    if (!this.model.get('list_id')) {
      return show_error_message('Please enter a List ID');
    }

    const credentials = {
      api_key: this.model.get('api_key'),
      api_secret: this.model.get('api_secret'),
      list_id: this.model.get('list_id'),
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_push=true`,
        credentials
      )
      .then(() => {
        channel.trigger('success', { integration: this.integration });
      })
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  },
});
