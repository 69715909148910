import axios from 'axios';
import { useState } from 'react';

export type BigQueryWorkbenchState = {
  tenant: number;
  status: 'idle' | 'loading' | 'error' | 'success';
  error?: Error;
  query?: string;
  result?: any;
};

export type BigQueryWorkbenchMethods = {
  runQuery: <T = any>() => Promise<T>;
  setQuery: (query: string) => void;
};

export function useBigQueryWorkbenchApi(
  tenant: number
): BigQueryWorkbenchState & BigQueryWorkbenchMethods {
  const [state, setState] = useState<BigQueryWorkbenchState>({
    tenant,
    status: 'idle',
  });

  function setQuery(query: string) {
    setState({
      ...state,
      query,
    });
  }

  async function runQuery<T = any>(): Promise<T> {
    try {
      setState({
        ...state,
        status: 'loading',
        result: null,
        error: null,
      });
      const url = `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/query`;
      const response = await axios.post(url, { query: state.query });
      const result = response.data;
      setState({
        ...state,
        status: 'success',
        result,
        error: null,
      });
      return response.data;
    } catch (error) {
      setState({
        ...state,
        result: null,
        status: 'error',
        error,
      });
    }
  }
  return {
    ...state,
    runQuery,
    setQuery,
  };
}
