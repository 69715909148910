import React from 'react';

type OverviewComponentProps = {
  tenant: number;
};

export default class OverviewComponent extends React.Component<
  OverviewComponentProps
> {
  render() {
    return (
      <div className="row">
        <div className="col">
          <div className="row justify-space-between">
            <div className="col-8">
              <h5>Overview</h5>
              <div>
                Want to use data from a system MadKudu does not currently have
                an integration with? Or send us flat files? You can send your
                data to MadKudu via Amazon S3.
              </div>
            </div>
            <div className="col-4">
              <h5>Links</h5>
              <div>
                <div>
                  <a
                    href="https://help.madkudu.com/docs/amazon-s3-how-to-format-and-send-data-to-madkudu-via-a-bucket"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to format your data
                  </a>
                </div>
                <div>
                  <a
                    href="https://help.madkudu.com/docs/amazon-s3-giving-madkudu-access-to-an-s3-bucket"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to give access to your bucket
                  </a>
                </div>
                <div>
                  <a
                    href="https://help.madkudu.com/docs/amazon-s3-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Frequently asked questions
                  </a>
                </div>
                <div>
                  <a
                    href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingBucket.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AWS resources
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h5>Best Used For</h5>
            <div>
              <p>— Sending flat files to MadKudu</p>
              <p>— Sending data from Redshift</p>
              <p>
                — Sending historical Segment data when you are not on a Business
                plan or higher with Segment
              </p>
            </div>
          </div>
          <div className="mt-3">
            <span>
              Check out this{' '}
              <a
                href="https://help.madkudu.com/docs/amazon-s3-how-to-format-and-send-data-to-madkudu-via-a-bucket"
                target="_blank"
                rel="noopener noreferrer"
              >
                Documentation
              </a>{' '}
              to help you set up an Amazon S3 integration.
            </span>
          </div>
          <div className="mt-3">
            Supported sources: Flat files (Newline-delimited JSON (preferred) or
            CSV), Redshift
          </div>
          <div className="mt-3">
            <h5>How this works</h5>
            <div className="row justify-content-center mt-3">
              <div className="col-2 alert alert-primary text-center">
                Your Data
              </div>
              <div className="col-1 pt-2">
                <span className="justify-content-center pl-2">&#10230;</span>
              </div>
              <div className="col-2 alert alert-primary text-center">S3</div>
              <div className="col-1 pt-2">
                <span className="justify-content-center pl-2">&#10230;</span>
              </div>
              <div className="col-2 alert alert-primary text-center">
                MadKudu
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h5>Prerequisites</h5>
            <div>
              <p>
                — Access to AWS account with an S3 bucket containing files with
                supported files-type and format
              </p>
              <p>
                — Ability to create an IAM role for MadKudu and grant permission
                to read from the bucket
              </p>
            </div>
          </div>
          <div className="mt-4">
            <div>
              <a
                href={`https://app.madkudu.com/org/${this.props.tenant}/settings/users`}
              >
                Not sure what to do? Invite your favorite developer
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
