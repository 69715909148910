import React from 'react';
import { ChartDefinition, ChartProperty } from '../../InsightsTypes';

type BigNumberComponentProps = {
  chartDefinition: ChartDefinition;
  data: UnknownArrayOfObjects;
};

export default class BigNumberComponent extends React.Component<
  BigNumberComponentProps
> {
  render() {
    const TEAL_COLOR = '#4DCCBD';
    const { chartDefinition, data } = this.props;
    const properties = chartDefinition.properties || [];
    const bigNumberProperty: ChartProperty = properties.find(
      (property) => property.name === 'bigNumber'
    );
    const timeframeProperty: ChartProperty = properties.find(
      (property) => property.name === 'timeframe'
    );

    const bigNumberValue = data[0][bigNumberProperty.value];

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-auto">
            <div className="row">
              <div className="col-8 mb-2 text-center text-nowrap">
                <h4>{timeframeProperty.value}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h1
                  className="display-1 text-center text-nowrap"
                  style={{ color: TEAL_COLOR }}
                >
                  <strong>{bigNumberValue}</strong>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
