import Backbone from 'backbone';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import React from 'react';
import qs from 'qs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import app from '../app';
import ProfileContainer from './component/ProfileContainer';

const template = require('./index.pug');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

export default Backbone.View.extend({
  async initialize(options: { tenant: number; queryString: string }) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.userEmail = user.email;
    this.superKudu = user.superKudu;
    this.queryParams = options.queryString ? qs.parse(options.queryString) : {};

    await this.render();

    this.renderProfileContainerComponent(this.tenant, this.superKudu);
  },

  updateQueryString(email: string) {
    this.queryParams.email = email;
    const newQueryString = qs.stringify(this.queryParams);
    const newUrl = `${window.location.pathname}?${newQueryString}`;
    Backbone.history.navigate(newUrl, { trigger: false });
  },

  renderProfileContainerComponent(tenant: number, superKudu: boolean) {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <ProfileContainer
          tenant={tenant}
          userEmail={this.userEmail}
          isSuperKudu={superKudu}
          updateQueryString={(email) => this.updateQueryString(email)}
          queryParams={this.queryParams}
        />
      </QueryClientProvider>,
      $('#ProfileContainerComponent')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
