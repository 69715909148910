import { scaleLinear } from 'd3-scale';
import { D3BasicSelection, Range, Segment } from './types';

export function getSegmentByValue(
  value: number,
  segments: Segment[],
  maxValue: number
): Segment | null {
  const segment = segments.find(
    ({ range: [start, end] }) =>
      (value === maxValue && maxValue === end) ||
      (value >= start && value < end)
  );

  return segment ?? null;
}

export function transformValueToAngle(
  value: number,
  valuesRange: Range,
  anglesRange: Range,
  toGrad = false
) {
  const scale = scaleLinear().domain(valuesRange).range(anglesRange);
  const angleInGrad = scale(value);

  if (toGrad) return angleInGrad * Math.PI;
  return angleInGrad * 180; // angle in deg
}

export function getTextSize(
  svg: D3BasicSelection,
  text: string,
  fontSize?: number
): { height: number; width: number } {
  const tempText = svg.append('text').attr('x', 10).attr('y', 30).text(text);

  if (fontSize) tempText.attr('font-size', fontSize);

  const width = tempText.node()?.getComputedTextLength() ?? 100;
  const height = tempText.node()?.getBBox().height ?? 20;

  tempText.remove();

  return { width, height };
}

export const getMiddleOfRange = (range: [number, number]) =>
  Math.floor((range[0] + range[1]) / 2);
