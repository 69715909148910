import React from 'react';
import Backbone from 'backbone';
import { Alert } from 'react-bootstrap';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { getHighLevelConnector } from './utils';
import RelevantEventsInformationModel from '../../models/RelevantEventsInformationModel';
import EventsTags from '../../../enums/EventsTags';
import ActivityTypeColors from '../../../enums/ActivityTypeColors';
import EventMappingConnectorsNames from '../../../enums/EventMappingConnectorsNames';
import ConnectorsNames from '../../../enums/ConnectorsNames';
import { unslugFieldName } from '../../../pullConfigManager';

require('codemirror/mode/sql/sql');

type IndividualCardDisplayComponentProps = {
  relevantEvent: RelevantEventsInformationModel;
  tenant: number;
  isReadOnly: boolean;
  index: number;
  connector: string;
};

export default class IndividualCardDisplayComponent extends React.Component<
  IndividualCardDisplayComponentProps,
  {}
> {
  constructor(props: IndividualCardDisplayComponentProps) {
    super(props);
    this.goToMappingConfiguration = this.goToMappingConfiguration.bind(this);
  }

  codeMirrorEditorInstance: any;

  getColorOfActivityType(activityType: string): string {
    const cleanedActivityType = activityType
      ?.replace(' ', '_')
      .replace('-', '')
      .trim();
    // @ts-ignore
    return ActivityTypeColors[cleanedActivityType];
  }

  getArrow() {
    return <img src="/media/icons/map-arrow.svg"></img>;
  }

  beautifySubCondition(condition: any) {
    const { object, subject, values, verb } = condition;
    const { connector } = this.props;
    const isSalesforce =
      getHighLevelConnector(connector) === ConnectorsNames.salesforce;
    return (
      <div className="ml-2 mr-2">
        {object && (
          <span
            className={`badge badge-${
              isSalesforce ? 'secondary' : 'success'
            } mr-1 p-2`}
          >
            {unslugFieldName(object)}
          </span>
        )}
        {subject && isSalesforce && (
          <span className="badge badge-success mr-1 p-2">
            {unslugFieldName(subject)}
          </span>
        )}
        {verb && <span className="badge badge-warning mr-1 p-2">{verb}</span>}
        {values &&
          values.length > 0 &&
          values.map((value: string, valueIndex: number) => {
            return (
              <span
                className="badge badge-danger mr-1 p-2"
                key={`value_${value}_${valueIndex}`}
              >
                {value}
              </span>
            );
          })}
      </div>
    );
  }

  renderBeautifiedSubCondition(condition: any) {
    return (
      <div className="d-flex flex-row">
        <div className="mt-1">Where</div> {this.beautifySubCondition(condition)}
      </div>
    );
  }

  beautifyEvent(event: any, index: number) {
    const {
      mkEventNameSignals,
      event: eventName,
      activityType,
      conditionFormData,
      campaignMemberStatus,
    } = event;
    const { connector } = this.props;

    const color = this.getColorOfActivityType(activityType);
    const isSalesforceCampaigns =
      connector === EventMappingConnectorsNames.salesforce_campaigns;

    return (
      <tr key={`event_${index}`} className="align-middle">
        <td className="align-middle">
          <span className={`badge badge-${color} p-2`}>{activityType}</span>
        </td>
        <td className="align-middle">{mkEventNameSignals}</td>
        <td className="align-middle">{this.getArrow()}</td>
        <td className="text-right">
          <div className="d-flex">
            <span className="badge badge-primary p-2">{eventName}</span>
            {isSalesforceCampaigns && (
              <span className="badge badge-success ml-1 p-2">{`Status: ${campaignMemberStatus}`}</span>
            )}
          </div>
          {conditionFormData &&
            conditionFormData.conditions?.map(
              (condition: any, conditionIndex: number) => {
                return (
                  <div
                    className="d-flex flex-row mt-2"
                    key={`sub_condition_${connector}_${conditionIndex}`}
                  >
                    {condition && this.renderBeautifiedSubCondition(condition)}
                  </div>
                );
              }
            )}
        </td>
      </tr>
    );
  }

  getColorAndTextBasedOnTag(
    tag: EventsTags,
    events: any[],
    disabledConnectors: string[],
    connector: EventMappingConnectorsNames
  ) {
    if (
      connector === EventMappingConnectorsNames.madMl &&
      !disabledConnectors?.includes(connector)
    ) {
      return {
        color: 'success',
        text: 'Enabled',
      };
    }
    if (
      disabledConnectors?.includes(connector) &&
      events.length > 0 &&
      tag === EventsTags.Live
    ) {
      return {
        color: 'warning',
        text: 'Draft',
      };
    }
    if (
      !disabledConnectors?.includes(connector) &&
      events.length > 0 &&
      tag === EventsTags.Live
    ) {
      return {
        color: 'success',
        text: 'Enabled',
      };
    }
    if (events.length > 0 && tag === EventsTags.Draft) {
      return {
        color: 'warning',
        text: 'Draft',
      };
    }
    return {
      color: 'danger',
      text: 'Not configured',
    };
  }

  goToMappingConfiguration(connector: EventMappingConnectorsNames) {
    const { tenant } = this.props;
    Backbone.history.navigate(
      `/org/${tenant}/mapping/event_mapping/${connector}`,
      true
    );
  }

  renderBeautifiedConnectorName(connector: string): string {
    if (
      connector === 'salesforce_campaigns' ||
      connector === 'salesforce_tasks'
    ) {
      return connector.replace(/_/g, ' ');
    }
    if (connector === 'madMl') {
      return 'MadMl: Custom Mapping';
    }
    if (connector === 's3') {
      return 'Amazon S3';
    }
    return connector;
  }

  removeExcludedEventFromEventList(
    events: any[],
    connector: EventMappingConnectorsNames
  ) {
    const isAnalyticConnector: boolean =
      connector === EventMappingConnectorsNames.segment ||
      connector === EventMappingConnectorsNames.amplitude ||
      connector === EventMappingConnectorsNames.mixpanel ||
      connector === EventMappingConnectorsNames.kissmetrics;
    if (isAnalyticConnector) {
      return events.filter((event) => {
        return !event.excludeFromMapping;
      });
    }
    return events;
  }

  renderCollapseContent() {
    const { relevantEvent } = this.props;
    const { connector, events, madMlQuery } = relevantEvent;
    const isMadMl = connector === EventMappingConnectorsNames.madMl;
    const cleanedEvents = this.removeExcludedEventFromEventList(
      events,
      connector
    );
    // If MadML
    if (isMadMl) {
      if (madMlQuery && madMlQuery.length > 0) {
        return (
          <CodeMirror
            value={madMlQuery}
            editorDidMount={(editor) => {
              this.codeMirrorEditorInstance = editor;
            }}
            className="h-250"
            options={{
              mode: 'sql',
              lineNumbers: true,
            }}
          />
        );
      }
      return (
        <Alert bsStyle="warning" className="w-100">
          <strong>No MadML Query has been configured yet.</strong>
        </Alert>
      );
    }
    // If not
    if (cleanedEvents.length > 0) {
      return (
        <div className="overflow-auto h-250">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Activity Type</th>
                <th scope="col">MadKudu Event</th>
                <th scope="col">{this.getArrow()}</th>
                <th scope="col">Your Event</th>
              </tr>
            </thead>
            <tbody>
              {cleanedEvents.map((event: any, eventIndex) => {
                return this.beautifyEvent(event, eventIndex);
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <Alert bsStyle="warning" className="w-100">
        <strong>No events have been configured yet.</strong>
      </Alert>
    );
  }

  renderCard() {
    const { index, relevantEvent, isReadOnly } = this.props;
    const { connector, tag, events, disabledConnectors } = relevantEvent;
    const { color, text } = this.getColorAndTextBasedOnTag(
      tag,
      events,
      disabledConnectors,
      connector
    );

    return (
      <div className="rounded">
        <div className="card-header bg-white">
          <div className="mb-0">
            <button
              className="btn btn-configure w-100"
              data-toggle="collapse"
              data-target={`#collapse_${index}`}
              aria-controls={`collapse_${index}`}
              // Need to refresh the codemirror instance to display directly
              onClick={() => this.codeMirrorEditorInstance?.refresh()}
            >
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-1 justify-content-center align-self-center">
                      <img
                        className="con-map-mk-integration-logo-size text-left"
                        style={{ width: '30px' }}
                        src={`/media/integrations/logos/${getHighLevelConnector(
                          `${connector}-icon`
                        )}.png`}
                      />
                    </div>
                    <div className="col-5 my-auto">
                      <h6 className="ml-2 my-auto text-left text-capitalize font-weight-bold">
                        {this.renderBeautifiedConnectorName(connector)}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="row">
                    <div className="col-1 justify-content-center align-self-center">
                      <div
                        className={`${color}-div rounded-circle`}
                        style={{ width: '15px', height: '15px' }}
                      ></div>
                    </div>
                    <div className="col-6 mb-0 justify-content-center align-self-center">
                      <p className="mb-0 text-left">{text}</p>
                    </div>
                    <div className="col-4 justify-content-center align-self-center mr-auto">
                      {!isReadOnly && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            this.goToMappingConfiguration(connector)
                          }
                        >
                          <i className="far fa-edit"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div
          id={`collapse_${index}`}
          className="collapse"
          aria-labelledby={`heading_${index}`}
          data-parent="#accordion-parent"
        >
          {this.renderCollapseContent()}
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderCard()}</div>;
  }
}
