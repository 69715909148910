import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { getModelLabels } from './utils';

const template = require('./model.pug');

async function get_new_id(tenant: number) {
  while (true) {
    let nid = (Math.random() * 10000000000000000000000).toString(36);
    nid = nid.substring(0, 6);
    const url = `${BONGO_URL}/v1/org/${tenant}/models/${nid}`;
    // eslint-disable-next-line no-await-in-loop
    const res = await axios.get(url, {
      crossDomain: true,
      item: { tenant, id: nid },
    } as any);
    if (res.data === '') {
      return nid;
    }
  }
}

type ModelEditorProps = {
  model: UnknownObject;
  tenant: number;
};

type ModelEditorState = {
  _name: string;
  // check for zero in case of model number 0
  _id: string;
  _type: string;
  _released: string;
  _description: string;
  _activated: boolean;
  _charts: UnknownObject;
  _labels: string[];
  _last_update: string;
  _signals: UnknownArrayOfObjects;
  _overrides: any;
  _leads: UnknownArrayOfObjects;
  _recall: UnknownArrayOfObjects;
  _periods: string[];
  _manual_signal_list: boolean;
  saving_loader: boolean;
  _target: string;
};

class ModelEditor extends Component<ModelEditorProps, ModelEditorState> {
  constructor(props: ModelEditorProps) {
    super(props);
    this.state = {
      _name: props.model.name ? props.model.name : 'Unknown',
      // check for zero in case of model number 0
      _id:
        props.model.modelId || props.model.modelId === 0
          ? props.model.modelId
          : 'error1',
      _type: props.model.type ? props.model.type : 'customer fit',
      _released:
        props.model.released_at &&
        new Date(props.model.released_at).toISOString(),
      _description:
        props.model.description ||
        'This model says if a lead is a good fit for your business or not.',
      _activated: props.model.live ? props.model.live : 0,
      _charts: props.model.charts
        ? props.model.charts
        : ['Leads distribution (%)', 'Conversion distribution (%)'],
      _labels: props.model.labels,
      _last_update:
        props.model.updated_at &&
        new Date(props.model.updated_at).toISOString(),
      _signals: props.model.dataset.signals
        ? props.model.dataset.signals
        : [{ s_name: 'New signal', strenght: 'IMPORTANT' }],
      _overrides: props.model.dataset.overrides
        ? JSON.stringify(props.model.dataset.overrides)
        : [],
      _leads: props.model.dataset.leads ? props.model.dataset.leads : [],
      _recall: props.model.dataset.recall ? props.model.dataset.recall : [],
      _periods: props.model.dataset.periods
        ? props.model.dataset.periods
        : ['J', 'F', 'M', 'A', 'M', 'J'],
      _manual_signal_list: true,
      saving_loader: false,
      _target: null,
    };
  }

  // ON-CHANGE FUNCTIONS FOR THE INPUTS

  handleChangeName(event: any) {
    this.setState({ _name: event.target.value });
  }

  handleChangeId(event: any) {
    this.setState({ _id: event.target.value });
  }

  handleChangeReleased(event: any) {
    this.setState({ _released: event.target.value });
  }

  handleChangeActivated(event: any) {
    this.setState({ _activated: event.target.checked });
  }

  handleOverrideJSON(event: any) {
    this.setState({ _overrides: event.target.value });
  }

  handleChangeDescription(event: any) {
    this.setState({ _description: event.target.value });
  }

  changeSignalList() {
    const new_list = JSON.parse(
      (document.getElementById('jsonarea') as HTMLTextAreaElement).value
    );
    if (new_list[0]) {
      if (new_list[0].s_name && new_list[0].strength) {
        this.setState({ _signals: new_list });
      }
    }
  }

  async refresh_model() {
    const url = `${BONGO_URL}/v1/org/${this.props.tenant}/models/${this.state._id}/refresh`;
    return axios.post(url, {
      crossDomain: true,
      item: { tenant: this.props.tenant, id: this.state._id },
    });
  }

  modelIs(type: string) {
    let description;
    let type_mod;
    let chart_labels;
    let target_mod;
    let charts_leg: UnknownObject;
    if (type.includes('fit')) {
      description =
        'This model says if a lead is a good fit for your business or not.';
      type_mod = 'fit';
      chart_labels = getModelLabels('customer_fit').reverse();
      target_mod = 'opportunities';
      charts_leg = {
        recall: 'Conversion distribution',
        leads: 'Leads distribution',
      };
    } else if (type.includes('likelihood') || type.includes('behavioral')) {
      description = 'This model says if the deal will be closed or not';
      type_mod = 'likelihood';
      chart_labels = getModelLabels('behavioral').reverse();
      target_mod = 'closed deals';
      charts_leg = {
        recall: 'Conversion distribution',
        leads: 'Leads distribution',
      };
    } else if (type.includes('grade')) {
      description =
        'This model says if a lead is a good fit for ytour business or not.';
      type_mod = 'grade';
      chart_labels = getModelLabels('lead_grade').reverse();
      target_mod = 'opportunities';
      charts_leg = {
        recall: 'Conversion distribution',
        leads: 'Leads distribution',
      };
    }
    this.setState({
      _type: type_mod,
      _description: description,
      _labels: chart_labels,
      _target: target_mod,
      _charts: charts_leg,
    });
  }

  // FUNCTIONS TO HANDLE SIGNALS
  addSignal() {
    const temporary_list = this.state._signals.slice();
    temporary_list.push({ s_name: 'New signal', strength: 'NEUTRAL' });
    this.setState({ _signals: temporary_list });
  }

  deleteSignal(nb: number) {
    const temporary_list = this.state._signals.slice();
    temporary_list.splice(nb, 1);
    this.setState({ _signals: temporary_list });
  }

  handleChangeSignals(e: any, nb: number, type: number) {
    const copy = this.state._signals.slice();
    if (type === 0) {
      copy[nb].s_name = e.target.value;
    } else {
      copy[nb].strength = e.target.value.toUpperCase();
    }
    this.setState({ _signals: copy });
  }

  // SAVE THE MODEL
  async save() {
    const { model } = this.props;

    model.name = this.state._name;
    model.description = this.state._description;
    model.charts = this.state._charts;
    model.live = !!this.state._activated;
    model.labels = this.state._labels;
    model.dataset = {
      leads: this.state._leads,
      recall: this.state._recall,
      overrides: JSON.parse(this.state._overrides),
      signals: this.state._signals,
      periods: this.state._periods,
    };

    await axios.put(
      `${BONGO_URL}/v1/org/${this.props.tenant}/models/${this.state._id}`,
      { model }
    );
    this.setState({ saving_loader: true });
    setTimeout(() => {
      this.back();
    }, 2500);
  }

  back() {
    Backbone.history.navigate(
      `/org/${this.props.tenant}/predictions/performances/models/${this.state._id}`,
      true
    );
  }

  // RENDERING
  render() {
    return (
      <div className="container">
        {this.state.saving_loader && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div>Saving the model</div>
            <div
              style={{ marginTop: '-10px', marginBottom: '70px' }}
              className="loader"
            ></div>
          </div>
        )}
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-md-6">
            <i className="fa fa-pencil mki_edit_name" aria-hidden="true"></i>
            <input
              id="name__box"
              style={{ fontSize: '30px', marginLeft: '20px' }}
              type="text"
              autoComplete="off"
              value={this.state._name}
              onChange={(e) => this.handleChangeName(e)}
            />
          </div>
          <div className="col-md-6">
            <div>
              <div className="text-right">
                <button
                  className="btn btn-danger mki_delete mr-2"
                  id="cancel__model__button"
                  onClick={() => this.back()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary mki_save"
                  id="save__model__button"
                  onClick={() => this.save()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card box-shadow-3d m-4 p-4">
          <h3>Description of the model</h3>
          <div>
            <div className="input-group">
              <span className="input-group-addon">Released: </span>
              <input
                className="form-control"
                type="text"
                value={this.state._released}
                onChange={(e) => this.handleChangeReleased(e)}
              />
            </div>
            <div className="input-group">
              <span className="input-group-addon">Goal of the model: </span>
              <input
                className="form-control"
                id="first_url"
                type="text"
                value={this.state._description}
                onChange={(e) => this.handleChangeDescription(e)}
              />
            </div>
            <div className="input-group">
              <span className="input-group-addon">Live: </span>
              <input
                type="checkbox"
                checked={this.state._activated}
                onChange={(e) => this.handleChangeActivated(e)}
              />
            </div>
            <div className="input-group">
              <span className="input-group-addon">Type of model: </span>
              <select
                value={this.state._type}
                onChange={(event: any) => this.modelIs(event.target.value)}
              >
                <option value="fit">Customer fit</option>
                <option value="likelihood">Likelihood to buy</option>
                <option value="grade">Lead Grade</option>
              </select>
            </div>
          </div>
        </div>
        <div className="card box-shadow-3d m-4 p-4">
          <h3>Overrides</h3>
          <div>
            <div>
              <div>
                <p>Overrides of the model</p>
              </div>
            </div>
            <input
              id="input_overrides_JSON"
              value={this.state._overrides}
              style={{ width: '100%', height: '50px' }}
              onChange={(event) => this.handleOverrideJSON(event)}
            />
          </div>
        </div>
        <div className="card box-shadow-3d m-4 p-4">
          <h3>Data</h3>
          <div>
            <div>
              <div>
                <p>Data showed</p>
              </div>
            </div>
            <button onClick={() => this.refresh_model()}>
              Refresh the data
            </button>
            <div>
              <p>Recall / Conversion</p>
              <input
                value={JSON.stringify(this.props.model.dataset.recall)}
                style={{ width: '100%', height: '50px' }}
              />
            </div>
            <div>
              <p>Leads</p>
              <input
                value={JSON.stringify(this.props.model.dataset.leads)}
                style={{ width: '100%', height: '50px' }}
              />
            </div>
          </div>
        </div>

        <div className="card box-shadow-3d m-4 p-4">
          <h3>Signals</h3>
          <div>
            <div>
              <div>
                <p>Key signals of the model</p>
              </div>
              <ul className="nav nav-pills nav-fill mb-2">
                <li className="nav-item">
                  <a
                    id="chart_box_1"
                    href="#"
                    className={`nav-link ${
                      this.state._manual_signal_list ? 'active' : ''
                    }`}
                    onClick={() => this.setState({ _manual_signal_list: true })}
                  >
                    Manual input
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="chart_box_2"
                    href="#"
                    className={`nav-link ${
                      !this.state._manual_signal_list ? 'active' : ''
                    }`}
                    onClick={() =>
                      this.setState({ _manual_signal_list: false })
                    }
                  >
                    JSON input
                  </a>
                </li>
              </ul>
            </div>

            {this.state._manual_signal_list ? (
              <div>
                <button
                  className="btn btn-primary"
                  id="btn_add_campaign"
                  onClick={() => this.addSignal()}
                >
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  <span style={{ marginLeft: '8px' }}>New signal</span>
                </button>
                {this.state._signals.map(({ s_name, strength }, nb) => (
                  <div className="input-group form-inline" key={nb}>
                    <div>
                      <span className="input-group-addon"> Signal: </span>
                      <input
                        className="form-control"
                        type="text"
                        value={s_name}
                        onChange={(e) => this.handleChangeSignals(e, nb, 0)}
                      />
                    </div>
                    <div>
                      <span className="input-group-addon"> Strength: </span>
                      <select
                        defaultValue={strength}
                        onChange={(e) => this.handleChangeSignals(e, nb, 1)}
                      >
                        <option value="CRITICAL">CRITICAL</option>
                        <option value="IMPORTANT">IMPORTANT</option>
                        <option value="INFLUENCING">INFLUENCING</option>
                        <option value="NEGATIVE">NEGATIVE</option>
                      </select>
                      {/* <input className="form-control" type="text" value={strength} onChange={(e) => (this.handleChangeSignals(e, nb, 1))}/> */}
                      <button
                        className="btn btn-danger mki_delete"
                        onClick={() => this.deleteSignal(nb)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-2">
                <h4>Upload JSON list of signals </h4>
                <div>
                  <div>
                    {
                      'JSON object with [{ "s_name": "request demo", "strength": "influencing"}]'
                    }
                  </div>
                  <textarea
                    className="form-control"
                    id="jsonarea"
                    style={{ width: '100%', height: '300px' }}
                  />
                  <button className="btn btn-primary" id="btn_add_mapping">
                    <span
                      id="button_JSON_signal"
                      onClick={() => this.changeSignalList()}
                    >
                      Summit the JSON list of signals
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Backbone.View.extend({
  events: {
    'click .integration-item--plays': 'open_performances',
    'click #cancel__model__button': 'return',
    'click #btn_add_model': 'saveJSONModel',
    'click #refresh_data_button': 'refresh_data',
  },

  async initialize(options: { tenant: number; name: string; id: string }) {
    this.name = options.name;
    this.tenant = options.tenant;
    this.model_id = options.id;

    if (this.model_id === 'new') {
      // if new model create an example and generate a new key
      const NEWID = await get_new_id(this.tenant);
      this.model = {
        tenant: this.tenant,
        _id: NEWID,
        name: 'New model',
        description: '',
        dataset: {},
      };
    } else {
      // else retrieve the data from the model
      const res = await axios.get(
        `${BONGO_URL}/v1/org/${this.tenant}/models/${this.model_id}`,
        { item: { tenant: this.tenant, id: this.model_id } } as any
      );
      this.model = res.data;
    }
    this.render_description();
  },

  return() {
    Backbone.history.navigate(`/org/${this.tenant}/predictions`, true);
  },

  render() {
    const html = template();
    this.$el.html(html);
    return this;
  },

  render_description() {
    ReactDOM.render(
      <ModelEditor model={this.model} tenant={this.tenant} />,
      $('.model_description', this.$el)[0]
    );
  },
});
