import Backbone from 'backbone';
import { ConnectorFieldNameDataModel } from '../../models/ConnectorFieldNameDataModel';

export function goBackToConversionMappingOverview(tenant: number) {
  Backbone.history.navigate(`/org/${tenant}/mapping/conversion_mapping`, true);
}

export function getFieldsNameFromTable(
  parametersFieldsNames: ConnectorFieldNameDataModel[],
  table: string
): ConnectorFieldNameDataModel {
  if (!table) {
    return {
      fields: [],
      title: 'default',
    };
  }
  if (table.toLowerCase() === 'lead / contact') {
    const leadFields = parametersFieldsNames.find((parameterFieldName) => {
      return parameterFieldName.title.toLowerCase() === 'lead';
    });
    const contactFields = parametersFieldsNames.find((parameterFieldName) => {
      return parameterFieldName.title.toLowerCase() === 'contact';
    });
    return {
      fields: [...leadFields.fields, ...contactFields.fields],
      title: 'lead / contact',
    };
  }
  return parametersFieldsNames.find((parameterFieldName) => {
    return parameterFieldName.title.toLowerCase() === table.toLowerCase();
  });
}
