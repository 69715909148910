import React from 'react';
import { DatePicked, PickedObject, PushConfigurationItem } from '../types';
import ModelDataModel from '../../../models/model/ModelDataModel';

type ReviewConfigurationCardProps = {
  connector: string;
  pushConfigurationItem: PushConfigurationItem;
  pickedObject: PickedObject;
  datePicked: DatePicked;
  generateModelBadges: Function;
  modelsList: ModelDataModel[];
};

class ReviewConfigurationCard extends React.Component<
  ReviewConfigurationCardProps
> {
  render() {
    const {
      pushConfigurationItem,
      connector,
      pickedObject,
      datePicked,
      generateModelBadges,
    } = this.props;
    const mappedCrmObjectIntercom: any[] = [];
    // eslint-disable-next-line array-callback-return
    pushConfigurationItem.attributes.map((obj: any) => {
      if (obj.realtime) {
        mappedCrmObjectIntercom.push(obj);
      }
    });
    return (
      <div className="row mt-4 mb-2">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-2">
                  <img
                    src={`/media/integrations/logos/${connector}.svg`}
                    className="w-50"
                  />
                </div>
                {connector !== 'intercom' && (
                  <div className="col-10 text-right m-auto">
                    {connector === 'segment' ? (
                      <>
                        Only Idenfity record with a Segment event in the last 30
                        days are scored by MadKudu in the fields mapped below
                      </>
                    ) : (
                      <>
                        {`All ${pickedObject[connector].lead ? 'Lead' : ''} ${
                          pickedObject[connector].lead &&
                          pickedObject[connector].contact
                            ? 'and'
                            : ''
                        } ${
                          pickedObject[connector].contact ? 'Contact' : ''
                        } records created after ${
                          datePicked &&
                          datePicked[connector]?.score_contacts_created_since
                            ? `after ${datePicked[connector].score_contacts_created_since} `
                            : ''
                        }are scored by MadKudu in the fields mapped below`}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="card-body p-2">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Segmentation</th>
                    <th scope="col">MadKudu</th>
                    {pickedObject[connector].lead && <th scope="col">Lead</th>}
                    {pickedObject[connector].contact && (
                      <th scope="col">Contact</th>
                    )}
                    {pickedObject[connector].identify && (
                      <th scope="col">Identify</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(connector === 'intercom'
                    ? mappedCrmObjectIntercom
                    : pushConfigurationItem.attributes
                  ).map((row: any, index: number) => {
                    let nameToDisplay = row.name;
                    const match = row.name?.match(
                      /mk_(?:likelihood_to_buy|lead_grade)_.*$/
                    );
                    const hybridMatch = row.name?.match(
                      /mk_(?:likelihood_to_buy|lead_grade)_.*_(\d+)$/
                    );
                    let modelId: number | null = null;
                    if (hybridMatch) {
                      modelId = Number(hybridMatch[1]);
                      nameToDisplay = nameToDisplay.replace(/_(\d+)$/, '');
                    } else if (match) {
                      const liveModel: ModelDataModel = this.props.modelsList.find(
                        (m: ModelDataModel) => m.type === 'pql'
                      );
                      modelId = liveModel.modelId;
                    }

                    const model = modelId
                      ? this.props.modelsList.find(
                          (m: ModelDataModel) => m.modelId === modelId
                        )
                      : null;

                    return (
                      <tr key={`${row}_${index}`}>
                        <td>
                          {generateModelBadges(
                            row.name,
                            row.tag ? row.tag : null,
                            model
                          )}
                        </td>
                        <td>{nameToDisplay}</td>
                        {pickedObject[connector].lead && (
                          <td>
                            {row.integrations[connector]?.Lead === undefined
                              ? 'DO_NOT_MAP'
                              : row.integrations[connector].Lead}
                          </td>
                        )}

                        {pickedObject[connector].contact && (
                          <td>
                            {(row.integrations[connector]?.Contact ||
                              row.integrations[connector]?.contact) ===
                            undefined
                              ? 'DO_NOT_MAP'
                              : row.integrations[connector].Contact ||
                                row.integrations[connector]?.contact}
                          </td>
                        )}
                        {pickedObject[connector].identify && (
                          <td>
                            {row.integrations[this.props.connector]?.identify ??
                              'DO_NOT_MAP'}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type ReviewConfigurationProps = {
  connectors: string[];
  pickedObject: PickedObject;
  pushConfigurationItem: PushConfigurationItem;
  generateModelBadges: Function;
  datePicked: DatePicked;
  modelsList: ModelDataModel[];
};

export default class ReviewConfiguration extends React.Component<
  ReviewConfigurationProps
> {
  render() {
    return (
      <React.Fragment>
        {this.props.connectors.map((connector: string) => {
          return (
            <React.Fragment key={connector}>
              <ReviewConfigurationCard
                connector={connector}
                pickedObject={this.props.pickedObject}
                pushConfigurationItem={this.props.pushConfigurationItem}
                generateModelBadges={this.props.generateModelBadges}
                datePicked={this.props.datePicked}
                modelsList={this.props.modelsList}
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}
