import Swal from 'sweetalert2';

export async function handleErrors(error: Error): Promise<void> {
  switch (error.message) {
    case 'invalid_email':
      await Swal.fire('Invalid email', 'Please enter a valid email', 'error');
      break;
    case 'missing_password':
      await Swal.fire(
        'Missing password',
        'Please enter a new password.',
        'error'
      );
      break;
    case 'missing_current_password':
      await Swal.fire(
        'Missing current password',
        'Please enter your current password.',
        'error'
      );
      break;
    case 'invalid_password':
      await Swal.fire(
        'Invalid current password',
        'The password you entered is incorrect',
        'error'
      );
      break;
    case 'password_too_short':
      await Swal.fire(
        'Password is too short',
        'The minimum password length is 8 characters',
        'error'
      );
      break;
    case 'password_mismatch':
      await Swal.fire(
        'Password are differents',
        "Confirm password doesn't match new password",
        'error'
      );
      break;
    case 'invalid_password_format':
      await Swal.fire({
        title: 'Invalid password format',
        html:
          '<h4>Please choose a password that : </h4></br>' +
          '- has a minimum of 8 characters' +
          '</br>' +
          '- has at least one uppercase and one lowercase' +
          '</br>' +
          '- has least one numeric character and one special character' +
          '</br>' +
          '- is different from the inputted email',
        icon: 'warning',
      });
      break;
    case 'personal_email':
      await Swal.fire({
        title: 'Pros only\nPlease enter your company email instead',
        imageUrl:
          'http://38.media.tumblr.com/df0f1c7faa1523ce46d03347612d7965/tumblr_mldmjuXtsi1ry46hlo1_400.gif',
        imageHeight: 400,
        imageWidth: 400,
      });
      break;
    case 'invalid_login_enable_captcha':
      await Swal.fire(
        'Invalid captcha',
        'Please enter a valid captcha',
        'error'
      );
      break;
    case 'email_already_registered': {
      const { isConfirmed } = await Swal.fire({
        title: 'Oooops',
        text: 'This email address is already used.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#219C23',
        confirmButtonColor: '#219C23',
        confirmButtonText: 'I want to login instead',
        cancelButtonText: 'I forgot my password',
      });
      if (isConfirmed) {
        window.location.href = '/login';
      } else {
        window.location.href = '/recover';
      }
      break;
    }
    default:
      await Swal.fire(
        'An unexpected error occured',
        error && error.message,
        'error'
      );
  }
}
