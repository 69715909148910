import React from 'react';
import ReactDOM from 'react-dom';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {
  ConversionMappingConnectorsNames,
  MappingConversionsTypes,
} from '../../../../../../types';
import ParameterFormComponents from '../forms/default_form/ParameterFormComponents';
import { ConversionTypeData } from '../../../../models/ConversionTypeData';
import StripeSqoFormComponent from '../forms/stripe_form/StripeSqoForm';
import SourceSystemComponent from '../forms/analytic_form/SourceSystemComponent';
import { DefaultFiltersForm } from '../../../../models/filters_forms/DefaultFiltersForm';
import StripeFiltersForm from '../../../../models/filters_forms/StripeFiltersForm';
import { AnalyticsFiltersForm } from '../../../../models/filters_forms/AnalyticsFiltersForm';
import { unslugFieldName } from '../../../../../pullConfigManager';
import { DATASOURCE_OBJECTS } from '../../../../../utils';
import {
  DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION,
  DEFAULT_NAME_CONVERSION_OPEN_OPP_DEFINITION,
  DEFAULT_NAME_CONVERSION_SQO_DEFINITION,
} from '../../../../../../predictions/utils';
import { ConversionMappingError } from '../../../../models/ConversionMappingData';
import {
  FIELD_PLACEHOLDER_VALUE,
  OBJECT_PLACEHOLDER_VALUE,
} from '../../../../../constants';

const defaultsDefinitionNames: string[] = [
  DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION,
  DEFAULT_NAME_CONVERSION_SQO_DEFINITION,
  DEFAULT_NAME_CONVERSION_OPEN_OPP_DEFINITION,
];
let globalDomElement: any;
let typeOfSwal: MappingConversionsTypes;

function renderStandardParametersForm(
  conversionTypeData: ConversionTypeData,
  conversionTypeDataIndex: number,
  handlerFunctions: any
) {
  return (
    <div>
      <form className="d-flex justify-content-between align-content-center">
        {conversionTypeData.connector !== 'stripe' && (
          <FormGroup
            controlId={`${conversionTypeData.connector}_type_filter_values`}
            className="w-50"
          >
            <ControlLabel style={{ height: '50px' }}>
              <p>
                What type of deal would you like to predict? (deal type - comma
                separated){' '}
                <span className="font-weight-lighter font-italic ml-1">
                  can be empty
                </span>
              </p>
            </ControlLabel>
            <FormControl
              type="text"
              value={conversionTypeData.filterTypesValues.toString()}
              placeholder="Enter text"
              onChange={(e: any) =>
                handlerFunctions.handleFilterTypesChanges(
                  e.target.value,
                  conversionTypeDataIndex
                )
              }
            />
            <FormControl.Feedback />
          </FormGroup>
        )}
        {conversionTypeData.getFields() &&
          DATASOURCE_OBJECTS[conversionTypeData.connector] && (
            <FormGroup
              controlId={`${conversionTypeData.connector}_amount_field_${conversionTypeDataIndex}`}
              className="w-50 ml-2"
            >
              <ControlLabel style={{ height: '50px' }}>
                What field identifies the deals amount on the deal object?
              </ControlLabel>
              <div className="d-flex justify-content-between">
                <Select
                  className="w-25 mr-2"
                  value={{
                    label: conversionTypeData.amountField.table,
                    value: conversionTypeData.amountField.table,
                  }}
                  placeholder={OBJECT_PLACEHOLDER_VALUE}
                  options={DATASOURCE_OBJECTS[conversionTypeData.connector].map(
                    (object) => ({
                      label: object,
                      value: object,
                    })
                  )}
                  onChange={({ value }) => {
                    handlerFunctions.handleAmountFieldChangeTable(
                      value,
                      conversionTypeDataIndex
                    );
                  }}
                />
                <Select
                  value={{
                    label: conversionTypeData.amountField.value,
                    value: conversionTypeData.amountField.value,
                  }}
                  placeholder={FIELD_PLACEHOLDER_VALUE}
                  options={conversionTypeData
                    .getFields(conversionTypeData.amountField.table)
                    .map(({ name }) => ({
                      label: unslugFieldName(name),
                      value: name,
                    }))}
                  onChange={({ value }) => {
                    handlerFunctions.handleAmountFieldChangeValue(
                      value,
                      conversionTypeDataIndex
                    );
                  }}
                />
              </div>
              <FormControl.Feedback />
            </FormGroup>
          )}
      </form>
    </div>
  );
}

// SALESFORCE && HUBSPOT
function displayConnectorParametersFormComponent(
  domElement: any,
  editable: boolean,
  handlerFunctions: any,
  conversionTypeData: ConversionTypeData,
  conversionTypeDataIndex: number,
  errors: ConversionMappingError[]
) {
  const IS_STANDARD_DEFINITION: boolean = defaultsDefinitionNames.includes(
    conversionTypeData.groupName
  );
  const htmlContainer = domElement
    ? domElement.children[5]
    : globalDomElement.children[5];
  htmlContainer.style.cssText = 'display: block;';

  ReactDOM.render(
    <div>
      {!IS_STANDARD_DEFINITION &&
        renderStandardParametersForm(
          conversionTypeData,
          conversionTypeDataIndex,
          handlerFunctions
        )}
      <ParameterFormComponents
        editable={editable}
        errors={errors}
        conversionTypeDataIndex={conversionTypeDataIndex}
        connector={conversionTypeData.connector}
        parametersFieldsNames={conversionTypeData.connectorFieldsName}
        filterForm={conversionTypeData.filtersForm as DefaultFiltersForm}
        createNewParameterByDefault={!(typeOfSwal === 'Open Opp')}
        handleLower={(conditionIndex) =>
          handlerFunctions.handleConnectorLowerChange(
            conditionIndex,
            conversionTypeDataIndex
          )
        }
        createNewParameter={() =>
          handlerFunctions.createNewConnectorParameter(conversionTypeDataIndex)
        }
        createNewParameterValue={(conditionIndex) =>
          handlerFunctions.createNewConnectorParameterValue(
            conditionIndex,
            conversionTypeDataIndex
          )
        }
        removeParameterValue={(conditionIndex: number) =>
          handlerFunctions.removeConnectorParameterValue(
            conditionIndex,
            conversionTypeDataIndex
          )
        }
        handleLogicChange={(value) =>
          handlerFunctions.handleConnectorLogicChange(
            value,
            conversionTypeDataIndex
          )
        }
        handleValueChange={(value, conditionIndex, valueIndex) =>
          handlerFunctions.handleConnectorValueChange(
            value,
            conditionIndex,
            conversionTypeDataIndex,
            valueIndex
          )
        }
        handleTableChange={(value, conditionIndex) =>
          handlerFunctions.handleConnectorTableChange(
            value,
            conditionIndex,
            conversionTypeDataIndex
          )
        }
        handleFieldChange={(value, conditionIndex) =>
          handlerFunctions.handleConnectorFieldChange(
            value,
            conditionIndex,
            conversionTypeDataIndex
          )
        }
        handleConditionChange={(value, conditionIndex) =>
          handlerFunctions.handleConnectorVerbChange(
            value,
            conditionIndex,
            conversionTypeDataIndex
          )
        }
        handleRemoveParameter={(conditionIndex) =>
          handlerFunctions.handleConnectorRemoveParameter(
            conditionIndex,
            conversionTypeDataIndex
          )
        }
      />
    </div>,
    htmlContainer
  );
}

function displayStripeSqoFormComponent(
  domElement: any,
  handlerFunctions: any,
  conversionTypeData: ConversionTypeData,
  conversionTypeDataIndex: number
) {
  const IS_STANDARD_DEFINITION: boolean = defaultsDefinitionNames.includes(
    conversionTypeData.groupName
  );

  const htmlContainer = domElement
    ? domElement.children[5]
    : globalDomElement.children[5];
  htmlContainer.style.cssText = 'display: block;';

  ReactDOM.render(
    <div>
      {!IS_STANDARD_DEFINITION &&
        renderStandardParametersForm(
          conversionTypeData,
          conversionTypeDataIndex,
          handlerFunctions
        )}
      <StripeSqoFormComponent
        filterForm={conversionTypeData.filtersForm as StripeFiltersForm}
        handleSqoAmountThreshold={(value: string) =>
          handlerFunctions.handleSqoAmountThreshold(
            value,
            conversionTypeDataIndex
          )
        }
      />
    </div>,
    htmlContainer
  );
}

function displayAnalyticsSourceSystemComponent(
  domElement: any,
  handlerFunctions: any,
  conversionTypeData: ConversionTypeData,
  conversionTypeDataIndex: number,
  activeIntegrations: ConversionMappingConnectorsNames[],
  errors: ConversionMappingError[]
) {
  const htmlContainer = domElement
    ? domElement.children[5]
    : globalDomElement.children[5];
  htmlContainer.style.cssText = 'display: block;';

  ReactDOM.render(
    <SourceSystemComponent
      errors={errors}
      conversionTypeDataIndex={conversionTypeDataIndex}
      filterForm={conversionTypeData.filtersForm as AnalyticsFiltersForm}
      activeIntegrations={activeIntegrations}
      handleSourceSystem={(value: string) =>
        handlerFunctions.handleSourceSystem(value, conversionTypeDataIndex)
      }
      handleConversionEvent={(value: string) =>
        handlerFunctions.handleConversionEvent(value, conversionTypeDataIndex)
      }
    />,
    htmlContainer
  );
}

export function displayConditionFormComponent(
  domElement: any,
  editable: boolean,
  conversionTypeDataIndex: number,
  conversionTypeData: ConversionTypeData,
  handlerFunctions: any,
  activeIntegrations: ConversionMappingConnectorsNames[],
  errors: ConversionMappingError[]
) {
  if ((domElement || globalDomElement) && conversionTypeData) {
    switch (conversionTypeData.connector) {
      case 'stripe':
        displayStripeSqoFormComponent(
          domElement,
          handlerFunctions,
          conversionTypeData,
          conversionTypeDataIndex
        );
        break;
      case 'analytics':
        displayAnalyticsSourceSystemComponent(
          domElement,
          handlerFunctions,
          conversionTypeData,
          conversionTypeDataIndex,
          activeIntegrations,
          errors
        );
        break;
      default:
        displayConnectorParametersFormComponent(
          domElement,
          editable,
          handlerFunctions,
          conversionTypeData,
          conversionTypeDataIndex,
          errors
        );
    }
  }
}

export function launchSwal(
  conversionTypeDataIndex: number,
  conversionTypeData: ConversionTypeData,
  handlerFunctions: any,
  activeIntegrations: ConversionMappingConnectorsNames[],
  editable: boolean,
  errors: ConversionMappingError[]
) {
  return Swal.fire({
    title: `Configure your conversion type for: ${
      conversionTypeData.connector.charAt(0).toUpperCase() +
      conversionTypeData.connector.slice(1)
    } ${conversionTypeData.conversionType}`,
    width: '90%',
    willOpen: (domElement: any) => {
      globalDomElement = domElement;
      typeOfSwal = conversionTypeData.conversionType;
      displayConditionFormComponent(
        domElement,
        editable,
        conversionTypeDataIndex,
        conversionTypeData,
        handlerFunctions,
        activeIntegrations,
        errors
      );
    },
  });
}
