import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { BackboneViewProps } from '../../../types';
import { isUserReadOnly } from '../../../utils';
import app from '../../../app';
import ConversionMappingManager from '../models/ConversionMappingManager';
import OverviewComponent from './components/OverviewComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.user = app.session.get('user');
    this.isReadOnly = isUserReadOnly(this.user);
    this.email = this.user.email;

    this.conversionMappingDataManager = new ConversionMappingManager(
      this.tenant,
      this.email,
      this.isReadOnly,
      this.user.superKudu
    );
    await this.conversionMappingDataManager.init();
    const isRedirecting = this.redirectToStep();

    if (isRedirecting) return;

    await this.sleep(0);

    this.render();
    this.renderOverviewReactComponent();
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  renderOverviewReactComponent() {
    ReactDOM.render(
      <OverviewComponent
        conversionMappingDataManager={this.conversionMappingDataManager}
      />,
      $('#MainConversionMappingReactComponentContainer')[0]
    );
  },

  redirectToStep(): boolean {
    if (this.conversionMappingDataManager.isFinished) {
      const link = `org/${this.tenant}/mapping/conversion_mapping/overview`;
      Backbone.history.navigate(link, true);
      return true;
    }
    if (this.conversionMappingDataManager.step === 1) {
      const link = `org/${this.tenant}/mapping/conversion_mapping/connector_overview`;
      Backbone.history.navigate(link, true);
      return true;
    }
    if (this.conversionMappingDataManager.step === 2) {
      const link = `org/${this.tenant}/mapping/conversion_mapping/standard_parameters`;
      Backbone.history.navigate(link, true);
      return true;
    }
    if (this.conversionMappingDataManager.step === 3) {
      const link = `org/${this.tenant}/mapping/conversion_mapping/conversion_type`;
      Backbone.history.navigate(link, true);
      return true;
    }
    return false;
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
