import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import ConditionsForm from './ConditionsForm';
import TimestampThresholdForm from './TimestampThresholdForm';
import {
  concatenateConditions,
  getConfig,
  getSamples,
  handleRequestSample,
  mapConfigConditionsToSql,
  mapFiltersToSql,
  setConfig,
  showSampleModal,
  showSavingModal,
  swalWithBootstrapButtons,
} from './utils';
import ClearBitRevealConfigModeComponent from '../clearbitReveal/components/ClearBitRevealConfigModeComponent';

const template = require('./template.pug');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #request-sample': 'requestSample',
    'click #see-sample': 'seeSample',
    'click #save-configuration': 'saveModal',
    'click #go_back': 'goBack',
    'click #go_back_integration_board': 'goBackToIntegrations',
  },

  async initialize(options: {
    tenant: number;
    showSample: boolean;
    integration: string;
  }) {
    this.tenant = options.tenant;
    this.integration = options.integration;
    this.showSample = options.showSample;
    this.isClearbitReveal = this.integration === 'clearbit_reveal';
    this.formData = {};
    this.userEmail = app.session.get('user').email;
    if (!this.isClearbitReveal) {
      this.formData = await getConfig(this.tenant, this.integration);
      this.sampleData = await getSamples(this.tenant, this.integration);
    }

    if (this.sampleData) {
      this.sampleExists = true;
    }

    this.render();

    if (!this.isClearbitReveal) {
      // eslint-disable-next-line
      if (this.showSample && this.sampleData) {
        await showSampleModal(this.sampleData);
      }
      this.renderForms();
    } else {
      await this.sleep(0); // Force to sleep until the page is created with the html tags
      this.renderClearBitRevealConfigModeComponent();
    }
  },

  renderClearBitRevealConfigModeComponent() {
    ReactDOM.render(
      <ClearBitRevealConfigModeComponent
        tenant={this.tenant}
        userEmail={this.userEmail}
      />,
      $('#ClearbitRevealConfigModeContainer')[0]
    );
  },

  renderForms() {
    ReactDOM.render(
      <TimestampThresholdForm formData={this.formData} />,
      $('#timestamp-form')[0]
    );
    ReactDOM.render(
      <ConditionsForm formData={this.formData} filters={true} />,
      $('#filters-form')[0]
    );
    ReactDOM.render(
      <ConditionsForm formData={this.formData} />,
      $('#conditions-form')[0]
    );
  },

  async requestSample() {
    const { conditions, filters } = this.formData;
    const config = {
      conditionsSql: mapConfigConditionsToSql(conditions),
      sampleConditions: conditions,
      sampleFilters: filters,
    };

    return handleRequestSample(
      this.tenant,
      this.integration,
      config,
      this.userEmail,
      this.sampleExists
    );
  },

  async seeSample() {
    return showSampleModal(this.sampleData);
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  async saveModal() {
    // eslint-disable-next-line
    showSavingModal();

    const accountDomainSelection = document.getElementById(
      'account_domain_selection'
    )
      ? (document.getElementById(
          'account_domain_selection'
        ) as HTMLInputElement).value
      : '';
    const confirmNewField = document.getElementById('confirm-create-new-fields')
      ? (document.getElementById(
          'confirm-create-new-fields'
        ) as HTMLInputElement).checked === true
      : false;
    const { conditions, filters, filtersLogic, timestamp } = this.formData;
    const { month, year } = timestamp;
    const filtersSql = mapFiltersToSql(filters, filtersLogic);

    const config = {
      accountDomainSelection,
      concatConditionsSql: concatenateConditions(month, year, filtersSql),
      conditions,
      conditionsSql: mapConfigConditionsToSql(conditions),
      confirmNewField,
      filters,
      filtersLogic,
      filtersSql,
      timestamp,
      waitingForActive: false,
    };

    const titleBase = 'Congratulations! Your configuration is saved.';
    const title = confirmNewField
      ? titleBase
      : `
        ${titleBase}
        You need to do one more step, however...
      `;
    const mutualText = `
      Please wait up to 3 days before the PredictLeads data gets populated to Salesforce,
       and your Customer Success Manager will confirm once this sync operation has been completed. 
    `;
    const text = confirmNewField
      ? `
        <div>
          ${mutualText}
        </div>
      `
      : `
        <div>
          Since you did not allow MadKudu to create a new field in your Salesforce Account object,
          please proceed to create a new field in your Salesforce instance following
          <a target="_blank" href="https://madkudusupport.zendesk.com/hc/en-us/articles/360042360512">these instructions</a>.

          ${mutualText}
        </div>
      `;

    try {
      await setConfig(this.tenant, this.integration, config);
      // using ajax to avoid sending default headers to zapier, who doesn't like them
      await axios.post(
        `${BONGO_URL}/v1/org/${this.tenant}/services/zapier/5187190/o58eick`,
        {
          email: this.userEmail,
          tenant: this.tenant,
        }
      );
      return swalWithBootstrapButtons.fire({
        title,
        html: text,
        confirmButtonText: 'Return to main page',
        width: 600,
      });
    } catch (_e) {
      return swalWithBootstrapButtons.fire({
        title: 'Oops!',
        text: 'An error occured while saving your configuration',
        width: 600,
      });
    }
  },

  goBack() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}`,
      true
    );
  },

  goBackToIntegrations() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  render() {
    const {
      accountDomainSelection = '',
      confirmNewField = false,
    } = this.formData;
    const html = template({
      accountDomainSelection,
      confirmNewField,
      integration: this.integration,
      sampleExists: this.sampleExists,
      isClearbitReveal: this.isClearbitReveal,
    });
    this.$el.html(html);
    return this;
  },
});
