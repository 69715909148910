import 'chartjs-plugin-datalabels';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import React from 'react';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap';
import { DataSourceVariable } from '../../InsightsTypes';

const iterateOnVariableObject = (
  variableObject: UnknownObject,
  options: {
    handleVariableChange: (value: any) => void;
    deletable: boolean;
    superKudu: boolean;
    deleteFn?: () => void;
  }
) => {
  return Object.keys(variableObject).map((variableName) => {
    const variableValue = variableObject[variableName];
    const subVariable: DataSourceVariable = {
      value: variableValue,
      name: variableName,
    };
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <ConfigurationFormFieldComponent
        key={variableName}
        variable={subVariable}
        superKudu={options.superKudu}
        handleVariableChange={(newValue) => {
          const newObject = cloneDeep(variableObject);
          newObject[variableName] = newValue;
          options.handleVariableChange(newObject);
        }}
        deletable={options.deletable}
        deleteValue={options.deleteFn}
      />
    );
  });
};

type ConfigurationFormFieldComponentProps = {
  variable: DataSourceVariable;
  superKudu: boolean;
  addNewValue?: (value: any) => void;
  deleteValue?: (value: any) => void;
  handleVariableChange: (value: any) => void;
  deletable: boolean;
};

export default class ConfigurationFormFieldComponent extends React.Component<
  ConfigurationFormFieldComponentProps,
  {}
> {
  render() {
    const {
      variable,
      deletable,
      deleteValue,
      handleVariableChange,
      superKudu,
    } = this.props;
    let field: JSX.Element | JSX.Element[] = <div />;
    if (variable.value && typeof variable.value === 'object') {
      // is this a real object or an array?
      const isArray = Array.isArray(variable.value);
      const fields = isArray
        ? Object.keys(variable.value).map((variableName, i) => {
            const value = variable.value[variableName];

            const deleteFn = () => {
              const filteredObject = Object.values(
                omit(variable.value, variableName)
              );
              handleVariableChange(filteredObject);
            };

            const handleVariableChangeFn = (newValue: any) => {
              const newObject = cloneDeep(variable.value);
              newObject[variableName] = newValue;
              handleVariableChange(newObject);
            };

            if (typeof value === 'object') {
              return iterateOnVariableObject(value, {
                handleVariableChange: handleVariableChangeFn,
                deletable: Object.keys(variable.value).length > 1,
                deleteFn,
                superKudu,
              });
            }
            return (
              <ConfigurationFormFieldComponent
                key={`${variableName}_${i}`}
                variable={{
                  name: variableName,
                  value,
                }}
                superKudu={superKudu}
                handleVariableChange={handleVariableChangeFn}
                deletable
                deleteValue={deleteFn}
              />
            );
          })
        : iterateOnVariableObject(variable.value, {
            handleVariableChange: (newValue: any) => {
              handleVariableChange(newValue);
            },
            deletable: false,
            superKudu,
          });

      field = [
        <div key={`${variable.name}_fields`} className="col-sm-8">
          {fields}
        </div>,
        <div key={`${variable.name}_buttons`} className="col-sm-1">
          {isArray && (
            <button
              className="btn btn-primary"
              onClick={() => {
                const existingKeys = Object.keys(variable.value);
                const newObject = cloneDeep(variable.value);
                const newKeyIndex = existingKeys.length;
                // Duplicate first value
                newObject[newKeyIndex] = cloneDeep(
                  variable.value[existingKeys[0]]
                );
                handleVariableChange(newObject);
              }}
            >
              +
            </button>
          )}
        </div>,
      ];
    } else {
      field = (
        <div className="col-sm-9">
          <Row>
            <FormControl
              className="col-sm-10"
              name={variable.name}
              type="text"
              disabled={!superKudu}
              value={variable.value ? String(variable.value) : ''}
              onChange={(e) =>
                this.props.handleVariableChange((e.target as any).value)
              }
            />
            {deletable && (
              <div className="col-sm-2">
                <button className="btn btn-danger" onClick={deleteValue}>
                  <i className="fa fa-trash" />
                </button>
              </div>
            )}
          </Row>
        </div>
      );
    }

    return (
      <ListGroupItem>
        <FormGroup className="row">
          <ControlLabel className="col-sm-3 col-form-label">
            {variable.name}
          </ControlLabel>
          {field}
        </FormGroup>
      </ListGroupItem>
    );
  }
}
