import React, { ReactElement, useState } from 'react';

import ConnectorSelect, { Connector } from './ConnectorSelect';
import DataTypeSelect, { DataType } from './DataTypeSelect';

export type Values = {
  connector: Connector;
  dataType: DataType;
  gitHubPath: string;
};

const defaultValues: Values = {
  connector: 'salesforce',
  dataType: 'export_contacts',
  gitHubPath: '',
};

type ForcePushFormProps = {
  onSubmit: (values: Values) => Promise<void>;
  sending: boolean;
};

function ForcePushForm({
  onSubmit,
  sending,
}: ForcePushFormProps): ReactElement {
  const [values, setValues] = useState<Values>(defaultValues);

  const handleChange = (event: any) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    await onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <legend>
        Trigger manually the push of records returned by a SQL query
      </legend>
      <div className="alert alert-warning" role="alert">
        For advanced needs only.
      </div>
      <div className="form-group row">
        <label htmlFor="connector" className="col-sm-2 col-form-label">
          Connector*
        </label>
        <div className="col-sm-10">
          <ConnectorSelect value={values.connector} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="dataType" className="col-sm-2 col-form-label">
          Data type*
        </label>
        <div className="col-sm-10">
          <DataTypeSelect value={values.dataType} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="gitHubPath" className="col-sm-2 col-form-label">
          File name*{' '}
          <i
            className="fas fa-info-circle"
            title={`The name of the SQL file to run. This file must be located in tenant-config/${values.dataType}/manual. The SQL query must return records whose structure is identical to the ${values.dataType} table structure.`}
          />
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="gitHubPath"
            required
            value={values.gitHubPath}
            onChange={handleChange}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={sending}>
        {sending ? 'Pushing...' : 'Start push'}
      </button>
    </form>
  );
}

export default ForcePushForm;
