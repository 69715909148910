import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import app from '../../app';
import { IntegrationPage } from './IntegrationPage';
import { LaunchDarklyClient, getContext } from '../utils/launchdarkly';
import { Tab } from './types';

const template = require('./index.pug');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

export default Backbone.View.extend({
  async initialize(options: { tenant: number; queryString: string }) {
    this.tenant = options.tenant;
    this.integration = 'job_changes';
    this.session = app.session;
    this.renderedOnce = false;
    this.isSuperKudu = app.session.get('user').superKudu;

    this.guardSuperkuduConfiguration();

    await this.renderJobChangesIntegrationComponent();
  },

  guardSuperkuduConfiguration() {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];

    if (lastItem === 'superkudu-configuration' && !this.isSuperKudu)
      this.changeTab('overview');
  },

  getActiveTab(): Tab {
    const splitUrl = window.location.href.split('/');

    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === 'job_changes') return 'overview';

    return lastItem as Tab;
  },

  changeTab(tab: Tab) {
    if (tab === 'overview') {
      Backbone.history.navigate(
        `/org/${this.tenant}/integrations/${this.integration}`
      );
    } else {
      Backbone.history.navigate(
        `/org/${this.tenant}/integrations/${this.integration}/${tab}`
      );
    }
  },

  async renderJobChangesIntegrationComponent() {
    // BUG: For some reason, Backbone will initialize the view once
    // but will render the component twice
    if (this.renderedOnce) return;

    // prepare dom for react component
    await this.$el.empty();
    this.$el.html(template());

    const ldInstance = await LaunchDarklyClient.getInstance();
    const context = getContext(Number(this.tenant));

    this.renderedOnce = true;

    // render react component
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <LDProvider
          clientSideID={ldInstance.clientSideID}
          context={context}
          timeout={5000}
        >
          <IntegrationPage
            tenant={this.tenant}
            onActiveTabChange={this.changeTab.bind(this)} // need to bind this to the function as it use this inside
            initialTab={this.getActiveTab()}
            isSuperkudu={this.isSuperKudu}
          />
        </LDProvider>
      </QueryClientProvider>,
      $('#JobChangesIntegrationContainer')[0]
    );
  },
});
