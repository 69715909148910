import React, { Dispatch, SetStateAction, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import Highlighter from 'react-highlight-words';
import { Icon } from '../../../components/ui';

import {
  Computation,
  EnrichmentCategory,
  ModelSubset,
  PersonModelScore,
  TreeDiagnosis,
} from '../../types';
import TreesDiagnosis from './TreesDiagnosis/TreesDiagnosis';
import useFetchScoreLookup from '../../hooks/api/useFetchScoreLookup';
import RulesDiagnosis from './RulesDiagnosis/RulesDiagnosis';
import Badge from '../../../components/Badge';

interface Props {
  computations: Computation[];
  personModelScore: PersonModelScore;
  model: ModelSubset;
  email: string;
  tenant: number;
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
}

export default function ScoreLookup({
  computations,
  personModelScore,
  model,
  email,
  tenant,
  setSearch,
  setSelectedCategory,
}: Props) {
  const [unfolded, setUnfolded] = useState(false);

  const isTreeBased = !!personModelScore.diagnosis?.treesDiagnosis?.length;
  const { data: scoreLookup, isFetching: isLoading } = useFetchScoreLookup(
    tenant,
    model.modelId,
    personModelScore,
    isTreeBased
  );

  if (!personModelScore.diagnosis)
    return (
      <div className="tw-rounded-md tw-overflow-hidden tw-mx-2 tw-mt-4">
        <div className="tw-relative tw-p-7 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-bg-[#b4e3ff] tw-text-md tw-text-blue-900">
          No diagnosis
        </div>
      </div>
    );

  return (
    <>
      <div className="tw-flex tw-gap-2 tw-items-center tw-mt-12">
        <FontAwesomeIcon icon={faEye} size="xl" />
        <span className="tw-font-semibold tw-text-lg">
          Score explanation
        </span>{' '}
        <Badge>Beta</Badge>
      </div>
      <div className="tw-text-md tw-text-gray-500 tw-mt-1">
        Here is the most impactful reason of why this profile has this score.
      </div>

      <div className="tw-rounded-md tw-overflow-hidden tw-mx-2 tw-mt-4">
        <div className="tw-relative tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-bg-[#b4e3ff] tw-text-md tw-text-blue-900">
          <div
            className={`tw-flex tw-items-center tw-justify-center tw-gap-4 tw-p-7 ${
              !isLoading ? 'tw-cursor-pointer' : ''
            }`}
            onClick={!isLoading ? () => setUnfolded(!unfolded) : undefined}
          >
            <div className="tw-cursor-text tw-text-[#00476e] tw-leading-loose">
              {isLoading && (
                <div>
                  <Icon
                    name="load"
                    width={30}
                    height={30}
                    className="tw-text-color-blue-900 tw-mr-2"
                  />
                  Diagnosing the score...
                </div>
              )}
              {!isLoading &&
                !scoreLookup?.completion &&
                'Score explanation sentence is unavailable for the moment.'}
              {!isLoading && scoreLookup?.completion && (
                <Highlighter
                  highlightClassName="tw-font-medium tw-bg-[#fff6c8]"
                  searchWords={[
                    'historical data',
                    'business overrides',
                    'business override',
                    'scored low',
                    'scored medium',
                    'scored good',
                    'scored very good',
                  ]}
                  autoEscape={true}
                  textToHighlight={scoreLookup.completion}
                />
              )}
            </div>
          </div>

          {unfolded && !isLoading && scoreLookup?.diagnosis && isTreeBased && (
            <TreesDiagnosis
              treeDiagnosis={scoreLookup.diagnosis as TreeDiagnosis}
              personProperties={personModelScore}
              computations={computations}
              model={model}
              email={email}
              tenant={tenant}
              setSearch={setSearch}
              setSelectedCategory={setSelectedCategory}
            />
          )}
          {unfolded && !isLoading && scoreLookup?.diagnosis && !isTreeBased && (
            <RulesDiagnosis
              personProperties={personModelScore}
              ruleDiagnosis={scoreLookup.diagnosis}
            />
          )}
        </div>
        {!isLoading && (
          <div
            onClick={() => setUnfolded(!unfolded)}
            className="tw-bg-[#d2efff] tw-cursor-pointer tw-h-12 tw-flex tw-items-center tw-font-medium tw-justify-center tw-gap-3 tw-text-blue-700 tw-text-sm hover:tw-text-blue-800"
          >
            {!unfolded && (
              <span>
                Unfold to view details{' '}
                <FontAwesomeIcon icon={faChevronDown} size="sm" />
              </span>
            )}
            {unfolded && (
              <span>
                Fold to hide details{' '}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="sm"
                  className="tw-rotate-180"
                />
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
}
