import Mn from 'backbone.marionette';
import $ from 'jquery';

const main_template = require('./main.pug');

const MainView = Mn.View.extend({
  el: '#main',

  regions: {
    content: '#content',
  },

  getTemplate() {
    return '#tpl_main';
    // return this.options.onboarding ? '#tpl_onboarding' : '#tpl_main'
  },

  initialize(options = {}) {
    this.options = options;
    // this.options.onboarding = typeof options.onboarding !== 'undefined' ? options.onboarding : false
    this.options.sidebar = !this.options.onboarding;
  },
});

MainView.setRenderer(function (template: any, data: {}) {
  return $(template, main_template(data)).html();
});

export default MainView;
