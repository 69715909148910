import Backbone from 'backbone';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import React from 'react';
import { DataStudio } from './DataStudio';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: { tenant: number }) {
    this.tenant = options.tenant;
    await this.render();
    this.renderDataStudioComponent();
  },

  renderDataStudioComponent() {
    ReactDOM.render(
      <DataStudio tenant={this.tenant} />,
      $('#DataStudioComponent')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
