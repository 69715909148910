import axios from 'axios';
import {
  AttributeMappingFilterForm,
  AttributeMappingForm,
} from './models/filters_forms/AttributeMappingFilterForm';
import { unslugFieldName } from '../pullConfigManager';

function getFieldPrefix(dataSource: string, object: string) {
  if (dataSource === 'Hubspot') {
    if (object === 'Contact') return 'hc';
    if (object === 'Deal') return 'ho';
    if (object === 'Company') return 'ha';
  }
  if (dataSource === 'Salesforce') {
    if (object === 'Lead') return 'sl';
    if (object === 'Contact') return 'sc';
    if (object === 'Account') return 'sa';
    if (object === 'Opportunity') return 'so';
  }

  throw new Error('Unable to build attributes query');
}

/**
 * Map attributes to SQL
 * @param {Array} attributes - Attributes to map. Example [{ dataSource: 'Hubspot', object: 'Contact', fieldName: '', fieldNameDisplay: '' }]
 */
export default function mapAttributesToSql(attributes: UnknownArray) {
  if (!attributes.length) return null;

  return attributes.reduce((acc, attribute) => {
    if (!attribute) return acc;
    const { fieldName, fieldNameDisplay, dataSource, object } = attribute;
    if (!fieldName || !fieldNameDisplay) return acc;

    return `${acc}        MAX(${getFieldPrefix(
      dataSource,
      object
    )}.${fieldName}) AS ${fieldNameDisplay},\n    `;
  }, '');
}

export async function getAttributes(tenant: number) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/data/mappings/attributes`
  );
  return data;
}

export function sortFormsByTableAndField(
  forms: AttributeMappingForm[],
  IS_SALESFORCE: boolean
): AttributeMappingForm[] {
  return forms?.sort(
    (a, b) =>
      a.table.localeCompare(b.table) ||
      unslugFieldName(a.field, IS_SALESFORCE).localeCompare(
        unslugFieldName(b.field, IS_SALESFORCE)
      )
  );
}

export function sortFiltersForm(
  filterForms: AttributeMappingFilterForm[]
): AttributeMappingFilterForm[] {
  return filterForms?.map((filterForm) => {
    const sortedFilterForm = filterForm;
    const IS_SALESFORCE = filterForm.connector === 'salesforce';
    sortedFilterForm.forms = sortFormsByTableAndField(
      filterForm.forms,
      IS_SALESFORCE
    );
    return filterForm;
  });
}
