import React, { ChangeEvent } from 'react';
import { useSnowflakeWorkbenchApi } from './hooks/useSnowflakeWorkbenchApi';
import { StatusPanel } from './components/StatusPanel';

export type SnowflakeWorkbenchViewParams = {
  tenant: number;
};

export default function SnowflakeWorkbenchView({
  tenant,
}: SnowflakeWorkbenchViewParams) {
  const {
    query,
    status,
    error,
    result,
    setQuery,
    runQuery,
  } = useSnowflakeWorkbenchApi(tenant);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setQuery(event.currentTarget.value);
  return (
    <div className="m-2">
      <div id="section_query">
        <h4 className="mb-1">Run query on Snowflake</h4>
        <div className="row form-group" style={{ display: 'flex' }}>
          <div className="col-sm-1">
            <label>Query</label>
          </div>
          <div className="col-sm-10">
            <textarea
              className="form-control"
              placeholder="SELECT"
              value={query}
              onChange={onChange}
            ></textarea>
          </div>
          <div className="col-sm-1">
            <button className="btn btn-success" onClick={runQuery}>
              Run
            </button>
          </div>
        </div>
        <StatusPanel status={status} error={error} />
        <div className="div_loader_query"></div>
        {result ? (
          <div
            className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
            role="alert"
          >
            <p className="font-bold">Result</p>
            <pre className="text-sm">
              {JSON.stringify(result, undefined, 2)}
            </pre>
          </div>
        ) : null}{' '}
      </div>
    </div>
  );
}
