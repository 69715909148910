import React from 'react';
import ReactTooltip from 'react-tooltip';
import { unslugFieldName } from '../../../pullConfigManager';
import AttributeMappingManager from '../../models/AttributeMappingManager';
import MissingFieldMicrocopy from '../../../../components/ErrorMicroCopyComponent';
import MultiSelect from '../../../../components/select/MultiSelect';
import { MultiSelectOption } from '../../../../components/select/types';

interface DomainMappingFormProps {
  attributeMappingManager: AttributeMappingManager;
  isEditClicked: boolean;
  onDomainFieldsChange: (newLeadFields: string[]) => void;
}

function DomainMappingFormComponent({
  attributeMappingManager,
  isEditClicked,
  onDomainFieldsChange,
}: DomainMappingFormProps) {
  const { isReadOnly, data: attributeMappingData } = attributeMappingManager;
  const salesforceDomainFields = attributeMappingData
    .getFilterForm('salesforce')
    .getFields('Account', false);

  const { domainFields } = attributeMappingData.getFilterForm('salesforce');

  const fieldToOption = (field: string): MultiSelectOption => ({
    label: unslugFieldName(field),
    value: field,
  });

  return (
    <>
      <p>
        Which field on the Account object identifies the web domain of the
        account?
        <span
          className="badge badge-info"
          data-tip="This field is used to match the lead or contact email domain to an account to get the account enrichment when the lead or contact is not already linked to an account"
        >
          ?
        </span>
        <ReactTooltip place="top" type="info" effect="solid" />
      </p>
      <MultiSelect
        disabled={isReadOnly || !isEditClicked}
        options={salesforceDomainFields.map(({ name }) => fieldToOption(name))}
        values={domainFields.map((field) => fieldToOption(field))}
        onChange={(newValues) => {
          onDomainFieldsChange(newValues.map(({ value }) => value));
        }}
      />
      {attributeMappingData.hasErrorOnProperty(
        'salesforce',
        -1,
        'domainField'
      ) && (
        <MissingFieldMicrocopy
          microcopy={`Please select a valid domain field value.`}
        />
      )}
    </>
  );
}

export default DomainMappingFormComponent;
