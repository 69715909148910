import $ from 'jquery';

type Options = { type?: string };

const notifications = {
  info(message: string, paramOptions: Options) {
    const options = paramOptions || {};
    options.type = 'info';
    this.show(message, options);
  },

  warning(message: string, paramOptions: Options) {
    const options = paramOptions || {};
    options.type = 'warning';
    this.show(message, options);
  },

  danger(message: string, paramOptions: Options) {
    const options = paramOptions || {};
    options.type = 'danger';
    this.show(message, options);
  },

  success(message: string, paramOptions: Options) {
    const options = paramOptions || {};
    options.type = 'success';
    this.show(message, options);
  },

  show_sidebar() {
    this.sidebar = true;
  },

  show(message: string, options: Options) {
    const defaults = {
      dismiss_timeout: 0,
      type: 'info',
    };

    // combine the settings with the defaults
    this.settings = $.extend({}, defaults, options || {});

    // create a wrapper element
    this.wrapper = $('#notification');
    const element = $(`<div class="notification alert alert-dismissible" role="alert">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      <p class='notification_text'><strong>Notification!</strong></p>
    </div>`);
    if (this.sidebar) {
      element.addClass('sidebar-visible');
    }
    this.wrapper.append(element);

    // adjust the class of the notification and modify the text
    this.alert = $('.notification', this.wrapper);
    this.alert.addClass(`alert-${this.settings.type}`);
    $('.notification_text', this.alert).html(message);

    // show the thing
    this.alert.show();
    this.alert.alert();

    // identify the main page wrapper and modify it to make the alert visible
    const content_wrappers = $('.main-wrapper, .subnav, .admin-wrapper');
    content_wrappers.addClass('notification-visible');

    // when closing the notification, return the wrapper to normal
    $('.notification').on('closed.bs.alert', () => {
      content_wrappers.removeClass('notification-visible');
    });

    // auto-dismiss the alert after dismiss_timeout
    if (this.settings.dismiss_timeout > 0) {
      setTimeout(() => {
        this.alert.alert('close');
      }, this.settings.dismiss_timeout);
    }

    return this;
  },
};

export default notifications;
