import React, { BaseSyntheticEvent, useEffect, useState } from 'react';

import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion';
import {
  Alert,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Table,
} from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import capitalize from 'lodash/capitalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  deleteUser,
  getUsers,
  inviteNewUser,
  updateUserRole,
  useGetTeams,
  useUpdateTeam,
  useDeleteTeam,
  useCreateTeam,
  useUpdateUserTeam,
  reinviteUser,
} from '../utils';
import { TeamServiceModel, UserServiceModel } from '../types';
import UserRow from './UserRow';
import { UserRoles } from '../../../types';
import { useSorting } from '../../../utils/hooks/useSorting';
import TeamRow from './TeamRow';
import { Tooltip } from '../../../components/ui/Tooltip';

interface UsersMainProps {
  tenant: number;
  isSuperKudu: boolean;
  isAdmin: boolean;
}

const tabs = ['Users', 'Teams'] as const;

const UpsertTeamForm = ({
  name,
  ownerField,
}: {
  name?: string;
  ownerField?: string;
}) => (
  <div className="text-left">
    <form>
      <FormGroup>
        <ControlLabel>Team name</ControlLabel>
        <FormControl
          id="team_name"
          type="text"
          placeholder='Enter team name (e.g. "SDR", "AE", "CS", "Support")'
          defaultValue={name}
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          Owner field{' '}
          <Tooltip text="Corresponds to an Account field in your Salesforce (OwnerId, SDR_Owner__c...). It allows identifying the book of accounts of user in MadKudu Copilot. The field must be a reference field (contains a salesforce user Id). Only 1 field is accepted">
            <FontAwesomeIcon
              icon={faCircleQuestion}
              fontSize={12}
              className="mr-2"
            />
          </Tooltip>
        </ControlLabel>
        <FormControl
          id="owner_field"
          type="text"
          placeholder='Enter owner field (e.g. "ownerID", "SDR_Owner__c...")'
          defaultValue={ownerField}
        />
      </FormGroup>
    </form>
  </div>
);

export default function Users({
  tenant,
  isSuperKudu,
  isAdmin,
}: UsersMainProps) {
  const cacheKey: QueryKey = ['users', tenant];
  const [activeTab, setActiveTab] = useState<typeof tabs[number]>(tabs[0]);
  const {
    data: users,
    isFetching: isFetchingUsers,
    error: usersError,
  } = useQuery(cacheKey, () => getUsers(tenant));
  const {
    data: teams,
    isFetching: isFetchingTeams,
    error: teamsError,
  } = useGetTeams(tenant);

  const { mutate: deleteTeam } = useDeleteTeam({
    tenant,
    onSuccess: (team) => {
      Swal.fire({
        icon: 'success',
        title: `Team ${team.name} has been deleted!`,
      });
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: `Failed to delete team`,
      });
    },
  });
  const { mutate: createTeam } = useCreateTeam({
    tenant,
    onSuccess: () => {
      Swal.close();
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'Failed to create team',
      });
    },
  });
  const { mutate: updateTeam } = useUpdateTeam({
    tenant,
    onSuccess: () => {
      Swal.close();
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'Failed to update team',
      });
    },
  });
  const { mutate: updateUserTeam } = useUpdateUserTeam({
    tenant,
    onSuccess: () => {
      Swal.close();
    },
    onError: () => {
      Swal.fire({
        icon: 'error',
        title: 'Failed to update user team',
      });
    },
  });

  const queryClient = useQueryClient();
  const setUsers = (_users: UserServiceModel[]) =>
    queryClient.setQueryData(cacheKey, () => _users);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const debouncedSearch = debounce(
    (_users: UserServiceModel[], query: string) =>
      setFilteredUsers(
        (_users ?? []).filter(({ email, first_name, last_name, role }) =>
          query
            ? `${email} ${first_name} ${last_name} ${role}`
                .toLowerCase()
                .includes(query.toLowerCase())
            : true
        )
      ),
    300
  );

  useEffect(() => {
    debouncedSearch(users, searchQuery);
  }, [users, searchQuery]);

  const { sortedItems, SortIcon, sortBy } = useSorting<UserServiceModel>(
    filteredUsers,
    {
      defaultSortedKey: '_id',
      defaultSortDirection: 'DESC',
      columns: [
        {
          key: 'first_name',
          sortFunction: (a, b) =>
            `${a?.first_name ?? ''} ${a?.last_name ?? ''}`.localeCompare(
              `${b?.first_name ?? ''} ${b?.last_name ?? ''}`,
              'en',
              { sensitivity: 'base' }
            ),
        },
      ],
    }
  );

  // Clean up debouncing when destroying the component
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onUserRoleChange = async (newUserRole: UserRoles, userId: string) => {
    const userToChange = users.find(({ _id }) => _id === userId);
    const newRole = await updateUserRole(tenant, newUserRole, userToChange);
    if (newRole === newUserRole) {
      const newUsers = cloneDeep(users).map((user) => {
        const newUser = user;
        if (user._id === userId) {
          newUser.role = newRole as UserRoles;
        }
        return newUser;
      });
      setUsers(newUsers);
    }
  };

  const onUserTeamChange = async (teamId: string, userId: string) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will update the user team',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Request in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();
      updateUserTeam({
        teamId,
        userId,
      });
    }
  };

  const onAddNewUserClick = async () => {
    const newUser = await inviteNewUser(tenant, teams);
    if (newUser) {
      const newUsers = cloneDeep(users);
      newUsers.push(newUser);
      setUsers(newUsers);
    }
  };

  const onDeleteUserClick = async (userId: string) => {
    const user = users.find(({ _id }) => _id === userId);
    const isUserDeleted = await deleteUser(user, tenant);
    if (isUserDeleted) {
      const newUsers = cloneDeep(users);
      setUsers(newUsers.filter(({ _id }) => userId !== _id));
    }
  };

  const onReinviteUserClick = async (userId: string) => {
    const user = users.find(({ _id }) => _id === userId);

    const result = await Swal.fire({
      title: `Re-invite ${user.email}?`,
      text: 'This will send a new invitation email to the user.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, send invitation',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: 'Sending invitation...',
        icon: 'info',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const updatedUser = await reinviteUser(tenant, userId);

        // Update local state
        const newUsers = cloneDeep(users);
        const userIndex = newUsers.findIndex(({ _id }) => _id === userId);
        if (userIndex !== -1) {
          newUsers[userIndex].resetPasswordExpires =
            updatedUser.resetPasswordExpires;
          setUsers(newUsers);
        }

        Swal.fire({
          icon: 'success',
          title: `Invitation sent to ${user.email}`,
        });
      } catch (error) {
        console.error('Failed to re-invite user:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed to send invitation',
          text: 'Please try again or contact support.',
        });
      }
    }
  };

  const handleDeleteTeam = async (team: TeamServiceModel) => {
    const result = await Swal.fire({
      title: `Delete ${team.name} team?`,
      html: `This will delete ${team.name} team permanently. Proceed?`,
      confirmButtonText: `Yes, delete ${team.name}`,
      showDenyButton: true,
      denyButtonText: 'Cancel',
      denyButtonColor: '#6e7881',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      deleteTeam(team);
    }
  };

  const handleCreateTeam = async () => {
    const reactSwal = withReactContent(Swal);
    const result = await reactSwal.fire({
      title: 'Create a new team',
      html: <UpsertTeamForm />,
      confirmButtonText: 'Create',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary mr-2',
      },
      reverseButtons: true,
      showCancelButton: true,
      preConfirm: () => {
        const name = (document.getElementById('team_name') as HTMLInputElement)
          .value;
        const ownerField = (document.getElementById(
          'owner_field'
        ) as HTMLInputElement).value;
        return { name, ownerField };
      },
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Request in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();

      const { name, ownerField } = result.value;
      createTeam({ name, ownerField });
    }
  };

  const handleUpdateTeam = async (team: TeamServiceModel) => {
    const reactSwal = withReactContent(Swal);
    const result = await reactSwal.fire({
      title: `Update ${team.name} team`,
      html: <UpsertTeamForm name={team.name} ownerField={team.ownerField} />,
      confirmButtonText: 'Update',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary mr-2',
      },
      reverseButtons: true,
      showCancelButton: true,
      preConfirm: () => {
        const name = (document.getElementById('team_name') as HTMLInputElement)
          .value;
        const ownerField = (document.getElementById(
          'owner_field'
        ) as HTMLInputElement).value;
        return { name, ownerField };
      },
    });
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Request in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();

      const { name, ownerField } = result.value;
      updateTeam({ teamId: team._id, team: { name, ownerField } });
    }
  };

  if (isFetchingUsers || isFetchingTeams) {
    return (
      <div className="d-flex justify-content-center py-4">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const canEdit = isSuperKudu || isAdmin;

  if (usersError || teamsError) {
    return (
      <Alert bsStyle="danger">
        Something went wrong, please contact our support team.
      </Alert>
    );
  }

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
        <ul className="nav nav-pills mx-auto">
          {tabs.map((tabName) => (
            <li className="nav-item" key={tabName}>
              <a
                className={`nav-link ${
                  activeTab === tabName ? 'active show' : ''
                }`}
                id={tabName}
                data-toggle="tab"
                href={`#${tabName}`}
                onClick={() => {
                  setActiveTab(tabName);
                }}
                role="tab"
              >
                {capitalize(tabName)}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="container">
        <div className="mt-4">
          {activeTab === 'Users' && (
            <>
              <h3>Users</h3>
              <div className="d-flex flex-row gap-4">
                <FormControl
                  className="mb-0"
                  style={{ flex: 1, maxWidth: 300 }}
                  type="text"
                  value={searchQuery}
                  placeholder="Search any user.."
                  onChange={(event: BaseSyntheticEvent) => {
                    setSearchQuery(event.target.value);
                  }}
                />
                {searchQuery && (
                  <Button
                    className="btn btn-outline-secondary mb-3"
                    onClick={() => setSearchQuery('')}
                  >
                    <i className="fas fa-times user-select-none" />
                  </Button>
                )}
                <Button
                  className="btn btn-primary mb-3"
                  onClick={onAddNewUserClick}
                >
                  Add new user
                </Button>
              </div>
              <Table striped bordered condensed hover>
                <thead>
                  <tr>
                    <th
                      className="cursor-pointer user-select-none"
                      onClick={() => sortBy('email')}
                    >
                      Email
                      <SortIcon columnKey="email" className="float-right" />
                    </th>
                    <th
                      className="cursor-pointer user-select-none"
                      onClick={() => sortBy('first_name')}
                    >
                      Name
                      <SortIcon
                        columnKey="first_name"
                        className="float-right"
                      />
                    </th>
                    <th
                      className="cursor-pointer user-select-none"
                      onClick={() => sortBy('team')}
                    >
                      Team
                      <SortIcon columnKey="team" className="float-right" />
                    </th>
                    <th
                      className="cursor-pointer user-select-none"
                      onClick={() => sortBy('role')}
                    >
                      Role
                      <SortIcon columnKey="role" className="float-right" />
                    </th>
                    {canEdit && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {sortedItems.map((user) => (
                    <UserRow
                      key={user._id}
                      user={user}
                      teams={teams}
                      onUserTeamChange={(teamId) =>
                        onUserTeamChange(teamId, user._id)
                      }
                      onUserRoleChange={(newRole) =>
                        onUserRoleChange(newRole, user._id)
                      }
                      onDeleteUserClick={() => onDeleteUserClick(user._id)}
                      onReinviteUserClick={() => onReinviteUserClick(user._id)}
                      isAllowedToDelete={canEdit}
                    />
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {activeTab === 'Teams' && (
            <>
              <div className="d-flex align-items-start">
                <div>
                  <h3>Teams</h3>
                  <p>Manage your team(s) books of accounts</p>
                </div>
                <Button
                  className="btn btn-primary mb-3 float-right ml-auto"
                  onClick={handleCreateTeam}
                >
                  Add new team
                </Button>
              </div>
              <Table striped bordered condensed hover>
                <thead>
                  <tr>
                    <th className="cursor-pointer user-select-none">Name</th>
                    <th className="cursor-pointer user-select-none">
                      Salesforce Account Owner field{' '}
                      <Tooltip text="Corresponds to an Account field in your Salesforce(OwnerId, SDR_Owner__c...). It allows identifying the book of accounts of user in MadKudu Copilot. It must be a reference field (contains a salesforce user Id)">
                        <FontAwesomeIcon
                          icon={faCircleQuestion}
                          fontSize={12}
                          className="mr-2"
                        />
                      </Tooltip>
                    </th>
                    {canEdit && <th style={{ width: '120px' }}>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {teams?.map((team) => (
                    <TeamRow
                      key={team._id}
                      team={team}
                      actionColumn={
                        canEdit && (
                          <div className="d-flex gap-4">
                            <Button
                              className="btn btn-primary"
                              onClick={() => handleUpdateTeam(team)}
                            >
                              <i className="fa fa-pencil"></i>
                            </Button>
                            <Button
                              className="btn btn-danger"
                              onClick={() => handleDeleteTeam(team)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </div>
                        )
                      }
                    />
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
