import React from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';

type DomainListFormProps = {
  formData: UnknownObject;
};

type DomainListFormState = {
  data: {
    domains?: string[];
  };
};

export default class DomainListForm extends React.Component<
  DomainListFormProps,
  DomainListFormState
> {
  constructor(props: DomainListFormProps) {
    super(props);

    this.handleDomainFieldsChange = this.handleDomainFieldsChange.bind(this);
    this.removeDomainField = this.removeDomainField.bind(this);
    this.createDomainField = this.createDomainField.bind(this);

    const data = props.formData && props.formData.data;
    this.state = { data };
    if (data && !data.domains) this.state.data.domains = [];
  }

  componentDidMount() {
    const { domains } = this.state.data;
    if (!domains || !domains.length) {
      this.createDomainField();
    }
  }

  componentDidUpdate() {
    this.props.formData.data = this.state.data;
  }

  createDomainField() {
    const { data } = this.state;
    const newDomainFieldsArray = [...data.domains, ''];
    this.setState({
      data: { ...data, domains: newDomainFieldsArray },
    });
  }

  removeDomainField(index: number) {
    const { data } = this.state;
    const newArray = data.domains.filter((_e, i) => i !== index);

    this.setState({
      data: { ...data, domains: newArray },
    });
  }

  handleDomainFieldsChange(e: any, index: number) {
    const { data } = this.state;
    const newArray = [...data.domains];
    newArray[index] = e.target.value;

    this.setState({
      data: {
        ...this.state.data,
        domains: newArray,
      },
    });
  }

  render() {
    const { data } = this.state;

    if (!data.domains) return null;
    return data.domains.map((domain, index, domainsList) => {
      return (
        <Row key={`domain_list_${index}`} className="mt-1">
          <Col md={8}>
            <FormControl
              name="domainFields"
              type="text"
              value={domain}
              onChange={(e) => this.handleDomainFieldsChange(e, index)}
            />
          </Col>
          {index >= domainsList.length - 1 && (
            <Col>
              <Button
                bsStyle="primary"
                className="mr-1"
                onClick={() => this.createDomainField()}
              >
                +
              </Button>
            </Col>
          )}
          {
            // if item is first and is alone
            // or if list is bigger than one (in order to be able to remove first item)
            (domainsList.length === 1 && index !== 0) ||
              (domainsList.length > 1 && (
                <Col>
                  <Button
                    bsStyle="danger"
                    onClick={() => this.removeDomainField(index)}
                  >
                    X
                  </Button>
                </Col>
              ))
          }
        </Row>
      );
    });
  }
}
