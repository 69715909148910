import Backbone from 'backbone';
import { Flag_collection } from './models/flag';
import { BackboneViewProps } from './types';

require('backbone.epoxy');

export default (Backbone as any).Epoxy.Model.extend({
  idAttribute: '_id',

  urlRoot: () => `${BONGO_URL}/v1/org/`,

  initialize(properties: BackboneViewProps) {
    this.tenant = properties.tenant || this.tenant;
    this.flags = new Flag_collection({ tenant: this.tenant });
  },

  defaults: {
    _id: '',
    domain: '',
    name: '',
    other_domains: [],
  },

  is_fetched() {
    // asume that if the name property is fetched, the tenant has been correctly fetched
    return !!this.get('name');
  },

  reset(tenant_doc: UnknownObject = {}) {
    this.set(tenant_doc);
    this.flags.initialize({ tenant: tenant_doc._id });
  },

  // override the default backbone fetch to also get the flags
  async fetch(options: any) {
    await this.flags.fetch();
    return Backbone.Collection.prototype.fetch.call(this, options);
  },

  // not in use currently, but keeping for the example
  // note: I think we need to force recomputations after updating the model
  computeds: {
    // has_active_integration: {
    //   get () {
    //     const active_integration = _.find(this.get('integrations'), (value) => {
    //       return value.pull && (value.pull.date_range || value.pull.active)
    //     })
    //     return typeof active_integration !== 'undefined'
    //   }
    // }
  },
});
