import Backbone from 'backbone';
import $ from 'jquery';
import getQueryParameter from '../auth/get_query_parameter';
import model_integration from '../models/integration';
import { BackboneViewProps } from '../types';
import FieldsMapping from './fields_mapping';
import { deleteIntegrationAndLaunchPopUp } from './utils';

const template = require('./intercom.pug');

export default Backbone.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #mki_oauth_btn': 'redirect_to_oauth',
    'click #mki_delete_connection': 'delete',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'intercom';
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
    const activate_intercom = getQueryParameter('activate_intercom') === 'true';

    if (activate_intercom === true) {
      this.redirect_to_oauth(true);
    }
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    const credentialsFound = this.model.get('not_found') !== true;
    const html = $(
      '#tpl_main',
      template({ integration: this.integration })
    ).html();
    this.$el.html(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound: this.model.get('not_found') !== true,
    });

    this.assign(fieldsMappingView, '#fields_mapping');

    if (credentialsFound) {
      this.render_oauth_connected();
    } else {
      this.render_oauth();
    }
    return this;
  },

  render_oauth() {
    const html = $(
      '#tpl_oauth',
      template({ integration: this.integration })
    ).html();
    $('#mki_oauth').html(html);
    return this;
  },

  redirect_to_oauth(redirectToIntercomAppStore: boolean) {
    const redirectUrl = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/oauth/redirect`;
    window.location.href =
      redirectUrl +
      (redirectToIntercomAppStore === true ? '?redirect_to_intercom=true' : '');
  },

  async delete() {
    await deleteIntegrationAndLaunchPopUp(this.tenant, 'intercom');
  },

  render_oauth_connected() {
    const params = {
      app_id: this.model.get('app_id'),
      integration: this.integration,
    };
    const html = $('#tpl_connected', template(params)).html();
    $('#mki_oauth').html(html);

    return this;
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },
});
