import React from 'react';
import $ from 'jquery';
import EventsArrayComponent from './EventsArrayComponent';
import { MODEL_TYPES_NAMES } from '../../../utils/constants';

type BehavioralModelComponentProps = {
  tenant: number;
  type: 'pql' | 'pql2' | 'mql' | 'mqa';
  featuresEvaluation: UnknownObject;
};

type BehavioralModelComponentState = {
  featuresEvaluationToShow: UnknownObject;
};

export default class BehavioralModelComponent extends React.Component<
  BehavioralModelComponentProps,
  BehavioralModelComponentState
> {
  constructor(props: BehavioralModelComponentProps) {
    super(props);
    this.state = {
      featuresEvaluationToShow: { variables: [] },
    };
  }

  componentDidMount() {
    const featureEvaluationToShow = this.props.featuresEvaluation
      ? this.props.featuresEvaluation
      : { variables: [] };
    this.setState({
      featuresEvaluationToShow: featureEvaluationToShow,
    });
  }

  render() {
    // eslint-disable-next-line func-names
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    const { tenant, type } = this.props;
    return (
      <div>
        <h2>{MODEL_TYPES_NAMES[type]}</h2>
        <div>
          <p className="muted">
            In this section, you can find the weight and decay factor of each
            MadKudu Event building up the Likelihood to Buy Score. The{' '}
            <strong>MadKudu Predictive Power</strong>{' '}
            <a
              href="https://www.madkudu.com/blog/marketo-lead-scoring"
              rel="noopener noreferrer"
              target="_blank"
              className="badge badge-secondary"
              data-toggle="tooltip"
              data-original-title="Click ↓ to find out more"
            >
              ?
            </a>{' '}
            and <strong>decay</strong>{' '}
            <a
              href="#"
              rel="noopener noreferrer"
              target="_blank"
              className="badge badge-secondary"
              data-toggle="tooltip"
              data-original-title="Scores will gradually decay over the time decay period to ensure that the 'Likelihood to Buy' score accurately depict lead behaviors at the point time."
            >
              ?
            </a>{' '}
            factor have been allocated to optimize the model performance.
            <br />
            <br />
            The <strong>MadKudu Events</strong> are mapped from the events
            tracked in your integrations. To find out what user activities the
            MadKudu Events are mapped from, head to the{' '}
            <a href={`/org/${tenant}/mapping/event_mapping`}>
              <strong>Event Mapping.</strong>
            </a>
          </p>
        </div>
        <br />
        <div>
          <EventsArrayComponent
            variables={this.state.featuresEvaluationToShow.variables}
          />
        </div>
      </div>
    );
  }
}
