import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import Swal from 'sweetalert2';

require('bootstrap-toggle');

const tenantsTemplate = require('./tenants.pug');

const DEFAULT_LIMIT_PER_PAGE = 20;

async function getTenantPageCount(query: {}) {
  const { data = { count: 0 } } = await axios.get(
    `${BONGO_URL}/v1/org/paginated/count`,
    {
      params: { dbQuery: query },
    }
  );
  const count = Math.ceil(data.count / DEFAULT_LIMIT_PER_PAGE);

  // list page buttons 1-20, then final page
  if (count > 20) {
    const pages = new Array(20);
    pages.push(count);
    return pages;
  }

  return new Array(count);
}

async function getTenantData(query: {}, pageNumber?: number) {
  const { data = [] } = await axios.get(`${BONGO_URL}/v1/org/paginated`, {
    params: {
      dbQuery: query,
      limit: DEFAULT_LIMIT_PER_PAGE,
      skip: (pageNumber - 1) * DEFAULT_LIMIT_PER_PAGE || 0,
    },
  });

  return data;
}

export default Backbone.View.extend({
  events: {
    'submit #form_search_tenants': 'onSearch',
    'click .page-button': 'changePage',
    'change #active-switch': 'toggleActive',
    'click #delete': 'delete',
  },

  async initialize(options: { currentPage?: number } = {}) {
    this.tenants = [];
    this.pages = 0;
    this.currentPage = options.currentPage || 1;
    this.showActive = this.showActive ?? true;

    let query = {};

    if (this.showActive) {
      query = { 'status.active': true };
    }

    try {
      this.tenants = await getTenantData(query, this.currentPage);
      this.pages = await getTenantPageCount(query);
      this.render();
    } catch (err) {
      console.error(err);
    }
  },

  delete(e: any) {
    const tenant = $(e.currentTarget).attr('value');
    const deleteTenant = $(e.currentTarget).attr('delete') === 'tenant';

    let url = '';
    let message = '';

    if (deleteTenant) {
      message = 'Are you sure you want to delete this tenant?';
      url = `${BONGO_URL}/v1/org/${tenant}`;
    } else {
      message = "Are you sure you want to delete this tenant's data?";
      url = `${BONGO_URL}/v1/org/${tenant}/data`;
    }

    Swal.fire({
      title: message,
      text: 'Enter the tenant ID below to confirm this action',
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: (id: string) => {
        if (Number(id) !== Number(tenant)) {
          Swal.showValidationMessage(
            'Request failed: Tenant ID does not match'
          );
          return;
        }

        return axios
          .delete(url)
          .then((response) => {
            if (response.status < 200 || response.status > 300) {
              throw new Error('Something went wrong!');
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((response: any) => {
      if (response.value) {
        Swal.fire({
          icon: 'success',
          title: 'Deleted successfully!',
        });
      }
    });
  },

  toggleActive() {
    this.showActive = !this.showActive;

    this.initialize();
  },

  changePage(e: any) {
    e.preventDefault();
    Backbone.history.navigate(
      `/admin/tenant/list?page=${e.target.innerText || 1}`,
      true
    );
  },

  async onSearch(e: any) {
    e.preventDefault();

    const search = (document.getElementById('search_input') as HTMLInputElement)
      .value;
    let query: { _id?: number; name?: string; 'status.active'?: boolean } = {};

    if (this.showActive) {
      query = { 'status.active': true };
    }

    if (search) {
      if (!Number.isNaN(Number(search))) {
        query._id = Number(search);
      } else {
        query.name = search;
      }
    }

    const data = await getTenantData(query);
    this.pages = await getTenantPageCount(query);
    this.currentPage = 1;

    this.searchValue = search;
    this.tenants = data;
    this.render();
  },

  render() {
    this.$el.empty();

    this.$el.html(
      tenantsTemplate({
        tenants: this.tenants,
        pages: this.pages,
        currentPage: this.currentPage,
        onSearch: this.onSearch,
        searchValue: this.searchValue,
        showActive: this.showActive,
      })
    );

    return this;
  },
});
