import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input } from '../../../components/ui';
import { Loading } from '../Loading';
import { useChangeZoomInfoApiKey } from '../hooks/useChangeZoomInfoApiKey';
import { zoomInfoCredentialsSchema } from '../types';
import { TextArea } from '../../../components/ui/TextArea';

type Props = {
  tenant: number;
};

export function ConnectionView({ tenant }: Props) {
  const { reset: resetForm, control, handleSubmit, formState } = useForm({
    reValidateMode: 'onChange',
    resolver: zodResolver(zoomInfoCredentialsSchema),
    defaultValues: {
      clientId: '',
      username: '',
      privateKey: '',
    },
  });

  const { mutate, isLoading: isConnecting, isError } = useChangeZoomInfoApiKey(
    tenant,
    {
      onSuccess: () => resetForm(),
    }
  );

  if (isConnecting) return <Loading loadingLabel="Connecting to ZoomInfo" />;

  const {
    clientId: clientIdKeyErrors,
    username: usernameKeyErrors,
    privateKey: privateKeyErrors,
  } = formState.errors;

  return (
    <>
      <p>
        <p>
          Status:{' '}
          {!isConnecting && <b className="tw-text-red-500">Not connected</b>}
        </p>
        Enter your credentials bellow, to authorize MadKudu to connect to
        ZoomInfo
      </p>

      <Form
        className="tw-flex tw-flex-col tw-items-start tw-gap-4"
        onSubmit={handleSubmit((values) => {
          mutate(values);
        })}
      >
        <div className="tw-w-full">
          <span>
            <b>Username</b>
          </span>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || usernameKeyErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500'
                }
                placeholder="Enter your ZoomInfo username"
                type="text"
                {...field}
              />
            )}
          />
          {usernameKeyErrors?.message && (
            <div className="tw-text-red-500 tw-mt-1">
              {usernameKeyErrors?.message}
            </div>
          )}
        </div>

        <div className="tw-w-full">
          <span>
            <b>Client id</b>
          </span>
          <Controller
            name="clientId"
            control={control}
            render={({ field }) => (
              <Input
                className={
                  (isError || clientIdKeyErrors) &&
                  'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500'
                }
                placeholder="Enter your ZoomInfo client id"
                type="text"
                {...field}
              />
            )}
          />
          {clientIdKeyErrors?.message && (
            <div className="tw-text-red-500 tw-mt-1">
              {clientIdKeyErrors?.message}
            </div>
          )}
        </div>

        <div className="tw-w-full">
          <div>
            <b>Private key</b>
          </div>
          <div className="tw-text-gray-300 tw-mb-2">
            Copy and paste here the whole private key (including "-----BEGIN
            PRIVATE KEY-----" and "-----END PRIVATE KEY-----" headers) from
            ZoomInfo
          </div>
          <Controller
            name="privateKey"
            control={control}
            render={({ field }) => (
              <TextArea
                className={`tw-resize-none tw-h-[400px] ${
                  isError || privateKeyErrors
                    ? 'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500'
                    : ''
                }`}
                placeholder="Enter your ZoomInfo private key"
                {...field}
              />
            )}
          />
          {privateKeyErrors?.message && (
            <div className="tw-text-red-500 tw-mt-1">
              {privateKeyErrors?.message}
            </div>
          )}
        </div>

        {isError && (
          <p className="tw-text-red-500 tw-mt-2">
            Invalid API token. Please double-check the token and try again.
          </p>
        )}

        <Button type="submit" className="tw-mt-5" disabled={isConnecting}>
          Connect
        </Button>
      </Form>
    </>
  );
}
