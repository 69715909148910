import axios from 'axios';

export const clearBitRevealPlans = {
  trial: 'madkudu-reveal-trial',
  'less than 100k MUVs': 'madkudu-reveal-under-100k',
  'more than 100k MUVs': 'madkudu-reveal-over-100k',
};

export async function getTenantDomain(tenant: number): Promise<string> {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}`;
    const result = await axios.get(url);
    const { domain } = result.data;
    return domain;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function deactivateClearbitReveal(tenant: number) {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}/integrations/clearbit_reveal/remove`;
    await axios.delete(url);
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function activateClearbitReveal(
  tenant: number,
  userEmail: string,
  plan: string
) {
  try {
    const tenantDomain = await getTenantDomain(tenant);

    const url = `${BONGO_URL}/v1/org/${tenant}/integrations/clearbit_reveal/create`;
    const body = {
      domain: tenantDomain,
      email: userEmail,
      plan,
    };
    await axios.put(url, body);
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function getClearbitRevealCPullState(
  tenant: number
): Promise<boolean> {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}/integrations/clearbit_reveal`;
    const { data } = await axios.get(url);
    return data.pull.active;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getClearbitRevealActivePlan(
  tenant: number
): Promise<string> {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}/integrations/clearbit_reveal/credentials`;
    const { data } = await axios.get(url);
    if (data?.plan && data.plan.length > 0) {
      return data.plan;
    }
    return clearBitRevealPlans.trial;
  } catch (e) {
    console.error(e);
    return clearBitRevealPlans.trial;
  }
}

export async function updateClearbitRevealActivePlan(
  tenant: number,
  plan: string
) {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}/integrations/clearbit_reveal/create`;
    await axios.put(url, { plan });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}
