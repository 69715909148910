import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import { getBadgeColorFrom } from './EventMappingHeader';
import { SelectOptionLike } from './utils';
import CircleBadge from './utils/CircleBadge';

export function EventMappingHistoryValueContainer(
  props: ValueContainerProps<SelectOptionLike>
) {
  const selectedOptions = props.getValue() as SelectOptionLike[];
  const className = getBadgeColorFrom(String(selectedOptions?.[0]?.label));
  return (
    <div
      className="d-flex flex-row align-items-center gap-2 pl-2"
      style={{ flex: 1 }}
    >
      <div>
        <CircleBadge variant={className} />
      </div>
      <components.ValueContainer {...props} />
    </div>
  );
}
