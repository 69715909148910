import Backbone from 'backbone';
import forEach from 'lodash/forEach';

import { Flag_collection } from '../models/flag';
import { BackboneViewProps } from '../types';
import app from '../app';

const $ = require('jquery');
require('bootstrap-toggle');

const template = require('./flags.pug');
const template_403 = require('../errors/403.pug');

const Flag_view = (Backbone as any).Epoxy.View.extend({
  events: { 'click .mki_flag': 'settings' },

  setterOptions: { save: true },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.superKudu = user.superKudu;
    if (!this.superKudu) {
      Backbone.history.navigate('error/403', true);
      return this;
    }
    this.render();
  },

  render() {
    const html = $('#tpl_flag', template()).html();
    this.$el.html(html);
    this.applyBindings();
    $('.mki_toggle', this.$el).bootstrapToggle();
    return this;
  },

  settings(event: any) {
    event.preventDefault();
    Backbone.history.navigate(
      `/org/${this.tenant}/flags/${this.model.get('name')}`,
      true
    );
  },
});

const Flags_view = (Backbone as any).Epoxy.View.extend({
  events: { 'click #btn_save': 'event_save' },

  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
  },

  render() {
    forEach(this.collection.models, (model) => {
      $('#mki_flags').append(
        new Flag_view({
          tenant: this.tenant,
          model,
          collection: this.collection,
        }).$el
      );
    });
    return this;
  },
});

export default Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.collection = new Flag_collection({ tenant: this.tenant });
    this.flags_view = new Flags_view({
      tenant: this.tenant,
      collection: this.collection,
    });
    this.collection.fetch({
      success: () => this.render(),
      error: () => this.render_error(),
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.flags_view.render();
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },
});
