import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  Row,
  Table,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import { DefaultFiltersForm } from '../../../../../models/filters_forms/DefaultFiltersForm';
import { ConnectorsNames } from '../../../../../../../types';
import {
  DATASOURCE_OBJECTS,
  EVENTS_VERBS,
  getVerbBoundaries,
  VERBS,
  VERBS_FLOATS_ONLY,
} from '../../../../../../utils';
import { unslugFieldName } from '../../../../../../pullConfigManager';
import { getFieldsNameFromTable } from '../../../../utils';
import { DEFAULT_FIRST_VALUE } from '../../../../../models/ParameterDataModel';
import { ConnectorFieldNameDataModel } from '../../../../../../models/ConnectorFieldNameDataModel';
import {
  ConversionMappingError,
  ConversionMappingErrorLevel,
} from '../../../../../models/ConversionMappingData';
import MissingFieldMicrocopy from '../../../../../../../components/ErrorMicroCopyComponent';
import {
  FIELD_PLACEHOLDER_VALUE,
  OBJECT_PLACEHOLDER_VALUE,
  VERB_PLACEHOLDER_VALUE,
} from '../../../../../../constants';
import VerbsNames from '../../../../../../enums/VerbsNames';

type ParameterFormComponentsProps = {
  editable: boolean;
  errors: ConversionMappingError[];
  conversionTypeDataIndex: number;
  createNewParameterByDefault: boolean;
  filterForm: DefaultFiltersForm;
  parametersFieldsNames: ConnectorFieldNameDataModel[];
  connector: ConnectorsNames;
  handleLower: (c: number) => void;
  createNewParameter: () => void;
  createNewParameterValue: (c: number) => void;
  removeParameterValue: (c: number) => void;
  handleLogicChange: (c: string) => void;
  handleValueChange: (c: string, v: number, e: number) => void;
  handleTableChange: (c: string, v: number) => void;
  handleFieldChange: (c: string, v: number) => void;
  handleConditionChange: (c: string, v: number) => void;
  handleRemoveParameter: (c: number) => void;
  withoutTitle?: boolean;
  eventsVerbs?: string[];
  formData?: UnknownObject;
};

export default class ParameterFormComponents extends React.Component<
  ParameterFormComponentsProps,
  {}
> {
  constructor(props: ParameterFormComponentsProps) {
    super(props);
    this.handleLogicChange = this.handleLogicChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleConditionChange = this.handleConditionChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.createNewParameter = this.createNewParameter.bind(this);
    this.handleRemoveParameter = this.handleRemoveParameter.bind(this);
    this.handleLower = this.handleLower.bind(this);
  }

  componentDidMount() {
    const { filterForm, createNewParameterByDefault } = this.props;
    const { parameters } = filterForm;
    if (parameters.length) return;

    if (createNewParameterByDefault) {
      this.createNewParameter();
    }
  }

  handleLower(parameterIndex: number) {
    this.props.handleLower(parameterIndex);
  }

  createNewParameter() {
    this.props.createNewParameter();
  }

  handleLogicChange(e: any) {
    this.props.handleLogicChange(e.target.value.toUpperCase());
  }

  handleValueChange(e: any, parameterIndex: number, valueIndex: number) {
    this.props.handleValueChange(e.target.value, parameterIndex, valueIndex);
  }

  handleTableChange(value: string, parameterIndex: number) {
    this.props.handleTableChange(value, parameterIndex);
  }

  handleFieldChange(value: string, parameterIndex: number) {
    this.props.handleFieldChange(value, parameterIndex);
  }

  handleConditionChange(value: string, parameterIndex: number) {
    this.props.handleConditionChange(value, parameterIndex);
  }

  handleRemoveParameter(parameterIndex: number) {
    this.props.handleRemoveParameter(parameterIndex);
  }

  hasErrorLevelOnParameter(
    errors: ConversionMappingError[],
    level: ConversionMappingErrorLevel,
    parameterIndex: number,
    valueIndex?: number
  ) {
    if (valueIndex) {
      return errors.some(
        (error) =>
          error.level === level &&
          error.parameterIndex === parameterIndex &&
          error.valueIndex === valueIndex
      );
    }
    return errors.some(
      (error) =>
        error.level === level && error.parameterIndex === parameterIndex
    );
  }

  render() {
    const {
      filterForm,
      parametersFieldsNames,
      editable,
      eventsVerbs,
      withoutTitle,
      connector,
      conversionTypeDataIndex,
      errors,
    } = this.props;
    const { parameters, parametersLogic } = filterForm;

    const verbsToUse = eventsVerbs ? EVENTS_VERBS : VERBS;

    const concernedErrors = errors.filter(
      ({ conversionTypeIndex, connector: errorConnector }) =>
        errorConnector === connector &&
        conversionTypeIndex === conversionTypeDataIndex
    );

    const parameterLogicError = concernedErrors.find(
      (error) =>
        error.level === 'condition logic extra parameters' ||
        error.level === 'condition logic empty' ||
        error.level === 'condition logic ignored parameters' ||
        error.level === 'condition logic syntax error'
    );

    return (
      <React.Fragment>
        <ReactTooltip place="top" />
        {!withoutTitle && <h5>Additional Parameters</h5>}
        <Row>
          <Col sm={12}>
            {parameters.length ? (
              <Row className="mb-2">
                <Col
                  componentClass={ControlLabel}
                  className="font-weight-bold mt-1"
                  sm={3}
                >
                  Edit condition logic:
                </Col>
                <Col sm={8}>
                  <Row>
                    <FormControl
                      type="text"
                      data-tip="You can use parenthesis eg: $1 AND ($2 OR $3)"
                      value={parametersLogic}
                      onChange={(e) => this.handleLogicChange(e)}
                      placeholder="$1 AND ($2 OR $3)"
                      name="parametersLogic"
                      readOnly={!editable}
                    />
                  </Row>
                  <Row>
                    {parameterLogicError?.level === 'condition logic empty' && (
                      <MissingFieldMicrocopy microcopy="Please enter a valid condition logic." />
                    )}
                    {parameterLogicError?.level ===
                      'condition logic syntax error' && (
                      <MissingFieldMicrocopy microcopy="Syntax error, please respect the following pattern: $1 AND ($2 OR $3)." />
                    )}
                    {parameterLogicError?.level ===
                      'condition logic extra parameters' && (
                      <MissingFieldMicrocopy
                        microcopy={`Extra parameters shouldn't be used: ${parameterLogicError.indexes.join(
                          ', '
                        )}`}
                      />
                    )}
                    {parameterLogicError?.level ===
                      'condition logic ignored parameters' && (
                      <MissingFieldMicrocopy
                        microcopy={`Missing parameter(s):: ${parameterLogicError.indexes.join(
                          ', '
                        )}`}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Alert bsStyle="info">No Parameters yet.</Alert>
            )}
            <div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="p-1"></th>
                    <th className="p-1">Object</th>
                    <th className="p-1">Fields from table</th>
                    <th className="p-1">Condition</th>
                    <th className="p-1">Value</th>
                    <th className="p-1">Case insensitive</th>
                    <th className="p-1">Actions</th>
                  </tr>
                </thead>
                {parameters.map((parameter, parameterIndex) => {
                  return (
                    parameter && (
                      <tbody
                        key={`${conversionTypeDataIndex}_form_group_${parameterIndex}`}
                      >
                        <tr key={parameterIndex}>
                          <td className="p-1">
                            <span style={{ opacity: 0.5 }}>
                              ${parameterIndex + 1}
                            </span>
                          </td>
                          <td className="p-1">
                            <Select
                              value={{
                                label: parameter.table,
                                value: parameter.table,
                              }}
                              isDisabled={!editable}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (baseStyles) => ({
                                  ...baseStyles,
                                  zIndex: 1065, // Swal container z-index is 1060
                                }),
                              }}
                              placeholder={OBJECT_PLACEHOLDER_VALUE}
                              options={DATASOURCE_OBJECTS[connector].map(
                                (object) => ({
                                  label: object,
                                  value: object,
                                })
                              )}
                              onChange={({ value }) => {
                                this.handleTableChange(value, parameterIndex);
                              }}
                            />
                            {this.hasErrorLevelOnParameter(
                              concernedErrors,
                              'parameter invalid table',
                              parameterIndex
                            ) && (
                              <MissingFieldMicrocopy microcopy="Please select an object." />
                            )}
                          </td>
                          <td className="p-1">
                            <Select
                              value={{
                                label: unslugFieldName(parameter.field),
                                value: parameter.field,
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (baseStyles) => ({
                                  ...baseStyles,
                                  zIndex: 1065, // Swal container z-index is 1060
                                }),
                              }}
                              isDisabled={
                                !editable ||
                                this.hasErrorLevelOnParameter(
                                  concernedErrors,
                                  'parameter invalid table',
                                  parameterIndex
                                )
                              }
                              placeholder={FIELD_PLACEHOLDER_VALUE}
                              options={getFieldsNameFromTable(
                                parametersFieldsNames,
                                parameter.table
                              ).fields.map(({ name }) => ({
                                label: unslugFieldName(name),
                                value: name,
                              }))}
                              onChange={({ value }) => {
                                this.handleFieldChange(value, parameterIndex);
                              }}
                            />
                            {this.hasErrorLevelOnParameter(
                              concernedErrors,
                              'parameter invalid field',
                              parameterIndex
                            ) && (
                              <MissingFieldMicrocopy microcopy="Please select a field." />
                            )}
                          </td>
                          <td className="p-1">
                            <Select
                              value={{
                                label: parameter.verb,
                                value: parameter.verb,
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (baseStyles) => ({
                                  ...baseStyles,
                                  zIndex: 1065, // Swal container z-index is 1060
                                }),
                              }}
                              isDisabled={!editable}
                              placeholder={VERB_PLACEHOLDER_VALUE}
                              options={verbsToUse.map((verb: VerbsNames) => ({
                                label: verb,
                                value: verb,
                              }))}
                              onChange={({ value }) => {
                                this.handleConditionChange(
                                  value,
                                  parameterIndex
                                );
                              }}
                            />
                            {this.hasErrorLevelOnParameter(
                              concernedErrors,
                              'parameter invalid verb',
                              parameterIndex
                            ) && (
                              <MissingFieldMicrocopy microcopy="Please select a condition." />
                            )}
                          </td>
                          <td className="p-1">
                            <div>
                              {parameter.values.map(
                                (value: string, valueIndex: number) => {
                                  return (
                                    <div
                                      key={`${conversionTypeDataIndex}_value_${valueIndex}_for_${parameterIndex}`}
                                    >
                                      <FormControl
                                        name="value"
                                        className="mb-1"
                                        type={
                                          VERBS_FLOATS_ONLY.includes(
                                            parameter.verb
                                          )
                                            ? 'number'
                                            : 'text'
                                        }
                                        value={
                                          value === DEFAULT_FIRST_VALUE
                                            ? ''
                                            : value
                                        }
                                        placeholder={
                                          parameters[0].type === 'SQO'
                                            ? 'Enter value'
                                            : '0'
                                        }
                                        readOnly={!editable}
                                        onChange={(e) =>
                                          this.handleValueChange(
                                            e,
                                            parameterIndex,
                                            valueIndex
                                          )
                                        }
                                      />
                                      {this.hasErrorLevelOnParameter(
                                        concernedErrors,
                                        'parameter invalid values',
                                        parameterIndex,
                                        valueIndex
                                      ) && (
                                        <MissingFieldMicrocopy microcopy="Please enter a valid value." />
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </td>
                          <td className="text-center p-1">
                            <Checkbox
                              checked={parameter.lower}
                              disabled={!editable}
                              className="mt-2"
                              onChange={() => this.handleLower(parameterIndex)}
                            />
                          </td>
                          <td className="p-1">
                            <div className="mb-1">
                              {parameter.values.length <
                                getVerbBoundaries(parameter.verb).max && (
                                <Button
                                  bsStyle="primary"
                                  title="Add value"
                                  disabled={!editable}
                                  bsSize="small"
                                  onClick={() => {
                                    this.props.createNewParameterValue(
                                      parameterIndex
                                    );
                                  }}
                                >
                                  <i className="fas fa-plus fa-sm" />
                                </Button>
                              )}
                              {parameter.values.length >
                                getVerbBoundaries(parameter.verb).min && (
                                <Button
                                  title="Delete last value"
                                  bsStyle="danger"
                                  disabled={!editable}
                                  bsSize="small"
                                  className="ml-1"
                                  onClick={() => {
                                    this.props.removeParameterValue(
                                      parameterIndex
                                    );
                                  }}
                                >
                                  <i className="fas fa-minus fa-sm" />
                                </Button>
                              )}
                            </div>
                            <Button
                              title="Remove the condition"
                              bsStyle="danger"
                              disabled={!editable}
                              bsSize="small"
                              onClick={() => {
                                this.handleRemoveParameter(parameterIndex);
                              }}
                            >
                              <i className="fas fa-trash fa-sm" />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    )
                  );
                })}
              </Table>
            </div>
          </Col>
        </Row>
        <Row className="pull-right">
          <Col>
            <Button
              bsStyle="primary"
              disabled={!editable}
              onClick={() => this.createNewParameter()}
            >
              Add a new condition
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
