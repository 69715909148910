import React from 'react';

import { GithubOverview } from './GithubOverview';

const TABS = ['overview', 'configuration'] as const;
export type Tab = typeof TABS[number];

type Props = {
  tenant: number;
  hasAccessToConfiguration: boolean;
};

export function GithubIntegration({ tenant, hasAccessToConfiguration }: Props) {
  return (
    <div className="row ml-4">
      <div className="tab-content w-100" id="TabContent">
        <div className="tab-pane fade active show" role="tabpanel">
          <div className="row justify-content-center">
            <div className="col-8 mt-4 mb-4">
              <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src="/media/integrations/logos/github-icon.svg"
                    />
                    <h5 className="mb-0 ml-3">Github</h5>
                  </div>
                </div>

                <div className="card-body">
                  <GithubOverview
                    tenant={tenant}
                    hasAccessToConfiguration={hasAccessToConfiguration}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
