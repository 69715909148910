import React, { Component } from 'react';

export default class SignalsComponent extends Component {
  render() {
    return (
      <div className="card box-shadow-3d m-4">
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th title="Field #1">Category</th>
              <th title="Field #2">Possible Values</th>
              <th title="Field #3">Preview of Signal</th>
              <th title="Field #4">Signal Type</th>
              <th title="Field #5">Description of the Signal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Job title or role</td>
              <td>founder</td>
              <td>lead is a founder</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead has a job title of a founder.</td>
            </tr>
            <tr>
              <td></td>
              <td>developer</td>
              <td>lead is a developer</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead has a job title of a developer.</td>
            </tr>
            <tr>
              <td></td>
              <td>engineer</td>
              <td>lead is a engineer</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead has a job title of a engineer.</td>
            </tr>
            <tr>
              <td></td>
              <td>ceo</td>
              <td>lead is a ceo</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job title of a ceo.</td>
            </tr>
            <tr>
              <td></td>
              <td>sales</td>
              <td>lead role is sales</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within sales.</td>
            </tr>
            <tr>
              <td></td>
              <td>operations</td>
              <td>lead role is operations</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within operations.</td>
            </tr>
            <tr>
              <td></td>
              <td>finance</td>
              <td>lead role is finance</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within finance.</td>
            </tr>
            <tr>
              <td></td>
              <td>marketing</td>
              <td>lead role is marketing</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within marketing.</td>
            </tr>
            <tr>
              <td></td>
              <td>education</td>
              <td>lead role is education</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within education.</td>
            </tr>
            <tr>
              <td></td>
              <td>engineering</td>
              <td>lead role is engineering</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within engineering.</td>
            </tr>
            <tr>
              <td></td>
              <td>product</td>
              <td>lead role is product</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within product .</td>
            </tr>
            <tr>
              <td></td>
              <td>information technology</td>
              <td>lead role is information technology</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within information technology.</td>
            </tr>
            <tr>
              <td></td>
              <td>unknown</td>
              <td>lead role is unknown</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a job role within unknown .</td>
            </tr>
            <tr>
              <td>Fortune 500/1000</td>
              <td>is a US Fortune 500</td>
              <td>company is a US Fortune 500</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in a company that is a US Fortune 500</td>
            </tr>
            <tr>
              <td></td>
              <td>is a US Fortune 1000</td>
              <td>company is a US Fortune 1000</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in a company that is a US Fortune 1000</td>
            </tr>
            <tr>
              <td>Industry</td>
              <td>Financial Services</td>
              <td>company industry is Financial Services</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Financial Services</td>
            </tr>
            <tr>
              <td></td>
              <td>Software</td>
              <td>company industry is Software</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Software</td>
            </tr>
            <tr>
              <td></td>
              <td>Edu</td>
              <td>company industry is Edu</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Edu</td>
            </tr>
            <tr>
              <td></td>
              <td>Consulting</td>
              <td>company industry is Consulting</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Consulting</td>
            </tr>
            <tr>
              <td></td>
              <td>Internet</td>
              <td>company industry is Internet</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Internet</td>
            </tr>
            <tr>
              <td></td>
              <td>Consumer Staples</td>
              <td>company industry is Consumer Staples</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Consumer Staples</td>
            </tr>
            <tr>
              <td></td>
              <td>E-commerce</td>
              <td>company industry is E-commerce</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called E-commerce</td>
            </tr>
            <tr>
              <td></td>
              <td>Telco</td>
              <td>company industry is Telco</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Telco</td>
            </tr>
            <tr>
              <td></td>
              <td>Media</td>
              <td>company industry is Media</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs in an industry called Media</td>
            </tr>
            <tr>
              <td></td>
              <td>Hotels, Restaurants &amp; Leisure</td>
              <td>company industry is Hotels, Restaurants &amp; Leisure</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs in an industry called Hotels, Restaurants &amp;
                Leisure
              </td>
            </tr>
            <tr>
              <td>Company Size</td>
              <td>small</td>
              <td>company size is small</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company with a small company size, not a
                personal email domain and raised zero funding
              </td>
            </tr>
            <tr>
              <td></td>
              <td>[number of employees - will differ based off the lead]</td>
              <td>company size is 372</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company with a company size of 372, and
                does not have a personal or education email domain
              </td>
            </tr>
            <tr>
              <td>Raised</td>
              <td>
                [amount of funding raised - will differ based off the lead]
              </td>
              <td>company raised $136000000</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that raised $136000000</td>
            </tr>
            <tr>
              <td>Market Cap</td>
              <td>
                [amount of market capitalization amount - will differ based off
                the lead]
              </td>
              <td>market capitalization is $50000000</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The leads belongs to a company with a market capitalization of
                $50000000
              </td>
            </tr>
            <tr>
              <td>Geography</td>
              <td>united states</td>
              <td>company is located in united states</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in united states</td>
            </tr>
            <tr>
              <td></td>
              <td>united kingdom</td>
              <td>company is located in united kingdom</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in united kingdom</td>
            </tr>
            <tr>
              <td></td>
              <td>germany</td>
              <td>company is located in germany</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in germany</td>
            </tr>
            <tr>
              <td></td>
              <td>canada</td>
              <td>company is located in canada</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in canada</td>
            </tr>
            <tr>
              <td></td>
              <td>australia</td>
              <td>company is located in australia</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in australia</td>
            </tr>
            <tr>
              <td></td>
              <td>sweden</td>
              <td>company is located in sweden</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in sweden</td>
            </tr>
            <tr>
              <td></td>
              <td>norway</td>
              <td>company is located in norway</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in norway</td>
            </tr>
            <tr>
              <td></td>
              <td>denmark</td>
              <td>company is located in denmark</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in denmark</td>
            </tr>
            <tr>
              <td></td>
              <td>united arab emirates</td>
              <td>company is located in united arab emirates</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in united arab emirates
              </td>
            </tr>
            <tr>
              <td></td>
              <td>switzerland</td>
              <td>company is located in switzerland</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in switzerland</td>
            </tr>
            <tr>
              <td></td>
              <td>singapore</td>
              <td>company is located in singapore</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in singapore</td>
            </tr>
            <tr>
              <td></td>
              <td>san marino</td>
              <td>company is located in san marino</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in san marino</td>
            </tr>
            <tr>
              <td></td>
              <td>qatar</td>
              <td>company is located in qatar</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in qatar</td>
            </tr>
            <tr>
              <td></td>
              <td>new zealand</td>
              <td>company is located in new zealand</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in new zealand</td>
            </tr>
            <tr>
              <td></td>
              <td>macao</td>
              <td>company is located in macao</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in macao</td>
            </tr>
            <tr>
              <td></td>
              <td>luxembourg</td>
              <td>company is located in luxembourg</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in luxembourg</td>
            </tr>
            <tr>
              <td></td>
              <td>japan</td>
              <td>company is located in japan</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in japan</td>
            </tr>
            <tr>
              <td></td>
              <td>israel</td>
              <td>company is located in israel</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in israel</td>
            </tr>
            <tr>
              <td></td>
              <td>ireland</td>
              <td>company is located in ireland</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in ireland</td>
            </tr>
            <tr>
              <td></td>
              <td>iceland</td>
              <td>company is located in iceland</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in iceland</td>
            </tr>
            <tr>
              <td></td>
              <td>hong kong</td>
              <td>company is located in hong kong</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in hong kong</td>
            </tr>
            <tr>
              <td></td>
              <td>france</td>
              <td>company is located in france</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in france</td>
            </tr>
            <tr>
              <td></td>
              <td>finland</td>
              <td>company is located in finland</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in finland</td>
            </tr>
            <tr>
              <td></td>
              <td>belgium</td>
              <td>company is located in belgium</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in belgium</td>
            </tr>
            <tr>
              <td></td>
              <td>austria</td>
              <td>company is located in austria</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in austria</td>
            </tr>
            <tr>
              <td></td>
              <td>grenada</td>
              <td>company is located in grenada</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in grenada</td>
            </tr>
            <tr>
              <td></td>
              <td>malaysia</td>
              <td>company is located in malaysia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in malaysia</td>
            </tr>
            <tr>
              <td></td>
              <td>romania</td>
              <td>company is located in romania</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in romania</td>
            </tr>
            <tr>
              <td></td>
              <td>mauritius</td>
              <td>company is located in mauritius</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in mauritius</td>
            </tr>
            <tr>
              <td></td>
              <td>turkey</td>
              <td>company is located in turkey</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in turkey</td>
            </tr>
            <tr>
              <td></td>
              <td>maldives</td>
              <td>company is located in maldives</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in maldives</td>
            </tr>
            <tr>
              <td></td>
              <td>russia</td>
              <td>company is located in russia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in russia</td>
            </tr>
            <tr>
              <td></td>
              <td>mexico</td>
              <td>company is located in mexico</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in mexico</td>
            </tr>
            <tr>
              <td></td>
              <td>brazil</td>
              <td>company is located in brazil</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in brazil</td>
            </tr>
            <tr>
              <td></td>
              <td>st. lucia</td>
              <td>company is located in st. lucia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in st. lucia</td>
            </tr>
            <tr>
              <td></td>
              <td>china</td>
              <td>company is located in china</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in china</td>
            </tr>
            <tr>
              <td></td>
              <td>gabon</td>
              <td>company is located in gabon</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in gabon</td>
            </tr>
            <tr>
              <td></td>
              <td>dominica</td>
              <td>company is located in dominica</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in dominica</td>
            </tr>
            <tr>
              <td></td>
              <td>suriname</td>
              <td>company is located in suriname</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in suriname</td>
            </tr>
            <tr>
              <td></td>
              <td>kazakhstan</td>
              <td>company is located in kazakhstan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in kazakhstan</td>
            </tr>
            <tr>
              <td></td>
              <td>bulgaria</td>
              <td>company is located in bulgaria</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in bulgaria</td>
            </tr>
            <tr>
              <td></td>
              <td>dominican republic</td>
              <td>company is located in dominican republic</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in dominican republic
              </td>
            </tr>
            <tr>
              <td></td>
              <td>st. vincent and the grenadines</td>
              <td>company is located in st. vincent and the grenadines</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in st. vincent and the
                grenadines
              </td>
            </tr>
            <tr>
              <td></td>
              <td>montenegro</td>
              <td>company is located in montenegro</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in montenegro</td>
            </tr>
            <tr>
              <td></td>
              <td>turkmenistan</td>
              <td>company is located in turkmenistan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in turkmenistan</td>
            </tr>
            <tr>
              <td></td>
              <td>libya</td>
              <td>company is located in libya</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in libya</td>
            </tr>
            <tr>
              <td></td>
              <td>ecuador</td>
              <td>company is located in ecuador</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in ecuador</td>
            </tr>
            <tr>
              <td></td>
              <td>peru</td>
              <td>company is located in peru</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in peru</td>
            </tr>
            <tr>
              <td></td>
              <td>thailand</td>
              <td>company is located in thailand</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in thailand</td>
            </tr>
            <tr>
              <td></td>
              <td>colombia</td>
              <td>company is located in colombia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in colombia</td>
            </tr>
            <tr>
              <td></td>
              <td>serbia</td>
              <td>company is located in serbia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in serbia</td>
            </tr>
            <tr>
              <td></td>
              <td>islamic republic of iran</td>
              <td>company is located in islamic republic of iran</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in islamic republic of
                iran
              </td>
            </tr>
            <tr>
              <td></td>
              <td>belarus</td>
              <td>company is located in belarus</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in belarus</td>
            </tr>
            <tr>
              <td></td>
              <td>jordan</td>
              <td>company is located in jordan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in jordan</td>
            </tr>
            <tr>
              <td></td>
              <td>fiji</td>
              <td>company is located in fiji</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in fiji</td>
            </tr>
            <tr>
              <td></td>
              <td>botswana</td>
              <td>company is located in botswana</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in botswana</td>
            </tr>
            <tr>
              <td></td>
              <td>fyr macedonia</td>
              <td>company is located in fyr macedonia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in fyr macedonia</td>
            </tr>
            <tr>
              <td></td>
              <td>jamaica</td>
              <td>company is located in jamaica</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in jamaica</td>
            </tr>
            <tr>
              <td></td>
              <td>belize</td>
              <td>company is located in belize</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in belize</td>
            </tr>
            <tr>
              <td></td>
              <td>samoa</td>
              <td>company is located in samoa</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in samoa</td>
            </tr>
            <tr>
              <td></td>
              <td>guyana</td>
              <td>company is located in guyana</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in guyana</td>
            </tr>
            <tr>
              <td></td>
              <td>namibia</td>
              <td>company is located in namibia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in namibia</td>
            </tr>
            <tr>
              <td></td>
              <td>iraq</td>
              <td>company is located in iraq</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in iraq</td>
            </tr>
            <tr>
              <td></td>
              <td>el salvador</td>
              <td>company is located in el salvador</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in el salvador</td>
            </tr>
            <tr>
              <td></td>
              <td>bosnia and herzegovina</td>
              <td>company is located in bosnia and herzegovina</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in bosnia and herzegovina
              </td>
            </tr>
            <tr>
              <td></td>
              <td>albania</td>
              <td>company is located in albania</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in albania</td>
            </tr>
            <tr>
              <td></td>
              <td>algeria</td>
              <td>company is located in algeria</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in algeria</td>
            </tr>
            <tr>
              <td></td>
              <td>tonga</td>
              <td>company is located in tonga</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in tonga</td>
            </tr>
            <tr>
              <td></td>
              <td>guatemala</td>
              <td>company is located in guatemala</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in guatemala</td>
            </tr>
            <tr>
              <td></td>
              <td>paraguay</td>
              <td>company is located in paraguay</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in paraguay</td>
            </tr>
            <tr>
              <td></td>
              <td>georgia</td>
              <td>company is located in georgia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in georgia</td>
            </tr>
            <tr>
              <td></td>
              <td>sri lanka</td>
              <td>company is located in sri lanka</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in sri lanka</td>
            </tr>
            <tr>
              <td></td>
              <td>egypt</td>
              <td>company is located in egypt</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in egypt</td>
            </tr>
            <tr>
              <td></td>
              <td>tunisia</td>
              <td>company is located in tunisia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in tunisia</td>
            </tr>
            <tr>
              <td></td>
              <td>azerbaijan</td>
              <td>company is located in azerbaijan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in azerbaijan</td>
            </tr>
            <tr>
              <td></td>
              <td>mongolia</td>
              <td>company is located in mongolia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in mongolia</td>
            </tr>
            <tr>
              <td></td>
              <td>indonesia</td>
              <td>company is located in indonesia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in indonesia</td>
            </tr>
            <tr>
              <td></td>
              <td>armenia</td>
              <td>company is located in armenia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in armenia</td>
            </tr>
            <tr>
              <td></td>
              <td>marshall islands</td>
              <td>company is located in marshall islands</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in marshall islands</td>
            </tr>
            <tr>
              <td></td>
              <td>angola</td>
              <td>company is located in angola</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in angola</td>
            </tr>
            <tr>
              <td></td>
              <td>bolivia</td>
              <td>company is located in bolivia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in bolivia</td>
            </tr>
            <tr>
              <td></td>
              <td>cabo verde</td>
              <td>company is located in cabo verde</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in cabo verde</td>
            </tr>
            <tr>
              <td></td>
              <td>micronesia</td>
              <td>company is located in micronesia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in micronesia</td>
            </tr>
            <tr>
              <td></td>
              <td>morocco</td>
              <td>company is located in morocco</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in morocco</td>
            </tr>
            <tr>
              <td></td>
              <td>swaziland</td>
              <td>company is located in swaziland</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in swaziland</td>
            </tr>
            <tr>
              <td></td>
              <td>philippines</td>
              <td>company is located in philippines</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in philippines</td>
            </tr>
            <tr>
              <td></td>
              <td>tuvalu</td>
              <td>company is located in tuvalu</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in tuvalu</td>
            </tr>
            <tr>
              <td></td>
              <td>vanuatu</td>
              <td>company is located in vanuatu</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in vanuatu</td>
            </tr>
            <tr>
              <td></td>
              <td>bhutan</td>
              <td>company is located in bhutan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in bhutan</td>
            </tr>
            <tr>
              <td></td>
              <td>honduras</td>
              <td>company is located in honduras</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in honduras</td>
            </tr>
            <tr>
              <td></td>
              <td>papua</td>
              <td>company is located in papua</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in papua</td>
            </tr>
            <tr>
              <td></td>
              <td>new</td>
              <td>company is located in new</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in new</td>
            </tr>
            <tr>
              <td></td>
              <td>guinea</td>
              <td>company is located in guinea</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in guinea</td>
            </tr>
            <tr>
              <td></td>
              <td>sudan</td>
              <td>company is located in sudan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in sudan</td>
            </tr>
            <tr>
              <td></td>
              <td>nigeria</td>
              <td>company is located in nigeria</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in nigeria</td>
            </tr>
            <tr>
              <td></td>
              <td>vietnam</td>
              <td>company is located in vietnam</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in vietnam</td>
            </tr>
            <tr>
              <td></td>
              <td>uzbekistan</td>
              <td>company is located in uzbekistan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in uzbekistan</td>
            </tr>
            <tr>
              <td></td>
              <td>nicaragua</td>
              <td>company is located in nicaragua</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in nicaragua</td>
            </tr>
            <tr>
              <td></td>
              <td>timor-leste</td>
              <td>company is located in timor-leste</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in timor-leste</td>
            </tr>
            <tr>
              <td></td>
              <td>ukraine</td>
              <td>company is located in ukraine</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in ukraine</td>
            </tr>
            <tr>
              <td></td>
              <td>solomon islands</td>
              <td>company is located in solomon islands</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in solomon islands</td>
            </tr>
            <tr>
              <td></td>
              <td>republic of congo</td>
              <td>company is located in republic of congo</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in republic of congo
              </td>
            </tr>
            <tr>
              <td></td>
              <td>lao p.d.r.</td>
              <td>company is located in lao p.d.r.</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in lao p.d.r.</td>
            </tr>
            <tr>
              <td></td>
              <td>djibouti</td>
              <td>company is located in djibouti</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in djibouti</td>
            </tr>
            <tr>
              <td></td>
              <td>moldova</td>
              <td>company is located in moldova</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in moldova</td>
            </tr>
            <tr>
              <td></td>
              <td>india</td>
              <td>company is located in india</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in india</td>
            </tr>
            <tr>
              <td></td>
              <td>sao tome and prC-ncipe</td>
              <td>company is located in sao tome and prC-ncipe</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in sao tome and prC-ncipe
              </td>
            </tr>
            <tr>
              <td></td>
              <td>ghana</td>
              <td>company is located in ghana</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in ghana</td>
            </tr>
            <tr>
              <td></td>
              <td>kenya</td>
              <td>company is located in kenya</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in kenya</td>
            </tr>
            <tr>
              <td></td>
              <td>pakistan</td>
              <td>company is located in pakistan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in pakistan</td>
            </tr>
            <tr>
              <td></td>
              <td>kiribati</td>
              <td>company is located in kiribati</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in kiribati</td>
            </tr>
            <tr>
              <td></td>
              <td>cote d&#39;&#39;ivoire</td>
              <td>company is located in cote d&#39;&#39;ivoire</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in cote d&#39;&#39;ivoire
              </td>
            </tr>
            <tr>
              <td></td>
              <td>bangladesh</td>
              <td>company is located in bangladesh</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in bangladesh</td>
            </tr>
            <tr>
              <td></td>
              <td>myanmar</td>
              <td>company is located in myanmar</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in myanmar</td>
            </tr>
            <tr>
              <td></td>
              <td>cameroon</td>
              <td>company is located in cameroon</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in cameroon</td>
            </tr>
            <tr>
              <td></td>
              <td>mauritania</td>
              <td>company is located in mauritania</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in mauritania</td>
            </tr>
            <tr>
              <td></td>
              <td>zambia</td>
              <td>company is located in zambia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in zambia</td>
            </tr>
            <tr>
              <td></td>
              <td>cambodia</td>
              <td>company is located in cambodia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in cambodia</td>
            </tr>
            <tr>
              <td></td>
              <td>yemen</td>
              <td>company is located in yemen</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in yemen</td>
            </tr>
            <tr>
              <td></td>
              <td>zimbabwe</td>
              <td>company is located in zimbabwe</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in zimbabwe</td>
            </tr>
            <tr>
              <td></td>
              <td>senegal</td>
              <td>company is located in senegal</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in senegal</td>
            </tr>
            <tr>
              <td></td>
              <td>tanzania</td>
              <td>company is located in tanzania</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in tanzania</td>
            </tr>
            <tr>
              <td></td>
              <td>kyrgyz republic</td>
              <td>company is located in kyrgyz republic</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in kyrgyz republic</td>
            </tr>
            <tr>
              <td></td>
              <td>lesotho</td>
              <td>company is located in lesotho</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in lesotho</td>
            </tr>
            <tr>
              <td></td>
              <td>chad</td>
              <td>company is located in chad</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in chad</td>
            </tr>
            <tr>
              <td></td>
              <td>mali</td>
              <td>company is located in mali</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in mali</td>
            </tr>
            <tr>
              <td></td>
              <td>benin</td>
              <td>company is located in benin</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in benin</td>
            </tr>
            <tr>
              <td></td>
              <td>eritrea</td>
              <td>company is located in eritrea</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in eritrea</td>
            </tr>
            <tr>
              <td></td>
              <td>tajikistan</td>
              <td>company is located in tajikistan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in tajikistan</td>
            </tr>
            <tr>
              <td></td>
              <td>haiti</td>
              <td>company is located in haiti</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in haiti</td>
            </tr>
            <tr>
              <td></td>
              <td>ethiopia</td>
              <td>company is located in ethiopia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in ethiopia</td>
            </tr>
            <tr>
              <td></td>
              <td>comoros</td>
              <td>company is located in comoros</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in comoros</td>
            </tr>
            <tr>
              <td></td>
              <td>nepal</td>
              <td>company is located in nepal</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in nepal</td>
            </tr>
            <tr>
              <td></td>
              <td>rwanda</td>
              <td>company is located in rwanda</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in rwanda</td>
            </tr>
            <tr>
              <td></td>
              <td>sierra leone</td>
              <td>company is located in sierra leone</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in sierra leone</td>
            </tr>
            <tr>
              <td></td>
              <td>burkina faso</td>
              <td>company is located in burkina faso</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in burkina faso</td>
            </tr>
            <tr>
              <td></td>
              <td>guinea-bissau</td>
              <td>company is located in guinea-bissau</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in guinea-bissau</td>
            </tr>
            <tr>
              <td></td>
              <td>uganda</td>
              <td>company is located in uganda</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in uganda</td>
            </tr>
            <tr>
              <td></td>
              <td>togo</td>
              <td>company is located in togo</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in togo</td>
            </tr>
            <tr>
              <td></td>
              <td>afghanistan</td>
              <td>company is located in afghanistan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in afghanistan</td>
            </tr>
            <tr>
              <td></td>
              <td>guinea</td>
              <td>company is located in guinea</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in guinea</td>
            </tr>
            <tr>
              <td></td>
              <td>liberia</td>
              <td>company is located in liberia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in liberia</td>
            </tr>
            <tr>
              <td></td>
              <td>democratic republic of the congo</td>
              <td>company is located in democratic republic of the congo</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in democratic republic of
                the congo
              </td>
            </tr>
            <tr>
              <td></td>
              <td>the gambia</td>
              <td>company is located in the gambia</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in the gambia</td>
            </tr>
            <tr>
              <td></td>
              <td>mozambique</td>
              <td>company is located in mozambique</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in mozambique</td>
            </tr>
            <tr>
              <td></td>
              <td>niger</td>
              <td>company is located in niger</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in niger</td>
            </tr>
            <tr>
              <td></td>
              <td>madagascar</td>
              <td>company is located in madagascar</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in madagascar</td>
            </tr>
            <tr>
              <td></td>
              <td>central african republic</td>
              <td>company is located in central african republic</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead belongs to a company that is in central african
                republic
              </td>
            </tr>
            <tr>
              <td></td>
              <td>malawi</td>
              <td>company is located in malawi</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in malawi</td>
            </tr>
            <tr>
              <td></td>
              <td>burundi</td>
              <td>company is located in burundi</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in burundi</td>
            </tr>
            <tr>
              <td></td>
              <td>south sudan</td>
              <td>company is located in south sudan</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead belongs to a company that is in south sudan</td>
            </tr>
            <tr>
              <td>Predicted Traffic</td>
              <td>very small</td>
              <td>website traffic is very small</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead has a website traffic in the range of 0 to 300</td>
            </tr>
            <tr>
              <td></td>
              <td>small</td>
              <td>website traffic is small</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead has a website traffic in the range of 301 to 3,000
              </td>
            </tr>
            <tr>
              <td></td>
              <td>medium</td>
              <td>website traffic is medium</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead has a website traffic in the range of 3,001 to 10,000
              </td>
            </tr>
            <tr>
              <td></td>
              <td>medium large</td>
              <td>website traffic is medium large</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead has a website traffic in the range of 10,001 to 100,000
              </td>
            </tr>
            <tr>
              <td></td>
              <td>large</td>
              <td>website traffic is large</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead has a website traffic in the range of 100,001 to
                1,000,000
              </td>
            </tr>
            <tr>
              <td></td>
              <td>very large</td>
              <td>website traffic is very large</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead has a website traffic of more than 1,000,000</td>
            </tr>
            <tr>
              <td>Company Enviroment</td>
              <td>crypto</td>
              <td>company is related to crypto</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead&#39;s company description or Twitter bio contains
                &quot;blockchain&quot;, &quot;crypto&quot; or
                &quot;bitcoin&quot;
              </td>
            </tr>
            <tr>
              <td></td>
              <td>highly regulated industry</td>
              <td>company is in a highly regulated industry</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead&#39;s name contains &quot;bank&quot; or industry is
                bank, insurance, finance, health, or company tag is legal,
                health, finance, insurance, investment, pharma, biotech, or
                company description or twitter bio includes &quot;fintech&quot;
                or &quot;trading&quot;
              </td>
            </tr>
            <tr>
              <td></td>
              <td>gaming</td>
              <td>company is related to gaming</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead&#39;s name or tag contains &quot;game&quot; or company
                description or Twitter bio includes &quot;gaming&quot;,
                &quot;video game&quot;, &quot;game studio&quot;, &quot;virtual
                reality&quot;, &quot;multiplayer&quot;
              </td>
            </tr>
            <tr>
              <td>Location in Bay Area</td>
              <td>in bay area</td>
              <td>company is located in bay area</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company is located in the Bay Area</td>
            </tr>
            <tr>
              <td>Tech</td>
              <td>microsoft</td>
              <td>company is a microsoft shop</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead&#39;s company uses technologies that include Microsoft
                or Outlook
              </td>
            </tr>
            <tr>
              <td></td>
              <td>apache</td>
              <td>company uses apache</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead&#39;s company uses technologies that include Apache
              </td>
            </tr>
            <tr>
              <td></td>
              <td>wordpress</td>
              <td>company uses wordpress</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>
                The lead&#39;s company uses technologies that include Wordpress
              </td>
            </tr>
            <tr>
              <td>Tech Centers</td>
              <td>in tech area</td>
              <td>lead is based in tech area</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead is located in California or New York</td>
            </tr>
            <tr>
              <td>Personal Email</td>
              <td>personal developer email</td>
              <td>lead is using personal developer email</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s email is a personal developer email</td>
            </tr>
            <tr>
              <td></td>
              <td>personal email</td>
              <td>lead is using personal email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead&#39;s email is a personal email</td>
            </tr>
            <tr>
              <td></td>
              <td>corporate email</td>
              <td>lead is using corporate email</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>
                The lead&#39;s email is not a personal email and company has
                more than 0 employees
              </td>
            </tr>
            <tr>
              <td>Tags</td>
              <td>B2B SaaS</td>
              <td>company is B2B SaaS</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of B2B SaaS</td>
            </tr>
            <tr>
              <td></td>
              <td>B2B Ent</td>
              <td>company is B2B Ent</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of B2B Ent</td>
            </tr>
            <tr>
              <td></td>
              <td>enterprise</td>
              <td>company serves enterprise</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of enterprise</td>
            </tr>
            <tr>
              <td></td>
              <td>B2B</td>
              <td>company is B2B</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of B2B</td>
            </tr>
            <tr>
              <td></td>
              <td>SaaS</td>
              <td>company is SaaS</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of SaaS</td>
            </tr>
            <tr>
              <td></td>
              <td>Tech</td>
              <td>company is Tech</td>
              <td className="badge badge-success">
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of Tech</td>
            </tr>
            <tr>
              <td></td>
              <td>B2C</td>
              <td>company is B2C</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead&#39;s company has a tag of B2C</td>
            </tr>
            <tr>
              <td>Social</td>
              <td>[Github follower count]</td>
              <td>Lead Github follower count is 128</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The Lead Github follower count is 128</td>
            </tr>
            <tr>
              <td></td>
              <td>[Github following count]</td>
              <td>Lead Github following count is 216</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The Lead Github following count is 216</td>
            </tr>
            <tr>
              <td></td>
              <td>[Twitter follower count]</td>
              <td>Lead Twitter follower count is 128</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The Lead Twitter follower count is 128</td>
            </tr>
            <tr>
              <td></td>
              <td>[Twitter following count]</td>
              <td>Lead Twitter following count is 216</td>
              <td>
                <h5>
                  <span className="badge badge-success">Positive</span>
                </h5>
              </td>
              <td>The Lead Twitter following count is 216</td>
            </tr>
            <tr>
              <td>Domain</td>
              <td>AOL email</td>
              <td>Lead is using AOL email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The Lead is using AOL email</td>
            </tr>
            <tr>
              <td></td>
              <td>hotmail email</td>
              <td>Lead is using hotmail email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The Lead is using hotmail email</td>
            </tr>
            <tr>
              <td></td>
              <td>yahoo emial</td>
              <td>Lead is using yahoo emial</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The Lead is using yahoo emial</td>
            </tr>
            <tr>
              <td></td>
              <td>Gmail email</td>
              <td>Lead is using Gmail email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The Lead is using Gmail email</td>
            </tr>
            <tr>
              <td></td>
              <td>microsoft email</td>
              <td>Lead is using microsoft email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The Lead is using microsoft email</td>
            </tr>
            <tr>
              <td></td>
              <td>QQ email</td>
              <td>Lead is using QQ email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The Lead is using QQ email</td>
            </tr>
            <tr>
              <td>Spam</td>
              <td>is spam</td>
              <td>Lead is using spammy email</td>
              <td>
                <h5>
                  <span className="badge badge-danger">Negative</span>
                </h5>
              </td>
              <td>The lead is using personal and spam email</td>
            </tr>
          </tbody>
        </table>
      </div>
      // I can't believe you made it this far. And yes I hard coded this. jk i used a csv to html table converter ;-))
    );
  }
}
