import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input } from '../../../components/ui';
import { Loading } from '../Loading';
import { useChangeSlackToken } from '../hooks/useChangeSlackToken';
import { slackCredentialsSchema } from '../types';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  tenant: number;
};

export function ConnectionView({ tenant }: Props) {
  const { reset: resetForm, control, handleSubmit, formState } = useForm({
    reValidateMode: 'onChange',
    resolver: zodResolver(slackCredentialsSchema),
    defaultValues: {
      botToken: '',
    },
  });

  const { enableSocialSignals } = useFlags();

  const { mutate, isLoading: isConnecting, isError } = useChangeSlackToken(
    tenant,
    {
      onSuccess: () => resetForm(),
    }
  );

  if (isConnecting)
    return (
      <div className="d-flex justify-content-center py-3">
        <Loading loadingLabel="Connecting to Slack" />
      </div>
    );

  const { botToken: botTokenKeyErrors } = formState.errors;

  return (
    <Form
      className="tw-flex tw-flex-col tw-items-start tw-gap-4"
      onSubmit={handleSubmit((values) => {
        mutate(values);
      })}
      disabled={isConnecting || !enableSocialSignals}
    >
      <div className="tw-w-full">
        <span>
          <b>OAuth token</b>
        </span>
        <Controller
          name="botToken"
          control={control}
          render={({ field }) => (
            <Input
              className={
                (isError || botTokenKeyErrors) &&
                'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500'
              }
              placeholder="Enter your OAuth Token"
              disabled={isConnecting || !enableSocialSignals}
              type="text"
              {...field}
            />
          )}
        />
        {botTokenKeyErrors?.message && (
          <div className="tw-text-red-500 tw-mt-1">
            {botTokenKeyErrors?.message}
          </div>
        )}
      </div>

      {isError && (
        <p className="tw-text-red-500 tw-mt-2">
          The credentials you entered are invalid. Please try again.
        </p>
      )}

      <Button
        type="submit"
        className="tw-mt-5"
        disabled={isConnecting || !enableSocialSignals}
      >
        Save token
      </Button>
    </Form>
  );
}
