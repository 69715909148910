import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import app from '../../app';
import ClearBitRevealDisplayModeComponent from './components/ClearBitRevealDisplayModeComponent';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  async initialize(options: { tenant: number; context: string }) {
    this.tenant = options.tenant;
    this.context = options.context;
    this.superKudu = app.session.get('user').superKudu;

    await this.sleep(0); // Force to sleep until the page is created with the html tags

    this.render();
    this.renderClearBitRevealParentComponent();
  },

  renderClearBitRevealParentComponent() {
    ReactDOM.render(
      <ClearBitRevealDisplayModeComponent />,
      $('#ClearBitRevealParentComponentContainer')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
