import React, { FormEventHandler, useRef, useState } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { createPortal } from 'react-dom';

type MkEventPopoverProps = {
  connector: string;
  style: React.CSSProperties;
};

const MkEventPopover = ({ connector, style }: MkEventPopoverProps) => {
  const isSalesforceCampaigns = connector === 'salesforce_campaigns';
  const isSalesforceTasks = connector === 'salesforce_tasks';
  const isSegment = connector === 'segment';
  const isAmplitude = connector === 'amplitude';
  const isMarketo = connector === 'marketo';
  const isHubspot = connector === 'hubspot';

  return (
    <div className="popover-main" style={style}>
      <div className="popover-header">
        <strong>Tip:</strong>
        <span className="ml-1">
          Automatically map multiple events using{' '}
          {!isSalesforceCampaigns && (
            <code className="text-error-500">{'{{field}}'}</code>
          )}
          {isSalesforceCampaigns && (
            <>
              <code className="text-error-500">{'{{c.field}}'}</code> or{' '}
              <code className="text-error-500">{'{{ce.field}}'}</code>
            </>
          )}{' '}
          among available fields.
        </span>
      </div>
      <div className="popover-body">
        <strong>Example(s):</strong>
        <ul className="match-preview">
          {(isSegment || isAmplitude) && (
            <>
              <li>
                "<code className="text-error-500">{'{{event}}'}</code>
                ": maps the event without transformation replace
              </li>
              <li>
                "Viewed Blog{' '}
                <code className="text-error-500">{'{{title}}'}</code>
                ": extracts the page title
              </li>
              <li>
                "Submitted form{' '}
                <code className="text-error-500">{'{{path}}'}</code> : extracts
                the page path
              </li>
            </>
          )}
          {isMarketo && (
            <>
              <li>
                "Viewed Blog{' '}
                <code className="text-error-500">
                  {'{{primaryattributevalue}}'}
                </code>
                ": extracts the web page URL
              </li>
              <li>
                "Submitted form{' '}
                <code className="text-error-500">
                  {'{{primaryattributevalue}}'}
                </code>{' '}
                : extracts the form title
              </li>
            </>
          )}
          {isHubspot && (
            <li>
              "Submitted form{' '}
              <code className="text-error-500">{'{{title}}'}</code> : extracts
              the form title
            </li>
          )}
          {isSalesforceCampaigns && (
            <>
              <li>
                "Attended Webinar{' '}
                <code className="text-error-500">{'{{c.name}}'}</code>
                ": extracts the campaign name
              </li>
              <li>
                "
                <code className="text-error-500">
                  {'{{ce.status}} {{c.name}}'}
                </code>
                ": maps the Campaign member status and Campaign name
              </li>
            </>
          )}
          {isSalesforceTasks && (
            <li>
              "Replied to Sales email{' '}
              <code className="text-error-500">{'{{subject}}'}</code>" extracts
              the email subject
            </li>
          )}
          {!isSalesforceCampaigns &&
            !isSalesforceTasks &&
            !isHubspot &&
            !isMarketo &&
            !isAmplitude && (
              <>
                <li>
                  "Viewed Blog{' '}
                  <code className="text-error-500">{'{{title}}'}</code>" : will
                  extracts the page title
                </li>
                <li>
                  "Visited page{' '}
                  <code className="text-error-500">{'{{path}}'}</code>
                  ": extracts the page path
                </li>
              </>
            )}
        </ul>
      </div>
    </div>
  );
};

type EventMappingMkEventFieldProps = {
  connector: string;
  onBlur: FormEventHandler<FormControl>;
  onChange: FormEventHandler<FormControl>;
  value: string;
};

export const EventMappingMkEventField = ({
  connector,
  onBlur,
  onChange,
  value,
}: EventMappingMkEventFieldProps) => {
  const ref = useRef<HTMLDivElement>();
  const rawPosition = ref.current?.getBoundingClientRect();
  const [isFocused, setFocused] = useState(false);
  const parentEl =
    document.getElementById('event-mapping-root') || document.body;
  const parentRect = parentEl.getBoundingClientRect();
  const position = {
    x: (rawPosition?.x || 0) - parentRect.x + 10,
    y: (rawPosition?.bottom || 0) - parentRect.y + 10,
  };
  return (
    <div ref={ref}>
      <FormGroup style={{ marginBottom: 0 }} className="popover-wrapper">
        <FormControl
          type="text"
          value={value}
          onChange={onChange}
          onBlur={(event) => {
            onBlur(event);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
        />
        {isFocused &&
          createPortal(
            <MkEventPopover
              connector={connector}
              style={{
                background: '#fff',
                position: 'absolute',
                animation: 'fadeIn 0.5s ease',
                top: position.y,
                left: position.x,
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                maxWidth: '400px',
                minWidth: '400px',
                zIndex: 1000,
              }}
            />,
            parentEl
          )}
      </FormGroup>
    </div>
  );
};
