import Backbone from 'backbone';

const template = require('./index.pug');

const TARGETS: any = {
  tenant_create: 'tenant/create',
  tenant_list: 'tenant/list',
  model_list: 'model/list',
};

export default Backbone.View.extend({
  events: {
    'click a': 'navigate',
  },

  initialize() {
    this.render();
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = template({});
    this.$el.html(html);
  },

  navigate(e: any): any {
    e.preventDefault();

    const target = TARGETS[e.currentTarget.id];
    if (!target) return null;

    Backbone.history.navigate(`/admin/${target}`, true);
  },
});
