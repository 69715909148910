import axios from 'axios';
import React from 'react';
import { ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import { beautifyWhitespace, VERBS_AND_SQL } from '../../mapping/utils';

export const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-info',
  },
  buttonsStyling: false,
});

export async function getConfig(tenant: number, integration: string) {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/enrichment/${integration}`
    );
    // return default data in catch
    if (!data) throw new Error();
    return data.config;
  } catch (_e) {
    return { conditions: [] };
  }
}

export async function setConfig(
  tenant: number,
  integration: string,
  config: {}
) {
  return axios.put(
    `${BONGO_URL}/v1/org/${tenant}/enrichment/${integration}`,
    config
  );
}

export async function getSamples(tenant: number, integration: string) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/enrichment/${integration}`
  );
  if (!data || !data.sample) return null;
  return data;
}

async function removeSample(tenant: number, integration: string) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/enrichment/${integration}`
  );
  data.sample = null;

  return axios.put(
    `${BONGO_URL}/v1/org/${tenant}/enrichment/${integration}/sample`,
    {
      item: data,
    }
  );
}

function renderConditionReadable(condition: UnknownObject) {
  const { trait, values, verb } = condition;
  return (
    <p>
      <i>{trait}</i> {verb} "{values}"
    </p>
  );
}

function SampleOfLeads(props: {
  leads: any[];
  conditions: any[];
  filters: any[];
}) {
  return (
    <div style={{ overflowX: 'scroll' }}>
      <div className="text-left mt-3 mb-5">
        <div className="mb-2">
          <h3>Keyword conditions:</h3>
          <ListGroup>
            {props.conditions.map((condition, i) => {
              return (
                <ListGroupItem key={`${i}_condition`}>
                  {
                    <div>
                      <span>{renderConditionReadable(condition)}</span>
                      {condition.subConditions &&
                        condition.subConditions.length &&
                        condition.subConditions.map(
                          (subCondition: UnknownObject) => (
                            <ListGroupItem key={`${i}_subCondition`}>
                              <b>AND</b> {renderConditionReadable(subCondition)}
                            </ListGroupItem>
                          )
                        )}
                    </div>
                  }
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
        <div>
          <h3>Job type filters:</h3>
          <ListGroup>
            {props.filters.map((filter, i) => {
              return (
                <ListGroupItem key={`${i}_${filter}`}>
                  {renderConditionReadable(filter)}
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>
      </div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Domain</th>
            <th>Title</th>
            <th>Location</th>
            <th>URL</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {props.leads.map((lead, index) => {
            const { description, domain, location, title, url } = lead;
            return (
              <tr key={`lead_${index}_row`}>
                <td key={`lead_${index}_domain`}>{domain}</td>
                <td key={`lead_${index}_title`}>{title}</td>
                <td key={`lead_${index}_location`}>{location}</td>
                <td key={`lead_${index}_url`}>
                  <a href={url}>Job opening url</a>
                </td>
                <td key={`lead_${index}_description`}>{description}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

async function showSampleLoadingModal() {
  await swalWithBootstrapButtons.fire({
    title: 'Getting sample...',
    text:
      'Please allow 5-10 minutes for this process. You will receive an email once your sample is ready',
    width: 600,
    showConfirmButton: false,
  });
}

export async function showSampleModal(sampleData: {
  sample: any[];
  sampleConditions: any[];
  sampleFilters: any[];
}) {
  const { sample, sampleConditions, sampleFilters } = sampleData;

  await swalWithBootstrapButtons.fire({
    title: 'Sample of leads',
    width: 1000,
    willOpen: (domElement: any) => {
      ReactDOM.render(
        <SampleOfLeads
          leads={sample}
          conditions={sampleConditions}
          filters={sampleFilters}
        />,
        domElement.children[1]
      );
    },
  });
}

export function showSavingModal() {
  return swalWithBootstrapButtons.fire({
    title: 'Saving...',
    text: 'Please wait a moment while your preferences get saved.',
    width: 600,
    showConfirmButton: false,
  });
}

export function showSampleErrorModal() {
  return swalWithBootstrapButtons.fire({
    title: 'Error',
    text:
      'An error has occurred while attempting to retrieve a sample of leads.',
    width: 600,
  });
}

function mapConditionToSql(condition: UnknownObject) {
  if (!condition) return '';
  const { trait, verb } = condition;

  const values = condition.values.map((v: string) => v.toLowerCase());
  const value1 = values[0];
  const value2 = values[1];
  const allValues = `'${values.join("', '")}'`;

  return VERBS_AND_SQL[verb]
    .replace('{{value}}', value1)
    .replace('{{value2}}', value2)
    .replace('{{subject}}', `LOWER(${trait})`)
    .replace('{{allValues}}', allValues);
}

export function mapFiltersToSql(filters: any[], filtersLogic: string) {
  if (!filters.length || !filtersLogic) return '';

  const sqlFilters = filters.map((c) => mapConditionToSql(c));
  let sql = beautifyWhitespace(filtersLogic);

  sqlFilters.forEach((filter, index) => {
    sql = sql.replace(`$${index + 1}`, filter);
  });

  return `AND ${sql}`;
}

export function mapConfigConditionsToSql(conditions: any[]) {
  const sqlConditions = conditions.map((c) => mapConditionToSql(c));
  const sql = sqlConditions.reduce((acc, condition, index) => {
    let sqlLine = condition;
    const fullCondition = conditions[index];
    if (
      fullCondition &&
      fullCondition.subConditions &&
      fullCondition.subConditions.length
    ) {
      const subs = fullCondition.subConditions.reduce(
        (subAcc: string, subC: any) => {
          return `${subAcc} AND ${mapConditionToSql(subC)}`;
        },
        ''
      );
      sqlLine = `${sqlLine} ${subs}`;
    }
    return `${acc}\n        ${index !== 0 ? 'WHEN' : ''} ${sqlLine} THEN 1`;
  }, '');

  if (!sql.trim()) return '';
  return `${sql}`;
}

export function concatenateConditions(
  month: number,
  year: number,
  filtersSql: string
) {
  return `
      DATE_PART(YEAR, last_seen) >= ${year}
      AND DATE_PART(MONTH, last_seen) >= ${month}
      ${filtersSql}
  `;
}

export async function handleRequestSample(
  tenant: number,
  integration: string,
  config: UnknownObject,
  email: string,
  sampleExists: boolean
) {
  // eslint-disable-next-line
  showSampleLoadingModal();

  try {
    if (sampleExists) await removeSample(tenant, integration);
    await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/enrichment/${integration}/sample?email=${email}`,
      config
    );
  } catch (_err) {
    await showSampleErrorModal();
  }
}
