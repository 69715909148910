import axios from 'axios';
import Backbone from 'backbone';
import codemirror from 'codemirror';
import forEach from 'lodash/forEach';
import Swal from 'sweetalert2';
import app from '../app';
import { CampaignCollection, CampaignModel } from '../models/campaign';

const $ = require('jquery');

require('bootstrap-toggle');
require('codemirror/mode/javascript/javascript');
const template_one = require('./campaign.pug');
const template_all = require('./campaigns.pug');

const CampaignDetailsView = (Backbone as any).Epoxy.View.extend({
  el: '#campaign_container',
  bindings: 'data-bind',
  events: {
    'click .mki_back': 'back',
    'click .mki_save': 'save',
    'click .mki_publish': 'publish',
    'click .mki_preview': 'preview',
    'click .mki_edit_name': 'handle_campaign_name_update',
    'click .mki_show_advanced_settings': 'show_advanced_settings',
    'click .mki_hide_advanced_settings': 'hide_advanced_settings',
  },

  initialize(options: { tenant: number; parent_view: string }) {
    this.tenant = options.tenant;
    this.parent_view = options.parent_view;
    this.campaign_id = this.model.get('_id');
    window.history.pushState(
      `campaigns/${this.campaign_id}`,
      'Campaign',
      `/org/${this.tenant}/campaigns/${this.campaign_id}`
    );
    this.$el.html('');
    this.cm_array = [];
    this.render();
  },

  show_advanced_settings() {
    $('.advanced', this.$el).removeClass('d-none');
    $('.mki_show_advanced_settings', this.$el).addClass('d-none');
    // refresh each codemirror box
    this.cm_array.forEach((cm: any) => {
      cm.refresh();
    });
    app.notifications.success('Advanced mode activated.', {
      dismiss_timeout: 2000,
    });
  },

  hide_advanced_settings() {
    $('.advanced', this.$el).addClass('d-none');
    $('.mki_show_advanced_settings', this.$el).removeClass('d-none');
    // refresh each codemirror box
    this.cm_array.forEach((cm: any) => {
      cm.refresh();
    });

    app.notifications.success('Simple mode activated.', {
      dismiss_timeout: 2000,
    });
  },

  format_text_areas() {
    const areas = ['trigger', 'on_load', 'on_qualified', 'on_not_qualified']; // 'custom_css' removed on 20180115
    areas.forEach((type) => {
      /* @todo: make the code box a view */
      const conf = {
        theme: 'solarized dark',
        mode: 'javascript',
        lineNumbers: true,
      };
      let attribute_name = `variation_0_${type}_js`;
      if (type === 'trigger') {
        attribute_name = 'trigger_js';
      } else if (type === 'custom_css') {
        attribute_name = 'custom_css';
        conf.mode = 'css';
      }
      const cm = codemirror.fromTextArea(
        ($(`#codemirror_${type}`, this.$el) as any).get(0),
        conf
      );
      // set size
      cm.setSize('100%', '100%');
      // set value
      cm.setValue(this.model.get(attribute_name));
      // listen to changes to update the model
      cm.on('changes', (instance) => {
        this.model.set(attribute_name, instance.getValue());
      });
      // add each codemirror to the array for refresh after hide and show
      this.cm_array.push(cm);
    });
  },

  render() {
    const html = $('#tpl_campaign_details', template_one()).html();
    this.$el.html(html);
    this.format_text_areas();
    this.applyBindings();
    $('.mki_toggle', this.$el).bootstrapToggle();
    // activate bootstrap dismissible alert
    $(document).alert();
    if ($('#first_url').val() === '') {
      $('#first_url').val(this.model.get('website'));
    }
    return this;
  },

  back(e: any) {
    if (e) {
      e.preventDefault();
    }
    this.undelegateEvents();
    this.parent_view.campaign_id = null;
    Backbone.history.navigate(`/org/${this.tenant}/campaigns`, true);
  },

  save(event: string) {
    this.model.save(null, {
      error: (_model: string, response: { responseText: string }) => {
        let error: { message?: string } = {};
        try {
          error = JSON.parse(response.responseText);
        } catch (e) {
          return;
        }
        return Swal.fire({
          title: 'Oops!',
          icon: 'error',
          text: `There was an error ${error.message}`,
        });
      },
      success: () => {
        this.model.initComputeds();
        if (event) {
          // only display the notification if called via the button
          app.notifications.success('Configuration saved', {
            dismiss_timeout: 2000,
          });
        }
      },
    });
  },

  publish() {
    this.model.publish(null, {
      error(_model: string, response: { responseText: string }) {
        let error: { message?: string } = {};
        try {
          error = JSON.parse(response.responseText);
        } catch (e) {
          return;
        }
        return Swal.fire({
          title: 'Oops!',
          icon: 'error',
          text: `There was an error ${error.message}`,
        });
      },
      success() {
        app.notifications.success(
          'Your changes are being published and will be viewable by your visitors within 5-10 minutes.',
          { dismiss_timeout: 10000 }
        );
      },
    });
  },

  handle_campaign_name_update() {
    $('.campaign__name')[0].focus();
  },

  open_website() {
    let url = $('#first_url').val() as string;
    const regex_prefix = new RegExp('^https?://');
    const regex_param = new RegExp('\\?');
    const regex_valid_url = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
    ); // eslint-disable-line

    if (!regex_prefix.exec(url)) {
      url = `http://${url}`;
    }
    if (regex_valid_url.exec(url)) {
      // eslint-disable-next-line
      axios.get(`${BONGO_URL}/v1/org/${this.tenant}`).then((res) => {
        window.open(
          `${url + (regex_param.exec(url) ? '&' : '?')}mk_ext=${
            res.data.api_key
          }`
        );
      });
    } else {
      app.notifications.danger('Invalid URL', { dismiss_timeout: 5000 });
    }
  },

  preview() {
    const redirect_to_chrome_store = function () {
      Swal.fire({
        title: 'Preview requires the MadKudu Chrome extension',
        text:
          'This extension will let you see how your form behaves before deploying to your customers.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Install the extension',
      }).then(() => {
        // triggers install of MadKudu's extension
        window.open(
          'https://chrome.google.com/webstore/detail/ldjmmabjdnpaldpmcpgffhkaeidoknha'
        );
      });
    };

    const require_chrome = function () {
      Swal.fire({
        title: 'Preview requires Chrome',
        text:
          "To preview your personalization campaign, please open this page using the Chrome browser and click 'preview' again.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Install Chrome',
        cancelButtonText: "Got it, I'll open this page in Chrome",
      }).then(() => {
        window.open('https://www.google.com/chrome/browser/desktop/index.html');
      });
    };

    this.model.save(null, {
      error(_model: any, response: { responseText: string }) {
        let error: { message?: string } = {};
        try {
          error = JSON.parse(response.responseText);
        } catch (e) {
          return;
        }
        return app.notifications.danger(`Save error: ${error.message}`, {
          dismiss_timeout: 2000,
        });
      },
      success: () => {
        if (
          window.chrome &&
          window.chrome.app &&
          document.querySelector('meta[name="extension-is-installed"') &&
          (document.querySelector(
            'meta[name="extension-is-installed"'
          ) as HTMLMetaElement).content === 'true'
        ) {
          this.open_website();
        } else if (window.chrome) {
          redirect_to_chrome_store();
        } else {
          require_chrome();
        }
      },
    });
  },
});

const CampaignSummaryView = (Backbone as any).Epoxy.View.extend({
  className: 'campaign',
  bindings: 'data-bind',

  events: {
    'click .mki_delete': 'delete_campaign',
    'click .mki_edit': 'edit_campaign',
  },

  setterOptions: { save: true },

  initialize(options: { tenant: number; parent_view: string }) {
    this.tenant = options.tenant;
    this.parent_view = options.parent_view;
    this.render();
  },

  render() {
    const html = $(
      '#tpl_campaign_summary',
      template_all({
        name: this.model.name,
        active: this.model.get('active'),
      })
    ).html();
    this.$el.html(html);
    return this;
  },

  edit_campaign(e: any) {
    e.preventDefault();
    this.details_view = new CampaignDetailsView({
      tenant: this.tenant,
      model: this.model,
      parent_view: this.parent_view,
    });
  },

  delete_campaign(e: any) {
    e.preventDefault();
    this.model.destroy();

    // remove campaign from DOM
    this.$el.remove();
  },
});

const CampaignsView = (Backbone as any).Epoxy.View.extend({
  bindings: 'data-bind',

  initialize(options: { tenant: number; parent_view: string }) {
    this.tenant = options.tenant;
    this.parent_view = options.parent_view;
  },

  render() {
    forEach(this.collection.models, (model) => {
      const view = new CampaignSummaryView({
        tenant: this.tenant,
        model,
        parent_view: this.parent_view,
      });
      $('#mki_forms').append(view.$el);
    });
    return this;
  },
});

export default Backbone.View.extend({
  bindings: 'data-bind',

  events: {
    'click #btn_add_campaign': 'create_campaign',
  },

  initialize(options: { tenant: number; campaign_id: string }) {
    this.tenant = options.tenant;
    this.campaign_id = options.campaign_id;
    this.collection = new CampaignCollection({
      tenant: this.tenant,
    });
    this.campaigns_view = new CampaignsView({
      tenant: this.tenant,
      collection: this.collection,
      parent_view: this,
    });
    this.collection.fetch({
      success: () => {
        this.render();
      },
    });
  },

  render() {
    const model = this.collection.findWhere({ _id: this.campaign_id });
    if (model) {
      this.details_view = new CampaignDetailsView({
        tenant: this.tenant,
        model,
        parent_view: this,
      });
    } else {
      const html = $('#tpl_main', template_all()).html();
      this.$el.html(html);
      this.campaigns_view.render();
    }
    // this.listenTo(this.collection, 'add remove', this.render)
    return this;
  },

  create_campaign(e: any) {
    e.preventDefault();
    const model = new CampaignModel({ tenant: this.tenant });
    model.set('name', 'New campaign');
    model.set('active', false);
    model.save(null, {
      error: (_model: any, response: { responseText: string }) => {
        console.error('error', response);
      },
      success: (mod: any) => {
        this.collection.add(mod);
        this.details_view = new CampaignDetailsView({
          tenant: this.tenant,
          mod,
          parent_view: this,
        });
      },
    });
  },
});
