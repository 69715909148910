import React, { useMemo } from 'react';
import {
  OBJECT_PLACEHOLDER_VALUE,
  SUBJECT_PLACEHOLDER_VALUE,
  VERB_PLACEHOLDER_VALUE,
} from '../../../constants';
import { unslugFieldName } from '../../../pullConfigManager';
import { Condition } from '../../models/types';

type EventMappingConditionItemProps = Condition & {
  conditionIndex: number;
  lastConditionIndex: number;
  conditionLogic: string;
  currentEventErrors: any[];
  isSalesforce: boolean;
  maxWidth: React.CSSProperties['maxWidth'];
};

export default function EventMappingConditionItem({
  object,
  subject,
  verb,
  values,
  conditionIndex,
  currentEventErrors,
  isSalesforce,
  maxWidth,
  conditionLogic,
  lastConditionIndex,
}: EventMappingConditionItemProps) {
  const currentConditionErrors = currentEventErrors
    .filter((el) => el.index === conditionIndex)
    .map(({ level }) => level);

  const invalidSubject =
    currentConditionErrors.includes('subject') ||
    String(SUBJECT_PLACEHOLDER_VALUE).toLowerCase() ===
      String(subject).toLowerCase();
  const invalidObject =
    currentConditionErrors.includes('object') ||
    String(OBJECT_PLACEHOLDER_VALUE).toLowerCase() ===
      String(object).toLowerCase();
  const invalidVerb =
    currentConditionErrors.includes('verb') ||
    String(VERB_PLACEHOLDER_VALUE).toLowerCase() === String(verb).toLowerCase();
  const isBinaryVerb = verb === 'is known' || verb === 'is unknown';
  const invalidValues =
    currentConditionErrors.includes('values') || (!isBinaryVerb && !values);

  const isClosing = conditionLogic.includes(`$${conditionIndex + 1})`);

  /**
   * Here we try to find the previous operator plus brackets before some condition.
   * We should expect something like `AND `, `OR `, `AND (`, `OR (` etc.
   */
  const previousOperator = useMemo(() => {
    if (conditionIndex === 0) {
      const conditionStr = conditionLogic.trimStart().split('$')?.shift() ?? '';
      // If event has more than one condition, open bracket
      return lastConditionIndex === 0
        ? `AND ${conditionStr}`
        : `AND (${conditionStr}`;
    }
    const isOpening = conditionLogic.includes(`($${conditionIndex + 1} `);
    if (isOpening) {
      const matches: RegExpExecArray = new RegExp(
        `([\\( ]+)\\$${conditionIndex + 1} `
      ).exec(conditionLogic);
      if (matches) {
        matches.shift();
        const brackets = matches.join('');
        return conditionLogic.includes(`AND${brackets}$${conditionIndex + 1}`)
          ? `AND${brackets}`
          : `OR${brackets}`;
      }
      return '';
    }
    return conditionLogic.includes(`AND $${conditionIndex + 1}`) ? 'AND' : 'OR';
  }, [conditionIndex, conditionLogic]);

  /**
   * Here we try to match every bracket after some condition.
   */
  const closingTags = useMemo(() => {
    if (!isClosing) return '';
    const matches: RegExpExecArray = new RegExp(
      ` \\$${conditionIndex + 1}([\\) ]+)`
    ).exec(conditionLogic);
    if (matches) {
      matches.shift();
      return matches.join('');
    }
    return '';
  }, [conditionIndex, conditionLogic]);

  return (
    <p
      className="mb-0"
      style={{
        // TODO: Look if there is a cleanier way to do this with Bootstrap.
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: `${maxWidth}px`,
        overflow: 'hidden',
      }}
    >
      <code className="align-self-end text-nowrap mr-1 text-secondary-500">
        {previousOperator}
      </code>

      {invalidObject ? (
        <span className="text-danger-500 mr-1">...</span>
      ) : (
        <span className="mr-1">{unslugFieldName(object)} </span>
      )}

      {subject && isSalesforce && (
        <>
          {invalidSubject ? (
            <span className="text-danger-500 mr-1">...</span>
          ) : (
            <span className="mr-1">
              {unslugFieldName(subject, isSalesforce)}{' '}
            </span>
          )}
        </>
      )}

      {invalidVerb ? (
        <span className="text-danger-500 mr-1">...</span>
      ) : (
        <code className="align-self-end text-nowrap text-uppercase mr-1 text-secondary-500">
          {verb}
        </code>
      )}

      {invalidValues ? (
        <span className="text-danger-500">...</span>
      ) : (
        <span>{values?.length ? values : ''}</span>
      )}

      {isClosing && (
        <code className="align-self-end ml-1 text-secondary-500">
          {closingTags}
        </code>
      )}
      {conditionIndex === lastConditionIndex && lastConditionIndex !== 0 && (
        <code className="align-self-end ml-1 text-secondary-500">{')'}</code>
      )}
    </p>
  );
}
