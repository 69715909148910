import Backbone from 'backbone';
import { BackboneViewProps } from '../types';

require('backbone.epoxy');

export default (Backbone as any).Epoxy.Model.extend({
  initialize(properties: BackboneViewProps) {
    this.tenant = properties.tenant || this.tenant;
  },

  urlRoot() {
    return `${BONGO_URL}/v1/org/${this.tenant}/billing`;
  },

  defaults: {
    plan: 'free',
  },
});
