import Backbone from 'backbone';
import moment from 'moment';

require('backbone.epoxy');

const TIME_DANGER_H = 12;

export const Process_model = (Backbone as any).Epoxy.Model.extend({
  // idAttribute: 'name',

  initialize(properties: { tenant?: number } = {}) {
    this.tenant =
      properties.tenant ||
      (this.collection ? this.collection.tenant : undefined);
  },

  urlRoot() {
    const tenant = this.get('tenant') || this.tenant;
    return `${BONGO_URL}/v1/org/${tenant}/processes`;
  },

  parse(response: any) {
    return response || {};
  },

  computeds: {
    time_since_last_success: {
      deps: ['end_date'],
      get() {
        return this.get('end_date')
          ? moment(this.get('end_date')).fromNow()
          : 'never';
      },
    },

    no_recent_success: {
      deps: ['end_date'],
      get() {
        return this.get('end_date')
          ? moment().diff(moment(this.get('end_date')), 'hours') > TIME_DANGER_H
          : true;
      },
    },

    failed: {
      deps: ['error'],
      get() {
        return !!this.get('error');
      },
    },

    idle: {
      deps: ['status'],
      get() {
        return this.get('status') === 'idle';
      },
    },

    processing: {
      deps: ['status'],
      get() {
        return (
          this.get('status') === 'processing' || this.get('status') === 'queued'
        );
      },
    },

    time_since_start: {
      deps: ['start_date'],
      get() {
        return this.get('start_date')
          ? moment(this.get('start_date')).fromNow(true)
          : null;
      },
    },

    status_display: {
      deps: ['status', 'error', 'time_since_start'],
      get() {
        let status = this.get('status');
        const error = this.get('error');
        if (this.get('processing')) {
          status += ` - for ${this.get('time_since_start')}`;
        }
        if (error) {
          status += ` (errors: ${error.count}, last error: ${error.last_message})`;
        }
        return status;
      },
    },

    name_split: {
      deps: ['name'],
      get() {
        return (this.get('name') || '').split('/');
      },
    },

    element_0: {
      deps: ['name_split'],
      get() {
        const split = this.get('name_split');
        return split && split[0];
      },
    },

    element_1: {
      deps: ['name_split'],
      get() {
        const split = this.get('name_split');
        return split && split[1];
      },
    },

    element_2: {
      deps: ['name_split'],
      get() {
        const split = this.get('name_split');
        return split && split[2];
      },
    },

    sub_category: {
      deps: ['name'],
      get() {
        if (this.get('element_0') === 'connectors') {
          return [this.get('element_0'), this.get('element_2')].join('_');
        }
        return this.get('element_0');
      },
    },

    is_triggerable: {
      deps: ['name'],
      get() {
        if (this.get('element_0') === 'data') {
          return true;
        }
        return false;
      },
    },
  },
});

export const Process_collection = Backbone.Collection.extend({
  model: Process_model,

  initialize(properties: { tenant?: number } = {}) {
    this.tenant = properties.tenant;
  },

  url() {
    if (this.tenant) {
      return `${BONGO_URL}/v1/org/${this.tenant}/processes`;
    }
    return `${BONGO_URL}/v1/admin/processes`;
  },
});
