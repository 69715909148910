import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useApolloIsConnected(tenant: number) {
  return useQuery<boolean>({
    queryKey: ['apollo', tenant],
    queryFn: async () => {
      try {
        // Check if the connector exists and has valid credentials
        const { data } = await axios.get(
          `${BONGO_URL}/v1/org/${tenant}/integrations/apollo/connection-status`
        );

        // Return true only if the connector exists and the API key is valid
        return data.tested && data.valid === true;
      } catch (error) {
        // If any error occurs, consider the connection as invalid
        return false;
      }
    },
  });
}
