var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (RECAPTCHA_SITE_KEY, is_sso, signupUrl, sso) {
      pug_html = pug_html + "\u003Cdiv class=\"onboarding-container\"\u003E\u003Cdiv class=\"div\"\u003E\u003Cdiv class=\"text-center\"\u003E\u003Cimg src=\"\u002Fmedia\u002Fimages\u002Fmadkudu_horns_only_blue.svg\"\u003E\u003C\u002Fdiv\u003E";
if (sso) {
}
pug_html = pug_html + "\u003Cdiv class=\"justify-content-center d-flex align-items-center\"\u003E\u003Cdiv class=\"col-md-4 col-md-offset-4\"\u003E\u003Cdiv class=\"panel panel-login p-5 m-1 box-shadow-3d rounded text-white\"\u003E\u003Cdiv class=\"panel-body\"\u003E\u003Cform class=\"form-horizontal\" id=\"form_login\" role=\"form\"\u003E";
if (!is_sso) {
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Cinput class=\"form-control\" type=\"text\" name=\"email\" placeholder=\"Email\" autocomplete=\"off\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cinput class=\"form-control\" type=\"password\" name=\"password\" placeholder=\"Password\" autocomplete=\"off\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E\u003Cbutton class=\"btn btn-teal text-white btn-block\" id=\"btn_login\"\u003ESign In\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"text-center\"\u003E\u003Cp\u003Eor\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"form-group text-center\"\u003E\u003Cimg id=\"btn_login_google\" src=\"\u002Fmedia\u002Ficons\u002Fbtn_google_signin_light_normal_web@2x.png\" width=\"250px\" style=\"cursor: pointer\"\u003E\u003Cp\u003Eor\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group text-center\"\u003E\u003Ca href=\"\u002Flogin\u002Fsso\"\u003ESign in with Okta\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003Cscript" + (pug.attr("src", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`, true, true)+" async=\"\" defer=\"\"") + "\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (!is_sso) {
pug_html = pug_html + "\u003Cdiv class=\"justify-content-center d-flex align-items-center\"\u003E\u003Cdiv class=\"col-md-4 col-md-offset-4\"\u003E\u003Cdiv class=\"pull-left\"\u003E\u003Ca href=\"\u002Frecover\"\u003EForgot your password?\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pull-right\"\u003E\u003Ca" + (pug.attr("href", signupUrl, true, true)) + "\u003ECreate an account\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "RECAPTCHA_SITE_KEY" in locals_for_with ?
        locals_for_with.RECAPTCHA_SITE_KEY :
        typeof RECAPTCHA_SITE_KEY !== 'undefined' ? RECAPTCHA_SITE_KEY : undefined, "is_sso" in locals_for_with ?
        locals_for_with.is_sso :
        typeof is_sso !== 'undefined' ? is_sso : undefined, "signupUrl" in locals_for_with ?
        locals_for_with.signupUrl :
        typeof signupUrl !== 'undefined' ? signupUrl : undefined, "sso" in locals_for_with ?
        locals_for_with.sso :
        typeof sso !== 'undefined' ? sso : undefined));
    ;;return pug_html;};
module.exports = template;