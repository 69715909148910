import React from 'react';

const BootstrapTable = require('react-bootstrap-table-next').default;
const filterFactory = require('react-bootstrap-table2-filter');
const paginationFactory = require('react-bootstrap-table2-paginator');

type ModelsTableComponentProps = {
  models: UnknownArrayOfObjects;
};

export default class ModelsTableComponent extends React.Component<
  ModelsTableComponentProps,
  {}
> {
  render() {
    const { models } = this.props;
    const modelsWithLink = models
      .map((model) => {
        // eslint-disable-next-line
        model.id = `${model.tenant}_${model.modelId}`;
        return model;
      })
      .sort((a, b) => {
        if (a.tenant === b.tenant) {
          return a.modelId > b.modelId ? 1 : -1;
        }
        return a.tenant > b.tenant ? 1 : -1;
      });

    const columns = [
      {
        dataField: 'tenant',
        text: 'Tenant',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'modelId',
        text: 'ID',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'id',
        text: 'Performances',
        formatter: (value: string) => {
          const model = modelsWithLink.find((m) => m.id === value);
          const url = `/org/${model.tenant}/predictions/performances/models/${
            model.modelId
          }${model.type !== 'customer_fit' ? '/diagnostics' : ''}`;
          return <a href={url}>Link</a>;
        },
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'type',
        text: 'Type',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'batch',
        text: 'Batch?',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'realtime',
        text: 'Realtime?',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'recall',
        text: 'Recall',
        sort: true,
        filter: filterFactory.textFilter(),
      },
    ];

    return (
      <BootstrapTable
        keyField="id"
        data={modelsWithLink}
        columns={columns}
        filter={filterFactory.default()}
        pagination={paginationFactory.default()}
        wrapperClasses="table-responsive table-hover"
      />
    );
  }
}
