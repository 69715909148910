import React from 'react';
import ActiveIntegrationDataModel from '../../models/ActiveIntegrationDataModel';
import {
  getEventMappingMadMlData,
  getRelevantEvents,
  sortRelevantEvents,
} from '../../utils';
import RelevantEventsInformationModel from '../../models/RelevantEventsInformationModel';
import EventMappingConnectorsNames from '../../../enums/EventMappingConnectorsNames';
import IndividualCardDisplayComponent from './IndividualCardDisplayComponent';
import EventsTags from '../../../enums/EventsTags';

type EventMappingParentDisplayComponentProps = {
  arrayOfActiveIntegrations: ActiveIntegrationDataModel[];
  tenant: number;
  isReadOnly: boolean;
};

type EventMappingParentDisplayComponentState = {
  noData: boolean;
};

export default class EventMappingParentDisplayComponent extends React.Component<
  EventMappingParentDisplayComponentProps,
  EventMappingParentDisplayComponentState
> {
  relevantEvents: RelevantEventsInformationModel[]; // need this list as Read only

  constructor(props: EventMappingParentDisplayComponentProps) {
    super(props);
    this.state = {
      noData: true,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    const { arrayOfActiveIntegrations, tenant } = this.props;
    const promiseList = arrayOfActiveIntegrations
      .filter((activeIntegration) => {
        return activeIntegration.name !== EventMappingConnectorsNames.madMl;
      })
      .map((activeIntegration) => {
        return getRelevantEvents(tenant, activeIntegration.name);
      });
    const result = await Promise.all(promiseList);
    this.relevantEvents = result.map((relevantEvent) => {
      const {
        connector,
        events,
        currentTag,
        disabledConnectors,
      } = relevantEvent;
      return new RelevantEventsInformationModel(
        connector as EventMappingConnectorsNames,
        events,
        currentTag,
        disabledConnectors
      );
    });
    this.relevantEvents = sortRelevantEvents(this.relevantEvents);

    // MadMl
    const {
      disabledConnectors,
      history,
      madMlSqlQuery,
    } = await getEventMappingMadMlData(tenant);
    const madMlTag = history.length === 0 ? EventsTags.Draft : EventsTags.Live;

    this.relevantEvents.push(
      new RelevantEventsInformationModel(
        EventMappingConnectorsNames.madMl,
        [],
        madMlTag,
        disabledConnectors,
        madMlSqlQuery
      )
    );

    this.setState({
      noData: false,
    });
  }

  renderAccordion() {
    const { tenant, isReadOnly } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-9">
                    Name <i className="fas fa-caret-down"></i>
                  </div>
                  <div className="col-3">
                    Status <i className="fas fa-caret-down"></i>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordion-parent">
                {this.relevantEvents.map((relevantEvent, index) => {
                  return (
                    <div
                      key={`d_${relevantEvent.connector}`}
                      id={`d_${relevantEvent.connector}`}
                      data-parent="#accordion-parent"
                      className="shadow"
                    >
                      <IndividualCardDisplayComponent
                        relevantEvent={relevantEvent}
                        tenant={tenant}
                        isReadOnly={isReadOnly}
                        index={index}
                        connector={relevantEvent.connector}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data">
        <div className="card-body align-middle">
          <div className="spinner-border float-left mr-2"></div>
          <h5>Loading...</h5>
        </div>
      </div>
    );
  }

  render() {
    const { noData } = this.state;
    return <div>{noData ? this.renderLoader() : this.renderAccordion()}</div>;
  }
}
