import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowProgress } from '@fortawesome/pro-solid-svg-icons/faArrowProgress';
import { faChartColumn } from '@fortawesome/pro-solid-svg-icons/faChartColumn';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';

type Props = {
  tenant: number;
};
export function DataStudio({ tenant }: Props) {
  return (
    <div className="p-4">
      <div className="tw-w-full tw-max-w-7xl tw-shadow-lg lg:tw-w-5/6 tw-bg-white tw-mx-auto tw-mt-[12vh] tw-border tw-rounded-lg tw-overflow-hidden">
        <div className="tw-h-14 tw-px-6 tw-border-b tw-bg-gray-50 tw-flex tw-items-center">
          <h1 className="tw-m-0 tw-text-2xl">Welcome to MadKudu Data Studio</h1>
        </div>
        <div className="tw-flex lg:tw-flex-row p-4">
          <div className="tw-w-1/2 tw-space-y-10">
            <div>
              <h5 className="tw-flex tw-items-center">
                <FontAwesomeIcon
                  icon={faUserTie}
                  fontSize={22}
                  className="mr-2"
                />{' '}
                Explore your Ideal Customer Profile
              </h5>
              <p className="tw-text-gray-500">
                Leverage hundreds of firmographic, demographic and technographic
                data points.
              </p>
            </div>
            <div>
              <h5 className="tw-flex tw-items-center">
                <FontAwesomeIcon
                  icon={faChartColumn}
                  fontSize={22}
                  className="mr-2"
                />
                Create Scoring Models
              </h5>
              <p className="tw-text-gray-500">
                Configure Customer Fit and Engagement scoring models to
                prioritize leads and accounts effectively
              </p>
            </div>
            <div>
              <h5 className="tw-flex tw-items-center">
                <FontAwesomeIcon
                  icon={faArrowProgress}
                  fontSize={22}
                  className="mr-2"
                />
                Identify buyer journey milestones
              </h5>
              <p className="tw-text-gray-500">
                Pinpoint critical touchpoints and stages in the buyer journey to
                optimize marketing strategies
              </p>
            </div>
            <div className="tw-text-center">
              <button
                className="btn btn-primary my-2 px-3 py-2"
                onClick={() =>
                  window.open(`https://studio.madkudu.com/tenant/${tenant}`)
                }
              >
                Open Data studio{' '}
                <FontAwesomeIcon
                  icon={faExternalLink}
                  fontSize={14}
                  className="ml-1"
                />
              </button>
            </div>
          </div>
          <div className="tw-w-1/2 tw-text-center">
            <img
              width="339"
              height="272"
              src="/media/images/studio.png"
              alt="data studio"
              className="mw-100 tw-object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
