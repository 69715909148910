import React from 'react';

export default class Step extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.isConfirmed = this.isConfirmed.bind(this);
    this.handleStepValueChange = this.handleStepValueChange.bind(this);
    this.handleMultipleValuesChange = this.handleMultipleValuesChange.bind(
      this
    );
  }

  isConfirmed() {
    const { confirmStepHandler, stepId } = this.props;
    confirmStepHandler(stepId);
  }

  handleStepValueChange(e: any) {
    this.props.handleStepValueChange(e.target.value);
  }

  handleMultipleValuesChange(index: number) {
    return (e: any) => {
      this.props.handleMultipleValuesChange(e.target.value, index);
    };
  }

  render() {
    const {
      stepId,
      stepName,
      stepValue,
      stepValues,
      shouldShow,
      inputName,
      inputType,
      placeholder,
      isAdvanced,
      isConfigured,
      inputId,
      accordionId,
      collapseId,
      headingId,
      hrefDataAccordionId,
      hrefDataCollapseId,
      description,
      twoInput,
      inputName2,
    } = this.props;
    const valuesToDisplay = stepValues || [stepValue];

    return (
      <div className="row justify-content-center">
        <div className="col-10 mt-2">
          <div
            className="accordion md-accordion"
            id={accordionId}
            role="tablist"
            aria-multiselectable="true"
          >
            <div
              className="card box-shadow-3d"
              style={{ borderColor: isConfigured ? '#4DCCBD' : 'default' }}
            >
              <div
                className="card-header align-items-center"
                role="tab"
                id={headingId}
              >
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent={hrefDataAccordionId}
                  href={hrefDataCollapseId}
                  aria-expanded="false"
                  aria-controls={collapseId}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="row align-items-center">
                    <div className="col align-items-center">
                      <h5
                        className="align-items-center mb-0"
                        style={{ color: isConfigured ? '#4DCCBD' : 'black' }}
                      >
                        {stepName}
                        <h5
                          className="pull-right mb-0"
                          style={{ color: isConfigured ? '#4DCCBD' : 'red' }}
                        >
                          <span className="mr-4">
                            {isConfigured ? 'Configured' : 'Not Configured'}
                          </span>
                          <span>
                            <i className="fas fa-angle-down"></i>
                          </span>
                        </h5>
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div
                id={collapseId}
                className={`collapse ${
                  stepId === 1 || shouldShow ? 'show' : ''
                }`}
                role="tabpanel"
                aria-labelledby={headingId}
                data-parent={hrefDataAccordionId}
                style={{ textDecoration: 'none' }}
              >
                <div className="card-body">
                  {description && (
                    <div className="row mb-2">
                      <div className="col">
                        <h6>{description}</h6>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputCampaign"
                            className="col-md-auto col-form-label"
                            style={{ fontSize: '1em', fontWeight: 'bold' }}
                          >
                            {inputName}
                          </label>
                          <div className="col-9">
                            <input
                              type={inputType}
                              className="form-control"
                              id={inputId}
                              onChange={
                                stepValues
                                  ? this.handleMultipleValuesChange(0)
                                  : this.handleStepValueChange
                              }
                              value={valuesToDisplay[0]}
                              placeholder={placeholder}
                              style={{ backgroundColor: '#f2f4f4' }}
                            ></input>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {isAdvanced && (
                    <div className="card p-3 mb-2">
                      <div className="row">
                        <div className="col">
                          <h6>Advanced(optional):</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-group row">
                              <label
                                htmlFor="inputCampaign"
                                className="col-md-auto col-form-label"
                                style={{ fontSize: '1em', fontWeight: 'bold' }}
                              >
                                Regex:
                              </label>
                              <div className="col-9">
                                <input
                                  type="url"
                                  className="form-control"
                                  id="advanced"
                                  onChange={this.handleMultipleValuesChange(1)}
                                  value={valuesToDisplay[1]}
                                  placeholder="https://www.example.com/thank-you"
                                  style={{ backgroundColor: '#f2f4f4' }}
                                ></input>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {twoInput && (
                    <div className="row">
                      <div className="col">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="form-group row">
                            <label
                              htmlFor="input"
                              className="col-md-auto col-form-label"
                              style={{ fontSize: '1em', fontWeight: 'bold' }}
                            >
                              {inputName2}
                            </label>
                            <div className="col-9">
                              <input
                                type={inputType}
                                className="form-control"
                                id={inputId}
                                onChange={this.handleMultipleValuesChange(2)}
                                value={valuesToDisplay[2]}
                                placeholder={placeholder}
                                style={{ backgroundColor: '#f2f4f4' }}
                              ></input>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        disabled={!valuesToDisplay[0]}
                        onClick={this.isConfirmed}
                      >
                        <a
                          href={valuesToDisplay[0] && hrefDataCollapseId}
                          className="btn-primary"
                          data-toggle="collapse"
                          aria-expanded="false"
                          aria-controls="collapse"
                        >
                          Confirm
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
