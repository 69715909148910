import axios from 'axios';
import Backbone from 'backbone';
import Chart from 'chart.js';
import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import { COLORS } from '../../../predictions/utils';
import { getBootstrapColumnsFromData, refreshDataDiscovery } from '../../utils';
import app from '../../../app';

const ChartDataLabels = require('chartjs-plugin-datalabels');

require('bootstrap-table/dist/bootstrap-table');

const template = require('./discovery.pug');

Chart.plugins.register(ChartDataLabels);

export default Backbone.View.extend({
  events: {
    'click #go_back': 'go_back',
    'click #refresh': 'refresh',
  },
  async initialize(options: { tenant: number; source_system: string }) {
    this.tenant = options.tenant;
    this.userEmail = app.session.get('user').email;
    this.sourceSystem = options.source_system;
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/discovery/${this.sourceSystem}`;
    const { data } = await axios.get(url);
    const updatedAt: string =
      moment(data.updated_at).format('lll') ?? 'unknown';

    this.data = data;
    this.updatedAt = updatedAt;
    this.render();
    this.numberOfEventsGraph();
    this.monthlyActiveUsers();
    this.avgEventsPerMonthlyActiveUser();
    this.newIdentifiedUsers();
    this.mostFrequentEvents();

    if (this.sourceSystem === 'segment') {
      this.mostFrequentPages();
    }
  },

  go_back() {
    Backbone.history.navigate(`/org/${this.tenant}/data`, true);
  },

  async refresh() {
    await refreshDataDiscovery(this.tenant, this.userEmail, this.sourceSystem);
  },

  numberOfEventsGraph() {
    const data: UnknownObject[] = this.data.dataset.events;
    const labels = data.reduce(
      (acc, row) =>
        acc.concat(moment(row['event timestamp']).format('MMM YYYY')),
      []
    );
    const ctx = document.getElementById('number_of_events').getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Distinct events',
            data: data.map((row: any) => row['distinct events']),
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 10,
            backgroundColor: COLORS[1],
            showLine: false,
            yAxisID: 'B',
          },
          {
            label: 'Total events logged',
            data: data.map((row: any) => row['total events logged']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },
  monthlyActiveUsers() {
    const data: UnknownObject[] = this.data.dataset.events;
    const labels = data.reduce(
      (acc, row) =>
        acc.concat(moment(row['event timestamp']).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('monthly_active_users')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Total active users',
            data: data.map((row: any) => row['active users']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },
  avgEventsPerMonthlyActiveUser() {
    const data: UnknownObject[] = this.data.dataset.events;
    const labels = data.reduce(
      (acc, row) =>
        acc.concat(moment(row['event timestamp']).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('avg_events_per_monthly_active_user')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Avg events per active user',
            data: data.map((row: any) => row['average events per active user']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },
  newIdentifiedUsers() {
    const data: UnknownObject[] = this.data.dataset.contacts;
    const labels = data.reduce(
      (acc, row) => acc.concat(moment(row['Created date']).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('new_identified_users')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'New users',
            data: data.map((row: any) => row['New Users']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },
  mostFrequentEvents() {
    const mostFrequentEvents: UnknownObject[] = this.data.dataset
      .most_frequent_events;

    const data = mostFrequentEvents.map((row) => {
      return {
        event: row.event,
        count_event: row.count_event,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#most_frequent_events', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },
  mostFrequentPages() {
    const mostFrequentPages: {
      path: string;
      'days since last seen': string;
      count: number;
    }[] = this.data.dataset.most_frequent_pages;

    const data = mostFrequentPages.map((row) => {
      return {
        path: row.path,
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#most_frequent_pages', this.$el).bootstrapTable({
      columns: [
        { field: 'path', title: 'Path', sortable: true },
        {
          field: 'count',
          title: 'Count',
          sortable: true,
          formatter: (_value: string) => numeral(_value).format('0,0'),
        },
        {
          field: 'days_since_last_seen',
          title: 'Days Since Last Seen',
          sortable: true,
          formatter: (_value: string) => numeral(_value).format('0,0'),
        },
      ],
      data,
      pagination: true,
      search: true,
    });
  },
  render() {
    const html = template({
      tenant: this.tenant,
      sourceSystem: this.sourceSystem,
      noData: typeof this.data === 'undefined',
      updatedAt: this.updatedAt,
    });
    this.$el.html(html);

    return this;
  },
});
