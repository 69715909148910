// Important to konw: When adding a new SVG file, please remove the width and height attributes from the svg tag
import add from './add.svg';
import applications from './applications.svg';
import arrowDown from './arrow-down.svg';
import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';
import blocks from './blocks.svg';
import book from './book.svg';
import check from './check.svg';
import clock from './clock.svg';
import dotsMenu from './dots-menu.svg';
import book2 from './dual-tone/book.svg';
import boxChecked from './dual-tone/box-checked.svg';
import chat from './dual-tone/chat.svg';
import code from './dual-tone/code.svg';
import compass from './dual-tone/compass.svg';
import data from './dual-tone/data.svg';
import documentation from './dual-tone/documentation.svg';
import envelopeOpen from './dual-tone/envelope-open.svg';
import gear from './dual-tone/gear.svg';
import glasses from './dual-tone/glasses.svg';
import house from './dual-tone/house.svg';
import insights from './dual-tone/insights.svg';
import integrations from './dual-tone/integrations.svg';
import logout from './dual-tone/logout.svg';
import mapping from './dual-tone/mapping.svg';
import message from './dual-tone/message.svg';
import predictions from './dual-tone/predictions.svg';
import profiles from './dual-tone/profiles.svg';
import question from './dual-tone/question.svg';
import user from './dual-tone/user.svg';
import workspaceApplication from './dual-tone/workspace-application.svg';
import workspaceStudio from './dual-tone/workspace-studio.svg';
import wrench from './dual-tone/wrench.svg';
import edit from './edit.svg';
import email from './email.svg';
import externalLink from './external-link.svg';
import kudu from './kudu.svg';
import listDot from './list-dot.svg';
import loadMadkudu from './load-madkudu.svg';
import load from './load.svg';
import lock from './lock.svg';
import phone from './phone.svg';
import play from './play.svg';
import plug from './plug.svg';
import search from './search.svg';
import trash from './trash.svg';
import triangleExclamation from './triangle-exclamation.svg';
import userSolid from './user.svg';
import xmark from './xmark.svg';

const svgs = {
  house,
  profiles,
  integrations,
  insights,
  predictions,
  mapping,
  data,
  gear,
  wrench,
  user,
  logout,
  load,
  loadMadkudu,
  documentation,
  arrowDown,
  arrowRight,
  arrowLeft,
  applications,
  check,
  search,
  edit,
  add,
  message,
  kudu,
  workspaceApplication,
  workspaceStudio,
  externalLink,
  glasses,
  dotsMenu,
  plug,
  listDot,
  blocks,
  trash,
  book,
  userSolid,
  phone,
  email,
  lock,
  question,
  triangleExclamation,
  xmark,
  play,
  clock,
  chat,
  code,
  book2,
  envelopeOpen,
  boxChecked,
  compass,
};

export default svgs;
