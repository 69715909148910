import * as d3Shape from 'd3-shape';
import * as d3Ease from 'd3-ease';
import * as d3Interpolate from 'd3-interpolate';
import 'd3-transition';

import {
  CursorConfig,
  D3BasicSelection,
  GaugeConfigWithRanges,
  SegmentsConfig,
} from '../types';
import { transformValueToAngle } from '../utils';

export default function drawCursor(
  svg: D3BasicSelection,
  value: number,
  gaugeConfig: GaugeConfigWithRanges,
  segmentsConfig: SegmentsConfig,
  cursorConfig: CursorConfig
) {
  const radius = gaugeConfig.diameter / 2;
  const isValueValid =
    value >= gaugeConfig.valuesRange[0] && value <= gaugeConfig.valuesRange[1];

  const zeroAngle = transformValueToAngle(
    0,
    gaugeConfig.valuesRange,
    gaugeConfig.anglesRange
  );
  const angle = transformValueToAngle(
    value,
    gaugeConfig.valuesRange,
    gaugeConfig.anglesRange
  );

  const indicator = d3Shape
    .symbol()
    .type(d3Shape.symbolTriangle)
    .size(cursorConfig.size * cursorConfig.size);

  if (isValueValid) {
    const indicatorSvg = svg
      .append('g')
      .attr('width', 10)
      .attr('height', radius)
      .attr('transform', `rotate(${zeroAngle})`);

    indicatorSvg
      .append('path')
      .attr('d', indicator)
      .attr('stroke', cursorConfig.color)
      .attr('fill', cursorConfig.color)
      .attr(
        'transform',
        `translate(0, -${
          radius -
          segmentsConfig.width -
          cursorConfig.margin -
          cursorConfig.size
        })`
      );

    indicatorSvg
      .transition()
      .duration(cursorConfig.transition)
      .ease(d3Ease.easeCubicInOut)
      .attrTween('transform', () => {
        const f = d3Interpolate.interpolate(zeroAngle, angle);
        return (t: number) => {
          return `rotate(${f(t)})`;
        };
      });
  }
}
