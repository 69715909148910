import React, { Component } from 'react';

import { FormControl } from 'react-bootstrap';
import { getColorOfScore } from '../../utils';

type GlobalModelPerformanceComponentProps = {
  selectedConversionType: string;
  activeConversionTypes: string[];
  score: string;
  handleOnConversionTypeChange: Function;
};

export default class GlobalModelPerformanceComponent extends Component<
  GlobalModelPerformanceComponentProps,
  {}
> {
  render() {
    const {
      activeConversionTypes,
      handleOnConversionTypeChange,
      selectedConversionType,
      score,
    } = this.props;

    let scorePrint = '';

    if (score === 'N/A') {
      scorePrint = score;
    } else {
      scorePrint = `${score}%`;
    }

    return (
      <div className="row my-3">
        <div className="col-12">
          <div className="card box-shadow-3d d-flex h-100">
            <h5 className="card-header">Model Performance</h5>
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-2-sm ml-2">
                  <p className="font-weight-bold mt-1">Conversion type:</p>
                </div>
                <div className="col-2">
                  <FormControl
                    componentClass="select"
                    value={selectedConversionType}
                    onChange={(event) => handleOnConversionTypeChange(event)}
                  >
                    {activeConversionTypes.map((activeConversion) => {
                      return (
                        <option
                          key={`select_conversion_type_${activeConversion}`}
                        >
                          {activeConversion}
                        </option>
                      );
                    })}
                  </FormControl>
                </div>
              </div>
              <div>
                <p className="mb-0">
                  <b>{scorePrint}</b> of your conversions came from leads that
                  were predicted as 'Good' or 'Very Good' by MadKudu{' '}
                </p>
                <div
                  className="progress mt-3 bg-gray"
                  style={{ height: '40px' }}
                >
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={10}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{
                      width: parseInt(scorePrint, 10) ? scorePrint : 0,
                      backgroundColor: getColorOfScore(Number(score)),
                    }}
                  >
                    <h6 className={'m-auto'}> {scorePrint}</h6>
                  </div>
                </div>
              </div>
              <div>
                <p className="mt-3 text-muted d-flex flex-column">
                  <small>
                    Based on the number of conversions in the past 6 months.
                  </small>
                  <small className="font-weight-bold">
                    Note: We use the opportunity created date for SQO and Open
                    Opp predictions, and we use opportunity close date for
                    Closed Won for Closed Won predictions.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
