import axios from 'axios';
import 'chartjs-plugin-datalabels';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Loader } from '../../../components/Loader';
import { PageDefinition } from '../../InsightsTypes';
import TabsComponent from './TabsComponent';
import launchAirflowDag from '../../../utils/launch_airflow_dag';

type PageComponentProps = {
  toggleView: Function;
  getActiveView: () => string;
  tenant: number;
  pageId: string;
  tabId: string;
  title: string;
  back: () => void;
  goTo: (url: string) => void;
  superKudu: boolean;
  email: string;
};

type PageComponentState = {
  page: PageDefinition;
  error: boolean;
  lastUpdatedAt: string;
};

export default class PageComponent extends React.Component<
  PageComponentProps,
  PageComponentState
> {
  constructor(props: PageComponentProps) {
    super(props);
    this.loadPage = this.loadPage.bind(this);
    this.refresh = this.refresh.bind(this);

    this.state = {
      page: null,
      error: false,
      lastUpdatedAt: '',
    };
  }

  async componentDidMount() {
    // eslint-disable-next-line
    this.loadPage();
  }

  async loadPage() {
    const { tenant, pageId } = this.props;
    let lastUpdatedAt = '';
    try {
      const [{ data: dataMetadata }, { data: dataPage }] = await Promise.all([
        axios.get(`${BONGO_URL}/v1/org/${tenant}/insights_metadata`),
        axios.get(`${BONGO_URL}/v1/org/${tenant}/insights/page/${pageId}`),
      ]);

      const { page }: { page: PageDefinition } = dataPage;
      const dataSources = page.data_sources;
      const dataSourcesLastUpdatedAt = dataMetadata.insight_types
        .filter(({ name }: { name: string }) => dataSources.includes(name))
        .map(({ updated_at }: { updated_at: number }) => updated_at);

      // We consider the last update time of the oldest updated data source
      const minUpdatedAt = Math.min(...dataSourcesLastUpdatedAt);
      lastUpdatedAt = moment(minUpdatedAt).format('lll');

      this.setState({
        lastUpdatedAt,
        page,
      });
    } catch (error) {
      this.setState({
        error: true,
      });
    }
  }

  async refresh() {
    const { tenant, pageId, email } = this.props;
    await launchAirflowDag({
      tenant,
      dagName: 'global_insights',
      sectionName: pageId,
      dagConf: {
        tenant,
        insight_types: this.state.page.data_sources,
      },
    });
    window.analytics.track('Insights refresh button clicked', {
      email,
      tenant,
      insights_report: pageId,
    });
  }

  render() {
    const {
      tenant,
      pageId,
      tabId,
      title,
      back,
      goTo,
      getActiveView,
      toggleView,
      superKudu,
    } = this.props;
    const { page, error, lastUpdatedAt } = this.state;
    let body: JSX.Element | JSX.Element[];
    if (error) {
      body = (
        <div className="col-12">
          <Alert bsStyle="danger">Cannot load the page</Alert>
        </div>
      );
    } else if (!page) {
      body = <Loader />;
    } else {
      body = (
        <React.Fragment key={tabId}>
          <TabsComponent
            page={page}
            tenant={tenant}
            superKudu={superKudu}
            toggleView={toggleView}
            getActiveView={getActiveView}
            pageId={pageId}
          />
        </React.Fragment>
      );
    }

    return (
      <div>
        <ReactTooltip place="left" />
        <div
          className="nav navbar navbar-expand-lg navbar-light bg-light box-shadow-3d"
          aria-label="breadcrumb"
        >
          <ul className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
            <li className="breadcrumb-item">
              <a onClick={back} className="breadcrumb-link text-primary">
                Insights
              </a>
            </li>
            <li className="breadcrumb-item">{title}</li>
          </ul>

          <div className="my-2 my-lg-0 d-flex justify-content-between">
            <p className="mb-0 mt-1 mr-2">{`Last refresh: ${
              lastUpdatedAt || 'unknown'
            }`}</p>
            <Button
              className="btn-primary mr-2"
              data-tip="Refresh this report"
              onClick={this.refresh}
            >
              <i className="fas fa-refresh"></i>
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                goTo(`/org/${tenant}/evr_insights/${pageId}/configuration`)
              }
            >
              <i className="fas fa-cog"></i>
            </Button>
          </div>
        </div>
        <div className="container-fluid p-0">{body}</div>
      </div>
    );
  }
}
