import Calculator from './calculator';

const { GoogleCharts } = require('google-charts');

const color_palette = ['#5DA5DA', '#FAA43A', '#60BD68', '#D3D3D3'];

function options_charts(min_value: number, max_value: number) {
  return {
    height: 400,
    vAxis: {
      format: '$#,###',
      viewWindow: {
        min: min_value - (max_value - min_value) * 0.1,
        max: max_value + (max_value - min_value) * 0.1,
      },
    },
    hAxis: { title: 'Months' },
    isStacked: true,
    series: {
      0: { areaOpacity: 0.1, color: color_palette[0] },
    },
    pointSize: 8,
    legend: { position: 'none' },
    chartArea: { left: 100, top: 20, width: '100%', height: '80%' },
    animation: { duration: 1000, easing: 'out', startup: true },
  };
}

function options_breakdown() {
  return {
    height: 250,
    vAxis: {
      format: '$#,###',
    },
    hAxis: { title: 'Months' },
    seriesType: 'bars',
    isStacked: true,
    series: {
      0: { color: color_palette[0] },
      1: { color: color_palette[1] },
      2: { color: color_palette[2] },
      3: { color: color_palette[3] },
    },
    legend: { position: 'none' },
    chartArea: { left: 100, top: 20, width: '100%', height: '80%' },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
  };
}

function options_mrr(min_value: number, max_value: number) {
  return {
    height: 250,
    vAxis: {
      format: '$#,###',
      viewWindow: {
        min: min_value - (max_value - min_value) * 0.1,
        max: max_value + (max_value - min_value) * 0.1,
      },
    },
    hAxis: { title: 'Months' },
    isStacked: false,
    series: {
      0: { areaOpacity: 0.05, color: color_palette[0] },
      1: { areaOpacity: 0.05, color: color_palette[2], lineDashStyle: [4, 4] },
    },
    pointSize: 8,
    legend: { position: 'none' },
    chartArea: { left: 100, top: 20, width: '100%', height: '80%' },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
  };
}

export default async function () {
  let currency_formatter: UnknownObject;

  const draw_mrr_chart = function (raw_data: UnknownArray, div_target: string) {
    const min_value = raw_data[1][1];
    const max_value = raw_data[raw_data.length - 1][1];
    window.mk_simulator.label_final_mrr = max_value;

    const data = GoogleCharts.api.visualization.arrayToDataTable(raw_data);
    currency_formatter.format(data, 1);

    const chart = new GoogleCharts.api.visualization.AreaChart(
      document.getElementById(div_target)
    );
    chart.draw(data, options_charts(min_value, max_value));
  };

  const draw_mrr_breakdown_chart = function (
    raw_data: UnknownArray,
    div_target: string
  ) {
    // eslint-disable-next-line
    window.mk_simulator.label_churn_amount = raw_data[raw_data.length - 1][4];

    const data = GoogleCharts.api.visualization.arrayToDataTable(raw_data);
    currency_formatter.format(data, 1);
    currency_formatter.format(data, 2);
    currency_formatter.format(data, 3);
    currency_formatter.format(data, 4);

    const chart = new GoogleCharts.api.visualization.ComboChart(
      document.getElementById(div_target)
    );
    chart.draw(data, options_breakdown());
  };

  const draw_mrr_with_goal = function (
    raw_data: UnknownArray,
    raw_data_goal: UnknownArray,
    div_target: string
  ) {
    const min_value =
      raw_data[1][1] < raw_data_goal[1][1]
        ? raw_data[1][1]
        : raw_data_goal[1][1];
    const max_value =
      raw_data[raw_data.length - 1][1] >
      raw_data_goal[raw_data_goal.length - 1][1]
        ? raw_data[raw_data.length - 1][1]
        : raw_data_goal[raw_data_goal.length - 1][1];

    // eslint-disable-next-line
    window.mk_simulator.label_final_mrr_simulated =
      raw_data_goal[raw_data_goal.length - 1][1];

    const combined_raw_data = raw_data;
    combined_raw_data[0] = [
      'Months',
      'MRR with current churn',
      'MRR with desired churn',
    ];

    for (let i = 1; i < raw_data.length; i += 1) {
      combined_raw_data[i].push(raw_data_goal[i][1]);
    }

    const data = GoogleCharts.api.visualization.arrayToDataTable(
      combined_raw_data
    );
    currency_formatter.format(data, 1);
    currency_formatter.format(data, 2);

    const chart = new GoogleCharts.api.visualization.AreaChart(
      document.getElementById(div_target)
    );
    chart.draw(data, options_mrr(min_value, max_value));
  };

  window.mk_simulator = window.mk_simulator || {};

  function drawVisualization() {
    window.mk_simulator = window.mk_simulator || {};

    const elements_to_listen_to = [
      '#starting_MRR',
      '#revenue_growth',
      '#churn',
      '#upsell',
      '#projection_time',
      '#simulated_churn',
    ];

    currency_formatter = new GoogleCharts.api.visualization.NumberFormat({
      pattern: '$#,###',
    });

    const calculator = new Calculator();
    calculator.compute(
      parseFloat($(elements_to_listen_to[0]).val() as string),
      parseFloat($(elements_to_listen_to[1]).val() as string),
      ($(elements_to_listen_to[2]).val() as number) / 100,
      ($(elements_to_listen_to[3]).val() as number) / 100,
      parseFloat($(elements_to_listen_to[4]).val() as string)
    );

    const calculator_goal = new Calculator();
    calculator_goal.compute(
      parseFloat($(elements_to_listen_to[0]).val() as string),
      parseFloat($(elements_to_listen_to[1]).val() as string),
      ($(elements_to_listen_to[5]).val() as number) / 100,
      ($(elements_to_listen_to[3]).val() as number) / 100,
      parseFloat($(elements_to_listen_to[4]).val() as string)
    );

    draw_mrr_chart(calculator.mrr, 'chart_div_mrr');
    draw_mrr_breakdown_chart(
      calculator.mrr_breakdown,
      'chart_div_mrr_breakdown'
    );
    draw_mrr_with_goal(
      calculator.mrr,
      calculator_goal.mrr,
      'chart_div_mrr_goal'
    );
  }

  await GoogleCharts.load(drawVisualization);
}
