import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import Swal from 'sweetalert2';
import app from '../app';
import { BackboneViewProps } from '../types';
import Tenant from './model_tenant_admin';

const template = require('./api_usage_admin.pug');
const template_403 = require('../errors/403.pug');

const DEFAULT_QUOTA = {
  demographics: {},
  predictions: {},
};

const ApiUsageAdminView = (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_save': 'event_save',
    'click #btn_reset_usage': 'event_reset_usage',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${this.tenant}/quota`
    );
    this.model = new Tenant({ tenant: this.tenant });
    const quota = data || DEFAULT_QUOTA;
    const modelQuota = {
      demographics_requests_per_month:
        quota.demographics && quota.demographics.requests_per_month,
      predictions_requests_per_month:
        quota.predictions && quota.predictions.requests_per_month,
      requests_per_minute: quota.requests_per_minute,
    };
    this.model.set(modelQuota);
    const quotaUsage = await axios.get(
      `${BONGO_URL}/v1/org/${this.tenant}/quota/usage`
    );
    const usage: UnknownObject = {
      demographics_this_month:
        quotaUsage.data.demographics && quotaUsage.data.demographics.this_month,
      predictions_this_month:
        quotaUsage.data.predictions && quotaUsage.data.predictions.this_month,
      this_minute: quotaUsage.data.this_minute,
    };
    // calculate usage percentage
    usage.demographics_this_month_percent = modelQuota.demographics_requests_per_month
      ? Math.round(
          (100 * usage.demographics_this_month) /
            modelQuota.demographics_requests_per_month
        )
      : 0;
    usage.predictions_this_month_percent = modelQuota.predictions_requests_per_month
      ? Math.round(
          (100 * usage.predictions_this_month) /
            modelQuota.predictions_requests_per_month
        )
      : 0;
    usage.this_minute_percent = modelQuota.requests_per_minute
      ? Math.round((100 * usage.this_minute) / modelQuota.requests_per_minute)
      : 0;

    // build text
    usage.demographics_this_month_percent_text = `${usage.demographics_this_month_percent} %`;
    usage.predictions_this_month_percent_text = `${usage.predictions_this_month_percent} %`;
    usage.this_minute_percent_text = `${usage.this_minute_percent} %`;

    this.model.set(usage);
    this.render(usage);
  },

  render(usage: {}) {
    const html = $('#tpl_admin', template(usage)).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },

  async event_reset_usage(e: any) {
    e.preventDefault();
    try {
      await axios.post(`${BONGO_URL}/v1/org/${this.tenant}/quota/usage/reset`);
      app.notifications.success('Quota usage reset!', {
        dismiss_timeout: 2000,
      });
      this.initialize({
        tenant: this.tenant,
      });
    } catch (err) {
      Swal.fire('Oops!', 'We were unable to reset the quota usage', 'error');
    }
  },

  async event_save(e: any) {
    e.preventDefault();
    const demographicsRequestsPerMonth = this.model.get(
      'demographics_requests_per_month'
    );
    const predictionsRequestsPerMonth = this.model.get(
      'predictions_requests_per_month'
    );
    const requestsPerMinute = this.model.get('requests_per_minute');
    const quota: UnknownObject = {
      demographics: {},
      predictions: {},
    };

    // we save demographics limit only if there is a value
    if (demographicsRequestsPerMonth !== '') {
      quota.demographics.requests_per_month = demographicsRequestsPerMonth;
    }
    // we save predictions limit only if there is a value
    if (predictionsRequestsPerMonth !== '') {
      quota.predictions.requests_per_month = predictionsRequestsPerMonth;
    }
    // we save the number of authorized requests per minutes only if there is a value
    if (requestsPerMinute !== '') {
      quota.requests_per_minute = requestsPerMinute;
    }

    try {
      await axios.post(`${BONGO_URL}/v1/org/${this.tenant}/quota`, quota);
      app.notifications.success('Settings updated', { dismiss_timeout: 2000 });
      this.initialize({
        tenant: this.tenant,
      });
    } catch (err) {
      Swal.fire(
        'Oops!',
        "There was an error saving the quota. We're looking into it!",
        'error'
      );
    }
  },
});

export default Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.admin_view = new ApiUsageAdminView({ tenant: this.model.get('_id') });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(this.admin_view, '#tenant_info');
    return this;
  },
});
