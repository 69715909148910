import React from 'react';
import {
  Button,
  FormControl,
  ListGroup,
  ListGroupItem,
  Table,
} from 'react-bootstrap';
import {
  ActiveConnectorDataModel,
  ConversionMappingData,
  ConversionMappingError,
} from '../../../models/ConversionMappingData';
import {
  ConnectorsNames,
  ConversionMappingConnectorsNames,
  MappingConversionsTypes,
} from '../../../../../types';
import { displayConditionFormComponent, launchSwal } from './swalManager';
import { unslugFieldName } from '../../../../pullConfigManager';
import StripeFiltersForm from '../../../models/filters_forms/StripeFiltersForm';
import { DefaultFiltersForm } from '../../../models/filters_forms/DefaultFiltersForm';
import { AnalyticsFiltersForm } from '../../../models/filters_forms/AnalyticsFiltersForm';
import { ConversionTypeData } from '../../../models/ConversionTypeData';
import { ConversionTypesGroup } from '../../../models/ConversionTypesGroup';
import MadMlEditModeComponent from '../../../../madMlMapping/reactComponents/MadMlEditModeComponent';
import MappingsMode from '../../../../enums/MappingsMode';
import {
  conversionTypeCodeColor,
  DEFAULT_CONVERSION_CUSTOM_DEFINITION_NAME,
} from '../../../utils';
import MissingFieldMicrocopy from '../../../../../components/ErrorMicroCopyComponent';

type ConversionTypeCardsComponentProps = {
  tenant: number;
  editable: boolean;
  errors: ConversionMappingError[];
  conversionMappingData: ConversionMappingData;
  conversionTypesGroups: ConversionTypesGroup[];
  activeConnectors: ActiveConnectorDataModel[];
  handlerFunctions: any;
  activeIntegrations: ConversionMappingConnectorsNames[];
};

export default class ConversionTypeCardsComponent extends React.Component<
  ConversionTypeCardsComponentProps,
  {}
> {
  conversionTypeDataIndex: number;

  conversionMappingData: ConversionMappingData;

  constructor(props: ConversionTypeCardsComponentProps) {
    super(props);
    const { conversionMappingData } = this.props;
    conversionMappingData.conversionTypesGroups = this.props.conversionTypesGroups;
    this.conversionMappingData = conversionMappingData;
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.displayLogosSqo = this.displayLogosSqo.bind(this);
    this.displayLogosOpenOpp = this.displayLogosOpenOpp.bind(this);
  }

  componentDidUpdate() {
    const {
      conversionMappingData,
      errors,
      editable,
      handlerFunctions,
      activeIntegrations,
      conversionTypesGroups,
    } = this.props;
    conversionMappingData.conversionTypesGroups = conversionTypesGroups;
    this.conversionMappingData = conversionMappingData;
    displayConditionFormComponent(
      null,
      editable,
      this.conversionTypeDataIndex,
      this.conversionMappingData.getConversionTypeByIndex(
        this.conversionTypeDataIndex
      ),
      handlerFunctions,
      activeIntegrations,
      errors
    );
  }

  handleClose() {
    this.setState({
      showSalesforcePopup: false,
    });
  }

  handleShow(
    conversionTypeDataIndex: number,
    conversionTypeData: ConversionTypeData
  ) {
    const {
      handlerFunctions,
      activeIntegrations,
      editable,
      errors,
    } = this.props;
    this.conversionTypeDataIndex = conversionTypeDataIndex;
    launchSwal(
      conversionTypeDataIndex,
      conversionTypeData,
      handlerFunctions,
      activeIntegrations,
      editable,
      errors
    );
  }

  getDefaultDefinitionRow(
    conversionTypeData: ConversionTypeData,
    connectorName: ConnectorsNames,
    type: MappingConversionsTypes,
    conversionTypeDataIndex: number,
    keyIndex: number
  ) {
    const { errors } = this.props;
    const filterForm = conversionTypeData.filtersForm as DefaultFiltersForm;
    if (filterForm.parameters.length > 0) {
      const concernedErrors = errors.filter(
        ({ conversionTypeIndex, connector }) =>
          connector === connectorName &&
          conversionTypeIndex === conversionTypeDataIndex
      );
      return (
        <div>
          {
            <ListGroup className="list-group-flush" key={keyIndex}>
              {filterForm.parameters &&
              !this._checkNullFullArray(filterForm.parameters) ? (
                filterForm.parameters.map((parameter, index) => {
                  const parameterHasError = concernedErrors.some(
                    (error) => error.parameterIndex === index
                  );
                  return (
                    <div key={keyIndex}>
                      {parameter && (
                        <ListGroupItem
                          key={`list_${connectorName}_${index}_conversion_type_index_${conversionTypeDataIndex}`}
                          className="pl-0"
                        >
                          <div className="row align-items-center">
                            <div className="col-sm-10">
                              <p
                                className={`${
                                  parameterHasError
                                    ? 'text-danger'
                                    : 'text-dark'
                                }`}
                              >
                                {`${parameter.table}.${unslugFieldName(
                                  parameter.field
                                )} ${parameter.verb} ${parameter.values}`}
                              </p>
                            </div>
                            <div className="col-sm-2">
                              <Button
                                bsStyle="primary"
                                onClick={() =>
                                  this.handleShow(
                                    conversionTypeDataIndex,
                                    conversionTypeData
                                  )
                                }
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        </ListGroupItem>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="text-center">
                  <Button
                    bsStyle="primary"
                    onClick={() =>
                      this.handleShow(
                        conversionTypeDataIndex,
                        conversionTypeData
                      )
                    }
                  >
                    +
                  </Button>
                </div>
              )}
            </ListGroup>
          }
        </div>
      );
    }
    return (
      <div className="text-center">
        <Button
          bsStyle="primary"
          onClick={() =>
            this.handleShow(conversionTypeDataIndex, conversionTypeData)
          }
        >
          +
        </Button>
      </div>
    );
  }

  _checkNullFullArray(array: UnknownObject[]) {
    const filtredArray = array
      ? array.filter((element) => {
          return element === null;
        })
      : [];
    return array.length === filtredArray.length;
  }

  getStripeDefinitionRow(
    conversionTypeData: ConversionTypeData,
    conversionTypeDataIndex: number,
    keyIndex: number
  ) {
    const {
      sqoAmountThreshold,
    } = conversionTypeData.filtersForm as StripeFiltersForm;
    return (
      <ListGroup className="list-group-flush" key={keyIndex}>
        <ListGroupItem className="pl-0">
          <div className="row align-items-center">
            <div className="col-sm-10">
              <p>{`Stripe Sqo Amount threshold: ${sqoAmountThreshold}`}</p>
            </div>
            <div className="col-sm-2">
              <Button
                bsStyle="primary"
                onClick={() =>
                  this.handleShow(conversionTypeDataIndex, conversionTypeData)
                }
              >
                +
              </Button>
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    );
  }

  getAnalyicsDefinitionRow(
    conversionTypeData: ConversionTypeData,
    conversionTypeDataIndex: number,
    keyIndex: number
  ) {
    const { errors } = this.props;
    const {
      sourceSystem,
      conversionEvents,
    } = conversionTypeData.filtersForm as AnalyticsFiltersForm;
    const concernedErrors = errors.filter(
      ({ conversionTypeIndex, connector }) =>
        connector === 'analytics' &&
        conversionTypeIndex === conversionTypeDataIndex
    );
    const hasSourceSystemsErrors = concernedErrors.some(
      ({ level }) => level === 'empty source systems'
    );
    const hasConversionEventsErrors = concernedErrors.some(
      ({ level }) => level === 'empty conversion events'
    );

    return (
      <ListGroup className="list-group-flush" key={keyIndex}>
        <ListGroupItem className="pl-0">
          <div className="row align-items-center">
            <div className="col-sm-10">
              <p
                className={`${
                  hasSourceSystemsErrors ? 'text-danger' : 'text-dark'
                }`}
              >{`Source system: ${sourceSystem}`}</p>
            </div>
            <div className="col-sm-2">
              <Button
                bsStyle="primary"
                onClick={() =>
                  this.handleShow(conversionTypeDataIndex, conversionTypeData)
                }
              >
                +
              </Button>
            </div>
          </div>
        </ListGroupItem>
        <ListGroupItem className="pl-0">
          <div className="row align-items-center">
            <div className="col-sm-10">
              <p
                className={`${
                  hasConversionEventsErrors ? 'text-danger' : 'text-dark'
                }`}
              >{`Conversion event: ${conversionEvents}`}</p>
            </div>
            <div className="col-sm-2">
              <Button
                bsStyle="primary"
                onClick={() =>
                  this.handleShow(conversionTypeDataIndex, conversionTypeData)
                }
              >
                +
              </Button>
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    );
  }

  displayLogosSqo(connector: ConnectorsNames) {
    if (connector !== 'analytics') {
      return (
        <div className="text-white mt-1">
          <img
            src={`/media/integrations/logos/${connector}.svg`}
            className="con-map-mk-integration-logo-size"
          />
        </div>
      );
    }
  }

  displayLogosOpenOpp(connector: ConnectorsNames) {
    if (connector === 'analytics') {
      return (
        <div className="text-white mt-1">
          <img
            src={`/media/integrations/logos/${connector}.svg`}
            className="con-map-mk-integration-logo-size"
          />
        </div>
      );
    }
  }

  renderDefinitionRow(
    conversionTypeDataIndex: number,
    conversionMappingTypeData: ConversionTypeData,
    keyIndex: number
  ) {
    switch (conversionMappingTypeData.connector) {
      case 'analytics':
        return this.getAnalyicsDefinitionRow(
          conversionMappingTypeData,
          conversionTypeDataIndex,
          keyIndex
        );
      case 'stripe':
        return this.getStripeDefinitionRow(
          conversionMappingTypeData,
          conversionTypeDataIndex,
          keyIndex
        );
      default:
        return this.getDefaultDefinitionRow(
          conversionMappingTypeData,
          conversionMappingTypeData.connector,
          conversionMappingTypeData.conversionType,
          conversionTypeDataIndex,
          keyIndex
        );
    }
  }

  renderConversionTypeConnectorBlock(
    conversionTypeDataIndex: number,
    conversionTypeData: ConversionTypeData,
    keyIndex: number
  ) {
    return (
      <tr>
        <td className="align-middle w-25">
          <div>
            <div className="text-white mt-1">
              <img
                src={`/media/integrations/logos/${conversionTypeData.connector}.svg`}
                key={`img_connector_${conversionTypeData.connector}_${conversionTypeDataIndex}`}
                className="con-map-mk-integration-logo-size"
              />
            </div>
          </div>
        </td>
        <td className="align-middle w-25">
          {conversionTypeData.filterTypesValues.join(',')}
        </td>
        <td className="align-middle w-25">
          {conversionTypeData.connector !== 'analytics' &&
            `${conversionTypeData.amountField.table}.${unslugFieldName(
              conversionTypeData.amountField.value
            )}`}
        </td>
        <td className="align-middle w-25">
          {this.renderDefinitionRow(
            conversionTypeDataIndex,
            conversionTypeData,
            keyIndex
          )}
        </td>
      </tr>
    );
  }

  renderNameInputOrDisplay(
    conversionGroupData: ConversionTypesGroup,
    conversionGroupDataIndex: number,
    keyIndex: number
  ) {
    if (conversionGroupData.tag === 'custom') {
      const { errors } = this.props;
      const hasError = errors.some(
        (error) =>
          error.level === 'custom conversion has no name' &&
          error.conversionTypeIndex === conversionGroupDataIndex
      );
      return (
        <>
          <FormControl
            key={`conversion_type_group_section_${conversionGroupDataIndex}_${keyIndex}`}
            type="text"
            value={
              conversionGroupData.name.toLowerCase() ===
              DEFAULT_CONVERSION_CUSTOM_DEFINITION_NAME
                ? ''
                : conversionGroupData.name
            }
            onChange={(e: any) =>
              this.props.handlerFunctions.handleCustomSqoBlockNameChange(
                e.target.value,
                conversionGroupDataIndex
              )
            }
            placeholder="name your conversion definition"
          />
          {hasError && (
            <MissingFieldMicrocopy microcopy="Please enter a valid name." />
          )}
        </>
      );
    }
    return <span>{conversionGroupData.name}</span>;
  }

  renderCardBody(conversionGroupData: ConversionTypesGroup) {
    if (conversionGroupData.isCustom) {
      return (
        <div>
          <MadMlEditModeComponent
            tenant={conversionGroupData.tenant}
            email={conversionGroupData.email}
            mappingMode={MappingsMode.conversion}
            goToDisplayMode={null}
            goBackToOverview={null}
            isReadOnly={!this.props.editable}
            scriptCheckerState={false}
            madMlSqlQuery={
              conversionGroupData.getCustomIndexedConversionType()
                ?.conversionTypeData.madMlSqlQuery
            }
            handleSqlQueryChange={(value: string) =>
              this.props.handlerFunctions.handleChangeMadMlSqlQuery(
                value,
                conversionGroupData.getCustomIndexedConversionType()?.index
              )
            }
          />
        </div>
      );
    }
    const notCustomConversionTypes = conversionGroupData.indexedConversionTypesData.filter(
      (indexedConversionTypeData) =>
        !indexedConversionTypeData.conversionTypeData.isCustom
    );

    return (
      <Table responsive className="table">
        <thead>
          <tr>
            <th className="pl-3">Defined Within</th>
            <th>Type Parameter</th>
            <th>Amount Field</th>
            <th>Definitions</th>
          </tr>
        </thead>
        <tbody>
          {notCustomConversionTypes.map((indexedConversionTypeData, index) => {
            return this.renderConversionTypeConnectorBlock(
              indexedConversionTypeData.index,
              indexedConversionTypeData.conversionTypeData,
              index
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderSections() {
    const availableConversionGroup = this.props.conversionMappingData.getConversionTypeGroupWithActiveConnectors();
    return (
      <div>
        <div>
          {availableConversionGroup.map((conversionGroupData, index) => {
            const isBlockCanBeDeleted = conversionGroupData.tag === 'custom';
            return (
              <div
                className="card box-shadow-3d mb-4"
                key={`${index}_conversion_group_card_${conversionGroupData.index}_type_${conversionGroupData.type}`}
              >
                <h4 className="card-header d-flex">
                  <div className="d-flex justify-content-center">
                    <span
                      className={`badge badge-${
                        conversionTypeCodeColor[conversionGroupData.type]
                      } con-map-mk-badge-size`}
                      style={{ height: '25px' }}
                    >
                      {conversionGroupData.type}
                    </span>
                    {conversionGroupData.isCustom && (
                      <span
                        className="badge badge-warning con-map-mk-badge-size ml-2"
                        style={{ height: '25px' }}
                      >
                        SCRIPTED
                      </span>
                    )}
                    <div className="ml-2 w-75">
                      {this.renderNameInputOrDisplay(
                        conversionGroupData,
                        conversionGroupData.index,
                        index
                      )}
                    </div>
                  </div>
                  {isBlockCanBeDeleted ? (
                    <div className="pull-right">
                      <Button
                        bsStyle="danger"
                        style={{ cursor: 'pointer' }}
                        disabled={!isBlockCanBeDeleted}
                        onClick={() =>
                          this.props.handlerFunctions.handleRemoveBlock(
                            conversionGroupData.index
                          )
                        }
                      >
                        <i
                          className="fas fa-trash"
                          style={{ pointerEvents: 'none' }}
                        />
                      </Button>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`customSwitch_${conversionGroupData.index}`}
                          onChange={() =>
                            this.props.handlerFunctions.handleSetIsCustom(
                              conversionGroupData.index
                            )
                          }
                          checked={conversionGroupData.isCustom}
                        ></input>
                        <label
                          className="custom-control-label my-auto"
                          htmlFor={`customSwitch_${conversionGroupData.index}`}
                        >
                          {conversionGroupData.isCustom ? 'MadML' : 'Standard'}
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                </h4>
                {this.renderCardBody(conversionGroupData)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderSections()}</div>;
  }
}
