import React from 'react';
import Swal from 'sweetalert2';
import { useBigQueryContext } from '../contexts/BigQueryContext';
import { loadBigQueryAccessTokens } from '../utils/loadBigQueryAccessTokens';

type Props = {
  nextStep: () => void;
};

export function GrantAccessStep({ nextStep }: Props) {
  const { tenant, credentials, setCredentials } = useBigQueryContext();

  const openAuthenticationWindow = async () => {
    try {
      const newTokens = await loadBigQueryAccessTokens({
        tenant,
      });
      setCredentials({
        ...credentials,
        tokens: newTokens,
      });
      nextStep();
    } catch (error) {
      await Swal.fire({
        text:
          'We were not able to save your credentials, notify our team at support@madkudu.com for us to look into it.',
      });
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="card box-shadow-3d mb-3">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <img
                  className="con-map-mk-integration-logo-size"
                  style={{ width: '30px' }}
                  src="/media/integrations/logos/bigquery-icon.png"
                />
                <h5 className="mb-0 ml-3">BigQuery</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <h5>Grant access</h5>
                  <p className="mt-3">
                    {credentials?.tokens
                      ? 'You can update your credentials in this step or keep the same ones by clicking on "Next" button'
                      : 'You need to grant access to your Google BigQuery account to proceed.'}
                  </p>
                </div>
                <div className="col-md-4">
                  <b className="d-block">Links</b>
                  <a
                    href="https://help.madkudu.com/docs/bigquery-1"
                    target="_blank"
                  >
                    How to connect BigQuery to MadKudu
                  </a>
                </div>
              </div>

              <button
                className="btn btn-outline-primary mt-3"
                onClick={openAuthenticationWindow}
              >
                <i className="fa fa-key mr-1"></i>
                {credentials?.tokens
                  ? 'Update connection'
                  : 'Grant access to Google BigQuery'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 pb-4 text-center">
        <button
          className="btn btn-primary"
          disabled={!credentials?.tokens}
          onClick={() => {
            nextStep();
          }}
        >
          Next
        </button>
      </div>
    </>
  );
}
