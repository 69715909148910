import * as React from 'react';
import ReactSelect, { Props } from 'react-select';

export const Select = (props: Props<any, boolean, any>) => {
  const { className = '', ...rest } = props;
  return (
    <ReactSelect
      className={`${className}`}
      unstyled
      isSearchable={false}
      classNames={{
        control: (state) =>
          `${
            state.isFocused
              ? 'tw-border-blue-600 tw-outline-none tw-ring-1 tw-ring-blue-600'
              : 'tw-border-gray-200'
          } ${
            state.isDisabled
              ? 'tw-border-gray-100 tw-bg-gray-100'
              : 'tw-bg-white'
          } tw-rounded-md tw-px-3 tw-border tw-text-sm tw-placeholder-gray-300`,
        menu: () =>
          'tw-border tw-mt-1 tw-border-gray-200 tw-bg-white tw-text-sm tw-overflow-hidden tw-rounded-md tw-shadow',
        option: ({ isSelected }) =>
          `${
            isSelected ? 'tw-bg-blue-500 tw-text-white' : 'hover:tw-bg-blue-100'
          } hover:tw-cursor-pointer tw-px-3 tw-py-2 tw-text-sm`,
      }}
      classNamePrefix="react-select"
      {...rest}
    />
  );
};
