import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { ZoomInfoCredentials } from '../types';

export function useChangeZoomInfoApiKey(
  tenant: number,
  options?: UseMutationOptions<void, unknown, ZoomInfoCredentials, unknown>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (credentials: ZoomInfoCredentials) => {
      // validate key
      try {
        const { data } = await axios.post<{ tested: boolean }>(
          `${BONGO_URL}/v1/org/${tenant}/integrations/zoominfo/ping`,
          credentials
        );

        if (!data.tested) throw Error();
      } catch (error) {
        throw Error('invalid-api-key');
      }

      await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/zoominfo/credentials`,
        credentials
      );
    },
    ...options,
    onSuccess: (data, variables, ctx) => {
      options?.onSuccess?.(data, variables, ctx);
      queryClient.setQueryData(['zoominfo', tenant], true);
    },
  });
}
