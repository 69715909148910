var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (app_id) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E\u003Cdiv id=\"mki_oauth\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"fields_mapping\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_oauth\"\u003E\u003Cp\u003EClick on the button below to authorize MadKudu in Intercom\u003C\u002Fp\u003E\u003Csmall\u003EThis will redirect you to Intercom, then bring you back here\u003C\u002Fsmall\u003E\u003Cdiv class=\"div m-t m-b-md\"\u003E\u003Cbutton class=\"btn btn-primary\" id=\"mki_oauth_btn\"\u003EConnect to Intercom\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_connected\"\u003E";
if (app_id) {
pug_html = pug_html + "\u003Cp\u003ESuccessfully connected via OAuth2 on workspace \u003Cb\u003E" + (pug.escape(null == (pug_interp = app_id) ? "" : pug_interp)) + "\u003C\u002Fb\u003E\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp\u003ESuccessfully connected via OAuth2\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cbutton class=\"btn btn-light\" id=\"mki_oauth_btn\"\u003EUpdate connection\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-danger ml-2\" id=\"mki_delete_connection\"\u003Edelete connection\u003C\u002Fbutton\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "app_id" in locals_for_with ?
        locals_for_with.app_id :
        typeof app_id !== 'undefined' ? app_id : undefined));
    ;;return pug_html;};
module.exports = template;