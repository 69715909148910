import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useLinkedinIsConnected(tenant: number) {
  return useQuery<boolean>({
    queryKey: ['linkedin', tenant],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/integrations/linkedin/ping`
      );

      return !!data?.valid;
    },
  });
}
