import React from 'react';
import Backbone from 'backbone';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import getActiveInsightsPages from '../../evr_insights/utils';
import { BackboneViewProps } from '../../types';
import { swalWithBootstrapButtons } from '../../integrations/configure/utils';
import { createAsanaTask, slackNotification } from '../../utils';
import { Homepage } from './Homepage';

const template = require('./homepage.pug');

function highlight(subclass: string) {
  $('.sidebar__nav *').removeClass('active');
  $(`li.sidebar__nav--${subclass}`).addClass('active');
}

export default Backbone.View.extend({
  events: {
    'click #goL1_Leadsource': 'goL1_Leadsource',
    'click #go_L2': 'goL2',
    'click #go_L3': 'goL3',
    'click #go_insights': 'go_insights',
    'click #go-integrations': 'go_integrations',
    'click #go-profiles': 'go_profiles',
    'click #go-predictions': 'go_predictions',
    'click #beta-access_L3': 'go_beta_L3',
    'click #access_L1_leadsource': 'go_L1_leadsource',
  },

  goL1_Leadsource() {
    Backbone.history.navigate(
      `/org/${this.tenant}/evr_insights/l1_leadsource`,
      true
    );
  },
  goL2() {
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l2`, true);
  },
  goL3() {
    Backbone.history.navigate(`/org/${this.tenant}/evr_insights/l3`, true);
  },
  go_predictions() {
    highlight('predictions');
    Backbone.history.navigate(`/org/${this.tenant}/predictions`, true);
  },
  go_insights() {
    highlight('insights');
    Backbone.history.navigate(`/org/${this.tenant}/evr`, true);
  },
  go_integrations() {
    highlight('integrations');
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  async go_beta_L3() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L3 Hand Raiser SLA`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  async go_L1_leadsource() {
    const slackAsanaSharedText = `${this.userEmail} from tenant ${this.tenant} is requesting the L1 Leadsource report`;
    await Promise.all([
      slackNotification(
        this.tenant,
        'insights',
        slackAsanaSharedText,
        'C01EZB01CEA'
      ),
      createAsanaTask(
        this.tenant,
        'insights',
        `${this.userEmail} from tenant ${this.tenant} is requesting access to L1 Leadsource report`,
        'Description: Insights report request'
      ),
    ]);

    return swalWithBootstrapButtons.fire({
      title: 'Request Sent!',
      html: 'We will reach out shortly',
      confirmButtonText: 'Return to main page',
      width: 600,
    });
  },

  go_profiles() {
    highlight('profiles');
    Backbone.history.navigate(`/org/${this.tenant}/profiles`, true);
  },

  async initialize(paramOptions: BackboneViewProps) {
    const options = paramOptions || {};
    this.tenant = options.tenant || this.tenant;
    this.activeInsightsPages = await getActiveInsightsPages(this.tenant);
    await this.render();
    this.renderProfileContainerComponent();
  },

  renderProfileContainerComponent() {
    ReactDOM.render(
      <Homepage tenant={this.tenant} />,
      $('#HomepageContainerComponent')[0]
    );
  },

  async render() {
    const html = template({
      activeInsightsPages: this.activeInsightsPages,
      tenant: this.tenant,
    });
    await this.$el.html(html);
    return this;
  },
});
