import React from 'react';
import { DatePicked } from '../../types';

type SegmentTimeframeProps = {
  datePicked: DatePicked;
  handleSegmentTimeFrameChange: Function;
};

export default class SegmentTimeframe extends React.Component<
  SegmentTimeframeProps
> {
  render() {
    const { datePicked, handleSegmentTimeFrameChange } = this.props;
    return (
      <div className="card">
        <div className="card-header">Records updated</div>
        <div className="card-body">
          <div className="row">
            <div className="col-auto">
              Records with a Segment event in the last
            </div>
            <div className="col-auto">
              <input
                type="text"
                className="form-control-segment-timeframe w-25 mr-3"
                id="segment-timeframe"
                value={
                  datePicked.segment
                    .score_contacts_active_last_X_days_segment as string
                }
                onChange={(e) => {
                  handleSegmentTimeFrameChange(e);
                }}
              />
              will be scored
            </div>
          </div>
          <div className="font-italic" style={{ fontSize: '12px' }}>
            Enter a value between 1 and 90
          </div>
        </div>
      </div>
    );
  }
}
