import axios from 'axios';
import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import CampaignsParent from './CampaignsParent';
import app from '../app';
import { isUserReadOnly } from '../utils';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_back_campaigns': 'goBackCampaigns',
  },

  async initialize(options: any) {
    this.campaignId = options.campaign_id;
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    const isReadOnly: boolean = isUserReadOnly(user);
    if (isReadOnly) {
      Backbone.history.navigate('error/403', true);
      return;
    }
    this.render();

    setTimeout(() => {
      this.renderCampaignParent();
    }, 0);
  },

  goBackCampaigns() {
    Backbone.history.navigate(`org/${this.tenant}/campaigns`, true);
  },

  openCampaign(id: string, isAdvanced: boolean = false) {
    Backbone.history.navigate(
      `org/${this.tenant}/${isAdvanced ? 'advanced_' : ''}campaigns/${id}`,
      true
    );
  },

  async createCampaign(isAdvanced: boolean = false) {
    try {
      const { data } = await axios.post(
        `${BONGO_URL}/v1/org/${this.tenant}/campaigns`
      );
      const url = `org/${this.tenant}/${
        isAdvanced ? 'advanced_' : ''
      }campaigns/${data._id}`;
      Backbone.history.navigate(url, true);
    } catch (err) {
      console.error(err);
    }
  },

  renderCampaignParent() {
    ReactDOM.render(
      <CampaignsParent
        campaignId={this.campaignId}
        createCampaign={this.createCampaign}
        openCampaign={this.openCampaign}
        goBackCampaigns={this.goBackCampaigns}
        tenant={this.tenant}
      />,
      $('#campaignsParent')[0]
    );
  },

  render() {
    const html = template();
    this.$el.html(html);

    return this;
  },
});
