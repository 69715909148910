import z from 'zod';

export type userRoles =
  | 'admin'
  | 'user'
  | 'architect'
  | 'superKudu'
  | 'salesUser';

export interface Permission {
  role: userRoles;
  tenant: number;
}

export type UserServiceModel = {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: userRoles;
  team: string;
  permissions: Permission[];
  superKudu?: boolean;
  active?: boolean;
  last_login_at?: string | null;
  resetPasswordExpires?: string | null;
  resetPasswordToken?: string | null;
};

export const teamSchema = z.object({
  _id: z.string(),
  tenant: z.number(),
  name: z.string(),
  ownerField: z.string(),
});

export const teamUpsertSchema = z.object({
  name: z.string().min(2).optional(),
  ownerField: z.string(),
});

export const teamsSchema = z.array(teamSchema);

export type TeamServiceModel = z.infer<typeof teamSchema>;
export type TeamUpsertServiceModel = Partial<z.infer<typeof teamUpsertSchema>>;
