import React from 'react';
import { ContainerWhite } from '../../components/ui';
import { PersonResult, PredictResult } from '../types';

type Props = {
  personResult?: PersonResult;
  predictResult?: PredictResult;
  isLoading: boolean;
};

export default function ProfileCard({
  personResult,
  predictResult,
  isLoading,
}: Props) {
  return (
    <ContainerWhite
      title="Person"
      className="tw-h-[257px]"
      isLoading={isLoading}
    >
      {!isLoading && personResult && predictResult && (
        <div className="tw-flex tw-gap-5">
          <span className="tw-flex tw-items-center tw-justify-center tw-h-20 tw-w-20 tw-shrink-0 tw-grow-0 tw-rounded-full tw-bg-gray-50 tw-border tw-border-gray-200 tw-overflow-hidden">
            {predictResult.person__avatar ? (
              <img
                className="tw-w-20 tw-h-20"
                src={predictResult.person__avatar}
              />
            ) : (
              <svg
                width="40"
                height="40"
                viewBox="0 0 46 45"
                fill="none"
                className="text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.6829 0.065033C16.5125 0.065033 11.5131 5.06664 11.5131 11.237C11.5131 17.4053 16.5126 22.4069 22.6829 22.4069C28.8512 22.4069 33.8528 17.4053 33.8528 11.237C33.8528 5.06658 28.8511 0.065033 22.6829 0.065033Z"
                  fill="currentColor"
                />
                <path
                  d="M17.8948 24.6417C8.20166 24.6417 0.341156 32.5001 0.341156 42.1954C0.341156 43.605 1.48474 44.7486 2.89432 44.7486H42.5008C43.8937 44.7486 45.0247 43.6175 45.0247 42.2248C45.0247 32.5128 37.1513 24.6416 27.4415 24.6416L17.8948 24.6417Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </span>

          <div className="tw-space-y-3">
            <span className="tw-text-xl tw-font-semibold">
              {personResult.properties.first_name}{' '}
              {personResult.properties.last_name}
            </span>
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
              <svg
                width="20"
                height="20"
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.1656 5.75497L16.2946 0.715207C16.3157 0.797884 16.3266 0.881999 16.3266 0.966835V10.543C16.3266 10.6278 16.3157 10.7119 16.2946 10.7946L11.1656 5.75497ZM1.15559 11.4166C1.27821 11.4784 1.41174 11.5101 1.54798 11.5101H15.4097C15.5684 11.5101 15.7244 11.4662 15.8621 11.3828L10.6499 6.2619L9.70431 7.19077C9.38003 7.50207 8.95494 7.67102 8.51691 7.66167C8.07616 7.66024 7.65175 7.48554 7.32612 7.17207L6.40032 6.26262L1.15559 11.4166ZM0.683497 0.644165C0.648074 0.747691 0.631042 0.856959 0.631042 0.966964V10.5431C0.631042 10.6531 0.648073 10.7624 0.683497 10.8659L5.8847 5.75493L0.683497 0.644165ZM15.4097 0H1.54798C1.41173 0 1.27821 0.0323515 1.15559 0.0941803L6.63826 5.48124L6.64031 5.48339L7.80522 6.62794V6.62866C8.00142 6.81702 8.25687 6.92126 8.52189 6.92126C8.78144 6.92845 9.03281 6.83068 9.22561 6.64806L10.4116 5.48411L10.4137 5.48195L15.8622 0.127292C15.7246 0.0438962 15.5684 0 15.4097 0Z"
                  fill="#132F4F"
                />
              </svg>
              <a
                href={`mailto:${personResult.email}`}
                className="tw-text-blue-700"
              >
                {personResult.email}
              </a>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
              <svg
                width="20"
                height="20"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.95566 0C3.48855 0 0.677429 2.68132 0.677429 5.98834C0.677429 9.29535 6.95566 16.7419 6.95566 16.7419C6.95566 16.7419 13.2339 9.29535 13.2339 5.98834C13.2339 2.68036 10.4228 0 6.95566 0ZM6.95566 8.00816C5.55456 8.00816 4.419 6.92503 4.419 5.58863C4.419 4.25223 5.55456 3.1691 6.95566 3.1691C8.35675 3.1691 9.49231 4.25223 9.49231 5.58863C9.49231 6.92503 8.35675 8.00816 6.95566 8.00816Z"
                  fill="#132F4F"
                />
              </svg>
              {predictResult.person__location || '-'}
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
              <svg
                width="20"
                height="20"
                viewBox="0 0 13 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4986 13.1042C12.4791 13.0054 12.4791 12.9065 12.4604 12.8278C12.4409 12.7089 12.4222 12.6101 12.4028 12.4919C12.0194 10.6738 10.9268 9.15212 9.43138 8.24255C10.3322 7.37322 10.9073 6.12793 10.9073 4.76358C10.9073 2.23351 8.97076 0.158228 6.5559 0.020073C6.44058 2.33882e-08 6.3642 0 6.26834 0C6.07664 0 5.88494 0.020073 5.69324 0.0393751C5.65505 0.0393751 5.59739 0.0594481 5.5592 0.0594481C5.40569 0.079521 5.25217 0.118896 5.09942 0.158271C5.07995 0.158271 5.04176 0.178344 5.02304 0.178344C4.63964 0.29724 4.27571 0.454733 3.94921 0.652379C2.58782 1.48233 1.66834 3.00397 1.66834 4.76362C1.66834 6.14714 2.24344 7.37235 3.14429 8.24259C1.246 9.36824 0 11.4435 0 13.8354V16.7419H12.5565V13.8354C12.537 13.5984 12.5181 13.3412 12.4986 13.1042ZM7.62977 13.42L6.32605 15.337L6.26839 15.4366L6.21073 15.3377L4.907 13.4207L5.76966 11.0288L4.83063 9.68466H7.70615L6.76712 11.0288L7.62977 13.42Z"
                  fill="#132F4F"
                />
              </svg>
              {predictResult.person__employment__title || '-'}
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-text-sm">
              <svg
                width="20"
                height="20"
                viewBox="0 0 21 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.50851 1.73463H14.4907V13.2159H6.50851V1.73463ZM0 15H21V13.8039H0V15ZM13.2028 1.14659V0H7.61577V1.14564L13.2028 1.14659ZM1.64529 3.98397H5.915V13.2157H1.64529V3.98397ZM3.01447 6.37518H4.54379V4.85747H3.01447V6.37518ZM3.01447 9.3497H4.54379V7.83199H3.01447V9.3497ZM3.01447 12.3242H4.54379V10.8037H3.01447V12.3242ZM15.0831 6.7681H19.3545V13.2158H15.0831V6.7681ZM16.4522 9.30878H17.9844V7.79107H16.4532V9.30878H16.4522ZM16.4522 12.4307H17.9844L17.9854 10.912H16.4532V12.4307H16.4522ZM11.3072 10.7978V12.3165H12.8375V10.7978H11.3072ZM8.16147 10.7978V12.3165H9.69078L9.69174 10.7978H8.16147ZM11.3072 8.20771V9.72637H12.8375V8.20866L11.3072 8.20771ZM8.16147 8.20771V9.72637H9.69078L9.69174 8.20866L8.16147 8.20771ZM11.3072 5.61758V7.13529H12.8375V5.61758H11.3072ZM8.16147 5.61758V7.13529H9.69078V5.61758H8.16147ZM11.3072 3.02745V4.54516H12.8375V3.02745H11.3072ZM8.16147 3.02745V4.54516H9.69078V3.02745H8.16147Z"
                  fill="#132F4F"
                />
              </svg>
              {personResult.company.properties.name || '-'}
            </div>
          </div>
        </div>
      )}
    </ContainerWhite>
  );
}
