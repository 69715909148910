import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import app from '../../../../app';
import { BackboneViewProps } from '../../../../types';
import { isUserReadOnly } from '../../../../utils';
import AttributeMappingManager from '../../models/AttributeMappingManager';
import SimpleConfigurationParentComponent from '../../components/simple/SimpleConfigurationParentComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_back': 'goBack',
    'click #show-salesforce-button': 'showSalesforce',
    'click #show-hubspot-button': 'showHubspot',
    'click #delete': 'delete',
  },

  async initialize(options: BackboneViewProps) {
    this.options = options;
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.superKudu = user.superKudu;
    this.email = user.email;
    this.isReadOnly = isUserReadOnly(user);

    await this.sleep(2000);

    const attributeMappingManager = new AttributeMappingManager(
      this.tenant,
      this.email,
      this.isReadOnly
    );

    await attributeMappingManager.init();

    this.attributeMappingManager = attributeMappingManager;

    this.hasClickedOnEdit = false;

    this.render();

    this.renderSimpleConfigurationParentComponent();
  },

  renderSimpleConfigurationParentComponent() {
    ReactDOM.render(
      <SimpleConfigurationParentComponent
        isReadOnly={this.isReadOnly}
        isSuperKudu={this.superKudu}
        tenant={this.tenant}
        lastPublisher={this.attributeMappingManager?.lastPublisher}
        publishedAt={moment(this.attributeMappingManager?.updatedAt).format(
          'lll'
        )}
        createdAt={moment(this.attributeMappingManager?.createdAt).format(
          'lll'
        )}
        shouldShowSalesforce={this.shouldShowSalesforce}
        shouldShowHubspot={this.shouldShowHubspot}
        attributeMappingManager={this.attributeMappingManager}
      />,
      $('#SimpleConfigurationParentComponentContainer')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
