'use client';

import React from 'react';
import * as TooltipRadix from '@radix-ui/react-tooltip';

interface Props {
  children: React.ReactNode;
  text: string | React.ReactNode;
  position?: 'top' | 'bottom' | 'right' | 'left';
  avoidCollisions?: boolean;
  offset?: number;
  delay?: number;
  visible?: boolean;
  maxWidth?: number;
}

export const Tooltip = React.forwardRef<HTMLDivElement, Props>(function Tooltip(
  props: Props,
  ref
) {
  const {
    children,
    text,
    position = 'top',
    offset = 5,
    delay = 20,
    visible = true,
    avoidCollisions = true,
    maxWidth = 360,
  } = props;
  return (
    <TooltipRadix.Provider delayDuration={delay}>
      <TooltipRadix.Root>
        <TooltipRadix.Trigger asChild>{children}</TooltipRadix.Trigger>
        <TooltipRadix.Portal>
          <TooltipRadix.Content
            ref={ref}
            className={`${visible ? 'tw-visible' : 'tw-hidden'} 
            tw-leading-relaxed tw-max-w-[${maxWidth}px] data-[state=delayed-open]:data-[side=top]:tw-animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:tw-animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:tw-animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:tw-animate-slideUpAndFade tw-text-violet11 tw-z-50 tw-select-none tw-rounded-[4px] tw-bg-dark tw-px-4 py-2 tw-text-xs text-white tw-will-change-[transform,opacity]`}
            side={position}
            sideOffset={offset}
            avoidCollisions={avoidCollisions}
            style={{ zIndex: 9999 }}
          >
            {text}
            <TooltipRadix.Arrow className="tw-fill-blue-900" />
          </TooltipRadix.Content>
        </TooltipRadix.Portal>
      </TooltipRadix.Root>
    </TooltipRadix.Provider>
  );
});
