import React, { Component } from 'react';

export default class PredictedLeadValueComponent extends Component<{}, {}> {
  render() {
    return (
      <div className="col-4">
        <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
          <div className="card-body">
            <i className="far fa-compass fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>Guide: </strong>
              Predicted Lead Value
            </h5>
            <div className="border-top mt-2 border-primary"></div>
            <i className="fas fa-flask fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>How MadKudu Computes This:</strong>
            </h5>
            <p>
              <strong>Predicted Value:</strong> looks at the value of a lead
              before they have reached the end of the funnel (aka made a
              purchase), based on the historical value of similar leads who have
              made the purchase. Thus, the computation is: average conversion
              rate * average deal size for each customer fit segment. Average
              deal size will have to be based on Closed Won deals, since we are
              computing the value when they actually made a purchase, not if
              they simply just open an opportunity.
            </p>
            <h5 className="mt-2">
              <strong>MadKudu Tip:</strong>
            </h5>
            <p>
              This is the first step to putting a dollar value on your
              top-of-funnel leads. What this means is that you can predict how
              much a lead is worth before they actually convert into a paying
              customer, and this can help you quantify your marketing pipeline
              in order to measure success.{' '}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
