import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import AbstractMadMlMapping from '../AbstractMadMlMapping';
import MadMlMappingFactory from '../MadMlMappingFactory';
import MappingsMode from '../../enums/MappingsMode';

require('codemirror/mode/sql/sql');

type MadMlDisplayModeComponentProps = {
  tenant: number;
  email: string;
  mappingMode: MappingsMode;
  madMalSqlQuery?: string;
};

type MadMlDisplayModeComponentState = {
  madMalSqlQuery: string;
  error: boolean;
  errorMessage: string;
};

export default class MadMlDisplayModeComponent extends React.Component<
  MadMlDisplayModeComponentProps,
  MadMlDisplayModeComponentState
> {
  madMlMapping: AbstractMadMlMapping;

  constructor(props: MadMlDisplayModeComponentProps) {
    super(props);
    this.state = {
      madMalSqlQuery: this.props.madMalSqlQuery || '',
      error: false,
      errorMessage: '',
    };
  }

  async componentDidMount() {
    const { tenant, email, mappingMode } = this.props;
    const madMlMappingFactory = new MadMlMappingFactory();

    this.madMlMapping = await madMlMappingFactory.createMadMlMapping(
      tenant,
      email,
      mappingMode
    );
    const madMalSqlQuery = await this.madMlMapping.getMadMlData();
    this.setState({
      madMalSqlQuery,
    });
  }

  render() {
    const { madMalSqlQuery } = this.state;

    return (
      <div>
        <div
          className="card box-shadow-3d mb-4 mt-4"
          style={{ height: '500px' }}
        >
          <div className="card-header">
            <h4 className="mb-0">MadML Custom Query Input</h4>
          </div>
          <div className="card-body h-100">
            <CodeMirror
              value={madMalSqlQuery}
              className="w-100 h-100"
              options={{
                mode: 'sql',
                lineNumbers: true,
                readOnly: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
