import React from 'react';
import TablePullDisplayComponent from './TablePullDisplayComponent';
import { ConnectorFieldNameDataModel } from '../../mapping/models/ConnectorFieldNameDataModel';

type PullSectionComponentProps = {
  pullFields: ConnectorFieldNameDataModel[];
  tenant: number;
  integration: string;
  credentialsFound: boolean;
  renderPullButton: Function;
};

export default class PullSectionComponent extends React.Component<
  PullSectionComponentProps
> {
  render() {
    const {
      integration,
      pullFields,
      credentialsFound,
      renderPullButton,
    } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="card box-shadow-3d mt-md-2 d-flex w-75">
          <div className="card-header">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-2 justify-content-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src={`/media/integrations/logos/${`${integration}-icon`}.png`}
                    />
                  </div>
                  <div className="col-10 my-auto">
                    <h5 className="text-capitalize mb-0"> {integration}</h5>
                  </div>
                </div>
              </div>
              <div className="col-7 my-auto">
                {credentialsFound ? (
                  <div className="ml-1 my-auto d-flex justify-content-between flex-row float-right">
                    <div className="success-div rounded-circle mr-1"></div>
                    <span>
                      Pulling data from{' '}
                      <span className="text-capitalize"> {integration}</span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-body">
            {!credentialsFound && (
              <React.Fragment>
                <div className="row">
                  <div className="col">
                    <p>
                      Send{' '}
                      <span className="text-capitalize">{integration} </span>
                      data to MadKudu
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">{renderPullButton()}</div>
                </div>
              </React.Fragment>
            )}
            {credentialsFound && pullFields && pullFields.length > 0 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col mb-2">
                    These are the important fields we gather from your users to
                    enable us to score your leads.
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TablePullDisplayComponent
                      pullFields={pullFields}
                      integration={integration}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
