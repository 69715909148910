import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faPipe } from '@fortawesome/pro-light-svg-icons/faPipe';
import {
  ConversionTree,
  SegmentsColors,
  ModelSubset,
  EnrichmentCategory,
  Segment,
  Computation,
} from '../../../../types';
import TreeCard from './TreeCard';
import { Tooltip } from '../../../../../components/ui';
import OverrideDiagnosis from './OverrideDiagnosis';

type Props = {
  personProperties: Record<string, any>;
  conversionTrees: ConversionTree[];
  treesWeights: { [key: string]: number };
  treeOverview: {
    conversionRateForAllTrees: number;
    segmentForAllTrees: Segment;
  };
  setTree: Dispatch<SetStateAction<number>>;
  tenant: number;
  model: ModelSubset;
  computations: Computation[];
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
};

export default function ConversionPath({
  personProperties,
  conversionTrees,
  treesWeights,
  treeOverview,
  setTree,
  tenant,
  model,
  computations,
  setSearch,
  setSelectedCategory,
}: Props) {
  const angles = {
    0: {
      1: {
        path: 'tw-rotate-0 !-tw-right-[8rem] !tw-w-[8rem]',
        weight: 'tw-rotate-0',
      },
      2: {
        path: 'tw-rotate-23 !-tw-right-[8rem] !tw-w-[8rem]',
        weight: '-tw-rotate-23',
      },
      3: { path: 'tw-rotate-40', weight: '-tw-rotate-40 -tw-ml-5' },
    },
    1: {
      1: { path: 'tw-rotate-0', weight: 'tw-rotate-0' },
      2: {
        path: '-tw-rotate-23 !-tw-right-[8rem] !tw-w-[8rem]',
        weight: 'tw-rotate-23',
      },
      3: {
        path: 'tw-rotate-0 !-tw-right-[6.5rem] !tw-w-[6.5rem]',
        weight: 'tw-rotate-0',
      },
    },
    2: {
      1: { path: 'tw-rotate-0', weight: 'tw-rotate-0' },
      2: { path: 'tw-rotate-0', weight: 'tw-rotate-0' },
      3: { path: '-tw-rotate-40', weight: 'tw-rotate-40 -ml-5' },
    },
  };

  const totalTrees = conversionTrees.filter(
    (conversionTree: ConversionTree) => treesWeights[conversionTree.index] > 0
  ).length as 1 | 2 | 3;

  const hasAnOverride = !!personProperties?.diagnosis?.overrideDiagnosis
    ?.length;

  return (
    <>
      <div className="tw-ml-[4rem] tw-mt-8 tw-leading-loose">
        <div className="tw-text-xl tw-font-bold">Overview</div>
        <span className="tw-text-sm tw-leading-relaxed">
          Score profile has been calculated thanks to{' '}
          <b>
            {totalTrees} decision tree{totalTrees > 1 ? 's' : ''}
          </b>{' '}
          based on historical data.
          {hasAnOverride && (
            <div>An override has been applied on the result.</div>
          )}
        </span>
      </div>
      <div className="tw-relative tw-pt-8 tw-pb-28 tw-overflow-auto visible-scrollbar">
        <div className="min-w-full tw-flex tw-flex-nowrap">
          <div className="tw-relative tw-ml-auto tw-min-w-[4rem]"></div>

          <div className="tw-relative tw-flex">
            <div className="tw-absolute tw-cursor-default tw-left-0 tw-right-2 -tw-bottom-20 tw-text-center tw-border-t tw-border-gray-400 tw-py-2 tw-text-sm tw-font-semibold">
              <Tooltip
                maxWidth={260}
                text={`MadKudu analyzes your historical data to predict how this lead should get scored looking at populations who convert better than others. You can click in the decistion tree to get more details.`}
                position="bottom"
              >
                <span>
                  Historical scoring
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    size="lg"
                    className="tw-ml-2"
                  />
                </span>
              </Tooltip>
              <FontAwesomeIcon
                icon={faPipe}
                size="sm"
                className="tw-absolute tw-left-0 -tw-top-[6px] tw-text-gray-400"
              />
              <FontAwesomeIcon
                icon={faPipe}
                size="sm"
                className="tw-absolute tw-right-0 -tw-top-[6px] tw-text-gray-400"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-7 justify-center">
              {conversionTrees
                .filter(
                  (conversionTree: ConversionTree) =>
                    treesWeights[conversionTree.index] > 0
                )
                .map((conversionTree: ConversionTree, index: number) => (
                  <div className="tw-flex tw-grow tw-items-center" key={index}>
                    <TreeCard
                      setTree={setTree}
                      conversionTree={conversionTree}
                      tenant={tenant}
                      model={model}
                    />
                    <div
                      className={`${
                        totalTrees === 1 ? 'tw-hidden' : ''
                      } tw-relative tw-flex tw-min-w-[10rem] tw-items-center tw-justify-center`}
                    >
                      <div className="tw-absolute tw-bottom-0 tw-top-0 tw-left-0 tw-right-0 tw-m-auto tw-h-[1px] tw-border-t-2 tw-border-dashed tw-border-white"></div>
                      <div
                        className={`tw-absolute tw-bottom-0 tw-top-0 -tw-right-[10rem] tw-m-auto tw-flex tw-h-[1px] tw-w-[10rem] tw-origin-top-left tw-translate-x-1 tw-items-center tw-justify-center tw-border-t-2 tw-border-dashed tw-border-white ${
                          angles[index as 0 | 1 | 2][totalTrees].path
                        }`}
                      >
                        <Tooltip
                          maxWidth={260}
                          position="top"
                          text={`Tree ${conversionTree.index} has a weight of ${
                            treesWeights[conversionTree.index] * 100
                          }%`}
                        >
                          <span
                            className={`tw-inline-block tw-cursor-default ${
                              angles[index as 0 | 1 | 2][totalTrees].weight
                            } tw-rounded-sm tw-bg-[#436985] tw-px-2 tw-py-1 tw-text-[10px] tw-font-medium tw-leading-normal tw-text-white`}
                          >
                            {treesWeights[conversionTree.index] * 100}%
                          </span>
                        </Tooltip>

                        <FontAwesomeIcon
                          icon={faCaretRight}
                          size="xl"
                          className="tw-absolute -tw-right-1 -tw-top-[2px] tw-bottom-0 tw-m-auto tw-text-white"
                        />
                      </div>
                      <span className="tw-absolute tw-bottom-0 tw-top-0 -tw-right-[9px] tw-m-auto tw-block tw-h-3 tw-w-3 tw-rounded-full tw-bg-white"></span>
                      <Tooltip
                        maxWidth={260}
                        position="top"
                        text={`Path for Decision tree ${
                          conversionTree.index
                        } resulted in a "
                          ${
                            conversionTree.paths[
                              conversionTree.paths.length - 1
                            ].segment
                          }" segment`}
                      >
                        <span
                          className={`tw-z-10 tw-cursor-default tw-whitespace-nowrap tw-rounded-md tw-bg-[${
                            SegmentsColors[
                              conversionTree.paths[
                                conversionTree.paths.length - 1
                              ].segment
                            ]
                          }] tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-capitalize tw-text-white tw-whitespace-nowrap`}
                        >
                          {
                            conversionTree.paths[
                              conversionTree.paths.length - 1
                            ].segment
                          }
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ))}
            </div>
            <div
              className={`tw-relative ${
                totalTrees === 1 ? 'tw-ml-[0]' : 'tw-ml-[8rem]'
              } tw-px-4 tw-flex tw-flex-col tw-gap-1 tw-items-center tw-justify-center`}
            >
              {hasAnOverride && (
                <>
                  <div className="tw-absolute tw-bottom-0 tw-top-0 -tw-right-[5rem] tw-m-auto tw-flex tw-h-[1px] tw-w-[5rem] tw-origin-top-left tw-translate-x-1 tw-items-center tw-justify-center  tw-border-t-2 tw-border-dashed tw-border-white">
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      size="xl"
                      className="tw-absolute -tw-right-1 -tw-top-[2px] tw-bottom-0 tw-m-auto tw-text-white"
                    />
                  </div>
                  <span className="tw-absolute tw-bottom-0 tw-top-0 -tw-right-[1rem] tw-m-auto tw-block tw-h-3 tw-w-3 tw-rounded-full tw-bg-white"></span>
                </>
              )}
              {!hasAnOverride && (
                <span className="tw-text-xl tw-font-bold tw-inline-block -tw-mt-8">
                  {personProperties.score}
                </span>
              )}

              <Tooltip
                maxWidth={260}
                position="top"
                text={`
                ${
                  totalTrees > 1
                    ? `After weighting the ${totalTrees} trees, the segment is "${
                        hasAnOverride
                          ? treeOverview.segmentForAllTrees
                          : personProperties.segment
                      }"`
                    : `There is only 1 decision tree which results a "${
                        hasAnOverride
                          ? treeOverview.segmentForAllTrees
                          : personProperties.segment
                      }" segment`
                }`}
              >
                <span
                  className={`tw-z-10 tw-cursor-default tw-whitespace-nowrap tw-rounded-md tw-bg-[${
                    SegmentsColors[
                      (hasAnOverride
                        ? treeOverview.segmentForAllTrees
                        : personProperties.segment) as
                        | 'low'
                        | 'medium'
                        | 'good'
                        | 'very good'
                    ]
                  }] tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-capitalize tw-text-white tw-whitespace-nowrap`}
                >
                  {hasAnOverride
                    ? treeOverview.segmentForAllTrees
                    : personProperties.segment}
                </span>
              </Tooltip>
            </div>
          </div>
          {hasAnOverride && (
            <OverrideDiagnosis
              personProperties={personProperties}
              tenant={tenant}
              model={model}
              segmentBeforeOverride={treeOverview.segmentForAllTrees}
              computations={computations}
              setSearch={setSearch}
              setSelectedCategory={setSelectedCategory}
            ></OverrideDiagnosis>
          )}
          <div className="tw-relative tw-mr-auto tw-min-w-[4rem]"></div>
        </div>
      </div>
    </>
  );
}
