import 'chartjs-plugin-datalabels';
import React from 'react';
import { ChartDefinition } from '../../InsightsTypes';

const BootstrapTable = require('react-bootstrap-table-next').default;
const filterFactory = require('react-bootstrap-table2-filter');
const paginationFactory = require('react-bootstrap-table2-paginator');
const ToolkitProvider = require('react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit')
  .default;

type TableComponentProps = {
  chartDefinition: ChartDefinition;
  data: UnknownArrayOfObjects;
};

export default class TableComponent extends React.Component<
  TableComponentProps,
  {}
> {
  render() {
    const { chartDefinition, data } = this.props;
    const columns = chartDefinition.columns.map((column) => {
      return {
        dataField: column.dataField,
        text: column.name,
        sort: true,
        headerStyle: {
          width: '150px',
          textAlign: 'center',
        },
        style: { whiteSpace: 'break-spaces' },
        filter: !column.columnFilter ? filterFactory.textFilter() : null,
        formatter: (value: any) => {
          switch (column.type) {
            case 'percentage': {
              const fixedValue = Number(value).toFixed(2);
              return `${fixedValue}%`;
            }
            case 'dollar': {
              const dollarFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              return dollarFormatter.format(value);
            }
            default:
              break;
          }
          if (column.decimalType) {
            return Number(value).toFixed(column.decimalType);
          }
          if (column.type !== 'url') {
            return value;
          }
          return (
            <a href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          );
        },
      };
    });
    const key = chartDefinition.columns.find((c) => c.key);
    const ExportCSV = (props: { onExport: () => any }) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div className="mb-2">
          <button className="btn btn-outline-secondary" onClick={handleClick}>
            Export to CSV
          </button>
        </div>
      );
    };

    return (
      <ToolkitProvider
        keyField={key ? key.name : chartDefinition.columns[0].name}
        data={data}
        columns={columns}
        exportCSV
      >
        {(props: UnknownObject) => (
          <div>
            <ExportCSV {...props.csvProps} />
            <div
              style={{
                position: 'relative',
                maxWidth: '100%',
                overflowX: 'scroll',
                display: 'block',
              }}
            >
              <BootstrapTable
                {...props.baseProps}
                keyField={key ? key.name : chartDefinition.columns[0].name}
                filter={filterFactory.default()}
                pagination={paginationFactory.default()}
                wrapperClasses="table-responsive"
                columns={columns}
                bodyStyle={{ whiteSpace: 'break-spaces !important' }}
                filterPosition="top"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}
