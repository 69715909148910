import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import app from '../../../app';
import EventMappingEditor from '../components/functional/EventMappingEditor';
import { isUserReadOnly } from '../../../utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_to_event_mapping': 'goEventMapping',
    'click #go_to_mapping': 'goMapping',
  },

  async initialize(options: { tenant: number; connector: string }) {
    this.tenant = options.tenant;
    this.session = app.session;
    this.connector = options.connector ? options.connector.trim() : '';
    const user = this.session.get('user');
    this.isReadOnly = isUserReadOnly(user);

    this.superKudu = user.superKudu;

    if (this.isReadOnly) {
      Backbone.history.navigate('error/403', true);
      return this;
    }

    this.email = user.email;
    this.render();
    setTimeout(() => this.displayComponent(), 0);
  },

  goMapping() {
    Backbone.history.navigate(`/org/${this.tenant}/mapping`, true);
  },

  goEventMapping() {
    Backbone.history.navigate(
      `/org/${this.tenant}/mapping/event_mapping`,
      true
    );
  },

  displayComponent() {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <EventMappingEditor
          isEventsEmpty={this.isEventsEmpty}
          email={this.email}
          tenant={this.tenant}
          connector={this.connector}
          superKudu={this.superKudu}
        />
      </QueryClientProvider>,
      $('#eventsContainer')[0]
    );
  },

  render() {
    const html = template({
      connector: `${this.connector[0].toUpperCase()}${this.connector
        .slice(1)
        .replace(/_/g, ' ')}`,
    });
    this.$el.html(html);

    return this;
  },
});
