import Backbone from 'backbone';
import $ from 'jquery';
import qs from 'qs';
import Swal from 'sweetalert2';
import app from '../app';
import amplitude from './amplitude';
import api from './api';
import apollo from './apollo/index';
import zoominfo from './zoomInfo/index';

import bigquery from './bigQuery/index';
import bulk from './bulk';
import clearbit from './clearbit/index';
import clearbit_reveal from './clearbitReveal';
import cognism from './cognism';
import configurePush from './configurePush';
import database from './database';
import eloqua from './eloqua';
import freshsales from './freshsales';
import github from './github';
import gong from './gong/index';
import hubspot from './hubspot';
import hull from './hull';
import identification from './identification/index';
import intercom from './intercom';
import { isIntegrationPullActive, isIntegrationPushActive } from './is_active';
import kissmetrics from './kissmetrics';
import linkedin from './linkedin';
import marketo from './marketo';
import mixpanel from './mixpanel';
import pipedrive from './pipedrive';
import predictleads from './predictleads';
import s3 from './s3/index';
import salesforce from './salesforce';
import salesforceiq from './salesforceiq';
import segment from './segment/index';
import slack from './slack';
import snowflake from './snowflake';
import stripe from './stripe';
import outreach from './outreach';
import salesloft from './salesloft';
import tag from './tag';
import test_connection from './test_connection';
import { getPredictLeadsActiveFlag } from './utils';
import zendesk from './zendesk';
import job_changes from './job_changes';

const template = require('./view_one.pug');

const Views: UnknownObject = {
  amplitude,
  api,
  clearbit,
  database,
  eloqua,
  freshsales,
  github,
  gong,
  hubspot,
  hull,
  intercom,
  tag,
  kissmetrics,
  linkedin,
  marketo,
  mixpanel,
  pipedrive,
  predictleads,
  apollo,
  s3,
  bigquery,
  salesforce,
  salesforceiq,
  snowflake,
  segment,
  slack,
  stripe,
  zendesk,
  configurePush,
  bulk,
  identification,
  clearbit_reveal,
  zoominfo,
  cognism,
  job_changes,
  outreach,
  salesloft,
};

export default Backbone.View.extend({
  events: {
    'click #go_back': 'event_back',
    'click #mki_admin': 'admin',
    'click #configure_integration': 'configure',
    'click #go_back_integration_board': 'go_back_integration_board',
    'click #test_button': 'test_connection',
  },

  async initialize(options: {
    tenant: number;
    integration: string;
    query_params: string | {};
  }) {
    this.tenant = options.tenant;
    this.user = app.session.user.attributes;
    this.isSuperKudu = this.user.superKudu;
    this.integration = options.integration;
    this.query_params =
      typeof options.query_params === 'string'
        ? qs.parse(options.query_params)
        : options.query_params || {};
    this.predictLeadsFlag = {};
    this.showConfigure = false;
    this.integrationActive = false;
    const [isPushActive, isPullActive] = await Promise.all([
      isIntegrationPushActive(this.tenant, this.integration),
      isIntegrationPullActive(this.tenant, this.integration),
    ]);
    this.integrationActive = isPushActive || isPullActive;
    this.predictLeadsFlag = getPredictLeadsActiveFlag(app.session.tenant.flags);

    this.isClearbitReveal = this.integration === 'clearbit_reveal';

    this.showConfigure =
      (this.integrationActive &&
        this.predictLeadsFlag &&
        this.integration === 'predictleads') ||
      (this.isClearbitReveal && this.isSuperKudu);
    this.render();
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();
    const html = $(
      '#tpl_main',
      template({
        tenant: this.tenant,
        integration: this.integration,
        showConfigure: this.showConfigure,
        user: this.user,
        isClearbitReveal: this.isClearbitReveal,
        integrationActive: this.integrationActive,
      })
    ).html();
    this.$el.html(html);
    const isSegment = this.integration === 'segment';
    const view = new Views[this.integration]({
      tenant: this.tenant,
      model: this.model,
      query_params: this.query_params,
      showConfigure: this.showConfigure,
      isSuperKudu: this.isSuperKudu,
      integrationActive: this.integrationActive,
      isSegment,
    });
    this.assign(view, '#integration');
    return this;
  },

  event_back(event: any) {
    event.preventDefault();
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_back_integration_board() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations/`, true);
  },

  admin(event: any) {
    event.preventDefault();
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/admin`,
      true
    );
  },

  configure() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/configure`,
      true
    );
  },

  async test_connection() {
    try {
      await test_connection(this.tenant, this.integration);
      Swal.fire({
        title: 'Tip top',
        text: 'The credentials are valid',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `Something went wrong for ${this.integration}, the following error did occur: ${error}`,
        icon: 'error',
      });
    }
  },
});
