import MappingsMode from '../enums/MappingsMode';
import AbstractMadMlMapping from './AbstractMadMlMapping';
import MadMlEventMapping from './MadMlEventMapping';
import MadMlAttributeMapping from './MadMlAttributeMapping';
import MadMlAudienceMapping from './MadMlAudienceMapping';

export default class MadMlMappingFactory {
  public async createMadMlMapping(
    tenant: number,
    email: string,
    mappingType: MappingsMode
  ): Promise<AbstractMadMlMapping> {
    if (mappingType === MappingsMode.event) {
      return new MadMlEventMapping(tenant, email);
    }
    if (mappingType === MappingsMode.attribute) {
      return new MadMlAttributeMapping(tenant, email);
    }
    if (mappingType === MappingsMode.audience) {
      return new MadMlAudienceMapping(tenant, email);
    }
  }
}
