enum EventMappingConnectorsNames {
  hubspot = 'hubspot',
  segment = 'segment',
  amplitude = 'amplitude',
  kissmetrics = 'kissmetrics',
  mixpanel = 'mixpanel',
  marketo = 'marketo',
  salesforce_campaigns = 'salesforce_campaigns',
  salesforce_tasks = 'salesforce_tasks',
  snowflake = 'snowflake',
  madMl = 'madMl',
  s3 = 's3',
  bigquery = 'bigquery',
}

export default EventMappingConnectorsNames;
