import React from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader } from '../../../components/Loader';
import { useSnowflakeContext } from '../contexts/SnowflakeContext';

type Props = {
  previousStep: () => void;
  nextStep: () => void;
};

export function TableMappingStep({ previousStep, nextStep }: Props) {
  const {
    tenant,
    getTables,
    credentials,
    setCredentials,
    testTableMapping,
  } = useSnowflakeContext();

  const tablesCacheKey = [
    'snowflake',
    'tables',
    tenant,
    credentials?.account ?? '',
    credentials?.username ?? '',
    credentials?.pwd ?? '',
    credentials?.database ?? '',
    credentials?.schema ?? '',
  ];
  const { data: tables, isLoading: loadingTables } = useQuery(
    tablesCacheKey,
    getTables
  );

  const { mutate: test, isLoading: isTesting } = useMutation(testTableMapping);

  const {
    tableNames: { event, contact_update, account_update } = {
      event: '',
      contact_update: '',
      account_update: '',
    },
  } = credentials ?? {};

  const handleNextClick = async () => {
    if (!event && !contact_update && !account_update) {
      Swal.fire({
        title: 'Invalid table mapping',
        text: 'Please map at least one table',
        icon: 'error',
      });

      return;
    }

    test(null, {
      onSuccess: (state) => {
        if (state) nextStep();
        else
          Swal.fire({
            title: 'Invalid table mapping',
            text:
              'MadKudu is unable to find these tables. Please verify they exist in your Snowflake database.',
            icon: 'error',
          });
      },
    });
  };

  const usedTables = [event, contact_update, account_update].filter(Boolean);

  return (
    <>
      {loadingTables && <Loader className="m-auto w-50" />}
      {loadingTables || (
        <>
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="card box-shadow-3d mb-3">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src="/media/integrations/logos/snowflake-icon.png"
                    />
                    <h5 className="mb-0 ml-3">Snowflake</h5>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="mt-3">Table mapping</h5>
                  <p>
                    Select which table(s) from Snowflake should be synced to
                    MadKudu. Not all tables are required, you can sync one table
                    only or more
                  </p>

                  <form>
                    <div className="form-group row">
                      <label
                        htmlFor="event"
                        className="col-sm-2 col-form-label"
                      >
                        Events
                      </label>
                      <div className="col-sm-10">
                        <Select
                          value={
                            event
                              ? {
                                  label: event,
                                  value: event,
                                }
                              : null
                          }
                          name="event"
                          placeholder="Select the table that stores events data"
                          onChange={(option) =>
                            setCredentials({
                              ...credentials,
                              tableNames: {
                                ...credentials.tableNames,
                                event: option?.value ?? '',
                              },
                            })
                          }
                          options={tables?.map((table) => ({
                            label: table,
                            value: table,
                            isDisabled: usedTables.includes(table),
                          }))}
                          isClearable
                          isDisabled={!tables?.length}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="contacts"
                        className="col-sm-2 col-form-label"
                      >
                        Contacts
                      </label>
                      <div className="col-sm-10">
                        <Select
                          value={
                            contact_update
                              ? {
                                  label: contact_update,
                                  value: contact_update,
                                }
                              : null
                          }
                          name="contacts"
                          placeholder="Select the table that stores contacts data"
                          onChange={(option) =>
                            setCredentials({
                              ...credentials,
                              tableNames: {
                                ...credentials.tableNames,
                                contact_update: option?.value ?? '',
                              },
                            })
                          }
                          options={tables?.map((table) => ({
                            label: table,
                            value: table,
                            isDisabled: usedTables.includes(table),
                          }))}
                          isClearable
                          isDisabled={!tables?.length}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="accounts"
                        className="col-sm-2 col-form-label"
                      >
                        Accounts
                      </label>
                      <div className="col-sm-10">
                        <Select
                          value={
                            account_update
                              ? {
                                  label: account_update,
                                  value: account_update,
                                }
                              : null
                          }
                          name="accounts"
                          placeholder="Select the table that stores contacts data"
                          onChange={(option) =>
                            setCredentials({
                              ...credentials,
                              tableNames: {
                                ...credentials.tableNames,
                                account_update: option?.value ?? '',
                              },
                            })
                          }
                          options={tables?.map((table) => ({
                            label: table,
                            value: table,
                            isDisabled: usedTables.includes(table),
                          }))}
                          isClearable
                          isDisabled={!tables?.length}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 pb-4 text-center">
            <button
              className="btn btn-light mr-2"
              disabled={isTesting}
              onClick={previousStep}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              disabled={isTesting}
              onClick={handleNextClick}
            >
              {isTesting ? 'Testing tables...' : 'Next'}
            </button>
          </div>
        </>
      )}
    </>
  );
}
