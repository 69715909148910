import React, { Component } from 'react';

type GlobalModelPerformanceLeadGradeGuideComponentProps = {
  tenant: number;
  goTo: (url: string) => void;
};

export default class GlobalModelPerformanceLeadGradeGuideComponent extends Component<
  GlobalModelPerformanceLeadGradeGuideComponentProps,
  {}
> {
  render() {
    const { tenant, goTo } = this.props;
    return (
      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
            <div className="card-body">
              <i className="far fa-compass fa-lg mt-2"></i>
              <h5 className="mt-2">
                <strong>Guide: </strong>
                Global Model Performance
              </h5>
              <div className="border-top mt-2 border-primary"></div>
              <i className="fas fa-flask fa-lg mt-2"></i>
              <h5 className="mt-2">
                <strong>How MadKudu Computes This:</strong>
              </h5>
              <p className="mt-2">
                <strong>Recall Distribution:</strong> What percentage of
                conversions{' '}
                <a
                  onClick={() =>
                    goTo(`/org/${tenant}/mapping/conversion_mapping`)
                  }
                  style={{ textDecoration: 'underline' }}
                >
                  (see how conversions are mapped)
                </a>{' '}
                created in the selected timeframe and have converted came from
                leads scored as A, B, C, D, or E by MadKudu's Lead Grade model.
              </p>
              <p className="mt-2">
                <strong>Leads Distribution:</strong> What percentage of leads
                created in the selected timeframe are scored as very A, B, C, D,
                or E by MadKudu's Lead Grade model.
              </p>
              <div className="mt-2">
                <strong>Note:</strong>
                <ul>
                  <li>
                    We use the last score of the lead before the opportunity is
                    created to compute the performance
                  </li>
                  <li>
                    The conversion dates here are the opportunity created date
                    for SQO and Open Opp predictions, and the close date for
                    Closed Won predictions.
                  </li>
                  <li>
                    We are only considering conversions tied to active leads 90
                    days prior to conversion in these reports based on the
                    integrations enabled.
                  </li>
                </ul>
              </div>
              <h5 className="mt-2">
                <strong>How to Interpret This:</strong>
              </h5>
              <p>
                Find out if your highly scored leads account for most of the
                conversions. The higher the number the better the model is at
                identifying who is likely to generate a success.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
