import React from 'react';
import ModelDataModel from '../../../models/model/ModelDataModel';
import { Integration, PushConfigurationItem } from '../types';

type DisplayConfigurationCardProps = {
  generateModelBadges: Function;
  pushConfigurationItem: PushConfigurationItem;
  modelsList: ModelDataModel[];
  connector: Integration;
};

class DisplayConfigurationCard extends React.Component<
  DisplayConfigurationCardProps
> {
  render() {
    const {
      pushConfigurationItem,
      connector,
      generateModelBadges,
      modelsList,
    } = this.props;
    return (
      <div className="row mt-4 mb-2">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-2">
                  <img
                    src={`/media/integrations/logos/${connector}.svg`}
                    className="w-50"
                  />
                </div>
                <div className="col-10 text-right m-auto">
                  {connector === 'segment' &&
                    `Identify records scored with a Segment event in the last ${pushConfigurationItem?.configuration?.timeframe.segment.score_contacts_active_last_X_days_segment.toString()} days`}
                  {['salesforce', 'hubspot'].includes(connector) &&
                    `All ${
                      pushConfigurationItem?.configuration?.objects[connector]
                        ?.lead === true
                        ? 'Lead'
                        : ''
                    } ${
                      pushConfigurationItem?.configuration?.objects[connector]
                        ?.lead === true &&
                      pushConfigurationItem?.configuration?.objects[connector]
                        ?.contact === true
                        ? 'and'
                        : ''
                    } ${
                      pushConfigurationItem?.configuration?.objects[connector]
                        ?.contact === true
                        ? 'Contact'
                        : ''
                    } records ${
                      pushConfigurationItem?.configuration?.timeframe
                        ? `created after ${pushConfigurationItem?.configuration?.timeframe[connector]?.score_contacts_created_since} `
                        : ''
                    }are scored by MadKudu in the fields mapped below`}
                </div>
              </div>
            </div>
            <div className="card-body p-2">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Segmentation</th>
                    <th scope="col">MadKudu</th>
                    {pushConfigurationItem?.configuration?.objects[connector]
                      ?.lead && <th scope="col">Lead</th>}
                    {pushConfigurationItem?.configuration?.objects[connector]
                      ?.identify && <th scope="col">Identify</th>}
                    {pushConfigurationItem?.configuration?.objects[connector]
                      ?.contact && <th scope="col">Contact</th>}
                  </tr>
                </thead>
                <tbody>
                  {pushConfigurationItem?.attributes?.map(
                    ({ name, tag, integrations }) => {
                      let nameToDisplay = name;
                      if (connector === 'intercom' && !name.includes('fit'))
                        return null;

                      const match = name?.match(
                        /mk_(?:likelihood_to_buy|lead_grade)_.*$/
                      );
                      const hybridMatch = name?.match(
                        /mk_(?:likelihood_to_buy|lead_grade)_.*_(\d+)$/
                      );
                      let modelId: number | null = null;
                      if (hybridMatch) {
                        modelId = Number(hybridMatch[1]);
                        nameToDisplay = nameToDisplay.replace(/_(\d+)$/, '');
                      } else if (match) {
                        const liveModel: ModelDataModel = modelsList.find(
                          (m: ModelDataModel) => m.type === 'pql'
                        );
                        modelId = liveModel.modelId;
                      }

                      const model = modelId
                        ? modelsList.find(
                            (m: ModelDataModel) => m.modelId === modelId
                          )
                        : null;

                      return (
                        <tr key={name}>
                          <td>{generateModelBadges(name, tag, model)}</td>
                          <td>{nameToDisplay}</td>
                          {pushConfigurationItem?.configuration?.objects[
                            connector
                          ]?.lead && (
                            <td>{integrations[connector]?.Lead ?? '-'}</td>
                          )}
                          {pushConfigurationItem?.configuration?.objects[
                            connector
                          ]?.identify && (
                            // @ts-ignore
                            <td>{integrations[connector]?.identify ?? '-'}</td>
                          )}

                          {pushConfigurationItem?.configuration?.objects[
                            connector
                          ]?.contact && (
                            <td>
                              {integrations[connector]?.Contact ??
                                // @ts-ignore
                                integrations[connector]?.contact ??
                                '-'}
                            </td>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type ReviewConfigurationProps = {
  generateModelBadges: Function;
  pushConfigurationItem: PushConfigurationItem;
  modelsList: ModelDataModel[];
};

export default class DisplayConfiguration extends React.Component<
  ReviewConfigurationProps
> {
  render() {
    const connectors = Object.keys(
      this.props.pushConfigurationItem.configuration.connectors
    ).filter(
      (k) => this.props.pushConfigurationItem.configuration.connectors[k]
    );
    return (
      <React.Fragment>
        {connectors.map((connector: Integration) => {
          return (
            <React.Fragment key={connector}>
              <DisplayConfigurationCard
                modelsList={this.props.modelsList}
                connector={connector}
                pushConfigurationItem={this.props.pushConfigurationItem}
                generateModelBadges={this.props.generateModelBadges}
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}
