import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import TablePushDisplayComponent from './TablePushDisplayComponent';
import { ConnectorFieldNameDataModel } from '../../mapping/models/ConnectorFieldNameDataModel';

type PushSectionComponentProps = {
  tenant: number;
  integration: string;
  credentialsFound: boolean;
  pushConfigData: ConnectorFieldNameDataModel[];
  writeKey: string;
};

type PushSectionComponentState = {
  writeKey: string;
};
export default class PushSectionComponent extends React.Component<
  PushSectionComponentProps,
  PushSectionComponentState
> {
  constructor(props: PushSectionComponentProps) {
    super(props);
    this.state = {
      writeKey: this.props.writeKey,
    };

    this.handlePropertyChange = this.handlePropertyChange.bind(this);
  }

  handlePropertyChange() {
    return (e: any) => {
      this.setState({
        writeKey: e.target.value,
      });
    };
  }

  saveKey() {
    const { writeKey } = this.state;
    const show_error_message = function (text: string) {
      Swal.fire({
        title: 'Oops',
        text,
        icon: 'error',
      });
    };

    if (!writeKey) {
      return show_error_message('Please enter a Write Key');
    }

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.props.tenant}/integrations/${this.props.integration}/credentials?activate_push=true`,
        { write_key: writeKey }
      )
      .catch(() => {
        show_error_message(
          'We were not able to save your settings. Our team has been notified and is looking into it'
        );
      });
  }

  render() {
    const { integration, pushConfigData, writeKey: savedWriteKey } = this.props;
    const { writeKey } = this.state;
    return (
      <div className="row justify-content-center">
        <div className="card box-shadow-3d mt-md-2 d-flex w-75">
          <div className="card-header">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-2 justify-content-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src={`/media/integrations/logos/${`${integration}-icon`}.png`}
                    />
                  </div>
                  <div className="col-10 my-auto">
                    <h5 className="text-capitalize mb-0"> {integration}</h5>
                  </div>
                </div>
              </div>
              <div className="col-7 my-auto">
                {savedWriteKey ? (
                  <div className="ml-1 my-auto d-flex justify-content-between flex-row float-right">
                    <div className="success-div rounded-circle mr-1"></div>
                    <span>
                      Pushing data to{' '}
                      <span className="text-capitalize"> {integration}</span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <p>
                  Send MadKudu data to
                  <span className="text-capitalize"> {integration}</span>
                </p>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <form className="form-inline">
                  <div className="input-group w-75">
                    <input
                      type="text"
                      value={writeKey}
                      onChange={this.handlePropertyChange()}
                      className="form-control"
                      placeholder="Write key"
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-secondary"
                        onClick={() => this.saveKey()}
                        type="button"
                      >
                        {!savedWriteKey ? 'Save' : 'Edit'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <a
                  href="https://segment.com/docs/connections/find-writekey/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <small>Where do I find my write key ?</small>
                </a>
              </div>
            </div>
            {savedWriteKey && pushConfigData && pushConfigData.length > 0 ? (
              <React.Fragment>
                <div className="row">
                  <div className="col mb-2">
                    This table represents the correlation between internal
                    MadKudu fields and your{' '}
                    <span className="text-capitalize">{integration} </span>{' '}
                    fields.
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <TablePushDisplayComponent
                      pushConfigData={pushConfigData}
                      integration={integration}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
