import axios from 'axios';
import Backbone from 'backbone';
import { ConnectorsNames } from '../types';
import { OBJECT_PLACEHOLDER_VALUE } from './constants';
import FiltersType from './enums/FiltersType';
import MappingsMode from './enums/MappingsMode';
import VerbsNames from './enums/VerbsNames';
import { CONNECTORS_NAMES } from './event_mapping/constants';
import ActiveIntegrationDataModel from './event_mapping/models/ActiveIntegrationDataModel';
import { convertDataToListOfActiveIntegrationModels } from './event_mapping/utils';
import ParameterDataModel from './models/ParameterDataModel';

export const ALLOWED_MAPPING_SEQ: { [key: string]: string[] } = {
  compute: ['attribute'],
  map: ['audiences', 'events', 'conversion'],
};

export const VERBS_AND_SQL: Record<string, string> = {
  is: "{{subject}} = '{{value}}'",
  'is not': "COALESCE(CAST({{subject}} AS VARCHAR), 'unknown') != '{{value}}'",
  contains: "{{subject}} LIKE '%{{value}}%'",
  'does not contain': "COALESCE({{subject}}, 'unknown') NOT LIKE '%{{value}}%'",
  'is less than or equal to':
    'COALESCE(CAST({{subject}} AS FLOAT), 0) <= {{value}}',
  'is more than or equal to':
    'COALESCE(CAST({{subject}} AS FLOAT), 0) >= {{value}}',
  'is less than': 'COALESCE(CAST({{subject}} AS FLOAT), 0) < {{value}}',
  'is more than': 'COALESCE(CAST({{subject}} AS FLOAT), 0) > {{value}}',
  'is any of': '{{subject}} IN({{allValues}})',
  'is none of':
    "COALESCE(CAST({{subject}} AS VARCHAR), 'unknown') NOT IN({{allValues}})",
  'is between':
    'COALESCE(CAST({{subject}} AS FLOAT), 0) BETWEEN {{value}} AND {{value2}}',
  'is known': '{{subject}} IS NOT NULL',
  'is unknown': '{{subject}} IS NULL',
  'begins with': "{{subject}} LIKE '{{value}}%'",
  'ends with': "{{subject}} LIKE '%{{value}}'",
};

export const CONVERSION_CONNECTORS_NAMES = {
  salesforce: 'salesforce',
  analytics: 'analytics',
  hubspot: 'hubspot',
  stripe: 'stripe',
};

export const DATASOURCE_OBJECTS_ATTRIBUTE: { [key: string]: string[] } = {
  hubspot: [OBJECT_PLACEHOLDER_VALUE, 'Contact', 'Deal', 'Company'],
  salesforce: [
    OBJECT_PLACEHOLDER_VALUE,
    'Contact',
    'Lead',
    'Account',
    'Opportunity',
  ],
  marketo: [OBJECT_PLACEHOLDER_VALUE, 'lead'],
  stripe: [OBJECT_PLACEHOLDER_VALUE, 'invoice'],
  s3: [OBJECT_PLACEHOLDER_VALUE, 'Contact', 'Account'],
  bigquery: [OBJECT_PLACEHOLDER_VALUE, 'Contact', 'Account'],
  snowflake: [OBJECT_PLACEHOLDER_VALUE, 'Contact', 'Account'],
};

export const DATASOURCE_OBJECTS_AUDIENCE: { [key: string]: string[] } = {
  hubspot: ['Contact', 'Deal', 'Company'],
  salesforce: ['Contact', 'Lead', 'Account', 'Opportunity'],
  marketo: ['lead'],
  stripe: ['invoice'],
};

export const COMPANY_CONTACTS_MAPPING = {
  associatedCompanyId: 'associatedCompanyId',
  domain: 'domain',
};

export const VERBS_FLOATS_ONLY = [
  'is less than or equal to',
  'is more than or equal to',
  'is less than',
  'is more than',
  'is between',
];

export const VERBS = Object.keys(VERBS_AND_SQL);
export const EVENTS_VERBS = [
  'is',
  'is not',
  'contains',
  'does not contain',
  'is any of',
  'is none of',
];

export const DATASOURCE_OBJECTS: Record<string, string[]> = {
  hubspot: ['Contact', 'Deal', 'Company'],
  salesforce: ['Account', 'Opportunity', 'Lead / Contact'],
  marketo: [
    'campaign',
    'webpage_url',
    'referrer_url',
    'primaryattributevalue',
    'description',
    'link',
  ],
  salesforceCampaign: ['Campaign', 'CampaignMember'],
  salesforceTask: ['Task'],
  stripe: ['invoice'],
};

export const TABLES_LABELS: { [key: string]: string } = {
  Account: 'a',
  Opportunity: 'o',
  'Lead / Contact': 'c',
  Contact: 'c',
  Deal: 'o',
  Company: 'a',
  Campaign: 'c',
  CampaignMember: 'ce',
};

export const SOURCE_SYSTEMS = ['Hubspot', 'Salesforce'];

export function getFieldPrefix(dataSource: string, object: string) {
  const lowerDatasource = dataSource.toLowerCase();
  const lowerObject = object?.toLowerCase();

  if (lowerDatasource === 'hubspot') {
    if (lowerObject === 'contact') return 'hc';
    if (lowerObject === 'company') return 'ha';
    if (lowerObject === 'deal') return 'ho';
  }
  if (lowerDatasource === 'salesforce') {
    if (lowerObject === 'lead') return 'sl';
    if (lowerObject === 'contact') return 'sc';
    if (lowerObject === 'account') return 'sa';
    if (lowerObject === 'opportunity') return 'so';
  }
  if (lowerDatasource === 'marketo') {
    return 'mkto';
  }

  throw new Error('Unable to build attributes query');
}

/**
 * Pull the "pull" datasource object to Bongo
 * @param tenant
 * @param datasource
 * @returns {Promise<*>}
 */
export async function pullDatasourceObjects(
  tenant: number,
  datasource: string
) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenant}/integrations/${datasource}/pull?ignore=true`
  );
  return data.config;
}

/**
 * Take an array and translate it to a YAML array as a STRING
 * @param array
 * @returns {string}
 */
export function translateJSArrayToYAMLArray(array: string[]) {
  let yamlArray = '';
  if (array && array.length > 0) {
    array.forEach((element: any, index: number) => {
      if (index === 0) yamlArray += `\n    - ${element}\n`;
      else yamlArray += `    - ${element}\n`;
    });
  }
  return yamlArray;
}

export function beautifyYAMLArray(yamlArray: string[] | string = []) {
  if (typeof yamlArray === 'object' && yamlArray.length > 0) {
    return yamlArray.reduce((accumulator, currentValue) => {
      return `${accumulator}, ${currentValue}`;
    });
  }
  if (typeof yamlArray === 'string' && yamlArray) {
    return yamlArray.trim().replace('- ', '').replace(' - ', ', ');
  }
}

export function convertStringOfAmountFieldsNameToArray(str: string) {
  if (str === '' || typeof str !== 'string') {
    return [];
  }
  return str.split(',');
}

/**
 * Get the attribute name of a filters form based on the connector and the type
 * @param connector
 * @param type
 */
export function getConnectorTypeFiltersFormAttributeName(
  connector: ConnectorsNames,
  type: FiltersType
): string {
  return `${connector}_${type}_filters_form`;
}

export function getAmountFieldAttributeNameOfConnector(
  connector: ConnectorsNames
): string {
  return `${connector}_amount_field`;
}

export function getTypeFilterAttributeNameOfConnector(
  connector: ConnectorsNames
): string {
  return `${connector}_type_filter_values`;
}

export function generateApiUrlDeleteBasedOnMappingMode(
  tenant: number,
  mappingMode: MappingsMode,
  audienceName?: string
): string {
  switch (mappingMode) {
    case MappingsMode.audience:
      return `${BONGO_URL}/v1/org/${tenant}/data/mappings/audiences/${audienceName}`;
    case MappingsMode.conversion:
      return `${BONGO_URL}/v1/org/${tenant}/data/mappings/conversion`;
    case MappingsMode.attribute:
      return `${BONGO_URL}/v1/org/${tenant}/data/mappings/attribute`;
    default:
      throw new Error('Unknown mapping mode for delete!');
  }
}

export async function getScriptCheckerStateBasedOnMappingMode(
  tenant: number,
  mappingMode: MappingsMode
) {
  try {
    return axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/${mappingMode}/check`
    );
  } catch (e) {
    throw new Error(e);
  }
}

const EMPTY_STRING = '';
/**
 * Take a parameter or a condition already converted to SQL (STRING) and translate it to a globalVar for the YAML file (the section which is in top on comment)
 * @param sqlConditionOrParameter
 * @param index
 * @param type = 'parameter' || 'condition'
 * @returns {string}
 */
export function mapParameterToGlobalVar(
  sqlConditionOrParameter: string,
  index: number,
  type = 'parameter' || 'condition'
) {
  return sqlConditionOrParameter
    ? `${type}${index + 1}: '${sqlConditionOrParameter}' `.toLowerCase()
    : EMPTY_STRING;
}

/**
 * Beautify the whitespaces
 * @param str
 * @returns string
 */
export function beautifyWhitespace(str: string) {
  if (str)
    return str
      .replace(/ AND /g, '\n          AND ')
      .replace(/ OR /g, '\n          OR ');
  return EMPTY_STRING;
}

/**
 * Get the Not null index conditions, due to the array containing null element
 * @param conditions
 * @returns condition
 */
export function getNotNullConditionIndexes(conditions: string[]) {
  const notNullConditionIndexes = conditions
    .map((condition, index) => {
      if (condition !== null) return index;
      return null;
    })
    .filter((condition) => condition !== null);

  return notNullConditionIndexes;
}

/**
 * Compute the default logic from the conditions and returning the result
 * @param conditions
 * @returns string
 */
export function computeDefaultLogicUpdate(conditions: any[]) {
  const notNullConditionsIndexes = getNotNullConditionIndexes(conditions);
  return notNullConditionsIndexes.map((i) => `$${i + 1}`).join(' AND ');
}

/**
 * Check the string and return lowered and trimed
 * @param str
 * @returns {string}
 */
export function checkStringAndLowerAndTrim(str: string) {
  return str ? str.trim().toLowerCase() : EMPTY_STRING;
}

/**
 * check the string, split it into an array, trim elements and return the resulted array
 * @param str
 * @returns {*}
 */
export function checkStringSplitAndConvert(str: string) {
  return str ? str.split(',').map((item) => item.trim().toLowerCase()) : [];
}

/**
 * Convert a coondition to SQL(String) for the YAML file.
 * @param condition
 * @returns {string|*}
 */
export function mapConditionToSql(condition: UnknownObject) {
  if (!condition || !condition.values) {
    return EMPTY_STRING;
  }
  const { verb, lower } = condition;
  let { object, subject, values } = condition;

  // apply table to subject
  if (TABLES_LABELS[object]) {
    subject = `${TABLES_LABELS[object]}.${subject}`;
  }

  if (lower) {
    subject = `LOWER(${subject})`;
    object = EMPTY_STRING;
    values = values.map((v: string) => v.toLowerCase());
  }

  const value1: string = values[0];
  const value2: string = values[1];
  const allValues = `'${values.join("', '")}'`;

  // eslint-disable-next-line
  if (!isNaN(Number(value1))) {
    if (!VERBS_FLOATS_ONLY.includes(verb)) {
      return VERBS_AND_SQL[verb]
        .replace("'{{value}}'", value1)
        .replace("'{{value2}}'", value2)
        .replace('{{subject}}', verb === 'is' ? `${subject}::FLOAT` : subject)
        .replace('{{allValues}}', allValues);
    }
    return VERBS_AND_SQL[verb]
      .replace('{{value}}', value1)
      .replace('{{value2}}', value2)
      .replace('{{subject}}', verb === 'is' ? `${subject}::FLOAT` : subject)
      .replace('{{allValues}}', allValues);
  }
  return VERBS_AND_SQL[verb]
    .replace('{{value}}', String(value1).trim())
    .replace('{{value2}}', String(value2).trim())
    .replace('{{subject}}', subject)
    .replace('{{allValues}}', allValues);
}

function getVerbsToSqlOfNumberField(
  verb: VerbsNames,
  field: string,
  value: string,
  value2: string,
  allSqlValues: string
): string {
  if (!VERBS_FLOATS_ONLY.includes(verb)) {
    return VERBS_AND_SQL[verb]
      .replace(
        '{{subject}}',
        verb === VerbsNames.is ? `${field}::FLOAT` : field
      )
      .replace("'{{value}}'", value)
      .replace('{{allValues}}', allSqlValues);
  }
  return VERBS_AND_SQL[verb]
    .replace('{{subject}}', verb === VerbsNames.is ? `${field}::FLOAT` : field)
    .replace('{{value}}', value)
    .replace('{{value2}}', value2)
    .replace('{{allValues}}', allSqlValues);
}

export function mapGenericParameterToSql(parameter: ParameterDataModel) {
  if (!parameter || !parameter.field || !parameter.values) return EMPTY_STRING;
  const { verb, values, lower, table } = parameter;
  let { field } = parameter;
  // apply table to field
  if (TABLES_LABELS[table]) {
    field = `${TABLES_LABELS[table]}.${field}`;
  }

  if (lower) {
    field = `LOWER(${field})`;
  }

  const value: string = values[0];
  const value2: string = values[1];
  const allSqlValues = `'${values.join("', '")}'`;

  // eslint-disable-next-line
  if (!isNaN(Number(value))) {
    return getVerbsToSqlOfNumberField(verb, field, value, value2, allSqlValues);
  }
  return VERBS_AND_SQL[verb]
    .replace('{{subject}}', field)
    .replace('{{value}}', String(value).trim())
    .replace('{{allValues}}', allSqlValues);
}

function convertParametersToSqlParameters(parameters: ParameterDataModel[]) {
  const sqlParametersForGlobalVars: string[] = [];
  const sqlParameters: string[] = parameters
    ? parameters.map((element, index) => {
        const parameterToSql = mapGenericParameterToSql(element);
        sqlParametersForGlobalVars.push(
          mapParameterToGlobalVar(parameterToSql, index, 'parameter')
        );
        return parameterToSql;
      })
    : [];
  return {
    sqlParametersForGlobalVars,
    sqlParameters,
  };
}

export function mapGenericParametersToSql(
  parameters: ParameterDataModel[],
  conditionsLogic: string
) {
  const {
    sqlParameters,
    sqlParametersForGlobalVars,
  } = convertParametersToSqlParameters(parameters);
  let sql = conditionsLogic || EMPTY_STRING;
  let blockSqlSquery = EMPTY_STRING;

  if (sql) sql = beautifyWhitespace(sql);
  sqlParameters.forEach((condition, index) => {
    sql = sql.replace(`$${index + 1}`, condition);
  });

  const notEmptySqlConditions = sqlParameters.filter(
    (condition) => condition !== EMPTY_STRING
  );

  if (notEmptySqlConditions.length === 1) {
    blockSqlSquery = `AND ${notEmptySqlConditions[0]}`;
  } else if (!sql) {
    blockSqlSquery = EMPTY_STRING;
  } else {
    blockSqlSquery = `AND (${sql.trim()})`;
  }
  return { sql: blockSqlSquery, sqlGlobalVars: sqlParametersForGlobalVars };
}

/**
 * Convert a coondition to SQL(String) for the YAML file.
 * @param condition
 * @returns {string|*}
 */
export function mapMarketoConditionToSql(
  condition: UnknownObject,
  isEventMapping: boolean = false,
  isHubspot: boolean = false
) {
  if (!condition || !condition.values || !condition.object) return EMPTY_STRING;
  const { verb, lower, subject, object } = condition;
  let { values } = condition;
  let targetField = isEventMapping ? object : subject;

  if (isHubspot && targetField === 'a_url') {
    targetField = 'e.'.concat(targetField);
  }
  // apply table to subject
  if (!isEventMapping && TABLES_LABELS[object]) {
    targetField = `${TABLES_LABELS[object]}.${subject}`;
  }

  if (lower) {
    targetField = `LOWER(${targetField})`;
    values = values.map((v: string) => v.toLowerCase());
  }

  const value1 = values[0];
  const value2 = values[1];
  const allValues = `'${values.join("', '")}'`;

  // eslint-disable-next-line
  if (!isNaN(Number(value1))) {
    if (!VERBS_FLOATS_ONLY.includes(verb)) {
      return VERBS_AND_SQL[verb]
        .replace("'{{value}}'", value1)
        .replace("'{{value2}}'", value2)
        .replace('{{subject}}', targetField || EMPTY_STRING)
        .replace('{{allValues}}', allValues);
    }
    return VERBS_AND_SQL[verb]
      .replace('{{value}}', value1)
      .replace('{{value2}}', value2)
      .replace('{{subject}}', targetField || EMPTY_STRING)
      .replace('{{allValues}}', allValues);
  }
  return VERBS_AND_SQL[verb]
    .replace('{{value}}', String(value1).trim())
    .replace('{{value2}}', String(value2).trim())
    .replace('{{subject}}', targetField || EMPTY_STRING)
    .replace('{{allValues}}', allValues);
}

/**
 * Convert a parameter to SQL(String) for the YAML file.
 * @param parameter
 * @returns {string|*}
 */
export function mapParameterToSql(parameter: UnknownObject) {
  if (!parameter || !parameter.field || !parameter.values) return EMPTY_STRING;
  const { condition, values, lower, table } = parameter;
  let { field } = parameter;

  // apply table to field
  if (TABLES_LABELS[table]) {
    field = `${TABLES_LABELS[table]}.${field}`;
  }

  if (lower) {
    field = `LOWER(${field})`;
  }

  const value = values[0];
  const value2 = values[1];
  const AllSqlValues = `'${values.join("', '")}'`;

  // eslint-disable-next-line
  if (!isNaN(Number(value))) {
    if (!VERBS_FLOATS_ONLY.includes(condition)) {
      return VERBS_AND_SQL[condition]
        .replace('{{subject}}', condition === 'is' ? `${field}::FLOAT` : field)
        .replace("'{{value}}'", value)
        .replace('{{allValues}}', AllSqlValues);
    }
    return VERBS_AND_SQL[condition]
      .replace('{{subject}}', condition === 'is' ? `${field}::FLOAT` : field)
      .replace('{{value}}', value)
      .replace('{{value2}}', value2)
      .replace('{{allValues}}', AllSqlValues);
  }
  return VERBS_AND_SQL[condition]
    .replace('{{subject}}', field)
    .replace('{{value}}', String(value).trim())
    .replace('{{allValues}}', AllSqlValues);
}

/**
 * Map conditions to condition logic, returning the appropriate SQL for each condition, linked correctly
 * NOTE: this function is only called by HUBSPOT and MARKETO, salesforce and analytics calls mapParametersToSql
 * @param {Array} conditions - Conditions to map. Example [{ subject: 'test', verb: 'is', value: 'real', lower: false }]
 * @param {String} conditionsLogic - The conditions' mapping logic. Example: $1 AND ($2 OR $3)
 * @param connector
 * @returns {{sqlGlobalVars: [], sql: string}}
 */
export function mapConditionsToSql(
  conditions: any[],
  conditionsLogic: string,
  connector = 'hubspot',
  isEventMapping = false
) {
  const sqlConditionsForGlobalVars: string[] = [];
  const isSalesforce: boolean =
    connector === CONNECTORS_NAMES.salesforce_campaigns ||
    connector === CONNECTORS_NAMES.salesforce_tasks ||
    connector === 'salesforce';
  const isHubspot: boolean = connector === CONNECTORS_NAMES.hubspot;
  const sqlConditions = conditions
    ? conditions.map((element, index) => {
        const conditionToSql = isSalesforce
          ? mapConditionToSql(element)
          : mapMarketoConditionToSql(element, isEventMapping, isHubspot);
        sqlConditionsForGlobalVars.push(
          mapParameterToGlobalVar(conditionToSql, index, 'condition')
        );
        return conditionToSql;
      })
    : [];

  let sql = conditionsLogic || EMPTY_STRING;

  let blockSqlSquery = EMPTY_STRING;

  sql = beautifyWhitespace(sql);
  sqlConditions.forEach((condition, index) => {
    sql = sql.replace(`$${index + 1}`, condition);
  });

  const notEmptySqlConditions = sqlConditions.filter(
    (condition) => condition !== EMPTY_STRING
  );

  if (notEmptySqlConditions.length === 1) {
    blockSqlSquery = `AND ${notEmptySqlConditions[0]}`;
  } else if (!sql.trim()) {
    blockSqlSquery = EMPTY_STRING;
  } else {
    blockSqlSquery = `AND (${sql})`;
  }
  return { sql: blockSqlSquery, sqlGlobalVars: sqlConditionsForGlobalVars };
}

export function getVerbBoundaries(subject: string) {
  switch (subject) {
    case 'is between':
      return {
        min: 2,
        max: 2,
      };
    case 'is known':
      return {
        min: 0,
        max: 0,
      };
    case 'is unknown':
      return {
        min: 0,
        max: 0,
      };
    case 'is any of':
      return {
        min: 1,
        max: 10,
      };
    case 'is none of':
      return {
        min: 1,
        max: 10,
      };
    default:
      return {
        min: 1,
        max: 1,
      };
  }
}

/**
 * Take the new values of a parameter(salesforce + analytic) or a condition (hubspot),
 * and update the array and returns it.
 * @param property (The property that we want to update here)
 * @param value (The new value)
 * @param conditionIndex (The index of the condition in the array)
 * @param valueIndex (The index of the value, if it's a value, by default null)
 * @param stateArray (The array coming from the state, like this.state.parameters)
 * @returns {*}
 */
export function updateParameters(
  property: string,
  value: string,
  conditionIndex: number,
  valueIndex: number = null,
  stateArray: UnknownObject[],
  tenant: number,
  email: string,
  connector: ConnectorsNames,
  mappingMode: MappingsMode,
  type?: FiltersType
) {
  return stateArray
    ? stateArray.map((parameter: ParameterDataModel, index) => {
        if (index === conditionIndex) {
          window.analytics.track('Edit mapping', {
            tenant,
            email,
            map: `${mappingMode} mapping - ${connector}${
              type ? ` - ${type}` : ''
            }`,
          });
          // eslint-disable-next-line no-param-reassign
          parameter.updated_at = Date.now();
          if (property === 'value') {
            parameter.values.forEach((_inputValue: string, vindex: any) => {
              if (vindex === valueIndex) {
                // eslint-disable-next-line
                parameter.values[vindex] = value;
              }
            });
          } else {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            parameter[property] = value;
          }
          return parameter;
        }
        return parameter;
      })
    : [];
}

/**
 * Return the parameter or the condition by index and array
 * @param conditionIndex
 * @param parametersOrConditionsArray
 * @returns {*}
 */
export function getParameterOrCondition(
  conditionIndex: number,
  parametersOrConditionsArray: UnknownObject[]
) {
  return parametersOrConditionsArray.filter((c, index) => {
    if (conditionIndex === index) return false;
    return true;
  });
}

export async function getAllActiveIntegrations(
  tenant: number
): Promise<ActiveIntegrationDataModel[]> {
  try {
    // Result will be an array containing objects
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/integrations`
    );
    if (data) {
      const activeIntegrations = data.filter(
        (integration: Record<string, Record<string, unknown>>) =>
          integration.pull.active || integration.push.active
      );

      return convertDataToListOfActiveIntegrationModels(activeIntegrations);
    }
    throw new Error('result for integrations is empty...');
  } catch (e) {
    return [];
  }
}

export function redirectToInsightsReport(
  mappingType: MappingsMode,
  tenant: number
) {
  switch (mappingType) {
    case MappingsMode.conversion:
      Backbone.history.navigate(
        `/org/${tenant}/evr_insights/conversion_mapping_output/0`,
        true
      );
      break;
    case MappingsMode.audience:
      Backbone.history.navigate(
        `/org/${tenant}/evr_insights/audience_mapping_output/0`,
        true
      );
      break;
    case MappingsMode.attribute:
      Backbone.history.navigate(
        `/org/${tenant}/evr_insights/attribute_mapping_output/0`,
        true
      );
      break;
    default:
      Backbone.history.navigate(
        `/org/${tenant}/evr_insights/event_mapping_output/0`,
        true
      );
  }
}
