import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import app from '../../app';
import { BackboneViewProps } from '../../types';
// React comps
import MainComponent from './reactComponents/MainComponent';
import { getAttributes, getModels, isOneModelLive } from './utils';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_to_salesforce': 'go_to_salesforce',
    'click #go_to_itnegrations': 'go_to_integrations',
    'click #go_to_integration_board': 'go_to_integration_board',
  },

  async initialize(options: BackboneViewProps) {
    this.isUserAdmin = app.session.get('user').role === 'admin';
    this.userEmail = app.session.get('user').email;
    this.isAttributeMappingConfigured = false;
    this.tenant = options.tenant;
    await this.launchSwalLoading();
    this.render();
    this.renderMainReactComponent();
  },
  renderMainReactComponent() {
    ReactDOM.render(
      <MainComponent
        isAttributeMappingConfigured={this.isAttributeMappingConfigured}
        isModelLive={!!this.isModelLive}
        tenant={this.tenant}
        email={this.userEmail}
      />,
      document.getElementById('MainComponentContainer')
    );
  },
  async launchSwalLoading() {
    return Swal.fire({
      title: 'Loading',
      text: 'Wait until the data is loaded...',
      icon: 'info',
      timerProgressBar: true,
      allowOutsideClick: () => !Swal.isLoading(),
      willOpen: async () => {
        Swal.showLoading();
        try {
          this.attributes = await getAttributes(this.tenant);
          if (this.attributes?.data) {
            if (this.attributes?.data.domainMapping?.domainFields?.length > 0) {
              this.isAttributeMappingConfigured = true;
            }
          } else if (this.attributes?.domainMapping?.domainFields) {
            this.isAttributeMappingConfigured = true;
          }
          this.models = await getModels(this.tenant);
          this.isModelLive = isOneModelLive(this.models);
          Swal.hideLoading();
          Swal.clickConfirm();
          return true;
        } catch (e) {
          Swal.hideLoading();
          Swal.clickConfirm();
          return false;
        }
      },
    }).then((result: any) => {
      if (result.value) {
        Swal.fire({
          title: 'Data loaded',
          text: 'Data loaded!',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: "Data didn't load",
          text: 'Something went wrong...!',
          icon: 'error',
        });
      }
    });
  },
  render() {
    const html = template({});
    this.$el.html(html);
    return this;
  },

  go_to_salesforce() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/salesforce/push`,
      true
    );
  },
  go_to_integrations() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_to_integration_board() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },
});
