var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (active, name) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E\u003Cdiv class=\"p-4\" id=\"campaign_container\"\u003E\u003Cdiv\u003E\u003Cdiv\u003E\u003Cbutton class=\"btn btn-primary\" id=\"btn_add_campaign\"\u003E\u003Ci class=\"fa fa-plus-circle ml-\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Cspan\u003ENew campaign\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"cards\" id=\"mki_forms\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_campaign_summary\"\u003E\u003Cdiv class=\"row m-2\"\u003E \u003Cdiv class=\"col-md-11\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["card","mki_edit",{"border-primary": active, "border-secondary": !active}], [false,false,true]), false, true)+" style=\"cursor: pointer\"") + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["card-body",{"text-primary": active, "text-secondary": !active}], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-md-2\"\u003E";
if (active) {
pug_html = pug_html + "\u003Cspan\u003EOn\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan\u003EOff\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-10\"\u003E\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-1\" style=\"line-height: 55px\"\u003E\u003Cbutton class=\"btn btn-danger mki_delete\"\u003E\u003Ci class=\"fa fa-trash\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "active" in locals_for_with ?
        locals_for_with.active :
        typeof active !== 'undefined' ? active : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;