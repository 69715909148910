import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export type Integration = {
  name: string;
  tenant: number;
  full_pull: {
    active: boolean;
  };
  credentials?: any;
};

export function useIntegrationQuery({
  tenant,
  integration,
}: {
  tenant: number;
  integration: string;
}) {
  return useQuery({
    queryKey: [tenant, 'integrations', integration],
    queryFn: async () => {
      const { data } = await axios.get<Integration>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}`
      );

      return data;
    },
  });
}
