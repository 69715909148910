import * as React from 'react';
import { Icon, IconName } from './Icon';

type Props = {
  icon?: IconName;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
} & React.HTMLProps<HTMLInputElement>;

export const Input = React.forwardRef<HTMLInputElement, Props>(function Input(
  props: Props,
  ref
) {
  const { className, icon, loading = false, disabled, ...rest } = props;
  return (
    <div className={`tw-relative tw-flex tw-w-full tw-items-center`}>
      {icon && (
        <Icon
          name={icon}
          className="tw-absolute tw-left-3 tw-text-gray-200"
          width={18}
        />
      )}
      {loading && (
        <Icon
          name="load"
          className="tw-absolute tw-right-2"
          width={18}
          height={18}
        />
      )}
      <input
        className={`tw-block tw-h-10 tw-w-full tw-appearance-none tw-rounded-md tw-border tw-border-gray-200 tw-px-3 tw-text-sm tw-placeholder-gray-300 tw-shadow focus:tw-border-blue-600 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-600 ${
          icon ? 'tw-pl-11 tw-pr-3' : 'tw-px-3'
        } ${className || ''}`}
        ref={ref}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
});
