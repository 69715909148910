import React, { useEffect, useState } from 'react';
import { faPersonRunningFast } from '@fortawesome/pro-solid-svg-icons/faPersonRunningFast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { FormLine, Form, Button, Icon, Input } from '../../components/ui';
import ProfileCard from './ProfileCard';
import CompanyCard from './CompanyCard';
import useFetchProfileData from '../hooks/api/useFetchProfileData';
import useFetchModels from '../hooks/api/useFetchModels';
import useDelayedText from '../hooks/useDelayedText';
import { SEARCH_SCORE_DELAYED_TEXT } from '../constants';
import Alert from '../../components/Alert';
import ScoreCard from './ScoreCard';
import ProfileAdmin from './ProfileAdmin';

interface ProfileContainerProps {
  tenant: number;
  userEmail: string;
  updateQueryString: (email: string) => void;
  queryParams: Record<string, any>;
  isAdminChecked: boolean;
}

export default function ProfileV2({
  tenant,
  updateQueryString,
  queryParams,
  userEmail,
  isAdminChecked,
}: ProfileContainerProps) {
  const queryEmail = queryParams?.email ?? '';

  const [searchState, changeSearchState] = useState<
    'success' | 'error' | 'idle'
  >('idle');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({ defaultValues: { email: queryEmail } });

  const {
    data,
    mutate: searchEmail,
    isError: isErrorProfileData,
    isLoading: isLoadingProfileData,
  } = useFetchProfileData(
    tenant,
    userEmail,
    () => changeSearchState('success'),
    () => changeSearchState('error')
  );

  const { data: models, isFetching: isLoadingModels } = useFetchModels(tenant);

  // search on page load if email in query params
  useEffect(() => {
    if (queryEmail) searchEmail(queryEmail);
  }, []);

  const isLoading = isLoadingProfileData || isLoadingModels;

  const delayedLoadingText = useDelayedText(
    SEARCH_SCORE_DELAYED_TEXT,
    isLoading && !isErrorProfileData
  );

  const backgroundClassName =
    'tw-fade-in tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-full';

  return (
    <div className="tw-relative tw-w-full tw-px-6 tw-min-w-70 tw-font-sans">
      <div className="tw-max-w-84 tw-w-full tw-mx-auto tw-my-12">
        {isAdminChecked && !isLoading && data?.personResult ? (
          <ProfileAdmin
            tenant={tenant}
            personResult={data.personResult}
            email={getValues('email')}
          />
        ) : (
          <>
            <div className="tw-rounded-lg tw-flex tw-flex-col">
              <span className="tw-text-sm tw-text-gray-600">
                Enter an email below to view their MadKudu score and data:
              </span>
              <Form
                onSubmit={handleSubmit(async (values: { email: string }) => {
                  const emailValue = values.email.trim();
                  setValue('email', emailValue);
                  updateQueryString(emailValue);
                  searchEmail(emailValue);
                })}
              >
                <FormLine>
                  <Input
                    icon="search"
                    placeholder="Enter an email"
                    autoFocus
                    {...register('email', {
                      required: true,
                      pattern: /^\S+@\S+\.\S+/i,
                    })}
                  />
                  <Button type="submit" loading={isLoadingProfileData}>
                    {isLoadingProfileData ? 'Searching' : 'View score'}
                  </Button>
                </FormLine>
              </Form>
              <div className="tw-inline-flex tw-mb-6">
                {errors.email && (
                  <Alert variant="error">Please provide a valid email</Alert>
                )}
                <div className="tw-flex-grow" />
                <div>
                  {delayedLoadingText && (
                    <Alert variant={'suggestion'} className="tw-fade-in">
                      {delayedLoadingText}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
            {searchState === 'success' && (
              <div className="fade-in">
                <div className="tw-grid tw-grid-cols-2 tw-gap-6">
                  <ProfileCard
                    predictResult={data?.predictResult}
                    personResult={data?.personResult}
                    isLoading={isLoadingProfileData}
                  />
                  <CompanyCard
                    predictResult={data?.predictResult}
                    personResult={data?.personResult}
                    isLoading={isLoadingProfileData}
                  />
                </div>
                <ScoreCard
                  computations={data?.computations}
                  email={getValues('email')}
                  isLoadingModels={isLoadingModels}
                  isLoadingProfileData={isLoadingProfileData}
                  models={models}
                  personResult={data?.personResult}
                  tenant={tenant}
                />
              </div>
            )}
            {searchState === 'idle' && ( // default page
              <div className={`${backgroundClassName} tw-mt-[10vh]`}>
                <Icon
                  name="profiles"
                  width={300}
                  height={300}
                  className="tw-opacity-10"
                />
              </div>
            )}
            {searchState === 'error' && (
              <div className={`${backgroundClassName} tw-pt-[16vh]`}>
                <FontAwesomeIcon
                  icon={faPersonRunningFast}
                  size="10x"
                  flip="horizontal"
                  className="tw-opacity-10 tw-mb-3"
                />
                <Alert variant={'warning'}>
                  {
                    <>
                      <div>
                        Uh-oh! Looks like the score ran away while I was chasing
                        it down.
                      </div>
                      <div>
                        Mind giving me a minute to round it back up and try
                        again later? 🏃‍♂️💨
                      </div>
                    </>
                  }
                </Alert>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
