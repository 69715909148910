import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faPipe } from '@fortawesome/pro-light-svg-icons/faPipe';
import {
  Computation,
  EnrichmentCategory,
  ModelSubset,
  Segment,
  SegmentsColors,
} from '../../../../types';
import OverrideCard from './OverrideCard';
import { Tooltip } from '../../../../../components/ui';

type Props = {
  personProperties: Record<string, any>;
  tenant: number;
  model: ModelSubset;
  segmentBeforeOverride: Segment;
  computations: Computation[];
  setSearch: Dispatch<SetStateAction<string>>;
  setSelectedCategory: Dispatch<SetStateAction<EnrichmentCategory>>;
};

export default function OverrideDiagnosis({
  personProperties,
  tenant,
  model,
  segmentBeforeOverride,
  computations,
  setSearch,
  setSelectedCategory,
}: Props) {
  return (
    <div className="tw-relative tw-flex">
      <div className="tw-absolute tw-cursor-default tw-left-2 tw-right-0 -tw-bottom-20 tw-text-center tw-border-t tw-border-gray-400 tw-py-2 tw-text-sm tw-font-semibold">
        <Tooltip
          maxWidth={260}
          text={`Business rules added to the Customer Fit model override the results provided by historical data analysis. Click the Studio link to change these rules.`}
          position="bottom"
        >
          <span>
            Business overrides
            <FontAwesomeIcon
              icon={faCircleInfo}
              size="lg"
              className="tw-ml-2"
            />
          </span>
        </Tooltip>
        <FontAwesomeIcon
          icon={faPipe}
          size="sm"
          className="tw-absolute tw-left-0 -tw-top-[6px] tw-text-gray-400"
        />
        <FontAwesomeIcon
          icon={faPipe}
          size="sm"
          className="tw-absolute tw-right-0 -tw-top-[6px] tw-text-gray-400"
        />
      </div>
      <div className="tw-relative tw-ml-20 tw-flex tw-items-center tw-px-4">
        <div className="tw-absolute tw-bottom-0 tw-top-0 -tw-right-[4.5rem] tw-m-auto tw-flex tw-h-[1px] tw-w-[5rem] tw-origin-top-left tw-translate-x-1 tw-items-center tw-justify-center  tw-border-t-2 tw-border-dashed tw-border-white">
          <FontAwesomeIcon
            icon={faCaretRight}
            size="xl"
            className="tw-absolute -tw-right-1 -tw-top-[2px] tw-bottom-0 tw-m-auto tw-text-white"
          />
        </div>
        <span className="tw-absolute tw-bottom-0 tw-top-0 -tw-right-[0.5rem] tw-m-auto tw-block tw-h-3 tw-w-3 tw-rounded-full tw-bg-white"></span>
        <OverrideCard
          personProperties={personProperties}
          tenant={tenant}
          model={model}
          computations={computations}
          setSearch={setSearch}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
      <div className="tw-relative tw-ml-20 tw-flex tw-justify-center tw-items-center tw-px-4">
        <Tooltip
          maxWidth={260}
          position="top"
          text={`${
            segmentBeforeOverride !== personProperties.segment
              ? `After the override has been applied, the segment changed from "${segmentBeforeOverride}" to "${personProperties.segment}"`
              : `After the override, the segment did not change and remained at "${personProperties.segment}"`
          }`}
        >
          <div className=" tw-flex tw-flex-col tw-gap-1 tw-justify-center tw-items-center">
            <span className="tw-text-xl tw-font-bold tw-inline-block -tw-mt-8">
              {personProperties.score}
            </span>

            <span
              className={`tw-z-10 tw-cursor-default tw-rounded-md tw-bg-[${
                SegmentsColors[
                  personProperties.segment as
                    | 'low'
                    | 'medium'
                    | 'good'
                    | 'very good'
                ]
              }] tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-capitalize tw-text-white tw-whitespace-nowrap`}
            >
              {personProperties.segment}
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
