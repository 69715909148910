enum DataSources {
  salesforce = 'salesforce',
  salesforce_tasks = 'salesforce_tasks',
  salesforce_campaigns = 'salesforce_campaigns',
  hubspot = 'hubspot',
  analytics = 'analytics',
  stripe = 'stripe',
}

export default DataSources;
