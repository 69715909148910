import Backbone from 'backbone';
import app from '../app';
import { BackboneViewProps } from '../types';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go-conversion-mapping': 'go_conversion_mapping',
    'click #go-attribute-mapping': 'go_attribute_mapping',
    'click #go-event-mapping': 'go_event_mapping',
    'click #go-data-discovery': 'go_data_discovery',
    'click #go-audience-mapping': 'go_audience_mapping',
  },
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.flags = app.session.tenant.flags;
    this.render();
  },

  go_conversion_mapping() {
    Backbone.history.navigate(
      `/org/${this.tenant}/mapping/conversion_mapping`,
      true
    );
  },

  go_attribute_mapping() {
    Backbone.history.navigate(
      `/org/${this.tenant}/mapping/attribute_mapping/overview`,
      true
    );
  },

  go_event_mapping() {
    Backbone.history.navigate(
      `/org/${this.tenant}/mapping/event_mapping`,
      true
    );
  },

  go_audience_mapping() {
    Backbone.history.navigate(`/org/${this.tenant}/mapping/audiences`, true);
  },

  render() {
    const html = template();
    this.$el.html(html);
    return this;
  },
});
