import FieldDataModel from './FieldDataModel';

export class ConnectorFieldNameDataModel {
  title: string;

  fields: FieldDataModel[];

  constructor(other?: ConnectorFieldNameDataModel) {
    if (other) {
      this.title = other.title;
      this.fields = other.fields;
    } else {
      this.title = '';
      this.fields = [];
    }
  }
}

export type ParametersFieldsNamesDataModel = {
  [key: string]: ConnectorFieldNameDataModel[];
};
