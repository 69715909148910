import { useEffect, DependencyList, EffectCallback } from 'react';

/** Like `EffectCallback` but async compatible. */
export type AsyncEffectCallback =
  | EffectCallback
  | (() => Promise<ReturnType<EffectCallback>>);

/**
 * Like `useEffect` but async compatible. Shorthand to:
 * ```ts
 * useEffect(() => {
 *   (async () => {
 *      await fn();
 *   })();
 * }, deps)
 * ```
 */
export default function useAsyncEffect(
  fn: AsyncEffectCallback,
  deps: DependencyList = []
) {
  return useEffect(() => {
    (async () => {
      await fn();
    })();
  }, deps);
}
