import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import ModelDataModel from '../../../models/model/ModelDataModel';
import { ModelSubset } from '../../types';
import { transformModelName } from '../../component/utils';

function useFetchModels(tenant: number) {
  return useQuery<ModelSubset[]>(
    ['profile-models', tenant],
    async () => {
      const { data: modelMetadata } = await axios.get<ModelDataModel[]>(
        `${BONGO_URL}/v1/org/${tenant}/models`
      );

      let models: ModelSubset[] = modelMetadata
        ?.filter(
          ({ live, type, realtime }) =>
            type === 'customer_fit' && live && realtime
        )
        .sort(({ is_primary }) => (is_primary ? -1 : 1))
        .map(({ name, is_primary, modelId }) => ({
          name: is_primary ? `(Standard) ${name}` : name,
          key: is_primary
            ? 'customer_fit'
            : `customer_fit_${transformModelName(name)}`,
          isDefault: false,
          modelId,
        }));

      // if no live models take default one
      if (!models.length) {
        models = [
          {
            name: '(Default) Customer fit',
            key: 'customer_fit',
            isDefault: true,
            modelId: -1,
          },
        ];
      }
      return models;
    },
    {
      staleTime: Infinity,
      retry: false,
    }
  );
}

export default useFetchModels;
