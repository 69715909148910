import React from 'react';
import Alert from '../../components/Alert';

export default function PremiumIntegrationWarning() {
  return (
    <div className="p-2 tw-w-full">
      <Alert variant="warning">
        <div className="p-2">
          This premium integration is not available in your current plan. Please
          reach out to your CSM to activate it.
        </div>
      </Alert>
    </div>
  );
}
