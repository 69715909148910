import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type SegmentCustomPropertyStatistics = {
  name: string;
  lastOccurrence: Date;
  occurrenceRatio: number;
  types: string[];
};

function useSegmentIntrospectSchema(tenant: number) {
  return useQuery<SegmentCustomPropertyStatistics[]>(
    ['segment-introspect-schema', tenant],
    async () => {
      const { data: schema } = await axios.get<
        SegmentCustomPropertyStatistics[]
      >(`${BONGO_URL}/v1/org/${tenant}/integrations/segment/introspect-schema`);
      return schema;
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
}

export default useSegmentIntrospectSchema;
