import capitalize from 'lodash/capitalize';
import React, { useState } from 'react';
import { ApolloConfiguration } from './ApolloConfiguration';
import { ApolloOverview } from './ApolloOverview';
import { Loading } from './Loading';
import { useApolloIsConnected } from './hooks/useApolloIsConnected';

const TABS = ['overview', 'configuration'] as const;
export type Tab = typeof TABS[number];
const ONLY_ADMIN_TABS: Tab[] = ['configuration'];

type Props = {
  tenant: number;
  hasAccessToConfiguration: boolean;
  initialTab: Tab;
  onActiveTabChange: (tab: Tab) => void;
};

function ApolloIntegration({
  tenant,
  onActiveTabChange,
  initialTab,
  hasAccessToConfiguration,
}: Props) {
  const { isLoading } = useApolloIsConnected(tenant);
  const availableTabs = hasAccessToConfiguration
    ? TABS
    : TABS.filter((tab) => !ONLY_ADMIN_TABS.includes(tab));

  const [activeTab, setActiveTab] = useState<Tab>(initialTab);

  function changeActiveTab(tab: Tab) {
    setActiveTab(tab);
    onActiveTabChange(tab);
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
        <ul className="nav nav-pills mx-auto">
          {availableTabs.map((tabName) => (
            <li
              className="nav-item tw-inline-flex tw-items-center"
              key={tabName}
            >
              <a
                className={`nav-link ${
                  activeTab === tabName ? 'active show' : ''
                }`}
                id={tabName}
                data-toggle="tab"
                href={`#${tabName}`}
                onClick={() => changeActiveTab(tabName)}
                role="tab"
              >
                {capitalize(tabName)}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      {availableTabs.map((tabName, index) => (
        <div className="row ml-4" key={`tab_content_${index}`}>
          <div className="tab-content w-100" id="TabContent">
            <div
              key={`tab_content_${tabName}`}
              className={`tab-pane fade ${
                activeTab === tabName ? 'active show' : ''
              }`}
              id={`${tabName}-tab`}
              role="tabpanel"
            >
              <div className="row justify-content-center">
                <div className="col-8 mt-4 mb-4">
                  <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                    <div className="card-header">
                      <div className="d-flex align-items-center">
                        <img
                          className="con-map-mk-integration-logo-size"
                          style={{ width: '30px' }}
                          src={`/media/integrations/logos/apollo-simple.svg`}
                        />
                        <h5 className="mb-0 ml-3">Apollo.io</h5>
                      </div>
                    </div>

                    <div className="card-body">
                      {isLoading && (
                        <Loading loadingLabel="Checking Apollo connection" />
                      )}
                      {tabName === 'overview' && !isLoading && (
                        <ApolloOverview
                          tenant={tenant}
                          changeActiveTab={changeActiveTab}
                          hasAccessToConfiguration={hasAccessToConfiguration}
                        />
                      )}
                      {tabName === 'configuration' && !isLoading && (
                        <ApolloConfiguration tenant={tenant} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export { ApolloIntegration };
