import React from 'react';
import { Connectors } from '../types';

class ConnectorCard extends React.Component<any> {
  render() {
    const { connector, connectorEnabled, connectorSelection } = this.props;
    return (
      <div className="row mt-4 mb-4 mr-2">
        <div className="col-3 ml-3">
          <div
            className={`card box-shadow-3d hover-size-increase ${
              connectorEnabled ? 'bg-success' : 'none'
            }`}
            style={{ width: '18rem', height: '18rem', cursor: 'pointer' }}
          >
            <div
              className="form-check"
              style={{ height: '100%', padding: '0%' }}
            >
              <input
                className="form-check-input"
                type="checkbox"
                style={{ width: '100%', height: '100%', opacity: '0%' }}
                value={connectorEnabled}
                onChange={connectorSelection}
                id={connector}
                checked={connectorEnabled}
              />
              <label
                className="form-check-label"
                htmlFor="defaultCheck1"
                style={{ width: '100%', height: '100%' }}
              >
                <div
                  className="card-body m-2 rounded d-flex justify-content-center"
                  style={{
                    backgroundColor: '#00A1E0',
                    width: '92%',
                    height: '90%',
                  }}
                >
                  <div
                    className="card-body m-2 rounded box-shadow-3d d-flex flex-wrap align-items-center"
                    style={{ backgroundColor: 'white' }}
                  >
                    <img
                      className="img-fluid my-auto"
                      src={`/media/integrations/logos/${connector}.svg`}
                      alt="Card image cap"
                    ></img>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type ConnectorChoiceProps = {
  handleConnectorSelection: Function;
  connectors: Connectors;
  activeConnectors: string[];
};

export default class ConnectorChoice extends React.Component<
  ConnectorChoiceProps
> {
  render() {
    return (
      <div className="row d-flex justify-content-center align-items-center">
        {this.props.activeConnectors.map((connector: string) => {
          return (
            <ConnectorCard
              key={connector}
              connector={connector}
              connectorEnabled={this.props.connectors[connector]}
              connectorSelection={this.props.handleConnectorSelection}
            />
          );
        })}
      </div>
    );
  }
}
