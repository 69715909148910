import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSlackIsConnected } from './hooks/useSlackIsConnected';
import { Loading } from './Loading';
import { ConnectionView } from './configuration-views/ConnectionView';
import { ConnectedView } from './configuration-views/ConnectedView';
import { useSlackSubscriptions } from './hooks/useSlackSubscriptions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PremiumIntegrationWarning from '../utils/PremiumIntegrationWarning';

type Props = {
  tenant: number;
};

export function SlackOverview({ tenant }: Props) {
  const { data: isSlackConnected, isLoading } = useSlackIsConnected(tenant);
  const { isLoading: isLoadingSubscriptions } = useSlackSubscriptions(tenant);
  const { enableSocialSignals } = useFlags();

  const isConnected = isSlackConnected && enableSocialSignals;

  if (isLoading || isLoadingSubscriptions)
    return <Loading loadingLabel="Checking Slack connection" />;

  return (
    <div className="d-flex">
      <div className="row w-75 mr-4">
        <div className="col">
          <div className="row justify-space-between">
            <div className="p-2">
              <h5>Overview</h5>
              <div>
                Connect your Slack community to MadKudu to start using signals
                like people joining, posting or reacting to messages.
              </div>
            </div>

            {!enableSocialSignals && <PremiumIntegrationWarning />}

            <div className="p-2 tw-w-full">
              {!isSlackConnected && <ConnectionView tenant={tenant} />}
              {isSlackConnected && <ConnectedView tenant={tenant} />}
            </div>
          </div>
        </div>
      </div>
      <div className="w-25">
        <p>
          <b>Status</b>
          <span className="d-block pt-1 cursor-pointer d-block">
            {isConnected && (
              <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                <FontAwesomeIcon
                  icon={faGear}
                  width="14"
                  height="14"
                  className="mr-1"
                />
                Connected
              </b>
            )}
            {!isConnected && (
              <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                <FontAwesomeIcon
                  icon={faGear}
                  width="14"
                  height="14"
                  className="mr-1"
                />
                Not Connected
              </b>
            )}
          </span>
        </p>
        <p className="mt-4">
          <b>Links</b>
        </p>
        <p>
          <a
            href="https://help.madkudu.com/docs/how-to-connect-your-slack-community-to-madkudu"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to connect your Slack community to MadKudu
          </a>
        </p>
      </div>
    </div>
  );
}
