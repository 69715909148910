import { useQuery } from '@tanstack/react-query';
import MappingsMode from '../../../../enums/MappingsMode';
import { getScriptCheckerStateBasedOnMappingMode } from '../../../../utils';

function useScriptChecker(tenant: number, mode: MappingsMode) {
  const {
    data: scriptCheckerStatus,
    isLoading: isLoadingScriptCheckerStatus,
    refetch: refreshScriptCheckerStatus,
  } = useQuery([`script-checker-status-${tenant}-${mode}`], async () => {
    const res = await getScriptCheckerStateBasedOnMappingMode(tenant, mode);
    return res.data;
  });
  return {
    scriptCheckerStatus,
    isScriptCheckerActive: !!scriptCheckerStatus,
    isLoadingScriptCheckerStatus,
    refreshScriptCheckerStatus,
  };
}
export type ScriptCheckerManager = ReturnType<typeof useScriptChecker>;
export default useScriptChecker;
