import { ChartDataSets, ChartOptions, ChartTooltipItem } from 'chart.js';
import numeral from 'numeral';
import React from 'react';
import ChartComponent, { Bar, ChartData, Line, Bubble } from 'react-chartjs-2';
import { COLORS, BUBBLE_COLORS } from '../../../predictions/utils';
import {
  ChartDefinition,
  ChartDefinitionAndData,
  ChartType,
} from '../../InsightsTypes';
import { isNumber } from '../../utils';

function getColor(chartDefinition: ChartDefinition) {
  if (chartDefinition.color) {
    if (chartDefinition.color === 'mk_blue') {
      return COLORS[0];
    }
    return chartDefinition.color;
  }
}

export function getChartOptions(
  chartDefinition: ChartDefinition,
  datasetsSize: number
): ChartOptions {
  const legend = {
    position: 'right',
    display:
      chartDefinition.type === 'bubble'
        ? datasetsSize <= 15
        : datasetsSize <= 15,
  } as any;
  const scales = {
    xAxes: [
      {
        stacked: chartDefinition.stacked,
        ticks: {
          autoSkip: false,
        },
        beginAtZero: chartDefinition.axis?.x?.beginAtZero,
        scaleLabel: {
          display: true,
          fontSize: 15,
          labelString: chartDefinition.axis.x.label,
        },
      },
    ],
    yAxes: [
      {
        stacked: chartDefinition.stacked,
        ticks: {
          callback: (value: any) => {
            return numeral(value).format('0,0');
          },
          beginAtZero: chartDefinition.axis?.y?.beginAtZero,
        },
        scaleLabel: {
          display: true,
          fontSize: 15,
          labelString: chartDefinition.axis.y.label,
        },
      },
    ],
  };

  function formatTooltipLabel(
    tooltipItem: ChartTooltipItem,
    data: ChartData<any>
  ) {
    const { datasetIndex } = tooltipItem;
    const { index } = tooltipItem;
    const yValue = data.datasets[datasetIndex].data[index].y;
    return `${data.datasets[datasetIndex].label}: ${yValue}`;
  }

  function formatTooltipTitle(items: ChartTooltipItem[], data: ChartData<any>) {
    const item = items[0];
    const { datasetIndex } = item;
    const { index } = item;
    return data.datasets[datasetIndex].data[index].x;
  }

  const plugins = {
    datalabels: {
      color: 'white',
      display: chartDefinition.type === 'bubble' ? false : 'auto',
      rotation: chartDefinition.type !== 'bubble' ? 270 : null,
      formatter(value: { x: string; y: number }) {
        return numeral(value.y).format('0,0');
      },
    },
  };
  switch (chartDefinition.type) {
    case 'bar':
    case 'line':
    case 'scatter':
    case 'bubble':
      return {
        legend,
        scales,
        plugins,
        tooltips: {
          callbacks: {
            label: formatTooltipLabel,
            title: formatTooltipTitle,
          },
        },
      };
    default:
  }
}

/*
  Get charts labels. Always getting the values of the first chart.
*/
export function getLabels(
  chartDefinitionAndData: ChartDefinitionAndData
): string[] {
  const { definition, data } = chartDefinitionAndData;
  const firstChart = definition.charts[0];

  const unsortedUniqueLabels = [
    ...new Set(data.map((row) => row[firstChart.axis.x.variable])),
  ];

  // Check if labels are dates or numbers (including number strings)
  const isNumberLabel = unsortedUniqueLabels.every(isNumber);

  return unsortedUniqueLabels.sort((a, b) =>
    isNumberLabel ? a - b : String(a).localeCompare(String(b))
  );
}

export function getDataForBarChart(
  chart: ChartDefinition,
  dataForDataset: UnknownArrayOfObjects,
  allLabels: string[]
) {
  return allLabels.map((label) => {
    const rowForLabel = dataForDataset.find(
      (row) => row[chart.axis.x.variable] === label
    );
    return {
      x: label,
      y: rowForLabel ? rowForLabel[chart.axis.y.variable] : 0,
    };
  });
}

export function getDataForOtherChart(
  chart: ChartDefinition,
  dataForDataset: UnknownArrayOfObjects
) {
  return dataForDataset.map((row) => ({
    x: row[chart.axis.x.variable],
    y: row[chart.axis.y.variable],
  }));
}

export function getDataForBubbleChart(
  chart: ChartDefinition,
  dataForDataset: UnknownArrayOfObjects
) {
  const data = dataForDataset.map((row) => ({
    r: row[chart.radius.r.variable],
    x: row[chart.axis.x.variable],
    y: row[chart.axis.y.variable],
  }));
  return data;
}

export function getDataset(
  dataset: string,
  chart: ChartDefinition,
  data: UnknownArrayOfObjects,
  order: number,
  allLabels: string[],
  backgroundColor: string
): ChartDataSets {
  const dataForDataset = data.filter(
    (row) => row[chart.dataset_identifier] === dataset
  );

  let chartData;
  switch (chart.type) {
    case 'bar':
      chartData = getDataForBarChart(chart, dataForDataset, allLabels);
      break;
    case 'bubble':
      chartData = getDataForBubbleChart(chart, dataForDataset);
      break;
    default:
      chartData = getDataForOtherChart(chart, dataForDataset);
  }

  switch (chart.type) {
    case 'bar':
      return {
        backgroundColor,
        type: chart.type,
        label: dataset,
        data: chartData,
      };
    case 'line':
      return {
        backgroundColor,
        borderColor: backgroundColor,
        type: chart.type,
        label: dataset,
        data: chartData,
        fill: chart.fill ? chart.fill : false,
      };
    case 'scatter':
      return {
        backgroundColor: COLORS[0],
        type: chart.type,
        label: dataset,
        data: chartData,
        fill: false,
      };
    case 'bubble':
      return {
        backgroundColor,
        type: chart.type,
        label: dataset,
        order,
        data: chartData,
        fill: false,
        lineTension: 1,
        borderWidth: 3,
        borderColor: backgroundColor,
        borderCapStyle: 'butt',
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 2,
        pointHitRadius: 2,
      };
    default:
  }
}

export function getDatasets(
  charts: ChartDefinition[],
  data: UnknownArrayOfObjects,
  allLabels: string[]
): ChartDataSets[] {
  // eslint-disable-next-line
  return [].concat.apply(
    [],
    charts.map((chart) => {
      const datasetsIds: string[] = [
        ...new Set(data.map((row) => row[chart.dataset_identifier])),
      ];

      const colors = chart.type === 'bubble' ? BUBBLE_COLORS : COLORS;
      const chartDatasets = datasetsIds.map((dataset, i) => {
        const ds: ChartDataSets = getDataset(
          dataset,
          chart,
          data,
          i,
          allLabels,
          getColor(chart) ? getColor(chart) : colors[i]
        );
        return ds;
      });
      return chartDatasets;
    })
  );
}

export function getChartComponent(type: ChartType): typeof ChartComponent {
  switch (type) {
    case 'bar':
      return Bar;
    case 'line':
    case 'scatter':
      return Line;
    case 'bubble':
      return Bubble;
    default:
  }
}

type CustomChartJSComponentProps = {
  chartDefinitionAndData: ChartDefinitionAndData;
};

export default class CustomChartJSComponent extends React.Component<
  CustomChartJSComponentProps,
  {}
> {
  render() {
    const { chartDefinitionAndData } = this.props;
    const { definition, data } = chartDefinitionAndData;
    const labels: string[] = getLabels(chartDefinitionAndData);
    const datasets: ChartDataSets[] = getDatasets(
      definition.charts,
      data,
      labels
    );
    const options = getChartOptions(definition.charts[0], datasets.length);
    const chartData: ChartData<Chart.ChartData> = {
      labels,
      datasets,
    };
    const Component = getChartComponent(definition.charts[0].type);

    return <Component data={chartData} options={options as any} />;
  }
}
