import axios from 'axios';
import Backbone from 'backbone';
import capitalize from 'lodash/capitalize';
import uniq from 'lodash/uniq';
import Swal from 'sweetalert2';
import { ConnectorFieldNameDataModel } from '../../mapping/models/ConnectorFieldNameDataModel';
import FieldDataModel from '../../mapping/models/FieldDataModel';

export function getPredictLeadsActiveFlag(flags: UnknownObject[]) {
  return flags.some((model) => {
    return model.attributes.active && model.name === 'predictleads_active';
  });
}

export function parsePushFields(data: UnknownObject) {
  const rows: UnknownObject[] = [];
  const dataKeys = Object.keys(data);

  // get list of all different fields (lead, contact, persons, etc)
  const rawValueTypes = dataKeys.reduce(
    (acc, mkField) => acc.concat(...Object.keys(data[mkField])),
    []
  );
  const allValueTypes = ['MadKudu', ...uniq(rawValueTypes)];

  dataKeys.forEach((mkField) => {
    const partialRow = Object.keys(data[mkField]).reduce(
      (acc: UnknownObject, valueType) => {
        acc[valueType] = data[mkField][valueType];
        return acc;
      },
      {}
    );
    rows.push({
      MadKudu: mkField,
      ...partialRow,
    });
  });

  return {
    rows,
    allValueTypes,
  };
}

export function cleanPullConfigData(
  data: Record<string, { attributes: FieldDataModel[] }>
): ConnectorFieldNameDataModel[] {
  return Object.entries(data).map(([key, item]) => {
    const fields: FieldDataModel[] = item.attributes.map((field: any) => {
      return new FieldDataModel(field.name);
    });
    return new ConnectorFieldNameDataModel({
      title: key,
      fields,
    });
  });
}

export function cleanPushConfigData(data: UnknownObject) {
  const { Contact: rawContact, Account: rawAccount } = data;

  // if no data
  if (!Object.keys(rawContact).length && !Object.keys(rawAccount).length) {
    return [];
  }

  const cleanedData = [
    {
      title: 'Contact',
      ...parsePushFields(rawContact),
    },
  ];

  // if no data under "account"
  if (!Object.keys(rawAccount).length) return cleanedData;

  cleanedData.push({
    title: 'Account',
    ...parsePushFields(rawAccount),
  });

  return cleanedData;
}

export async function getPullConfigForTenantAndIntegration(
  tenantId: number,
  integration: string
): Promise<ConnectorFieldNameDataModel[]> {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/pull`
  );
  return cleanPullConfigData(data.config);
}

export async function getPushConfigForTenantAndIntegration(
  tenantId: number,
  integration: string
) {
  const { data } = await axios.get(
    `${BONGO_URL}/v1/org/${tenantId}/integrations/${integration}/push`
  );
  return cleanPushConfigData(data);
}

export async function deleteIntegrationAndLaunchPopUp(
  tenant: number,
  integration: string,
  callback?: () => void,
  hasPull?: boolean
) {
  try {
    const deleteIntegrationMixinSwal = Swal.mixin({
      customClass: {
        confirmButton: 'ml-2 btn btn-danger',
        cancelButton: 'btn btn-outline-primary',
      },
      buttonsStyling: false,
    });

    const res = await deleteIntegrationMixinSwal.fire({
      title: `Delete connection between MadKudu and ${capitalize(
        integration
      )}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Delete connection',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      text: `If you delete this connection, MadKudu will stop pulling and pushing data to ${integration}. To reconnect this integration, you will need to set it up again. Are you sure you want to delete the ${integration} connection?`,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        await Promise.all([
          axios.delete(
            `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}`,
            {
              params: { attributes: ['credentials'] },
            }
          ),
          ...(hasPull !== false
            ? [
                axios.delete(
                  `${BONGO_URL}/v1/org/${tenant}/processes/${encodeURIComponent(
                    `connectors/${integration}/pull`
                  )}`
                ),
                axios.delete(
                  `${BONGO_URL}/v1/org/${tenant}/processes/${encodeURIComponent(
                    `connectors/${integration}_full/pull`
                  )}`
                ),
              ]
            : []),
        ]);
      },
    });
    if (res.isDismissed) return false;
    Swal.fire({
      title: `MadKudu has been disconnected from your ${capitalize(
        integration
      )} account`,
      icon: 'success',
    });
    if (callback) callback();
    // reload page
    Backbone.history.loadUrl();
  } catch (_e) {
    Swal.fire({
      text:
        'We were not able to delete your credentials, notify our team at product@madkudu.com for us to look into it.',
      icon: 'error',
    });
  }
}
