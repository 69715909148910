import React from 'react';
import ModelDataModel from '../../../models/model/ModelDataModel';
import { MappingConversionsTypes } from '../../../types';
import {
  extractActiveConversionTypeFromAudience,
  getAudiencesNames,
  getModelData,
} from '../../../utils';
import {
  DEFAULT_NAME_CONVERSION_SQO_DEFINITION,
  extractLeadsAndRecallsFromAudiencePerformanceData,
  getModelLabels,
  getMostRelevantDefaultAudienceName,
} from '../../utils';
import ChartsOverTimeComponent from './ChartsOverTimeComponent';
import DiagnosticsAudienceFilterHeaderComponent from './DiagnosticsAudienceFilterHeaderComponent';
import DiagnosticsHeaderComponent from './DiagnosticsHeaderComponent';
import GlobalOverviewChartComponent from './GlobalOverviewChartComponent';
import PredictedValueChartComponent from './PredictedValueChartComponent';
import SignalsComponent from './SignalsComponent';

type DiagnosticsParentComponentProps = {
  tenant: number;
  modelId: number;
  context: string;
  isSuperkudu: boolean;
  demo: boolean;
  goTo: (url: string) => void;
};

type DiagnosticsParentComponentState = {
  selectedAudienceName: string;
  audiencesNames: string[];
  selectedConversionType: string;
  activeConversionTypes: string[];
  model: ModelDataModel;
  isLoading: boolean;
  noData: boolean;
  noAudienceData: boolean;
};

export default class DiagnosticsParentComponent extends React.Component<
  DiagnosticsParentComponentProps,
  DiagnosticsParentComponentState
> {
  constructor(props: DiagnosticsParentComponentProps) {
    super(props);
    this.state = {
      audiencesNames: [],
      selectedAudienceName: 'all',
      model: null,
      isLoading: true,
      noData: true,
      activeConversionTypes: [],
      selectedConversionType: DEFAULT_NAME_CONVERSION_SQO_DEFINITION,
      noAudienceData: true,
    };

    this.getPerformancesDataForAudience = this.getPerformancesDataForAudience.bind(
      this
    );
    this.getCurrentAudiencePerformancesData = this.getCurrentAudiencePerformancesData.bind(
      this
    );
    this.handleOnAudienceNameChange = this.handleOnAudienceNameChange.bind(
      this
    );
    this.handleOnConversionTypeChange = this.handleOnConversionTypeChange.bind(
      this
    );
  }

  getPerformancesDataForAudience(model: ModelDataModel, audienceName: string) {
    const { context } = this.props;

    const dataName =
      context === 'lead_grade'
        ? 'lead_grade_audience_performance_data'
        : 'audience_performance_data';

    return model[dataName] ? model[dataName][audienceName] : null;
  }

  getCurrentAudiencePerformancesData() {
    const { model, selectedAudienceName } = this.state;
    return this.getPerformancesDataForAudience(model, selectedAudienceName);
  }

  async componentDidMount() {
    const { tenant, modelId } = this.props;

    this.setState({
      isLoading: true,
    });

    const listOfPromises = [
      getAudiencesNames(tenant),
      getModelData(tenant, modelId),
    ];
    const results = await Promise.all(listOfPromises);
    const audiencesNames = results[0] as string[];

    const model: ModelDataModel = new ModelDataModel(results[1]);

    const selectedAudienceName = getMostRelevantDefaultAudienceName(
      audiencesNames,
      model.type
    );

    const currentAudiencePerformanceData = this.getPerformancesDataForAudience(
      model,
      selectedAudienceName
    );

    if (!currentAudiencePerformanceData) {
      this.setState({
        selectedAudienceName,
        model,
        isLoading: false,
        noData: model.name === undefined,
        noAudienceData: true,
      });
      return;
    }
    const activeConversionTypes = extractActiveConversionTypeFromAudience(
      // @ts-ignore
      currentAudiencePerformanceData
    );
    const selectedConversionType = activeConversionTypes[0];

    this.setState({
      audiencesNames,
      model,
      selectedAudienceName,
      isLoading: false,
      noData: model.name === undefined,
      activeConversionTypes,
      selectedConversionType,
      noAudienceData: false,
    });
  }

  handleOnAudienceNameChange(newSelectedAudienceName: string) {
    this.setState({
      selectedAudienceName: newSelectedAudienceName,
      noAudienceData: false,
    });
  }

  handleOnConversionTypeChange(
    newSelectedConversionType: MappingConversionsTypes
  ) {
    this.setState({
      selectedConversionType: newSelectedConversionType,
    });
  }

  // render functions
  renderLoader(text: string) {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data">
        <div className="card-body align-middle">
          <div className="spinner-border float-left mr-2"></div>
          <h5>{text}</h5>
        </div>
      </div>
    );
  }

  renderGlobalOverviewChart() {
    const { tenant, context, goTo } = this.props;
    const { model, selectedConversionType } = this.state;
    const currentAudiencePerformanceData = this.getCurrentAudiencePerformancesData();

    const leadsAndRecallsForAudience = extractLeadsAndRecallsFromAudiencePerformanceData(
      currentAudiencePerformanceData,
      context === 'lead_grade' ? 'lead_grade' : model.type,
      []
    );
    const leadsAndRecallsForAudienceAndConversionType =
      leadsAndRecallsForAudience[selectedConversionType];
    return (
      <GlobalOverviewChartComponent
        tenant={tenant}
        modelType={context === 'lead_grade' ? 'lead_grade' : model.type}
        leadsAndRecallsForAudienceAndConversion={
          leadsAndRecallsForAudienceAndConversionType
        }
        lastUpdate={
          model.updated_at && new Date(model.updated_at).toISOString()
        }
        goTo={goTo}
      />
    );
  }

  renderEvolutionOverTimeChart() {
    const { tenant, context, goTo } = this.props;
    const { model, selectedConversionType } = this.state;
    const currentAudiencePerformanceData = this.getCurrentAudiencePerformancesData();
    const currentAudienceCurrentConversionPerformanceData =
      currentAudiencePerformanceData[selectedConversionType as any];
    const { periods } = currentAudienceCurrentConversionPerformanceData as any;
    const leadsAndRecallsForAudience = extractLeadsAndRecallsFromAudiencePerformanceData(
      currentAudiencePerformanceData,
      context === 'lead_grade' ? 'lead_grade' : model.type,
      periods
    );
    const leadsAndRecallsForAudienceAndConversionType =
      leadsAndRecallsForAudience[selectedConversionType];

    return (
      <ChartsOverTimeComponent
        tenant={tenant}
        modelType={context === 'lead_grade' ? 'lead_grade' : model.type}
        leadsAndRecallsForAudienceAndConversion={
          leadsAndRecallsForAudienceAndConversionType
        }
        goTo={goTo}
      />
    );
  }

  renderPredictedValueChart() {
    const { model, selectedConversionType } = this.state;
    const currentAudiencePerformanceData = this.getCurrentAudiencePerformancesData();
    const currentAudienceCurrentConversionPerformanceData =
      currentAudiencePerformanceData[selectedConversionType as any];
    const { periods } = currentAudienceCurrentConversionPerformanceData as any;
    const labels = getModelLabels(model.type).reverse();

    const currentAudienceClosedWonPerformanceData: any =
      currentAudiencePerformanceData['Closed Won' as any];
    const { conversion_distribution } =
      currentAudienceClosedWonPerformanceData || {};
    return (
      <PredictedValueChartComponent
        dates={periods}
        labels={labels}
        predictedValue={conversion_distribution}
      />
    );
  }

  renderSignals() {
    const { demo } = this.props;
    if (demo) return <SignalsComponent />;
    return <div />;
  }

  renderContent() {
    const { model, noAudienceData } = this.state;

    if (
      noAudienceData &&
      !model.lead_grade_audience_performance_data &&
      !Object.keys(model.audience_performance_data).length
    ) {
      return (
        <div>
          {this.renderLoader(
            'Data processing in progress. Please come back later'
          )}
        </div>
      );
    }
    if (noAudienceData) {
      return (
        <div>
          {this.renderLoader(
            'No audiences has been configured for this model.'
          )}
        </div>
      );
    }
    return (
      <div>
        {this.renderGlobalOverviewChart()}
        {this.renderEvolutionOverTimeChart()}
        {model.type === 'customer_fit' && (
          <div>{this.renderPredictedValueChart()}</div>
        )}
        {this.renderSignals()}
      </div>
    );
  }

  renderBody() {
    const { noData } = this.state;

    return (
      <div>
        {noData
          ? this.renderLoader(
              'Your model has just been deployed and we are still processing your data. Please check back in 4 hours.'
            )
          : this.renderContent()}
      </div>
    );
  }

  renderHeader() {
    const { isSuperkudu, tenant, context, goTo } = this.props;
    const {
      activeConversionTypes,
      selectedConversionType,
      audiencesNames,
      selectedAudienceName,
      model,
    } = this.state;

    const data =
      model[
        context === 'lead_grade'
          ? 'lead_grade_audience_performance_data'
          : 'audience_performance_data'
      ];

    const availableAudiences = data ? Object.keys(data) : [];
    const availableConversions = availableAudiences.length
      ? Object.keys(data[availableAudiences[0]])
      : [];

    return (
      <div>
        <DiagnosticsHeaderComponent
          tenant={tenant}
          model={model}
          context={context}
          goTo={goTo}
        />
        <DiagnosticsAudienceFilterHeaderComponent
          tenant={tenant}
          model={model}
          isSuperKudu={isSuperkudu}
          activeConversionTypes={activeConversionTypes}
          availableConversions={availableConversions}
          selectedConversionType={selectedConversionType}
          handleOnConversionTypeChange={this.handleOnConversionTypeChange}
          audiencesNames={audiencesNames}
          availableAudiences={availableAudiences}
          selectedAudienceName={selectedAudienceName}
          handleOnAudienceNameChange={this.handleOnAudienceNameChange}
        />
      </div>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        <div>{!isLoading && this.renderHeader()}</div>
        <div className="container mt-4 pb-5">
          {isLoading ? this.renderLoader('Loading...') : this.renderBody()}
        </div>
      </div>
    );
  }
}
