import React from 'react';
import Backbone from 'backbone';
import { Card } from '../../../../../components/Card';

type DefaultConnectorsOverviewComponentProps = {
  tenant: number;
};

export default class DefaultConnectorsOverviewComponent extends React.Component<
  DefaultConnectorsOverviewComponentProps,
  {}
> {
  constructor(props: DefaultConnectorsOverviewComponentProps) {
    super(props);
    // Binds
    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.goToSalesforceIntegration = this.goToSalesforceIntegration.bind(this);
    this.goToHubspotIntegration = this.goToHubspotIntegration.bind(this);
    this.goBackToConversionMapping = this.goBackToConversionMapping.bind(this);
  }

  goBackToMapping() {
    Backbone.history.navigate(`/org/${this.props.tenant}/mapping`, true);
  }

  goBackToConversionMapping() {
    Backbone.history.navigate(
      `/org/${this.props.tenant}/mapping/conversion_mapping/overview`,
      true
    );
  }

  goToSalesforceIntegration() {
    Backbone.history.navigate(
      `/org/${this.props.tenant}/integrations/salesforce`,
      true
    );
  }

  goToHubspotIntegration() {
    Backbone.history.navigate(
      `/org/${this.props.tenant}/integrations/hubspot`,
      true
    );
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToConversionMapping}
              >
                Conversion Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Connector Overview
            </li>
          </ol>
        </nav>
        <div className="shadow-sm p-3 mb-5 bg-white rounded text-center border-top">
          <strong>Step 1/3: </strong>Select the data source(s) where your
          conversions are stored
        </div>
        <div className="d-flex justify-content-center">
          <Card cardTitle="Missing Integration">
            <div>
              <p>
                It looks like you need to connect a data source before we can
                map your conversions.
              </p>
              <p>Please choose your primary CRM and connect it.</p>
              <div className="row">
                <div className="col-4">
                  <button
                    className="btn btn-primary"
                    onClick={this.goToHubspotIntegration}
                  >
                    Connect Hubspot &rarr;
                  </button>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <button
                    className="btn btn-primary"
                    onClick={this.goToSalesforceIntegration}
                  >
                    Connect Salesforce &rarr;
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
