import * as React from 'react';
import uniqueId from 'lodash/uniqueId';

type Props = {
  label?: any;
  disabled?: boolean;
  id?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & React.HTMLProps<HTMLInputElement>;

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  function Checkbox(props: Props, ref) {
    const {
      disabled = false,
      id = uniqueId('cb-'),
      className,
      ...rest
    } = props;

    return (
      <>
        <input
          id={id}
          ref={ref}
          className={`tw-h-5 tw-w-5 tw-cursor-pointer tw-appearance-none tw-rounded tw-border tw-border-gray-200 tw-text-blue-600 tw-outline-none tw-ring-0 tw-ring-offset-0 focus:tw-outline-none focus:tw-ring-0 focus:tw-ring-offset-0  ${
            className || ''
          }`}
          type="checkbox"
          onChange={props.onChange}
          disabled={disabled}
          {...rest}
        />
        <label
          htmlFor={id}
          className="tw-ml-2 tw-mb-0 tw-inline-block tw-cursor-pointer tw-text-sm tw-text-gray-700"
        >
          {props.label}
        </label>
      </>
    );
  }
);
