import { D3BasicSelection, GaugeConfigWithRanges, TicksConfig } from '../types';
import { transformValueToAngle } from '../utils';

export default function drawTicks(
  svg: D3BasicSelection,
  gaugeConfig: GaugeConfigWithRanges,
  ticksConfig: TicksConfig
) {
  const radius = gaugeConfig.diameter / 2;

  const ticksSvg = svg.append('g');

  const defaultTicks = ticksConfig?.ticks?.length
    ? ticksConfig.ticks
    : gaugeConfig.valuesRange;

  defaultTicks.forEach((tick) => {
    const angle = transformValueToAngle(
      tick,
      gaugeConfig.valuesRange,
      gaugeConfig.anglesRange
    );
    const middleAngle = transformValueToAngle(
      50,
      gaugeConfig.valuesRange,
      gaugeConfig.anglesRange
    ); // angle in deg
    const cursorRotate = middleAngle - angle;

    const tickSvg = ticksSvg
      .append('g')
      .attr('width', 10)
      .attr('height', radius)
      .attr('transform', `rotate(${angle})`);

    tickSvg
      .append('text')
      .text(tick)
      .attr(
        'transform',
        `translate(0, -${radius + ticksConfig.margin}) rotate(${cursorRotate})`
      )
      .attr('fill', ticksConfig.color)
      .attr('text-anchor', tick < 50 ? 'end' : tick > 50 ? 'start' : 'middle')
      .attr('alignment-baseline', 'middle');

    if (ticksConfig.fontSize) tickSvg.attr('font-size', ticksConfig.fontSize);
  });
}
