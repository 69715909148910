import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../components/ui';
import { Loading } from '../Loading';
import { useDisconnectSlack } from '../hooks/useDisconnectSlack';
import { useSlackChannels } from '../hooks/useSlackChannels';
import { slackChannelsSchema } from '../types';
import MultiSelect from '../../../components/select/MultiSelect';
import { useSlackSubscriptions } from '../hooks/useSlackSubscriptions';
import { useChangeSlackSubscriptions } from '../hooks/useChangeSlackSubscriptions';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  tenant: number;
};
export function ConnectedView({ tenant }: Props) {
  const { control, handleSubmit, setValue } = useForm({
    reValidateMode: 'onChange',
    resolver: zodResolver(slackChannelsSchema),
    defaultValues: {
      channels: [],
    },
  });

  const { enableSocialSignals } = useFlags();

  const { mutate: disconnect, isLoading: isDisconnecting } = useDisconnectSlack(
    tenant
  );

  const {
    data: channels,
    isFetching: isLoadingSlackChannels,
  } = useSlackChannels(tenant);
  const {
    data: followedChannels,
    isFetching: isLoadingSlackIntegration,
  } = useSlackSubscriptions(tenant);

  const {
    mutate,
    isError,
    isLoading: isSavingSubscriptions,
    isSuccess,
  } = useChangeSlackSubscriptions(tenant);

  useEffect(() => {
    if (followedChannels) setValue('channels', followedChannels);
  }, [followedChannels, setValue]);

  const onSubmit = (data: any) => {
    mutate(data.channels);
  };

  const channelOptions =
    channels
      ?.map((channel) => ({
        value: channel.name,
        label: channel.name,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ) || [];

  const isLoadingSlack = isLoadingSlackChannels || isLoadingSlackIntegration;

  if (isDisconnecting || isLoadingSlack)
    return (
      <div className="d-flex justify-content-center py-3">
        {isDisconnecting && <Loading loadingLabel="Disconnecting from Slack" />}
        {isLoadingSlack && <Loading loadingLabel="Loading Slack channels" />}
      </div>
    );

  return (
    <>
      <span>
        <b>Channels to follow</b>
      </span>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="channels"
          control={control}
          render={({ field }) => (
            <div className="tw-mt-5">
              <MultiSelect
                placeholder="Select channels"
                options={channelOptions}
                values={channelOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(newValues) =>
                  field.onChange(newValues.map((option) => option.value))
                }
                disabled={isSavingSubscriptions || !enableSocialSignals}
              />
            </div>
          )}
        />

        <div className="d-flex tw-mt-5 gap-4">
          <Button
            type="submit"
            disabled={isSavingSubscriptions || !enableSocialSignals}
            color={isSavingSubscriptions ? 'white' : 'blue'}
          >
            {isSavingSubscriptions ? 'Saving...' : 'Save'}
          </Button>
          <Button
            color="red"
            onClick={() => disconnect()}
            disabled={isSavingSubscriptions}
          >
            Disconnect
          </Button>
        </div>

        {isError && (
          <p className="tw-text-red-500 tw-mt-2">
            Encountered an error while saving channel subscriptions. Please try
            again.
          </p>
        )}
        {isSuccess && (
          <p className="tw-text-green-500 tw-mt-2">
            Subscriptions saved successfully!
          </p>
        )}
      </form>
    </>
  );
}
