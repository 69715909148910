import axios from 'axios';
import differenceWith from 'lodash/differenceWith';
import DataSources from '../../../enums/DataSources';
import { getConnectorPullConfigFields } from '../../../pullConfigManager';
import EventMappingConnectorsNames from '../../../enums/EventMappingConnectorsNames';
import {
  CONNECTORS_NAMES,
  DATA_DISCOVERY_EVENT_KEYS,
  HUBSPOT_EVENTS,
  MARKETO_EVENTS,
  SALESFORCE_CAMPAIGN_OBJECTS,
  SALESFORCE_TASKS_OBJECTS,
} from '../../constants';
import { ErrorDataModel } from '../../models/ErrorDataModel';
import {
  CONDITION_LOGIC_CHECK_REGEX,
  OBJECT_PLACEHOLDER_VALUE,
  SUBJECT_PLACEHOLDER_VALUE,
  VERB_PLACEHOLDER_VALUE,
} from '../../../constants';
import { ConditionFormData, Event } from '../../models/types';

export function getUpperCaseConnector(connector: string) {
  return `
    ${connector[0].toUpperCase()}${connector.slice(1).replace(/_/g, ' ')}
  `;
}

export function getHighLevelConnector(connector: string) {
  if (connector.includes('salesforce')) return 'salesforce';
  return connector;
}

export function convertToMkEventStyle(
  str: string,
  connector: string = '',
  compaignMemberStatus: string = ''
) {
  if (connector === CONNECTORS_NAMES.salesforce_campaigns) {
    return `${compaignMemberStatus} to ${str}`;
  }
  return str
    .split('_')
    .map((word) => {
      return word
        .charAt(0)
        .toUpperCase()
        .concat(word.substring(1).toLowerCase());
    })
    .join(' ');
}

export async function getConnectorEvents(
  tenant: number,
  connector: string
): Promise<UnknownObject> {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}/data/discovery/${connector}`;
    const result = await axios.get(url);
    return (result as any) || {};
  } catch (e) {
    return {};
  }
}

export async function getDiscoveryEvents(tenant: number, connector: string) {
  const highLevelConnector = connector.includes('salesforce')
    ? 'salesforce'
    : connector;
  const { data } = await getConnectorEvents(tenant, highLevelConnector);
  const campaignKey = DATA_DISCOVERY_EVENT_KEYS[connector];
  if (!data || !data.dataset || !data.dataset[campaignKey]) return [];

  return data.dataset[campaignKey]
    .reduce((acc: string[], campaign: UnknownObject) => {
      let eventName;
      switch (connector) {
        case CONNECTORS_NAMES.salesforce_campaigns:
          eventName = 'campaign_type';
          break;
        case CONNECTORS_NAMES.salesforce_tasks:
          eventName = 'a_type';
          break;
        default:
          eventName = 'event';
      }
      const discoveryItem = String(campaign[eventName]);

      if (!acc.includes(discoveryItem)) acc.push(discoveryItem);
      return acc;
    }, [])
    .sort();
}

export async function getDiscoveryCampaignMemberStatuses(
  tenant: number
): Promise<string[]> {
  const { data } = await getConnectorEvents(tenant, 'salesforce');
  const campaignKey = DATA_DISCOVERY_EVENT_KEYS.salesforce_campaigns;
  if (!data || !data.dataset || !data.dataset[campaignKey]) return [];

  return [
    ...new Set<string>(
      data.dataset[campaignKey]
        .map((campaign: UnknownObject) => campaign.campaign_member_status)
        .sort()
    ),
  ];
}

/**
 * Get the fields value, in case of using a drop down menu in the event fields(first column)
 * @param connector
 * @param tenant, used only in case of salesforce(for now), by default 0
 * @returns {string[]|Promise<any[]|T[]>}
 */
export async function getEventsValuesFieldsByConnector(
  connector = CONNECTORS_NAMES.marketo ||
    CONNECTORS_NAMES.salesforce_campaigns ||
    CONNECTORS_NAMES.hubspot,
  tenant = 0
) {
  switch (connector) {
    case CONNECTORS_NAMES.marketo:
      return getDiscoveryEvents(tenant, connector);
    case CONNECTORS_NAMES.salesforce_campaigns:
      // eslint-disable-next-line no-return-await
      return getConnectorPullConfigFields(
        tenant,
        DataSources.salesforce_campaigns,
        SALESFORCE_CAMPAIGN_OBJECTS[0]
      );
    case CONNECTORS_NAMES.salesforce_tasks:
      return getConnectorPullConfigFields(
        tenant,
        DataSources.salesforce_tasks,
        SALESFORCE_TASKS_OBJECTS[0]
      );
    case CONNECTORS_NAMES.hubspot: {
      const { data: flagData } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/flags/enabled_hubspot_pull_web_events`
      );
      if (!flagData?.active) return HUBSPOT_EVENTS;

      const url = `${BONGO_URL}/v1/org/${tenant}/data/discovery/hubspot`;
      const { data } = await axios.get(url);

      const allEvents: UnknownObject[] =
        data?.dataset?.event_type_distribution ?? [];
      const allEventsName: string[] = allEvents.map(
        (rowEvent) => rowEvent.event
      );

      const webEvents = allEventsName.filter((event) => event.startsWith('e_'));
      const distinctWebEvents = [...new Set(webEvents)];

      return [...HUBSPOT_EVENTS, ...distinctWebEvents];
    }
    default:
      return MARKETO_EVENTS; // by default(for now) return marketo events
  }
}

export function lowerCaseAndReplaceSpaces(string: string) {
  return string
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('_');
}

/**
 * Return a new default Condition
 * @returns {{subject, values: [string], lower: boolean, verb: string, object: (string)}}
 */
export function createNewDefault(connector: string) {
  const object = OBJECT_PLACEHOLDER_VALUE;
  let subject;
  if (connector === CONNECTORS_NAMES.hubspot) {
    subject = 'not_used';
  } else if (connector === CONNECTORS_NAMES.salesforce_campaigns) {
    subject = SUBJECT_PLACEHOLDER_VALUE;
  } else if (connector === CONNECTORS_NAMES.salesforce_tasks) {
    subject = SUBJECT_PLACEHOLDER_VALUE;
  } else {
    subject = 'not_used';
  }
  const verb = VERB_PLACEHOLDER_VALUE;

  const firstValue = '';
  const lower = false;
  return {
    object,
    subject,
    verb,
    values: [firstValue],
    lower,
  };
}

export function deactivateConnector(
  connector: EventMappingConnectorsNames,
  disabledConnectors: string[]
): string[] {
  if (connector === EventMappingConnectorsNames.madMl) {
    return [...new Set([connector])];
  }
  return [...new Set([...disabledConnectors, connector])];
}

export function activateConnector(
  connector: EventMappingConnectorsNames,
  disabledConnectors: string[]
): string[] {
  if (connector === EventMappingConnectorsNames.madMl) {
    return [
      'hubspot',
      'salesforce_campaigns',
      'salesforce_tasks',
      'marketo',
      'mixpanel',
      'kissmetrics',
      'amplitude',
      'segment',
    ];
  }
  const newDisabledConnectors = disabledConnectors.filter(
    (c) => c !== connector
  );
  if (!newDisabledConnectors.includes(EventMappingConnectorsNames.madMl)) {
    newDisabledConnectors.push(EventMappingConnectorsNames.madMl);
  }
  return newDisabledConnectors;
}

type EventReport = ConditionFormData & { errors?: ErrorDataModel[] };
export function checkConditionFormDataErrors(
  conditionFormData: ConditionFormData
): {
  errors: ErrorDataModel[];
  errorsTestedConditionFormData: EventReport;
  areErrorsDetected: boolean;
} {
  const errors: ErrorDataModel[] = [];
  const errorsTestedConditionFormData: EventReport = conditionFormData;
  if (errorsTestedConditionFormData) {
    errorsTestedConditionFormData.errors = [];
    if (
      !conditionFormData?.conditionsLogic?.length &&
      conditionFormData?.conditions?.length > 0
    ) {
      errors.push({
        index: 0,
        level: 'condition logic - empty',
      });
    }
    const usedIndexes = conditionFormData?.conditionsLogic
      ?.match(/\d/gi)
      ?.map((index: string) => Number(index));
    const conditionIndexes: number[] = [];
    conditionFormData?.conditions?.forEach((condition: any, index: number) => {
      conditionIndexes.push(index + 1);
      if (
        condition.verb &&
        [VERB_PLACEHOLDER_VALUE, ''].includes(condition.verb.trim())
      ) {
        errors.push({
          index,
          level: 'verb',
        });
      }
      if (
        condition.subject &&
        [SUBJECT_PLACEHOLDER_VALUE, ''].includes(condition.subject.trim())
      ) {
        errors.push({
          index,
          level: 'subject',
        });
      }
      if (
        condition.object &&
        [OBJECT_PLACEHOLDER_VALUE, ''].includes(condition.object)
      ) {
        errors.push({
          index,
          level: 'object',
        });
      }
      if (condition.values.map((v: string) => v.trim()).includes('')) {
        errors.push({
          index,
          level: 'values',
        });
      }
    });
    const ignoredIndexes = differenceWith(conditionIndexes, usedIndexes);
    const extraIndexes = differenceWith(usedIndexes, conditionIndexes);
    const syntaxCheckResult = conditionFormData?.conditionsLogic?.match(
      CONDITION_LOGIC_CHECK_REGEX
    );
    if (
      conditionFormData?.conditionsLogic?.length > 0 &&
      conditionFormData?.conditions?.length > 0
    ) {
      if (!syntaxCheckResult) {
        errors.push({
          index: 0,
          level: 'condition logic - syntax error',
        });
      }
      if (ignoredIndexes?.length) {
        errors.push({
          index: 0,
          level: 'condition logic - ignored indexes',
          indexes: ignoredIndexes,
        });
      }
      if (extraIndexes?.length) {
        errors.push({
          index: 0,
          level: 'condition logic - extra indexes',
          indexes: extraIndexes,
        });
      }
    }
    if (errors?.length > 0) {
      errorsTestedConditionFormData.errors = errors;
    } else {
      delete errorsTestedConditionFormData.errors;
    }
  }
  return {
    errors,
    errorsTestedConditionFormData,
    areErrorsDetected: errors?.length > 0,
  };
}

export function checkEventErrors(
  events: any[]
): {
  postTestedEvents: any[];
  isErrorsExists: boolean;
} {
  let isErrorsExists: boolean = false;
  const postTestedEvents = events.map((event) => {
    const postTestedEvent = event;
    const {
      errorsTestedConditionFormData,
      areErrorsDetected,
    } = checkConditionFormDataErrors(event.conditionFormData);
    if (areErrorsDetected) {
      isErrorsExists = true;
    }
    postTestedEvent.conditionFormData = errorsTestedConditionFormData;
    return postTestedEvent;
  });
  return {
    postTestedEvents,
    isErrorsExists,
  };
}

export async function getPrePopulatedEvents(
  tenant: number,
  connector: string
): Promise<Event[]> {
  try {
    const url = `${BONGO_URL}/v1/org/${tenant}/data/mappings/events/${connector}/pre_populated`;
    const { data } = await axios.get(url);
    return data;
  } catch (err) {
    return [];
  }
}
