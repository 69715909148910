import axios from 'axios';
import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import ModelsTableComponent from './ModelsTableComponent';

const modelsTemplate = require('./models.pug');
require('bootstrap-table/dist/bootstrap-table');

export default Backbone.View.extend({
  async initialize() {
    const { data = [] } = await axios.get(`${BONGO_URL}/v1/models`);
    this.models = data;
    this.render();
    this.modelsTable();
  },

  modelsTable() {
    ReactDOM.render(
      <ModelsTableComponent models={this.models} />,
      $('#models')[0]
    );
  },

  render() {
    this.$el.html(modelsTemplate());
    return this;
  },
});
