import axios from 'axios';
import Backbone from 'backbone';
import $ from 'jquery';
import app from '../app';
import { BackboneViewProps } from '../types';
import Tenant from './model_tenant_admin';

const template = require('./api_usage.pug');
const template_403 = require('../errors/403.pug');

const DEFAULT_QUOTA = {
  demographics: {},
  predictions: {},
};

const ApiUsageView = (Backbone as any).Epoxy.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${this.tenant}/quota`
    );
    this.model = new Tenant({ tenant: this.tenant });
    const quota = data || DEFAULT_QUOTA;
    const modelQuota = {
      demographics_requests_per_month:
        quota.demographics && quota.demographics.requests_per_month,
      predictions_requests_per_month:
        quota.predictions && quota.predictions.requests_per_month,
      requests_per_minute: quota.requests_per_minute,
    };
    const quotaUsage = await axios.get(
      `${BONGO_URL}/v1/org/${this.tenant}/quota/usage`
    );
    const usage: UnknownObject = {
      tenant: this.tenant,
      user: app.session.user.attributes,
      demographics_this_month:
        quotaUsage.data.demographics && quotaUsage.data.demographics.this_month,
      predictions_this_month:
        quotaUsage.data.predictions && quotaUsage.data.predictions.this_month,
      this_minute: quotaUsage.data.this_minute,
    };

    usage.demographics_this_month = quotaUsage.data.demographics.this_month;
    usage.predictions_this_month = quotaUsage.data.predictions.this_month;
    // calculate usage percentage
    usage.demographics_this_month_percent = modelQuota.demographics_requests_per_month
      ? Math.round(
          (100 * usage.demographics_this_month) /
            modelQuota.demographics_requests_per_month
        )
      : 0;
    usage.predictions_this_month_percent = modelQuota.predictions_requests_per_month
      ? Math.round(
          (100 * usage.predictions_this_month) /
            modelQuota.predictions_requests_per_month
        )
      : 0;
    usage.this_minute_percent = modelQuota.requests_per_minute
      ? Math.round((100 * usage.this_minute) / modelQuota.requests_per_minute)
      : 0;

    // build text
    usage.demographics_this_month_percent_text = `${usage.demographics_this_month_percent} %`;
    usage.predictions_this_month_percent_text = `${usage.predictions_this_month_percent} %`;
    usage.this_minute_percent_text = `${usage.this_minute_percent} %`;

    const usageData = { ...usage, ...modelQuota };
    this.model.set(usageData);
    this.render(usageData);
  },

  render(usage: {}) {
    const html = $('#tpl_admin', template(usage)).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },
});

export default Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.admin_view = new ApiUsageView({ tenant: this.model.get('_id') });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    const html = $('#tpl_main', template({ tenant: this.tenant })).html();
    this.$el.html(html);
    this.assign(this.admin_view, '#tenant_info');
    return this;
  },
});
