import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { useQueryClient } from '@tanstack/react-query';
import { useAddIntegrationMutation } from '../../../api/useAddIntegrationMutation';
import { useDeleteIntegrationMutation } from '../../../api/useDeleteIntegrationMutation';
import { useIntegrationQuery } from '../../../api/useIntegrationQuery';
import { Button, Icon, Tooltip } from '../../../components/ui';
import { RequestIntegrationAccessButton } from '../../components/RequestIntegrationAccessButton';
import { JobChangesConfig, Tab } from '../types';
import { jobChangesWatchListQueryKey } from '../api/useGetJobChangesWatchListQuery';
import { useGetIntegrationConfigQuery } from '../../../api/useGetIntegrationConfigQuery';

type Props = {
  tenant: number;
  changeActiveTab: (tab: Tab) => void;
};

export function IntegrationOverviewView({ tenant, changeActiveTab }: Props) {
  const queryClient = useQueryClient();

  const {
    mutate: deleteIntegration,
    isLoading: isDeletingIntegration,
  } = useDeleteIntegrationMutation({
    onSuccess: async () =>
      queryClient.resetQueries(jobChangesWatchListQueryKey(tenant)),
  });

  const { data: integration } = useIntegrationQuery({
    tenant,
    integration: 'job_changes',
  });

  const { data: jobChangesConfig } = useGetIntegrationConfigQuery<
    JobChangesConfig
  >({
    tenant,
    integration: 'job_changes',
  });

  const {
    mutateAsync: addIntegration,
    isLoading: isAddingIntegration,
  } = useAddIntegrationMutation();

  const { jobChangeEnabled } = useFlags();

  const isIntegrationActive = integration?.full_pull?.active;

  return (
    <div>
      <div className="d-flex">
        <div className="row w-75 mr-4">
          <div className="col">
            <div className="row justify-space-between">
              <div className="tw-pl-2">
                <h5 className="tw-font-bold">Overview</h5>
                <div>
                  Track any job changes in your contact watch list based on
                  LinkedIn data. Know when your champions are switching company
                  or change role. Generate pipeline from playbooks based on
                  former customer joining a new company or manage churn risk
                  when champions are leavin your customer accounts.
                </div>
                <div className="tw-w-[400px] tw-shadow-md tw-rounded-lg tw-mt-6 tw-border tw-border-gray-100 tw-p-3">
                  <img
                    src="/media/demo/job-changes.png"
                    className="tw-w-full tw-h-full tw-rounded-lg"
                  />
                </div>

                <h5 className="tw-font-bold tw-mt-8">
                  Once activated, you will be able to
                </h5>
                <div className="tw-flex tw-flex tw-flex-col tw-gap-2 tw-mt-4">
                  <div className="tw-inline-flex ">
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      className="tw-mr-4 tw-text-lg"
                    />
                    <span>
                      Use playbooks based on people switching companies or roles
                      (See in the{' '}
                      <a
                        href="https://msi.madkudu.com/playbooks/library"
                        target="_blank"
                      >
                        Playbooks Library
                      </a>
                      )
                    </span>
                  </div>
                  <div className="tw-inline-flex">
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      className="tw-mr-4 tw-text-lg"
                    />
                    <span>View monitored contacts job history</span>
                  </div>
                  <div className="tw-inline-flex">
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      className="tw-mr-4 tw-text-lg"
                    />
                    <span>Add them to sequences</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-25">
          <div className="tw-flex tw-flex-col tw-gap-6">
            <div>
              <div className="tw-mb-2 tw-font-bold">Actions</div>
              <div className="tw-space-y-2">
                {!jobChangeEnabled && (
                  <RequestIntegrationAccessButton
                    tenant={tenant}
                    integration="job_changes"
                  />
                )}
                {!isIntegrationActive && jobChangeEnabled && (
                  <Button
                    loading={isAddingIntegration}
                    disabled={isAddingIntegration}
                    onClick={async () => {
                      await addIntegration({
                        name: 'job_changes',
                        tenant,
                        full_pull: { active: true },
                        credentials: {},
                      });
                      changeActiveTab('configuration');
                    }}
                  >
                    Get set up
                  </Button>
                )}
                {isIntegrationActive && jobChangeEnabled && (
                  <Button
                    color="danger"
                    disabled={isDeletingIntegration}
                    loading={isDeletingIntegration}
                    onClick={() =>
                      deleteIntegration({
                        tenant,
                        integration: 'job_changes',
                      })
                    }
                  >
                    {isDeletingIntegration ? 'Disabling...' : 'Disable'}
                  </Button>
                )}
              </div>
            </div>

            <div>
              <div className="tw-mb-2 tw-font-bold">Availability</div>
              <div className="tw-bg-yellow-400 tw-font-medium tw-py-1 tw-px-4 tw-text-white tw-rounded-full tw-w-fit tw-text-sm">
                Premium
              </div>
            </div>
            <div>
              <div className="tw-mb-2 tw-font-bold">Watch list limit</div>
              <div>
                <span>{jobChangesConfig.watch_list_limit} emails </span>
                <Tooltip text="To change your watch list limit, please contact Madkudu.">
                  <span>
                    <Icon name="question" width={15} className="tw-ml-1" />
                  </span>
                </Tooltip>
              </div>
            </div>

            <div>
              <div className="tw-mb-2 tw-font-bold">Links</div>
              <a
                href="https://help.madkudu.com/docs/job-change-signals"
                target="_blank"
              >
                How to get started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
