import React from 'react';
import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';
import { FormDataType } from '../../../types/index';
import { deleteIntegrationAndLaunchPopUp } from '../../utils';

type ConfigurationComponentProps = {
  formData: FormDataType;
  tenant: number;
  apiKey: string;
};

type ConfigurationComponentState = {
  s3Fields: {
    s3_role_arn?: string;
    s3_bucket_region?: string;
    s3_folder_path?: string[];
    external_id?: string;
  };
  isConnectedToS3: boolean;
  isValidationError: boolean;
  isNetworkError: boolean;
  checkedConnection: boolean;
  checkingConnection: boolean;
};

function isAxiosError(input: any): input is AxiosError {
  return !!input?.isAxiosError;
}

export default class ConfigurationComponent extends React.Component<
  ConfigurationComponentProps,
  ConfigurationComponentState
> {
  initialFieldsState: {
    s3_role_arn?: string;
    s3_bucket_region?: string;
    s3_folder_path?: string[];
    external_id?: string;
  };

  constructor(props: ConfigurationComponentProps) {
    super(props);

    this.state = {
      s3Fields: {
        ...this.props.formData,
        s3_folder_path: this.props.formData
          ? this.props.formData.s3_folder_path
          : [''],
        external_id: this.props.apiKey,
      },
      isConnectedToS3: false,
      isValidationError: false,
      isNetworkError: false,
      checkedConnection: false,
      checkingConnection: false,
    };
    this.initialFieldsState = {
      s3_role_arn: '',
      s3_bucket_region: '',
      s3_folder_path: [''],
      external_id: '',
    };
    this.addField = this.addField.bind(this);
    this.handlePropertyChange = this.handlePropertyChange.bind(this);
    this.saveCredentials = this.saveCredentials.bind(this);
    this.checkConnection = this.checkConnection.bind(this);
    this.deleteCredentials = this.deleteCredentials.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeField = this.removeField.bind(this);
  }

  componentDidUpdate() {
    const { formData } = this.props;
    const { s3Fields } = this.state;
    if (formData) {
      formData.s3_folder_path = s3Fields.s3_folder_path;
      return formData.s3_folder_path;
    }
  }

  handleChange(index: number, value: string) {
    const inputFieldsValues = [...this.state.s3Fields.s3_folder_path];
    inputFieldsValues[index] = value;
    this.setState({
      s3Fields: { ...this.state.s3Fields, s3_folder_path: inputFieldsValues },
    });
  }

  handlePropertyChange(property: string) {
    return (event: any) => {
      this.setState({
        s3Fields: { ...this.state.s3Fields, [property]: event.target.value },
      });
    };
  }

  addField(event: any) {
    event.preventDefault();
    this.setState((prevState) => ({
      s3Fields: {
        ...this.state.s3Fields,
        s3_folder_path: [...prevState.s3Fields.s3_folder_path, ''],
      },
    }));
  }

  removeField(index: number) {
    const inputFieldsValues = [...this.state.s3Fields.s3_folder_path].filter(
      (_, elementIndex) => elementIndex !== index
    );

    this.setState({
      s3Fields: { ...this.state.s3Fields, s3_folder_path: inputFieldsValues },
    });
  }

  async checkConnection() {
    this.setState({
      isConnectedToS3: false,
      checkingConnection: true,
      isNetworkError: false,
      isValidationError: false,
    });
    await axios
      .post(
        `${BONGO_URL}/v1/org/${this.props.tenant}/integrations/s3/ping_s3`,
        {
          s3_role_arn: this.state.s3Fields.s3_role_arn,
          s3_folder_path: this.state.s3Fields.s3_folder_path,
          s3_bucket_region: this.state.s3Fields.s3_bucket_region,
          external_id: this.state.s3Fields.external_id,
        }
      )
      .then(() => {
        this.setState({
          isConnectedToS3: true,
          isNetworkError: false,
          isValidationError: false,
          checkedConnection: true,
          checkingConnection: false,
        });
      })
      .catch((error) => {
        const hasNetworkError =
          isAxiosError(error) && error.message === 'Network Error';

        this.setState({
          isConnectedToS3: false,
          isValidationError: !hasNetworkError,
          isNetworkError: hasNetworkError,
          checkedConnection: true,
          checkingConnection: false,
        });
      });
  }

  resetState() {
    this.setState({
      s3Fields: this.initialFieldsState,
    });
  }

  async deleteCredentials() {
    const { tenant } = this.props;
    this.resetState();
    await deleteIntegrationAndLaunchPopUp(tenant, 's3');
  }

  saveCredentials() {
    const { tenant, formData } = this.props;
    const { s3Fields } = this.state;

    if (formData && s3Fields.s3_folder_path[0] === '') {
      return Swal.fire({
        title: 'Missing folder path',
        text: 'Please input at least one folder path',
      });
    }

    axios
      .post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/s3/credentials?activate_pull=true`,
        s3Fields
      )
      .then(() => {
        Swal.fire({
          title: 'Your info have been successfully saved!',
          icon: 'success',
        });
      })
      .catch(() => {
        Swal.fire({
          text:
            'We were not able to save your credentials, notify our team at product@madkudu.com for us to look into it.',
        });
      });
  }

  render() {
    const { apiKey, formData } = this.props;
    const {
      s3Fields,
      checkedConnection,
      checkingConnection,
      isConnectedToS3,
    } = this.state;

    return (
      <>
        <div className="row">
          <div className="col">
            <h5>How to give MadKudu access to your S3 bucket</h5>
            <p>
              To give MadKudu access to your S3 bucket, you will need to setup
              an IAM role for MadKudu.
              <a
                href="https://help.madkudu.com/docs/amazon-s3-giving-madkudu-access-to-an-s3-bucket"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Here is a step by step documentation
              </a>
            </p>
          </div>
        </div>
        <p className="mt-3">
          You will be prompted to input MadKudu's AWS informations as follow:
        </p>
        <p className="mt-2">
          Account ID: <span className="font-weight-bold">203796963081</span>
        </p>
        <p>
          External ID: <span className="font-weight-bold">{apiKey}</span>
        </p>
        <div className="row mt-3">
          <div className="col">Once the IAM role is setup, please enter:</div>
        </div>
        <div className="row mt-3">
          <div className="col-10">
            <div className="form-group">
              <label htmlFor="roleArn" className="font-weight-bold">
                The Role ARN
              </label>
              <input
                type="text"
                className="form-control"
                value={s3Fields.s3_role_arn}
                onChange={this.handlePropertyChange('s3_role_arn')}
                id="inputRoleArn"
                placeholder="arn:aws:iam::026660334455:role/madkudu-external-read"
              />
            </div>
            <div className="form-group">
              <label htmlFor="bucketRegion" className="font-weight-bold">
                Your bucket region
              </label>
              <input
                type="text"
                className="form-control"
                value={s3Fields.s3_bucket_region}
                id="inputBucketRegion"
                onChange={this.handlePropertyChange('s3_bucket_region')}
                placeholder="us-west-2"
              />
            </div>
            <div>
              <label htmlFor="folderPath" className="font-weight-bold">
                The folder path of the bucket where files MadKudu would look
                into are located
              </label>
              {s3Fields.s3_folder_path.map((input: string, index: number) => (
                <div className="row" key={index}>
                  <div className="col-11">
                    <input
                      type="text"
                      value={input}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        this.handleChange(index, event.target.value);
                      }}
                      className="form-control mt-2"
                      placeholder="s3://[name_of_your_bucket]/[folder]"
                    />
                  </div>
                  <div className="col-1">
                    {index !== 0 && (
                      <button
                        role="button"
                        className="btn btn-danger mt-2"
                        onClick={() => {
                          this.removeField(index);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <button
          className="btn btn-outline-primary mt-3"
          onClick={this.addField}
        >
          + Add Path
        </button>
        <div className="row mt-3">
          <div className="col-2">
            <button
              className="btn btn-outline-primary"
              onClick={this.saveCredentials}
              disabled={!s3Fields.s3_role_arn || !s3Fields.s3_bucket_region}
            >
              {formData && formData.s3_role_arn ? 'Update info' : 'Save info'}
            </button>
          </div>
          <div className="col-3">
            <button
              className="btn btn-outline-primary"
              onClick={this.checkConnection}
              disabled={
                checkingConnection ||
                !s3Fields.s3_role_arn ||
                !s3Fields.s3_bucket_region
              }
            >
              {checkingConnection ? 'Checking...' : 'Check connection'}
            </button>
          </div>

          {checkedConnection && (
            <div className="col-7 my-auto">
              {isConnectedToS3 ? (
                <p className="text-success mb-0">
                  &#x2714; Madkudu connects to your S3 Bucket
                </p>
              ) : (
                <>
                  {this.state.isValidationError && (
                    <p className="text-danger">
                      &#x274C; MadKudu does not seem to have access to your S3
                      bucket. Please make sure your input above is correct or
                      reach out to the Support team
                      <a
                        href="https://madkudusupport.zendesk.com/hc/en-us/requests?new?&tf_subject=Amazon S3 Set Up - Questions&tf_4674786087693=customer_challenge_integration__amazon_s3"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        here
                      </a>
                    </p>
                  )}
                  {this.state.isNetworkError && (
                    <p className="text-danger mb-0">
                      &#x274C; Unable to test your S3 bucket, please check your
                      network.
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <button
          className="btn btn-danger mt-2"
          onClick={this.deleteCredentials}
        >
          Delete credentials
        </button>
        <p className="mt-3 mb-2">
          Any questions? Please reach out to our Support team
          <a
            href="https://madkudusupport.zendesk.com/hc/en-us/requests?new?&tf_subject=Amazon S3 Set Up - Questions&tf_4674786087693=customer_challenge_integration__amazon_s3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            here
          </a>
        </p>
      </>
    );
  }
}
