var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (account_name) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E\u003Cbutton class=\"btn btn-primary stripe-connect\" id=\"mki_oauth\"\u003E\u003Cspan\u003EConnect with Stripe\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fscript\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_connected\"\u003E\u003Cp\u003ESuccessfully connected to Stripe\u003C\u002Fp\u003E\u003Cp\u003EAccount: " + (pug.escape(null == (pug_interp = account_name) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cbutton class=\"btn btn-light\" id=\"mki_oauth_btn\"\u003EUpdate connection\u003C\u002Fbutton\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "account_name" in locals_for_with ?
        locals_for_with.account_name :
        typeof account_name !== 'undefined' ? account_name : undefined));
    ;;return pug_html;};
module.exports = template;