import { DependencyList, useEffect, useRef, useState } from 'react';
import { useWindowSize } from './useWindowSize';

type UseOverflowOptions = {
  deps?: DependencyList;
};

/**
 * Given two exposed React refs `containerRef` and `childRef`, tells you if the child
 * element is overflowing the parent element horizontally (`isOverflowing`).
 */
export function useOverflow(options?: UseOverflowOptions) {
  const containerRef = useRef(null);
  const childRef = useRef(null);
  const [isOverflowing, setOverflowing] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const deps = options?.deps ? [windowWidth, ...options.deps] : [windowWidth];

  useEffect(() => {
    const containerWidth = containerRef?.current?.offsetWidth ?? 0;
    const childWidth = childRef?.current?.offsetWidth ?? 0;
    setOverflowing(containerWidth < childWidth);
  }, deps);

  return { containerRef, childRef, isOverflowing };
}
