import React, { PropsWithChildren } from 'react';

type DisplayableProps = {
  readOnly?: boolean;
  readOnlyContent?: string | number | JSX.Element;
};

export default function Displayable(
  {
    children,
    readOnly,
    readOnlyContent,
  }: PropsWithChildren<DisplayableProps> = { readOnly: true }
) {
  if (readOnly) {
    return <>{readOnlyContent ?? ''}</>;
  }
  return <>{children}</>;
}
