import React from 'react';
import Gauge from '../../components/Gauge/Gauge';
import { Tooltip } from '../../components/ui';
import {
  SEGMENT_CONFIGS,
  getSegmentRange,
  getSegmentSymbol,
} from '../constants';
import { ModelSubset, PersonModelScore } from '../types';
import TopSignals from './TopSignals';

interface Props {
  personModelScore: PersonModelScore;
  model: ModelSubset;
  email: string;
  tenant: number;
}

export default function ScoreResult({
  personModelScore,
  model,
  email,
  tenant,
}: Props) {
  const scoreValue =
    personModelScore.score ??
    (personModelScore.segment
      ? (getSegmentRange(personModelScore.segment)[0] +
          getSegmentRange(personModelScore.segment)[1]) /
        2
      : 0);

  return (
    <>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <svg
          width="30"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.14286 3.0559C7.14286 1.94488 8.10268 1.04348 9.28571 1.04348H10.7143C11.8973 1.04348 12.8571 1.94488 12.8571 3.0559V17.8137C12.8571 18.9247 11.8973 19.8261 10.7143 19.8261H9.28571C8.10268 19.8261 7.14286 18.9247 7.14286 17.8137V3.0559ZM0 11.1056C0 9.99457 0.959821 9.09317 2.14286 9.09317H3.57143C4.75446 9.09317 5.71429 9.99457 5.71429 11.1056V17.8137C5.71429 18.9247 4.75446 19.8261 3.57143 19.8261H2.14286C0.959821 19.8261 0 18.9247 0 17.8137V11.1056ZM16.4286 3.72671H17.8571C19.0402 3.72671 20 4.62811 20 5.73913V17.8137C20 18.9247 19.0402 19.8261 17.8571 19.8261H16.4286C15.2455 19.8261 14.2857 18.9247 14.2857 17.8137V5.73913C14.2857 4.62811 15.2455 3.72671 16.4286 3.72671Z"
            fill="black"
          />
        </svg>
        <span className="tw-font-semibold tw-text-lg">Score result</span>
      </div>
      <div className="tw-text-md tw-text-gray-500 tw-mt-1">
        This result has been calculated for <b>{email}</b> -&nbsp;
        <a
          className="tw-text-blue-700 hover:tw-no-underline hover:tw-text-blue-800"
          href={`https://studio.madkudu.com/tenant/${tenant}/models/${model.modelId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View model in MadKudu Studio&nbsp;
          <svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.9362 4.90489C14.9362 5.37703 14.3657 5.61345 14.0324 5.27809L12.5708 3.81848L8.94704 7.42044C8.8388 7.52869 8.6761 7.52869 8.56587 7.42044L7.62024 6.47482C7.512 6.36458 7.512 6.20188 7.62024 6.09364L11.244 2.49168L9.78236 1.03008C9.449 0.696722 9.68607 0.124298 10.1556 0.124298H14.4062C14.6971 0.124298 14.9382 0.365358 14.9382 0.656211L14.9362 4.90489Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7493 7.82804V10.4843C11.7493 11.8031 10.6789 12.8736 9.35812 12.8736H2.45323C1.13441 12.8736 0.0620117 11.8032 0.0620117 10.4843V3.57736C0.0620117 2.25854 1.13447 1.18614 2.45323 1.18614H8.29562C8.44437 1.18614 8.56058 1.30235 8.56058 1.45309V1.98302C8.56058 2.13376 8.44636 2.24997 8.29562 2.24997H2.45323C1.7221 2.24997 1.12378 2.84629 1.12378 3.57743V10.4844C1.12378 11.2135 1.7221 11.8118 2.45323 11.8118H9.35812C10.0893 11.8118 10.6876 11.2135 10.6876 10.4844V7.82811C10.6876 7.67737 10.8038 7.56116 10.9525 7.56116H11.4844C11.6332 7.56116 11.7493 7.6773 11.7493 7.82804Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
      <div className="tw-grid tw-w-full tw-gap-5 tw-grid-cols-2 tw-mt-6">
        <div className="tw-relative tw-h-[27rem] tw-rounded-lg tw-border tw-border-light tw-bg-white tw-shadow tw-flex tw-flex-col tw-items-center tw-p-7">
          <Tooltip
            avoidCollisions={false}
            position="top"
            offset={20}
            text={
              <div>
                <span className="tw-font-bold tw-mb-2 tw-text-sm tw-block">
                  About the Customer Fit
                </span>
                <p className="tw-text-sm">
                  The score is based on the analysis of your existing customers
                  to predict if this lead is a good fit based on demo, firmo,
                  techno.
                </p>
                <p className="tw-text-sm">
                  Leads with a “very good” customer fit usually convert about 10
                  times more than leads with a “low” customer fit.
                </p>
              </div>
            }
          >
            <div className="tw-w-full tw-mb-3 tw-flex tw-gap-2 tw-justify-center tw-items-center tw-cursor-default">
              <span className="tw-font-medium">Customer Fit</span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className="tw-text-gray-300 tw-absolute tw-right-4"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_147_461)">
                  <path
                    d="M9.00934 3.70006e-06C11.3972 0.00211312 13.6857 0.953446 15.372 2.64438C17.0588 4.33469 18.0038 6.62616 18.0003 9.01404C17.9969 11.4019 17.0441 13.6899 15.3531 15.3752C13.6614 17.0606 11.3691 18.0049 8.98144 18C6.59428 17.9951 4.3063 17.0416 2.62168 15.3491C0.93699 13.6567 -0.00595854 11.3645 0.00052146 8.97678C0.00684954 6.5925 0.958877 4.3074 2.64778 2.62476C4.33739 0.941476 6.62542 -0.0021563 9.0097 3.70006e-06H9.00934ZM8.32169 6.84H6.71575C6.59903 6.84141 6.48301 6.85758 6.37051 6.88852C6.23269 6.91735 6.12792 7.02844 6.10754 7.16766C6.06958 7.31883 6.14551 7.47492 6.28754 7.53821C6.46051 7.61907 6.64614 7.66899 6.83669 7.68587C7.3373 7.70907 7.5384 7.92916 7.46318 8.44383C7.32255 9.40712 7.13412 10.3626 6.97169 11.3238C6.862 11.9721 6.76989 12.6218 6.66373 13.2694C6.5217 14.1427 7.33522 14.9612 7.98138 15.0694C8.36388 15.1236 8.75059 15.1482 9.13732 15.1433C9.96561 15.1426 10.7566 14.8002 11.324 14.1969C11.4273 14.102 11.5047 13.9824 11.549 13.8495C11.5708 13.7335 11.5433 13.6133 11.473 13.5184C11.3886 13.4649 11.2846 13.4537 11.1911 13.4874C11.0434 13.5359 10.9119 13.6365 10.7678 13.6998C10.5997 13.7785 10.4232 13.839 10.2418 13.8798C10.0963 13.9156 9.9416 13.8854 9.81996 13.7975C9.69761 13.7096 9.62027 13.5725 9.60831 13.4227C9.57808 13.2554 9.57245 13.0838 9.59214 12.9151C9.65331 12.4974 9.7398 12.0833 9.81504 11.6692C10.021 10.5406 10.2292 9.41069 10.4401 8.28011C10.4978 7.97073 10.5948 7.66628 10.5301 7.34073C10.5203 7.19588 10.4521 7.06088 10.3417 6.96737C10.2313 6.87315 10.0878 6.82745 9.94302 6.84011L8.32169 6.84ZM9.30114 2.83716C8.63739 2.80763 8.02215 3.18311 7.74443 3.78708C7.46669 4.39037 7.58201 5.10192 8.03623 5.58708C8.49044 6.07153 9.19287 6.23324 9.81302 5.99629C10.4339 5.75864 10.8487 5.16941 10.8635 4.50567C10.8888 3.61552 10.1906 2.87162 9.30114 2.84067V2.83716Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_147_461">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Tooltip>
          <Gauge
            className="tw-saturate-[1.09]"
            value={scoreValue}
            segments={SEGMENT_CONFIGS.map((config) => ({
              color: config.color,
              range: config.range,
              label: config.label,
            }))}
            ticksConfig={{
              ticks: [
                0,
                ...SEGMENT_CONFIGS.map((config) => config.range[0]),
                100,
              ],
              fontSize: 16,
            }}
            gaugeConfig={{ maxCircleAngle: 0.6 }}
            valueConfig={{ bottom: 35, tagPaddingY: 5 }}
            tooltipsConfig={{
              fontSize: 20,
              paddingX: 8,
              paddingY: 4,
              margin: 20,
            }}
            segmentsConfig={{ width: 17, gap: 4 }}
            size="84%"
          />

          <div className="tw-relative tw-rounded-lg tw-mt-1 tw-bg-light tw-flex tw-w-full tw-border tw-border-gray-150 tw-gap-5 tw-bg-[##f0f4f8] tw-items-center tw-justify-center tw-py-4 tw-px-8">
            <Tooltip
              offset={20}
              avoidCollisions={false}
              text={
                <div>
                  <span className="tw-font-bold tw-mb-2 tw-text-sm tw-block">
                    About the emojis
                  </span>
                  <p className="tw-text-sm">
                    This emoji helps you to have a visual representation of the
                    profile segment. The possible emojis are:
                  </p>
                  <ul className="tw-list-none tw-text-sm tw-p-0">
                    <li>⭐️⭐️⭐️ Very good segment</li>
                    <li>⭐️⭐️ Good segment</li>
                    <li>⭐️ Medium Segment</li>
                    <li> ❌ Low Segment</li>
                  </ul>
                </div>
              }
              position="bottom"
            >
              <span className="tw-text-[0.9rem] tw-inline-flex tw-items-center tw-gap-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  className="tw-text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_147_461)">
                    <path
                      d="M9.00934 3.70006e-06C11.3972 0.00211312 13.6857 0.953446 15.372 2.64438C17.0588 4.33469 18.0038 6.62616 18.0003 9.01404C17.9969 11.4019 17.0441 13.6899 15.3531 15.3752C13.6614 17.0606 11.3691 18.0049 8.98144 18C6.59428 17.9951 4.3063 17.0416 2.62168 15.3491C0.93699 13.6567 -0.00595854 11.3645 0.00052146 8.97678C0.00684954 6.5925 0.958877 4.3074 2.64778 2.62476C4.33739 0.941476 6.62542 -0.0021563 9.0097 3.70006e-06H9.00934ZM8.32169 6.84H6.71575C6.59903 6.84141 6.48301 6.85758 6.37051 6.88852C6.23269 6.91735 6.12792 7.02844 6.10754 7.16766C6.06958 7.31883 6.14551 7.47492 6.28754 7.53821C6.46051 7.61907 6.64614 7.66899 6.83669 7.68587C7.3373 7.70907 7.5384 7.92916 7.46318 8.44383C7.32255 9.40712 7.13412 10.3626 6.97169 11.3238C6.862 11.9721 6.76989 12.6218 6.66373 13.2694C6.5217 14.1427 7.33522 14.9612 7.98138 15.0694C8.36388 15.1236 8.75059 15.1482 9.13732 15.1433C9.96561 15.1426 10.7566 14.8002 11.324 14.1969C11.4273 14.102 11.5047 13.9824 11.549 13.8495C11.5708 13.7335 11.5433 13.6133 11.473 13.5184C11.3886 13.4649 11.2846 13.4537 11.1911 13.4874C11.0434 13.5359 10.9119 13.6365 10.7678 13.6998C10.5997 13.7785 10.4232 13.839 10.2418 13.8798C10.0963 13.9156 9.9416 13.8854 9.81996 13.7975C9.69761 13.7096 9.62027 13.5725 9.60831 13.4227C9.57808 13.2554 9.57245 13.0838 9.59214 12.9151C9.65331 12.4974 9.7398 12.0833 9.81504 11.6692C10.021 10.5406 10.2292 9.41069 10.4401 8.28011C10.4978 7.97073 10.5948 7.66628 10.5301 7.34073C10.5203 7.19588 10.4521 7.06088 10.3417 6.96737C10.2313 6.87315 10.0878 6.82745 9.94302 6.84011L8.32169 6.84ZM9.30114 2.83716C8.63739 2.80763 8.02215 3.18311 7.74443 3.78708C7.46669 4.39037 7.58201 5.10192 8.03623 5.58708C8.49044 6.07153 9.19287 6.23324 9.81302 5.99629C10.4339 5.75864 10.8487 5.16941 10.8635 4.50567C10.8888 3.61552 10.1906 2.87162 9.30114 2.84067V2.83716Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_147_461">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Representation of the segment:
              </span>
            </Tooltip>
            <span className="tw-text-2xl">
              {getSegmentSymbol(personModelScore.segment)}
            </span>
          </div>
        </div>
        <TopSignals
          topSignals={personModelScore.top_signals}
          model={model}
          tenant={tenant}
        />
      </div>
    </>
  );
}
