import axios from 'axios';
import React, { ReactNode, createContext, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { ColumnNames, TableMapping } from '../../utils/data-warehouse';

export type Credentials = {
  tokens: Record<string, unknown>;
  projectId: string;
  dataset: string;
  location?: string;
  tableNames: TableMapping;
  columnNames: ColumnNames;
};

type BigQueryContextType = {
  tenant: number;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  getCredentials: () => Promise<Credentials>;
  credentials: Credentials;
  setCredentials: (credentials: Credentials) => void;
  testCredentials: () => Promise<boolean>;
  getDatasets: () => Promise<string[]>;
  getTables: () => Promise<string[]>;
  testTableMapping: () => Promise<boolean>;
  getColumns: () => Promise<Record<string, string[]>>;
  save: () => Promise<boolean>;
  clearCredentials: () => void;
};

export const BigQueryContext = createContext<BigQueryContextType>(null);

export function BigQueryProvider({
  tenant,
  children,
}: {
  tenant: number;
  children: ReactNode;
}) {
  const queryClient = useQueryClient();
  const [credentials, setCredentials] = React.useState<Credentials>(null);
  const [editMode, setEditMode] = React.useState(false);

  async function getCredentials(): Promise<Credentials> {
    try {
      const response = await axios.get<Credentials>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/credentials`
      );

      if (Object.keys(response.data).length === 0) return null;

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response.status === 404) {
        return null;
      }

      throw error;
    }
  }

  async function getDatasets(): Promise<string[]> {
    const response = await axios.post<string[]>(
      `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/get_datasets`,
      credentials
    );

    return response.data;
  }

  async function getTables(): Promise<string[]> {
    const response = await axios.post<string[]>(
      `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/get_tables`,
      credentials
    );

    return response.data;
  }

  async function getColumns(): Promise<Record<string, string[]>> {
    const response = await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/get_columns`,
      credentials
    );

    return response.data;
  }

  async function testCredentials(): Promise<boolean> {
    try {
      const response = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/ping`,
        {
          tokens: credentials.tokens,
          projectId: credentials.projectId,
          dataset: credentials.dataset,
          location: credentials.location,
        }
      );

      return response?.data?.tested ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function testTableMapping(): Promise<boolean> {
    try {
      const response = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/ping`,
        {
          tokens: credentials.tokens,
          projectId: credentials.projectId,
          dataset: credentials.dataset,
          location: credentials.location,
          tableNames: credentials.tableNames,
        }
      );

      return response?.data?.tested ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function save(): Promise<boolean> {
    try {
      const response = await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/bigquery/credentials`,
        credentials,
        { params: { activate_pull: true } }
      );

      queryClient.setQueryData(
        ['bigquery', 'credentials', tenant],
        response.data
      );

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  function clearCredentials() {
    queryClient.invalidateQueries(['bigquery', 'credentials', tenant]);
  }

  return (
    <BigQueryContext.Provider
      value={{
        tenant,
        editMode,
        setEditMode,
        getCredentials,
        credentials,
        setCredentials,
        testCredentials,
        getDatasets,
        getTables,
        testTableMapping,
        getColumns,
        save,
        clearCredentials,
      }}
    >
      {children}
    </BigQueryContext.Provider>
  );
}

export function useBigQueryContext() {
  const bigQueryContext = useContext(BigQueryContext);

  if (!bigQueryContext)
    throw new Error(
      'useBigQueryContext was used outside BigQueryContext provider'
    );

  return bigQueryContext;
}
