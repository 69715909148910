import axios, { AxiosError } from 'axios';
import 'chartjs-plugin-datalabels';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Loader } from '../../../components/Loader';
import {
  ChartDefinitionAndData,
  ChartGroupDefinition,
  PageChart,
} from '../../InsightsTypes';
import ErrorBoundary from './ErrorBoundary';
import InnerChartComponent from './InnerChartComponent';
import InnerChartConfigurationComponent from './InnerChartConfigurationComponent';

type ChartComponentProps = {
  tenant: number;
  chart: PageChart;
  superKudu: boolean;
};

type ChartComponentState = {
  charts: ChartDefinitionAndData[];
  error: boolean;
  errorMessage: string;
  configurationMode: boolean;
};

export default class ChartComponent extends React.Component<
  ChartComponentProps,
  ChartComponentState
> {
  constructor(props: ChartComponentProps) {
    super(props);
    this.loadChart = this.loadChart.bind(this);
    this.setConfigurationMode = this.setConfigurationMode.bind(this);
    this.cancelConfigurationModeAndRefresh = this.cancelConfigurationModeAndRefresh.bind(
      this
    );
    this.state = {
      charts: [],
      error: false,
      errorMessage: '',
      configurationMode: false,
    };
  }

  async componentDidMount() {
    // eslint-disable-next-line
    this.loadChart();
  }

  async loadChart() {
    try {
      const { tenant, chart } = this.props;
      const { name, ids } = chart;
      const url = `${BONGO_URL}/v1/org/${tenant}/insights/chart/${name}`;
      const { data } = await axios.get(url).catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          throw new Error('No data was found for this chart');
        }
        if (err.response?.status === 500) {
          throw new Error('A server error occured while loading the chart');
        }
        throw new Error('Cannot load this chart');
      });
      const charts: ChartDefinitionAndData[] = data.chart
        .filter((definition: ChartGroupDefinition) =>
          ids.includes(definition.id)
        )
        .map((definition: ChartGroupDefinition) => {
          return {
            definition,
            data: data.data,
          };
        });
      this.setState({
        charts,
      });
    } catch (err) {
      this.setState({
        error: true,
        errorMessage:
          err.message ?? 'An unexpected error occured while loading the chart.',
      });
    }
  }

  setConfigurationMode(action: 'enable' | 'disable') {
    this.setState({
      configurationMode: action === 'enable',
    });
  }

  async cancelConfigurationModeAndRefresh() {
    this.setState({
      configurationMode: false,
    });
  }

  render() {
    const {
      tenant,
      chart: { align, name },
    } = this.props;
    const { charts, error, errorMessage, configurationMode } = this.state;

    const alertBlock = (
      <Alert bsStyle="info" className="mt-4">
        {errorMessage}
      </Alert>
    );

    if (error) {
      return alertBlock;
    }
    if (!charts.length) return <Loader />;

    return (
      <ErrorBoundary alertBlock={alertBlock}>
        <div
          className={`row  ${
            configurationMode || align === 'horizontal'
              ? 'justify-content-center'
              : ''
          }`}
        >
          {charts.map((chart) => {
            return (
              <div
                className={`
                  ${align === 'horizontal' ? 'col-sm-8' : 'col-sm-10 offset-1'} 
                  ${configurationMode ? 'd-flex justify-content-center' : ''}
                `}
                key={`chart_${chart.definition.id}`}
              >
                <div
                  className={`card box-shadow-3d mt-4 ${
                    configurationMode ? 'w-50' : ''
                  }`}
                >
                  <div className="card-header my-auto">
                    <div className="row justify-content-between">
                      <div className="col my-auto">
                        <h5 className="mb-0">
                          {chart.definition.title}
                          {configurationMode ? ' - Customize' : ''}
                        </h5>
                      </div>
                      {!configurationMode &&
                        chart.definition.charts.some((c) =>
                          c?.properties?.some((p) => p.customizable)
                        ) && (
                          <div className="col-auto">
                            <Button
                              className="float-right btn-outline-secondary"
                              onClick={() =>
                                this.setConfigurationMode('enable')
                              }
                            >
                              Customize
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="card-body">
                    {configurationMode ? (
                      <InnerChartConfigurationComponent
                        tenant={tenant}
                        chartName={name}
                        chartDefinition={chart.definition}
                        cancelConfigurationMode={() =>
                          this.setConfigurationMode('disable')
                        }
                        cancelConfigurationModeAndRefresh={
                          this.cancelConfigurationModeAndRefresh
                        }
                      />
                    ) : (
                      <InnerChartComponent chartDefinitionAndData={chart} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ErrorBoundary>
    );
  }
}
