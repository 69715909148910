import Backbone from 'backbone';
import $ from 'jquery';
import qs from 'qs';
import app from '../app';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click .prototype_box': 'navigate',
  },

  initialize(options: { tenant: number; query_params: string }) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.superKudu = user.superKudu;
    if (!this.superKudu) {
      Backbone.history.navigate('error/403', true);
      return this;
    }
    this.query_params = options.query_params
      ? qs.parse(options.query_params)
      : {};
    this.render();
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const el = $('#tpl_main', template({ tenant: this.tenant }));
    this.$el.html(el.html());
  },

  navigate(e: any) {
    e.preventDefault();
    const href = $(e.currentTarget).attr('href');
    Backbone.history.navigate(href, true);
  },
});
