import React from 'react';
import { Button } from '../../../components/ui';
import { Loading } from '../Loading';
import { useDisconnectZoomInfo } from '../hooks/useDisconnectZoomInfo';

type Props = {
  tenant: number;
};
export function ConnectedView({ tenant }: Props) {
  const {
    mutate: disconnect,
    isLoading: isDisconnecting,
  } = useDisconnectZoomInfo(tenant);

  if (isDisconnecting)
    return <Loading loadingLabel="Disconnecting from ZoomInfo" />;

  return (
    <>
      <p>
        Status:{' '}
        <b className="tw-text-green-500">You're connected to ZoomInfo</b>
      </p>

      <Button color="danger" onClick={() => disconnect()}>
        Disconnect
      </Button>
    </>
  );
}
