import VerbsNames from '../../enums/VerbsNames';
import { ConnectorsNames, MappingConversionsTypes } from '../../../types';
import {
  getFieldNamesFromObject,
  unslugFieldName,
} from '../../pullConfigManager';
import { DATASOURCE_OBJECTS } from '../../utils';
import { ConnectorFieldNameDataModel } from '../../models/ConnectorFieldNameDataModel';
import {
  FIELD_PLACEHOLDER_VALUE,
  VERB_PLACEHOLDER_VALUE,
} from '../../constants';

const SALESFORCE_CLOSEDWON_DEFAULT_FIELD_NAME = 'a_probability';
const HUBSPOT_CLOSEDWON_DEFAULT_FIELD_NAME = 'a_hs_deal_stage_probability';
export const DEFAULT_FIRST_VALUE = '';

export default class ParameterDataModel {
  verb: VerbsNames | null;

  field: string | null;

  table: string | null;

  type: MappingConversionsTypes;

  values: string[];

  lower: boolean;

  updatedAt: number;

  constructor(other?: ParameterDataModel) {
    if (other) {
      this.verb = other.verb;
      this.field = other.field;
      this.table = other.table;
      this.type = other.type;
      this.values = other.values;
      this.lower = other.lower;
      this.updatedAt = other.updatedAt;
    } else {
      this.verb = VerbsNames.is;
      this.field = '';
      this.table = '';
      this.type = 'SQO';
      this.values = [];
      this.lower = false;
      this.updatedAt = Date.now();
    }
  }

  public static createDefaultParameterBasedOnConversionType(
    connector: ConnectorsNames,
    connectorFieldsName: ConnectorFieldNameDataModel[],
    conversionType: MappingConversionsTypes
  ) {
    if (conversionType === 'Closed Won') {
      if (connector === 'salesforce') {
        return ParameterDataModel.createADefaultSalesforceClosedWonParameter(
          connectorFieldsName
        );
      }
      if (connector === 'hubspot') {
        return ParameterDataModel.createADefaultHubspotClosedWonParameter(
          connectorFieldsName
        );
      }
    }
    if (conversionType === 'Open Opp') {
      if (connector === 'salesforce') {
        return ParameterDataModel.createADefaultSalesforceOpenOppParameter(
          connectorFieldsName
        );
      }
      if (connector === 'hubspot') {
        return ParameterDataModel.createADefaultHubspotOpenOppParameter(
          connectorFieldsName
        );
      }
    }
    return ParameterDataModel.createADefaultParameter(
      connector,
      connectorFieldsName,
      conversionType
    );
  }

  public static createAParameterByAttributes(
    verb: VerbsNames,
    field: string,
    table: string,
    type: MappingConversionsTypes,
    values: string[],
    lower: boolean,
    updatedAt: number
  ): ParameterDataModel {
    const parameter = new ParameterDataModel();
    parameter.verb = verb;
    parameter.field = field;
    parameter.table = table;
    parameter.type = type;
    parameter.values = values;
    parameter.lower = lower;
    parameter.updatedAt = updatedAt;
    return parameter;
  }

  private static getDefaultConnectorField(
    connector: ConnectorsNames,
    connectorFieldsName: ConnectorFieldNameDataModel[],
    searchedField?: string,
    dataSource?: string
  ): string {
    if (searchedField) {
      const fieldsNames = getFieldNamesFromObject(
        dataSource,
        connectorFieldsName,
        connector
      );
      return fieldsNames.filter((fieldName) => {
        return fieldName.name.toLowerCase() === searchedField.toLowerCase();
      })[0]?.name;
    }
    return getFieldNamesFromObject(
      DATASOURCE_OBJECTS[connector][1],
      connectorFieldsName,
      connector
    )[0]?.name;
  }

  public static createADefaultParameter(
    connector: ConnectorsNames,
    connectorFieldsName: ConnectorFieldNameDataModel[],
    type: MappingConversionsTypes
  ): ParameterDataModel {
    const table = DATASOURCE_OBJECTS[connector][0];
    const field = FIELD_PLACEHOLDER_VALUE;
    const verb = VERB_PLACEHOLDER_VALUE;
    const firstValue: string[] = [DEFAULT_FIRST_VALUE];
    return ParameterDataModel.createAParameterByAttributes(
      verb as VerbsNames,
      field,
      table,
      type,
      firstValue,
      false,
      Date.now()
    );
  }

  public static createADefaultSalesforceClosedWonParameter(
    connectorFieldsName: ConnectorFieldNameDataModel[]
  ): ParameterDataModel {
    const defaultConnectorFieldName = this.getDefaultConnectorField(
      'salesforce',
      connectorFieldsName,
      SALESFORCE_CLOSEDWON_DEFAULT_FIELD_NAME,
      DATASOURCE_OBJECTS.salesforce[1]
    );
    const table = DATASOURCE_OBJECTS.salesforce[1];
    const field = defaultConnectorFieldName;
    const verb = VerbsNames.is;
    const firstValue: string[] = ['100'];
    return ParameterDataModel.createAParameterByAttributes(
      verb,
      field,
      table,
      'Closed Won',
      firstValue,
      false,
      Date.now()
    );
  }

  public static createADefaultSalesforceOpenOppParameter(
    connectorFieldsName: ConnectorFieldNameDataModel[]
  ): ParameterDataModel {
    const defaultConnectorFieldName = this.getDefaultConnectorField(
      'salesforce',
      connectorFieldsName,
      SALESFORCE_CLOSEDWON_DEFAULT_FIELD_NAME,
      DATASOURCE_OBJECTS.salesforce[1]
    );
    const table = DATASOURCE_OBJECTS.salesforce[1];
    const field = defaultConnectorFieldName;
    const verb = VerbsNames.is_more_than_or_equal_to;
    const firstValue: string[] = ['0'];
    return ParameterDataModel.createAParameterByAttributes(
      verb,
      field,
      table,
      'Open Opp',
      firstValue,
      false,
      Date.now()
    );
  }

  public static createADefaultHubspotClosedWonParameter(
    connectorFieldsName: ConnectorFieldNameDataModel[]
  ): ParameterDataModel {
    const defaultConnectorFieldName = this.getDefaultConnectorField(
      'hubspot',
      connectorFieldsName,
      HUBSPOT_CLOSEDWON_DEFAULT_FIELD_NAME,
      DATASOURCE_OBJECTS.hubspot[0]
    );
    const table = DATASOURCE_OBJECTS.hubspot[0];
    const field = defaultConnectorFieldName;
    const verb = VerbsNames.is;
    const firstValue: string[] = ['1'];
    return ParameterDataModel.createAParameterByAttributes(
      verb,
      field,
      table,
      'Closed Won',
      firstValue,
      false,
      Date.now()
    );
  }

  public static createADefaultHubspotOpenOppParameter(
    connectorFieldsName: ConnectorFieldNameDataModel[]
  ): ParameterDataModel {
    const defaultConnectorFieldName = this.getDefaultConnectorField(
      'hubspot',
      connectorFieldsName,
      HUBSPOT_CLOSEDWON_DEFAULT_FIELD_NAME,
      DATASOURCE_OBJECTS.hubspot[0]
    );
    const table = DATASOURCE_OBJECTS.hubspot[0];
    const field = defaultConnectorFieldName;
    const verb = VerbsNames.is_more_than;
    const firstValue: string[] = ['0'];
    return ParameterDataModel.createAParameterByAttributes(
      verb,
      field,
      table,
      'Open Opp',
      firstValue,
      false,
      Date.now()
    );
  }

  public equals(other: ParameterDataModel): boolean {
    return (
      unslugFieldName(this.field) === unslugFieldName(other.field) &&
      this.verb === other.verb &&
      this.lower === other.lower &&
      JSON.stringify(this.values) === JSON.stringify(other.values) &&
      this.type === other.type &&
      this.table === other.table
    );
  }
}
