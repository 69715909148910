import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useLinkedinIsConnected } from './hooks/useLinkedinIsConnected';
import { Loading } from './Loading';
import { zodResolver } from '@hookform/resolvers/zod';
import { linkedinCredentialsSchema } from './types';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Input } from '../../components/ui';
import { useSaveLinkedinCredentials } from './hooks/useSaveLinkedinCredentials';
import { useLinkedinCredentials } from './hooks/useLinkedinCredentials';
import { useDisconnectLinkedin } from './hooks/useDisconnectLinkedin';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PremiumIntegrationWarning from '../utils/PremiumIntegrationWarning';

type Props = {
  tenant: number;
  hasAccessToConfiguration: boolean;
};

export function LinkedinOverview({ tenant, hasAccessToConfiguration }: Props) {
  const {
    data: isLinkedinConnected,
    isLoading: isCheckingConnection,
  } = useLinkedinIsConnected(tenant);
  const {
    data: credentials,
    isLoading: isLoadingCredentials,
  } = useLinkedinCredentials(tenant);

  const { reset: resetForm, control, handleSubmit, formState } = useForm({
    reValidateMode: 'onChange',
    resolver: zodResolver(linkedinCredentialsSchema),
    defaultValues: {
      companyUrl: '',
    },
  });

  useEffect(() => {
    if (!isLoadingCredentials && credentials) {
      resetForm(credentials);
    }
  }, [credentials, isLoadingCredentials]);

  const {
    mutate,
    isLoading: isConnecting,
    isSuccess: hasSavedCredentials,
    isError,
    reset,
  } = useSaveLinkedinCredentials(tenant, {
    onSuccess: (values) => resetForm(values),
  });

  const { enableSocialSignals } = useFlags();
  const isConnected = isLinkedinConnected && enableSocialSignals;

  const {
    mutate: disconnectLinkedin,
    isLoading: isDisconnecting,
  } = useDisconnectLinkedin(tenant, {
    onSuccess: () => resetForm({ companyUrl: '' }),
  });

  const { companyUrl: companyUrlErrors } = formState.errors;

  if (isCheckingConnection || isLoadingCredentials)
    return <Loading loadingLabel="Checking LinkedIn connection" />;

  return (
    <div className="d-flex">
      <div className="row w-75 mr-4">
        <div className="col">
          <div className="row justify-space-between">
            <div className="col-8">
              <h5>Overview</h5>
              <div>
                Enter your company LinkedIn page to start using signals like
                people commenting and reacting to your company posts.
              </div>
            </div>

            {!enableSocialSignals && <PremiumIntegrationWarning />}

            <div className="mt-4 px-2 col-12">
              <Form
                className="tw-flex tw-flex-col tw-items-start tw-gap-4 w-full"
                onSubmit={handleSubmit((values) => {
                  mutate(values);
                })}
                disabled={
                  isDisconnecting || isConnecting || !enableSocialSignals
                }
              >
                <span>
                  <b>Your company LinkedIn page</b>
                </span>
                <Controller
                  name="companyUrl"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={
                        (isError || companyUrlErrors) &&
                        'tw-border-red-500 tw-text-red-500 focus:tw-border-red-500 focus:tw-ring-red-500 w-full'
                      }
                      placeholder="Enter your company's LinkedIn page URL. Example: https://www.linkedin.com/company/madkudu/"
                      type="text"
                      disabled={
                        !hasAccessToConfiguration ||
                        isDisconnecting ||
                        isConnecting ||
                        !enableSocialSignals
                      }
                      {...field}
                    />
                  )}
                />
                {companyUrlErrors?.message && (
                  <div className="tw-text-red-500 tw-mt-1">
                    {companyUrlErrors?.message}
                  </div>
                )}

                <div className="tw-flex tw-gap-4">
                  <Button
                    type="submit"
                    className="tw-mt-5"
                    disabled={
                      isDisconnecting || isConnecting || !enableSocialSignals
                    }
                    color={isConnecting ? 'white' : 'blue'}
                  >
                    {isConnecting ? 'Saving...' : 'Save'}
                  </Button>

                  <Button
                    type="button"
                    className="tw-mt-5"
                    disabled={isDisconnecting || isConnecting}
                    color={isDisconnecting ? 'white' : 'red'}
                    onClick={() => {
                      reset();
                      disconnectLinkedin();
                    }}
                  >
                    {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                  </Button>
                </div>

                {isError && (
                  <p className="tw-text-red-500 tw-mt-2">
                    The credentials you entered are invalid. Please try again.
                  </p>
                )}

                {hasSavedCredentials && (
                  <p className="tw-text-green-500 tw-mt-2">
                    Configuration saved successfully!
                  </p>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="w-25">
        <p>
          <b>Status</b>
          <span className="d-block pt-1">
            {isCheckingConnection && (
              <span className="text-secondary">loading...</span>
            )}
            {!isCheckingConnection && (
              <span className="cursor-pointer d-block">
                {isConnected && (
                  <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Connected
                  </b>
                )}
                {!isConnected && (
                  <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Not Connected
                  </b>
                )}
              </span>
            )}
          </span>
        </p>
        <p className="mt-4">
          <b>Links</b>
        </p>
        <p>
          <a
            href="https://help.madkudu.com/docs/how-to-track-your-linkedin-company-posts-engagement-with-madkudu"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to track your LinkedIn company posts engagement with MadKudu
          </a>
        </p>
      </div>
    </div>
  );
}
