import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { LinkedinCredentials } from '../types';

export function useSaveLinkedinCredentials(
  tenant: number,
  options?: UseMutationOptions<
    LinkedinCredentials,
    unknown,
    LinkedinCredentials,
    unknown
  >
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (credentials: LinkedinCredentials) => {
      await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/linkedin/credentials`,
        credentials
      );
      return credentials;
    },
    ...options,
    onSuccess: (data, variables, ctx) => {
      options?.onSuccess?.(data, variables, ctx);
      queryClient.setQueryData(['linkedin', tenant], true);
      queryClient.setQueryData(['linkedin-credentials', tenant], variables);
    },
  });
}
