import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useDeleteIntegrationMutation(opt?: {
  onSuccess?: () => Promise<void>;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      tenant,
      integration,
    }: {
      tenant: number;
      integration: string;
    }) => {
      return axios.delete(
        `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}`
      );
    },
    onSuccess: async (_data, { integration, tenant }) => {
      queryClient.setQueriesData([tenant, 'integrations', integration], null);
      if (opt?.onSuccess) await opt.onSuccess();
    },
  });
}
