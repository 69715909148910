import Swal from 'sweetalert2';
import MappingsMode from '../enums/MappingsMode';

export default abstract class AbstractMadMlMapping {
  tenant: number;

  email: string;

  constructor(tenant: number, email: string) {
    this.tenant = tenant;
    this.email = email;
  }

  abstract getMadMlData(): Promise<string>;

  abstract prepareMadMlData(madMlSqlQuery: string): Promise<any>;

  abstract createMadMlData(madMlSqlQuery: string): Promise<any>;

  abstract createMadMlObjectToSend(madMlSqlQuery: string): any;

  async launchPublishSwal(
    madMlSqlQuery: string,
    isCreation: boolean,
    mappingMode: MappingsMode,
    reloadScriptCheckerState: Function,
    setStatePostPublish: Function,
    goToDisplayMode: Function
  ) {
    Swal.fire({
      title: 'Publish?',
      html: `
      <p>This will update your mapping with the changes you have saved on this connector</p>
 
      ${
        madMlSqlQuery.length < 100
          ? '<p class="text-warning">Your query is <span class="font-italic">short</span>, are you sure?</p>'
          : ''
      }
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, publish',
      cancelButtonText: 'No, cancel',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.createMadMlData(madMlSqlQuery);
          if (reloadScriptCheckerState) {
            await reloadScriptCheckerState();
          }
          if (setStatePostPublish) {
            await setStatePostPublish();
          }
          if (goToDisplayMode) {
            goToDisplayMode();
          }
          return { error: false, message: '' };
        } catch (e) {
          return { error: true, message: e?.response?.data?.message };
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((response: any) => {
      if (!response.value.error) {
        Swal.fire({
          icon: 'success',
          title: 'Published successfully!',
        });
        window.analytics.track(
          `${isCreation ? 'Create new' : 'Publish'} mapping`,
          {
            mode: 'custom',
            map: mappingMode,
            tenant: this.tenant,
            email: this.email,
          }
        );
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: `Publish failed: ${response.value.message}`,
        });
      }
    });
  }
}
