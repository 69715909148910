import React from 'react';
import capitalize from 'lodash/capitalize';
import DataPrivacyComponent from './DataPrivacyComponent';
import DataSharingComponent from './DataSharingComponent';

export type TabValues = 'Data sharing' | 'Data privacy';

type PrivacyParentComponentProps = {
  tenant: number;
  getActivePage: () => TabValues;
  toggleView: Function;
};

type PrivacyParentComponentState = {
  activeTab: TabValues;
  tabNames: TabValues[];
};

export default class PrivacyParentComponent extends React.Component<
  PrivacyParentComponentProps,
  PrivacyParentComponentState
> {
  constructor(props: PrivacyParentComponentProps) {
    super(props);

    const currentView = props.getActivePage();

    this.state = {
      activeTab: currentView,
      tabNames: ['Data privacy', 'Data sharing'],
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  async componentDidMount() {
    const { toggleView } = this.props;
    const { activeTab, tabNames } = this.state;

    this.setState({ tabNames });
    toggleView(activeTab);
  }

  handleTabClick = (event: any) => {
    this.setState({ activeTab: event.target.id });
    const { toggleView } = this.props;
    return toggleView(event.target.id);
  };

  render() {
    const { tabNames, activeTab } = this.state;
    const { tenant } = this.props;

    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
          <ul className="nav nav-pills mx-auto">
            {tabNames
              // TODO: remove this filter when we need to activate the Data sharing tab
              .filter((tabName) => tabName !== 'Data sharing')
              .map((tabName) => (
                <li className="nav-item" key={tabName}>
                  <a
                    className={`nav-link ${
                      activeTab === tabName ? 'active show' : ''
                    }`}
                    id={tabName}
                    data-toggle="tab"
                    href={`#${tabName}`}
                    onClick={this.handleTabClick}
                    role="tab"
                  >
                    {capitalize(tabName)}
                  </a>
                </li>
              ))}
          </ul>
        </nav>
        <>
          {tabNames.map((tabName, index) => (
            <div className="row ml-4" key={`tab_content_${index}`}>
              <div className="tab-content w-100" id="TabContent">
                <div
                  key={`tab_content_${tabName}`}
                  className={`tab-pane fade ${
                    activeTab === tabName ? 'active show' : ''
                  }`}
                  id={`${tabName}-tab`}
                  role="tabpanel"
                >
                  {tabName === 'Data sharing' && (
                    <DataSharingComponent tenant={tenant} />
                  )}
                  {tabName === 'Data privacy' && (
                    <DataPrivacyComponent tenant={tenant} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      </>
    );
  }
}
