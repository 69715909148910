import * as LD from 'launchdarkly-js-client-sdk';

const CLIENT_SIDE_ID =
  ENVIRONMENT === 'prod'
    ? '65b76ba632aaac1036c86cee'
    : '65b76ba632aaac1036c86ced';
const SOCIAL_SIGNALS_FLAG = 'enable-social-signals';

/**
 * Singleton class to manage the LaunchDarkly client, following
 * [their recommanded best practices](https://docs.launchdarkly.com/sdk/concepts/getting-started#implement-sdks-in-a-singleton-pattern).
 *
 * The main use case is being able to use it inside a hybrid Backbone/React application.
 */
export class LaunchDarklyClient {
  static #instance: LaunchDarklyClient;

  ldClient: LD.LDClient;

  clientSideID = CLIENT_SIDE_ID;

  // Private constructor to prevent instantiation
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static async getInstance(): Promise<LaunchDarklyClient> {
    if (!LaunchDarklyClient.#instance) {
      LaunchDarklyClient.#instance = new LaunchDarklyClient();
      LaunchDarklyClient.#instance.ldClient = LD.initialize(CLIENT_SIDE_ID, {
        kind: 'tenant',
        key: '0',
        id: 0,
      });
      await LaunchDarklyClient.#instance.ldClient.waitForInitialization(5000);
      return LaunchDarklyClient.#instance;
    }

    return Promise.resolve(LaunchDarklyClient.#instance);
  }
}

export function getContext(tenant: number) {
  return {
    kind: 'tenant',
    key: tenant.toString(),
    id: tenant,
  };
}

export async function checkToggleForTenant(
  tenant: number,
  flag: string
): Promise<boolean> {
  const instance = await LaunchDarklyClient.getInstance();
  await instance.ldClient.identify({
    kind: 'tenant',
    key: tenant.toString(),
    id: tenant,
  });
  return instance.ldClient.variation(flag, false);
}

export async function hasEnabledSocialSignals(
  tenant: number
): Promise<boolean> {
  return checkToggleForTenant(tenant, SOCIAL_SIGNALS_FLAG);
}
