import * as React from 'react';
import { FormError } from './Form-error';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  label?: string;
  description?: string;
  error?: string;
  errorLevel?: 'warning' | 'error' | 'suggestion';
};

export function FormLine(props: Props) {
  const {
    label,
    description,
    error,
    errorLevel,
    children,
    ...restProps
  } = props;

  return (
    <div className="tw-relative" {...restProps}>
      <div className="tw-flex tw-flex-col">
        <div className="tw-my-1 tw-font-medium">
          <div className="tw-text-gray-300">{label ? `${label}:` : ''}</div>
          {description && (
            <div className="tw-font-normal tw-italic tw-leading-6 tw-text-gray-300">
              {description}
            </div>
          )}
        </div>
        <div
          className={`tw-grow-1 tw-flex tw-items-center tw-gap-3 ${
            error ? 'tw-form-field-error' : null
          }`}
        >
          {children}
        </div>
      </div>
      {error && <FormError errorLevel={errorLevel}>{error}</FormError>}
    </div>
  );
}
