import React, { Component } from 'react';

export default class EvolutionOverTimeLeadsPQLGuideComponent extends Component<
  {},
  {}
> {
  render() {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
        <div className="card-body">
          <i className="far fa-compass fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>Guide: </strong>
            Evolution Over Time: Leads Distribution
          </h5>
          <div className="border-top mt-2 border-primary"></div>
          <i className="fas fa-flask fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>How MadKudu Computes This:</strong>
          </h5>
          <p className="mt-2">
            How many of your leads created in the last 6 months are currently
            scored as very high, high, medium or low by MadKudu's Likelihood to
            Buy model.
          </p>
          <h5 className="mt-2">
            <strong>How to Interpret This:</strong>
          </h5>
          <p>
            This section aims to showcase the score decay, as leads grow older
            they get colder unless they stay active. Additionally it helps
            understand the current number of leads created in the last 6 months
            that are scored as very high or high by MadKudu's Likelihood to Buy
            model. If the trend in lead scored as very high or high has been
            declining at an abnormal pace but the overall number of leads has
            remained steady, there is a chance to review recent marketing
            activities that have possibly reduced engagement of leads.
          </p>
        </div>
      </div>
    );
  }
}
