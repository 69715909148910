import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { MixpanelCredentials } from '../types';

export function useMixpanelCredentials(tenant: number) {
  return useQuery<MixpanelCredentials>({
    queryKey: ['mixpanel-credentials', tenant],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${BONGO_URL}/v1/org/${tenant}/integrations/mixpanel/credentials`
        );

        return data;
      } catch (error) {
        if (error.response.status === 404) {
          return null;
        }
      }
    },
  });
}
