import React from 'react';

export default class MultiStep extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.isConfirmed = this.isConfirmed.bind(this);
    this.handleMultipleValuesChange = this.handleMultipleValuesChange.bind(
      this
    );
  }

  isConfirmed() {
    const { confirmStepHandler, stepId } = this.props;
    confirmStepHandler(stepId);
  }

  handleMultipleValuesChange(index: number) {
    return (e: any) => {
      this.props.handleMultipleValuesChange(e.target.value, index);
    };
  }

  render() {
    const {
      stepId,
      stepName,
      stepValues,
      shouldShow,
      inputTitle,
      titlePlaceholder,
      inputSubtitle,
      subPlaceholder,
      yesPlaceholder,
      noPlaceholder,
      ctaYes,
      ctaNo,
      isConfigured,
      inputType,
      accordionId,
      collapseId,
      headingId,
      hrefDataAccordionId,
      hrefDataCollapseId,
    } = this.props;
    return (
      <div className="row justify-content-center">
        <div className="col-10 mt-2">
          <div
            className="accordion md-accordion"
            id={accordionId}
            role="tablist"
            aria-multiselectable="true"
          >
            <div
              className="card box-shadow-3d"
              style={{ borderColor: isConfigured ? '#4DCCBD' : 'default' }}
            >
              <div className="card-header" role="tab" id={headingId}>
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent={hrefDataAccordionId}
                  href={hrefDataCollapseId}
                  aria-expanded="false"
                  aria-controls={collapseId}
                  style={{ textDecoration: 'none' }}
                >
                  <h5
                    className="mb-0"
                    style={{ color: isConfigured ? '#4DCCBD' : 'black' }}
                  >
                    {stepName}
                    <h5
                      className="pull-right mb-0"
                      style={{ color: isConfigured ? '#4DCCBD' : 'red' }}
                    >
                      <span className="mr-4">
                        {isConfigured ? 'Configured' : 'Not Configured'}
                      </span>
                      <span>
                        <i className="fas fa-angle-down rotate-icon"></i>
                      </span>
                    </h5>
                  </h5>
                </a>
              </div>
              <div
                id={collapseId}
                className={`collapse ${
                  stepId === 1 || shouldShow ? 'show' : ''
                }`}
                role="tabpanel"
                aria-labelledby={headingId}
                data-parent={hrefDataAccordionId}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputCampaign"
                            className="col-md-auto col-form-label"
                            style={{ fontSize: '1.1em', fontWeight: 'bold' }}
                          >
                            {inputTitle}
                          </label>
                          <div className="col-9">
                            <input
                              type={inputType}
                              className="form-control"
                              value={stepValues[0]}
                              onChange={this.handleMultipleValuesChange(0)}
                              placeholder={titlePlaceholder}
                              style={{ backgroundColor: '#f2f4f4' }}
                            ></input>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputCampaign"
                            className="col-md-auto col-form-label"
                            style={{ fontSize: '1.1em', fontWeight: 'bold' }}
                          >
                            {inputSubtitle}
                          </label>
                          <div className="col-9">
                            <input
                              type={inputType}
                              className="form-control"
                              value={stepValues[1]}
                              onChange={this.handleMultipleValuesChange(1)}
                              placeholder={subPlaceholder}
                              style={{ backgroundColor: '#f2f4f4' }}
                            ></input>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputCampaign"
                            className="col-md-auto col-form-label"
                            style={{ fontSize: '1.1em', fontWeight: 'bold' }}
                          >
                            {ctaYes}
                          </label>
                          <div className="col-9">
                            <input
                              type={inputType}
                              className="form-control"
                              value={stepValues[2]}
                              onChange={this.handleMultipleValuesChange(2)}
                              placeholder={yesPlaceholder}
                              style={{ backgroundColor: '#f2f4f4' }}
                            ></input>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputCampaign"
                            className="col-md-auto col-form-label"
                            style={{ fontSize: '1.1em', fontWeight: 'bold' }}
                          >
                            {ctaNo}
                          </label>
                          <div className="col-9">
                            <input
                              type="text"
                              className="form-control"
                              value={stepValues[3]}
                              onChange={this.handleMultipleValuesChange(3)}
                              placeholder={noPlaceholder}
                              style={{ backgroundColor: '#f2f4f4' }}
                            ></input>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        disabled={
                          !stepValues[0] ||
                          !stepValues[1] ||
                          !stepValues[2] ||
                          !stepValues[3]
                        }
                        onClick={this.isConfirmed}
                      >
                        <a
                          href={
                            stepValues[0] &&
                            stepValues[1] &&
                            stepValues[2] &&
                            stepValues[3] &&
                            hrefDataCollapseId
                          }
                          className="btn-primary"
                          data-toggle="collapse"
                          aria-expanded="false"
                          aria-controls="collapse"
                        >
                          Confirm
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
