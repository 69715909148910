// load the CSS
// we only do this for demo pages to make sure the other pages are safe
function load_custom_css(src: string) {
  const demo_css = document.createElement('link');
  demo_css.setAttribute('rel', 'stylesheet');
  demo_css.setAttribute('type', 'text/css');
  demo_css.setAttribute('href', src);
  document.getElementsByTagName('head').item(0).append(demo_css);
}

function load_custom_js(src: string, callback?: () => any) {
  const demo_js = document.createElement('script');
  demo_js.setAttribute('src', src);
  demo_js.onload = callback;
  document.body.appendChild(demo_js);
}

export default function execute_demo() {
  load_custom_css('/css/demo.css');
  load_custom_js(
    `https://growth.madkudu.com/demo.js?cache_buster=${Math.floor(
      Math.random() * 10000
    )}`
  );
}
