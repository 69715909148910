import axios from 'axios';

export default async function (tenant: number, integration: string) {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/integrations/${integration}/ping`
    );
    return data;
  } catch (err) {
    const error = err.response && err.response.data;
    throw new Error(error.message);
  }
}
