import cloneDeep from 'lodash/cloneDeep';
import {
  MappingConversionsTypes,
  ModelTypes,
  PerformanceData,
} from '../../types';

export const DEFAULT_NAME_CONVERSION_SQO_DEFINITION = 'SQO';
export const DEFAULT_NAME_CONVERSION_CLOSED_WON_DEFINITION = 'Closed Won';
export const DEFAULT_NAME_CONVERSION_OPEN_OPP_DEFINITION = 'Open Opp';

const DEFAULT_ANALYSIS_DATA_STRUCTURE = {
  good: {
    value: 0,
    percentage: 'NaN%',
  },
  low: {
    value: 0,
    percentage: 'NaN%',
  },
  medium: {
    value: 0,
    percentage: 'NaN%',
  },
  'very good': {
    value: 0,
    percentage: 'NaN%',
  },
};

export const COLORS = [
  '#1E77CC',
  '#45B8AA',
  '#F0C933',
  '#D81E5B',
  '#BC1F6B',
  '#9C2874',
  '#792F76',
  '#563270',
  '#353064',
  '#182b52',
];
export const BUBBLE_COLORS = [
  'rgba(30, 119, 204, 0.2)',
  'rgba(40, 135, 196, 0.2)',
  'rgba(49, 151, 187, 0.2)',
  'rgba(69, 184, 170, 0.2)',
  'rgba(155, 193, 110, 0.2)',
  'rgba(240, 201, 51, 0.2)',
  'rgba(218, 199, 66, 0.2)',
  'rgba(197, 197, 81, 0.2)',
  'rgba(212, 157, 76, 0.2)',
  'rgba(197, 197, 81, 0.2)',
  'rgba(212, 157, 76, 0.2)',
  'rgba(228, 116, 71, 0.2)',
  'rgba(220, 136, 74, 0.2)',
  'rgba(212, 157, 76, 0.2)',
  'rgba(213, 126, 80, 0.2)',
  'rgba(214, 94, 84, 0.2)',
  'rgba(216, 30, 91, 0.2)',
  'rgba(188, 31, 107, 0.2)',
  'rgba(156, 40, 116, 0.2)',
  'rgba(121, 47, 118, 0.2)',
  'rgba(86, 50, 112, 0.2)',
  'rgba(53, 48, 100, 0.2)',
  'rgba(207, 68, 68, 0.2)',
  'rgba(240, 36, 151, 0.2)',
  'rgba(230, 36, 240, 0.2)',
  'rgba(208, 20, 255, 0.2)',
  'rgba(117, 10, 199, 0.2)',
  'rgba(188, 110, 247, 0.2)',
  'rgba(75, 236, 190, 0.29)',
  'rgba(185, 238, 223, 0.29)',
];
export const MORE_COLORS = [
  '#1E77CC',
  '#2887C4',
  '#3197BB',
  '#45B8AA',
  '#9BC16E',
  '#F0C933',
  '#DAC742',
  '#C5C551',
  '#D49D4C',
  '#E47447',
  '#DC884A',
  '#D49D4C',
  '#D57E50',
  '#D65E54',
  '#D81E5B',
  '#BC1F6B',
  '#9C2874',
  '#792F76',
  '#563270',
  '#353064',
];

// Map label => value
export const LABEL_TO_VALUE_MAP: Record<string, Record<string, string>> = {
  customer_fit: {
    'very good': 'very good',
    good: 'good',
    medium: 'medium',
    low: 'low',
  },
  pql: {
    'very high': 'very good',
    high: 'good',
    medium: 'medium',
    low: 'low',
  },
  pql2: {
    'very high': 'very good',
    high: 'good',
    medium: 'medium',
    low: 'low',
  },
  mql: {
    'very high': 'very good',
    high: 'good',
    medium: 'medium',
    low: 'low',
  },
  mqa: {
    'very high': 'very good',
    high: 'good',
    medium: 'medium',
    low: 'low',
  },
  lead_grade: {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
  },
};

const fitToInt: { [key: string]: number } = {
  low: 0,
  medium: 1,
  good: 2,
  'very good': 3,
};
export function compareCustomerFits(c1: string, c2: string) {
  return fitToInt[c1] - fitToInt[c2];
}

const ltbToInt: { [key: string]: number } = {
  low: 0,
  medium: 1,
  high: 2,
  'very high': 3,
};

export function compareLtb(c1: string, c2: string) {
  return ltbToInt[c1] - ltbToInt[c2];
}

export function getColorForSegment(segment: string) {
  switch (segment) {
    case 'very good':
    case 'very high':
    case 'A':
      return 'primary';
    case 'good':
    case 'high':
    case 'B':
      return 'teal';
    case 'medium':
    case 'C':
      return 'yellow';
    case 'D':
      return 'warning';
    case 'low':
    case 'E':
      return 'red';
    default:
    //
  }
}

export function getColorOfRevenue(revenue: number): string {
  if (revenue >= 70) {
    return '#1E77CC';
  }
  if (revenue >= 60) {
    return '#4DCCBD';
  }
  if (revenue >= 50) {
    return '#F0C933';
  }
  if (revenue < 40) {
    return '#D81E5B';
  }
}

export function getBackgroundColorForLeadGrade(leadGrade: string) {
  const Green = '#BDD5AC';
  const LightGreen = '#DCE9D5';
  const Yellow = '#FEF2D0';
  const Orange = '#F9E5D0';
  const Red = '#EECDCD';
  switch (leadGrade) {
    case 'A':
      return Green;
    case 'B':
      return LightGreen;
    case 'C':
      return Yellow;
    case 'D':
      return Orange;
    case 'E':
      return Red;
    default:
  }
}

export function getModelValues(modelType: ModelTypes) {
  return Object.values(LABEL_TO_VALUE_MAP[modelType]);
}

export function getModelLabels(modelType: ModelTypes) {
  return Object.keys(LABEL_TO_VALUE_MAP[modelType]);
}

export function dataFormatter(
  data: UnknownObject,
  dates: string[],
  customLabels: string[],
  modelType: string
) {
  if (!data) return {};

  const datasets = customLabels.map((label, i) => {
    return {
      label,
      backgroundColor: COLORS[customLabels.length - i - 1],
      // Don't remove cloneDeep! ChartJS is mutating the data object
      data: cloneDeep(data[LABEL_TO_VALUE_MAP[modelType][label]]),
    };
  });
  return {
    datasets,
    labels: dates,
  };
}

export function dataFormatterHorizontal(
  data: UnknownObject,
  label: string,
  custom_labels: string[],
  indicator: string,
  roundTo: number
) {
  // eslint-disable-next-line
  const coeff = Math.pow(10, roundTo);

  function getValueForSegment(segment: string) {
    return Math.round(data[segment][indicator] * coeff) / coeff;
  }

  if (!data) return {};

  return {
    labels: [
      custom_labels[3],
      custom_labels[2],
      custom_labels[1],
      custom_labels[0],
    ],
    datasets: [
      {
        label,
        backgroundColor: [COLORS[0], COLORS[1], COLORS[2], COLORS[3]],
        data: [
          getValueForSegment('very good'),
          getValueForSegment('good'),
          getValueForSegment('medium'),
          getValueForSegment('low'),
        ],
      },
    ],
  };
}

export function capitalizeEachWord(sentence: string) {
  return sentence
    .split(/ /g)
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
}

export function getColorOfScore(score: number) {
  if (score >= 90) {
    return '#1E77CC';
  }
  if (score >= 75) {
    return '#1E77CC';
  }
  if (score >= 50) {
    return '#4DCCBD';
  }
  if (score >= 30) {
    return '#F0C933';
  }
  if (score < 30) {
    return '#D81E5B';
  }
  return '#DCC72A';
}

export function extractLeadsAndRecallsFromAudiencePerformanceData(
  audiencePerformanceData: PerformanceData,
  modelType: ModelTypes,
  dates: string[]
) {
  const labels = getModelLabels(modelType).reverse();
  return Object.keys(audiencePerformanceData).reduce(
    (acc: any, conversionType: MappingConversionsTypes) => {
      // @ts-ignore
      const { leads, recall } = audiencePerformanceData[conversionType];
      acc[conversionType] = {
        leads: leads ? dataFormatter(leads, dates, labels, modelType) : {},
        recall: recall ? dataFormatter(recall, dates, labels, modelType) : {},
      };
      return acc;
    },
    {}
  );
}

export function recallPercentageUpdate(
  dataToGet: number[],
  leadsAndRecalls: any,
  conversionType: string
): string {
  const data = [0, 0, 0, 0];
  // eslint-disable-next-line
  for (const val of dataToGet) {
    for (let i = 0; i < data.length; i += 1) {
      data[i] += leadsAndRecalls[conversionType].recall.datasets[i].data[val];
    }
  }
  const totalRecall = data[0] + data[1] + data[2] + data[3];

  let recallPercentage = (((data[2] + data[3]) / totalRecall) * 100).toFixed(0);
  // eslint-disable-next-line
  if (isNaN(recallPercentage as any)) {
    recallPercentage = 'N/A';
  }
  return recallPercentage;
}

export function extractScoreAndDatasetFromPerformanceData(
  conversionType: string,
  leadsAndRecalls: any
): any {
  const type = 'recall';

  if (!leadsAndRecalls[conversionType]) {
    return null;
  }

  const { datasets } = leadsAndRecalls[conversionType][type];

  if (!datasets) return null;

  const dataToGet: number[] = [5, 4, 3, 2, 1, 0]; // Last 6 months

  const data = [0, 0, 0, 0];
  // eslint-disable-next-line
  for (const val of dataToGet) {
    for (let i = 0; i < 4; i += 1) {
      data[i] += datasets[i].data[val];
    }
  }

  const score = recallPercentageUpdate(
    dataToGet,
    leadsAndRecalls,
    conversionType
  );
  const total = data[0] + data[1] + data[2] + data[3];
  const data_percentage = data;
  const currentData = {
    datasets: [
      {
        label: datasets[0].label,
        data: [data_percentage[0]],
        backgroundColor: COLORS[3],
      },
      {
        label: datasets[1].label,
        data: [data_percentage[1]],
        backgroundColor: COLORS[2],
      },
      {
        label: datasets[2].label,
        data: [data_percentage[2]],
        backgroundColor: COLORS[1],
      },
      {
        label: datasets[3].label,
        data: [data_percentage[3]],
        backgroundColor: COLORS[0],
      },
    ],
  };

  return {
    total,
    currentData,
    score,
  };
}

// Function that calculates the Median *FOR LEAD TREND MODULES*
export function calculateMedianOfFunnelBreakDown(values: number[]) {
  const sortedValues = values.sort((a, b) => {
    return a - b;
  });

  const half = Math.floor(sortedValues.length / 2);

  if (sortedValues.length % 2) {
    return sortedValues[half];
  }
  return (sortedValues[half - 1] + sortedValues[half]) / 2.0;
}

export function calculatePercentageValues(
  modelValues: any,
  amount: any,
  amountTotal: any
): string {
  return modelValues.reduce((acc: UnknownObject, curr: string) => {
    acc[curr] = `${((Number(amount[curr]) / Number(amountTotal)) * 100).toFixed(
      0
    )}%`;
    return acc;
  }, {});
}

export function calculatePercentage(
  revenue: any,
  revenueTotal: any,
  modelValues: any
): string {
  return `${(
    ((Number(revenue[modelValues[0]]) + Number(revenue[modelValues[1]])) /
      Number(revenueTotal)) *
    100
  ).toFixed(0)}%`;
}

export function calculateLeadAmount(
  performanceData: any,
  modelValues: string[],
  isSqo: boolean
) {
  const data_leads = isSqo ? performanceData?.recall : performanceData?.leads;

  const leadAmount = modelValues.reduce((acc: UnknownObject, curr: any) => {
    acc[curr] = 0;
    return acc;
  }, {});

  if (data_leads && data_leads.low && data_leads.low.length === 6) {
    for (let i = 0; i < 6; i += 1) {
      // eslint-disable-next-line
      for (const ind of modelValues.reverse()) {
        leadAmount[ind] += Number(data_leads[ind][i]);
      }
    }
  }
  return leadAmount;
}

export function calculateLeadAmountTotal(
  performanceData: any,
  modelValues: string[],
  isSqo: boolean
) {
  const data_leads = isSqo ? performanceData?.recall : performanceData?.leads;

  let leadAmountTotal = 0;

  if (data_leads && data_leads.low && data_leads.low.length === 6) {
    for (let i = 0; i < 6; i += 1) {
      // eslint-disable-next-line
      for (const ind of modelValues.reverse()) {
        leadAmountTotal += Number(data_leads[ind][i]);
      }
    }
  }
  return leadAmountTotal;
}

export function calculateLeadAmountPercentageValues(
  performanceData: any,
  modelValues: any,
  isSqo: boolean
): string {
  const lead_amount_total = calculateLeadAmountTotal(
    performanceData,
    modelValues,
    isSqo
  );
  const lead_amount = calculateLeadAmount(performanceData, modelValues, isSqo);

  return calculatePercentageValues(modelValues, lead_amount, lead_amount_total);
}

export function calculateLeadRevenue(performanceData: any, modelValues: any) {
  const dataConversionDistribution = performanceData?.conversion_distribution;
  const leadRevenue = modelValues.reduce((acc: UnknownObject, curr: any) => {
    acc[curr] = 0;
    return acc;
  }, {});
  if (dataConversionDistribution) {
    for (let i = 0; i < 6; i += 1) {
      // eslint-disable-next-line
      for (const ind of modelValues.reverse()) {
        leadRevenue[ind] += Number(dataConversionDistribution[ind][i]);
      }
    }
  }
  return leadRevenue;
}

export function calculateLeadRevenueTotal(
  performanceData: any,
  modelValues: any
) {
  const dataConversionDistribution = performanceData?.conversion_distribution;

  let leadRevenueTotal = 0;

  if (dataConversionDistribution) {
    for (let i = 0; i < 6; i += 1) {
      // eslint-disable-next-line
      for (const ind of modelValues.reverse()) {
        // Stores the total lead data *FOR MULTIPLE GRAPHS*
        leadRevenueTotal =
          Number(leadRevenueTotal) + Number(dataConversionDistribution[ind][i]);
      }
    }
  }
  return leadRevenueTotal;
}

export function calculateLeadRevenueValues(
  modelValues: any,
  performanceData: any
) {
  const leadRevenue = calculateLeadRevenue(performanceData, modelValues);

  const leadRevenueTotal = calculateLeadRevenueTotal(
    performanceData,
    modelValues
  );

  const leadRevenuePercentage: string = calculatePercentage(
    leadRevenue,
    leadRevenueTotal,
    modelValues
  );
  const leadRevenuePercentageValues: string = calculatePercentageValues(
    modelValues,
    leadRevenue,
    leadRevenueTotal
  );
  const colorOfRevenue = getColorOfRevenue(
    Number(leadRevenuePercentage.replace('%', ''))
  );
  return {
    leadRevenuePercentage,
    leadRevenuePercentageValues,
    colorOfRevenue,
  };
}

export function calculateTotalRevenuePredicted(
  performanceData: any,
  modelType: ModelTypes
): number {
  const data_predicted_value = performanceData?.predicted_value;
  const indice = getModelValues(modelType).slice(0, 2);

  let dataPredictedValueQualifiedSum = 0;
  if (
    data_predicted_value &&
    data_predicted_value.low &&
    data_predicted_value.low.length === 6
  ) {
    for (let i = 0; i < 6; i += 1) {
      // eslint-disable-next-line
      for (const ind of indice) {
        const total = Number(performanceData.conversion_distribution[ind][i]);
        dataPredictedValueQualifiedSum += total;
      }
    }
  }
  return dataPredictedValueQualifiedSum;
}

export function calculateLeadValue(
  performanceData: any,
  modelType: ModelTypes
) {
  const data_predicted_value_accrued = performanceData?.predicted_value_accrued;
  let leadValues: UnknownObject = DEFAULT_ANALYSIS_DATA_STRUCTURE;
  let veryGoodWorthXMore: string = '';

  if (data_predicted_value_accrued) {
    const modelValues = getModelValues(modelType);
    const allLeadValues = Object.values(
      data_predicted_value_accrued
    ).map((n: UnknownObject) => Number(n ? n.predicted_email_value : 0));
    const maxLeadValue = Number(Math.max(...allLeadValues).toFixed(3));
    leadValues = modelValues.reduce((acc: UnknownObject, modelValue) => {
      acc[modelValue] = {
        value: data_predicted_value_accrued[modelValue].predicted_email_value,
        percentage: `${
          Number(
            (
              data_predicted_value_accrued[modelValue].predicted_email_value /
              maxLeadValue
            ).toFixed(3)
          ) * 100
        }%`,
      };
      return acc;
    }, {});
    veryGoodWorthXMore = (
      Number(
        data_predicted_value_accrued[modelValues[0]].predicted_email_value
      ) /
      Number(
        data_predicted_value_accrued[modelValues[modelValues.length - 1]]
          .predicted_email_value
      )
    ).toFixed(0);
  }
  return {
    veryGoodWorthXMore,
    leadValues,
  };
}

export function calculateConversionRates(
  performanceData: any,
  modelType: ModelTypes
) {
  const data_predicted_value_accrued = performanceData?.predicted_value_accrued;
  let conversionRates: UnknownObject = DEFAULT_ANALYSIS_DATA_STRUCTURE;
  if (data_predicted_value_accrued) {
    const allConversions = Object.values(
      data_predicted_value_accrued
    ).map((n: UnknownObject) => Number(n ? n.conversion_rate : 0));
    const maxConversion = Number(Math.max(...allConversions).toFixed(3));
    conversionRates = getModelValues(modelType).reduce(
      (acc: UnknownObject, modelValue: string) => {
        const percentage: number =
          Number(data_predicted_value_accrued[modelValue].conversion_rate) /
          maxConversion;

        acc[modelValue] = {
          value: data_predicted_value_accrued[modelValue].conversion_rate,
          percentage: `${Number(percentage.toFixed(3)) * 100}%`,
        };
        return acc;
      },
      {}
    );
  }
  return conversionRates;
}

export function calculateDealSize(performanceData: any, modelType: ModelTypes) {
  const dataPredictedValueAccrued = performanceData?.predicted_value_accrued;
  let dealSize: UnknownObject = DEFAULT_ANALYSIS_DATA_STRUCTURE;
  if (dataPredictedValueAccrued) {
    const allDealSizes = Object.values(
      dataPredictedValueAccrued
    ).map((n: UnknownObject) => Number(n.average_opportunity_amount));
    const maxDealSize = Number(Math.max(...allDealSizes).toFixed(3));
    dealSize = getModelValues(modelType).reduce(
      (acc: UnknownObject, modelValue) => {
        acc[modelValue] = {
          value:
            dataPredictedValueAccrued[modelValue].average_opportunity_amount,
          percentage: `${
            Number(
              (
                dataPredictedValueAccrued[modelValue]
                  .average_opportunity_amount / maxDealSize
              ).toFixed(2)
            ) * 100
          }%`,
        };
        return acc;
      },
      {}
    );
  }
  return dealSize;
}

export function sortOverrides(list: UnknownArrayOfObjects) {
  list.sort((a, b) => {
    const order = [
      'low',
      'D',
      'E',
      'medium',
      'C',
      'good',
      'high',
      'B',
      'very high',
      'very good',
      'A',
    ];
    const index_a = order.indexOf(a.segment);
    const index_b = order.indexOf(b.segment);
    return index_a - index_b;
  });
  return list;
}

function giveWeightsToAudienceNames(
  audiencesNames: string[]
): { audienceName: string; weight: number }[] {
  return audiencesNames.map((audienceName) => {
    if (audienceName.toLowerCase() === 'inbound') {
      return {
        audienceName,
        weight: 2,
      };
    }
    if (audienceName.toLowerCase() === 'all') {
      return {
        audienceName,
        weight: 0,
      };
    }
    return {
      audienceName,
      weight: 1,
    };
  });
}

export function getMostRelevantDefaultAudienceName(
  audiencesNames: string[],
  modelType: ModelTypes
): string {
  const weightedAudienceNames: {
    audienceName: string;
    weight: number;
  }[] = giveWeightsToAudienceNames(audiencesNames);
  if (modelType === 'customer_fit') {
    // ASC
    return weightedAudienceNames.sort((a, b) => {
      return b.weight - a.weight;
    })[0].audienceName;
  }
  // DESC
  return weightedAudienceNames.sort((a, b) => {
    return a.weight - b.weight;
  })[0].audienceName;
}
