import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import { BackboneViewProps } from '../../types';
import OverviewComponent from './components/custom/OverviewComponent';
import { getAttributes } from './utils';
import { isUserReadOnly } from '../../utils';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.isReadOnly = isUserReadOnly(user);
    this.email = user.email;
    const data = await getAttributes(this.tenant);
    this.date = data?.updated_at && new Date(data.updated_at).toISOString();
    this.lastPublisher = data?.email;
    this.date = data?.date && new Date(data?.date).toISOString();

    this.render();

    if (data) {
      if (data.isCustom) {
        Backbone.history.navigate(
          `org/${this.tenant}/mapping/attribute_mapping/configuration/custom`,
          true
        );
      } else {
        Backbone.history.navigate(
          `org/${this.tenant}/mapping/attribute_mapping/configuration/simple`,
          true
        );
      }
    } else {
      this.renderOverviewReactComponent(this.tenant, this.isReadOnly);
    }
  },

  renderOverviewReactComponent(tenant: number, isReadOnly: boolean) {
    ReactDOM.render(
      <OverviewComponent tenant={tenant} isReadOnly={isReadOnly} />,
      $('#OverviewComponentContainer')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
