import * as React from 'react';

type Props = React.HTMLProps<HTMLDivElement> & {
  variant?: 'error' | 'warning' | 'suggestion' | 'neutral';
};

const variantClasses = {
  error: 'tw-bg-red-100 tw-text-red-500',
  warning: 'tw-bg-orange-100 tw-text-orange-500',
  suggestion: 'tw-bg-blue-100 tw-text-blue-500',
  neutral: 'tw-bg-gray-100 tw-text-gray-500',
};

function Alert({ variant = 'suggestion', children, className = '' }: Props) {
  return (
    <div
      className={`tw-inline-block tw-p-2 tw-mt-2 tw-font-medium tw-text-sm ${variantClasses[variant]} ${className}`}
    >
      {children}
    </div>
  );
}

export default Alert;
