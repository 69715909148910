import React from 'react';
import { Button } from 'react-bootstrap';
import ModelDataModel from '../../../models/model/ModelDataModel';
import {
  IS_HYBRID,
  IS_LLTB,
  MODEL_TYPES_NAMES,
} from '../../../utils/constants';

type DiagnosticsHeaderComponentProps = {
  tenant: number;
  model: ModelDataModel;
  context: string;
  goTo: (url: string) => void;
};

export default class DiagnosticsHeaderComponent extends React.Component<
  DiagnosticsHeaderComponentProps,
  {}
> {
  constructor(props: DiagnosticsHeaderComponentProps) {
    super(props);

    this.getModelUrl = this.getModelUrl.bind(this);
    this.getPageTitle = this.getPageTitle.bind(this);
  }

  getModelUrl() {
    const { tenant, model, context } = this.props;
    const param = context === 'lead_grade' ? 'lead_grade' : '';
    return `/org/${tenant}/predictions/performances/models/${model.modelId}/diagnostics/model/${param}`;
  }

  getPageTitle() {
    const { model, context } = this.props;
    const { type, name, live, is_primary } = model;
    const modelName = live && !is_primary ? ` - ${name}` : '';
    if (type === 'customer_fit') {
      return `Diagnostics for Model: Customer Fit${modelName}`;
    }
    if (context === 'lead_grade') {
      return `Model: Lead Grade${
        IS_HYBRID(type) ? ` - ${MODEL_TYPES_NAMES[type as 'pql2' | 'mql']}` : ''
      }`;
    }
    if (type === 'pql') {
      return 'Model: Lead Likelihood to Buy';
    }
    if (type === 'pql2') {
      return 'Model: PQL';
    }
    if (type === 'mql') {
      return 'Model: MQL';
    }
    if (type === 'mqa') {
      return 'Model: Account Likelihood to Buy';
    }
  }

  render() {
    const { tenant, model, goTo, context } = this.props;
    const { modelId, type } = model;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between"
        aria-label="breadcrumb"
      >
        <ul className="navbar-nav navbar-brand mr-auto.mt-2 mt-lg-0">
          <li className="breadcrumb-item">
            <a
              className="breadcrumb-link text-primary"
              onClick={() => goTo(`/org/${tenant}/predictions`)}
            >
              Predictions
            </a>
          </li>
          {type === 'customer_fit' && (
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={() =>
                  goTo(
                    `/org/${tenant}/predictions/performances/models/${model.modelId}`
                  )
                }
              >
                Model Performance
              </a>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            {this.getPageTitle()}
          </li>
        </ul>
        <div>
          {type === 'customer_fit' && (
            <>
              <a
                className="btn btn-primary my-2 my-lg-0 mx-1"
                href={`https://studio.madkudu.com/tenant/${tenant}/models/${modelId}/model`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View model
              </a>
              <Button
                className="btn btn-primary my-2 my-lg-0 mx-1"
                onClick={() =>
                  goTo(`/org/${tenant}/evr_insights/customer_fit_deep_dive/0`)
                }
              >
                See deep dive
              </Button>
            </>
          )}
          {type === 'mqa' && (
            <>
              <Button
                className="btn btn-primary my-2 my-lg-0 mx-1"
                onClick={() => goTo(this.getModelUrl())}
              >
                Diagnostics
              </Button>
              <a
                className="btn btn-primary my-2 my-lg-0 mx-1"
                href={`https://studio.madkudu.com/tenant/${tenant}/models/${modelId}/model`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View model
              </a>
              <Button
                className="btn btn-primary my-2 my-lg-0 mx-1"
                onClick={() =>
                  goTo(`/org/${tenant}/evr_insights/mqa_deep_dive/0`)
                }
              >
                See deep dive
              </Button>
            </>
          )}
          {context === 'lead_grade' && (
            <>
              <Button
                className="btn btn-primary my-2 my-lg-0 mx-1"
                onClick={() => goTo(this.getModelUrl())}
              >
                Diagnostics
              </Button>
              <a
                className="btn btn-primary my-2 my-lg-0 mx-1"
                href={`https://studio.madkudu.com/tenant/${tenant}/models/${modelId}/lead_grade`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View model
              </a>
              {IS_HYBRID(type) || (
                <Button
                  className="btn btn-primary my-2 my-lg-0 mx-1"
                  onClick={() =>
                    goTo(`/org/${tenant}/evr_insights/lead_grade_deep_dive/0`)
                  }
                >
                  See deep dive
                </Button>
              )}
            </>
          )}
          {IS_LLTB(type) && context !== 'lead_grade' && (
            <>
              <Button
                className="btn btn-primary my-2 my-lg-0 mx-1"
                onClick={() => goTo(this.getModelUrl())}
              >
                Diagnostics
              </Button>
              <a
                className="btn btn-primary my-2 my-lg-0 mx-1"
                href={`https://studio.madkudu.com/tenant/${tenant}/models/${modelId}/model`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View model
              </a>
              {IS_HYBRID(type) || (
                <Button
                  className="btn btn-primary my-2 my-lg-0 mx-1"
                  onClick={() =>
                    goTo(`/org/${tenant}/evr_insights/behavioral_deep_dive/0`)
                  }
                >
                  See deep dive
                </Button>
              )}
            </>
          )}
        </div>
      </nav>
    );
  }
}
