import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const DATE_FORMAT = 'YYYY-MM-DD';

export type Values = {
  salesforceObject: 'Lead' | 'Contact' | 'Account';
  from: Date;
  nullFields?: string;
  pushDate?: Date;
};

export type ValuesToSubmit = {
  salesforceObject: 'Lead' | 'Contact' | 'Account';
  from: string;
  nullFields?: string;
  pushDate?: string;
};

const defaultValues: Values = {
  salesforceObject: 'Lead',
  from: moment().subtract(1, 'year').toDate(),
  nullFields: null,
  pushDate: null,
};

type ForcePushRecordsFormProps = {
  onSubmit: (values: ValuesToSubmit) => Promise<void>;
  sending: boolean;
};

function ForcePushRecordsForm({
  onSubmit,
  sending,
}: ForcePushRecordsFormProps): ReactElement {
  const [values, setValues] = useState<Values>(defaultValues);

  const handleChange = (event: any) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const setFrom = (date: Date) => {
    setValues({ ...values, from: date });
  };

  const setPushDate = (date: Date) => {
    setValues({ ...values, pushDate: date });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    await onSubmit({
      ...values,
      from: moment(values.from).format(DATE_FORMAT),
      pushDate: values.pushDate
        ? moment(values.pushDate).format(DATE_FORMAT)
        : null,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <legend>
        Trigger manually the push on records in Salesforce created after a
        specific date
      </legend>
      <div className="form-group row">
        <label htmlFor="salesforceObject" className="col-sm-2 col-form-label">
          Salesforce object*
        </label>
        <div className="col-sm-10">
          <select
            id="salesforceObject"
            className="form-control"
            value={values.salesforceObject}
            onChange={handleChange}
            required
          >
            <option value="Lead">Lead</option>
            <option value="Contact">Contact</option>
            <option value="Account">Account</option>
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="from" className="col-sm-2 col-form-label">
          Records created from*{' '}
          <i
            className="fas fa-info-circle"
            title="Only the records created since this date will be included in the push."
          />
        </label>
        <div className="col-sm-10">
          <DatePicker
            id="from"
            className="form-control"
            selected={values.from}
            onChange={setFrom}
            required
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="nullFields" className="col-sm-2 col-form-label">
          Having these fields that are null{' '}
          <i
            className="fas fa-info-circle"
            title="Only the records that have at least one of the specified fields being null will be included in the push."
          />
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="nullFields"
            placeholder="field1,field2"
            value={values.nullFields}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="pushDate" className="col-sm-2 col-form-label">
          Not updated since{' '}
          <i
            className="fas fa-info-circle"
            title="Only the records that haven't been updated since this date will be included in the push."
          />
        </label>
        <div className="col-sm-10">
          <DatePicker
            id="pushDate"
            className="form-control"
            selected={values.pushDate}
            onChange={setPushDate}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={sending}>
        {sending ? 'Pushing...' : 'Start push'}
      </button>
    </form>
  );
}

export default ForcePushRecordsForm;
