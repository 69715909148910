import axios from 'axios';
import { stringify, parse } from 'flatted';
import cloneDeep from 'lodash/cloneDeep';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getScriptCheckerStateBasedOnMappingMode } from '../../utils';
import MappingsMode from '../../enums/MappingsMode';
import { ConversionMappingData } from './ConversionMappingData';
import { getConversionMappingData } from '../utils';

export default class ConversionMappingManager {
  step: number;

  tenant: number;

  lastPublisher: string;

  email: string;

  createdAt: number;

  updatedAt: number;

  scriptCheckerStatus: boolean;

  isReadOnly: boolean;

  data: ConversionMappingData;

  isFinished: boolean;

  isSuperKudu: boolean;

  constructor(
    tenant: number,
    email: string,
    isReadOnly: boolean,
    isSuperKudu: boolean
  ) {
    this.tenant = tenant;
    this.email = email;
    this.isReadOnly = isReadOnly;
    this.scriptCheckerStatus = false;
    this.step = 0;
    this.lastPublisher = '';
    this.createdAt = Date.now();
    this.updatedAt = Date.now();
    this.data = new ConversionMappingData(this.tenant, this.email);
    this.isFinished = false;
    this.isSuperKudu = isSuperKudu;
  }

  async init() {
    const oldConversionMapping = (await getConversionMappingData(
      this.tenant
    )) as ConversionMappingManager;
    if (oldConversionMapping && oldConversionMapping.data) {
      const conversionMappingData = new ConversionMappingData(
        this.tenant,
        this.email
      );
      await conversionMappingData.init();
      conversionMappingData.build({
        conversionTypesGroups: cloneDeep(
          oldConversionMapping.data.conversionTypesGroups
        ),
        updatedAt: oldConversionMapping.data.updatedAt,
        createdAt: oldConversionMapping.data.createdAt,
        activeConnectors: oldConversionMapping.data.activeConnectors,
      });
      this.data = conversionMappingData;
      this.step = oldConversionMapping.step;
      this.isFinished = oldConversionMapping.isFinished;
      this.createdAt = oldConversionMapping.createdAt;
      this.updatedAt = oldConversionMapping.updatedAt;
      this.lastPublisher = oldConversionMapping.lastPublisher;
      this.email = oldConversionMapping.email;
    }
    const {
      data: scriptCheckerData,
    } = await getScriptCheckerStateBasedOnMappingMode(
      this.tenant,
      MappingsMode.conversion
    );
    this.scriptCheckerStatus = !!scriptCheckerData;
    await this.data.init();
  }

  prepare() {
    this.data.prepare();
  }

  async save(): Promise<ConversionMappingManager> {
    this.prepare();
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/conversion`;
    const { data } = await axios.put(url, this);
    return parse(stringify(data));
  }

  async launchPublishSwal(description: string, redirection: () => void) {
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/conversion`;

    this.prepare();

    let swalSettings: SweetAlertOptions = {
      title: 'Are you sure?',
      text: description,
      icon: 'warning',

      showCancelButton: true,
      cancelButtonText: 'cancel',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, publish it!',
    };
    if (this.isSuperKudu) {
      swalSettings = {
        ...swalSettings,
        input: 'checkbox',
        inputPlaceholder:
          'Define as default conversion mappings for all new tenants',
      };
    }

    const { value: setAsDefaultMapping, isConfirmed } = await Swal.fire(
      swalSettings
    );

    const defaultMappingsMetadata: Record<string, unknown> = {
      isDefaultMappings: false,
      type: MappingsMode.conversion,
    };

    if (isConfirmed) {
      if (this.isSuperKudu && setAsDefaultMapping === 1) {
        defaultMappingsMetadata.isDefaultMappings = true;

        const { value: associatedConnector } = await Swal.fire({
          title: 'Connector?',
          text: 'Select the associated connector',
          input: 'select',
          inputOptions: {
            salesforce: 'salesforce',
            hubspot: 'hubspot',
            analytics: 'analytics',
          },
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: '#3085d6',
        });
        defaultMappingsMetadata.associatedConnector = associatedConnector;

        const { value: associatedPackage } = await Swal.fire({
          title: 'Package?',
          text: 'Select the associated package',
          input: 'select',
          inputOptions: { default: 'default' },
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
        });
        defaultMappingsMetadata.associatedPackage = associatedPackage;
      }

      Swal.fire({
        title: 'Publishing in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();
      let response;
      let error;
      try {
        response = await axios.post(url, {
          ...this,
          defaultMappingsMetadata,
        });
      } catch (e) {
        error = e;
      }

      Swal.close();

      if (response.status < 200 || response.status > 300 || error) {
        Swal.fire({
          icon: 'error',
          title: `Request failed: ${error?.message ?? response.data}!`,
        });
      } else {
        window.analytics.track(
          `${this.isFinished ? 'Publish' : 'Create new'} mapping`,
          {
            mode: 'simple',
            map: 'conversion',
            tenant: this.tenant,
            email: this.email,
          }
        );
        Swal.fire({
          icon: 'success',
          title: 'Published successfully!',
        });
        redirection();
      }
    }
  }

  launchGoBackSwal(redirection: () => void) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'If you go back, your changes will be lost!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Abort changes & go back!',
      confirmButtonText: 'Cancel',
    }).then((response: any) => {
      if (response?.dismiss === 'cancel') {
        redirection();
      }
    });
  }
}
