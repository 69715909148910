import { handleErrors } from './handle_errors';

const PASSWORD_REGEX = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,50}/;

export function checkPassword(password: string, email: string) {
  const splitEmail = email.split('@');
  const emailSplitted = splitEmail[0];
  if (
    !PASSWORD_REGEX.test(password) ||
    password === emailSplitted ||
    password === email
  ) {
    const error = new Error('invalid_password_format');
    handleErrors(error);
    return false;
  }
  return true;
}
