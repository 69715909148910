import axios from 'axios';
import Backbone from 'backbone';
import cloneDeep from 'lodash/cloneDeep';
import differenceWith from 'lodash/differenceWith';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import {
  getAudienceParams,
  getSimpleAudiencesNames,
  LEAD_MAPPING_OBJECTS,
  mapAudienceConditionsToSql,
  mapAudienceFieldsToSql,
} from '../utils';
import { ParametersFieldsNamesDataModel } from '../../../models/ConnectorFieldNameDataModel';
import { AudienceMappingConnectorsNames } from '../../../../types';
import ActiveIntegrationDataModel from '../../../event_mapping/models/ActiveIntegrationDataModel';
import { extractFieldNamesFromObjectsByDatasource } from '../../../pullConfigManager';
import DataSources from '../../../enums/DataSources';
import { AudienceMappingCondition } from './AudienceMappingCondition';
import { getAllActiveIntegrations, getVerbBoundaries } from '../../../utils';
import VerbsNames from '../../../enums/VerbsNames';
import FieldDataModel from '../../../models/FieldDataModel';
import { CONDITION_LOGIC_CHECK_REGEX } from '../../../constants';
import MappingsMode from '../../../enums/MappingsMode';

function updateConditionChange(
  verb: VerbsNames,
  conditionIndex: number,
  conditions: AudienceMappingCondition[]
): AudienceMappingCondition[] {
  const boundaries = getVerbBoundaries(verb);
  const newConditions = conditions;
  newConditions[conditionIndex].verb = verb;
  newConditions[conditionIndex].values = [];
  for (let i = 0; i <= boundaries.min - 1; i += 1) {
    conditions[conditionIndex].values.push('');
  }
  return conditions;
}

type ErrorProperty =
  | 'object'
  | 'sourceSystem'
  | 'trait'
  | 'verb'
  | 'values'
  | 'conditionLogic - empty'
  | 'conditionLogic - extra conditions'
  | 'conditionLogic - ignored conditions'
  | 'conditionLogic - syntax error';

type Error = {
  index: number;

  property: ErrorProperty;

  indexes?: number[];
};

export default class AudienceMappingManager {
  tenant: number;

  updatedAt: number;

  createdAt: number;

  name: string;

  email: string;

  lastPublisherEmail: string;

  connectorFieldsName: ParametersFieldsNamesDataModel;

  activeIntegrations: AudienceMappingConnectorsNames[];

  accountLeadMapping: string;

  domainFields: string;

  opportunityLeadMapping: string;

  isCustom: boolean;

  conditionsLogic: string;

  conditions: AudienceMappingCondition[];

  isNewAudience: boolean;

  audienceNames: string[];

  errors: Error[];

  isFixing: boolean;

  isSuperKudu: boolean;

  isConditionLogicCustom: boolean;

  constructor(
    tenant: number,
    email: string,
    name: string,
    isSuperKudu: boolean
  ) {
    this.isNewAudience = name === 'newAudience' || name === 'newCustomAudience';
    this.tenant = tenant;
    this.name = this.isNewAudience ? '' : name;
    this.email = email;
    this.lastPublisherEmail = '';
    this.isCustom = this.isNewAudience ? name === 'newCustomAudience' : false;
    this.isSuperKudu = isSuperKudu;
    this.updatedAt = Date.now();
    this.createdAt = Date.now();
    this.accountLeadMapping = '';
    this.domainFields = '';
    this.opportunityLeadMapping = '';
    this.conditionsLogic = '';
    this.activeIntegrations = [];
    this.conditions = [];
    this.audienceNames = [];
    this.errors = [];
    this.isFixing = false;
    this.isConditionLogicCustom = false;
    this.connectorFieldsName = {
      salesforce: [],
      marketo: [],
      hubspot: [],
      stripe: [],
    };
  }

  build(dbData: any) {
    if (dbData) {
      const {
        email,
        updated_at,
        created_at,
        updatedAt,
        createdAt,
        accountLeadMapping,
        domainFields,
        opportunityLeadMapping,
        isCustom,
        conditionsLogic,
        conditions,
        isConditionLogicCustom,
      } = dbData;
      this.lastPublisherEmail = email;
      this.isCustom = isCustom ?? this.isCustom;
      this.updatedAt = (updatedAt || updated_at) ?? this.updatedAt;
      this.createdAt = (createdAt || created_at) ?? this.createdAt;
      this.accountLeadMapping = accountLeadMapping ?? '';
      this.domainFields = domainFields ?? '';
      this.opportunityLeadMapping = opportunityLeadMapping ?? '';
      this.isConditionLogicCustom = isConditionLogicCustom ?? false;
      this.conditionsLogic = conditionsLogic;
      if (conditions?.length > 0) {
        this.conditions = conditions
          .filter((condition: AudienceMappingCondition) => condition)
          .map(
            (condition: AudienceMappingCondition) =>
              new AudienceMappingCondition({
                values: condition.values,
                object: condition.object,
                sourceSystem: condition.sourceSystem,
                verb: condition.verb,
                trait: condition.trait,
                lower: condition.lower,
              })
          );
      } else if (!this.isCustom) {
        // Only add a default condition if not MadML.
        this.addCondition();
      }
    }
  }

  private async getDbData(): Promise<any> {
    return Promise.all([
      getAudienceParams(this.tenant, this.name),
      getAllActiveIntegrations(this.tenant),
      getSimpleAudiencesNames(this.tenant),
    ]);
  }

  async initConnectorFieldsNames(): Promise<void> {
    const listOfPromises = this.activeIntegrations.map((activeIntegration) =>
      extractFieldNamesFromObjectsByDatasource(
        this.tenant,
        activeIntegration as DataSources
      )
    );

    const listOfConnectorFieldsNames = await Promise.all(listOfPromises);
    listOfConnectorFieldsNames.forEach((connectorFieldNames, index) => {
      this.connectorFieldsName[
        this.activeIntegrations[index]
      ] = connectorFieldNames;
    });
  }

  initActiveIntegrations(allActiveIntegrations: ActiveIntegrationDataModel[]) {
    this.activeIntegrations = allActiveIntegrations
      .filter((activeIntegration: ActiveIntegrationDataModel) =>
        ['salesforce', 'hubspot', 'stripe', 'marketo'].includes(
          activeIntegration?.name
        )
      )
      .map(
        (activeIntegration: ActiveIntegrationDataModel) =>
          activeIntegration.name as AudienceMappingConnectorsNames
      );
  }

  async init() {
    const [
      dbData,
      allActiveIntegrations,
      audienceNames,
    ] = await this.getDbData();

    this.audienceNames = audienceNames;

    this.initActiveIntegrations(allActiveIntegrations);

    await this.initConnectorFieldsNames();

    this.build(dbData);
  }

  addCondition() {
    const newCondition = AudienceMappingCondition.create();
    this.conditions.push(newCondition);
    if (this.conditions?.length > 1) {
      this.computeLogicNewParameter();
    } else {
      this.computeConditionsLogic();
    }
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    this.checkLeadMappingObjectsAndUpdate();
    return this;
  }

  computeLogicNewParameter() {
    const lastIndex = this.conditions.length;
    this.conditionsLogic = (this.conditionsLogic ?? '').concat(
      ` AND $${lastIndex}`
    );
  }

  removeCondition(conditionIndex: number) {
    this.conditions.splice(conditionIndex, 1);
    if (!this.isConditionLogicCustom) {
      this.computeConditionsLogic();
    }
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    this.checkLeadMappingObjectsAndUpdate();
    return this;
  }

  setConditionSourceSystem(
    newSourceSystem: AudienceMappingConnectorsNames,
    conditionIndex: number
  ) {
    this.conditions[conditionIndex].sourceSystem = newSourceSystem;
    this.conditions[conditionIndex].object = null;
    this.conditions[conditionIndex].trait = null;
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    this.checkLeadMappingObjectsAndUpdate();
    return this;
  }

  checkLeadMappingObjectsAndUpdate() {
    const hasAccountCondition: boolean = this.conditions.some(
      (condition) => condition.object === 'Account'
    );
    const hasOpportunityCondition: boolean = this.conditions.some(
      (condition) => condition.object === 'Opportunity'
    );
    if (hasAccountCondition) {
      this.accountLeadMapping =
        this.accountLeadMapping || LEAD_MAPPING_OBJECTS.Account[0];
    } else {
      this.accountLeadMapping = '';
    }
    if (hasOpportunityCondition) {
      this.opportunityLeadMapping =
        this.opportunityLeadMapping || LEAD_MAPPING_OBJECTS.Opportunity[0];
    } else {
      this.opportunityLeadMapping = '';
    }
  }

  setConditionObject(newObject: string, conditionIndex: number) {
    this.conditions[conditionIndex].object = newObject;
    this.conditions[conditionIndex].trait = null;

    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    this.checkLeadMappingObjectsAndUpdate();
    return this;
  }

  setConditionTrait(newTrait: string, conditionIndex: number) {
    this.conditions[conditionIndex].trait = newTrait;
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    return this;
  }

  setConditionVerb(newVerb: VerbsNames, conditionIndex: number) {
    this.conditions = updateConditionChange(
      newVerb,
      conditionIndex,
      this.conditions
    );
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    return this;
  }

  setConditionValue(
    newValue: string,
    conditionIndex: number,
    valueIndex: number
  ) {
    this.conditions[conditionIndex].values[valueIndex] = newValue;
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    return this;
  }

  setConditionLower(conditionIndex: number) {
    this.conditions[conditionIndex].lower = !this.conditions[conditionIndex]
      .lower;
    this.updatedAt = Date.now();
    return this;
  }

  addConditionValue(conditionIndex: number) {
    this.conditions[conditionIndex].values.push('');
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    return this;
  }

  removeConditionValue(conditionIndex: number) {
    this.conditions[conditionIndex].values.pop();
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    return this;
  }

  setConditionsLogic(newConditionsLogic: string) {
    this.conditionsLogic = newConditionsLogic;
    this.isConditionLogicCustom = true;
    this.updatedAt = Date.now();
    if (this.isFixing) {
      this.checkErrors();
    }
    return this;
  }

  setName(newAudienceName: string) {
    this.name = newAudienceName;
    this.updatedAt = Date.now();
    return this;
  }

  computeConditionsLogic() {
    if (this.conditions?.length === 1) {
      this.conditionsLogic = '$1';
    } else if (this.conditions?.length === 0) {
      this.conditionsLogic = '';
    } else {
      this.conditionsLogic = this.conditions
        .map((_, index) => `$${index + 1}`)
        .join(' AND ');
    }
  }

  getFields(
    sourceSystem: AudienceMappingConnectorsNames,
    object: string
  ): FieldDataModel[] {
    if (!sourceSystem || !object) {
      return [];
    }

    const fields =
      this.connectorFieldsName[sourceSystem.toLowerCase()]?.find(
        (connectorFieldName) =>
          connectorFieldName.title.toLowerCase() === object.toLowerCase()
      )?.fields ?? [];

    return fields;
  }

  checkErrors() {
    this.errors = [];
    if (!this.isCustom) {
      if (this.conditions?.length > 1 && this.conditionsLogic.trim() === '') {
        this.errors.push({
          index: 0,
          property: 'conditionLogic - empty',
        });
      }
      const usedIndexes = this.conditionsLogic
        ?.match(/\d/gi)
        ?.map((index) => Number(index));
      const conditionIndexes: number[] = [];
      this.conditions.forEach((condition, index) => {
        conditionIndexes.push(index + 1);
        if (!condition.sourceSystem) {
          this.errors.push({
            index,
            property: 'sourceSystem',
          });
        }
        if (!condition.object) {
          this.errors.push({
            index,
            property: 'object',
          });
        }
        if (!condition.trait) {
          this.errors.push({
            index,
            property: 'trait',
          });
        }
        if (!condition.verb) {
          this.errors.push({
            index,
            property: 'verb',
          });
        }
        if (
          condition.values
            .map((value) => value.trim())
            .some((value) => value === '')
        ) {
          this.errors.push({
            index,
            property: 'values',
          });
        }
      });
      const ignoredIndexes = differenceWith(conditionIndexes, usedIndexes);
      const extraIndexes = differenceWith(usedIndexes, conditionIndexes);
      if (ignoredIndexes?.length) {
        this.errors.push({
          index: 0,
          property: 'conditionLogic - ignored conditions',
          indexes: ignoredIndexes,
        });
      }
      if (extraIndexes?.length) {
        this.errors.push({
          index: 0,
          property: 'conditionLogic - extra conditions',
          indexes: extraIndexes,
        });
      }
      const syntaxCheckResult = this.conditionsLogic
        ?.trim()
        ?.match(CONDITION_LOGIC_CHECK_REGEX);
      if (!syntaxCheckResult) {
        this.errors.push({
          index: 0,
          property: 'conditionLogic - syntax error',
        });
      }
    }
    return this;
  }

  hasErrorOnProperty(property: ErrorProperty, conditionIndex: number): boolean {
    return this.errors.some(
      (error) => error.property === property && error.index === conditionIndex
    );
  }

  getErrorOfProperty(property: ErrorProperty, conditionIndex: number): Error {
    return this.errors.find(
      (error) => error.property === property && error.index === conditionIndex
    );
  }

  async publish() {
    if (this.errors?.length > 0) {
      Swal.fire({
        title: 'Form errors',
        text: `It seems you have invalid inputs, please enter valid values.`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    const {
      conditions,
      conditionsLogic,
      domainFields,
      accountLeadMapping,
      opportunityLeadMapping,
      tenant,
      email,
      name,
    } = cloneDeep(this);
    const data = {
      tenant,
      email,
      name,
      conditions,
      conditionsLogic: conditionsLogic ?? '',
      domainFields,
      accountLeadMapping,
      opportunityLeadMapping,
      sqlConditions: mapAudienceConditionsToSql(conditions, conditionsLogic),
      sqlFields: mapAudienceFieldsToSql(conditions),
    };

    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/mappings/audiences/${name}`;
    if (['', 'newAudience', 'newCustomAudience'].includes(name)) {
      Swal.fire({
        title: 'Name error',
        text: 'Need a valid name for new audience',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
      return;
    }
    const lowerCasedAudienceNames = this.audienceNames.map(
      (audienceName: string) => audienceName.toLowerCase()
    );
    if (
      this.isNewAudience &&
      lowerCasedAudienceNames.includes(name.toLowerCase())
    ) {
      Swal.fire({
        title: 'Name error',
        text: `${name} already exist!`,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    let swalSettings: SweetAlertOptions = {
      title: 'Publish audience?',
      text:
        'This will create or update this audience. You will receive an email when finished.',
      icon: 'info',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, publish it!',
      showLoaderOnConfirm: true,
    };

    if (this.isSuperKudu) {
      swalSettings = {
        ...swalSettings,
        input: 'checkbox',
        inputPlaceholder:
          'Define as default conversion mappings for all new tenants',
      };
    }

    const { value: setAsDefaultMapping, isConfirmed } = await Swal.fire(
      swalSettings
    );

    const defaultMappingsMetadata: Record<string, unknown> = {
      isDefaultMappings: false,
      type: MappingsMode.audience,
    };

    if (isConfirmed) {
      if (this.isSuperKudu && setAsDefaultMapping === 1) {
        defaultMappingsMetadata.isDefaultMappings = true;

        const { value: associatedConnector } = await Swal.fire({
          title: 'Connector?',
          text: 'Select the associated connector',
          input: 'select',
          inputOptions: {
            salesforce: 'salesforce',
            hubspot: 'hubspot',
            analytics: 'analytics',
          },
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonColor: '#3085d6',
        });
        defaultMappingsMetadata.associatedConnector = associatedConnector;

        const { value: associatedPackage } = await Swal.fire({
          title: 'Package?',
          text: 'Select the associated package',
          input: 'select',
          inputOptions: { default: 'default' },
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
        });
        defaultMappingsMetadata.associatedPackage = associatedPackage;
      }

      Swal.fire({
        title: 'Publishing in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();

      try {
        await axios({
          method: this.isNewAudience ? 'POST' : 'PUT',
          url,
          data: {
            ...data,
            defaultMappingsMetadata,
          },
        });

        window.analytics.track(
          `${this.isNewAudience ? 'Publish' : 'Create new'} mapping`,
          {
            mode: 'simple',
            map: 'audience',
            tenant: this.tenant,
            email: this.email,
          }
        );

        await Swal.fire({
          icon: 'success',
          title: 'Audience submitted',
        });
        Backbone.history.navigate(`/org/${tenant}/mapping/audiences`, true);
      } catch (err) {
        await Swal.fire({
          icon: 'error',
          title: `Request failed: ${err?.message}!`,
        });
      }
    }
  }
}
