import Backbone from 'backbone';
import $ from 'jquery';
import $script from 'scriptjs';
import Swal from 'sweetalert2';
import app from '../app';
import { BackboneViewProps } from '../types';
import Billing_model from './model_tenant_billing';

const template = require('./billing.pug');
const template_403 = require('../errors/403.pug');

const Billing_info_view = Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
  },

  render() {
    const html = $(
      '#tpl_billing_info',
      template({
        plan: this.model.get('plan'),
        user: app.session.user,
        tenant: this.tenant,
      })
    ).html();
    this.$el.html(html);
    window.Stripe.setPublishableKey(app.session.config.stripe.publishable_key);
    this.populate_inputs();
    this.add_stripe_interactions();
    this.add_update_listener();
  },

  populate_inputs() {
    // show masked credit card if a card is already on file
    if (this.model && this.model.get('stripe_token')) {
      $('#mki_form_new_credit_card').hide();
      $('#mki_buttons').hide();
      $('#mki_div_credit_card_on_file').show();
    } else {
      // show credit card form if not credit card on file
      $('#mki_div_credit_card_on_file').hide();
      $('#mki_form_new_credit_card').show();
      $('#mki_buttons').show();
    }
  },

  add_update_listener() {
    $('#mki_btn_update_payment_details').click(() => {
      $('#mki_div_credit_card_on_file').hide();
      $('#mki_form_new_credit_card').show();
      $('#mki_buttons').show();
    });
  },

  show_error_msg(text: string) {
    Swal.fire({
      title: 'There was an issue',
      text,
      icon: 'error',
      allowOutsideClick: true,
    });
  },

  show_success_msg() {
    Swal.fire({
      title: 'Changes updated successfully',
      text: '',
      icon: 'success',
      allowOutsideClick: true,
    }).then(() => {
      this.render();
    });
  },

  validate_credit_card(
    card_number: number,
    cvc: number,
    exp_month: number,
    exp_year: number
  ) {
    const is_card_valid = window.Stripe.card.validateCardNumber(card_number);
    const is_expiration_valid = window.Stripe.card.validateExpiry(
      exp_month,
      exp_year
    );
    const is_cvc_valid = window.Stripe.card.validateCVC(cvc);

    if (!is_card_valid || !is_expiration_valid || !is_cvc_valid) {
      this.handle_cc_error(is_card_valid, is_expiration_valid, is_cvc_valid);
      return false;
    }
    return true;
  },

  handle_cc_error(
    is_card_valid: boolean,
    is_expiration_valid: boolean,
    is_cvc_valid: boolean
  ) {
    let error_message = 'The ';
    let something_before = false;
    if (!is_card_valid) {
      error_message += 'card number';
      something_before = true;
    }
    if (!is_expiration_valid) {
      if (something_before) {
        error_message += ' and ';
      }
      error_message += 'expiration date';
      something_before = true;
    }
    if (!is_cvc_valid) {
      if (something_before) {
        error_message += ' and ';
      }
      error_message += 'CVC code';
      something_before = true;
    }
    error_message += ' you entered';
    error_message += error_message.indexOf('and') === -1 ? ' seems' : ' seem';
    error_message += ' to be incorrect. Please try again.';
    this.show_error_msg(error_message);
  },

  stripe_response_handler(status: any, response: any) {
    if (response.error) {
      console.error(response.error);
      this.show_error_msg(response.error.message);
    } else {
      // send the card info to the server
      const credit_card_number = ($('#mki_input_card_number').val() as string)
        .replace('-', '')
        .replace(' ', '')
        .replace('.', '');
      const data = {
        stripe_token: response.id,
        cc_last_4: credit_card_number.substring(
          credit_card_number.length - 4,
          credit_card_number.length
        ),
      };
      this.model.save(data, {
        success: () => {
          window.analytics.track('Added credit card', { tenant: this.tenant });
          this.show_success_msg();
        },
        error: (_model: any, newRes: UnknownObject = {}) => {
          const { responseJSON } = newRes;
          const default_msg =
            "There was an error updating your billing info. We've been notified and will let you know when this is resolved";
          this.show_error_msg(responseJSON.message || default_msg);
        },
      });
    }
  },

  set_plan() {
    let plan_value = '';
    const selected = $('#mki_form_plan input[type="radio"]:checked');
    if (selected.length > 0) {
      plan_value = selected.val() as string;
    }
    this.model.set('plan', plan_value);
  },

  add_stripe_interactions() {
    $('#btn_cancel').click((event) => {
      event.preventDefault();
      this.render();
    });

    $('#btn_save').click((event) => {
      event.preventDefault();

      // check credit card info and save to server if credit card is being edited
      if ($('#mki_form_new_credit_card').is(':visible')) {
        const card_number = ($(
          '#mki_input_card_number'
        ).val() as string).replace('-', '');
        const cvc = $('#mki_input_cvc').val();
        const exp_month = $('#mki_input_exp_month').val();
        const exp_year = $('#mki_input_exp_year').val();

        const is_valid = this.validate_credit_card(
          card_number,
          cvc,
          exp_month,
          exp_year
        );
        if (is_valid) {
          // case where the entered information seems correct
          window.Stripe.card.createToken(
            {
              number: card_number,
              cvc,
              exp_month,
              exp_year,
            },
            this.stripe_response_handler.bind(this)
          );
        }
      }
    });
  },
});

const Billing_view = Backbone.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.model = new Billing_model({ tenant: this.tenant });
    this.billing_info_view = new Billing_info_view({
      model: this.model,
      tenant: this.tenant,
    });
    this.listenTo(this.model, 'sync', this.render);
    this.model.fetch({
      error: () => this.render_error(),
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = $(
      '#tpl_billing',
      template({ tenant: this.tenant, user: app.session.user.attributes })
    ).html();
    this.$el.html(html);
    $script('https://js.stripe.com/v2/', () => {
      this.assign(this.billing_info_view, '#billing_info');
    });
    return this;
  },

  render_error() {
    this.$el.html(template_403());
    return this;
  },
});

export default Backbone.View.extend({
  initialize() {},

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();
    const html = $('#tpl_main', template()).html();
    this.$el.html(html);
    this.assign(
      new Billing_view({ tenant: this.model.get('_id') }),
      '#billing'
    );
    return this;
  },
});
