import axios from 'axios';
import 'chartjs-plugin-datalabels';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Loader } from '../../../components/Loader';
import { DataSourceDefinition, PageDefinition } from '../../InsightsTypes';
import ConfigurationFormComponent from './ConfigurationFormComponent';

type ConfigurationPageComponentProps = {
  tenant: number;
  superKudu: boolean;
  pageId: string;
  title: string;
  email: string;
  back: () => void;
};

type ConfigurationPageComponentState = {
  dataSources: DataSourceDefinition[];
  configured: boolean;
  changed: boolean;
  error: boolean;
};

export default class ConfigurationPageComponent extends React.Component<
  ConfigurationPageComponentProps,
  ConfigurationPageComponentState
> {
  constructor(props: ConfigurationPageComponentProps) {
    super(props);
    this.getDataSourceForPage = this.getDataSourceForPage.bind(this);
    this.loadConfigurations = this.loadConfigurations.bind(this);
    this.reset = this.reset.bind(this);
    this.handleDefinitionChange = this.handleDefinitionChange.bind(this);
    this.publishConfirmation = this.publishConfirmation.bind(this);
    this.resetConfirmation = this.resetConfirmation.bind(this);

    this.state = {
      dataSources: [],
      configured: false,
      changed: false,
      error: false,
    };
  }

  async componentDidMount() {
    // eslint-disable-next-line
    this.loadConfigurations();
  }

  async getDataSourceForPage(): Promise<{
    dataSources: string[];
    configured: boolean;
  }> {
    const { tenant, pageId } = this.props;

    const urlPage = `${BONGO_URL}/v1/org/${tenant}/insights/page/${pageId}`;
    const { data: dataPage } = await axios.get(urlPage);
    const {
      page,
      configured,
    }: { page: PageDefinition; configured: boolean } = dataPage;
    const { data_sources: dataSources } = page;
    return { dataSources, configured };
  }

  async loadConfigurations() {
    try {
      const { tenant } = this.props;

      // get page
      const { dataSources, configured } = await this.getDataSourceForPage();

      const loadConfiguration = async (dataSource: string) => {
        // get data source configuration
        const urlConfiguration = `${BONGO_URL}/v1/org/${tenant}/insights/etl/${dataSource}`;
        const { data: dataConfiguration } = await axios.get(urlConfiguration);
        const { definition }: { definition: UnknownObject } = dataConfiguration;

        if (!definition) return null;

        const dataSourceDefinition: DataSourceDefinition = {
          definition,
          name: dataSource,
        };

        return dataSourceDefinition;
      };

      const dataSourcesDefinition = await Promise.all(
        dataSources.map(loadConfiguration)
      );

      this.setState({
        dataSources: dataSourcesDefinition.filter(Boolean),
        configured,
        changed: false,
      });
    } catch (err) {
      this.setState({
        error: true,
      });
    }
  }

  async reset() {
    this.setState({
      dataSources: [],
      configured: false,
      error: false,
      changed: false,
    });
    const { email, tenant, pageId } = this.props;

    try {
      // Reload
      await this.loadConfigurations();

      if (window.analytics) {
        window.analytics.track('Insights reset configuration', {
          email,
          tenant,
          insights_report: pageId,
        });
      }
    } catch (err) {
      this.setState({
        error: true,
      });
    }
  }

  async publish() {
    this.setState({
      error: false,
    });
    const { email, tenant, pageId } = this.props;

    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const dataSource of this.state.dataSources) {
        const { name, definition } = dataSource;
        // Save etl
        const saveDataSourceUrl = `${BONGO_URL}/v1/org/${tenant}/insights/etl/${name}`;
        // eslint-disable-next-line no-await-in-loop
        await axios.post(saveDataSourceUrl, { parameters: definition });
      }

      if (window.analytics) {
        window.analytics.track('Insights publish configuration', {
          email,
          tenant,
          insights_report: pageId,
        });
      }

      this.setState({
        configured: true,
        error: false,
        changed: false,
      });
    } catch (error) {
      this.setState({
        error: true,
      });
    }
  }

  handleDefinitionChange(name: string, newDefinition: UnknownObject) {
    this.setState({
      dataSources: this.state.dataSources.map((dataSource) => {
        if (dataSource.name === name)
          return { ...dataSource, definition: newDefinition };

        return dataSource;
      }),
      changed: true,
    });
  }

  async publishConfirmation() {
    const res = await Swal.fire({
      title: 'Publish Confirmation',
      icon: 'info',
      text:
        'Are you sure you want to publish? This will override your current parameters.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    });
    if (res.isConfirmed) {
      Swal.fire({
        title: 'Publishing in progress',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
      });
      Swal.showLoading();

      try {
        await this.publish();

        await Swal.fire({
          icon: 'success',
          title: 'Published successfully!',
        });
      } catch (err) {
        await Swal.fire({
          icon: 'error',
          title: `Request failed: ${err?.message}!`,
        });
      }
    }
  }

  resetConfirmation() {
    Swal.fire({
      title: 'Reset Confirmation',
      text:
        'Are you sure you want to reset the form? The form values will be replaced with the live ones.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.reset();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  render() {
    const { title, back, superKudu } = this.props;
    const { dataSources, configured, changed, error } = this.state;

    if (error) {
      return <Alert bsStyle="danger">Cannot load the configuration</Alert>;
    }
    if (!dataSources.length) return <Loader />;

    return (
      <div>
        <div
          className="nav navbar navbar-expand-lg navbar-light bg-light"
          aria-label="breadcrumb"
        >
          <ul className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0">
            <li className="breadcrumb-item">
              <a onClick={back} className="breadcrumb-link text-primary">
                Insights - {title}
              </a>
            </li>
            <li className="breadcrumb-item">Configuration</li>
          </ul>
          <div className="my-2 my-lg-0">
            {!configured && !changed && (
              <i className="p gray-1 mr-2">
                Please Configure All Global Variables
              </i>
            )}
            {changed && (
              <i className="p gray-1 mr-2">
                You have changes - publish them to save
              </i>
            )}
            {!changed && configured && (
              <Button className="btn-success mr-2">Published</Button>
            )}
            {changed && (
              <Button
                className="btn-primary mr-2"
                onClick={this.publishConfirmation}
              >
                Publish
              </Button>
            )}
            {superKudu && (
              <Button className="btn-danger" onClick={this.resetConfirmation}>
                Reset form
              </Button>
            )}
          </div>
        </div>
        <div className="container-fluid">
          <ConfigurationFormComponent
            superKudu={superKudu}
            dataSourcesDefinition={dataSources}
            handleDefinitionChange={this.handleDefinitionChange}
          />
        </div>
      </div>
    );
  }
}
