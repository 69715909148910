import { ConnectorsNames, MappingConversionsTypes } from '../../../../types';
import { ConnectorFieldNameDataModel } from '../../../models/ConnectorFieldNameDataModel';

export type AbstractFiltersFormOptions = {
  createdAt: number;
  updatedAt: number;
};
export abstract class AbstractFiltersForm {
  connector: ConnectorsNames;

  tenant: number;

  conversionType: MappingConversionsTypes;

  parametersFieldsNames: ConnectorFieldNameDataModel[];

  createdAt: number;

  updatedAt: number;

  protected constructor(
    tenant: number,
    connector: ConnectorsNames,
    conversionType: MappingConversionsTypes,
    parametersFieldsNames: ConnectorFieldNameDataModel[],
    options?: AbstractFiltersFormOptions
  ) {
    this.tenant = tenant;
    this.connector = connector;
    this.conversionType = conversionType;
    this.parametersFieldsNames = parametersFieldsNames;
    if (options?.createdAt) {
      this.createdAt = options.createdAt;
    } else {
      this.createdAt = Date.now();
    }
    if (options?.updatedAt) {
      this.updatedAt = options.updatedAt;
    } else {
      this.updatedAt = Date.now();
    }
  }

  abstract init(): Promise<void>;

  abstract prepare(): void;
}
