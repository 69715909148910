import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useGithubIsConnected } from './hooks/useGithubIsConnected';
import { Loading } from './Loading';
import { useGithubCredentials } from './hooks/useGithubCredentials';
import { useDisconnectGithub } from './hooks/useDisconnectGithub';
import { Form } from '../../components/ui/Form/Form';
import { Button, Input } from '../../components/ui';
import { useSaveGithubCredentials } from './hooks/useSaveGithubCredentials';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PremiumIntegrationWarning from '../utils/PremiumIntegrationWarning';

type Props = {
  tenant: number;
  hasAccessToConfiguration: boolean;
};

export function GithubOverview({ tenant, hasAccessToConfiguration }: Props) {
  const { data: isGithubConnected, isLoading } = useGithubIsConnected(tenant);
  const { enableSocialSignals } = useFlags();
  const isConnected = isGithubConnected && enableSocialSignals;

  const {
    data: credentials,
    isLoading: isLoadingCredentials,
  } = useGithubCredentials(tenant);

  const [repositories, setRepositories] = useState<
    { value: string; id: string }[]
  >([]);
  const generateId = () => Math.random().toString(36).substring(7);

  useEffect(() => {
    if (!isLoadingCredentials && !isLoading && credentials) {
      setRepositories(
        credentials?.repositories.map((repository) => ({
          value: repository,
          id: generateId(),
        })) || []
      );
    }
  }, [credentials, isLoadingCredentials, isLoading]);

  const {
    mutate,
    isLoading: isConnecting,
    isSuccess: hasSavedCredentials,
    isError,
    reset,
  } = useSaveGithubCredentials(tenant, {
    onSuccess: (values) => {
      setRepositories(
        values.repositories.map((repository) => ({
          value: repository,
          id: generateId(),
        }))
      );
    },
  });

  const {
    mutate: disconnectGithub,
    isLoading: isDisconnecting,
  } = useDisconnectGithub(tenant, {
    onSuccess: () => {
      setRepositories([]);
    },
  });

  if (isLoading || isLoadingCredentials)
    return <Loading loadingLabel="Checking Github connection" />;

  return (
    <div className="d-flex">
      <div className="row w-75 mr-4">
        <div className="col">
          <div className="row justify-space-between">
            <div className="col-8">
              <h5>Overview</h5>
              <div>
                <p>
                  Connect your GitHub open source product to MadKudu to see who
                  contributes, comments, pull requests, open issues, discussions
                  etc.
                </p>
                <p>
                  Your team will be able to leverage this activity in MadKudu
                  Copilot through playbooks and few in people’s activity feed.
                </p>
              </div>
            </div>

            {!enableSocialSignals && <PremiumIntegrationWarning />}

            <div className="mt-4 px-2 col-12">
              <Form
                className="tw-flex tw-flex-col tw-items-start tw-gap-4 w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                  const payload = {
                    repositories: repositories.map((f) => f.value),
                  };
                  mutate(payload);
                }}
                disabled={
                  isDisconnecting || isConnecting || !enableSocialSignals
                }
              >
                <span>
                  <b>Repositories</b>
                  <p className="tw-mb-0">
                    Fill in the format <code>owner/repository</code>
                  </p>
                </span>

                <div style={{ width: '100%' }}>
                  {repositories.map((field) => (
                    <div
                      key={field.id}
                      className="tw-flex tw-gap-2 mb-2 w-full"
                    >
                      <Input
                        className="w-full"
                        placeholder="owner/repository"
                        type="text"
                        disabled={
                          !hasAccessToConfiguration ||
                          isDisconnecting ||
                          isConnecting ||
                          !enableSocialSignals
                        }
                        defaultValue={field.value}
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setRepositories((prev) =>
                            prev.map((f) =>
                              f.id === field.id ? { ...f, value } : f
                            )
                          );
                        }}
                      />
                      <div
                        className="tw-flex tw-gap-4 tw-mr-2"
                        style={{ minWidth: '80px', maxWidth: '80px' }}
                      >
                        <Button
                          type="button"
                          color="red"
                          onClick={() =>
                            setRepositories((prev) => {
                              return prev.filter((f) => f.id !== field.id);
                            })
                          }
                          disabled={
                            !hasAccessToConfiguration ||
                            isDisconnecting ||
                            isConnecting ||
                            !enableSocialSignals
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}

                  <Button
                    type="button"
                    onClick={() =>
                      setRepositories((prev) => [
                        ...prev,
                        { value: '', id: generateId() },
                      ])
                    }
                    color="blue"
                    className={repositories?.length ? 'tw-mt-2' : ''}
                    disabled={
                      !hasAccessToConfiguration ||
                      isDisconnecting ||
                      isConnecting ||
                      !enableSocialSignals
                    }
                  >
                    Add repository
                  </Button>
                </div>

                <div className="tw-flex tw-gap-4">
                  <Button
                    type="submit"
                    className="tw-mt-5"
                    disabled={
                      isDisconnecting || isConnecting || !enableSocialSignals
                    }
                    color={isConnecting ? 'white' : 'blue'}
                  >
                    {isConnecting ? 'Saving...' : 'Save'}
                  </Button>

                  <Button
                    type="button"
                    className="tw-mt-5"
                    disabled={isDisconnecting || isConnecting}
                    color={isDisconnecting ? 'white' : 'red'}
                    onClick={() => {
                      reset();
                      disconnectGithub();
                    }}
                  >
                    {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                  </Button>
                </div>

                {isError && (
                  <p className="tw-text-red-500 tw-mt-2">
                    The credentials you entered are invalid. Please try again.
                  </p>
                )}

                {hasSavedCredentials && (
                  <p className="tw-text-green-500 tw-mt-2">
                    Configuration saved successfully!
                  </p>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="w-25">
        <p>
          <b>Status</b>
          <span className="d-block pt-1">
            {isLoading && <span className="text-secondary">loading...</span>}
            {!isLoading && (
              <span className="cursor-pointer d-block">
                {isConnected && (
                  <b className="tw-text-green-900 d-block p-2 rounded tw-bg-[#c5f3da]">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Connected
                  </b>
                )}
                {!isConnected && (
                  <b className="tw-text-gray-500 d-block p-2 rounded tw-bg-gray-100">
                    <FontAwesomeIcon
                      icon={faGear}
                      width="14"
                      height="14"
                      className="mr-1"
                    />
                    Not Connected
                  </b>
                )}
              </span>
            )}
          </span>
        </p>
        <p className="mt-4">
          <b>Links</b>
        </p>
        <p>
          <a
            href="https://help.madkudu.com/docs/how-to-connect-your-github-open-source-product-to-madkudu"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to connect your GitHub open source product to MadKudu
          </a>
        </p>
      </div>
    </div>
  );
}
