import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../../app';
import DiagnosticsParentComponent from './DiagnosticsParentComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_to_event_mapping': 'goEventMapping',
    'click #go_to_mapping': 'goMapping',
  },

  async initialize(options: { tenant: number; id: string; context: string }) {
    const flag = app.session.tenant.flags.get('demo');
    this.demo = flag.get('active');
    this.tenant = options.tenant;
    this.modelId = options.id;
    this.context = options.context;
    this.isSuperKudu = await app.session.get('user').superKudu;

    this.render();
    this.renderPerformanceParentComponent();
  },

  goTo(url: string) {
    Backbone.history.navigate(url, true);
  },

  renderPerformanceParentComponent() {
    ReactDOM.render(
      <DiagnosticsParentComponent
        isSuperkudu={this.isSuperKudu}
        tenant={this.tenant}
        modelId={this.modelId}
        context={this.context}
        demo={this.demo}
        goTo={this.goTo}
      />,
      $('#diagnosticsParentComponentContainer')[0]
    );
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
