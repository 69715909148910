import React from 'react';
import { Tab } from '../types';
import { Icon } from '../../../components/ui';
import { snakeCaseToHumanReadable } from '../../../utils';

export function NavItem({
  tabName,
  activeTab,
  changeActiveTab,
  disabled,
  superkuduTab = false,
}: {
  tabName: Tab;
  activeTab: Tab;
  changeActiveTab: (tab: Tab) => void;
  disabled?: boolean;
  superkuduTab?: boolean;
}) {
  const readableTabName = snakeCaseToHumanReadable(tabName.replace(/-/g, ' '));

  return (
    <li className="nav-item tw-inline-flex tw-items-center" key={tabName}>
      <a
        className={`nav-link ${activeTab === tabName ? 'active show' : ''} ${
          disabled ? 'tw-select-none tw-opacity-30 tw-cursor-not-allowed' : ''
        }`}
        id={tabName}
        data-toggle="tab"
        href={`#${tabName}`}
        onClick={() => changeActiveTab(tabName)}
        role="tab"
      >
        {superkuduTab && <Icon name="kudu" className="tw-mr-2" />}
        {readableTabName}
      </a>
    </li>
  );
}
