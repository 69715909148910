import * as React from 'react';

type Props = {
  className?: string;
} & React.HTMLProps<HTMLTextAreaElement>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  function TextArea(props: Props, ref) {
    const { className, ...rest } = props;
    return (
      <textarea
        className={`tw-block tw-h-10 tw-w-full tw-appearance-none tw-rounded-md tw-border tw-border-gray-200 tw-p-3 tw-text-sm tw-placeholder-gray-300 tw-shadow focus:tw-border-blue-600 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-600  ${
          className || ''
        }`}
        ref={ref}
        {...rest}
      />
    );
  }
);
