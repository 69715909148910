import Backbone from 'backbone';
import React from 'react';
import { Button } from 'react-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import ConversionMappingManager from '../../../models/ConversionMappingManager';
import SourceSystems from '../../../../enums/SourceSystems';
import { launchDeleteMappingPopup } from '../../../../swal';
import { goBackToConversionMappingOverview } from '../../utils';
import MappingsMode from '../../../../enums/MappingsMode';
import ConversionTypeCardsComponent from './ConversionTypeCardsComponent';
import { ConversionMappingData } from '../../../models/ConversionMappingData';

type ConversionTypeComponentProps = {
  conversionMappingManager: ConversionMappingManager;
};

type ConversionTypeComponentState = {
  conversionMappingData: ConversionMappingData;
  scriptCheckerState: boolean;
  isSaving: boolean;
};

export default class ConversionTypeComponent extends React.Component<
  ConversionTypeComponentProps,
  ConversionTypeComponentState
> {
  constructor(props: ConversionTypeComponentProps) {
    super(props);
    this.state = {
      conversionMappingData: this.props.conversionMappingManager.data,
      scriptCheckerState: false,
      isSaving: false,
    };

    // Binds
    this.goBack = this.goBack.bind(this);
    this.handleSetIsCustom = this.handleSetIsCustom.bind(this);
    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.handleOnClickPublish = this.handleOnClickPublish.bind(this);
    this.goBackToConversionMapping = this.goBackToConversionMapping.bind(this);
    // SALESFORCE && HUBSPOT
    this.handleConnectorLogicChange = this.handleConnectorLogicChange.bind(
      this
    );
    this.handleConnectorValueChange = this.handleConnectorValueChange.bind(
      this
    );
    this.handleConnectorVerbChange = this.handleConnectorVerbChange.bind(this);
    this.handleConnectorFieldChange = this.handleConnectorFieldChange.bind(
      this
    );
    this.handleConnectorTableChange = this.handleConnectorTableChange.bind(
      this
    );
    this.createNewConnectorParameter = this.createNewConnectorParameter.bind(
      this
    );
    this.createNewConnectorParameterValue = this.createNewConnectorParameterValue.bind(
      this
    );
    this.removeConnectorParameterValue = this.removeConnectorParameterValue.bind(
      this
    );
    this.handleConnectorRemoveParameter = this.handleConnectorRemoveParameter.bind(
      this
    );
    this.handleConnectorLowerChange = this.handleConnectorLowerChange.bind(
      this
    );
    // STRIPE
    this.handleSqoAmountThreshold = this.handleSqoAmountThreshold.bind(this);
    // ANALYTICS
    this.handleConversionEvent = this.handleConversionEvent.bind(this);
    this.handleSourceSystem = this.handleSourceSystem.bind(this);
    // GENERAL
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
    this.handleAmountFieldChangeValue = this.handleAmountFieldChangeValue.bind(
      this
    );
    this.handleAmountFieldChangeTable = this.handleAmountFieldChangeTable.bind(
      this
    );
    this.handleFilterTypesChanges = this.handleFilterTypesChanges.bind(this);
    this.handleChangeMadMlSqlQuery = this.handleChangeMadMlSqlQuery.bind(this);
    this.goBack = this.goBack.bind(this);
    this.launchGoBackSwal = this.launchGoBackSwal.bind(this);
    this.handleRemoveBlock = this.handleRemoveBlock.bind(this);
    this.handleCreateNewSQOConversionType = this.handleCreateNewSQOConversionType.bind(
      this
    );
    this.handleCustomSqoBlockNameChange = this.handleCustomSqoBlockNameChange.bind(
      this
    );
  }

  goBackToMapping() {
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingManager.tenant}/mapping`,
      true
    );
  }

  goBackToConversionMapping() {
    Backbone.history.navigate(
      `/org/${this.props.conversionMappingManager.tenant}/mapping/conversion_mapping/overview`,
      true
    );
  }

  goBack() {
    Backbone.history.navigate(
      `org/${this.props.conversionMappingManager.tenant}/mapping/conversion_mapping/standard_parameters`,
      true
    );
  }

  async launchGoBackSwal(isThereErrors: boolean) {
    if (isThereErrors) {
      this.goBack();
      return;
    }
    this.props.conversionMappingManager.step = 2;
    this.setState({
      isSaving: true,
      conversionMappingData: cloneDeep(this.state.conversionMappingData), // remove reference in case
    });
    await this.props.conversionMappingManager.launchGoBackSwal(this.goBack);
    this.setState({
      isSaving: false,
    });
  }

  // ********************* Form functions *****************
  getHandlerFunctions() {
    return {
      // SALESFORCE && HUBSPOT
      handleConnectorLogicChange: this.handleConnectorLogicChange,
      handleConnectorValueChange: this.handleConnectorValueChange,
      handleConnectorVerbChange: this.handleConnectorVerbChange,
      handleConnectorFieldChange: this.handleConnectorFieldChange,
      handleConnectorTableChange: this.handleConnectorTableChange,
      createNewConnectorParameter: this.createNewConnectorParameter,
      createNewConnectorParameterValue: this.createNewConnectorParameterValue,
      removeConnectorParameterValue: this.removeConnectorParameterValue,
      handleConnectorRemoveParameter: this.handleConnectorRemoveParameter,
      handleConnectorLowerChange: this.handleConnectorLowerChange,
      // STRIPE
      handleSqoAmountThreshold: this.handleSqoAmountThreshold,
      // ANALYTICS
      handleConversionEvent: this.handleConversionEvent,
      handleSourceSystem: this.handleSourceSystem,
      // GENERIC
      handleAmountFieldChangeValue: this.handleAmountFieldChangeValue,
      handleAmountFieldChangeTable: this.handleAmountFieldChangeTable,
      handleFilterTypesChanges: this.handleFilterTypesChanges,
      handleCustomSqoBlockNameChange: this.handleCustomSqoBlockNameChange,
      handleChangeMadMlSqlQuery: this.handleChangeMadMlSqlQuery,
      handleRemoveBlock: this.handleRemoveBlock,
      handleSetIsCustom: this.handleSetIsCustom,
    };
  }

  redirectToOverview() {
    Backbone.history.navigate(
      `org/${this.props.conversionMappingManager.tenant}/mapping/conversion_mapping/overview`,
      true
    );
  }

  async handleOnClickPublish() {
    await this.props.conversionMappingManager.launchPublishSwal(
      'This updates the Conversion Mapping!',
      () => this.redirectToOverview()
    );
  }

  // ********************** SALESFORCE && HUBSPOT ***************************
  async handleConnectorLowerChange(
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.setLower(
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  createNewConnectorParameter(conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.addParameter(
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  createNewConnectorParameterValue(
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.addValue(
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  removeConnectorParameterValue(
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.removeValue(
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleConnectorLogicChange(value: string, conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeLogic(
      value,
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleConnectorValueChange(
    value: string,
    conditionIndex: number,
    conversionTypeDataIndex: number,
    valueIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeValue(
      value,
      conversionTypeDataIndex,
      conditionIndex,
      valueIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleConnectorTableChange(
    value: string,
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeTableValue(
      value,
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleConnectorFieldChange(
    value: string,
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeFieldValue(
      value,
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleConnectorVerbChange(
    value: string,
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeVerbValue(
      value,
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleConnectorRemoveParameter(
    conditionIndex: number,
    conversionTypeDataIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.removeParameter(
      conversionTypeDataIndex,
      conditionIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  // ***************************** STRIPE ********************************

  handleSqoAmountThreshold(value: string, conversionTypeDataIndex: number) {
    if (Number(value)) {
      const newConversionMappingData = this.props.conversionMappingManager.data.changeAmountThreshold(
        Number(value),
        conversionTypeDataIndex
      );

      this.setState({
        conversionMappingData: cloneDeep(newConversionMappingData),
      });
    }
  }

  // *************************** ANALYTICS **********************************

  handleSourceSystem(value: string, conversionTypeDataIndex: number) {
    if (value as SourceSystems) {
      const newConversionMappingData = this.props.conversionMappingManager.data.changeSourceSystem(
        value as SourceSystems,
        conversionTypeDataIndex
      );

      this.setState({
        conversionMappingData: cloneDeep(newConversionMappingData),
      });
    }
  }

  handleConversionEvent(value: string, conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeConversionEvents(
      value,
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  // // ************************ GENERIC **********************************

  handleAmountFieldChangeValue(value: string, conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeAmountFieldValue(
      value,
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleAmountFieldChangeTable(value: string, conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeAmountFieldTable(
      value,
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleFilterTypesChanges(value: string, conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeFilterTypesValues(
      value,
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  async handleOnClickRemove() {
    const { tenant, email } = this.props.conversionMappingManager;

    await launchDeleteMappingPopup(tenant, email, MappingsMode.conversion, () =>
      goBackToConversionMappingOverview(tenant)
    );
  }

  async handleCreateNewSQOConversionType(isCustom: boolean) {
    const newConversionMappingData = await this.props.conversionMappingManager.data.addNewSQOConversionType(
      isCustom
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleChangeMadMlSqlQuery(value: string, conversionTypeDataIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.changeMadMlSqlQuery(
      value,
      conversionTypeDataIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleCustomSqoBlockNameChange(
    newName: string,
    conversionTypeGroupIndex: number
  ) {
    const newConversionMappingData = this.props.conversionMappingManager.data.setGroupName(
      newName,
      conversionTypeGroupIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleRemoveBlock(conversionTypeGroupIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.removeConversionTypeGroup(
      conversionTypeGroupIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  handleSetIsCustom(conversionTypeGroupIndex: number) {
    const newConversionMappingData = this.props.conversionMappingManager.data.setIsCustomConversionGroup(
      conversionTypeGroupIndex
    );

    this.setState({
      conversionMappingData: cloneDeep(newConversionMappingData),
    });
  }

  // *************************************************************

  render() {
    const {
      tenant,
      isFinished,
      isReadOnly,
      isSuperKudu,
    } = this.props.conversionMappingManager;

    const {
      activeConnectors,
      activeIntegrations,
    } = this.state.conversionMappingData;

    const isAbleToAdd = this.state.conversionMappingData.isLimitOfSqoGroupsReached();
    const handlerFunctions = this.getHandlerFunctions();
    const errors = this.state.conversionMappingData.checkForErrors();
    const groupNamesErrors: string[] = [];
    errors.forEach(({ groupName }) => {
      if (!groupNamesErrors.includes(groupName) && groupName !== '') {
        groupNamesErrors.push(groupName);
      }
    });
    const isThereErrors = errors.length > 0;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToConversionMapping}
              >
                Conversion Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Conversion Definitions
            </li>
          </ol>
          <div className="float-right my-2 my-lg-0">
            <div>
              {isFinished && isSuperKudu && (
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-danger"
                    onClick={this.handleOnClickRemove}
                  >
                    Remove and create a new configuration
                  </button>
                </div>
              )}
            </div>
          </div>
        </nav>
        <div className="shadow-sm p-3 mb-5 bg-white rounded text-center border-top">
          <strong>Step 3/3: </strong>Configure your conversion types
        </div>
        <div className="container pb-4">
          <div className="row mt-3">
            <div className="col-sm-12">
              <ConversionTypeCardsComponent
                activeConnectors={activeConnectors}
                tenant={tenant}
                errors={errors}
                editable={!isReadOnly}
                conversionMappingData={cloneDeep(
                  this.state.conversionMappingData
                )}
                conversionTypesGroups={
                  this.state.conversionMappingData.conversionTypesGroups
                }
                handlerFunctions={handlerFunctions}
                activeIntegrations={activeIntegrations}
              />
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-outline-primary mt-3 mr-2"
                  disabled={isAbleToAdd || this.state.isSaving}
                  onClick={() => this.handleCreateNewSQOConversionType(false)}
                >
                  Create new SQO
                </button>
                <Button
                  className="mt-3"
                  bsStyle="warning"
                  disabled={isAbleToAdd || this.state.isSaving}
                  onClick={() => this.handleCreateNewSQOConversionType(true)}
                >
                  Create new Custom SQO
                </Button>
              </div>
              {isThereErrors && (
                <div className="shadow-sm p-3 mb-5 bg-primary-fade rounded mt-4">
                  <span className="font-weight-bold row">
                    Unable to publish, please fix the following errors:
                  </span>
                  {groupNamesErrors.map((groupNameError, index) => {
                    return (
                      <span
                        key={`error_${index}`}
                        className="font-weight-light mt-2 ml-2 row"
                      >
                        - {`${groupNameError}: Missing/invalid values.`}
                      </span>
                    );
                  })}
                </div>
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-outline-primary mt-3 mr-2"
                  onClick={() => this.launchGoBackSwal(isThereErrors)}
                  disabled={this.state.isSaving}
                >
                  Go Back
                </button>
                <Button
                  className="mt-3"
                  bsStyle="primary"
                  disabled={this.state.isSaving || isThereErrors}
                  onClick={this.handleOnClickPublish}
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
