import React, { useState } from 'react';
import { Steps } from '../../components/Steps';
import { CredentialsStep } from './steps/CredentialsStep';
import { TableMappingStep } from './steps/TableMappingStep';
import { ColumnMappingStep } from './steps/ColumnMappingStep';

export function SnowflakeForm() {
  const [step, setStep] = useState(0);

  return (
    <>
      <Steps
        steps={[
          { title: 'Credentials' },
          { title: 'Table Mapping' },
          { title: 'Column Mapping' },
        ]}
        currentStep={step}
        onStepChange={(nextStep) => {
          setStep(nextStep);
        }}
      />
      {step === 0 && (
        <CredentialsStep
          nextStep={() => {
            setStep(1);
          }}
        />
      )}
      {step === 1 && (
        <TableMappingStep
          previousStep={() => {
            setStep(0);
          }}
          nextStep={() => {
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <ColumnMappingStep
          previousStep={() => {
            setStep(1);
          }}
          nextStep={() => {
            setStep(0);
          }}
        />
      )}
    </>
  );
}
