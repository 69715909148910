import React from 'react';

type MissingFieldMicrocopyProps = {
  microcopy: string;
};

const MissingFieldMicrocopy = ({ microcopy }: MissingFieldMicrocopyProps) => (
  <p className="text-danger ml-1" style={{ fontSize: '12px' }}>
    {microcopy}
  </p>
);

export default MissingFieldMicrocopy;
