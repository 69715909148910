import React, { ReactElement, useState } from 'react';

import ConnectorSelect, { Connector } from './ConnectorSelect';
import DataTypeSelect, { DataType } from './DataTypeSelect';

export type Values = {
  connector: Connector;
  dataType: DataType;
  records: string;
};

const defaultValues: Values = {
  connector: 'salesforce',
  dataType: 'export_contacts',
  records: '',
};

type ForcePushSalesforceFormProps = {
  onSubmit: (values: Values) => Promise<void>;
  sending: boolean;
};

function ForcePushRecordsForm({
  onSubmit,
  sending,
}: ForcePushSalesforceFormProps): ReactElement {
  const [values, setValues] = useState<Values>(defaultValues);

  const handleChange = (event: any) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    await onSubmit(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <legend>
        Trigger manually the push on specific records from a connector
      </legend>
      <div className="form-group row">
        <label htmlFor="connector" className="col-sm-2 col-form-label">
          Connector*
        </label>
        <div className="col-sm-10">
          <ConnectorSelect value={values.connector} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="dataType" className="col-sm-2 col-form-label">
          Data type*
        </label>
        <div className="col-sm-10">
          <DataTypeSelect value={values.dataType} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="gitHubPath" className="col-sm-2 col-form-label">
          Records*
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="records"
            placeholder="record1,record2"
            required
            value={values.records}
            onChange={handleChange}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary" disabled={sending}>
        {sending ? 'Pushing...' : 'Start push'}
      </button>
    </form>
  );
}

export default ForcePushRecordsForm;
