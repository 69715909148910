import React from 'react';
import Backbone from 'backbone';
import { Button } from 'react-bootstrap';
import MadMlEditModeComponent from '../../../madMlMapping/reactComponents/MadMlEditModeComponent';
import MappingsMode from '../../../enums/MappingsMode';
import { getAttributes } from '../../utils';
import MadMlDisplayModeComponent from '../../../madMlMapping/reactComponents/MadMlDisplayModeComponent';
import { launchDeleteMappingPopup } from '../../../swal';
import { getScriptCheckerStateBasedOnMappingMode } from '../../../utils';

type CustomConfigurationParentComponentProps = {
  tenant: number;
  email: string;
  isReadOnly: boolean;
  isSuperKudu: boolean;
};

type CustomConfigurationParentComponentState = {
  isDisplayMode: boolean;
  isLoading: boolean;
  hasAlreadyData: boolean;
  scriptCheckerState: boolean;
};

export default class CustomConfigurationParentComponent extends React.Component<
  CustomConfigurationParentComponentProps,
  CustomConfigurationParentComponentState
> {
  constructor(props: CustomConfigurationParentComponentProps) {
    super(props);

    this.state = {
      isDisplayMode: false,
      isLoading: true,
      hasAlreadyData: false,
      scriptCheckerState: false,
    };

    this.goBackToMapping = this.goBackToMapping.bind(this);
    this.goBackToOverview = this.goBackToOverview.bind(this);
    this.goToDisplayMode = this.goToDisplayMode.bind(this);
    this.handleOnClickEditButton = this.handleOnClickEditButton.bind(this);
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
  }

  async componentDidMount() {
    const { tenant } = this.props;

    await this.reloadScriptCheckerState(tenant);
    const data = await getAttributes(tenant);

    this.setState({
      isLoading: false,
    });

    const madMlSqlQuery = data?.madMlSqlQuery || '';

    if (madMlSqlQuery) {
      this.setState({
        isDisplayMode: true,
        hasAlreadyData: true,
      });
    }
  }

  async reloadScriptCheckerState(tenant: number) {
    const { data } = await getScriptCheckerStateBasedOnMappingMode(
      tenant,
      MappingsMode.attribute
    );

    this.setState({
      scriptCheckerState: !!data,
    });
  }

  async handleOnClickRemove() {
    const { tenant, email } = this.props;

    await launchDeleteMappingPopup(tenant, email, MappingsMode.attribute, () =>
      this.goBackToOverview()
    );
  }

  goBackToMapping() {
    const { tenant } = this.props;

    Backbone.history.navigate(`org/${tenant}/mapping`, true);
  }

  goBackToOverview() {
    const { tenant } = this.props;

    Backbone.history.navigate(
      `org/${tenant}/mapping/attribute_mapping/overview`,
      true
    );
  }

  goToDisplayMode() {
    this.setState({
      isDisplayMode: true,
    });
  }

  handleOnClickEditButton() {
    this.setState({
      isDisplayMode: false,
    });
  }

  renderScriptCheckerAlert() {
    return (
      <div className="row ml-5 mr-5 mt-2">
        <div className="col mb-2">
          <div className="alert alert-warning box-shadow-3d d-flex alert-No-Data">
            <div className="spinner-border float-left mr-2"></div>
            <p>
              Your configuration has been submitted and is currently being
              processed. Please wait for an email to confirm success or failure
              of publish.
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { tenant, email, isReadOnly, isSuperKudu } = this.props;
    const {
      isDisplayMode,
      isLoading,
      hasAlreadyData,
      scriptCheckerState,
    } = this.state;

    if (isLoading) {
      return this.renderLoader();
    }
    if (isDisplayMode) {
      return (
        <React.Fragment>
          <MadMlDisplayModeComponent
            tenant={tenant}
            email={email}
            mappingMode={MappingsMode.attribute}
          />
          {isSuperKudu && (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-danger"
                onClick={this.handleOnClickRemove}
                disabled={scriptCheckerState}
              >
                Remove and create a new configuration
              </button>
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <MadMlEditModeComponent
        tenant={tenant}
        email={email}
        isReadOnly={isReadOnly}
        mappingMode={MappingsMode.attribute}
        goToDisplayMode={this.goToDisplayMode}
        goBackToOverview={
          hasAlreadyData ? this.goToDisplayMode : this.goBackToOverview
        }
        scriptCheckerState={scriptCheckerState}
        reloadScriptCheckerState={() => this.reloadScriptCheckerState(tenant)}
      />
    );
  }

  renderLoader() {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data">
        <div className="card-body align-middle">
          <div className="spinner-border float-left mr-2"></div>
          <h5>Loading...</h5>
        </div>
      </div>
    );
  }

  render() {
    const { isReadOnly } = this.props;
    const { scriptCheckerState } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ol
            className="navbar-nav navbar-brand mr-auto mt-2 mt-lg-0"
            id="breadcrumb"
          >
            <li className="breadcrumb-item">
              <a
                className="breadcrumb-link text-primary"
                onClick={this.goBackToMapping}
              >
                Mapping
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Attribute Mapping
            </li>
          </ol>
          {!isReadOnly && (
            <div className="float-right my-2 my-lg-0">
              <Button
                bsStyle="primary"
                className="mr-2"
                onClick={this.handleOnClickEditButton}
              >
                Edit
              </Button>
            </div>
          )}
        </nav>
        <nav className="navbar bavbar-expand-lg navbar-light bg-light border-top sticky-top box-shadow-3d">
          <p className="m-auto">
            <strong>Overview: </strong>Select the fields from your CRM to be
            used in your segmentations.
          </p>
        </nav>
        {scriptCheckerState && this.renderScriptCheckerAlert()}
        <div className="container mt-4 p-3">{this.renderContent()}</div>
      </div>
    );
  }
}
