import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { JobChangesWatchList } from '../types';

export const jobChangesWatchListQueryKey = (tenant: number) => [
  tenant,
  'integrations',
  'job_changes',
  'watchList',
];

export function useGetJobChangesWatchListQuery({ tenant }: { tenant: number }) {
  return useQuery({
    queryKey: jobChangesWatchListQueryKey(tenant),
    queryFn: async () => {
      const { data } = await axios.get<JobChangesWatchList>(
        `${BONGO_URL}/v1/org/${tenant}/integrations/job_changes/watch-list`
      );

      return data;
    },
  });
}
