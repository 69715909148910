import axios from 'axios';
import Backbone from 'backbone';
import { BackboneViewProps } from './types';

require('backbone.epoxy');

export const Model = (Backbone as any).Epoxy.Model.extend({
  idAttribute: '_id',

  initialize(props: BackboneViewProps) {
    this.tenant =
      props.tenant || (this.collection ? this.collection.tenant : undefined);
  },

  urlRoot() {
    return `${BONGO_URL}/v1/org/${this.tenant}/users`;
  },

  parse(res: any) {
    return res;
  },

  defaults: {
    _id: undefined,
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    superKudu: false,
    user_hash: '',
    role: 'user',
  },

  computeds: {
    full_name() {
      return `${this.get('first_name')} ${this.get('last_name')}`;
    },
  },

  update_password(data: {}) {
    return axios.put(
      `${BONGO_URL}/v1/org/${this.tenant}/users/${this.get('_id')}/password`,
      data
    );
  },
});

export const Collection = Backbone.Collection.extend({
  model: Model,
  url() {
    return `${BONGO_URL}/v1/org/${this.tenant}/users`;
  },
  initialize(props: BackboneViewProps) {
    this.tenant = props.tenant;
  },
});
