import React from 'react';
import {
  PageDefinition,
  PageChart,
  PageDescription,
} from '../../InsightsTypes';
import ChartComponent from './ChartComponent';
import PageDescriptionComponent from './PageDescriptionComponent';

type TabsComponentProps = {
  pageId: string;
  tenant: number;
  superKudu: boolean;
  page: PageDefinition;
  toggleView: Function;
  getActiveView: Function;
};

type TabsComponentState = {
  activeTab: string;
};

export default class TabsComponent extends React.Component<
  TabsComponentProps,
  TabsComponentState
> {
  constructor(props: TabsComponentProps) {
    super(props);

    const currentView = props.getActiveView();
    this.state = {
      activeTab: currentView,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  componentDidMount() {
    const { toggleView } = this.props;
    const { activeTab } = this.state;

    toggleView(activeTab);
  }

  handleTabClick = (event: any) => {
    this.setState({ activeTab: event.target.id });
    const { toggleView } = this.props;
    return toggleView(event.target.id);
  };

  render() {
    const { tenant, superKudu, page } = this.props;

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
          <ul className="nav nav-pills mx-auto">
            {page.tabs.map((view, index) => (
              <li className="nav-item" key={index}>
                <a
                  className={`nav-link ${
                    this.state.activeTab === String(index) ? 'active show' : ''
                  }`}
                  id={`${index}`}
                  data-toggle="tab"
                  href={`#${index}`}
                  onClick={this.handleTabClick}
                  role="tab"
                >
                  {view.tab}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="row ml-4">
          <div className="tab-content w-100" id="TabContent">
            {page.tabs.map((view, index) => (
              <div
                key={`tab_content_${index}`}
                className={`tab-pane fade ${
                  this.state.activeTab === String(index) ? 'active show' : ''
                }`}
                id={`${index}-tab`}
                role="tabpanel"
              >
                <div className="row w-100">
                  <div className="col-8 offset-2">
                    {view.content.map(
                      (block, blockIndex) =>
                        block.type === 'chart' && (
                          <React.Fragment key={`chart-${blockIndex}`}>
                            <ChartComponent
                              tenant={tenant}
                              chart={block.value as PageChart}
                              superKudu={superKudu}
                            />
                          </React.Fragment>
                        )
                    )}
                  </div>
                  <div className="col-2 pr-0">
                    {view.content.map(
                      (block, blockIndex) =>
                        block.type === 'description' && (
                          <PageDescriptionComponent
                            key={blockIndex}
                            description={block.value as PageDescription}
                          />
                        )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
