import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import { UserServiceModel } from '../../settings/users/types';
import { Tab, SlackIntegration } from './SlackIntegration';
import { LaunchDarklyClient, getContext } from '../utils/launchdarkly';
import { LDProvider } from 'launchdarkly-react-client-sdk';

const template = require('./index.pug');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

export default Backbone.View.extend({
  async initialize(options: { tenant: number; queryString: string }) {
    this.tenant = options.tenant;
    this.integration = 'slack';
    this.session = app.session;
    this.renderedOnce = false;
    const user: UserServiceModel = this.session.get('user');

    this.hasAccessToConfigurations =
      user.permissions.some(
        (permission) =>
          permission.tenant === this.tenant && permission.role === 'admin'
      ) || user.superKudu;

    await this.renderSlackIntegrationComponent(this.tenant, user);

    this.onlyAdminTabsGuard();
  },

  onlyAdminTabsGuard() {
    if (!this.hasAccessToConfigurations) {
      this.changeTab('overview');
    }
  },

  getActiveTab(): Tab {
    const splitUrl = window.location.href.split('/');

    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === 'slack' || !this.hasAccessToConfigurations)
      return 'overview';

    return lastItem as Tab;
  },

  changeTab(tab: Tab) {
    if (tab === 'overview') {
      Backbone.history.navigate(
        `/org/${this.tenant}/integrations/${this.integration}`
      );
    } else {
      Backbone.history.navigate(
        `/org/${this.tenant}/integrations/${this.integration}/${tab}`
      );
    }
  },

  async renderSlackIntegrationComponent() {
    // BUG: For some reason, Backbone will initialize the view once
    // but will render the component twice
    if (this.renderedOnce) return;

    // prepare dom for react component
    await this.$el.empty();
    this.$el.html(template());

    const ldInstance = await LaunchDarklyClient.getInstance();
    const context = getContext(Number(this.tenant));

    this.renderedOnce = true;

    // render react component
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <LDProvider
          clientSideID={ldInstance.clientSideID}
          context={context}
          timeout={5000}
        >
          <SlackIntegration tenant={this.tenant} />
        </LDProvider>
      </QueryClientProvider>,
      $('#SlackIntegrationContainer')[0]
    );
  },
});
