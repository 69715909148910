import axios from 'axios';
import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../app';
import { BackboneViewProps, ModelTypes, ModelTypesPoints } from '../types';
import {
  capitalizeEachWord,
  getColorForSegment,
  getModelLabels,
} from './utils';
import ModelDataModel from '../models/model/ModelDataModel';
import { IS_HYBRID, IS_LLTB, MODEL_TYPES_NAMES } from '../utils/constants';

const template = require('./predictions.pug');

function getScoresIncluded(modelType: ModelTypes) {
  return getModelLabels(modelType).map((label) => ({
    label: capitalizeEachWord(label),
    color: getColorForSegment(label),
  }));
}

type ModelBoxProps = {
  id: number;
  name: string;
  type: string;
  isPrimary: boolean;
  editable: boolean;
  tenant: number;
};

export class ModelBox extends React.Component<ModelBoxProps, any> {
  render() {
    const { type, name, isPrimary } = this.props;

    if (document.getElementById('MQA')) {
      document.getElementById('MQA').removeAttribute('hidden');
    }
    if (document.getElementById('likelihood')) {
      document.getElementById('likelihood').removeAttribute('hidden');
    }
    if (document.getElementById('alert')) {
      document.getElementById('alert').removeAttribute('hidden');
    }

    const getModelData = () => {
      switch (type) {
        case 'pql':
        case 'pql2':
          return [
            {
              displayName: MODEL_TYPES_NAMES[type],
              displayType: 'behavioral',
              description:
                "By using your in-app data, we'll tell you exactly when your leads are engaged with your product so that you can talk to them when it's the most relevant time.",
              scoresIncluded: getScoresIncluded('pql'),
              datasources: [
                'Website Activity',
                'Product Usage',
                'Email Activity',
                'Marketing Campaigns',
              ],
            },
            {
              displayName: `Lead Grade${
                IS_HYBRID(type) ? ` - ${MODEL_TYPES_NAMES[type]}` : ''
              }`,
              displayType: 'lead_grade',
              description:
                'Combining the Customer Fit score and Likelihood to Buy score, the Lead Grade surfaces your best and most engaged leads.',
              scoresIncluded: getScoresIncluded('lead_grade'),
              datasources: [
                'Website Activity',
                'Product Usage',
                'Email Activity',
                'Marketing Campaigns',
              ],
            },
          ];
        case 'mql':
          return [
            {
              displayName: MODEL_TYPES_NAMES[type],
              displayType: 'behavioral',
              description:
                "By using your marketing data, we'll tell you exactly when your leads are showing interest in your brand so that you can talk to them when it's the most relevant time.",
              scoresIncluded: getScoresIncluded('pql'),
              datasources: [
                'Website Activity',
                'Email Activity',
                'Marketing Campaigns',
              ],
            },
            {
              displayName: `Lead Grade${
                IS_HYBRID(type) ? ` - ${MODEL_TYPES_NAMES[type]}` : ''
              }`,
              displayType: 'lead_grade',
              description:
                'Combining the Customer Fit score and Likelihood to Buy score, the Lead Grade surfaces your best and most engaged leads.',
              scoresIncluded: getScoresIncluded('lead_grade'),
              datasources: [
                'Website Activity',
                'Email Activity',
                'Marketing Campaigns',
              ],
            },
          ];
        case 'mqa':
          return [
            {
              displayName: MODEL_TYPES_NAMES[type],
              displayType: 'behavioral',
              description:
                'By using your in-app data, we’ll tell you exactly when your accounts are engaged with your product so that you can talk to them when it’s the most relevant time.',
              scoresIncluded: getScoresIncluded('mqa'),
              datasources: [
                'Website Activity',
                'Product Usage',
                'Email Activity',
                'Marketing Campaigns',
              ],
            },
          ];
        default:
          return [
            {
              displayName: `Customer Fit${!isPrimary ? ` - ${name}` : ''}`,
              displayType: 'customer_fit',
              description:
                'The customer fit model automatically researches your leads and identifies those who are a good fit to buy from you.',
              scoresIncluded: getScoresIncluded('customer_fit'),
              datasources: [
                'Firmographic data: Company Size, Industry, Geo Location, etc.',
                'Demographic data: Job Title, Seniority, etc.',
                'Technographic data: Tech Stack',
              ],
            },
          ];
      }
    };

    const modelCards = getModelData().map((modelData) => {
      const {
        displayName,
        displayType,
        description,
        scoresIncluded,
        datasources,
      } = modelData;
      return (
        <div key={`model_box_${displayName}`} id={String(this.props.id)}>
          <div className="box-shadow-3d card mt-md-2 d-flex">
            <div className="h5 card-header">
              {' '}
              {displayName}
              <div className="float-right badge badge-success">LIVE</div>
            </div>
            <div className="card-body">
              <p>{description}</p>
              <span>
                <b>Scores Included:</b>
              </span>
              {scoresIncluded.map((score) => {
                return (
                  <span
                    key={`${displayName}_${score.label}`}
                    className={`badge badge-${score.color} p-2 ml-1 mb-2 text-white`}
                  >
                    {score.label}
                  </span>
                );
              })}
              <p>
                <b>Data Sources Included:</b>
              </p>
              {datasources.map((datasource) => (
                <p key={`${displayName}_${datasource}`}>{datasource}</p>
              ))}
              <div className="row">
                <div className="col-auto">
                  <a
                    className="btn btn-primary my-2.my-lg-0 mr-1 text-white"
                    href={`https://studio.madkudu.com/tenant/${this.props.tenant}/models/${this.props.id}/overview`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Model &rarr;
                  </a>
                </div>
                <div
                  className={`col-auto integration-item--plays ${displayType}`}
                  id={String(this.props.id)}
                >
                  <a className="button btn btn-primary my-2.my-lg-0 mr-1 text-white">
                    View Performance &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return <div>{modelCards}</div>;
  }
}

type ListModelContainerProps = {
  id: string;
  list: ModelDataModel[];
  editable: boolean;
  tenant: number;
};

export class ListModelContainer extends React.Component<
  ListModelContainerProps,
  {}
> {
  sortedLiveListOfModels: ModelDataModel[];

  add() {
    Backbone.history.navigate(
      `/org/${this.props.tenant}/predictions/performances/models/new/admin`,
      true
    );
  }

  render() {
    // Sorted based on model type
    const sortedLiveListOfModels = this.props.list
      .sort((a: ModelDataModel, b: ModelDataModel) => {
        return ModelTypesPoints[b.type] - ModelTypesPoints[a.type];
      })
      .filter(({ live }) => live);

    if (!sortedLiveListOfModels.length)
      return (
        <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data mt-4">
          <div className="card-body align-middle">
            <div className="spinner-border float-left mr-2"></div>
            <h5>
              <b>
                Looks like no model has been deployed yet. Head to the Data
                Studio to create or deploy one.
              </b>
            </h5>
          </div>
        </div>
      );

    return (
      <>
        {sortedLiveListOfModels.map(({ modelId, name, type, is_primary }) => (
          <ModelBox
            key={modelId}
            id={modelId}
            name={name}
            type={type}
            isPrimary={is_primary}
            tenant={this.props.tenant}
            editable={this.props.editable}
          />
        ))}
      </>
    );
  }
}

export default Backbone.View.extend({
  events: {
    'click .integration-item--plays.behavioral': 'open_diagnostics',
    'click .integration-item--plays.lead_grade': 'open_diagnostics_lead_grade',
    'click .integration-item--plays.customer_fit': 'open_performances',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user = this.session.get('user');
    this.modifiable = user.superKudu;
    await this.get_models();
    this.render();
    this.render_list_models();
  },
  open_performances(e: any) {
    const idn = e.currentTarget.id;
    Backbone.history.navigate(
      `/org/${this.tenant}/predictions/performances/models/${idn}`,
      true
    );
  },
  open_diagnostics(e: any) {
    const idn = e.currentTarget.id;
    Backbone.history.navigate(
      `/org/${this.tenant}/predictions/performances/models/${idn}/diagnostics`,
      true
    );
  },
  open_diagnostics_lead_grade(e: any) {
    const idn = e.currentTarget.id;
    Backbone.history.navigate(
      `/org/${this.tenant}/predictions/performances/models/${idn}/diagnostics/lead_grade`,
      true
    );
  },
  is_likelihood_active() {
    this.models.forEach((model: any) => {
      if (model.live && IS_LLTB(model.type)) {
        this.isLikelihood = true;
      }
    });
  },
  is_MQA_active() {
    this.models.forEach((model: any) => {
      if (model.live && model.type === 'mqa') {
        this.isMQA = true;
      }
    });
  },
  render() {
    $('[data-toggle="tooltip"]').tooltip();
    const html = template({
      isMQA: this.isMQA,
      isLikelihood: this.isLikelihood,
      tenant: this.tenant,
    });
    this.$el.html(html);
    return this;
  },
  async get_models() {
    const url = `${BONGO_URL}/v1/org/${this.tenant}/models`;
    const res = await (axios as any).get(url, {
      crossDomain: true,
      params: { tenant: this.tenant },
    });
    this.models = res.data;
    this.is_likelihood_active();
    this.is_MQA_active();
  },
  async render_list_models() {
    this.models = this.models.sort((prev: any, next: any) => {
      return String(prev.type).charAt(0) > String(next.type).charAt(0) ? 1 : -1;
    });
    ReactDOM.render(
      <ListModelContainer
        id="listModels"
        list={this.models}
        tenant={this.tenant}
        editable={this.modifiable}
      />,
      $('.list__models', this.$el)[0]
    );
  },
});
