import React from 'react';
import { Button, Col } from 'react-bootstrap';

const FILE_NAME = 'bulk_result.csv';

type BulkScorerResultComponentProps = {
  tenant: number;
  superKudu: boolean;
  requestid: string;
};

type BulkScorerResultComponentState = {
  version: 1 | 2;
};

export default class BulkScorerResultComponent extends React.Component<
  BulkScorerResultComponentProps,
  BulkScorerResultComponentState
> {
  constructor(props: BulkScorerResultComponentProps) {
    super(props);

    this.downloadFile = this.downloadFile.bind(this);
  }

  componentDidMount(): void {
    const searchParams = new URLSearchParams(window.location.search);

    let version = searchParams.get('version');
    if (!['1', '2'].includes(version)) version = '1';

    this.setState({ version: Number(version) as 1 | 2 });
  }

  async downloadFile() {
    const { requestid, tenant } = this.props;

    const url = `${BONGO_URL}/v1/org/${tenant}/bulkscorer${
      this.state.version === 2 ? '2' : ''
    }/result/${requestid}/${FILE_NAME}`;

    window.location.href = url;
  }

  render() {
    return (
      <Col sm={12}>
        <div className="card">
          <div className="card-header">
            <h4>Congratulation! Click on the button to download your file!</h4>
          </div>
          <div className="card-body">
            <Button bsStyle="teal text-white" onClick={this.downloadFile}>
              <span>Download File</span>
            </Button>
          </div>
        </div>
      </Col>
    );
  }
}
