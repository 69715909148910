import axios from 'axios';
import React from 'react';
import {
  Connectors,
  DatePicked,
  Models,
  PickedObject,
  RoundLtbYaml,
  RoundLgYaml,
  PushConfigurationItem,
} from '../../types';
import { sortFields } from '../../utils';
import AdvancedConfiguration from '../AdvancedConfiguration';
import CardField from './CardField';
import ModelDataModel from '../../../../models/model/ModelDataModel';
import { Loader } from '../../../../components/Loader';

interface DataMappingProps {
  modelsList: ModelDataModel[];
  tenant: number;
  connectors: Connectors;
  pickedModel: Models;
  handleDataMappingChange: Function;
  handleChangeObject: Function;
  pickedObject: PickedObject;
  pushConfigurationItem: PushConfigurationItem;
  generateModelBadges: (modelName: string, tag?: string) => JSX.Element;
  handleDatePick: (e: any, connector: string) => void;
  datePicked: DatePicked;
  handleTimeLimitCheck: (e: any, connector: string) => void;
  handleSegmentTimeFrameChange: (e: any, connector: string) => void;
  handleScoresChangeLtb: (name: string, value: number) => void;
  handleScoresChangeLg: (name: string, value: number) => void;
  roundLtbYaml: RoundLtbYaml;
  roundLgYaml: RoundLgYaml;
  handleYamlPickedModel: Function;
  yamlPickedModel: any;
  changePageState: Function;
  onConfirmDataMapping: Function;
}

interface DataMappingState {
  loading: boolean;
  salesforceContactData: string[];
  salesforceLeadData: string[];
  hubspotContactData: string[];
  segmentContactData: string[];
}

export default class DataModel extends React.Component<
  DataMappingProps,
  DataMappingState
> {
  constructor(props: DataMappingProps) {
    super(props);

    this.state = {
      loading: true,
      salesforceContactData: [],
      salesforceLeadData: [],
      hubspotContactData: [],
      segmentContactData: [],
    };

    this.fieldsToMap = this.fieldsToMap.bind(this);
  }

  async componentDidMount() {
    const { tenant, connectors } = this.props;
    const promises = [];

    if (connectors.salesforce) {
      promises.push(
        this.fetchContactSalesforceFields(tenant),
        this.fetchLeadSalesforceFields(tenant)
      );
    } else {
      promises.push(Promise.resolve([]), Promise.resolve([]));
    }

    if (connectors.hubspot) {
      promises.push(this.fetchContactHubspotFields(tenant));
    } else {
      promises.push(Promise.resolve([]));
    }

    if (connectors.segment) {
      promises.push(this.fetchAndStandardizeContactSegmentFields(tenant));
    } else {
      promises.push(Promise.resolve([]));
    }

    const [
      salesforceContactData,
      salesforceLeadData,
      hubspotContactData,
      segmentContactData,
    ] = await Promise.all(promises);

    this.setState({
      salesforceContactData,
      salesforceLeadData,
      hubspotContactData,
      segmentContactData,
      loading: false,
    });
  }

  async fetchContactSalesforceFields(tenant: number): Promise<string[]> {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/integrations/salesforce/data/Contact/fields`
    );

    const salesforceContactData = data.fields.map(
      ({ name }: { name: string }) => name
    );

    return salesforceContactData;
  }

  async fetchLeadSalesforceFields(tenant: number): Promise<string[]> {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/integrations/salesforce/data/Lead/fields`
    );

    const salesforceLeadData = data.fields.map(
      ({ name }: { name: string }) => name
    );

    return salesforceLeadData;
  }

  async fetchContactHubspotFields(tenant: number): Promise<string[]> {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/integrations/hubspot/data/contact/fields`
    );

    const hubspotContactData = data.fields.map(
      ({ name }: { name: string }) => name
    );

    return hubspotContactData;
  }

  async fetchAndStandardizeContactSegmentFields(
    tenant: number
  ): Promise<string[]> {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/integrations/segment/pull`
    );
    const fetchedSegmentContactData = data.config.identify.attributes.map(
      (identifyObject: Record<string, string>) => {
        return identifyObject.name;
      }
    );

    const aggregatedSegmentContactFields = [
      'mk_customer_fit_score',
      'mk_customer_fit_segment',
      'mk_customer_fit_signals',
      'mk_likelihood_to_buy_segment',
      'mk_likelihood_to_buy_score',
      'mk_likelihood_to_buy_signals',
      'mk_lead_grade_segment',
      'mk_lead_grade_score',
      ...fetchedSegmentContactData,
    ];
    const noDuplicateSegmentContactFields = [
      ...new Set(aggregatedSegmentContactFields),
    ];

    return noDuplicateSegmentContactFields;
  }

  fieldsToMap(connector: string, object: string) {
    if (connector === 'salesforce') {
      if (object === 'lead') {
        const sortedMkFields = sortFields(this.state.salesforceLeadData);

        return sortedMkFields.map((field) => ({ label: field, value: field }));
      }
      if (object === 'contact') {
        const sortedMkFields = sortFields(this.state.salesforceContactData);

        return sortedMkFields.map((field) => ({ label: field, value: field }));
      }
    }
    if (connector === 'hubspot') {
      if (object === 'contact') {
        const sortedMkFields = sortFields(this.state.hubspotContactData);
        return (
          this.state.hubspotContactData &&
          sortedMkFields.map((field) => ({ label: field, value: field }))
        );
      }
    }
    if (connector === 'intercom') {
      if (object === 'contact') {
        return [
          'mk_customer_fit_score',
          'mk_customer_fit_segment',
          'mk_customer_fit_signals',
        ].map((field: string) => ({ label: field, value: field }));
      }
      if (object === 'lead') {
        return [];
      }
    }
    if (connector === 'segment' && object === 'identify') {
      const sortedMkFields = sortFields(this.state.segmentContactData);
      return (
        this.state.segmentContactData &&
        sortedMkFields.map((field) => ({ label: field, value: field }))
      );
    }
  }

  render() {
    const displayConnector = Object.keys(this.props.connectors).filter(
      (k) => this.props.connectors[k]
    );

    if (this.state.loading) return <Loader />;

    return (
      <>
        {displayConnector.map((connector: string) => {
          return (
            <CardField
              key={connector}
              handleSegmentTimeFrameChange={
                this.props.handleSegmentTimeFrameChange
              }
              modelsList={this.props.modelsList}
              connector={connector}
              handleDataMappingChange={this.props.handleDataMappingChange}
              handleChangeObject={this.props.handleChangeObject}
              pickedObject={this.props.pickedObject}
              pushConfigurationItem={this.props.pushConfigurationItem}
              generateModelBadges={this.props.generateModelBadges}
              handleDatePick={this.props.handleDatePick}
              handleTimeLimitCheck={this.props.handleTimeLimitCheck}
              datePicked={this.props.datePicked}
              fieldsToMap={this.fieldsToMap}
            />
          );
        })}
        {(this.props.pickedModel.leadGrade ||
          this.props.pickedModel.likelihoodToBuy) && (
          <AdvancedConfiguration
            handleScoresChangeLtb={this.props.handleScoresChangeLtb}
            handleScoresChangeLg={this.props.handleScoresChangeLg}
            roundLtbYaml={this.props.roundLtbYaml}
            roundLgYaml={this.props.roundLgYaml}
            handleYamlPickedModel={this.props.handleYamlPickedModel}
            yamlPickedModel={this.props.yamlPickedModel}
            pickedModel={this.props.pickedModel}
          />
        )}

        <div className="row d-flex justify-content-center align-items-center m-2">
          <div className="col-2">
            <button
              className="btn btn-primary float-right"
              onClick={() => this.props.changePageState('connector')}
            >
              Go Back
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary"
              onClick={async () => {
                await this.props.onConfirmDataMapping();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
