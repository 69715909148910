import React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  Row,
  Table,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import {
  DATASOURCE_OBJECTS_AUDIENCE,
  getVerbBoundaries,
  VERBS,
} from '../../utils';
import { LEAD_MAPPING_OBJECTS, unslugAudienceField } from './utils';
import AudienceMappingManager from './models/AudienceMappingManager';
import MissingFieldMicrocopy from '../../../components/ErrorMicroCopyComponent';
import {
  FIELD_PLACEHOLDER_VALUE,
  OBJECT_PLACEHOLDER_VALUE,
  SOURCESYSTEMS_PLACEHOLDER_VALUE,
  VERB_PLACEHOLDER_VALUE,
} from '../../constants';
import VerbsNames from '../../enums/VerbsNames';
import { AudienceMappingConnectorsNames } from '../../../types';

type AudienceFormProps = {
  audienceMappingManager: AudienceMappingManager;
  isNewAudience: boolean;
  editable: boolean;
  createNewCondition: () => void;
  createNewConditionValue: (index: number) => void;
  removeConditionValue: (index: number) => void;
  handleLogicChange: (logic: string) => void;
  handleValueChange: (value: string, index: number, valueIndex: number) => void;
  handleObjectChange: (value: string, index: number) => void;
  handleTraitChange: (value: string, index: number) => void;
  handleSourceSystemChange: (
    value: AudienceMappingConnectorsNames,
    index: number
  ) => void;
  handleLowerChange: (index: number) => void;
  handleVerbChange: (value: string, index: number) => void;
  handleRemoveCondition: (index: number) => void;
  handleAccountLeadMappingChange: (value: string) => void;
  handleOpportunityLeadMappingChange: (value: string) => void;
  handleDomainFieldsChange: (value: string) => void;
};

export default class AudienceForm extends React.Component<AudienceFormProps> {
  showAccountLeadMappingSection() {
    const { editable, isNewAudience, audienceMappingManager } = this.props;
    const { accountLeadMapping, domainFields } = audienceMappingManager;

    return (
      <div>
        <div className="row">
          <div className="col">
            <h4> How should accounts be mapped to leads?</h4>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col mb-3">
            <p>Choose how accounts should be mapped to Leads:</p>
            <Select
              value={{ label: accountLeadMapping, value: accountLeadMapping }}
              options={LEAD_MAPPING_OBJECTS.Account.map((account) => ({
                label: account,
                value: account,
              }))}
              onChange={({ value }) => {
                this.props.handleAccountLeadMappingChange(value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col mb-3">
            <p>Define fields used for identifying domain:</p>
            <FormControl
              type="text"
              value={domainFields}
              onChange={(event) => {
                const target = event.target as HTMLInputElement;
                this.props.handleDomainFieldsChange(target.value);
              }}
              name="domainFields"
              disabled={!isNewAudience && !editable}
            />
          </div>
        </div>
      </div>
    );
  }

  showOpportunityLeadMappingSection() {
    const { editable, isNewAudience, audienceMappingManager } = this.props;
    const { opportunityLeadMapping } = audienceMappingManager;

    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col">
            <h4 className="mb-0">
              How should opportunities be mapped to leads?
            </h4>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col mb-3">
            <p>Choose how opportunities should be mapped to Leads:</p>
            <Select
              value={{
                label: opportunityLeadMapping,
                value: opportunityLeadMapping,
              }}
              options={LEAD_MAPPING_OBJECTS.Opportunity.map((opportunity) => ({
                label: opportunity,
                value: opportunity,
              }))}
              isDisabled={!isNewAudience && !editable}
              onChange={({ value }) => {
                this.props.handleOpportunityLeadMappingChange(value);
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    const { isNewAudience, editable, audienceMappingManager } = this.props;
    const {
      conditionsLogic,
      conditions,
      activeIntegrations,
    } = audienceMappingManager;
    const shouldShowAccountForm: boolean = audienceMappingManager.conditions.some(
      (condition) =>
        condition.sourceSystem?.toLowerCase() === 'salesforce' &&
        condition.object === 'Account'
    );
    const shouldShowOpportunityForm: boolean = audienceMappingManager.conditions.some(
      (condition) =>
        condition.sourceSystem?.toLowerCase() === 'salesforce' &&
        condition.object === 'Opportunity'
    );

    const readOnly = !isNewAudience && !editable;

    return (
      <div>
        <Row>
          <Col sm={12}>
            <p>This audience includes all the leads or contacts where...</p>
            {conditions.length ? (
              <div className="row mb-4">
                <ReactTooltip place="top" />
                <div className="col-sm-2">
                  <ControlLabel className="mt-1 font-weight-bold">
                    Edit condition logic:
                  </ControlLabel>
                </div>
                <div className="col">
                  <FormControl
                    type="text"
                    value={conditionsLogic}
                    onChange={(event) => {
                      const target = event.target as HTMLInputElement;
                      this.props.handleLogicChange(target.value);
                    }}
                    placeholder="$1 AND ($2 OR $3)"
                    data-tip="You can use parenthesis eg: $1 AND ($2 OR $3)"
                    disabled={!isNewAudience && !editable}
                    name="conditionsLogic"
                    className="w-50"
                  />
                  {audienceMappingManager.hasErrorOnProperty(
                    'conditionLogic - empty',
                    0
                  ) && (
                    <MissingFieldMicrocopy microcopy="Please select a valid condition logic." />
                  )}
                  {audienceMappingManager.hasErrorOnProperty(
                    'conditionLogic - extra conditions',
                    0
                  ) && (
                    <MissingFieldMicrocopy
                      microcopy={`Extra parameters shouldn't be used: ${audienceMappingManager
                        .getErrorOfProperty(
                          'conditionLogic - extra conditions',
                          0
                        )
                        .indexes.join(', ')}`}
                    />
                  )}
                  {audienceMappingManager.hasErrorOnProperty(
                    'conditionLogic - ignored conditions',
                    0
                  ) && (
                    <MissingFieldMicrocopy
                      microcopy={`Missing parameter(s): ${audienceMappingManager
                        .getErrorOfProperty(
                          'conditionLogic - ignored conditions',
                          0
                        )
                        .indexes.join(', ')}`}
                    />
                  )}
                  {audienceMappingManager.hasErrorOnProperty(
                    'conditionLogic - syntax error',
                    0
                  ) && (
                    <MissingFieldMicrocopy microcopy="Syntax error, please respect the following pattern: $1 AND ($2 OR $3)." />
                  )}
                </div>
              </div>
            ) : (
              <Alert bsStyle="info">No conditions yet</Alert>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="p-1"></th>
                  <th className="align-middle p-1">Source System</th>
                  <th className="align-middle p-1">Object</th>
                  <th className="align-middle p-1">Field</th>
                  <th className="align-middle p-1">Condition</th>
                  <th className="align-middle p-1">
                    Value
                    <a
                      href="#"
                      className="badge badge-yellow ml-2"
                      data-toggle="tooltip"
                      data-original-title="This field is case sensitive. Please check the 'Case insensitive' field toggle if you would like your value to be converted to lowercase."
                    >
                      !
                    </a>
                  </th>
                  <th className="align-middle p-1">Case insensitive</th>
                  {readOnly || <th className="align-middle p-1">Actions</th>}
                </tr>
              </thead>
              {conditions.map((condition, index) => (
                <tbody key={index}>
                  <tr key={index}>
                    <td className="p-1">
                      <span style={{ opacity: 0.5 }}>${index + 1}</span>
                    </td>
                    <td className="p-1">
                      {readOnly ? (
                        <span>{condition.sourceSystem}</span>
                      ) : (
                        <Select
                          value={
                            condition.sourceSystem && {
                              label: condition.sourceSystem,
                              value: condition.sourceSystem,
                            }
                          }
                          placeholder={SOURCESYSTEMS_PLACEHOLDER_VALUE}
                          options={activeIntegrations.map((integration) => ({
                            label: integration,
                            value: integration,
                          }))}
                          onChange={({ value }) => {
                            this.props.handleSourceSystemChange(value, index);
                          }}
                        />
                      )}

                      {audienceMappingManager.hasErrorOnProperty(
                        'sourceSystem',
                        index
                      ) && (
                        <MissingFieldMicrocopy microcopy="Please select a valid source system." />
                      )}
                    </td>
                    <td className="p-1">
                      {readOnly ? (
                        <span>{condition.object}</span>
                      ) : (
                        <Select
                          value={
                            condition.object && {
                              label: condition.object,
                              value: condition.object,
                            }
                          }
                          placeholder={OBJECT_PLACEHOLDER_VALUE}
                          options={
                            DATASOURCE_OBJECTS_AUDIENCE[
                              condition.sourceSystem?.toLowerCase()
                            ]?.map((object) => ({
                              label: object,
                              value: object,
                            })) ?? []
                          }
                          onChange={({ value }) => {
                            this.props.handleObjectChange(value, index);
                          }}
                          isDisabled={!condition.sourceSystem}
                        />
                      )}
                      {audienceMappingManager.hasErrorOnProperty(
                        'object',
                        index
                      ) && (
                        <MissingFieldMicrocopy microcopy="Please select a valid object." />
                      )}
                    </td>
                    <td className="p-1">
                      {readOnly ? (
                        <span>{unslugAudienceField(condition.trait)}</span>
                      ) : (
                        <Select
                          value={
                            condition.trait && {
                              label: unslugAudienceField(condition.trait),
                              value: condition.trait,
                            }
                          }
                          placeholder={FIELD_PLACEHOLDER_VALUE}
                          options={audienceMappingManager
                            .getFields(condition.sourceSystem, condition.object)
                            .map(({ name, ignore }) => ({
                              label: unslugAudienceField(name),
                              value: name,
                              isDisabled: ignore,
                            }))}
                          onChange={({ value }) => {
                            this.props.handleTraitChange(value, index);
                          }}
                          isDisabled={!condition.object}
                        />
                      )}
                      {audienceMappingManager.hasErrorOnProperty(
                        'trait',
                        index
                      ) && (
                        <MissingFieldMicrocopy microcopy="Please select a valid field." />
                      )}
                    </td>
                    <td className="p-1">
                      {readOnly ? (
                        <span>{condition.verb}</span>
                      ) : (
                        <Select
                          value={
                            condition.verb && {
                              label: condition.verb,
                              value: condition.verb,
                            }
                          }
                          placeholder={VERB_PLACEHOLDER_VALUE}
                          options={VERBS.map((verb: VerbsNames) => ({
                            label: verb,
                            value: verb,
                          }))}
                          onChange={({ value }) => {
                            this.props.handleVerbChange(value, index);
                          }}
                        />
                      )}
                      {audienceMappingManager.hasErrorOnProperty(
                        'verb',
                        index
                      ) && (
                        <MissingFieldMicrocopy microcopy="Please select a valid verb." />
                      )}
                    </td>
                    <td className="p-1">
                      {readOnly ? (
                        <ul className="pl-2">
                          {condition.values.map((value, valueIndex) => (
                            <li key={valueIndex}>{value}</li>
                          ))}
                        </ul>
                      ) : (
                        condition.values.map((value, valueIndex) => (
                          <FormControl
                            name="value"
                            type="text"
                            value={value}
                            disabled={
                              (!isNewAudience && !editable) || !condition.verb
                            }
                            key={valueIndex}
                            placeholder="Enter value..."
                            className="mb-1"
                            onChange={(event) => {
                              const target = event.target as HTMLInputElement;
                              this.props.handleValueChange(
                                target.value,
                                index,
                                valueIndex
                              );
                            }}
                          />
                        ))
                      )}
                      {audienceMappingManager.hasErrorOnProperty(
                        'values',
                        index
                      ) && (
                        <MissingFieldMicrocopy microcopy="Please select a valid value." />
                      )}
                    </td>
                    <td className="text-center p-1">
                      <Checkbox
                        checked={condition.lower}
                        disabled={!isNewAudience && !editable}
                        className="mt-2"
                        onChange={() => {
                          this.props.handleLowerChange(index);
                        }}
                      />
                    </td>
                    {readOnly || (
                      <td className="text-center p-1">
                        <div className="mb-1">
                          {condition.values.length <
                            getVerbBoundaries(condition.verb).max && (
                            <Button
                              bsStyle="primary"
                              title="Add value"
                              disabled={!isNewAudience && !editable}
                              bsSize="small"
                              onClick={() => {
                                this.props.createNewConditionValue(index);
                              }}
                            >
                              <i className="fas fa-plus fa-sm" />
                            </Button>
                          )}
                          {condition.values.length >
                            getVerbBoundaries(condition.verb).min && (
                            <Button
                              title="Delete last value"
                              bsStyle="danger"
                              disabled={!isNewAudience && !editable}
                              bsSize="small"
                              className="ml-1"
                              onClick={() => {
                                this.props.removeConditionValue(index);
                              }}
                            >
                              <i className="fas fa-minus fa-sm" />
                            </Button>
                          )}
                        </div>
                        <Button
                          title="Remove the condition"
                          bsStyle="danger"
                          disabled={!isNewAudience && !editable}
                          bsSize="small"
                          onClick={() => {
                            this.props.handleRemoveCondition(index);
                          }}
                        >
                          <i className="fas fa-trash fa-sm" />
                        </Button>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              disabled={!isNewAudience && !editable}
              bsStyle="primary"
              onClick={() => {
                this.props.createNewCondition();
              }}
            >
              Add condition
            </Button>
          </Col>
        </Row>
        {shouldShowAccountForm && (
          <Row className="mt-3">{this.showAccountLeadMappingSection()}</Row>
        )}
        {shouldShowOpportunityForm && (
          <Row className="mt-3 ml-0">
            <Col sm={12}>{this.showOpportunityLeadMappingSection()}</Col>
          </Row>
        )}
      </div>
    );
  }
}
