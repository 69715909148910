import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Integration } from './useIntegrationQuery';

export function useAddIntegrationMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (integration: Integration) => {
      return axios.post(
        `${BONGO_URL}/v1/org/${integration.tenant}/integrations/${integration.name}`,
        integration
      );
    },
    onSuccess: (_data, integration) => {
      const { credentials, ...integrationWithoutCredentials } = integration;

      queryClient.setQueryData(
        [integration.tenant, 'integrations', integration.name],
        integrationWithoutCredentials
      );

      queryClient.setQueryData(
        [integration.tenant, 'integrations', integration.name, 'credentials'],
        credentials
      );
    },
  });
}
