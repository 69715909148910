import React, { useEffect, useMemo } from 'react';
import { Icon, Select } from '../../components/ui';
import { Model, ModelSubset, PersonResult } from '../types';

type Props = {
  selectedModel: Model;
  setSelectedModel: React.Dispatch<React.SetStateAction<Model>>;
  models: ModelSubset[];
  personResult: PersonResult;
};

export default function SelectModel({
  selectedModel,
  setSelectedModel,
  models,
  personResult,
}: Props) {
  const selectModelOptions: Model[] = useMemo(
    () =>
      models
        ? models
            .filter(({ key }) => !!personResult.properties[key])
            .map((m) => ({
              label: m.name,
              value: m,
            }))
        : [],
    [models]
  );

  useEffect(() => {
    if (selectModelOptions.length) {
      setSelectedModel(selectModelOptions[0]);
    }
  }, [selectModelOptions]);

  return (
    <div className="tw-sticky -tw-top-1 tw-z-50 tw-mb-2 tw-flex tw-items-center tw-justify-between tw-gap-6 tw-bg-white tw-border-b tw-border-gray-100 tw-px-7 tw-h-24">
      <div>
        <div className="tw-flex tw-gap-2 tw-items-center">
          <Icon name="workspaceStudio" width={30} height={22} />
          <span className="tw-font-semibold tw-text-lg">Select a model</span>
        </div>
        <div className="tw-text-md tw-text-gray-500 tw-mt-1">
          You have <b>{selectModelOptions.length}</b> real-time model
          {selectModelOptions.length > 1 ? 's' : ''} available.
        </div>
      </div>

      <Select
        value={selectedModel}
        onChange={(option) => setSelectedModel(option)}
        options={selectModelOptions}
        className="tw-w-[30rem]"
        isDisabled={selectModelOptions.length <= 1}
      />
    </div>
  );
}
