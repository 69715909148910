import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

export function useDisconnectMixpanel(
  tenant: number,
  options: UseMutationOptions = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await axios.delete(`${BONGO_URL}/v1/org/${tenant}/integrations/mixpanel`);
    },
    onSuccess: (...args) => {
      queryClient.setQueryData(['mixpanel', tenant], false);
      queryClient.invalidateQueries(['mixpanel-credentials', tenant]);
      options?.onSuccess?.(...args);
    },
  });
}
