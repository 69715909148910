import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import toNumber from 'lodash/toNumber';
import { useEnabledJobChangesMutation } from '../api/useEnabledJobChangesMutation';
import { Button, Input } from '../../../components/ui';
import { useGetIntegrationConfigQuery } from '../../../api/useGetIntegrationConfigQuery';
import { JobChangesConfig } from '../types';
import { usePatchIntegrationConfigMutation } from '../../../api/usePatchIntegrationConfigMutation';

type Props = {
  tenant: number;
};

export function IntegrationSuperKuduConfigurationView({ tenant }: Props) {
  const { jobChangeEnabled } = useFlags();
  const [watchListLimit, setWatchListLimit] = React.useState<
    number | undefined
  >();

  const {
    mutate: enableJobChanges,
    isLoading: isEnablingJobChanges,
  } = useEnabledJobChangesMutation();

  const { data: jobChangesConfig } = useGetIntegrationConfigQuery<
    JobChangesConfig
  >({
    tenant,
    integration: 'job_changes',
  });

  const {
    mutate: patchJobChangesConfig,
    isLoading: isPatchingJobChangesConfig,
  } = usePatchIntegrationConfigMutation();

  useEffect(() => {
    if (!jobChangesConfig) return;
    setWatchListLimit(jobChangesConfig.watch_list_limit);
  }, [jobChangesConfig]);

  return (
    <div className="tw-flex tw-flex-col tw-w-full">
      <h5 className="tw-font-bold tw-mb-4">Job Changes access</h5>
      <div className="tw-mb-4">
        Job Changes is a premium feature, you can give the access to {tenant} by
        clicking the button below.
      </div>
      {jobChangeEnabled && (
        <span className="tw-text-green-500 tw-font-bold">
          Access to Job Changes granted
        </span>
      )}
      {!jobChangeEnabled && (
        <Button
          className="tw-w-fit"
          onClick={() => enableJobChanges(tenant)}
          loading={isEnablingJobChanges}
          disabled={isEnablingJobChanges}
        >
          Give access to Job Changes
        </Button>
      )}

      <h5 className="tw-font-bold tw-mt-8">Job Changes configuration</h5>
      <div className="tw-mb-4">
        Configure the different settings for the Job Changes feature.
      </div>

      <div className="tw-mb-2 tw-font-semibold">
        Limit of emails that can be tracked:
      </div>
      <Input
        className="tw-w-[300px]"
        value={watchListLimit}
        placeholder="Enter a limit of emails that can be tracked"
        type="number"
        onChange={(e) => {
          if (!e.currentTarget.value) return setWatchListLimit(undefined);

          const parsedLimit = toNumber(e.currentTarget.value);
          setWatchListLimit(parsedLimit);
        }}
      />
      <Button
        className="tw-mt-4 tw-w-fit"
        loading={isPatchingJobChangesConfig}
        disabled={isPatchingJobChangesConfig || watchListLimit === undefined}
        onClick={() =>
          patchJobChangesConfig({
            tenant,
            integration: 'job_changes',
            configPatch: {
              watch_list_limit: watchListLimit,
            },
          })
        }
      >
        Save
      </Button>
    </div>
  );
}
