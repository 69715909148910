import moment from 'moment';
import React from 'react';
import { Col, FormControl, Label, Row } from 'react-bootstrap';

type TimestampThresholdFormProps = {
  formData: UnknownObject;
};

type TimestampThresholdFormState = {
  month: string;
  year: number;
};

export default class TimestampThresholdForm extends React.Component<
  TimestampThresholdFormProps,
  TimestampThresholdFormState
> {
  years: number[];

  months: string[];

  constructor(props: TimestampThresholdFormProps) {
    super(props);
    this.years = this.initYears();
    this.months = this.initMonths();
    this.state = { month: '01', year: 2020 };

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  componentDidMount() {
    const { timestamp } = this.props.formData;
    if (!timestamp) {
      const now = moment();
      const current = { month: now.month() as any, year: now.year() };

      return this.setState(current);
    }
    return this.setState(timestamp);
  }

  componentDidUpdate() {
    const { month, year } = this.state;
    this.props.formData.timestamp = { month, year };
  }

  initYears() {
    const year = moment().year();
    const years = [year];

    // get last 5 years
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 6; i++) {
      years.push(year - i);
    }

    return years;
  }

  initMonths() {
    const months = [];

    // get 2-digit list of numeric months in a year
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 13; i++) {
      const month = i < 10 ? `0${i}` : `${i}`;
      months.push(month);
    }

    return months;
  }

  handleMonthChange(e: any) {
    const month = e.target.value;
    this.setState({ month });
  }

  handleYearChange(e: any) {
    const year = e.target.value;
    this.setState({ year });
  }

  render() {
    const { month, year } = this.state;

    return (
      <Row className="align-items-center text-center">
        <Col sm={1}>
          <Label>Month:</Label>
        </Col>
        <Col sm={2}>
          <FormControl
            name="month"
            value={month}
            componentClass="select"
            onChange={this.handleMonthChange}
          >
            {this.months.map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </FormControl>
        </Col>
        <Col sm={1} mdOffset={2}>
          <Label>Year:</Label>
        </Col>
        <Col sm={2} className="align-middle">
          <FormControl
            name="year"
            value={year}
            componentClass="select"
            onChange={this.handleYearChange}
          >
            {this.years.map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </FormControl>
        </Col>
      </Row>
    );
  }
}
