import Backbone from 'backbone';
import Inputmask from 'inputmask';
import $ from 'jquery';
import refresh_charts from './simulator/refresh_charts';
import refresh_labels from './simulator/refresh_labels';

const template = require('./simulator.pug');

export default Backbone.View.extend({
  events: {
    'change .input-simul': 'update',
  },

  async update() {
    await refresh_charts();
    refresh_labels();
  },

  async render() {
    const html = await template();
    await this.$el.html(html);

    window.mk_simulator = window.mk_simulator || {};
    // turn on the tooltips on the page
    $('[data-toggle="tooltip"]').tooltip();

    await this.initialize_input_masks();
    await refresh_charts();
    await refresh_labels();
    window.mk_simulator.has_changed_acquisition_spend = false;

    return this;
  },

  initialize_input_masks() {
    const mask_options_currency: Inputmask.Options = {
      groupSeparator: ',',
      digits: '0',
      autoGroup: true,
      prefix: '$ ',
      autoUnmask: true,
      rightAlign: false,
    };

    const mask_options_percentage: Inputmask.Options = {
      digits: '1',
      autoGroup: true,
      suffix: ' %',
      autoUnmask: true,
      rightAlign: false,
    };

    const mask_options_months: Inputmask.Options = {
      digits: '0',
      autoGroup: true,
      suffix: ' ',
      autoUnmask: true,
      rightAlign: false,
    };

    // define the masking configuration
    Inputmask('decimal', mask_options_currency).mask('#starting_MRR');
    Inputmask('decimal', mask_options_currency).mask('#revenue_growth');

    Inputmask('decimal', mask_options_percentage).mask('#churn');
    Inputmask('decimal', mask_options_percentage).mask('#upsell');

    Inputmask('decimal', mask_options_months).mask('#projection_time');

    Inputmask('decimal', mask_options_currency).mask('#acquisition_spend');
    Inputmask('decimal', mask_options_percentage).mask('#simulated_churn');

    // set up the default values
    $('#starting_MRR').val(100000);
    $('#revenue_growth').val(10000);
    $('#churn').val(5);
    $('#upsell').val(1);
    $('#projection_time').val(12);

    $('#acquisition_spend').val(10000 / (5 / 100) / 5);
    $('#simulated_churn').val(2.5);
  },

  async reset(e: any) {
    e.preventDefault();
  },
});
