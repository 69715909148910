import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import BehavioralModelComponent from './reactComponents/BehavioralModelComponent';
import LeadGradeModelComponent from './reactComponents/LeadGradeModelComponent';
import { displayOverrides, getModel } from './utils';

const template = require('./index.pug');

export default Backbone.View.extend({
  events: {
    'click #go_back_to_prediction': 'go_back_to_prediction',
    'click #go_back_to_diagnostics': 'go_back_to_diagnostics',
    'click #override-button': 'displayOverrides',
  },

  async initialize(options: { tenant: number; id: number; context: string }) {
    this.tenant = options.tenant;
    this.modelId = options.id;
    this.context = options.context;
    this.userEmail = await app.session.get('user').email;
    this.model = await getModel(this.tenant, this.modelId);
    this.render();
    this.renderModelComponent();
  },
  go_back_to_prediction() {
    Backbone.history.navigate(`/org/${this.tenant}/predictions/`, true);
  },
  go_back_to_diagnostics() {
    Backbone.history.navigate(
      `/org/${this.tenant}/predictions/performances/models/${this.modelId}/diagnostics`,
      true
    );
  },

  renderBehavioralModelComponent() {
    ReactDOM.render(
      <BehavioralModelComponent
        tenant={this.tenant}
        featuresEvaluation={this.model.features_evaluation || { variables: [] }}
        type={this.model.type}
      />,
      $('#ModelComponentContainer')[0]
    );
  },

  renderLeadGradeModelComponent() {
    ReactDOM.render(
      <LeadGradeModelComponent
        tenant={this.tenant}
        leadGradeMatrix={this.model.lead_grade_matrix}
      />,
      $('#ModelComponentContainer')[0]
    );
  },

  renderModelComponent() {
    if (this.context === 'lead_grade') {
      this.renderLeadGradeModelComponent();
    } else {
      this.renderBehavioralModelComponent();
    }
  },

  async displayOverrides() {
    await displayOverrides(this.tenant, this.modelId, this.userEmail);
  },

  async render() {
    const options = {
      modelName: this.model.name,
      displayOverrides: this.context !== 'lead_grade',
      modelType: this.model.type,
    };
    const html = template(options);
    await this.$el.html(html);
    return this;
  },
});
