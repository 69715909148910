import React, { useState } from 'react';
import EventMappingConditionItem from './EventMappingConditionItem';
import { IndexedEvent } from './hooks/useEventMappingHistory';

type EventMappingConditionListProps = {
  event: IndexedEvent;
  currentEventErrors: any[];
  isSalesforce: boolean;
};

export default function EventMappingConditionList({
  event,
  currentEventErrors,
  isSalesforce,
}: EventMappingConditionListProps) {
  const [collapsed, setCollapsed] = useState(false);
  const maxWidth = 500;

  const conditions = event?.conditionFormData?.conditions || [];
  if (!conditions.length) return <></>;

  const renderedConditions = conditions.map(
    ({ verb, subject, object, values, lower }, conditionIndex) => (
      <EventMappingConditionItem
        key={conditionIndex}
        conditionIndex={conditionIndex}
        lastConditionIndex={conditions.length - 1}
        currentEventErrors={currentEventErrors}
        isSalesforce={isSalesforce}
        maxWidth={maxWidth}
        verb={verb}
        subject={subject}
        object={object}
        values={values}
        lower={lower}
        conditionLogic={event?.conditionFormData?.conditionsLogic ?? ''}
      />
    )
  );

  const isCollapsible = renderedConditions.length > 5;

  return (
    <div className="text-secondary-400">
      <div
        className="d-flex flex-column gap-1 bg-white border border border-secondary-100 rounded px-2 py-1"
        style={{ maxWidth: `${maxWidth}px` }}
      >
        {renderedConditions.slice(0, 5)}
        {collapsed && isCollapsible && (
          <>
            {renderedConditions.slice(5)}
            <a
              className="text-primary-500 hover:text-primary-600"
              onClick={() => setCollapsed(false)}
            >
              Show less..
            </a>
          </>
        )}
        {!collapsed && isCollapsible && (
          <a
            className="text-primary-500 hover:text-primary-600"
            onClick={() => setCollapsed(true)}
          >
            Show {renderedConditions.length - 5} more condition
            {renderedConditions.length - 5 !== 1 ? 's' : ''}..
          </a>
        )}
      </div>
    </div>
  );
}
