import { faFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons/faFileCsv';

import { faXmark } from '@fortawesome/pro-duotone-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';

type Props = {
  onUpdate: (emails: string[]) => void;
  limit: number;
};

export function AudienceCsvInput({ onUpdate, limit }: Props) {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setError(undefined);

    if (!csvFile) return onUpdate([]);

    Papa.parse(csvFile, {
      complete({ data, meta }) {
        if (
          !meta.fields?.includes('email') &&
          !meta.fields?.includes('Email')
        ) {
          setCsvFile(null);
          return setError('Csv must include an email or Email column.');
        }

        const newEmails: string[] = [
          ...new Set(
            data.flatMap((row: Record<string, any>) => row.email || row.Email)
          ),
        ].filter(Boolean);

        if (newEmails.length > limit) {
          setCsvFile(null);
          return setError(
            `You can only upload a maximum of ${limit} emails based on your plan.`
          );
        }

        onUpdate(newEmails);
      },
      header: true,
      skipEmptyLines: true,
    });
  }, [csvFile]);

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => {
          if (!acceptedFiles.length) return;
          setCsvFile(acceptedFiles[0]);
        }}
        accept={{ 'text/csv': ['.csv'] }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className="tw-w-full tw-p-10 tw-border-2 tw-border-dashed tw-border-gray-200 tw-rounded-md tw-cursor-pointer tw-bg-gray-50"
            >
              <input {...getInputProps()} />
              <div className="tw-w-full tw-text-center tw-flex tw-flex-col tw-items-center">
                {!csvFile && (
                  <>
                    <FontAwesomeIcon
                      icon={faFileImport}
                      className="tw-text-5xl tw-text-gray-200 tw-mb-2"
                    />
                    <span className="tw-font-bold">
                      Drag and drop your csv file here
                    </span>
                    <p className="tw-align-center">
                      Your csv must include the email in a column named email or
                      Email and contains a maximum of {limit} emails based on
                      your plan.
                    </p>
                  </>
                )}
                {csvFile && (
                  <>
                    <FontAwesomeIcon
                      icon={faFileCsv}
                      className="tw-text-5xl tw-text-blue-800 tw-mb-2"
                    />
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="tw-flex tw-items-center"
                    >
                      <a
                        className="tw-font-bold"
                        href={URL.createObjectURL(csvFile)}
                      >
                        {csvFile.name}
                      </a>
                      <FontAwesomeIcon
                        onClick={() => setCsvFile(null)}
                        icon={faXmark}
                        className="tw-text-xl tw-ml-2"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      {error && (
        <span className="tw-text-red-500 tw-text-sm tw-block tw-mt-2">
          {error}
        </span>
      )}
    </div>
  );
}
