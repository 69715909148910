import axios from 'axios';
import Backbone from 'backbone';
import Mn from 'backbone.marionette';
import SessionModel from './model_session';
import notifications from './notifications';

require('backbone.epoxy');

const RootView = Mn.View.extend({
  el: '#app',
  regions: {
    notification: '#notification',
    sidebar: '#sidebar',
    chatbot: '#chatbot',
    main: '#main',
  },
});

const App = (Mn.Application as any).extend({
  // region: 'main', // not sure what this does

  initialize() {
    this.session = new SessionModel({});
    this.notifications = notifications;
    this.setRootLayout();
    axios.defaults.withCredentials = true;
    $.ajaxPrefilter(function (options) {
      // eslint-disable-next-line no-param-reassign
      options.xhrFields = {
        withCredentials: true,
      };
    });
  },

  setRootLayout() {
    this.root = new RootView();
  },

  start_router() {
    return Backbone.history.start({ pushState: true, root: '/' });
  },

  onStart() {
    if (!this.start_router()) {
      Backbone.history.navigate('error/404', { trigger: true });
    }
  },
});

const app = new App();

export default app;
