import Backbone from 'backbone';
import Mn from 'backbone.marionette';
import $ from 'jquery';
import CreateTenantView from './admin/create_tenant';
import AdminView from './admin/index';
import AdminModelsView from './admin/models';
import AdminTenantsView from './admin/tenants';
import app from './app';

const AuthController = (Mn.Object as any).extend({
  initialize() {
    this.session = app.session;
  },

  execute(callback: () => {}, args: { session: any }) {
    if (app.session.get('logged_in')) {
      if (callback) callback.apply(this, args);
    } else {
      let redirect;
      const pathname = window.location.pathname + window.location.search;
      if (!/login|redirect/.test(pathname) && pathname.length > 1) {
        redirect = `redirect=${encodeURIComponent(pathname)}`;
      }
      const login_url = redirect ? `/login?${redirect}` : '/login';
      Backbone.history.navigate(login_url, { trigger: true });
    }
  },

  change_title(title: string) {
    const newTitle = title ? `${title} | MadKudu` : 'MadKudu';
    $(document).attr('title', newTitle);
  },

  track_page(title: string) {
    return title && window.analytics && window.analytics.page(title);
  },

  transition_view(View: any, options: { title?: string } = {}) {
    const { title } = options;
    const user = this.session.get('user');
    if (!user.superKudu) {
      Backbone.history.navigate('error/404', { trigger: true });
      return;
    }

    this.track_page(title);
    this.change_title(title);

    app.root.showChildView(
      'main',
      // eslint-disable-next-line
      View.default ? new View.default(options) : new View(options)
    );
  },

  home() {
    this.transition_view(AdminView, { title: 'Admin' });
  },

  create_tenant() {
    this.transition_view(CreateTenantView, { title: 'Admin' });
  },

  list_tenants(currentPage: string) {
    this.transition_view(AdminTenantsView, {
      title: 'Admin',
      currentPage: Number(currentPage),
    });
  },

  list_models() {
    this.transition_view(AdminModelsView, {
      title: 'Live Models',
    });
  },
});

export default Mn.AppRouter.extend({
  execute(callback: () => {}, args: { session: any }, name: string) {
    this.controller.execute(callback, args, name);
    // if (callback) callback.apply(this, args)
  },
  appRoutes: {
    'admin(/)': 'home',
    'admin/tenant/create': 'create_tenant',
    'admin/tenant/list(?page=:page)': 'list_tenants',
    'admin/model/list': 'list_models',
  },

  initialize() {
    this.controller = new AuthController({});
  },
});
