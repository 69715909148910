import VerbsNames from '../../../enums/VerbsNames';
import { AudienceMappingConnectorsNames } from '../../../../types';

export class AudienceMappingCondition {
  verb?: VerbsNames;

  trait?: string;

  sourceSystem?: AudienceMappingConnectorsNames;

  object?: string;

  values: string[];

  lower: boolean;

  constructor(options: {
    verb?: VerbsNames;
    trait?: string;
    sourceSystem?: AudienceMappingConnectorsNames;
    object?: string;
    values: string[];
    lower: boolean;
  }) {
    this.verb = options.verb;
    this.trait = options.trait || '';
    this.lower = options.lower === true;
    this.sourceSystem = options.sourceSystem;
    this.object = options.object || '';
    this.values = options.values || [];
  }

  public static create(): AudienceMappingCondition {
    return new AudienceMappingCondition({
      values: [''],
      lower: false,
    });
  }
}
