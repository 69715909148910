import axios from 'axios';
import Backbone from 'backbone';
import Radio from 'backbone.radio';
import $ from 'jquery';
import Swal from 'sweetalert2';
import model_integration from '../models/integration';
import { BackboneViewProps } from '../types';
import FieldsMapping from './fields_mapping';
import test_connection from './test_connection';
import { deleteIntegrationAndLaunchPopUp } from './utils';

const channel = Radio.channel('integration');
const template = require('./hubspot.pug');

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click #btn_cancel': 'event_cancel',
    'click #btn_save': 'event_save',
    'click #mki_oauth_btn': 'redirect_to_oauth',
    'click #mki_delete_connection': 'delete',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'hubspot';
    this.model = new model_integration.Credentials_model({
      tenant: this.tenant,
      integration: this.integration,
    });
    this.model.fetch({
      success: () => this.render(),
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          this.model.set({
            not_found: true,
          });
          this.render();
        }
      },
    });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    const credentialsFound = this.model.get('not_found') !== true;

    const html = $(
      '#tpl_main',
      template({ integration: this.integration })
    ).html();
    this.$el.html(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    if (this.model.get('not_found') !== true) {
      this.render_oauth_connected();
    } else {
      this.render_oauth();
    }
    return this;
  },

  render_oauth() {
    const html = $(
      '#tpl_oauth',
      template({ integration: this.integration })
    ).html();
    $('#mki_oauth').html(html);
    return this;
  },

  redirect_to_oauth() {
    window.location.href = `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/oauth/redirect`;
  },

  render_oauth_connected() {
    const html = $(
      '#tpl_connected',
      template({
        integration: this.integration,
      })
    ).html();
    $('#mki_oauth').html(html);

    // Display portal_id if available
    const portalId = this.model.get('portal_id');
    if (portalId) {
      $('#portal_id', this.$el).html(`<p><b>Portal ID:</b> ${portalId}</p>`);
    }
    return this;
  },

  event_cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/integrations`, true);
  },

  async delete() {
    await deleteIntegrationAndLaunchPopUp(this.tenant, 'hubspot');
  },

  event_save(event: any) {
    event.preventDefault();

    const show_error_message = (message: string, title?: string) => {
      Swal.fire({
        title: title || 'Oops',
        text: message,
        icon: 'error',
      });
    };

    if (!this.model.get('api_key')) {
      return show_error_message('Please enter an API Key');
    }

    const credentials = {
      api_key: this.model.get('api_key'),
    };

    axios
      .post(
        `${BONGO_URL}/v1/org/${this.tenant}/integrations/${this.integration}/credentials?activate_pull=true&activate_push=true`,
        credentials
      )
      .then(() => {
        test_connection(this.tenant, this.integration)
          .then(() =>
            channel.trigger('success', {
              integration: this.integration,
              alert: true,
            })
          )
          .catch((err: any) =>
            show_error_message(err.message, 'Unable to connect to HubSpot')
          );
      })
      .catch((err: any) => {
        const error = err.response && err.response.data;
        show_error_message(error.message, 'Unable to save your credentials');
      });
  },
});
