import * as React from 'react';

export type Connector =
  | 'salesforce'
  | 'hubspot'
  | 'marketo'
  | 'eloqua'
  | 'segment';

type ConnectorSelectProps = {
  value: Connector;
  onChange: (event: any) => void;
};

function ConnectorSelect({
  value,
  onChange,
}: ConnectorSelectProps): React.ReactElement {
  return (
    <select
      className="form-control"
      id="connector"
      value={value}
      onChange={onChange}
      required
    >
      <option value="salesforce">Salesforce</option>
      <option value="hubspot">HubSpot</option>
      <option value="marketo">Marketo</option>
      <option value="eloqua">Eloqua</option>
      <option value="segment">Segment</option>
    </select>
  );
}

export default ConnectorSelect;
