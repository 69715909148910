import Backbone from 'backbone';
import $ from 'jquery';
import FieldsMapping from './fields_mapping';

const template = require('./hull.pug');

// credentials work in weird way for hull, so I decided to let the other
// display checks determine if push/pull fields should be displayed
const CREDENTIALS_FOUND = true;

export default (Backbone as any).Epoxy.View.extend({
  async initialize(options: { tenant: number; context: string }) {
    this.tenant = options.tenant;
    this.integration = 'hull';
    this.context = options.context;
    this.model.fetch({ success: () => this.render() });
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector));
  },

  render() {
    this.$el.empty();
    const html = $(
      '#tpl_main',
      template({
        integration: this.integration,
      })
    ).html();
    this.$el.html(html);

    const fieldsMappingView = new FieldsMapping({
      tenant: this.tenant,
      integration: this.integration,
      credentialsFound: CREDENTIALS_FOUND,
    });
    this.assign(fieldsMappingView, '#fields_mapping');

    this.applyBindings();
  },
});
