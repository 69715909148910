import axios from 'axios';
import Backbone from 'backbone';
import Swal from 'sweetalert2';
import getHtmlBodyOfRecapSwal from './htmlScript';

export async function getAttributes(tenant: number) {
  try {
    const { data } = await axios.get(
      `${BONGO_URL}/v1/org/${tenant}/data/mappings/attributes`
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function getModels(tenant: number) {
  try {
    const { data } = await axios.get(`${BONGO_URL}/v1/org/${tenant}/models`);
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export function isOneModelLive(models: any[]) {
  if (!models) throw new Error('models is empty or invalid!');
  return models.find((model) => {
    return (
      model.batch === true &&
      model.type === 'customer_fit' &&
      model.live === true
    );
  });
}

export function generateTicket(
  tenant: number,
  email: string,
  message: string,
  object = 'Account',
  period = 'Last 12 months',
  connector = 'Salesforce'
) {
  return {
    email,
    tenant,
    connector,
    object,
    period,
    message,
  };
}

export async function sendTicket(
  email: string,
  tenant: number,
  message: string
) {
  try {
    const data = generateTicket(tenant, email, message);
    await axios.post(
      `${BONGO_URL}/v1/org/${tenant}/services/zapier/5187190/oskylbp`,
      data
    );
  } catch (e) {
    throw new Error(e);
  }
}

export async function displayRecapSwal(
  email: string,
  tenant: number,
  message: string
) {
  return Swal.fire({
    title: 'Please check ticket first',
    icon: 'warning',
    html: getHtmlBodyOfRecapSwal(tenant, email, message),
    showCancelButton: true,
    confirmButtonText: 'Send ticket',
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      try {
        await sendTicket(email, tenant, message);
        return true;
      } catch (e) {
        return false;
      }
    },
  }).then((result: any) => {
    if (result.value === true) {
      Swal.fire({
        title: 'Success',
        icon: 'success',
        text: 'Your ticket was sent!',
      });
    } else if (result.value === false) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Something went wrong...',
      });
    }
  });
}

export async function displayNoModelSwal() {
  Swal.fire({
    icon: 'warning',
    text: 'No customer fit model deployed',
  });
}

export async function displayNoAttributeMappingSwal(tenant: number) {
  Swal.fire({
    icon: 'warning',
    text: 'No Account domain Mapped',
    showCancelButton: true,
    confirmButtonText: 'Attribute mapping',
    preConfirm: () => {
      return true;
    },
  }).then((result: any) => {
    if (result.value) {
      Backbone.history.navigate(
        `/org/${tenant}/mapping/attribute_mapping`,
        true
      );
    }
  });
}
