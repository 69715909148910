import numeral from 'numeral';

export default function () {
  window.mk_simulator = window.mk_simulator || {};

  $('.label_projection_time').text($('#projection_time').val() as string);
  $('.label_final_mrr').text(
    numeral(window.mk_simulator.label_final_mrr).format('($ 0 a)')
  );
  $('.label_current_churn').text(
    numeral(($('#churn').val() as number) / 100).format('0.[0]%')
  );
  $('.label_churn_amount').text(
    numeral(window.mk_simulator.label_churn_amount).format('($ 0 a)')
  );

  if (!window.mk_simulator.has_changed_acquisition_spend) {
    const estimated_acquisition_spend =
      ($('#revenue_growth').val() as number) /
      (($('#churn').val() as number) / 100) /
      5;
    $('#acquisition_spend').val(estimated_acquisition_spend);
  }

  $('.label_acquisition_units').text(
    numeral(
      ($('#acquisition_spend').val() as number) /
        ($('#revenue_growth').val() as number)
    ).format('($ 0.[0] a)')
  );
  $('.label_acquisition_spend_for_churn').text(
    numeral(
      (($('#acquisition_spend').val() as number) /
        ($('#revenue_growth').val() as number)) *
        window.mk_simulator.label_churn_amount
    ).format('($ 0 a)')
  );

  $('.label_simulated_churn').text(
    numeral(($('#simulated_churn').val() as number) / 100).format('0.[0]%')
  );
  $('.label_mrr_saved').text(
    numeral(
      window.mk_simulator.label_final_mrr_simulated -
        window.mk_simulator.label_final_mrr
    ).format('($ 0 a)')
  );
}
