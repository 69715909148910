import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useSlackIsConnected(tenant: number) {
  return useQuery<boolean>({
    queryKey: ['slack', tenant],
    queryFn: async () => {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/org/${tenant}/integrations/slack`
      );

      return !!data?._id;
    },
  });
}
