import { AttributeMappingConnectorsNames } from '../../../../types';
import { ConnectorFieldNameDataModel } from '../../../models/ConnectorFieldNameDataModel';
import { DATASOURCE_OBJECTS_ATTRIBUTE } from '../../../utils';
import FieldDataModel from '../../../models/FieldDataModel';
import { FIELD_PLACEHOLDER_VALUE } from '../../../constants';

export type AttributeMappingFilterFormOptions = {
  createdAt: number;
  updatedAt: number;
  forms: AttributeMappingForm[];
  domainFields: string[];
  leadFields: string[];
};

export class AttributeMappingForm {
  table: string;

  field: string;

  attributeFieldName: string;

  usedInComputations: string[] | null;

  constructor(
    table: string,
    field: string,
    attributeFieldName: string,
    usedInComputations: string[] | null
  ) {
    this.table = table;
    this.field = field;
    this.attributeFieldName = attributeFieldName;
    this.usedInComputations = usedInComputations;
  }
}

export class AttributeMappingFilterForm {
  connector: AttributeMappingConnectorsNames;

  tenant: number;

  connectorFieldsName: ConnectorFieldNameDataModel[];

  createdAt: number;

  updatedAt: number;

  forms: AttributeMappingForm[];

  domainFields: string[];

  leadFields: string[];

  constructor(
    tenant: number,
    connector: AttributeMappingConnectorsNames,
    connectorFieldsName: ConnectorFieldNameDataModel[],
    options?: AttributeMappingFilterFormOptions
  ) {
    this.tenant = tenant;
    this.connector = connector;
    this.forms = [];
    this.domainFields = options?.domainFields || [];
    this.leadFields = options?.leadFields || [];
    this.connectorFieldsName = connectorFieldsName;

    if (options?.createdAt) {
      this.createdAt = options.createdAt;
    } else {
      this.createdAt = Date.now();
    }
    if (options?.updatedAt) {
      this.updatedAt = options.updatedAt;
    } else {
      this.updatedAt = Date.now();
    }

    if (
      this.connector === 'salesforce' &&
      !this.leadFields.includes('a_convertedaccountid')
    ) {
      this.leadFields.unshift('a_convertedaccountid');
    }
    if (options?.forms) {
      this.forms = options.forms.map((form) => {
        return new AttributeMappingForm(
          form.table,
          form.field,
          form.attributeFieldName,
          form.usedInComputations
        );
      });
    }
  }

  getFields(table: string, withFieldPlaceHolderValue = true): FieldDataModel[] {
    const sortedFields =
      this.connectorFieldsName
        .find(
          (connectorFieldNames) =>
            connectorFieldNames.title.toLowerCase() === table.toLowerCase()
        )
        ?.fields.sort((a, b) => a.name.localeCompare(b.name)) || [];

    return withFieldPlaceHolderValue
      ? [{ name: FIELD_PLACEHOLDER_VALUE, standard: true }, ...sortedFields]
      : sortedFields;
  }

  addDefaultForm() {
    const defaultTables: string[] =
      DATASOURCE_OBJECTS_ATTRIBUTE[this.connector];
    const fields: FieldDataModel[] = this.getFields(defaultTables[1]);
    const defaultAttributeMappingForm = new AttributeMappingForm(
      defaultTables[0],
      fields[0].name,
      '',
      null
    );
    this.forms.push(defaultAttributeMappingForm);
  }

  prepare() {
    delete this.connectorFieldsName;
  }
}
