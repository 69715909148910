import Backbone from 'backbone';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { BackboneViewProps } from '../../types';
import { BigQuery } from './BigQuery';
import { defaultQueryClient, localStoragePersister } from '../../query_client';
import { BigQueryProvider } from './contexts/BigQueryContext';

const template = require('./index.pug');

export default (Backbone as any).Epoxy.View.extend({
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.integration = 'bigquery';
    requestAnimationFrame(() => {
      this.render();
      this.renderBigQueryIntegrationComponent();
    });
  },

  getActiveTabName(): string {
    const splitUrl = window.location.href.split('/');
    const lastItem = splitUrl[splitUrl.length - 1];
    if (lastItem === '') {
      return 'overview';
    }
    return lastItem;
  },

  toggleView(tenant: string, integration: string) {
    return (tabName: string) => {
      Backbone.history.navigate(
        `/org/${tenant}/integrations/${integration}/${tabName}`
      );
    };
  },

  renderBigQueryIntegrationComponent() {
    const containerDomElement = $('#BigQueryComponentContainer')[0];
    if (typeof containerDomElement !== 'undefined') {
      ReactDOM.render(
        <PersistQueryClientProvider
          client={defaultQueryClient}
          persistOptions={{ persister: localStoragePersister }}
        >
          <BigQueryProvider tenant={this.tenant}>
            <BigQuery />
          </BigQueryProvider>
        </PersistQueryClientProvider>,
        containerDomElement
      );
    }
  },

  render() {
    this.$el.empty();
    const html = template();
    this.$el.html(html);
  },
});
