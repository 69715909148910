import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { RuleDiagnosis, PersonModelScore, TreeDiagnosis } from '../../types';

function useFetchScoreLookup<TIsTreeBased extends boolean>(
  tenant: number,
  modelId: number,
  personModelScore: PersonModelScore,
  isTreeBased: TIsTreeBased
) {
  return useQuery<{
    completion: string;
    diagnosis: TIsTreeBased extends true ? TreeDiagnosis : RuleDiagnosis;
  }>(
    ['profile-score-lookup', tenant, modelId, personModelScore],
    async () => {
      const [{ data: diagnosis }, { data: completion }] = await Promise.all([
        isTreeBased
          ? axios.post<TreeDiagnosis>(
              `${BONGO_URL}/v1/org/${tenant}/models/${modelId}/conversion-trees`,
              {
                treesToFetch: personModelScore.diagnosis?.treesDiagnosis,
              }
            )
          : axios.post<RuleDiagnosis>(
              `${BONGO_URL}/v1/org/${tenant}/models/${modelId}/point-based-rules`,
              {
                rulesToMap: personModelScore.diagnosis?.microMlDiagnosis,
              }
            ),
        axios.post<string>(
          `${BONGO_URL}/v1/org/${tenant}/models/${modelId}/diagnosis-completion`,
          {
            score: personModelScore.score,
            segment: personModelScore.segment,
            diagnosis: personModelScore.diagnosis,
          }
        ),
      ]);

      return {
        completion,
        diagnosis: diagnosis as TIsTreeBased extends true
          ? TreeDiagnosis
          : RuleDiagnosis,
      };
    },
    {
      staleTime: Infinity,
      retry: false,
    }
  );
}

export default useFetchScoreLookup;
