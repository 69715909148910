import { useQuery } from '@tanstack/react-query';
import EventMappingMode from '../../../../modes/EventMappingMode';

/**
 * Wrapper hook around `EventMappingMode`.
 *
 * Originally `EventMappingMode` was the model in charge of
 * saving / copying / publishing event mappings.
 */
export default function useEventMappingModel(
  tenant: number,
  connector: string,
  email: string,
  superKudu: boolean
) {
  const { data: model, isFetching, error, refetch } = useQuery(
    [`event-mapping-model-${tenant}-${connector}`],
    async () => {
      const _model = new EventMappingMode(
        tenant,
        connector as any,
        email,
        superKudu
      );
      await _model.init();
      return _model;
    }
  );

  return {
    model,
    isFetching,
    invalidateModel: refetch,
    error,
  };
}
