import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../app';
import { UserServiceModel } from '../../settings/users/types';
import { MixpanelIntegration } from './IntegrationPage';

const template = require('./index.pug');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

export default Backbone.View.extend({
  async initialize(options: { tenant: number; queryString: string }) {
    this.tenant = options.tenant;
    this.integration = 'mixpanel';
    this.session = app.session;
    this.renderedOnce = false;
    const user: UserServiceModel = this.session.get('user');

    this.hasAccessToConfigurations =
      user.permissions.some(
        (permission) =>
          permission.tenant === this.tenant && permission.role === 'admin'
      ) || user.superKudu;

    await this.renderMixpanelIntegrationComponent(this.tenant, user);
  },

  async renderMixpanelIntegrationComponent() {
    // BUG: For some reason, Backbone will initialize the view once
    // but will render the component twice
    if (this.renderedOnce) return;

    // prepare dom for react component
    await this.$el.empty();
    this.$el.html(template());

    this.renderedOnce = true;

    // render react component
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <MixpanelIntegration
          tenant={this.tenant}
          hasAccessToConfiguration={this.hasAccessToConfigurations}
          user={this.user}
        />
      </QueryClientProvider>,
      $('#MixpanelIntegrationContainer')[0]
    );
  },
});
