import Backbone from 'backbone';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import React, { useEffect } from 'react';
import qs from 'qs';
import axios from 'axios';
// import axios from 'axios';

const template = require('./callback.pug');

function IntegrationCallback({
  integration,
  code,
}: {
  integration: string;
  code: string;
}) {
  useEffect(() => {
    async function getIntegrationOauthToken() {
      const { data } = await axios.get(
        `${BONGO_URL}/v1/integrations/${integration}/oauth/token?code=${code}`
      );

      const message = {
        target: 'bigqueryAuthPopup',
        data,
      };
      window.opener?.postMessage(message);
    }

    getIntegrationOauthToken();
  }, []);

  return <div>Connecting to BigQuery...</div>;
}

export default Backbone.View.extend({
  async initialize(options: { integration: string; query_params: string }) {
    this.integration = options.integration;
    this.query_params = options.query_params
      ? qs.parse(options.query_params)
      : {};
    this.code = this.query_params.code;

    await this.render();

    this.renderProfileContainerComponent();
  },

  renderProfileContainerComponent() {
    ReactDOM.render(
      <IntegrationCallback integration={this.integration} code={this.code} />,
      $('#CallbackComponent')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
