import axios from 'axios';
import Backbone from 'backbone';
import Chart from 'chart.js';
import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import { COLORS } from '../../../predictions/utils';
import { BackboneViewProps } from '../../../types';
import {
  createDefaultDates,
  extractTypes,
  getBootstrapColumnsFromData,
  refreshDataDiscovery,
} from '../../utils';
import app from '../../../app';

const ChartDataLabels = require('chartjs-plugin-datalabels');

require('bootstrap-table/dist/bootstrap-table');

Chart.plugins.register(ChartDataLabels);

const template = require('./discovery.pug');

export default Backbone.View.extend({
  events: {
    'click #go_back': 'go_back',
    'click #refresh': 'refresh',
  },

  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.userEmail = app.session.get('user').email;
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/discovery/hubspot`;
    const { data } = await axios.get(url);
    const updatedAt: string =
      moment(data.updated_at).format('lll') ?? 'unknown';
    this.data = data;
    this.updatedAt = updatedAt;
    this.render();
    this.hubspotFunnel();
    this.contactsCreated();
    this.dealsOpenedAnClosedWon();
    this.funnelData();
    this.hubspotDealTypes();
    this.dealStageProbability();
    this.leadSourceEvolution();
    this.topSourceDealOpenOpp();
    this.topSourceDealClosedWon();
    this.leadSourceFunnel();
    this.dealClosed();
    this.dealMinMax();
    this.contactsQuality();
    this.dealsQuality();
    this.event_type_distribution();
    this.monthly_active_contacts();
    this.most_frequent_hubspot_forms_submissions();
    this.total_form_submissions_and_form_titles();
    this.total_events_clicks();
    this.total_events_status();
  },

  async refresh() {
    await refreshDataDiscovery(this.tenant, this.userEmail, 'hubspot');
  },

  hubspotFunnel() {
    const rawData: UnknownObject[] = this.data.dataset.funnel_contacts_deals;
    const data = rawData.map((row: any) => {
      return {
        contacts_created: row['Contacts Created'],
        deals_opened: row['Deals Opened'],
        deals_closed_won: row['Deals Closed Won'],
        deals_closed_lost: row['Deals Closed Lost'],
      };
    });

    $('#hubspot_funnel', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  contactsCreated() {
    const rawData: UnknownObject[] = this.data.dataset.contacts;
    if (!rawData) return;

    const ctx = document
      .getElementById('contacts_created_chart')
      .getContext('2d');

    const dates = createDefaultDates(rawData);
    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Contacts Created',
            data: rawData.map((row: any) => row['Contacts Created']),
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'Created Date',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  dealsOpenedAnClosedWon() {
    const rawData: UnknownObject[] = this.data.dataset.funnel_data;
    if (!rawData || !rawData.length) return;

    const ctx = document
      .getElementById('deals_opened_and_closed_won_chart')
      .getContext('2d');

    const dates = createDefaultDates(rawData);

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Deals Opened',
            data: rawData.map((row: any) => row['Deals Opened']),
            backgroundColor: COLORS[0],
          },
          {
            label: 'Deals >0%',
            data: rawData.map((row: any) => row['Deals >0%']),
            backgroundColor: COLORS[1],
          },
          {
            label: 'Deals Closed Lost',
            data: rawData.map((row: any) => row['Deals Closed Lost']),
            backgroundColor: COLORS[2],
          },
          {
            label: 'Deals Closed Won',
            data: rawData.map((row: any) => row['Deals Closed Won']),
            backgroundColor: COLORS[3],
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'Created Date',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  funnelData() {
    const rawData: UnknownObject[] = this.data.dataset.funnel_data;

    const data = rawData.map((row: any) => {
      return {
        created_date: row['Created date'],
        'Deals >0%': row['Deals >0%'],
        deals_closed_won: row['Deals Closed Won'],
        deals_closed_lost: row['Deals Closed Lost'],
        'avg_time_to_close_(days)': row['avg time to close'],
      };
    });

    $('#funnel_data', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  hubspotDealTypes() {
    const rawData: UnknownObject[] = this.data.dataset.deal_types;

    const data = rawData.map((row: any) => {
      return {
        dealtype: row.dealtype,
        distinct_opp_id: row['distinct opp id'],
      };
    });

    $('#deal_types', this.$el).bootstrapTable({
      columns: [
        { field: 'dealtype', title: 'Deal type', sortable: true },
        {
          field: 'distinct_opp_id',
          title: 'Distinct Opp Id',
          sortable: true,
        },
      ],
      data,
      pagination: true,
      search: true,
    });
  },

  dealStageProbability() {
    const rawData: UnknownObject[] = this.data.dataset.deal_stage_probability;

    const data = rawData.map((row: any) => {
      return {
        is_closed: row['is closed'],
        probability: Number(Number(row.probability).toFixed(2)),
        distinct_opp_id: row['distinct opp id'],
      };
    });

    $('#deal_stage_and_probability', this.$el).bootstrapTable({
      columns: [
        { field: 'is_closed', title: 'Is closed', sortable: true },
        { field: 'probability', title: 'Probability', sortable: true },
        {
          field: 'distinct_opp_id',
          title: 'Distinct Opp Id',
          sortable: true,
        },
      ],
      data,
      pagination: true,
      search: true,
    });
  },

  leadSourceEvolution() {
    const rawData: UnknownObject[] = this.data.dataset.leadsource_evolution;

    const labels: any[] = [
      ...new Set(
        rawData.reduce((acc, row) => acc.concat(row['Created date']), [])
      ),
    ];
    const ctx = document
      .getElementById('leadsource_evolution')
      .getContext('2d');

    const sumByDate: UnknownObject = {};
    const leadSources: UnknownObject = {};
    rawData.forEach((row) => {
      // Lead Sources
      if (!leadSources[row.a_hs_analytics_source]) {
        leadSources[row.a_hs_analytics_source] = {};
      }
      leadSources[row.a_hs_analytics_source][row['Created date']] =
        row['Leads created'];

      // Sum by date
      sumByDate[row['Created date']] =
        (sumByDate[row['Created date']] || 0) + row['Leads created'];
    });

    const datasets: UnknownObject[] = Object.keys(leadSources).map(
      (leadSource, i) => {
        const data = labels.map((createdDate) => {
          return (
            ((leadSources[leadSource][createdDate] || 0) * 100) /
            sumByDate[createdDate]
          ).toFixed(2);
        });
        return {
          data,
          label: leadSource,
          backgroundColor: COLORS[i],
        };
      }
    );

    const dates = labels.map((date) => {
      return moment(date).format('MMM YYYY');
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
          },
        },
      },
    });
  },

  topSourceDealOpenOpp() {
    const rawData: UnknownObject[] = this.data.dataset.leadsource_distribution;
    // Will be used when query will be fixed
    // const dataAndPointRadius = rawData.map((row: any) => {
    //   const value: number = row['Conversion Rate to Deal'].toFixed(2);
    //   return value
    // });
    const ctx = document
      .getElementById('top_sources_deals_open')
      .getContext('2d');

    const events = extractTypes(rawData, 'Lead Source');

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: events,
        datasets: [
          {
            label: 'Conversion Rate to Deal',
            data: rawData.map((row: any) => row['Conversion Rate to Deal']),
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 12, // Replace by dataAndPointRadius, when query are fixed
            backgroundColor: COLORS[1],
            borderColor: COLORS[9],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'Deals Opened',
            data: rawData.map((row: any) => row['Deals Opened']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            formatter(value: string) {
              return numeral(value).format('0,0.00');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  topSourceDealClosedWon() {
    const rawData: UnknownObject[] = this.data.dataset.leadsource_distribution;
    // Will be used when query will be fixed
    // const dataAndPointRadius = rawData.map((row: any) => {
    //   const value: number = row['Conversion Rate to Closed Won'].toFixed(2);
    //   return value;
    // });
    const ctx = document
      .getElementById('top_sources_deals_closed')
      .getContext('2d');

    const events = extractTypes(rawData, 'Lead Source');

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: events,
        datasets: [
          {
            label: 'Conversion Rate to Closed Won',
            data: rawData.map(
              (row: any) => row['Conversion Rate to Closed Won']
            ),
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 12, // Replace by dataAndPointRadius when query will be fixed
            backgroundColor: COLORS[1],
            borderColor: COLORS[9],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'Deals Closed Won',
            data: rawData.map((row: any) => row['Deals Closed Won']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                precision: 0,
                beginAtZero: true,
              } as any,
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            formatter(value: string) {
              return numeral(value).format('0,0.00');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  leadSourceFunnel() {
    const rawData: UnknownObject[] = this.data.dataset.leadsource_distribution;

    const data = rawData.map((row: any) => {
      return {
        lead_source: row['Lead Source'],
        contacts_created: row['Contacts created'],
        deals_opened: row['Deals Opened'],
        conversion_rate_to_deal: numeral(row['Conversion Rate to Deal']).format(
          '0.00'
        ),
        deals_closed_won: row['Deals Closed Won'],
        conversion_rate_to_closed_won: numeral(
          row['Conversion Rate to Closed Won']
        ).format('0.00'),
      };
    });

    $('#leadsources_funnel', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  dealClosed() {
    const rawData: UnknownObject[] = this.data.dataset.deals_amount;

    const dates: string[] = [
      ...new Set(
        rawData.reduce((acc, row) => acc.concat(row['Created date']), [])
      ),
    ] as string[];
    const ctx = document.getElementById('deals_closed').getContext('2d');

    function getDataDeal(dealType: string) {
      const data = [];
      const sumByDate = rawData.reduce(
        (acc, row) =>
          acc.concat(
            row['Deals Closed Won with hs_closed_amount'] +
              row['Deals Closed Won without hs_closed_amount']
          ),
        []
      );
      const dataLabel = rawData.map((row: any) => row[dealType]);

      for (let i = 0; i < sumByDate.length; i += 1) {
        data.push((dataLabel[i] / sumByDate[i]) * 100);
      }
      return data;
    }

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Deals Closed Won with hs_closed_amount',
            data: getDataDeal('Deals Closed Won with hs_closed_amount'),
            backgroundColor: COLORS[0],
          },
          {
            label: 'Deals Closed Won without hs_closed_amount',
            data: getDataDeal('Deals Closed Won without hs_closed_amount'),
            backgroundColor: COLORS[3],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
          },
        },
      },
    });
  },

  dealMinMax() {
    const rawData: UnknownObject[] = this.data.dataset.deals_amount;

    const data = rawData.map((row: any) => {
      return {
        created_date: row['Created date'],
        deals_opened_with_a_hs_closed_amount:
          row['Deals opened with a hs_closed_amount'],
        deals_opened_without_a_hs_closed_amount:
          row['Deals opened without a hs_closed_amount'],
        deals_opened_min_hs_closed_amount:
          row['Deals Opened min hs_closed_amount'],
        deals_opened_max_hs_closed_amount:
          row['Deals Opened max hs_closed_amount'],
        deals_closed_won_with_hs_closed_amount:
          row['Deals Closed Won with hs_closed_amount'],
        deals_closed_won_without_hs_closed_amount:
          row['Deals Closed Won without hs_closed_amount'],
        deals_closed_won_min_hs_closed_amount:
          row['Deals Closed Won min hs_closed_amount'],
        deals_closed_won_max_hs_closed_amount:
          row['Deals Closed Won max hs_closed_amount'],
        deals_closed_won_avg_hs_closed_amount:
          row['Deals Closed Won avg hs_closed_amount'],
      };
    });

    $('#deals_min_max', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  contactsQuality() {
    const rawData: UnknownObject[] = this.data.dataset.contacts_data_quality;

    const data = rawData.map((row: any) => {
      return {
        created_date: row['Created date'],
        duplicated_contacts_id: row['Duplicated Contacts id'],
        duplicated_contacts_email: row['Duplicated Contacts email'],
        contacts_without_email: row['Contacts without email'],
        closed_won_contacts_without_deal_creation_date:
          row['Closed Won Contacts without Deal creation date'],
      };
    });

    $('#contacts_quality', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  dealsQuality() {
    const rawData: UnknownObject[] = this.data.dataset.deals_data_quality;

    const data = rawData.map((row: any) => {
      return {
        date: row.Date,
        deal_without_a_contact: row['Deal without a Contact'],
        percent_deal_without_a_contact: row['%Deal without a Contact'],
        deal_without_a_companyid: row['Deal without a CompanyId'],
        percent_deal_without_a_company: row['%Deal without a Company'],
      };
    });

    $('#deals_quality', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  event_type_distribution() {
    const data: UnknownObject[] = this.data.dataset.event_type_distribution;
    const target = document.getElementById('event_type_distribution');
    if (!data || !target) return;
    const ctx = target.getContext('2d');

    const allDates = [
      ...new Set(
        data.map((row) => moment(row['event timestamp']).format('MMM YYYY'))
      ),
    ];
    const allEvents = [...new Set(data.map((row) => row.event))];

    const datasets: UnknownObject[] = allEvents.map((event, i) => ({
      label: event,
      data: allDates.map((date) => {
        const evt = data.find(
          (row) =>
            row.event === event &&
            moment(row['event timestamp']).format('MMM YYYY') === date
        );
        return {
          x: date,
          y: evt ? evt['total events logged'] : 0,
        };
      }),
      backgroundColor: COLORS[i],
    }));

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: allDates,
        datasets,
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        legend: {
          position: 'right',
        },
        plugins: {
          datalabels: {
            display: false,
            color: 'white',
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, d) {
              let label = d.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  monthly_active_contacts() {
    const data: UnknownObject[] = this.data.dataset.monthly_active_contacts;
    const target = document.getElementById('monthly_active_contacts');

    if (!data || !target) return;
    const ctx = target.getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const values: number[] = [];
    data.forEach((row) => {
      values.push(row['active contacts']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'active contacts',
            data: values,
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: {
          position: 'top',
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            rotation: 270,
            color: 'white',
            display: false,
            formatter(value) {
              return numeral(value).format('0,0');
            },
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, d) {
              let label = d.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  most_frequent_hubspot_forms_submissions() {
    const rawData: UnknownObject[] = this.data.dataset
      .most_frequent_hubspot_forms_submissions;
    if (!rawData) return;

    const data = rawData.map((row) => {
      return {
        title: row.title,
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#most_frequent_hubspot_forms_submissions', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  total_form_submissions_and_form_titles() {
    const data: UnknownObject[] = this.data.dataset
      .total_form_submissions_and_form_titles;
    const target = document.getElementById(
      'total_form_submissions_and_form_titles'
    );
    if (!data || !target) return;
    const ctx = target.getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const distinct_title_dataset: number[] = [];
    data.forEach((row) => {
      distinct_title_dataset.push(row['distinct title']);
    });

    const total_events_logged_dataset: number[] = [];
    data.forEach((row) => {
      total_events_logged_dataset.push(row['total events logged']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'distinct title',
            data: distinct_title_dataset,
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: 16,
            backgroundColor: COLORS[3],
            showLine: false,
            yAxisID: 'B',
          },
          {
            label: 'total events logged',
            data: total_events_logged_dataset,
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  total_events_clicks() {
    const data: UnknownObject[] = this.data.dataset.total_events_clicks;
    const target = document.getElementById('total_events_clicks');
    if (!data || !target) return;
    const ctx = target.getContext('2d');

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    const values: number[] = [];
    data.forEach((row) => {
      values.push(row['total events logged']);
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'total events logged',
            data: values,
            backgroundColor: COLORS[0],
          },
        ],
      },
      options: {
        legend: {
          position: 'top',
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            rotation: 270,
            color: 'white',
            display: false,
            formatter(value) {
              return numeral(value).format('0,0');
            },
          },
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, d) {
              let label = d.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  total_events_status() {
    const data: UnknownObject[] = this.data.dataset.total_events_status;
    const target = document.getElementById('total_events_status');
    if (!data || !target) return;
    const ctx = target.getContext('2d');

    const COLORS_STATUS = [COLORS[0], COLORS[3], COLORS[1]]; // the PRD had those colors in that order

    const dates: string[] = [];
    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
    });

    function getDataStatus(status: string) {
      const res: number[] = [];

      data.forEach((row) => {
        if (
          row.a_portalsubscriptionstatus === status ||
          (status === 'null' && !row.a_portalsubscriptionstatus)
        ) {
          res.push(row['total events logged']);
        }
      });
      return res;
    }

    const STATUS = ['UNSUBSCRIBED', 'SUBSCRIBED', 'null'];
    const dataset = [];

    for (let i = 0; i < STATUS.length; i += 1) {
      dataset.push({
        label: STATUS[i],
        data: getDataStatus(STATUS[i]),
        backgroundColor: COLORS_STATUS[i],
      });
    }

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: dataset,
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        legend: {
          position: 'right',
        },
        plugins: {
          datalabels: {
            display: false,
            color: 'white',
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, d) {
              let label = d.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  render() {
    const dataset = this.data?.dataset;
    const withEvents =
      !!dataset?.event_type_distribution &&
      !!dataset?.monthly_active_contacts &&
      !!dataset?.most_frequent_hubspot_forms_submissions &&
      !!dataset?.total_form_submissions_and_form_titles &&
      !!dataset?.total_events_clicks &&
      !!dataset?.total_events_status;
    const html = template({
      tenant: this.tenant,
      noData: typeof this.data === 'undefined',
      updatedAt: this.updatedAt,
      withEvents,
    });
    this.$el.html(html);
    return this;
  },
});
