import React from 'react';
import { Table } from 'react-bootstrap';
import {
  capitalizeEachWord,
  compareCustomerFits,
  compareLtb,
  getBackgroundColorForLeadGrade,
} from '../../utils';

type LeadGradeModelComponentProps = {
  tenant: number;
  leadGradeMatrix: UnknownObject;
};

export default class LeadGradeModelComponent extends React.Component<
  LeadGradeModelComponentProps,
  {}
> {
  render() {
    const { leadGradeMatrix } = this.props;
    return (
      <div>
        <h2>Lead Grade</h2>
        <p>
          The Lead Grade segment is the combination of the Customer Fit segment
          and Likelihood to Buy segment according to the matrix below. This
          matrix has been defined to optimize the model performance but can be
          changed to increase or decrease the volume of A, B, C, D or Es to
          accomodate your workflow.
        </p>
        {leadGradeMatrix && Object.keys(leadGradeMatrix).length && (
          <div>
            <Table hover bordered responsive>
              <thead>
                <tr>
                  <th colSpan={2} rowSpan={2} />
                  <th colSpan={4} className="text-center">
                    <h4>Customer Fit</h4>
                  </th>
                </tr>
                <tr className="text-center">
                  <th>Very Good</th>
                  <th>Good</th>
                  <th>Medium</th>
                  <th>Low</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(leadGradeMatrix)
                  .sort(compareLtb)
                  .reverse()
                  .map((ltbSegment, i) => (
                    <tr key={`row_${ltbSegment}`}>
                      {i === 0 && (
                        <td rowSpan={4} style={{ verticalAlign: 'middle' }}>
                          <h4>Likelihood to Buy</h4>
                        </td>
                      )}
                      <td>
                        <b>{capitalizeEachWord(ltbSegment)}</b>
                      </td>
                      {Object.keys(leadGradeMatrix[ltbSegment])
                        .sort(compareCustomerFits)
                        .reverse()
                        .map((fitSegment) => {
                          return (
                            <td
                              key={`col_${ltbSegment}_${fitSegment}`}
                              className="text-center"
                              style={{
                                backgroundColor: getBackgroundColorForLeadGrade(
                                  leadGradeMatrix[ltbSegment][fitSegment]
                                ),
                              }}
                            >
                              {leadGradeMatrix[ltbSegment][fitSegment]}
                            </td>
                          );
                        })}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}
