var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (credentialsFound, displayPull, displayPush, integration, integrationPullActive, integrationPushActive, isAccountConfigured, isUserAdmin, pullConfigData, pushConfigData, pushFilter, tenant) {
      pug_html = pug_html + "\u003Cdiv\u003E\u003Cscript type=\"text\u002Ftemplate\" id=\"tpl_main\"\u003E\u003Cdiv id=\"tpl_pull_push\"\u003E";
if (credentialsFound && ((integrationPullActive && pullConfigData && pullConfigData.length) || (integrationPushActive && pushConfigData && pushConfigData.length))) {
pug_html = pug_html + "\u003Cdiv class=\"row justify-content-center mt-4\"\u003E\u003Cdiv class=\"col-8\"\u003E\u003Cdiv class=\"card box-shadow-3d mb-3\"\u003E\u003Cdiv class=\"card-header\"\u003E\u003Ch3\u003EFields mapping\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cp\u003EYou'll be able to see your push and\u002For pull fields here:\u003C\u002Fp\u003E\u003Cdiv class=\"row mt-1\"\u003E\u003Cdiv class=\"col-xl-12 p-1\"\u003E\u003Cdiv class=\"p-1\"\u003E";
if (integrationPushActive && integrationPullActive) {
pug_html = pug_html + "\u003Cdiv class=\"btn-group btn-group-toggle\"\u003E\u003Ca" + (pug.attr("class", pug.classes([`${displayPush ? 'active' : ''} btn btn-outline-secondary btn-lg`], [true]), false, true)+" id=\"pushToggle\" style=\"text-decoration:none\"") + "\u003EPush\u003C\u002Fa\u003E\u003Ca" + (pug.attr("class", pug.classes([`${displayPull ? 'active' : ''} btn btn-outline-secondary btn-lg`], [true]), false, true)+" id=\"pullToggle\" style=\"text-decoration:none\"") + "\u003EPull\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (displayPush && integrationPushActive && pushConfigData && pushConfigData.length > 0) {
pug_html = pug_html + "\u003Cdiv\u003E\u003Cp\u003EThis table represents the correlation between internal MadKudu fields and your " + (pug.escape(null == (pug_interp = integration[0].toUpperCase() + integration.slice(1)) ? "" : pug_interp)) + " fields.\u003Ca" + (pug.attr("href", `https://app.madkudu.com/org/${tenant}/push/overview`, true, true)) + "\u003E To edit the configuration, please follow this link to the push configuration.\u003C\u002Fa\u003E\u003C\u002Fp\u003E";
// iterate pushConfigData
;(function(){
  var $$obj = pushConfigData;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var board = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"card\" style=\"min-width: 90%;\"\u003E\u003Cdiv class=\"card-body\"\u003E";
if (pushConfigData.length > 0) {
pug_html = pug_html + "\u003Ch3 class=\"text-center card-title\"\u003E" + (pug.escape(null == (pug_interp = board.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"table-responsive-lg\"\u003E\u003Ctable class=\"table mt-3 mb-3 mx-auto table-bordered table-hover\"\u003E\u003Cthead\u003E\u003Ctr\u003E";
// iterate board.allValueTypes
;(function(){
  var $$obj = board.allValueTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var valueType = $$obj[pug_index1];
pug_html = pug_html + "\u003Cth" + (pug.attr("class", pug.classes([`table_element_${valueType} text-center`], [true]), false, true)+" colspan=\"1\"") + "\u003E" + (pug.escape(null == (pug_interp = valueType === 'MadKudu' ? valueType : `${integration[0].toUpperCase() + integration.slice(1)} > ${valueType}`) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var valueType = $$obj[pug_index1];
pug_html = pug_html + "\u003Cth" + (pug.attr("class", pug.classes([`table_element_${valueType} text-center`], [true]), false, true)+" colspan=\"1\"") + "\u003E" + (pug.escape(null == (pug_interp = valueType === 'MadKudu' ? valueType : `${integration[0].toUpperCase() + integration.slice(1)} > ${valueType}`) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E\u003Ctbody\u003E";
// iterate board.rows
;(function(){
  var $$obj = board.rows;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var row = $$obj[pug_index2];
pug_html = pug_html + "\u003Ctr\u003E";
// iterate board.allValueTypes
;(function(){
  var $$obj = board.allValueTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var rowValueType = $$obj[pug_index3];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var rowValueType = $$obj[pug_index3];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var row = $$obj[pug_index2];
pug_html = pug_html + "\u003Ctr\u003E";
// iterate board.allValueTypes
;(function(){
  var $$obj = board.allValueTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var rowValueType = $$obj[pug_index4];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var rowValueType = $$obj[pug_index4];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Fthead\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var board = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"card\" style=\"min-width: 90%;\"\u003E\u003Cdiv class=\"card-body\"\u003E";
if (pushConfigData.length > 0) {
pug_html = pug_html + "\u003Ch3 class=\"text-center card-title\"\u003E" + (pug.escape(null == (pug_interp = board.title) ? "" : pug_interp)) + "\u003C\u002Fh3\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"table-responsive-lg\"\u003E\u003Ctable class=\"table mt-3 mb-3 mx-auto table-bordered table-hover\"\u003E\u003Cthead\u003E\u003Ctr\u003E";
// iterate board.allValueTypes
;(function(){
  var $$obj = board.allValueTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var valueType = $$obj[pug_index5];
pug_html = pug_html + "\u003Cth" + (pug.attr("class", pug.classes([`table_element_${valueType} text-center`], [true]), false, true)+" colspan=\"1\"") + "\u003E" + (pug.escape(null == (pug_interp = valueType === 'MadKudu' ? valueType : `${integration[0].toUpperCase() + integration.slice(1)} > ${valueType}`) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var valueType = $$obj[pug_index5];
pug_html = pug_html + "\u003Cth" + (pug.attr("class", pug.classes([`table_element_${valueType} text-center`], [true]), false, true)+" colspan=\"1\"") + "\u003E" + (pug.escape(null == (pug_interp = valueType === 'MadKudu' ? valueType : `${integration[0].toUpperCase() + integration.slice(1)} > ${valueType}`) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E\u003Ctbody\u003E";
// iterate board.rows
;(function(){
  var $$obj = board.rows;
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var row = $$obj[pug_index6];
pug_html = pug_html + "\u003Ctr\u003E";
// iterate board.allValueTypes
;(function(){
  var $$obj = board.allValueTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index7 = 0, $$l = $$obj.length; pug_index7 < $$l; pug_index7++) {
        var rowValueType = $$obj[pug_index7];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index7 in $$obj) {
      $$l++;
      var rowValueType = $$obj[pug_index7];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var row = $$obj[pug_index6];
pug_html = pug_html + "\u003Ctr\u003E";
// iterate board.allValueTypes
;(function(){
  var $$obj = board.allValueTypes;
  if ('number' == typeof $$obj.length) {
      for (var pug_index8 = 0, $$l = $$obj.length; pug_index8 < $$l; pug_index8++) {
        var rowValueType = $$obj[pug_index8];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index8 in $$obj) {
      $$l++;
      var rowValueType = $$obj[pug_index8];
pug_html = pug_html + "\u003Ctd" + (pug.attr("class", pug.classes([`table_element_${rowValueType}`], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = row[rowValueType]) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Fthead\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

if (!isAccountConfigured && integration === 'salesforce') {
pug_html = pug_html + "\u003Cdiv class=\"card-body\"\u003E\u003Ch3 class=\"text-center card-title\"\u003EAccount\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (isUserAdmin || (!isUserAdmin && typeof pushFilter === 'string' && pushFilter.length > 0)) {
pug_html = pug_html + "\u003Cdiv class=\"mt-3 mb-2 col-xl-7 p-0\"\u003E\u003Cform id=\"pushFilterForm\"\u003E\u003Ch4 class=\"mb-1\"\u003EFilter applied to pushed data\u003C\u002Fh4\u003E";
if (isUserAdmin) {
pug_html = pug_html + "\u003Ctextarea class=\"form-control\" id=\"pushFilterText\" cols=\"30\" rows=\"5\" style=\"width: 100%\"\u003E" + (pug.escape(null == (pug_interp = pushFilter) ? "" : pug_interp)) + "\u003C\u002Ftextarea\u003E\u003Cbutton class=\"btn btn-success mt-1\" type=\"submit\"\u003ESave\u003C\u002Fbutton\u003E";
}
else
if (!isUserAdmin && pushFilter) {
pug_html = pug_html + "\u003Cdiv class=\"card\" style=\"min-width: 600px; height: 200px; padding: 10px; overflow-y: scroll;\"\u003E\u003Cp\u003E" + (pug.escape(null == (pug_interp = pushFilter) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E";
}
}
if (displayPull && integrationPullActive && pullConfigData && pullConfigData.length > 0) {
pug_html = pug_html + "\u003Cp\u003EThese are the important fields we gather from your users to enable us to score your leads.\u003Cdiv class=\"card\" style=\"min-width: 90%;\"\u003E\u003Cdiv class=\"card-body\"\u003E";
// iterate pullConfigData
;(function(){
  var $$obj = pullConfigData;
  if ('number' == typeof $$obj.length) {
      for (var pug_index9 = 0, $$l = $$obj.length; pug_index9 < $$l; pug_index9++) {
        var board = $$obj[pug_index9];
pug_html = pug_html + "\u003Cdiv class=\"col-xl-6 d-inline-flex\"\u003E\u003Ctable class=\"table mt-3 mb-3 mx-auto table-bordered table-hover\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth class=\"text-center\" colspan=\"1\" style=\"width: 100%; display: flex; justify-content: space-between;\"\u003E\u003Cdiv class=\"empty\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"name-table\"\u003E" + (pug.escape(null == (pug_interp = board.title[0].toUpperCase() + board.title.slice(1) + ' attributes') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"empty\"\u003E";
if (integration === 'salesforce' || integration === 'hubspot' || integration === 'snowflake' || integration === 'bigquery') {
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-primary btn-configure\""+pug.attr("id", 'btn-'+board.title, true, true)) + "\u003EConfigure\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fth\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E";
// iterate board.fields
;(function(){
  var $$obj = board.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index10 = 0, $$l = $$obj.length; pug_index10 < $$l; pug_index10++) {
        var field = $$obj[pug_index10];
pug_html = pug_html + "\u003Ctr\u003E";
if (field.ignore) {
pug_html = pug_html + "\u003Ctd style=\"color: red;\"\u003E" + (pug.escape(null == (pug_interp = `${field.name} - This field is ignored`) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = field.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index10 in $$obj) {
      $$l++;
      var field = $$obj[pug_index10];
pug_html = pug_html + "\u003Ctr\u003E";
if (field.ignore) {
pug_html = pug_html + "\u003Ctd style=\"color: red;\"\u003E" + (pug.escape(null == (pug_interp = `${field.name} - This field is ignored`) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = field.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index9 in $$obj) {
      $$l++;
      var board = $$obj[pug_index9];
pug_html = pug_html + "\u003Cdiv class=\"col-xl-6 d-inline-flex\"\u003E\u003Ctable class=\"table mt-3 mb-3 mx-auto table-bordered table-hover\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth class=\"text-center\" colspan=\"1\" style=\"width: 100%; display: flex; justify-content: space-between;\"\u003E\u003Cdiv class=\"empty\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"name-table\"\u003E" + (pug.escape(null == (pug_interp = board.title[0].toUpperCase() + board.title.slice(1) + ' attributes') ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"empty\"\u003E";
if (integration === 'salesforce' || integration === 'hubspot' || integration === 'snowflake' || integration === 'bigquery') {
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-primary btn-configure\""+pug.attr("id", 'btn-'+board.title, true, true)) + "\u003EConfigure\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fth\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E";
// iterate board.fields
;(function(){
  var $$obj = board.fields;
  if ('number' == typeof $$obj.length) {
      for (var pug_index11 = 0, $$l = $$obj.length; pug_index11 < $$l; pug_index11++) {
        var field = $$obj[pug_index11];
pug_html = pug_html + "\u003Ctr\u003E";
if (field.ignore) {
pug_html = pug_html + "\u003Ctd style=\"color: red;\"\u003E" + (pug.escape(null == (pug_interp = `${field.name} - This field is ignored`) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = field.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index11 in $$obj) {
      $$l++;
      var field = $$obj[pug_index11];
pug_html = pug_html + "\u003Ctr\u003E";
if (field.ignore) {
pug_html = pug_html + "\u003Ctd style=\"color: red;\"\u003E" + (pug.escape(null == (pug_interp = `${field.name} - This field is ignored`) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
else {
pug_html = pug_html + "\u003Ctd\u003E" + (pug.escape(null == (pug_interp = field.name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fscript\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "credentialsFound" in locals_for_with ?
        locals_for_with.credentialsFound :
        typeof credentialsFound !== 'undefined' ? credentialsFound : undefined, "displayPull" in locals_for_with ?
        locals_for_with.displayPull :
        typeof displayPull !== 'undefined' ? displayPull : undefined, "displayPush" in locals_for_with ?
        locals_for_with.displayPush :
        typeof displayPush !== 'undefined' ? displayPush : undefined, "integration" in locals_for_with ?
        locals_for_with.integration :
        typeof integration !== 'undefined' ? integration : undefined, "integrationPullActive" in locals_for_with ?
        locals_for_with.integrationPullActive :
        typeof integrationPullActive !== 'undefined' ? integrationPullActive : undefined, "integrationPushActive" in locals_for_with ?
        locals_for_with.integrationPushActive :
        typeof integrationPushActive !== 'undefined' ? integrationPushActive : undefined, "isAccountConfigured" in locals_for_with ?
        locals_for_with.isAccountConfigured :
        typeof isAccountConfigured !== 'undefined' ? isAccountConfigured : undefined, "isUserAdmin" in locals_for_with ?
        locals_for_with.isUserAdmin :
        typeof isUserAdmin !== 'undefined' ? isUserAdmin : undefined, "pullConfigData" in locals_for_with ?
        locals_for_with.pullConfigData :
        typeof pullConfigData !== 'undefined' ? pullConfigData : undefined, "pushConfigData" in locals_for_with ?
        locals_for_with.pushConfigData :
        typeof pushConfigData !== 'undefined' ? pushConfigData : undefined, "pushFilter" in locals_for_with ?
        locals_for_with.pushFilter :
        typeof pushFilter !== 'undefined' ? pushFilter : undefined, "tenant" in locals_for_with ?
        locals_for_with.tenant :
        typeof tenant !== 'undefined' ? tenant : undefined));
    ;;return pug_html;};
module.exports = template;