import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import StripeFiltersForm from '../../../../../models/filters_forms/StripeFiltersForm';

type StripeSqoFormComponentProps = {
  handleSqoAmountThreshold: (c: string) => void;
  filterForm: StripeFiltersForm;
};

export default class StripeSqoFormComponent extends React.Component<
  StripeSqoFormComponentProps,
  {}
> {
  constructor(props: StripeSqoFormComponentProps) {
    super(props);

    this.handleSqoAmountThreshold = this.handleSqoAmountThreshold.bind(this);
  }

  handleSqoAmountThreshold(e: any) {
    this.props.handleSqoAmountThreshold(e.target.value);
  }

  render() {
    const { sqoAmountThreshold } = this.props.filterForm;
    return (
      <div>
        <FormGroup controlId="stripe_SQO_amount_threshold">
          <ControlLabel>Minimum amount paid for SQO conversions</ControlLabel>
          <FormControl
            name="stripe_SQO_amount_threshold"
            type="number"
            value={sqoAmountThreshold}
            onChange={this.handleSqoAmountThreshold}
          />
          <FormControl.Feedback />
        </FormGroup>
      </div>
    );
  }
}
