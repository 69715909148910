import Backbone from 'backbone';
import axios from 'axios';
import Swal from 'sweetalert2';
import app from '../app';
import get_query_parameter from './get_query_parameter';
import populate_inputs from './populate_inputs';
import { generateReCaptchaV3Token } from './check_recaptcha';
import { isSafeRedirect } from './check_redirect';

const template = require('./login.pug');

export default Backbone.View.extend({
  el: '#main',
  events: {
    'click #btn_login': 'login',
    'click #btn_login_google': 'login_google',
    'click #btn_login_okta': 'login_okta',
  },

  render() {
    const params: any = ['redirect', 'ref', 'reset', 'recover', 'sso'].reduce(
      (acc: any, param: string) => {
        acc[param] = get_query_parameter(param);
        return acc;
      },
      {}
    );

    let signupUrl = params.redirect
      ? `/signup?redirect=${encodeURIComponent(params.redirect)}`
      : '/signup';
    // if the source is present in the URL, make sure we pass it on to the signup page
    if (params.ref) {
      signupUrl += `&ref=${params.ref}`;
    }
    this.$el.html(template({ is_sso: this.is_sso, signupUrl }));
    populate_inputs();

    if (params.reset) {
      app.notifications.info(
        'Password reset successful. Please log in now with your new password.',
        { dismiss_timeout: 5000 }
      );
    } else if (params.recover) {
      app.notifications.info(
        'If an account exists for the supplied email address, a password reset email will be sent shortly.'
      );
    } else if (params.sso && params.sso === 'unauthorized') {
      Swal.fire(
        'Oooops',
        'You do not have permission to access MadKudu. Please ask your IT admin to grant you permission to this app',
        'error'
      );
    }
    return this;
  },

  parse_form() {
    const form_content = $('form#form_login');
    const body: UnknownObject = {};
    $.each(form_content.serializeArray(), function (index, field) {
      body[field.name] = field.value;
      // eslint-disable-next-line
      field.value = ''; // https://app.asana.com/0/33091180320398/1118497934278433/f
    });
    return body;
  },

  async login(e: any) {
    e.preventDefault();
    const ref = get_query_parameter('ref');
    const form_data = this.parse_form();
    form_data.ref = ref;
    form_data['g-recaptcha-response'] = await generateReCaptchaV3Token();
    try {
      const {
        data,
      }: {
        data: { tenant: { _id: number } };
      } = await axios.post(`${BONGO_URL}/v1/login`, form_data);

      if (data && data.tenant && data.tenant._id) {
        app.session.store(data);
        const redirect = get_query_parameter('redirect');
        if (redirect) {
          window.location.href = isSafeRedirect(redirect)
            ? redirect
            : '/error/404';
        } else {
          Backbone.history.navigate(`/org/${data.tenant._id}`, {
            trigger: true,
          });
        }
      } else {
        throw new Error('Weird');
      }
    } catch (err) {
      const { response = {} } = err;
      const { data = {} } = response;
      const { message } = data;
      if (
        message === 'invalid_login_enable_captcha' ||
        message === 'invalid_captcha'
      ) {
        this.$el.html(template({ is_sso: this.is_sso, captcha: true }));
      }
      if (message === 'sso_enforced') {
        this.$el.html(template({ is_sso: true, captcha: true }));
      }
      if (message === 'forbidden')
        return Swal.fire(
          'Not allowed to access the App',
          `<p>You are only allowed to access MadKudu Copilot.</p>
          <p>You can access it through one of the following links:</p>
          <ul class="list-unstyled">
            <li><a href="https://chromewebstore.google.com/detail/madkudu-copilot/jikgjincjapiebekopikcefbkeaimegj?pli=1" target="_blank">Chrome extension (recommended)</a></li>
            <li><a href="https://login.salesforce.com" target="_blank">Salesforce plugin</a></li>
            <li><a href="https://msi.madkudu.com" target="_blank">Standalone app</a></li>
          </ul>`,
          'error'
        );

      return Swal.fire('Oooops', 'Authentication Failed', 'error');
    }
  },

  login_google(e: any) {
    e.preventDefault();
    const redirect = get_query_parameter('redirect');
    if (redirect && isSafeRedirect(redirect)) {
      window.location.href = `${BONGO_URL}/v1/login/google?redirect=${encodeURIComponent(
        redirect
      )}`;
    } else {
      window.location.href = `${BONGO_URL}/v1/login/google`;
    }
  },

  login_okta(e: any) {
    e.preventDefault();
    window.location.href = `${BONGO_URL}/v1/login/okta`;
  },
});
