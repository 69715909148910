import Backbone from 'backbone';
import Chart from 'chart.js';
import { BackboneViewProps } from '../types';

const template = require('./results.pug');

export default Backbone.View.extend({
  events: {
    'click #btn_home': 'event_home',
  },
  initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
  },

  render_chart() {
    const ctx = document.getElementById('myLineChart').getContext('2d');
    const gradient = ctx.createLinearGradient(20, 200, 30, 900);
    gradient.addColorStop(0, '#1e77cc');
    gradient.addColorStop(1, 'rgba(126, 67, 245, .01)');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Without MadKudu',
            backgroundColor: 'rgba(0, 0, 0, .4)',
            borderColor: 'gray',
            data: [1000, 1200, 1300, 1400, 1600, 1800],
          },
          {
            label: 'With MadKudu',
            backgroundColor: 'rgba(30,119,204,.4)',
            borderColor: '#1e77cc',
            data: [null, null, 1300, 1700, 1900, 2300],
          },
        ],
        labels: ['March', 'April', 'May', 'June', 'July', 'August'],
      },
      options: {
        title: {
          display: true,
          text: 'Number of Leads Generated',
          fontSize: 20,
          fontColor: 'black',
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'lighter',
        },
      },
    });
  },

  event_home(event: any) {
    event.preventDefault();
    Backbone.history.navigate(`/org/${this.tenant}/dashboard`, true);
  },

  async render() {
    const html = template();
    await this.$el.html(html);
    this.render_chart();
    return this;
  },
});
