import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { MultiValue } from 'react-select';
import useFetchModels from '../profiles/hooks/api/useFetchModels';
import MultiSelect from './select/MultiSelect';
import { ModelSubset } from '../profiles/types';
import { MultiSelectOption } from './select/types';

interface CsvUploadComponentProps {
  title: string;
  tenant: number;
  postPath: string;
  shortInstruction: string;
  longInstruction: string;
}

const modelToOption = (model: ModelSubset): MultiSelectOption => ({
  label: model.name,
  value: String(model.modelId),
});

export default function CsvUploadComponent({
  title,
  tenant,
  postPath,
  shortInstruction,
  longInstruction,
}: CsvUploadComponentProps) {
  const [selectedModels, setSelectedModels] = useState<ModelSubset[]>([]);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
  });
  const { data: models, isFetching: isLoadingModels } = useFetchModels(tenant);

  const handleSelectedModelsChange = (
    newValues: MultiValue<MultiSelectOption>
  ) => {
    setSelectedModels(
      newValues.map((value) =>
        models.find((model) => model.modelId === Number(value.value))
      )
    );
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitting(true);
    setFormError(null);

    const data = new FormData();
    data.append('fileToUpload', acceptedFiles[0]);
    data.append(
      'models',
      JSON.stringify(
        selectedModels.map(({ modelId, name }) => ({ modelId, name }))
      )
    );

    try {
      await axios.post(postPath, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setFormSubmitted(true);
    } catch (error) {
      setFormError(error?.response?.data?.message || error.message);
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="card box-shadow-3d d-flex h-100 w-75 mt-3">
        <div
          className="card-header"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className="card-body">
          <p dangerouslySetInnerHTML={{ __html: longInstruction }} />

          <form className="mt-2" onSubmit={handleFormSubmit}>
            {formSubmitted && (
              <div className="alert alert-success" role="alert">
                Thank you! You'll receive an email containing a link to the
                results very soon.
              </div>
            )}
            {formError && (
              <div className="alert alert-danger" role="alert">
                {formError}
              </div>
            )}
            <div {...getRootProps({ className: 'drop-zone' })}>
              <input {...getInputProps()} />
              <p
                className="drop-zone-title"
                dangerouslySetInnerHTML={{ __html: shortInstruction }}
              />
              <p className="drop-zone-text">Drag and drop your file here.</p>
            </div>
            {(!!acceptedFiles.length || !!fileRejections.length) && (
              <aside className="mt-3">
                {!!acceptedFiles.length && (
                  <>
                    <h4>Uploaded file</h4>
                    <ul>
                      {acceptedFiles.map((file) => (
                        <li key={file.name}>
                          {file.name} - {file.size} bytes
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {!!fileRejections.length && (
                  <>
                    <h4>Rejected files</h4>
                    <ul>
                      {fileRejections.map(({ file, errors }) => (
                        <li key={file.name}>
                          {file.name} - {file.size} bytes
                          <ul>
                            {errors.map((e) => (
                              <li key={e.code}>{e.message}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </aside>
            )}
            <div className="form-group mt-3">
              <label className="font-weight-bold">Models</label>
              <p>
                You can choose the models you want to use for bulk scoring. If
                no models were selected we will use the standard model.
              </p>
              <MultiSelect
                placeholder="Select one or multiple models"
                disabled={isLoadingModels}
                options={models?.map(modelToOption)}
                values={selectedModels.map(modelToOption)}
                onChange={(newValues) => {
                  handleSelectedModelsChange(newValues);
                }}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!acceptedFiles.length || formSubmitting}
            >
              {formSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
