export const OVERVIEW_STEP = 'overview';

export const EDIT_VIEW_STEP = 'model';
export const CONNECTOR_STEP = 'connector';
export const DATA_MAPPING_STEP = 'dataMapping';
export const REVIEW_CONFIGURATION_STEP = 'review';

export const ADMIN_VIEW_STEP = 'admin';

export const REVIEW_YAML_STEP = 'reviewYamlJson';

export const FORCE_PUSH_STEP = 'force';

export const EDIT_CONFIGURATION_STEPS = [
  EDIT_VIEW_STEP,
  CONNECTOR_STEP,
  DATA_MAPPING_STEP,
  REVIEW_CONFIGURATION_STEP,
];
