import {
  AudiencePerformanceData,
  DatasetDataModel,
  ModelBase,
  ModelTypes,
} from '../../types';

export default class ModelDataModel {
  id: string;

  modelId: number;

  tenant: number;

  type: ModelTypes;

  name: string;

  notes: string;

  live: boolean;

  realtime: boolean;

  batch: boolean;

  dataset: DatasetDataModel;

  audience_performance_data: AudiencePerformanceData;

  lead_grade_audience_performance_data: AudiencePerformanceData;

  charts: string[];

  labels: string[];

  created_at: number;

  updated_at: number;

  released_at: number;

  last_deployed_at: number;

  training: UnknownObject;

  secondary_model: boolean;

  is_primary: boolean;

  recall: number;

  base: ModelBase;

  constructor(other?: ModelDataModel) {
    if (other) {
      this.name = other.name;
      this.id = other.id;
      this.modelId = other.modelId;
      this.tenant = other.tenant;
      this.type = other.type;
      this.notes = other.notes;
      this.live = other.live;
      this.realtime = other.realtime;
      this.batch = other.batch;
      this.dataset = other.dataset;
      this.audience_performance_data = other.audience_performance_data;
      this.lead_grade_audience_performance_data =
        other.lead_grade_audience_performance_data;
      this.charts = other.charts;
      this.labels = other.labels;
      this.created_at = other.created_at;
      this.updated_at = other.updated_at;
      this.released_at = other.released_at;
      this.last_deployed_at = other.last_deployed_at;
      this.training = other.training;
      this.secondary_model = other.secondary_model;
      this.is_primary = other.is_primary;
      this.recall = other.recall;
      this.base = other.base;
    } else {
      this.name = 'default';
      this.secondary_model = false;
      this.is_primary = true;
      this.base = 'points';
    }
  }
}
