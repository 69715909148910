import { z } from 'zod';

export const zoomInfoCredentialsSchema = z.object({
  privateKey: z
    .string()
    .min(1, { message: 'Please provide a private key.' })
    .refine(
      (v) =>
        v.startsWith('-----BEGIN PRIVATE KEY-----') &&
        v.endsWith('-----END PRIVATE KEY-----'),
      {
        message:
          'Private key must start with "-----BEGIN PRIVATE KEY-----" and end with "-----END PRIVATE KEY-----" headers.',
      }
    ),
  username: z.string().min(1, {
    message: 'Please provide a username.',
  }),
  clientId: z.string().min(1, {
    message: 'Please provide a client ID.',
  }),
});

export type ZoomInfoCredentials = z.infer<typeof zoomInfoCredentialsSchema>;
