import Mn from 'backbone.marionette';
import $ from 'jquery';
import app from './app';
import GdprRequestView from './public_pages/gdpr_request';
import SimulatorView from './public_pages/simulator';

const PublicController = (Mn.Object as any).extend({
  initialize() {},

  change_title(title: string) {
    const newTitle = title ? `${title} | MadKudu` : 'MadKudu';
    $(document).attr('title', newTitle);
  },

  track_page(title: string) {
    return title && window.analytics && window.analytics.page(title);
  },

  transition_view(View: any, params = {}, options: { title?: string } = {}) {
    const { title } = options;
    this.track_page(title);
    this.change_title(title);
    app.root.detachChildView('main'); // detach the existing view if it exists
    // eslint-disable-next-line
    app.root.showChildView(
      'main', // eslint-disable-next-line
      View.default ? new View.default(params) : new View(params)
    );
  },

  simulator() {
    this.transition_view(SimulatorView, undefined, { title: 'Simulator' });
  },

  gdpr_request() {
    this.transition_view(GdprRequestView, undefined, { title: 'GDPR request' });
  },
});

export default Mn.AppRouter.extend({
  appRoutes: {
    simulator: 'simulator',
    'data-privacy-management': 'gdpr_request',
  },

  initialize() {
    this.controller = new PublicController({});
  },
});
