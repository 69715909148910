import numeral from 'numeral';
import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';
import { dataFormatter } from '../../utils';
import RevenueDistributionComponent from '../guideCards/RevenueDistributionComponent';

type PredictedValueChartComponentProps = {
  predictedValue: UnknownObject;
  dates: string[];
  labels: string[];
};

export default class PredictedValueChartComponent extends Component<
  PredictedValueChartComponentProps,
  {}
> {
  render() {
    const { predictedValue, dates, labels } = this.props;
    const data = dataFormatter(predictedValue, dates, labels, 'customer_fit');
    return (
      <div>
        <div className="card box-shadow-3d mt-3">
          <h4 className="card-header">Revenue Distribution</h4>
          <div className="card-body">
            <Bar
              data={data}
              options={{
                title: {
                  display: true,
                  text: 'Closed Won Amount Distribution ($)',
                  fontSize: 16,
                  position: 'top',
                },
                legend: { position: 'bottom' },
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                    },
                  ],
                },
                plugins: {
                  datalabels: {
                    color: 'white',
                    display() {
                      return false;
                    },
                    formatter(value: string) {
                      return numeral(value).format('0,0');
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <RevenueDistributionComponent />
      </div>
    );
  }
}
