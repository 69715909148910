import EventMappingConnectorsNames from '../../enums/EventMappingConnectorsNames';
import EventsTags from '../../enums/EventsTags';

export default class RelevantEventsInformationModel {
  connector: EventMappingConnectorsNames;

  events: any[];

  tag: EventsTags;

  disabledConnectors: string[];

  madMlQuery: string;

  constructor(
    connector?: EventMappingConnectorsNames,
    events?: any[],
    tag?: EventsTags,
    disabledConnectors?: string[],
    madMlQuery?: string
  ) {
    if (connector) {
      this.connector = connector;
    } else {
      this.connector = EventMappingConnectorsNames.hubspot;
    }
    if (events) {
      this.events = events;
    } else {
      this.events = [];
    }
    if (tag) {
      this.tag = tag;
    } else {
      this.tag = EventsTags.Draft;
    }
    if (disabledConnectors) {
      this.disabledConnectors = disabledConnectors;
    } else {
      this.disabledConnectors = [];
    }
    if (madMlQuery) {
      this.madMlQuery = madMlQuery;
    } else {
      this.madMlQuery = '';
    }
  }
}
