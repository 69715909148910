import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import app from '../../app';
import { BackboneViewProps } from '../../types';
import { UserServiceModel } from './types';
import Users from './components/Users';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.session = app.session;
    const user: UserServiceModel = this.session.get('user');
    this.isSuperKudu = user.superKudu;
    this.isAdmin = user.role === 'admin';
    this.render();
    // wait until html content is rendered
    await this.sleep(2000);
    // inject react function into the DOM
    this.renderUsersMainComponent();
  },

  renderUsersMainComponent() {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <Users
          tenant={this.tenant}
          isSuperKudu={this.isSuperKudu}
          isAdmin={this.isAdmin}
        />
      </QueryClientProvider>,
      $('#UsersMainContainer')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
