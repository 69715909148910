import React from 'react';
import { Checkbox, Col, Row } from 'react-bootstrap';

const BootstrapTable = require('react-bootstrap-table-next').default;
const filterFactory = require('react-bootstrap-table2-filter');

type EventsArrayComponentProps = {
  variables: UnknownArrayOfObjects;
};

type EventsArrayComponentState = {
  showAllEvents: boolean;
};

export default class EventsArrayComponent extends React.Component<
  EventsArrayComponentProps,
  EventsArrayComponentState
> {
  constructor(props: EventsArrayComponentProps) {
    super(props);
    this.toggleShowAllEvents = this.toggleShowAllEvents.bind(this);
    this.state = {
      showAllEvents: false,
    };
  }

  toggleShowAllEvents() {
    this.setState({
      showAllEvents: !this.state.showAllEvents,
    });
  }

  render() {
    const { variables } = this.props;
    const { showAllEvents } = this.state;

    const filteredVariables = variables.filter(
      (variable) => variable.score !== 0 || showAllEvents
    );

    const maxPredictivePower = Math.max(
      ...variables.map((v) => Math.abs(v.score))
    );

    const withNegativeValues = variables.some((v) => v.score < 0);

    const progressBar = (score: number) => {
      const color =
        // eslint-disable-next-line
        score < 0
          ? 'danger'
          : score < maxPredictivePower / 2
          ? 'yellow'
          : 'success';
      return (
        <div className={`progress ${score < 0 ? 'justify-content-end' : ''}`}>
          <div
            className={`progress-bar bg-${color}`}
            role="progressbar"
            aria-valuenow={Math.abs(score)}
            aria-valuemin={0}
            aria-valuemax={maxPredictivePower}
            style={{
              width: `${(Math.abs(score) * 100) / maxPredictivePower}%`,
            }}
          >
            {score}
          </div>
        </div>
      );
    };

    const columns = [
      {
        dataField: 'meta_event',
        text: 'Madkudu Event',
        sort: true,
        filter: filterFactory.textFilter(),
      },
      {
        dataField: 'score',
        text: 'MadKudu Predictive Power',
        sort: true,
        formatter: (score: number) =>
          withNegativeValues ? (
            <Row>
              <Col sm={6}>{score < 0 && progressBar(score)}</Col>
              <Col sm={6}>{score >= 0 && progressBar(score)}</Col>
            </Row>
          ) : (
            progressBar(score)
          ),
      },
      {
        dataField: 'decay_days',
        text: 'Decay (in days)',
        sort: true,
      },
    ];
    const defaultSorted = [
      {
        dataField: 'decay_days',
        order: 'desc',
      },
    ];

    return (
      variables && (
        <div>
          <Checkbox checked={showAllEvents} onChange={this.toggleShowAllEvents}>
            <span className="pl-2">Show all events available</span>
          </Checkbox>
          <BootstrapTable
            keyField="meta_event"
            data={filteredVariables}
            columns={columns}
            defaultSorted={defaultSorted}
            filter={filterFactory.default()}
            bootstrap4
          />
        </div>
      )
    );
  }
}
