enum VerbsNames {
  is = 'is',
  is_not = 'is not',
  contains = 'contains',
  does_not_contain = 'does not contain',
  is_less_than_or_equal_to = 'is less than or equal to',
  is_more_than_or_equal_to = 'is more than or equal to',
  is_less_than = 'is less than',
  is_more_than = 'is more than',
  is_any_of = 'is any of',
  is_none_of = 'is none of',
  is_between = 'is between',
  is_known = 'is known',
  is_unknown = 'is unknown',
  begins_with = 'begins with',
  ends_with = 'ends with',
}

export default VerbsNames;
