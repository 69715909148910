import React from 'react';

type Props = {
  personProperties: Record<string, any>;
  ruleDiagnosis: any;
};

export default function RulesDiagnosis({
  personProperties,
  ruleDiagnosis,
}: Props) {
  if (personProperties?.diagnosis?.microMlDiagnosis?.length === 0) return <></>;

  return (
    <div className="tw-border-t tw-border-white tw-pb-12">
      <div className="tw-ml-[4rem] tw-mt-8 tw-leading-loose tw-mb-4">
        <span className="tw-text-xl tw-font-bold">Point based rules</span>
        <div>Summary of all rules applied to get the final score</div>
      </div>

      <table className="tw-mx-auto tw-w-[90%] tw-table-fixed tw-rounded-lg tw-overflow-hidden">
        <thead className="tw-h-12 tw-bg-[#008fea] tw-text-left">
          <tr>
            <th className="tw-sticky tw-top-0 w-[70%] tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white">
              <span className="tw-cursor-pointer tw-select-none gap-2">
                Condition
              </span>
            </th>
            <th className="tw-sticky tw-top-0 tw-w-[30%] tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-center tw-text-sm tw-font-medium tw-text-white">
              <span className="tw-cursor-pointer tw-select-none tw-gap-2">
                Points
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="tw-h-12 tw-text-left">
          {ruleDiagnosis.pointBasedRules.map((rules: any, index: number) => (
            <tr key={index} className="odd:tw-bg-white even:tw-bg-light">
              <td className="tw-w-[70%] tw-px-4 tw-py-3 tw-text-sm">
                {rules.computationsValuesAndRules.map(
                  (rule: any, indexRule: number) => {
                    return (
                      <span key={indexRule}>
                        {indexRule > 0 ? (
                          <>
                            <br />
                            <b>And </b>
                          </>
                        ) : (
                          ''
                        )}
                        {rule.computation} {rule.verb}{' '}
                        {rule.verb === 'is between'
                          ? rule.values.join(' and ')
                          : rule.values.join(', ')}
                      </span>
                    );
                  }
                )}
              </td>
              <td className="tw-w-[30%] tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-center tw-text-sm tw-font-semibold">
                {rules.score}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {personProperties?.diagnosis?.overrideDiagnosis?.length > 0 && (
        <>
          <div className="tw-ml-[4rem] tw-mt-20 tw-leading-loose tw-mb-4">
            <span className="tw-text-xl tw-font-bold">Overrides</span>
            <div>Summary of overrides applied to get the final segment</div>
          </div>

          <table className="tw-mx-auto tw-w-[90%] tw-table-fixed tw-rounded-lg tw-overflow-hidden">
            <thead className="tw-h-12 tw-bg-[#008fea] tw-text-left">
              <tr>
                <th className="tw-sticky tw-top-0 w-[50%] tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white">
                  <span className="tw-cursor-pointer tw-select-none gap-2">
                    Condition
                  </span>
                </th>
                <th className="tw-sticky tw-top-0 tw-w-[50%] tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-center tw-text-sm tw-font-medium tw-text-white">
                  <span className="tw-cursor-pointer tw-select-none tw-gap-2">
                    Rule to apply
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="tw-h-12 tw-text-left">
              {personProperties?.diagnosis?.overrideDiagnosis.map(
                (rule: any, index: number) => (
                  <tr key={index} className="odd:tw-bg-white even:tw-bg-light">
                    <td className="tw-w-[50%] tw-px-4 tw-py-3 tw-text-sm">
                      {rule.computationsValuesAndRules.map(
                        (computation: any, i: number) => (
                          <div key={i}>
                            {i > 0 && <b>And </b>}
                            {computation.rule.toString()}
                          </div>
                        )
                      )}
                    </td>
                    <td className="tw-w-[50%] tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-center tw-text-sm">
                      Then segment {rule.rule} <b>{rule.segment}</b>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
