import Backbone from 'backbone';
import $ from 'jquery';
import Swal from 'sweetalert2';
import app from '../app';
import model_integration from '../models/integration';
import test_connection from './test_connection';
import view_limit from './view_limit';

const template = require('./view_admin.pug');
require('bootstrap-table/dist/bootstrap-table');

const Integration_view = (Backbone as any).Epoxy.View.extend({
  events: {
    'click .btn_cancel': 'cancel',
    'click .btn_save': 'save',
  },

  initialize(options: { tenant: number; integration: 'string' }) {
    this.tenant = options.tenant;
    this.integration = options.integration;
  },

  render() {
    const html = $('#tpl_integration', template()).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}`,
      true
    );
  },

  save(event: any) {
    event.preventDefault();
    this.model.save(null, {
      success() {
        app.notifications.success('Configuration updated successfully.', {
          dismiss_timeout: 3000,
        });
      },
      error() {
        Swal.fire('Oops!', 'There was an error saving your config', 'error');
      },
    });
  },
});

const Integration_workbench = (Backbone as any).Epoxy.View.extend({});

const Pull_view = (Backbone as any).Epoxy.View.extend({
  events: {
    'click .btn_cancel': 'cancel',
    'click .btn_save': 'save',
  },

  initialize(options: { tenant: number; integration: string }) {
    this.tenant = options.tenant;
    this.integration = options.integration;
    this.render();
  },

  render() {
    const html = $('#tpl_pull', template()).html();
    this.$el.html(html);
    this.applyBindings();
    return this;
  },

  cancel(event: any) {
    event.preventDefault();
    Backbone.history.navigate(
      `/org/${this.model.tenant}/integrations/${this.integration}`,
      true
    );
  },

  save(event: any) {
    event.preventDefault();
    this.model.save(null, {
      success() {
        Swal.fire('Done!', 'Config updated successfully', 'success');
      },
      error() {
        Swal.fire('Oops!', 'There was an error saving your config', 'error');
      },
    });
  },
});

export default (Backbone as any).Epoxy.View.extend({
  events: {
    'click .btn_test': 'test_connection',
    'click .btn_limit': 'view_limit',
    'click .btn_workbench_actions': 'display_workbench',
    'click #btn_workbench': 'go_workbench',
    'click #go_back': 'go_back',
    'click #go_back_integrations': 'go_back_integrations',
    'click #go_back_integration_board': 'go_back_integration_board',
  },

  initialize(options: {
    tenant: number;
    integration: string;
    query_params: UnknownObject;
  }) {
    this.tenant = options.tenant;

    this.tenant = options.tenant;
    this.integration = options.integration;
    this.query_params = options.query_params;

    this.model = new model_integration.Integration_model({
      tenant: this.tenant,
      name: this.integration,
    });

    this.pull_model = new model_integration.Pull_model({
      tenant: this.tenant,
      integration: this.integration,
    });

    this.integration_view = new Integration_view({
      model: this.model,
      tenant: this.tenant,
      integration: this.integration,
    });

    this.pull_view = new Pull_view({
      model: this.pull_model,
      tenant: this.tenant,
      integration: this.integration,
    });

    this.workbench_view = new Integration_workbench({
      tenant: this.tenant,
      name: this.integration,
    });

    this.model.fetch({
      success: () => {
        this.pull_model.fetch({ success: () => this.render() });
      },
    });
  },

  display_workbench() {
    this.assign(this.workbench_view, '#mki_integration');
    $('#mki_pull').hide();
    $('#header_section').hide();
  },

  go_workbench() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}/workbench`,
      true
    );
  },

  go_back() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/${this.integration}`,
      true
    );
  },
  go_back_integrations() {
    Backbone.history.navigate(
      `/org/${this.tenant}/integrations/list/all`,
      true
    );
  },
  go_back_integration_board() {
    Backbone.history.navigate(`/org/${this.tenant}/integrations/`, true);
  },

  assign(view: any, selector: string) {
    view.setElement(this.$(selector)).render();
  },

  render() {
    const html = $(
      '#tpl_main',
      template({ tenant: this.tenant, integration: this.integration })
    ).html();
    this.$el.html(html);
    this.assign(this.integration_view, '#mki_integration');
    this.assign(this.pull_view, '#mki_pull');
    return this;
  },

  test_connection() {
    test_connection(this.tenant, this.integration)
      .then((results = {}) => {
        if (results.tested) {
          Swal.fire({
            title: 'Tip top',
            text: 'The credentials are valid',
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Who knows',
            text: `We currently do not have a credential test for ${this.integration}`,
            icon: 'info',
          });
        }
      })
      .catch((err: any) => {
        console.error(err);
        Swal.fire({
          title: 'Invalid credentials',
          text: err && err.message,
          icon: 'error',
        });
      });
  },

  view_limit() {
    view_limit(this.tenant, this.integration)
      .then((res) => {
        Swal.fire({
          // title: 'Tip top',
          text: JSON.stringify(res, null, 2),
          icon: 'success',
        });
      })
      .catch((err: any) => {
        Swal.fire({
          // title: 'Invalid credentials',
          text: err.message,
          icon: 'error',
        });
      });
  },
});
