import React, { Component } from 'react';

export default class ConversionRateAndAverageDealSizeComponent extends Component<
  {},
  {}
> {
  render() {
    return (
      <div className="col-8">
        <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
          <div className="card-body">
            <i className="far fa-compass fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>Guide: </strong>
              Conversion Rate and Average Deal Size
            </h5>
            <div className="border-top mt-2 border-primary"></div>
            <i className="fas fa-flask fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>How MadKudu Computes This:</strong>
            </h5>
            <p>
              <strong>Conversion rate:</strong> is calculated by taking the
              number of unique closed won conversions that occurred in the past
              6 months from leads that were created in the past 6 months and
              dividing it by the number of leads that were created in the past 6
              months.{' '}
              <strong>
                Note that this does not look at any conversions that were
                generated from leads that were created before the 6 months mark.
              </strong>
            </p>
            <p className="mt-2">
              <strong>Average Deal Size</strong> is calculated by taking the sum
              of revenue (closed won conversions in dollar value) and dividing
              it by the number of closed conversions that occurred in the past 6
              months.{' '}
              <strong>
                Note that these conversions were taken from the leads that were
                created in the past 6 months and do not account for any
                conversions that occurred from leads that were created before
                the 6 months mark.
              </strong>
            </p>
            <h5 className="mt-2">
              <strong>How to Interpret This:</strong>
            </h5>
            <p>
              If you find your 'Very Good' and 'Good' leads having high
              conversion rates and average deal size, educate your sales reps on
              the value of prioritizing the 'Good' and 'Very Good' leads, they
              convert better AND are worth a lot more.
            </p>
            <p>
              Look at the conversion rates for 'Medium' and 'Low' and check
              their average deal size. If the conversion rates are high with a
              substantial deal size, there may be a potential need to prioritize
              those leads using the MadKudu Leads Grade model which combines fit
              and behavorial traits. (MK Model Types) If conversion rates and
              average deal sizes are low for the Medium or Low segment, keep
              them in the self-serve path and let Sales focus on the qualified
              leads.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
