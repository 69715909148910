import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { CognismCredentials } from '../types';

export function useChangeCognismApiToken(
  tenant: number,
  options?: UseMutationOptions<
    void,
    { message?: string },
    CognismCredentials,
    unknown
  >
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (credentials: CognismCredentials) => {
      await axios.post(
        `${BONGO_URL}/v1/org/${tenant}/integrations/cognism/credentials`,
        credentials
      );

      // Validate token
      const response = await axios.get<{
        success: boolean;
        message: string;
        statusCode: number;
      }>(`${BONGO_URL}/v1/org/${tenant}/integrations/cognism/ping`);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
    },
    ...options,
    onSuccess: (data, variables, ctx) => {
      options?.onSuccess?.(data, variables, ctx);
      queryClient.setQueryData(['cognism', tenant], true);
    },
  });
}
