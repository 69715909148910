import React, { useState } from 'react';
import {
  Computation,
  EnrichmentCategory,
  Model,
  ModelSubset,
  PersonModelScore,
  PersonResult,
} from '../types';
import { ContainerWhite } from '../../components/ui';
import Enrichment from './Enrichement';
import ScoreLookup from './ScoreLookup/ScoreLookup';
import ScoreResult from './ScoreResult';
import SelectModel from './SelectModel';

type Props = {
  personResult: PersonResult;
  models: ModelSubset[];
  computations: Computation[];
  email: string;
  tenant: number;
  isLoadingModels: boolean;
  isLoadingProfileData: boolean;
};

function ScoreCard({
  computations,
  isLoadingModels,
  isLoadingProfileData,
  models,
  tenant,
  email,
  personResult,
}: Props) {
  const [model, setModel] = useState<Model | null>(null);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<EnrichmentCategory>(
    'all'
  );

  const personModelScore: PersonModelScore | null =
    personResult && model ? personResult.properties[model.value.key] : null;

  return (
    <ContainerWhite
      title="Score"
      className="tw-mt-6 tw-min-h-[400px]"
      topContent={
        !isLoadingProfileData && (
          <SelectModel
            selectedModel={model}
            setSelectedModel={setModel}
            models={models}
            personResult={personResult}
          />
        )
      }
      isLoading={isLoadingProfileData}
    >
      {personModelScore && !isLoadingModels && !isLoadingProfileData && (
        <>
          <ScoreResult
            personModelScore={personModelScore}
            model={model.value}
            email={email}
            tenant={tenant}
          />

          {personModelScore.diagnosis && (
            <ScoreLookup
              personModelScore={personModelScore}
              computations={computations}
              model={model.value}
              email={email}
              tenant={tenant}
              setSearch={setSearch}
              setSelectedCategory={setSelectedCategory}
            />
          )}

          <Enrichment
            personModelScore={personModelScore}
            computations={computations}
            tenant={tenant}
            search={search}
            setSearch={setSearch}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </>
      )}
    </ContainerWhite>
  );
}

export default ScoreCard;
