import axios from 'axios';
import Backbone from 'backbone';
import Chart from 'chart.js';
import $ from 'jquery';
import moment from 'moment';
import numeral from 'numeral';
import { COLORS, MORE_COLORS } from '../../../predictions/utils';
import { BackboneViewProps } from '../../../types';
import {
  createDataAndPointRadius,
  createDefaultDataset,
  createDefaultDates,
  getBootstrapColumnsFromData,
  refreshDataDiscovery,
} from '../../utils';
import { OpportunityType } from './types';
import app from '../../../app';

const ChartDataLabels = require('chartjs-plugin-datalabels');

require('bootstrap-table/dist/bootstrap-table');

const template = require('./discovery.pug');

Chart.plugins.register(ChartDataLabels);

export default Backbone.View.extend({
  events: {
    'click #go_back': 'go_back',
    'click #refresh': 'refresh',
  },
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.userEmail = app.session.get('user').email;
    const url = `${BONGO_URL}/v1/org/${this.tenant}/data/discovery/salesforce`;
    const { data } = await axios.get(url);
    const updatedAt: string =
      moment(data.updated_at).format('lll') ?? 'unknown';

    this.data = data;
    this.updatedAt = updatedAt;
    this.render();
    this.funnelOverviewTable();
    this.volumeLeadsContactsGraph();
    this.volumeLeadsContactsTable();

    this.leadSourceEvolutionGraph();
    this.topOpportunitiesSourcesGraph();
    this.topClosedWonSourcesGraph();
    this.topSourcesOpportunitiesTable();

    this.oppsOpenedAndClosedWonGraph();
    this.oppsOpenedAndClosedWonTable();
    this.opportunitiesTypeTable();
    this.opportunitiesStagesTable();

    this.opportunitiesAmountDistributionGraph();
    this.opportunitiesAmountMinMaxGraph();
    this.opportunitiesAmountMinMaxTable();

    this.dataQualityTable();

    this.taskTypes();
    this.campaignResponsesByCampaignType();
    this.mostFrequentlyRespondedCampaigns();
  },

  go_back() {
    Backbone.history.navigate(`/org/${this.tenant}/data`, true);
  },

  async refresh() {
    await refreshDataDiscovery(this.tenant, this.userEmail, 'salesforce');
  },

  funnelOverviewTable() {
    const funnelData: UnknownObject = this.data.dataset.funnel_overview[0];
    const formatter = (value: string) => numeral(value).format('0,0');
    const columns: UnknownObject[] = [];
    const data: UnknownObject[] = [{}];

    Object.keys(funnelData).forEach((key) => {
      const field = key.toLowerCase().replace(/ /g, '_');
      columns.push({
        formatter,
        field,
        title: key,
      });

      data[0][field] = funnelData[key];
    });

    $('#salesforce_funnel', this.$el).bootstrapTable({
      columns,
      data,
      pagination: true,
      search: true,
    });
  },

  volumeLeadsContactsGraph() {
    const data: UnknownObject[] = this.data.dataset.leads_and_contacts_volume;
    const dates = data.reduce(
      (acc, row) => acc.concat(moment(row.Month).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('volume_leads_contacts_graph')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Leads',
            data: data.map((row: any) => row.Leads),
            backgroundColor: COLORS[0],
          },
          {
            label: 'Converted leads',
            data: data.map((row: any) => row['Converted leads']),
            backgroundColor: COLORS[1],
          },
          {
            label: 'Contacts',
            data: data.map((row: any) => row.Contacts),
            backgroundColor: COLORS[2],
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  volumeLeadsContactsTable() {
    const volumedata: UnknownObject[] = this.data.dataset
      .leads_and_contacts_volume;
    const data = volumedata.map((row: any) => {
      return {
        month: moment(row.Month).format('MMM YYYY'),
        leads: row.Leads,
        converted_leads: row['Converted leads'],
        contacts: row.Contacts,
      };
    });
    $('#volume_leads_contacts_table', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      // to handle every month + repeat of current month
      pageSize: 13,
      search: true,
    });
  },

  leadSourceEvolutionGraph() {
    const leadSourceEvolution: UnknownObject[] = this.data.dataset
      .lead_source_evolution;
    // get all unique dates
    const labels: string[] = [
      ...new Set(
        leadSourceEvolution.reduce(
          (acc, row) => acc.concat(row['Created date']),
          []
        )
      ),
    ] as string[];
    const ctx = document
      .getElementById('lead_source_evolution')
      .getContext('2d');
    const sumByDate: UnknownObject = {};
    const leadSources: UnknownObject = {};
    leadSourceEvolution.forEach((row) => {
      // Lead Sources
      if (!leadSources[row.Leadsource]) {
        leadSources[row.Leadsource] = {};
      }
      leadSources[row.Leadsource][row['Created date']] = row['Leads created'];

      // Sum by date
      sumByDate[row['Created date']] =
        (sumByDate[row['Created date']] || 0) + row['Leads created'];
    });
    const datasets: UnknownObject[] = Object.keys(leadSources).map(
      (leadSource, i) => {
        const data = labels.map((createdDate) => {
          return (
            ((leadSources[leadSource][createdDate] || 0) * 100) /
            sumByDate[createdDate]
          ).toFixed(2);
        });
        return {
          data,
          label: leadSource,
          backgroundColor: MORE_COLORS[i],
        };
      }
    );

    const dates = labels.map((date) => {
      return moment(date).format('MMM YYYY');
    });

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        legend: { position: 'bottom' },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
          },
        },
      },
    });
  },

  topOpportunitiesSourcesGraph() {
    const data: UnknownObject[] = this.data.dataset.open_opp_top_sources;
    // building dataPointRadius array with a minimun size
    const dataAndPointRadius = createDataAndPointRadius(
      data,
      'Conversion Rate to Opp'
    );

    const labels = data.reduce(
      (acc, row) => acc.concat(row['Lead source']),
      []
    );
    const ctx = document
      .getElementById('top_opportunities_sources')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Conversion rate Lead to Open Opp',
            data: data.map((row: any) =>
              row['Conversion Rate to Opp'].toFixed(2)
            ),
            type: 'line',
            fill: false,
            pointStyle: 'circle',
            pointRadius: dataAndPointRadius,
            backgroundColor: COLORS[1],
            borderColor: COLORS[9],
            showLine: false,
            yAxisID: 'B',
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'Opp Opened',
            data: data.map((row: any) => row['Opp Opened']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                callback: (value: any) => {
                  return `${value} %`;
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  topClosedWonSourcesGraph() {
    const data: UnknownObject[] = this.data.dataset.closed_won_top_sources;
    // building dataPointRadius array with a minimun size
    const dataAndPointRadius = createDataAndPointRadius(
      data,
      'Conversion Rate to Closed Won'
    );

    const labels = data.reduce(
      (acc, row) => acc.concat(row['Lead source']),
      []
    );
    const ctx = document
      .getElementById('top_closed_won_sources')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Conversion rate Open Opp to Closed Won',
            data: data.map((row: any) =>
              row['Conversion Rate to Closed Won'].toFixed(2)
            ),
            type: 'line',
            yAxisID: 'B',
            fill: false,
            pointStyle: 'circle',
            pointRadius: dataAndPointRadius,
            backgroundColor: COLORS[1],
            borderColor: COLORS[9],
            showLine: false,
            datalabels: {
              rotation: 0,
            },
          } as any,
          {
            label: 'Opp Closed Won',
            data: data.map((row: any) => row['Opp Closed Won']),
            backgroundColor: COLORS[0],
            yAxisID: 'A',
          },
        ],
      },
      options: {
        legend: { position: 'top' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                callback: (value: any) => {
                  return `${value} %`;
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return value;
            },
          },
        },
        hover: {
          mode: null,
        },
      },
    });
  },

  topSourcesOpportunitiesTable() {
    const sourcesdata: UnknownObject[] = this.data.dataset
      .opportunities_top_sources;
    const data = sourcesdata.map((row: any) => {
      return {
        lead_source: row['Lead source'],
        leads_created: row['Leads created'],
        opp_opened: row['Opp Opened'],
        conversion_rate_to_opp: numeral(row['Conversion Rate to Opp']).format(
          '0,0.00'
        ),
      };
    });

    $('#top_sources_opportunities_table', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  oppsOpenedAndClosedWonGraph() {
    const data: UnknownObject[] = this.data.dataset.opps_opened_and_closed_won;
    const labels = data.reduce(
      (acc, row) => acc.concat(moment(row.Date).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('opps_opened_and_closed_won_graph')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Open opp',
            data: data.map((row: any) => row['Open opp']),
            backgroundColor: COLORS[0],
          },
          {
            label: '20%',
            data: data.map((row: any) => row['20%']),
            backgroundColor: COLORS[1],
          },
          {
            label: '100%',
            data: data.map((row: any) => row['100%']),
            backgroundColor: COLORS[2],
          },
          {
            label: 'Is won = true',
            data: data.map((row: any) => row['Is won = true']),
            backgroundColor: COLORS[3],
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  oppsOpenedAndClosedWonTable() {
    const opportunitiesdata: UnknownObject[] = this.data.dataset
      .opps_opened_and_closed_won;
    const data = opportunitiesdata.map((row: any) => {
      return {
        date: moment(row.Date).format('MMM YYYY'),
        closed_lost: row['Closed Lost'],
        open_opp: row['Open opp'],
        '20%': row['20%'],
        '100%': row['100%'],
        avg_time_to_close: row['Average time to close'],
        iswon_true: row['Is won = true'],
      };
    });

    $('#opps_opened_and_closed_won_table', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  opportunitiesTypeTable() {
    const opportunitiesTypes: OpportunityType[] = this.data.dataset
      .opportunities_type;

    $('#opportunities_type', this.$el).bootstrapTable({
      columns: [
        {
          field: 'Distinct opp id',
          title: 'Distinct Opp Id',
          sortable: true,
        },
        { field: 'Type', title: 'Type', sortable: true },
      ],
      data: opportunitiesTypes,
      pagination: true,
      search: true,
    });
  },

  opportunitiesStagesTable() {
    const opportunitiesdata: UnknownObject[] = this.data.dataset
      .opportunities_stages;
    const data = opportunitiesdata.map((row: any) => {
      return {
        stage_name: row['Stage name'],
        probability: row.Probability,
        is_won: row['Is won'],
        distinct_opp_id: row['Distinct opp id'],
      };
    });

    $('#opportunities_stages', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  opportunitiesAmountDistributionGraph() {
    const data: UnknownObject[] = this.data.dataset.opportunities_amount;
    const dates = data.reduce(
      (acc, row) => acc.concat(moment(row.Date).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('opportunities_amount_distribution')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Closed Won without amount',
            data: data.map((row: any) => row['Closed Won without amount']),
            backgroundColor: COLORS[0],
          },
          {
            label: 'Closed Won with amount',
            data: data.map((row: any) => row['Closed Won with a_amount']),
            backgroundColor: COLORS[1],
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                autoSkip: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  opportunitiesAmountMinMaxGraph() {
    const data: UnknownObject[] = this.data.dataset.opportunities_amount;
    const dates = data.reduce(
      (acc, row) => acc.concat(moment(row.Date).format('MMM YYYY')),
      []
    );
    const ctx = document
      .getElementById('opportunities_amount_min_max_graph')
      .getContext('2d');

    // eslint-disable-next-line
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Closed Won MIN amount',
            data: data.map((row: any) => ({
              x: moment(row.Date).format('MMM YYYY'),
              y: row['Closed Won MIN amount'],
            })),
            backgroundColor: COLORS[0],
            borderColor: COLORS[0],
            fill: false,
          },
          {
            label: 'Closed Won MAX amount',
            data: data.map((row: any) => ({
              x: moment(row.Date).format('MMM YYYY'),
              y: row['Closed Won Max amount'],
            })),
            backgroundColor: COLORS[1],
            borderColor: COLORS[1],
            fill: false,
          },
          {
            label: 'Closed Won AVG amount',
            data: data.map((row: any) => ({
              x: moment(row.Date).format('MMM YYYY'),
              y: row['Closed Won AVG amount']
                ? row['Closed Won AVG amount'].toFixed(2)
                : undefined,
            })),
            backgroundColor: COLORS[2],
            borderColor: COLORS[2],
            fill: false,
          },
        ],
      },
      options: {
        legend: { position: 'right' },
        scales: {
          xAxes: [
            {
              type: 'category',
              position: 'bottom',
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: 'white',
            display: false,
            rotation: 270,
            formatter(value: string) {
              return numeral(value).format('0,0');
            },
          },
        },
      },
    });
  },

  opportunitiesAmountMinMaxTable() {
    const opportunitiesdata: UnknownObject[] = this.data.dataset
      .opportunities_amount;
    const data = opportunitiesdata.map((row: any) => {
      return {
        date: moment(row.Date).format('YYYY-MM'),
        open_opp_min_amount: row['Open opp MIN amount'],
        open_opp_max_amount: row['Open opp MAX amount'],
        closed_won_min_amount: row['Closed Won MIN amount'],
        closed_won_max_amount: row['Closed Won Max amount'],
        closed_won_avg_amount: row['Closed Won AVG amount'],
      };
    });

    $('#opportunities_amount_min_max_table', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  dataQualityTable() {
    const opportunitiesdata: UnknownObject[] = this.data.dataset.data_quality;
    const data = opportunitiesdata.map((row: any) => {
      return {
        date: moment(row.Month).format('YYYY-MM'),
        leads_email_duplicated: row['Leads email duplicated'],
        leads_without_email: row['Leads without email'],
        contacts_email_duplicated: row['Contacts email duplicated'],
        contacts_without_email: row['Contacts without email'],
      };
    });

    $('#data_quality', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  taskTypes() {
    const rawData: UnknownObject[] = this.data.dataset.task_types;
    if (!rawData || !rawData.length) {
      $('#task_types').closest('.card.mt-2.box-shadow-3d').remove();
      return;
    }
    const ctx = document.getElementById('task_types').getContext('2d');

    const dates = createDefaultDates(rawData);
    const dataset = createDefaultDataset(
      rawData,
      'a_type',
      'total events logged'
    );

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets: dataset,
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        legend: {
          position: 'top',
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, dat) {
              let label = dat.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  campaignResponsesByCampaignType() {
    const data: UnknownObject[] = this.data.dataset
      .campaign_responses_by_campaign_type;

    const formatedData: { [key: string]: any[] } = {};

    // Order data, key is date
    data.forEach((row) => {
      if (!formatedData[row['event timestamp']]) {
        formatedData[row['event timestamp']] = [];
      }
      formatedData[row['event timestamp']].push({
        'total events logged': row['total events logged'],
        type: row.type,
      });
    });

    if (!data || !data.length) {
      $('#campaign_responses_by_campaign_type')
        .closest('.card.mt-2.box-shadow-3d')
        .remove();
      return;
    }

    const ctx = document
      .getElementById('campaign_responses_by_campaign_type')
      .getContext('2d');
    const dates: string[] = [];
    const timestampDates: string[] = [];
    const campaignTypes: string[] = [];

    data.forEach((row) => {
      const timestamp = moment(row['event timestamp']).format('MMM YYYY');
      if (!dates.includes(timestamp)) dates.push(timestamp);
      if (!timestampDates.includes(row['event timestamp']))
        timestampDates.push(row['event timestamp']);
      if (!campaignTypes.includes(row.type)) campaignTypes.push(row.type);
    });

    const datasets: UnknownObject[] = [];

    const campaignTypeData: { [key: string]: number[] } = {};

    campaignTypes.forEach((campaignType, index) => {
      timestampDates.forEach((timestamp) => {
        const foundCampaignTypeData = formatedData[timestamp].find(
          (attribute) => {
            return attribute.type === campaignType;
          }
        );
        if (!campaignTypeData[campaignType]) {
          campaignTypeData[campaignType] = [];
        }
        campaignTypeData[campaignType].push(
          foundCampaignTypeData
            ? foundCampaignTypeData['total events logged']
            : 0
        );
      });
      datasets.push({
        label: campaignType,
        data: campaignTypeData[campaignType],
        backgroundColor: COLORS[index % COLORS.length],
      });
    });

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: dates,
        datasets,
      },
      options: {
        scales: {
          xAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'event timestamp',
                fontSize: 16,
                fontStyle: 'bold',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback: (value: any) => {
                  return value.toLocaleString();
                },
              },
            },
          ],
        },
        legend: {
          position: 'right',
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, dat) {
              let label = dat.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel.toLocaleString();
              return label;
            },
          },
        },
      },
    });
  },

  mostFrequentlyRespondedCampaigns() {
    const rawData: UnknownObject[] = this.data.dataset
      .most_frequently_responded_campaigns;

    if (!rawData && !rawData.length) {
      $('#most_frequently_responded_campaigns')
        .closest('.card.mt-2.box-shadow-3d')
        .remove();
      return;
    }

    const data = rawData.map((row: any) => {
      return {
        campaign_type: row['campaign type'],
        campaign_name: row['campaign name'],
        campaign_member_status: row['campaign member status'],
        count: row.count,
        days_since_last_seen: row['days since last seen'],
      };
    });

    $('#most_frequently_responded_campaigns', this.$el).bootstrapTable({
      columns: getBootstrapColumnsFromData(data),
      data,
      pagination: true,
      search: true,
    });
  },

  render() {
    const html = template({
      tenant: this.tenant,
      noData: typeof this.data === 'undefined',
      updatedAt: this.updatedAt,
    });
    this.$el.html(html);

    return this;
  },
});
