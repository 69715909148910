import React, { PropsWithChildren, useState } from 'react';
import Swal, { SweetAlertResult } from 'sweetalert2';
import withReactContent, {
  ReactSweetAlertOptions,
  SweetAlert2,
} from 'sweetalert2-react-content';
import { createPortal } from 'react-dom';

const reactSwal = withReactContent(Swal);

export type UseSwalInstance = {
  isLoading: SweetAlert2['isLoading'];
  isVisible: SweetAlert2['isVisible'];
  clickCancel: SweetAlert2['clickCancel'];
  clickConfirm: SweetAlert2['clickConfirm'];
  clickDeny: SweetAlert2['clickDeny'];
  Modal: ({ children }: PropsWithChildren<{}>) => JSX.Element;
  fire: (opts: ReactSweetAlertOptions) => Promise<SweetAlertResult<any>>;
};

/**
 * Swal wrapped into a React hook, powered by [Portals](https://reactjs.org/docs/portals.html).
 * Slightly inspired from [this Github comment](https://github.com/sweetalert2/sweetalert2-react-content/issues/162#issuecomment-928928080).
 */
function useSwal(defaultOptions?: ReactSweetAlertOptions): UseSwalInstance {
  const [container, setContainer] = useState<HTMLDivElement>(null);
  let fireFromModal: (
    opts: ReactSweetAlertOptions
  ) => Promise<SweetAlertResult<any>> = (opts = {}) =>
    reactSwal.fire({ ...defaultOptions, ...opts });

  const Modal = ({ children }: PropsWithChildren<{}>) => {
    fireFromModal = async (opts) => {
      const computedOptions = { ...defaultOptions, ...opts };
      return reactSwal.fire({
        ...computedOptions,
        html: <div ref={(el) => setContainer(el)} />,
      });
    };
    return container ? createPortal(children, container) : <></>;
  };

  return {
    isLoading: reactSwal.isLoading,
    isVisible: reactSwal.isVisible,
    clickCancel: reactSwal.clickCancel,
    clickConfirm: reactSwal.clickConfirm,
    clickDeny: reactSwal.clickDeny,
    Modal,
    fire: (opts: ReactSweetAlertOptions = {}) => {
      reactSwal.close();
      return fireFromModal(opts);
    },
  };
}

export default useSwal;
