import * as d3Interpolate from 'd3-interpolate';
import {
  D3BasicSelection,
  GaugeConfigWithRanges,
  Segment,
  ValueConfig,
} from '../types';
import { getSegmentByValue, getTextSize } from '../utils';

export default function drawValue(
  svg: D3BasicSelection,
  value: number,
  data: Segment[],
  gaugeConfig: GaugeConfigWithRanges,
  valueConfig: ValueConfig
) {
  // Set the tag text
  const valueSegment = getSegmentByValue(
    value,
    data,
    gaugeConfig.valuesRange[1]
  ) ?? {
    color: '#E2E8F0',
    label: 'unknown',
    range: [0, 0],
  };

  const { height: textHeight, width: textWidth } = getTextSize(
    svg,
    valueSegment.label,
    valueConfig.tagFontSize
  );

  // Set the tag dimensions based on the size of the text
  const tagWidth = textWidth + valueConfig.tagPaddingX * 2;
  const tagHeight = textHeight + valueConfig.tagPaddingY * 2;

  const valueSvg = svg
    .append('g')
    .attr('transform', `translate(0,-${valueConfig.bottom})`);

  valueSvg
    .append('text')
    .attr('id', 'scoreVal')
    .text(value)
    .style('text-anchor', 'middle')
    .style('font-size', valueConfig.fontSize)
    .style('font-weight', valueConfig.fontWeight)
    .style('fill', valueConfig.color)
    .transition()
    .tween('text', () => {
      const interpolator = d3Interpolate.interpolateNumber(0, value);
      return (n: any) => {
        valueSvg.select('#scoreVal').text(Math.round(interpolator(n)));
      };
    })
    .duration(1000);

  valueSvg
    .append('rect')
    .attr('width', tagWidth)
    .attr('height', tagHeight)
    .attr('rx', valueConfig.tagBorderRadius)
    .attr('ry', valueConfig.tagBorderRadius)
    .attr('fill', valueSegment.color)
    .attr(
      'transform',
      `translate(-${tagWidth / 2},${
        valueConfig.tagPaddingY + valueConfig.tagMarginY
      })`
    );

  valueSvg
    .append('text')
    .attr('x', tagWidth / 2)
    .attr('y', tagHeight / 2) // Adjust y position to center the text vertically
    .attr('text-anchor', 'middle')
    .attr('font-size', valueConfig.tagFontSize)
    .attr('font-weight', valueConfig.tagFontWeight)
    .attr('fill', '#ffff')
    .attr(
      'transform',
      `translate(-${tagWidth / 2},${
        valueConfig.tagPaddingY + valueConfig.tagMarginY + tagHeight * 0.2
      })`
    )
    .text(valueSegment.label);
}
