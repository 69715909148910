import React from 'react';

export function NewMixpanelAuthInstructions() {
  return (
    <div className="mr-4 px-2">
      <h5>How it works</h5>
      <ul>
        <li>
          First, login to your{' '}
          <a href="https://mixpanel.com" target="_blank" rel="noreferrer">
            Mixpanel account
          </a>
        </li>
        <li>
          You will need to have a <b>Mixpanel project</b> ready to be used for
          MadKudu
        </li>
        <li>
          You can create a service account from either the selected project or
          your organization
        </li>
        <li>
          Go to either your selected project settings or your organization
          settings
        </li>
        <li>
          Click on the <b>Service Accounts</b> tab
        </li>
        <li>
          Click on <b>Add Service Account</b>
        </li>
        <li>
          If creating an organization service account, make sure it is allowed
          to use the Mixpanel project you want to connect with MadKudu
        </li>
        <li>
          Copy the <b>Service Account ID</b> and <b>Service Account Secret</b>{' '}
          and paste them in the form below
        </li>
        <li>
          Finally, copy the <b>Project ID</b> and paste it in the form below.
          You can find the Project ID in the URL of your Mixpanel project (
          <code>https://mixpanel.com/project/:id</code>)
        </li>
      </ul>
    </div>
  );
}
