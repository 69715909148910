import React from 'react';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useBigQueryContext } from '../contexts/BigQueryContext';
import { Loader } from '../../../components/Loader';
import {
  Columns,
  defaultColumNames,
  getTableLabel,
} from '../../utils/data-warehouse';

type Props = {
  previousStep: () => void;
  nextStep: () => void;
};

export function ColumnMappingStep({ previousStep }: Props) {
  const {
    tenant,
    setEditMode,
    getColumns,
    credentials,
    setCredentials,
    save,
  } = useBigQueryContext();

  const columnsCacheKey = [
    'bigquery',
    'columns',
    tenant,
    credentials?.tokens?.access_token ?? '',
    credentials?.projectId ?? '',
    credentials?.dataset ?? '',
    credentials?.location ?? '',
    credentials?.tableNames?.event ?? '',
    credentials?.tableNames?.contact_update ?? '',
    credentials?.tableNames?.account_update ?? '',
  ];
  const { data: columns, isLoading: loadingColumns } = useQuery(
    columnsCacheKey,
    getColumns
  );

  const { mutate, isLoading: isSaving } = useMutation(save);

  const { tableNames, columnNames = defaultColumNames } = credentials ?? {};

  const validate = (): boolean => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [ourTable, theirTable] of Object.entries(tableNames)) {
      // eslint-disable-next-line no-continue
      if (!theirTable) continue;
      // eslint-disable-next-line no-restricted-syntax
      for (const column of Object.keys(
        defaultColumNames[ourTable as keyof typeof defaultColumNames]
      )) {
        // @ts-ignore - we know this is valid
        if (!columnNames?.[ourTable]?.[column]) return false;
      }
    }
    return true;
  };

  const handleSaveClick = async () => {
    const valid = validate();

    if (!valid) {
      Swal.fire({
        title: 'Invalid column mapping',
        text: 'Please map all columns before saving',
        icon: 'error',
      });

      return;
    }

    mutate(null, {
      onSuccess: (saved) => {
        if (saved) {
          Swal.fire({
            title: 'Your BigQuery integration is set up!',
            html:
              'MadKudu will start pulling moving forward. To pull historical data please click <a href="https://madkudusupport.zendesk.com/hc/en-us/requests?new" target="_blank">here</a>',
            icon: 'success',
          });
          setEditMode(false);
        } else {
          Swal.fire({
            title: 'Invalid column mapping',
            text:
              'MadKudu is unable to find these columns. Please verify they exist in your BigQuery database.',
            icon: 'error',
          });
        }
      },
    });
  };

  return (
    <>
      {loadingColumns && <Loader className="m-auto w-50" />}
      {loadingColumns || (
        <>
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="card box-shadow-3d mb-3">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <img
                      className="con-map-mk-integration-logo-size"
                      style={{ width: '30px' }}
                      src="/media/integrations/logos/bigquery-icon.png"
                    />
                    <h5 className="mb-0 ml-3">BigQuery</h5>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="mt-3">Column mapping</h5>
                  <p>
                    Configure how columns from BigQuery should be mapped to the
                    required fields in MadKudu. The rest of your columns will be
                    synced to MadKudu as they are named.
                  </p>

                  <form>
                    <div className="m-3">
                      {([
                        'event',
                        'contact_update',
                        'account_update',
                      ] as const).map((table) => {
                        const clientTableName = tableNames?.[table];

                        if (!clientTableName) return null;

                        const allColumns = Object.keys(
                          defaultColumNames[table]
                        ) as Columns<typeof table>[];

                        return (
                          <div className="card mb-2" key={table}>
                            <div className="card-header">
                              {getTableLabel(table)} ({clientTableName})
                            </div>
                            <div className="card-body">
                              {allColumns.map((column) => {
                                const value =
                                  columnNames?.[table]?.[
                                    column as keyof typeof columnNames[typeof table]
                                  ] ?? '';
                                const usedColumns = Object.values(
                                  columnNames?.[table] ?? {}
                                );
                                return (
                                  <div
                                    className="form-group row"
                                    key={`${table}-${column}`}
                                  >
                                    <label className="col-sm-2 col-form-label">
                                      {column}&nbsp;
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="col-sm-10">
                                      <Select
                                        key={column}
                                        value={
                                          value ? { label: value, value } : null
                                        }
                                        placeholder={`Select a column (${column})`}
                                        onChange={(option) =>
                                          setCredentials({
                                            ...credentials,
                                            columnNames: {
                                              ...(credentials?.columnNames ??
                                                {}),
                                              [table]: {
                                                ...(credentials?.columnNames?.[
                                                  table
                                                ] ?? {}),
                                                [column]: option?.value ?? '',
                                              },
                                            },
                                          })
                                        }
                                        options={
                                          columns?.[table]?.map?.((col) => ({
                                            label: col,
                                            value: col,
                                            isDisabled: usedColumns.includes(
                                              col
                                            ),
                                          })) ?? []
                                        }
                                        isClearable
                                        isDisabled={!columns?.[table]?.length}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 pb-4 text-center">
            <button
              className="btn btn-light mr-2"
              disabled={isSaving}
              onClick={previousStep}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              disabled={isSaving}
              onClick={handleSaveClick}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </>
      )}
    </>
  );
}
