import React, { Component } from 'react';

export default class MadKuduFitQualityDistributionComponent extends Component<
  {},
  {}
> {
  render() {
    return (
      <div className="col-sm-6">
        <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
          <div className="card-body">
            <i className="far fa-compass fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>Guide: </strong>
              MadKudu Fit Quality Distribution
            </h5>
            <div className="border-top mt-2 border-primary"></div>
            <i className="fas fa-flask fa-lg mt-2"></i>
            <h5 className="mt-2">
              <strong>How MadKudu Computes This:</strong>
            </h5>
            <p>
              <strong>Leads:</strong> what percentage of net new emails captured
              in the last 6 months were scored very good or good.
            </p>
            <p className="mt-2">
              <strong>MadKudu SQO:</strong> what percentage of MadKudu SQOs
              created in the last 6 months came from leads scored as very good
              or good. (see mapping&gt;conversion for definition)
            </p>
            <p className="mt-2">
              <strong>Revenue:</strong>what percentage of the closed-won revenue
              from opportunities created in the last 6 months came from leads
              scored as very good or good.
            </p>
            <h5 className="mt-2">
              <strong>How to Interpret This:</strong>
            </h5>
            <p>
              This section aims to showcase the model’s ability to predict a
              majority of the MadKudu SQOs from a small subset of leads and how
              that translates to revenue. (see mapping&gt;conversion for
              definition of “MadKudu SQO”)
            </p>
          </div>
        </div>
      </div>
    );
  }
}
