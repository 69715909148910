import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useCognismIsConnected(tenant: number) {
  return useQuery<boolean>({
    queryKey: ['cognism', tenant],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${BONGO_URL}/v1/org/${tenant}/integrations/cognism`
        );

        // Validate token
        if (!!data?._id)
          await axios.get(
            `${BONGO_URL}/v1/org/${tenant}/integrations/cognism/ping`
          );

        return !!data?._id;
      } catch (error) {
        return false;
      }
    },
    // Prevent any automatic refetching
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
