import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
};

export function Loader({ className }: Props) {
  return (
    <div
      className={classNames(
        'card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary alert-No-Data',
        className
      )}
    >
      <div className="card-body align-middle">
        <div className="spinner-border float-left mr-2"></div>
        <h5>Loading...</h5>
      </div>
    </div>
  );
}
