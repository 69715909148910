import React from 'react';
import DisplayConfiguration from './DisplayConfiguration';
import ModelDataModel from '../../../models/model/ModelDataModel';
import { PushConfigurationItem } from '../types';

interface OverviewProps {
  isCustomPushConfig: boolean;
  isPushConfigFromUi: boolean;
  userCanEdit: boolean;
  pushConfigurationItem: PushConfigurationItem;
  generateModelBadges: Function;
  isSuperKudu: boolean;
  changePageState: Function;
  modelsList: ModelDataModel[];
}

export default function Overview({
  isPushConfigFromUi,
  pushConfigurationItem,
  userCanEdit,
  isCustomPushConfig,
  generateModelBadges,
  isSuperKudu,
  changePageState,
  modelsList,
}: OverviewProps) {
  if (isPushConfigFromUi) {
    return (
      <DisplayConfiguration
        modelsList={modelsList}
        pushConfigurationItem={pushConfigurationItem}
        generateModelBadges={generateModelBadges}
      />
    );
  }

  return (
    <>
      <div className="row mt-2">
        <div className="col-6 offset-3">
          <div className="alert alert-primary" role="alert">
            {isCustomPushConfig &&
              'A custom configuration has been set up here for you. Please contact MadKudu support for more information'}
            {!isCustomPushConfig &&
              userCanEdit &&
              'No push is configured yet. Please click on "Get Started" to proceed pushing MadKudu data to your CRM'}
            {!isCustomPushConfig &&
              !userCanEdit &&
              'No push is configured yet. Only Admin users can set this up.'}
          </div>
        </div>
      </div>
      {((userCanEdit && !isCustomPushConfig) || isSuperKudu) && (
        <div className="row mt-4">
          <div className="col-6 offset-5">
            <div
              className="btn btn-primary"
              role="button"
              onClick={() => changePageState('model')}
            >
              Get Started
            </div>
          </div>
        </div>
      )}
    </>
  );
}
