import React from 'react';
import { ConnectorFieldNameDataModel } from '../../mapping/models/ConnectorFieldNameDataModel';

type TablePushDisplayComponentProps = {
  pushConfigData: ConnectorFieldNameDataModel[];
  integration: string;
};

export default class TablePushDisplayComponent extends React.Component<
  TablePushDisplayComponentProps
> {
  render() {
    const { pushConfigData, integration } = this.props;
    return (
      pushConfigData.length > 0 &&
      pushConfigData.map((board: any, indexBoard: number) => (
        <table className="table table-bordered" key={`table_${indexBoard}`}>
          <thead>
            <tr>
              <th colSpan={2} className="text-center" scope="col">
                Object: {board.title}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              {board.allValueTypes.map((valueType: string, index: number) => {
                return (
                  <th
                    className={`table_element_${valueType} text-center`}
                    key={`table_element_${index}`}
                  >
                    {valueType === 'MadKudu'
                      ? valueType
                      : `${
                          integration[0].toUpperCase() + integration.slice(1)
                        } > ${valueType}`}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {board.rows.map((row: any, index: number) => {
              return (
                <React.Fragment key={`row_${index}`}>
                  <tr>
                    {board.allValueTypes.map(
                      (rowValueType: any, indexValueType: number) => {
                        return (
                          <td
                            className={`table_element_${rowValueType}`}
                            key={`element_${indexValueType}`}
                          >
                            {row[rowValueType]}
                          </td>
                        );
                      }
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      ))
    );
  }
}
