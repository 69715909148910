import React, { Component } from 'react';

export default class EvolutionOverTimeLeadsCustomerFitGuideComponent extends Component<
  {},
  {}
> {
  render() {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
        <div className="card-body">
          <i className="far fa-compass fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>Guide: </strong>
            Evolution Over Time: Leads Distribution
          </h5>
          <div className="border-top mt-2 border-primary"></div>
          <i className="fas fa-flask fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>How MadKudu Computes This:</strong>
          </h5>
          <p className="mt-2">
            How many of your leads created in the last 6 months are scored as
            very good, good, medium or low by the MadKudu's Customer Fit model.
          </p>
          <h5 className="mt-2">
            <strong>How to Interpret This:</strong>
          </h5>
          <p>
            This section aims to showcase the trend in the overall number of
            leads created in the last 6 months that have been scored as very
            good or good by MadKudu's Customer Fit model. If the trend in lead
            scored as very good or good has been declining at an abnormal pace
            but the overall number of leads has remained steady, there is a
            chance to review recent marketing activities that have possibly
            reduced engagement of leads.
          </p>
        </div>
      </div>
    );
  }
}
