import React, { ChangeEvent } from 'react';
import startCase from 'lodash/startCase';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { splitSentenceByCapitals } from '../utils';
import { Models } from '../types';
import AggregationsSelect from './AggregationsSelect';
import ComputationsSelect from './ComputationsSelect';
import ModelDataModel from '../../../models/model/ModelDataModel';
import HybridSelect from './HybridSelect';

type DataModelProps = {
  tenant: number;
  modelsList: ModelDataModel[];
  dataModelFormHasBeenSubmitted: boolean;
  handleModelSelection: (event: ChangeEvent<HTMLInputElement>) => void;
  pickedModel: Models;
  createArrayOfModels: Function;
  ltbModels: number[];
  lgModels: number[];
  hybridEnabled: boolean;
  hasHybridModels: boolean;
  handleLikelihoodToBuyChange: (likelihoodToBuy: number[]) => void;
  handleLeadGradeChange: (leadGrade: number[]) => void;
  computationsFields: string[];
  handleComputationsChange: (computations: string[]) => void;
  aggregationsFields: string[];
  handleAggregationsChange: (aggregations: string[]) => void;
  generateModelBadges: Function;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default class DataModel extends React.Component<DataModelProps> {
  render() {
    const models: string[] = this.props.createArrayOfModels();

    return (
      <QueryClientProvider client={queryClient}>
        {models.map((model) => {
          const isPicked =
            (this.props.pickedModel && this.props.pickedModel[model]) ?? false;
          const showHybridSelect =
            this.props.hybridEnabled || this.props.hasHybridModels;
          return (
            <div key={model} className="row mt-2">
              <div className="col-6 offset-3">
                <div className="card">
                  <label
                    htmlFor={`${model}`}
                    className={`card-header cursor-pointer ${
                      isPicked ? 'text-white bg-success' : 'none'
                    }`}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={this.props.handleModelSelection}
                        id={model}
                        checked={isPicked}
                      />
                      <span className="form-check-label font-weight-bold">
                        {startCase(
                          splitSentenceByCapitals(
                            model.replace('likelihoodToBuy', 'engagement')
                          )
                        )}
                      </span>
                    </div>
                  </label>
                  {isPicked && model === 'aggregations' && (
                    <div className="card-body">
                      <AggregationsSelect
                        tenant={this.props.tenant}
                        aggregationsFields={this.props.aggregationsFields}
                        handleAggregationsChange={
                          this.props.handleAggregationsChange
                        }
                      />
                    </div>
                  )}
                  {isPicked && model === 'computations' && (
                    <div className="card-body">
                      <ComputationsSelect
                        tenant={this.props.tenant}
                        computationsFields={this.props.computationsFields}
                        handleComputationsChange={
                          this.props.handleComputationsChange
                        }
                      />
                    </div>
                  )}
                  {isPicked && model === 'likelihoodToBuy' && showHybridSelect && (
                    <div className="card-body">
                      <>
                        {this.props.dataModelFormHasBeenSubmitted &&
                          this.props.pickedModel.likelihoodToBuy &&
                          !this.props.ltbModels.length && (
                            <span className="d-inline-block alert-danger mb-2">
                              Please select at least one model below
                            </span>
                          )}
                        <HybridSelect
                          modelsList={this.props.modelsList}
                          models={this.props.ltbModels}
                          handleModelsChange={
                            this.props.handleLikelihoodToBuyChange
                          }
                          generateModelBadges={this.props.generateModelBadges}
                          type="likelihood"
                        />
                      </>
                    </div>
                  )}
                  {isPicked && model === 'leadGrade' && showHybridSelect && (
                    <div className="card-body">
                      <>
                        {this.props.dataModelFormHasBeenSubmitted &&
                          this.props.pickedModel.leadGrade &&
                          !this.props.lgModels.length && (
                            <span className="d-inline-block alert-danger mb-2">
                              Please select at least one model below
                            </span>
                          )}
                        <HybridSelect
                          modelsList={this.props.modelsList}
                          models={this.props.lgModels}
                          handleModelsChange={this.props.handleLeadGradeChange}
                          generateModelBadges={this.props.generateModelBadges}
                          type="grade"
                        />
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </QueryClientProvider>
    );
  }
}
