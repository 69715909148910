import React from 'react';
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import { MultiSelectOption } from './types';

interface MultiSelectProps {
  placeholder?: string;
  options: readonly MultiSelectOption[];
  values: readonly MultiSelectOption[];
  onChange: (
    newValues: OnChangeValue<MultiSelectOption, true>,
    actionMeta: ActionMeta<MultiSelectOption>
  ) => void;
  disabled: boolean;
}

const styles: StylesConfig<MultiSelectOption, true> = {
  multiValue: (base, state) =>
    state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,
  multiValueLabel: (base, state) =>
    state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base,
  multiValueRemove: (base, state) =>
    state.data.isFixed ? { ...base, display: 'none' } : base,
};

const orderOptions = (values: readonly MultiSelectOption[]) => [
  ...values.filter(({ isFixed }) => isFixed),
  ...values.filter(({ isFixed }) => !isFixed),
];

const MultiSelect = ({
  placeholder,
  options,
  values,
  onChange: forwardChange,
  disabled,
}: MultiSelectProps) => {
  const onChange = (
    newValues: OnChangeValue<MultiSelectOption, true>,
    actionMeta: ActionMeta<MultiSelectOption>
  ) => {
    let forwardedNewValues = newValues;

    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) return;
        break;
      case 'clear':
        forwardedNewValues = options.filter(({ isFixed }) => isFixed);
        break;
      default:
        break;
    }
    forwardChange(forwardedNewValues, actionMeta);
  };

  return (
    <Select
      value={orderOptions(values)}
      isMulti
      options={options}
      closeMenuOnSelect={false}
      isClearable={values.some(({ isFixed }) => !isFixed)}
      styles={styles}
      onChange={onChange}
      isDisabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default MultiSelect;
