import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Loader } from '../components/Loader';

const IS_ADVANCED = true;

type CampaignProps = {
  tenant: number;
  createCampaign: Function;
  openCampaign: Function;
};

type CampaignState = {
  campaigns: any;
  creatingCampaign: boolean;
  togglingCampaign: boolean;
  checked: boolean;
};

export default class Campaigns extends React.Component<
  CampaignProps,
  CampaignState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      campaigns: [],
      creatingCampaign: false,
      togglingCampaign: false,
      checked: false,
    };

    this.openCampaign = this.openCampaign.bind(this);
    this.createCampaign = this.createCampaign.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.toggleCampaignStatus = this.toggleCampaignStatus.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${BONGO_URL}/v1/org/${this.props.tenant}/campaigns`)
      .then((res) => {
        const campaigns = res.data;
        this.setState({ campaigns });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createCampaign(isAdvanced?: boolean) {
    return () => {
      this.setState({ creatingCampaign: true });
      this.props.createCampaign(isAdvanced);
    };
  }

  openCampaign(id: string, campaignUi = {}) {
    const shouldOpenAsAdvanced = !Object.keys(campaignUi).length;
    return () => {
      this.props.openCampaign(id, shouldOpenAsAdvanced);
    };
  }

  deleteCampaign(id: string) {
    return async () => {
      const result = await Swal.fire({
        text: 'Are you sure you want to delete this campaign?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete!',
        showLoaderOnConfirm: true,
        reverseButtons: true,
      });
      if (!result.value) return;

      if (result.value) {
        await axios.delete(
          `${BONGO_URL}/v1/org/${this.props.tenant}/campaigns/${id}`
        );
        this.setState((prevState) => ({
          campaigns: prevState.campaigns.filter((el: any) => el._id !== id),
        }));
        Swal.fire({
          title: 'Campaign deleted',
          icon: 'success',
        });
      }
    };
  }

  async toggleCampaignStatus(campaign: any) {
    this.setState({ togglingCampaign: true });
    const { data: realCampaign } = await axios.get(
      `${BONGO_URL}/v1/org/${this.props.tenant}/campaigns/${campaign._id}`
    );
    const { campaigns } = this.state;
    const newCampaign = {
      ...realCampaign,
      active: !realCampaign.active,
    };
    const newCampaigns = campaigns.map((c: any) => {
      if (c._id === campaign._id) return newCampaign;
      return c;
    });

    return async () => {
      await axios.put(
        `${BONGO_URL}/v1/org/${this.props.tenant}/campaigns/${campaign._id}`,
        { active: newCampaign.active }
      );
      this.setState({ campaigns: newCampaigns, togglingCampaign: false });
    };
  }

  render() {
    const { campaigns, creatingCampaign, togglingCampaign } = this.state;

    return (
      <div className="container mt-4 pb-5">
        <div className="row d-flex justify-content-between">
          <div className="col-6">
            <div className="card md-6 mt-4 box-shadow-3d">
              <div className="card-header">
                <h5 className="mb-0">Setup a Fastlane Campaign</h5>
              </div>
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={creatingCampaign}
                  onClick={this.createCampaign()}
                >
                  {creatingCampaign && <Loader />}
                  Get Started
                </button>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card md-6 mt-4 box-shadow-3d">
              <div className="card-header">
                <h5 className="mb-0">Setup an Advanced Campaign</h5>
              </div>
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={creatingCampaign}
                  onClick={this.createCampaign(IS_ADVANCED)}
                >
                  {creatingCampaign && <Loader />}
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card mt-3 box-shadow-3d">
              <div className="card-header">
                <h5 className="mt-2">List of Campaigns</h5>
              </div>
              <div className="list-group list-group-flush">
                {!campaigns.length
                  ? null
                  : campaigns.map((campaign: any) => {
                      return (
                        <div
                          className="list-group-item hover-animation"
                          key={campaign._id}
                          style={{
                            backgroundColor: !campaign.active
                              ? '#e4e6e6'
                              : null,
                          }}
                        >
                          <div className="row">
                            <div className="col-3 my-auto">
                              {togglingCampaign ? (
                                <Loader />
                              ) : (
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={campaign._id}
                                    onChange={async () => {
                                      const promise = await this.toggleCampaignStatus(
                                        campaign
                                      );
                                      await promise();
                                    }}
                                    checked={campaign.active}
                                  ></input>
                                  <label
                                    className="custom-control-label"
                                    htmlFor={campaign._id}
                                  >
                                    {campaign.active ? 'On' : 'Off'}
                                  </label>
                                </div>
                              )}
                            </div>
                            <div
                              className="col-8 my-auto"
                              style={{ cursor: 'pointer' }}
                              onClick={this.openCampaign(
                                campaign._id,
                                campaign.campaignUi
                              )}
                            >
                              {campaign.name || 'Unnamed campaign'}
                            </div>
                            <div className="col-1 justify-content-center">
                              <button
                                className="btn btn-danger align-self-center"
                                onClick={this.deleteCampaign(campaign._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
