import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';

export function useChangeSlackSubscriptions(
  tenant: number,
  options?: UseMutationOptions<any, unknown, string[], unknown>
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (channelIds: string[]) => {
      const result = await axios.put(
        `${BONGO_URL}/v1/org/${tenant}/integrations/slack/subscriptions`,
        channelIds
      );

      return result.data;
    },
    ...options,
    onSuccess: (data: { channelNames?: string[] }, variables, ctx) => {
      options?.onSuccess?.(data, variables, ctx);
      queryClient.setQueryData(
        ['slack-subscriptions', tenant],
        data?.channelNames || []
      );
    },
  });
}
