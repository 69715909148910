import React from 'react';

const headerTexts = [
  'Select the calendar tool you use',
  'Configure your fastlane',
  'Test your fastlane',
];

const SubHeader = (props: { currentStep: number }) => {
  const { currentStep } = props;
  return (
    <div className="shadow-sm p-3 mb-5 bg-white rounded text-center border-top sticky-top">
      <p className="m-auto">
        <strong>Step {currentStep}/3:</strong> {headerTexts[currentStep - 1]}
      </p>
    </div>
  );
};

export default SubHeader;
