import React from 'react';
import { ConnectorFieldNameDataModel } from '../../mapping/models/ConnectorFieldNameDataModel';

type TablePullDisplayComponentProps = {
  pullFields: ConnectorFieldNameDataModel[];
  integration: string;
};

export default class TablePullDisplayComponent extends React.Component<
  TablePullDisplayComponentProps
> {
  render() {
    const { pullFields } = this.props;
    return pullFields.map((board: any, index: number) => {
      return (
        <table className="table table-bordered" key={`table_${index}`}>
          <thead>
            <tr>
              <th colSpan={2} className="text-center" scope="col">
                {`${
                  board.title[0].toUpperCase() + board.title.slice(1)
                } attributes`}
              </th>
            </tr>
          </thead>
          <tbody>
            {board.fields.map((field: any, indexField: number) => {
              return (
                <tr key={`field_${indexField}`}>
                  <td>{field.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    });
  }
}
