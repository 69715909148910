import React, { ChangeEvent } from 'react';
import { FormGroup } from 'react-bootstrap';
import capitalize from 'lodash/capitalize';
import Select from 'react-select';
import SegmentTimeframe from './SegmentTimeframe';
import { DatePicked, PickedObject, PushConfigurationItem } from '../../types';

import ModelDataModel from '../../../../models/model/ModelDataModel';

interface CardFieldProps {
  modelsList: ModelDataModel[];
  connector: string;
  pickedObject: PickedObject;
  datePicked: DatePicked;
  pushConfigurationItem: PushConfigurationItem;
  handleDatePick: (e: any, connector: string) => void;
  handleTimeLimitCheck: (e: any, connector: string) => void;
  handleChangeObject: Function;
  generateModelBadges: (
    modelName: string,
    tag?: string,
    model?: ModelDataModel
  ) => JSX.Element;
  handleDataMappingChange: Function;
  fieldsToMap: Function;
  handleSegmentTimeFrameChange: (e: any, connector: string) => void;
}

export default class CardField extends React.Component<CardFieldProps> {
  render() {
    const {
      connector,
      pickedObject,
      datePicked,
      pushConfigurationItem,
      handleDatePick,
      handleTimeLimitCheck,
      handleChangeObject,
      generateModelBadges,
      handleDataMappingChange,
      fieldsToMap,
      handleSegmentTimeFrameChange,
    } = this.props;
    const attributes =
      connector === 'intercom'
        ? pushConfigurationItem.attributes.filter(({ realtime }) => realtime)
        : pushConfigurationItem.attributes;
    const showDatePicking = !['intercom', 'segment'].includes(connector);

    const pickedObjectsArray = Object.entries(pickedObject[connector])
      .map(([object, picked]) => {
        if (!picked) return null;
        return object;
      })
      .filter((object) => !!object);

    return (
      <div className="row mt-2">
        <div className="col p-2">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-2">
                  <img
                    src={`/media/integrations/logos/${connector}.svg`}
                    className="w-50"
                  />
                </div>
              </div>
            </div>
            <div className="card-body p-4 mt-2 mb-2">
              <div className="row">
                <div className="col mb-3">
                  <div className="card">
                    <div className="card-header">
                      Which object should be updated with MadKudu data?
                    </div>
                    <div className="card-body">
                      <div>
                        Specify which system to push the data selected above to.
                      </div>

                      {connector === 'salesforce' && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeObject(e, connector)
                            }
                            checked={pickedObject[connector].lead}
                            id="lead"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkLead"
                          >
                            Lead
                          </label>
                        </div>
                      )}
                      {['salesforce', 'intercom', 'hubspot'].includes(
                        connector
                      ) && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeObject(e, connector)
                            }
                            checked={pickedObject[connector].contact}
                            id="contact"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkContact"
                          >
                            Contact
                          </label>
                        </div>
                      )}
                      {connector === 'segment' && (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleChangeObject(e, connector)
                            }
                            checked={pickedObject[connector].identify}
                            id="identify"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkIdentify"
                          >
                            Identify
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <div className="card">
                    <div className="card-header">
                      Which fields should be updated with MadKudu data?
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Segmentation</th>
                                <th>MadKudu data</th>
                                {pickedObjectsArray.map((object) => (
                                  <th key={object}>
                                    {`${capitalize(connector)} ${capitalize(
                                      object
                                    )}`}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {attributes.map(({ name, tag, integrations }) => {
                                let nameToDisplay = name;
                                const match = name?.match(
                                  /mk_(?:likelihood_to_buy|lead_grade)_.*$/
                                );
                                const hybridMatch = name?.match(
                                  /mk_(?:likelihood_to_buy|lead_grade)_.*_(\d+)$/
                                );
                                let modelId: number | null = null;
                                if (hybridMatch) {
                                  modelId = Number(hybridMatch[1]);
                                  nameToDisplay = nameToDisplay.replace(
                                    /_(\d+)$/,
                                    ''
                                  );
                                } else if (match) {
                                  const liveModel: ModelDataModel = this.props.modelsList.find(
                                    (m: ModelDataModel) => m.type === 'pql'
                                  );
                                  modelId = liveModel.modelId;
                                }

                                const model = modelId
                                  ? this.props.modelsList.find(
                                      (m: ModelDataModel) =>
                                        m.modelId === modelId
                                    )
                                  : null;

                                return (
                                  <tr key={name}>
                                    <td>
                                      {generateModelBadges(name, tag, model)}
                                    </td>
                                    <td>{nameToDisplay}</td>
                                    {pickedObjectsArray.map((object) => {
                                      const value =
                                        // @ts-ignore
                                        integrations?.[connector]?.[object] ??
                                        // @ts-ignore
                                        integrations?.[connector]?.[
                                          capitalize(object) // We have some connectors that use contact and other Contact
                                        ];
                                      return (
                                        <td key={object}>
                                          <FormGroup controlId="fieldsDropdown">
                                            <Select
                                              value={
                                                value && {
                                                  label: value,
                                                  value,
                                                }
                                              }
                                              placeholder="Select field..."
                                              options={fieldsToMap(
                                                connector,
                                                object
                                              )}
                                              onChange={(newValue) => {
                                                handleDataMappingChange(
                                                  newValue?.value ?? null,
                                                  connector,
                                                  object,
                                                  name
                                                );
                                              }}
                                              isClearable
                                            />
                                            {value &&
                                              !value
                                                ?.toLowerCase()
                                                ?.startsWith('mk_') && (
                                                <div className="text-danger">
                                                  Field selected is not a
                                                  MadKudu field
                                                </div>
                                              )}
                                          </FormGroup>
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {connector === 'segment' && (
                <SegmentTimeframe
                  datePicked={datePicked}
                  handleSegmentTimeFrameChange={handleSegmentTimeFrameChange}
                />
              )}
              {showDatePicking && (
                <div className="row">
                  <div className="col mb-3">
                    <div className="card">
                      <div className="card-header">
                        Which records should be updated?
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col">
                            Specify which records should be updated
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleTimeLimitCheck(e, connector)
                                }
                                checked={
                                  datePicked &&
                                  (datePicked[connector]
                                    .limit_scoring_timeframe as boolean)
                                }
                                id={`time_limit_${connector}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="defaultCheck1"
                              >
                                All records created after
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <input
                                type="date"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleDatePick(e, connector)
                                }
                                className="form-control"
                                id={`inputDate_${connector}`}
                                placeholder="date"
                                value={
                                  (datePicked?.[connector]
                                    ?.score_contacts_created_since as string) ??
                                  ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
