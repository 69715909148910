const segment_key = SEGMENT_WRITE_KEY || '2DO3M69SRODhp7OB8a6Qry08ebf7GcG2'; // eslint-disable-line no-undef

const load_segment = function () {
  /* eslint-disable */
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'page',
          'once',
          'off',
          'on',
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t) {
          var e = document.createElement('script');
          e.type = 'text/javascript';
          e.async = !0;
          e.src =
            ('https:' === document.location.protocol ? 'https://' : 'http://') +
            'cdn.segment.com/analytics.js/v1/' +
            t +
            '/analytics.min.js';
          var n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(e, n);
        };
        analytics.SNIPPET_VERSION = '3.1.0';
        if (!__MADKUDU_JS__) {
          analytics.load(segment_key);
        }
        analytics.page();
      }
  })();
  /* eslint-enable */
};

const load_madkudujs = function () {
  // only used for testing madkudu.js - gets scrapped from the final code
  /* eslint-disable no-undef */
  if (__MADKUDU_JS__) {
    /* eslint-enable no-undef */

    (function (window, document) {
      const madkudu = window.madkudu || [];
      if (!madkudu.initialize) {
        // prevent from loading twice
        if (madkudu.invoked) {
          if (window.console && console.error) {
            console.error('MadKudu snippet included twice.');
          }
        } else {
          // preload the methods
          madkudu.invoked = !0;
          madkudu.methods = [
            'identify',
            'reset',
            'group',
            'ready',
            'page',
            'track',
            'once',
            'on',
            'smart_form',
          ];
          madkudu.factory = function (t) {
            return function () {
              // eslint-disable-next-line
              const e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              madkudu.push(e);
              return madkudu;
            };
          };
          for (let t = 0; t < madkudu.methods.length; t += 1) {
            const e = madkudu.methods[t];
            madkudu[e] = madkudu.factory(e);
          }
          madkudu.load = function () {
            const src = 'http://localhost:3004/madkudu.js';
            // var src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.madkudu.com/madkudu.js/v1/" + api_key + "/madkudu.min.js";
            // if require is here, load with it
            if (typeof window.define === 'function' && window.define.amd) {
              window.require([src]);
              // otherwise, add the script to the page
            } else {
              const e = document.createElement('script');
              e.type = 'text/javascript';
              e.async = !0;
              e.src = src;
              const n = document.getElementsByTagName('script')[0];
              n.parentNode.insertBefore(e, n);
            }
          };
          madkudu.SNIPPET_VERSION = '0.3.0';
          madkudu.load('985ccd7664cec040468ba02ac8864151');
          madkudu.page();
          // madkudu.form();
        }
      }
      // eslint-disable-next-line
    })(window, document);
  }
};

const load_scripts = function () {
  load_segment();

  load_madkudujs();
};

load_scripts();
