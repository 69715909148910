import $ from 'jquery';
import getQueryParameter from './get_query_parameter';

const track_visitor = function () {
  const email = getQueryParameter('email');
  if (email) {
    window.analytics.identify(
      {
        email,
        signup_date: new Date(),
      },
      {
        integrations: {
          Salesforce: true,
        },
      }
    );
  }
};

export default function () {
  if (getQueryParameter('email')) {
    $('input[name="email"]').val(getQueryParameter('email'));
    $('input[name="password"]').focus();
  } else {
    $('input[name="email"]').focus();
  }
  track_visitor();
}
