import React, { Component } from 'react';

export default class EvolutionOverTimeLeadsLeadGradeGuideComponent extends Component<
  {},
  {}
> {
  render() {
    return (
      <div className="card box-shadow-3d d-flex h-100 border-primary bg-primary-fade text-primary">
        <div className="card-body">
          <i className="far fa-compass fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>Guide: </strong>
            Evolution Over Time: Leads Distribution
          </h5>
          <div className="border-top mt-2 border-primary"></div>
          <i className="fas fa-flask fa-lg mt-2"></i>
          <h5 className="mt-2">
            <strong>How MadKudu Computes This:</strong>
          </h5>
          <p className="mt-2">
            How many of your leads created in the last 6 months are scored as
            very A, B, C, D or E by MadKudu's Lead Grade model.
          </p>
          <h5 className="mt-2">
            <strong>How to Interpret This:</strong>
          </h5>
          <p>
            This section aims to showcase the trend in the overall number of
            leads created in the last 6 months that have been scored as A or B
            by MadKudu's Lead Grade model. If the trend in lead scored as A or B
            has been declining at an abnormal pace but the overall number of
            leads has remained steady, there is a chance we need to understand
            recent marketing activities that have decreased quality and
            engagement of leads.
          </p>
        </div>
      </div>
    );
  }
}
