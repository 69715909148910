import $ from 'jquery';
import Mn from 'backbone.marionette';
import app from './app';
import LoginView from './auth/login';
import RecoverPasswordView from './auth/recover_password';
import ResetPasswordView from './auth/reset_password';
import SAMLView from './auth/login_sso';
import SignupView from './auth/signup';

const AuthController = (Mn.Object as any).extend({
  initialize() {},

  change_title(title: string) {
    const newTitle = title ? `${title} | MadKudu` : 'MadKudu';
    $(document).attr('title', newTitle);
  },

  track_page(title: string) {
    return title && window.analytics && window.analytics.page(title);
  },

  transition_view(View: any, params = {}, options: { title?: string } = {}) {
    const { title } = options;
    this.track_page(title);
    this.change_title(title);
    app.root.detachChildView('main'); // detach the existing view if it exists
    // eslint-disable-next-line
    app.root.showChildView(
      'main', // eslint-disable-next-line
      View.default ? new View.default(params) : new View(params)
    );
  },

  login(sso_provider: any) {
    const params: { sso: UnknownObject } = { sso: {} };
    if (sso_provider) {
      params.sso[sso_provider] = true;
      if (sso_provider === 'sso') {
        return this.transition_view(SAMLView, undefined, {
          title: 'Single Sign On - Okta (SSO) Login',
        });
      }
    }
    this.transition_view(LoginView, params, { title: 'Login' });
  },

  signup() {
    this.transition_view(SignupView, undefined, { title: 'Signup' });
  },

  reset() {
    this.transition_view(ResetPasswordView, undefined, {
      title: 'Reset password',
    });
  },

  recover() {
    this.transition_view(RecoverPasswordView, undefined, {
      title: 'Recover password',
    });
  },
  saml() {
    this.transition_view(SAMLView, undefined, {
      title: 'Single Sign On - Okta (SSO) Login',
    });
  },
});

export default Mn.AppRouter.extend({
  appRoutes: {
    login: 'login',
    'login/:sso_provider': 'login',
    signup: 'signup',
    recover: 'recover',
    reset: 'reset',
    saml: 'saml',
  },

  initialize() {
    this.controller = new AuthController({});
  },
});
