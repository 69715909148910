import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useDisconnectCognism(tenant: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await axios.delete(`${BONGO_URL}/v1/org/${tenant}/integrations/cognism`);
    },
    onSuccess: () => {
      queryClient.setQueryData(['cognism', tenant], false);
    },
  });
}
