import React, { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';
import OverviewComponent from './OverviewComponent';
import ConfigurationComponent from './ConfigurationComponent';
import model_integration from '../../../models/integration';

export type TabValues = 'overview' | 'configuration';

type ClearbitParentComponentProps = {
  tenant: number;
  getActivePage: () => TabValues;
  toggleView: Function;
};

export default function ClearbitParentComponent({
  tenant,
  getActivePage,
  toggleView,
}: ClearbitParentComponentProps) {
  const currentView = getActivePage();
  const integration = 'clearbit';
  const tabNames: TabValues[] = ['overview', 'configuration'];

  const [activeTab, setActiveTab] = useState<TabValues>(currentView);
  const [loading, setLoading] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const handleTabClick = (tab: TabValues) => {
    setActiveTab(tab);
    return toggleView(tab);
  };

  const fetchClearbitCredentials = () => {
    const model = new model_integration.Credentials_model({
      tenant,
      integration,
    });

    const integrationModel = new model_integration.Integration_model({
      tenant,
      name: integration,
    });

    type Integration = {
      name: string;
      tenant: number;
      push?: { active: boolean };
      pull?: { active: boolean };
    };

    function isActive(clearbitIntegration: Integration) {
      return (
        clearbitIntegration.push?.active || clearbitIntegration.pull?.active
      );
    }

    async function checkIntegration() {
      await integrationModel.fetch({
        success: (_model: any, integrationState: Integration) => {
          setLoading(false);
          if (isActive(integrationState)) setIsConnected(true);
        },
        error: () => {
          setLoading(false);
          setIsConnected(false);
        },
      });
    }

    model.fetch({
      success: checkIntegration,
      error: (_collection: any, response: any) => {
        if (response.status === 404) {
          model.set({
            not_found: true,
          });
        }
        setLoading(false);
        setIsConnected(false);
      },
    });
  };

  useEffect(() => {
    toggleView(activeTab);
    fetchClearbitCredentials();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-top sticky-top">
        <ul className="nav nav-pills mx-auto">
          {tabNames.map((tabName) => (
            <li className="nav-item" key={tabName}>
              <a
                className={`nav-link ${
                  activeTab === tabName ? 'active show' : ''
                }`}
                id={tabName}
                data-toggle="tab"
                href={`#${tabName}`}
                onClick={() => handleTabClick(tabName)}
                role="tab"
              >
                {capitalize(tabName)}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      {tabNames.map((tabName, index) => (
        <div className="row ml-4" key={`tab_content_${index}`}>
          <div className="tab-content w-100" id="TabContent">
            <div
              key={`tab_content_${tabName}`}
              className={`tab-pane fade ${
                activeTab === tabName ? 'active show' : ''
              }`}
              id={`${tabName}-tab`}
              role="tabpanel"
            >
              <div className="row justify-content-center">
                <div className="col-8 mt-4 mb-4">
                  <div className="card box-shadow-3d mt-md-2 d-flex w-100">
                    <div className="card-header">
                      <div className="d-flex align-items-center">
                        <img
                          className="con-map-mk-integration-logo-size"
                          style={{ width: '30px' }}
                          src={`/media/integrations/logos/clearbit2.svg`}
                        />
                        <h5 className="mb-0 ml-3">Clearbit</h5>
                      </div>
                    </div>

                    <div className="card-body">
                      {tabName === 'overview' && (
                        <OverviewComponent
                          isConnected={isConnected}
                          loading={loading}
                          handleTabClick={handleTabClick}
                        />
                      )}
                      {tabName === 'configuration' && (
                        <ConfigurationComponent
                          tenant={tenant}
                          integration={integration}
                          isConnected={isConnected}
                          loading={loading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
