import { z } from 'zod';

// Mixpanel Project secret (legacy auth method)
export const legacyMixpanelAuthSchema = z.object({
  api_key: z.string().min(1, 'API key is required'),
  api_secret: z.string().min(1, 'API secret is required'),
  accountUsername: z.string().optional(),
  accountPassword: z.string().optional(),
  projectId: z.string().optional(),
  where: z.string().optional(),
  eu: z.boolean().default(false),
});

// Mixpanel Service account (newly recommended auth method)
export const newMixpanelAuthSchema = z.object({
  api_key: z.string().optional(),
  api_secret: z.string().optional(),
  accountUsername: z.string().min(1, 'Account username is required'),
  accountPassword: z.string().min(1, 'Account password is required'),
  projectId: z.string().min(1, 'Project ID is required'),
  where: z.string().optional(),
  eu: z.boolean().default(false),
});

export type MixpanelCredentials = {
  api_key?: string;
  api_secret?: string;
  accountUsername?: string;
  accountPassword?: string;
  projectId?: string;
  where?: string;
  eu?: boolean;
};
