import React from 'react';
import { Card } from '../../../components/Card';

export default class ClearBitRevealDisplayModeComponent extends React.Component<
  {},
  {}
> {
  render() {
    // We hide the Learn More button until the documentation is ready
    return (
      <div className="container mt-4 pb-5 w-50">
        <Card cardTitle="Anonymous Visitor Identifier">
          <p>
            Deanonymize your website traffic with MadKudu Anonymous Visitor
            Identifier powered by{' '}
            <span style={{ textDecoration: 'underline' }}>
              Clearbit Reveal.
            </span>
          </p>
          <p>
            With this integration, discover hot and engaged new accounts
            visiting your website.
          </p>
          <button className="btn btn-primary" hidden={true}>
            Learn More
          </button>
          <div className="float-right mt-4">
            <div className="d-flex justify-content-between">
              <span className="mt-2">in partnership with</span>
              <img
                src="/media/integrations/logos/clearbit.svg"
                style={{ height: '40px' }}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
