import React from 'react';
import { Button } from '../../../components/ui';
import { Loading } from '../Loading';
import { useDisconnectCognism } from '../hooks/useDisconnectCognism';

type Props = {
  tenant: number;
};

export function ConnectedView({ tenant }: Props) {
  const {
    mutate: disconnect,
    isLoading: isDisconnecting,
  } = useDisconnectCognism(tenant);

  if (isDisconnecting)
    return <Loading loadingLabel="Disconnecting from Cognism" />;

  return (
    <>
      <p>
        Status: <b className="tw-text-green-500">You're connected to Cognism</b>
      </p>

      <Button color="danger" onClick={() => disconnect()}>
        Disconnect
      </Button>
    </>
  );
}
