import { AbstractFiltersForm } from './AbstractFiltersForm';
import { ConnectorsNames, MappingConversionsTypes } from '../../../../types';
import { ConnectorFieldNameDataModel } from '../../../models/ConnectorFieldNameDataModel';

export default class StripeFiltersForm extends AbstractFiltersForm {
  sqoAmountThreshold: number;

  constructor(
    tenant: number,
    connector: ConnectorsNames,
    conversionType: MappingConversionsTypes,
    parametersFieldsNames: ConnectorFieldNameDataModel[],
    sqoAmountThreshold?: number
  ) {
    super(tenant, connector, conversionType, parametersFieldsNames);

    if (sqoAmountThreshold) {
      this.sqoAmountThreshold = sqoAmountThreshold;
    } else {
      this.sqoAmountThreshold = 0;
    }
  }

  async init(): Promise<void> {
    return undefined;
  }

  changeSqoAmountThreshold(newThreshold: number): number {
    this.sqoAmountThreshold = newThreshold;
    this.updatedAt = Date.now();
    return this.sqoAmountThreshold;
  }

  prepare(): void {
    delete this.parametersFieldsNames;
  }
}
