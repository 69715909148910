import Backbone from 'backbone';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import app from '../../../../app';
import { BackboneViewProps } from '../../../../types';
import { isUserReadOnly } from '../../../../utils';
import ConversionMappingManager from '../../models/ConversionMappingManager';
import ConversionTypeComponent from './components/ConversionTypeComponent';

const template = require('./index.pug');

export default Backbone.View.extend({
  async initialize(options: BackboneViewProps) {
    this.tenant = options.tenant;
    this.user = app.session.get('user');
    this.isReadOnly = isUserReadOnly(this.user);
    if (this.isReadOnly) {
      Backbone.history.navigate('error/403', true);
      return this;
    }
    this.email = this.user.email;

    this.conversionMappingManager = new ConversionMappingManager(
      this.tenant,
      this.email,
      this.isReadOnly,
      this.user.superKudu
    );

    await this.conversionMappingManager.init();

    // Not yet ready to be here
    if (
      !this.conversionMappingManager.isFinished &&
      this.conversionMappingManager.step === 0
    ) {
      Backbone.history.navigate(
        `org/${this.tenant}/mapping/conversion_mapping`,
        true
      );
    }

    await this.sleep(0);

    this.render();
    this.renderReactComponent();
  },

  renderReactComponent() {
    ReactDOM.render(
      <ConversionTypeComponent
        conversionMappingManager={this.conversionMappingManager}
      />,
      $('#ConversionTypeComponentContainer')[0]
    );
  },

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  render() {
    const html = template({});
    this.$el.html(html);

    return this;
  },
});
