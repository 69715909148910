export default class SelectOptionDataModel {
  value: number;

  label: string;

  constructor(other?: SelectOptionDataModel) {
    if (other) {
      this.value = other.value;
      this.label = other.label;
    } else {
      this.value = 0;
      this.label = 'Draft';
    }
  }
}
