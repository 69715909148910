var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (modelName, modelType) {
      pug_html = pug_html + "\u003Cnav class=\"navbar navbar-expand-lg navbar-light bg-light\" aria-label=\"breadcrumb\"\u003E\u003Cul class=\"navbar-nav navbar-brand mr-auto mt-2 mt-lg-0\"\u003E\u003Cli class=\"breadcrumb-item\"\u003E\u003Ca class=\"breadcrumb-link text-primary\" id=\"go_back_to_prediction\"\u003EPredictions\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item\"\u003E\u003Ca class=\"breadcrumb-link text-primary\" id=\"go_back_to_diagnostics\"\u003EDiagnostics\u003C\u002Fa\u003E\u003C\u002Fli\u003E\u003Cli class=\"breadcrumb-item active\" aria-current=\"page\"\u003E" + (pug.escape(null == (pug_interp = 'Model: ' + modelName) ? "" : pug_interp)) + "\u003C\u002Fli\u003E\u003C\u002Ful\u003E";
if (modelType && modelType !== 'pql' && modelType !== 'pql2' && modelType !== 'mql') {
pug_html = pug_html + "\u003Cdiv class=\"my-2 my-lg-0\"\u003E\u003Cdiv\u003E\u003Cbutton class=\"btn btn-primary\" id=\"override-button\"\u003EOverrides\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fnav\u003E\u003Cdiv\u003E\u003Cdiv class=\"container mt-4 pb-5\"\u003E\u003Cdiv class=\"card box-shadow-3d m-4 p-4\"\u003E\u003Cdiv id=\"ModelComponentContainer\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "modelName" in locals_for_with ?
        locals_for_with.modelName :
        typeof modelName !== 'undefined' ? modelName : undefined, "modelType" in locals_for_with ?
        locals_for_with.modelType :
        typeof modelType !== 'undefined' ? modelType : undefined));
    ;;return pug_html;};
module.exports = template;