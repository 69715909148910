import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  displayNoAttributeMappingSwal,
  displayNoModelSwal,
  displayRecapSwal,
} from '../utils';

type MainComponentProps = {
  tenant: number;
  email: string;
  isAttributeMappingConfigured: boolean;
  isModelLive: boolean;
};

type MainComponentState = {
  message: string;
};

export default class MainComponent extends React.Component<
  MainComponentProps,
  MainComponentState
> {
  constructor(props: MainComponentProps) {
    super(props);
    this.state = {
      message: '',
    };
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSendRequestButton = this.handleSendRequestButton.bind(this);
  }

  handleMessageChange(event: any) {
    event.persist();
    this.setState({
      message: event.target.value,
    });
  }

  async handleSendRequestButton() {
    const { message } = this.state;
    const {
      tenant,
      email,
      isAttributeMappingConfigured,
      isModelLive,
    } = this.props;
    if (!isAttributeMappingConfigured) {
      await displayNoAttributeMappingSwal(tenant);
    } else if (!isModelLive) {
      await displayNoModelSwal();
    } else {
      await displayRecapSwal(email, tenant, message);
    }
  }

  render() {
    const { isAttributeMappingConfigured, isModelLive } = this.props;

    return (
      <div>
        <div className="container">
          <div className="m-4 p-4 box-shadow-3d">
            <p>
              In this section, CSMs and CSEs are able to send a request to
              Engineering to activate Customer Fit Account scoring.
            </p>
            <div>
              <div className="container">
                <div className="m-4 p-4">
                  <Row>
                    <Col sm={1}>
                      <Button
                        bsStyle={
                          isAttributeMappingConfigured ? 'success' : 'danger'
                        }
                        style={{ width: '75px' }}
                      >
                        {isAttributeMappingConfigured ? 'success' : 'fail'}
                      </Button>
                    </Col>
                    <Col sm={5}>
                      <Row style={{ marginLeft: '15px', fontSize: '15px' }}>
                        <p>Account domain mapping</p>{' '}
                        <p
                          style={{
                            textDecoration: 'underline',
                            marginLeft: '5px',
                          }}
                        >
                          Salesforce Attribute Mapping
                        </p>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <Col sm={1}>
                      <Button
                        bsStyle={isModelLive ? 'success' : 'danger'}
                        style={{ width: '75px' }}
                      >
                        {isModelLive ? 'success' : 'fail'}
                      </Button>
                    </Col>
                    <Col sm={5}>
                      <Row style={{ marginLeft: '15px', fontSize: '15px' }}>
                        <p>Batch customer fit model deployed from the </p>{' '}
                        <p
                          style={{
                            textDecoration: 'underline',
                            marginLeft: '5px',
                          }}
                        >
                          Data Studio
                        </p>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <strong style={{ fontSize: '15px' }}>
                      Additional message
                    </strong>
                  </Row>
                  <Row>
                    <textarea
                      className="form-control"
                      rows={6}
                      onChange={this.handleMessageChange}
                    />
                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <Button
                      bsStyle="primary"
                      style={{ fontSize: '15px' }}
                      onClick={this.handleSendRequestButton}
                    >
                      Send request to the engineering team
                    </Button>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
