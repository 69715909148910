import React from 'react';

export default class TriggerOptions extends React.Component<any> {
  constructor(props: any) {
    super(props);

    this.isConfirmed = this.isConfirmed.bind(this);
    this.handleMultipleValuesChange = this.handleMultipleValuesChange.bind(
      this
    );
  }

  isConfirmed() {
    const { confirmStepHandler, stepId } = this.props;
    confirmStepHandler(stepId);
  }

  handleMultipleValuesChange(index: number) {
    return (e: any) => {
      this.props.handleMultipleValuesChange(e.target.value, index);
    };
  }

  render() {
    const {
      stepId,
      stepName,
      stepValues,
      shouldShow,
      inputName,
      inputType,
      placeholder,
      inputId,
      subRadioDescription,
      isConfigured,
      accordionId,
      collapseId,
      headingId,
      hrefDataAccordionId,
      hrefDataCollapseId,
      description,
    } = this.props;
    return (
      <div className="row justify-content-center">
        <div className="col-10 mt-2">
          <div
            className="accordion md-accordion"
            id={accordionId}
            role="tablist"
            aria-multiselectable="true"
          >
            <div
              className="card box-shadow-3d"
              style={{ borderColor: isConfigured ? '#4DCCBD' : 'default' }}
            >
              <div className="card-header" role="tab" id={headingId}>
                <a
                  className="collapsed"
                  data-toggle="collapse"
                  data-parent={hrefDataAccordionId}
                  href={hrefDataCollapseId}
                  aria-expanded="false"
                  aria-controls={collapseId}
                  style={{ textDecoration: 'none' }}
                >
                  <h5
                    className="mb-0"
                    style={{ color: isConfigured ? '#4DCCBD' : 'black' }}
                  >
                    {stepName}
                    <h5
                      className="pull-right mb-0"
                      style={{ color: isConfigured ? '#4DCCBD' : 'red' }}
                    >
                      <span className="mr-4">
                        {isConfigured ? 'Configured' : 'Not Configured'}
                      </span>
                      <span>
                        <i className="fas fa-angle-down rotate-icon"></i>
                      </span>
                    </h5>
                  </h5>
                </a>
              </div>
              <div
                id={collapseId}
                className={`collapse ${
                  stepId === 1 || shouldShow ? 'show' : ''
                }`}
                role="tabpanel"
                aria-labelledby={headingId}
                data-parent={hrefDataAccordionId}
              >
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col">
                      <h6>{description}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputCampaign"
                            className="col-md-auto col-form-label"
                            style={{ fontSize: '1.1em', fontWeight: 'bold' }}
                          >
                            {inputName}
                          </label>
                          <div className="col-9">
                            <input
                              type={inputType}
                              className="form-control"
                              id={inputId}
                              value={stepValues[0]}
                              onChange={this.handleMultipleValuesChange(0)}
                              placeholder={placeholder}
                              style={{ backgroundColor: '#f2f4f4' }}
                            ></input>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col">
                      <h6>{subRadioDescription}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="On field change"
                          checked={stepValues[1] === 'On field change'}
                          onChange={this.handleMultipleValuesChange(1)}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          On field change
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="On form submit"
                          checked={stepValues[1] === 'On form submit'}
                          onChange={this.handleMultipleValuesChange(1)}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          On form submit
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        disabled={!stepValues[0] || !stepValues[1]}
                        onClick={this.isConfirmed}
                      >
                        <a
                          href={
                            stepValues[0] && stepValues[1] && hrefDataCollapseId
                          }
                          className="btn-primary"
                          data-toggle="collapse"
                          aria-expanded="false"
                          aria-controls="collapse"
                        >
                          Confirm
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
